/**  
 * VbaShareService
 * Page/Class name   : VbaShareService
 * Author            :
 * Created Date      : 9-6-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, getTweetRecords
 * Purpose           : api service page
**/
import {Component } from 'react';
// import {getEndPoint,getTokenFromLocStorage, callApi} from  '../../common_utilities/commonjsfunc';
// newly added axios
//import axios from 'axios';
import {axiosCustomObj} from '../customisedthirdparty/AxiosCustom';

import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
const callApi = commonJsObj.callApi;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;



class VbaShareService extends Component {

    
    /**  
     * VbaShareService
     * Function name : constructor
     * Author        :
     * Created Date  : 9-6-2020
     * Purpose       : initializing state
     * Params        : 
    **/
    constructor() {
      super();
      this.state = {
          
      }

       //this.axiosObj = axios;
       this.axiosObj = null;
      
      this.apiEndPoint = commonJsObj.getEndPoint()+'rest/organisations/share/virtual_account';
    }

    /**  
     * TaskInvoiceShareService
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 9-6-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentDidMount() {
    }

    /**  
     * TaskInvoiceShareService
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 9-6-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentWillUnmount() {  
    }



    /**  
     * TaskInvoiceShareService
     * Function name : createTask
     * Author        :
     * Created Date  : 9-6-2020
     * Purpose       : saveOrgDetail check to provide access token 
     * Params        : paramObj
    **/
    async shareVba(paramObj){
      this.axiosObj = axiosCustomObj({});    
      let accToken = getTokenFromLocStorage();     
      try{
          let urldata =this.apiEndPoint;            
          
          let dataObj = paramObj.data;
          //let lpUserId = dataObj.lpUserId?dataObj.lpUserId:0;
          //let taskIdOrSubTaskId = dataObj.taskIdOrSubTaskId?dataObj.taskIdOrSubTaskId:0;
          //let taskOrSubTask = dataObj.taskOrSubTask?dataObj.taskOrSubTask:"";

          // if(taskOrSubTask=="STASK"){
          //   urldata = urldata+"/task/"+taskIdOrSubTaskId;
          // }
          // else if(taskOrSubTask=="SWSTASK"){
          //   urldata = urldata+"/sub_task/"+taskIdOrSubTaskId;
          // }

         
          
          
          //console.log(" ==dataObj==>",dataObj);


          let headerOptn = {  
            "Content-Type": "application/json",
            "Authorization": accToken
          };          

          let respObj = this.axiosObj.post(urldata,dataObj.postData, {
          headers: headerOptn
          });

          return respObj;
      }
      catch(err){
        console.log("Some error occured===>",err); // TypeError: failed to fetch
      }
      

    }

    
}
export default VbaShareService;