/**
 * commonjsfunc
 * Page/Class name   : commonjsfunc
 * Author            :
 * Created Date      : 11-2-2020
 * Functionality     : getCustomLocalUtcDate
 * Purpose           : time utc conversion to timestamp
**/
export const commonJsFuncModule = {
   /**
    * commonjsfunc
    * Function name : getCustomLocalUtcDate
    * Author        :
    * Created Date  : 11-2-2020
    * Purpose       : get utc timestamp of a particular day depending upon typeFlag
    * Params        : typeFlag
   **/
   getCustomLocalUtcDate: function(typeFlag){
        
        if(typeFlag=="TODAY"){
            let todaysDt = new Date();
            let utcTodaysDateTs =  commonJsFuncModule.getUtcTimestampFromDateObj(todaysDt);

            let todaysDtBegin = new Date(todaysDt.getFullYear(),todaysDt.getMonth(), todaysDt.getDate());            
            let utcTodaysDateTsBegin =  commonJsFuncModule.getUtcTimestampFromDateObj(todaysDtBegin);
           
            return {"start":utcTodaysDateTsBegin, "end":utcTodaysDateTs};
        }
        else if(typeFlag=="PREVSEVENTHDAY"){
            let dtObj = new Date(); 
            dtObj.setDate(dtObj.getDate() - 6);
            let dtObj2 = new Date(dtObj.getFullYear(),dtObj.getMonth(), dtObj.getDate());
            let utcPrevSeventhdayDateTs = commonJsFuncModule.getUtcTimestampFromDateObj(dtObj2);

            let todaysDt = new Date();
            let utcTodaysDateTs =  commonJsFuncModule.getUtcTimestampFromDateObj(todaysDt);
            
            return {"start":utcPrevSeventhdayDateTs, "end":utcTodaysDateTs};
        }
         else if(typeFlag=="PREV30THDAY"){
            let dtObj = new Date(); 
            dtObj.setDate(dtObj.getDate() - 29);
            let dtObj2 = new Date(dtObj.getFullYear(),dtObj.getMonth(), dtObj.getDate());
            let utcPrev30thdayDateTs =  commonJsFuncModule.getUtcTimestampFromDateObj(dtObj2);           
            
            let todaysDt = new Date();
            let utcTodaysDateTs =  commonJsFuncModule.getUtcTimestampFromDateObj(todaysDt);

            return {"start":utcPrev30thdayDateTs, "end":utcTodaysDateTs};
         }
         else if(typeFlag=="PREV365THDAY"){
            let dtObj = new Date(); 
            dtObj.setDate(dtObj.getDate() - 364);
            let dtObj2 = new Date(dtObj.getFullYear(),dtObj.getMonth(), dtObj.getDate());
            let utcPrev365thdayDateTs =  commonJsFuncModule.getUtcTimestampFromDateObj(dtObj2);
           
            
            let todaysDt = new Date();
            let utcTodaysDateTs =  commonJsFuncModule.getUtcTimestampFromDateObj(todaysDt);

            return {"start":utcPrev365thdayDateTs, "end":utcTodaysDateTs};
         }
         else if(typeFlag=="PREV6THMONTH"){
            let todaysDt3 = new Date();
            todaysDt3.setMonth(todaysDt3.getMonth() - 6);
            let utcPrev6thMonthDateUTC = todaysDt3.toUTCString();
            let utcPrev6thMonthDateTs =  Date.parse(utcPrev6thMonthDateUTC);
            return utcPrev6thMonthDateTs;
         }
      return null;
   },

   /**
    * commonjsfunc
    * Function name : getUtcTimestampFromDateObj
    * Author        :
    * Created Date  : 11-2-2020
    * Purpose       : get utc timestamp of a date object
    * Params        : typeFlag
   **/
   getUtcTimestampFromDateObj: function(dateObj){
      let utcTodaysDate = dateObj.toUTCString();
      let utcTodaysDateTs = Date.parse(utcTodaysDate);
      return utcTodaysDateTs;
   },

   getTimestampInMillisecondsFromDateObj: function(dateObj){      
      return dateObj.getTime();
   },

   getDateObjFromMilliseconds: function(ms){   
     let dtObj = new Date(ms);   
      return dtObj;
   },
   timeStringToFloat: function(time) {
    //time = 0.75;
    let hoursMinutes = time.toString().split(/[.:]/);
    let hours = parseInt(hoursMinutes[0], 10); // get the hour
    let minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0; // get the minute
    return {
      "hr": hours,
      "min": Math.round(("."+minutes) * 60) // convert decimal minute to whole number minute
    };
    //return hours + minutes / 60;
   },
   getOffsetOfLocalDateTime: function(dtObj){

       let ofstData =    -(((dtObj).getTimezoneOffset() )/60); // convert to hours
       let getHrMnOffset = commonJsFuncModule.timeStringToFloat(ofstData);
       //console.log("=getHrMnOffset=>",getHrMnOffset);
       return getHrMnOffset;

   },
   getMaxPageSize: function(){    
    //return 3;
     return process.env.REACT_APP_API_MAX_PAGE_SIZE;
  },
  
   /**
    * commonjsfunc
    * Function name : separateKeysAndValuesInJson
    * Author        :
    * Created Date  : 11-2-2020
    * Purpose       : separating keys and value of a json
    * Params        : typeFlag
   **/
   separateKeysAndValuesInJson : function(data){
      var keys = [];
      var values = [];
      Object.keys(data).forEach(function(key) {
          keys.push(key);
          values.push(data[key]);
      });
      var dataValue = {
          "values":values,
          "keys":keys
      }
      return dataValue
   },
   /**
    * commonjsfunc
    * Function name : breakNumber
    * Author        :
    * Created Date  : 11-2-2020
    * Purpose       : breaking numbers into thousand(k)
    * Params        : typeFlag
   **/
  breakNumber : function(num){
     let numb_abs = Math.abs(num)
     if(numb_abs > 999999999){ //billion
        return Math.sign(num)*((numb_abs/1000000000).toFixed(1)) + 'B';
     }else if(numb_abs > 999999){ //million
        return Math.sign(num)*((numb_abs/1000000).toFixed(1)) + 'M';
     }else if(numb_abs > 999){ //thousand
        return Math.sign(num)*((numb_abs/1000).toFixed(1)) + 'k';
     }else{ // below thousand
        return Math.sign(num)*numb_abs;
     }
   
  },
  /**
   * commonjsfunc
   * Function name : getTokenFromLocStorage
   * Author        :
   * Created Date  : 11-2-2020
   * Purpose       : get token from local storage
   * Params        : 
  **/
  getTokenFromLocStorage: function(){    
    let userObj = commonJsFuncModule.getUserInfo();
    if(userObj!==null){
        return  userObj.ltoken!==null? commonJsFuncModule.custDecode(userObj.ltoken): null;
    }
    return null;

  },
  /**
   * commonjsfunc
   * Function name : removeTokenFromLocStorage
   * Author        :
   * Created Date  : 11-2-2020
   * Purpose       : remove token from local storage
   * Params        : 
  **/
  removeTokenFromLocStorage: function(){
      if(commonJsFuncModule.getTokenFromLocStorage()!==null){
          localStorage.removeItem("laluser"); 
      }
          
  },
  /**
   * commonjsfunc
   * Function name : getAccessInfoFromLS
   * Author        :
   * Created Date  : 11-2-2020
   * Purpose       : get access info from local storage
   * Params        : 
  **/
    getAccessInfoFromLS: function(){          
        
        let userObj = commonJsFuncModule.getUserInfo();
        if(userObj!==null){
             return  userObj.tdaccess!==null? commonJsFuncModule.custDecode(userObj.tdaccess): null;
        }
        return null;
    },
    
  /**
   * commonjsfunc
   * Function name : removeAccessInfoFromLS
   * Author        :
   * Created Date  : 11-2-2020
   * Purpose       : remove access info from local storage
   * Params        : 
  **/
    removeAccessInfoFromLS: function(){
      if(commonJsFuncModule.getAccessInfoFromLS()!==null){
          localStorage.removeItem("tdaccess"); 
      }          
  },
   /**
   * commonjsfunc
   * Function name : callApi
   * Author        :
   * Created Date  : 11-2-2020
   * Purpose       : get response data from api 
   * Params        : jsonObject
  **/
  callApi: async function(jsonObject){        
      let respObj = null;        
      let headerObj = {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${commonJsFuncModule.getTokenFromLocStorage()}`
        };  
      let redirectFlag = jsonObject.redirectFlag?jsonObject.redirectFlag:'YES';
      let hitWithMethod  = jsonObject.hitWithMethod?jsonObject.hitWithMethod:'GET';
      let hitWithApi = commonJsFuncModule.getEndPoint()+jsonObject.hitWithApi;
      let hitWithBody = jsonObject.hitWithBody?jsonObject.hitWithBody:{};
      let hitWithHeader = jsonObject.hitWithHeader?jsonObject.hitWithHeader:headerObj;

      let forFetchJonParam = {};
      forFetchJonParam['method'] = hitWithMethod;
      forFetchJonParam['headers'] = hitWithHeader;
      if(jsonObject.hitWithBody){
          forFetchJonParam['body'] = hitWithBody;
      }

      let apiRespStatusCode = null;
      let apiRespMsg = '';

      await fetch(hitWithApi,
          forFetchJonParam).then(function(response) {
          //console.log("API Response received ====>>>",response.data);
          respObj = response;
          apiRespStatusCode = response.status?response.status: 500; 
          apiRespMsg =  response.statusText? response.statusText: '';            
          
      })
      .catch(function(error) {
            console.log('Some technical problem occurred',error)
    });      

    let respData = await respObj!==null?respObj.json():null;
    let unAuthStatusCode = [401,403];
    if(unAuthStatusCode.indexOf(apiRespStatusCode)!==-1 ){
        commonJsFuncModule.removeTokenFromLocStorage();
      if(redirectFlag=='YES'){
          window.location.href = "/login";
      }                
      respData = new Promise(function(resolve, reject){ 
          resolve(
              {
                  status:apiRespStatusCode, 
                  data:{}, 
                  statusFlag: 'error',
                  message: apiRespMsg            
              }
          );
      });        
      return respData;
      
    }  
    return respData;        
  },
   /**
   * commonjsfunc
   * Function name : getEndPoint
   * Author        :
   * Created Date  : 11-2-2020
   * Purpose       : get end point
   * Params        : 
  **/
  getEndPoint: ()=>{
    //return "http://3.12.228.75:8090/";
    return process.env.REACT_APP_API_ENDPOINT;
  },
   /**
   * commonjsfunc
   * Function name : getEndPoint
   * Author        :
   * Created Date  : 11-2-2020
   * Purpose       : get end point
   * Params        : 
  **/
   getEndPointStatic: ()=>{    
    return process.env.REACT_APP_API_ENDPOINTSTATIC;
  },
  getBaseUrl: ()=>{
    //return "http://3.12.228.75:8090/";
    return process.env.REACT_APP_BASE_URL;
  },
  
  getBaseUrlOfLp: ()=>{
    //return "http://3.12.228.75:8090/";
    return process.env.REACT_APP_BASE_URL_OF_LP;
  },
 getGoogleApiKey: ()=>{
    return process.env.REACT_APP_GOOGLE_API_KEY;
  },
  
  /**
   * commonjsfunc
   * Function name : custEncode
   * Author        :
   * Created Date  : 18-11-2019
   * Purpose       : encoding function
   * Params        : inputStr
  **/
  custEncode: (inputStr)=>{
     return btoa(inputStr);
  },
  /**
   * commonjsfunc
   * Function name : custDecode
   * Author        :
   * Created Date  : 18-11-2019
   * Purpose       : decoding function
   * Params        : inputStr
  **/
  custDecode: function(inputStr){
    return atob(inputStr);
  },
  /**
   * commonjsfunc
   * Function name : getUserInfo
   * Author        :
   * Created Date  : 18-11-2019
   * Purpose       : get User Info from Local Storage
   * Params        : 
  **/
  getUserInfo: ()=>{
        let laluser =  localStorage.getItem("laluser");
        laluser = laluser !== null ? JSON.parse(laluser):null;
        return laluser;
  },
  /**
   * commonjsfunc
   * Function name : getUserInfoBeforeLogin
   * Author        :
   * Created Date  : 14-2-2020
   * Purpose       : get User Info from Local Storage Before login
   * Params        : 
  **/
 getUserInfoBeforeLogin: ()=>{
    let lbluser =  localStorage.getItem("lbluser");
    lbluser = lbluser !== null ? JSON.parse(lbluser):null;
    return lbluser;
},
/**
   * commonjsfunc
   * Function name : removeUserInfoBeforeLogin
   * Author        :
   * Created Date  : 14-2-2020
   * Purpose       : remove before login user info from local storage
   * Params        : 
  **/
 removeUserInfoBeforeLogin: function(){
    if(commonJsFuncModule.getUserInfoBeforeLogin()!==null){
        localStorage.removeItem("lbluser"); 
    }
        
},
/**
   * commonjsfunc
   * Function name : setUserInfoBeforeLogin
   * Author        :
   * Created Date  : 14-2-2020
   * Purpose       : remove before login user info from local storage
   * Params        : 
  **/
 setUserInfoBeforeLogin: function(infoObj){
    localStorage.setItem("lbluser", JSON.stringify(infoObj));
        
},
/**
   * commonjsfunc
   * Function name : getUserEmailBeforeLogin
   * Author        :
   * Created Date  : 14-2-2020
   * Purpose       : get User Email from Local Storage
   * Params        : 
  **/
 getUserEmailBeforeLogin: function(){
    let userObj = commonJsFuncModule.getUserInfoBeforeLogin();
    if(userObj!==null){
        return  userObj.bl_emailid!==null? commonJsFuncModule.custDecode(userObj.bl_emailid): null;
    }
    return null;
  },
  /**
   * commonjsfunc
   * Function name : getUserName
   * Author        :
   * Created Date  : 11-2-2020
   * Purpose       : get User Name from Local Storage
   * Params        : 
  **/
  getUserName: ()=>{
    let userObj = commonJsFuncModule.getUserInfo();
    if(userObj!==null){
        return  userObj.userName!==null? commonJsFuncModule.custDecode(userObj.userName): null;
    }
    return null;
  },
  getCcUserId: ()=>{
    let userObj = commonJsFuncModule.getUserInfo();
    if(userObj!==null){
        return  userObj.userId!==null? commonJsFuncModule.custDecode(userObj.userId): null;
    }
    return null;
  },
  getOrgName: ()=>{
    let userObj = commonJsFuncModule.getUserInfo();
    if(userObj!==null){      
        return  userObj.organisationName!==null? commonJsFuncModule.custDecode(userObj.organisationName): null;
    }
    return null;
  },
  getRole: ()=>{
    let userObj = commonJsFuncModule.getUserInfo();
    if(userObj!==null){      
        return  userObj.role!==null? commonJsFuncModule.custDecode(userObj.role): null;
    }
    return null;
  },
  getDataShouldShowAddUserPopUp: ()=>{
    let userObj = commonJsFuncModule.getUserInfo();
    if(userObj!==null){      
        return  userObj.shouldShowAddUserPopUp!==null? commonJsFuncModule.custDecode(userObj.shouldShowAddUserPopUp): null;
    }
    return null;
  },
  getDataShouldShowOrganisationPopUp: ()=>{
    let userObj = commonJsFuncModule.getUserInfo();
    if(userObj!==null){      
        return  userObj.shouldShowOrganisationPopUp!==null? commonJsFuncModule.custDecode(userObj.shouldShowOrganisationPopUp): null;
    }
    return null;
  },

  isProfileUpdated: ()=>{
    let userObj = commonJsFuncModule.getUserInfo();
    if(userObj!==null){      
        return  userObj.isProfileUpdated!==null? commonJsFuncModule.custDecode(userObj.isProfileUpdated): null;
    }
    return null;
  },
  getOrganisationMaxCapacity: ()=>{
    let userObj = commonJsFuncModule.getUserInfo();
    if(userObj!==null){      
        return  userObj.organisationMaxCapacity!==null? commonJsFuncModule.custDecode(userObj.organisationMaxCapacity): null;
    }
    return null;
  },
  getOrganisationCurrentCapacity: ()=>{
    let userObj = commonJsFuncModule.getUserInfo();
    if(userObj!==null){      
        return  userObj.organisationCurrentCapacity!==null? commonJsFuncModule.custDecode(userObj.organisationCurrentCapacity): null;
    }
    return null;
  },



  updateShouldShowAddUserPopUp: function(data){    
    let userObj = commonJsFuncModule.getUserInfo();
    if(userObj!==null){      
        userObj.shouldShowAddUserPopUp = commonJsFuncModule.custEncode(data);
        localStorage.setItem("laluser", JSON.stringify(userObj));
    }
    
        
  },
  updateShouldShowOrganisationPopUp: function(data){
    let userObj = commonJsFuncModule.getUserInfo();
    if(userObj!==null){      
        userObj.shouldShowOrganisationPopUp = commonJsFuncModule.custEncode(data);
        localStorage.setItem("laluser", JSON.stringify(userObj));
    }    
        
  },
  updateOrganisationName: function(data){
    let userObj = commonJsFuncModule.getUserInfo();
    if(userObj!==null){      
        userObj.organisationName = commonJsFuncModule.custEncode(data);
        localStorage.setItem("laluser", JSON.stringify(userObj));
    }    
        
  },

  updateUserName: function(data){
    let userObj = commonJsFuncModule.getUserInfo();
    if(userObj!==null){      
        userObj.userName = commonJsFuncModule.custEncode(data);
        localStorage.setItem("laluser", JSON.stringify(userObj));
    }    
        
  },
  
  updateUserImage: function(data){
      let userObj = commonJsFuncModule.getUserInfo();
      if(userObj!==null){      
          userObj.userImage = commonJsFuncModule.custEncode(data);
          localStorage.setItem("laluser", JSON.stringify(userObj));
      }    
          
    },
  getUserImage: function(){
    let userObj = commonJsFuncModule.getUserInfo();
    if(userObj!==null){      
        return  userObj.userImage!==null? commonJsFuncModule.custDecode(userObj.userImage): null;
    }
    return null;   
          
  },
  updateTaskViewedStatus: function(data){
    let userObj = commonJsFuncModule.getUserInfo();
    if(userObj!==null){      
        userObj.taskViewedStatus = commonJsFuncModule.custEncode(data);
        localStorage.setItem("laluser", JSON.stringify(userObj));
    }    
        
  },

   getTaskViewedStatus: function(){
    let userObj = commonJsFuncModule.getUserInfo();
    if(userObj!==null){      
        return userObj.taskViewedStatus!==undefined && userObj.taskViewedStatus!==null? commonJsFuncModule.custDecode(userObj.taskViewedStatus): null;
    }
    return null;   
        
  },

  updateTaskViewedId: function(data){
    let userObj = commonJsFuncModule.getUserInfo();
    if(userObj!==null){      
        userObj.taskViewedId = commonJsFuncModule.custEncode(data);
        localStorage.setItem("laluser", JSON.stringify(userObj));
    }    
        
  },

   getTaskViewedId: function(){
    let userObj = commonJsFuncModule.getUserInfo();
    if(userObj!==null){      
        return  userObj.taskViewedId!==undefined && userObj.taskViewedId!==null? commonJsFuncModule.custDecode(userObj.taskViewedId): null;
    }
    return null;   
        
  },






   /**
   * commonjsfunc
   * Function name : getUserEmail
   * Author        :
   * Created Date  : 11-2-2020
   * Purpose       : get User Email from Local Storage
   * Params        : 
  **/
  getUserEmail: ()=>{
    let userObj = commonJsFuncModule.getUserInfo();
    if(userObj!==null){
        return  userObj.userEmailId!==null? commonJsFuncModule.custDecode(userObj.userEmailId): null;
    }
    return null;
  },
  

  /**
     * commonjsfunc
     * Function name : sortArrayOfJsonByKey
     * Author        :
     * Created Date  : 11-2-2020
     * Purpose       : Sort a json array and send json array as response
     * Params        : 
    **/
  sortArrayOfJsonByKey: (attrib,sortFlag)=>{
    sortFlag = (sortFlag)?sortFlag:'ASC';

    return function(a,b){
        if (a[attrib] > b[attrib]){
            if(sortFlag=="ASC"){
                return 1;
            }
            else{
                return -1;
            }
            
        } else if(a[attrib] < b[attrib]){
            if(sortFlag=="ASC"){
                return -1;
            }
            else{
                return 1;
            }
        }
        return 0;
     }
  },

  /**
     * commonjsfunc
     * Function name : sortOnObjectPropertyKey
     * Author        :
     * Created Date  : 11-2-2020
     * Purpose       : Sort json object property key name and send json object as response
     * Params        : 
    **/
  sortOnObjectPropertyKey: (objData,sortFlag)=> {
    var sorted = {};
    let key= 0;
    let ar = [];

    sortFlag = (sortFlag)?sortFlag:'ASC';

    for (key in objData) {
        if (objData.hasOwnProperty(key)) {
            ar.push(key);
        }
    }

    ar.sort();
    if(sortFlag=='DESC'){
      ar.reverse();
    }    

    if(ar.length>0){
        for (key = 0; key < ar.length; key++) {
            sorted[ar[key]] = objData[ar[key]];
        }
    }
    return sorted;
  },

  /**
     * commonjsfunc
     * Function name : getInvalidEmailIdDomain
     * Author        :
     * Created Date  : 14-2-2020
     * Purpose       : Returns array of invalid emailid domain json data
     * Params        : 
    **/
  getInvalidEmailIdDomain: function() {
            let emldata = [
                {
                  "domain": "aol.com"
                },
                {
                  "domain": "att.net"
                },
                {
                  "domain": "comcast.net"
                },
                {
                  "domain": "facebook.com"
                },
                {
                  "domain": "gmail.com"
                },
                {
                  "domain": "gmx.com"
                },
                {
                  "domain": "googlemail.com"
                },
                {
                  "domain": "google.com"
                },
                {
                  "domain": "hotmail.com"
                },
                {
                  "domain": "hotmail.co.uk"
                },
                {
                  "domain": "mac.com"
                },
                {
                  "domain": "me.com"
                },
                {
                  "domain": "mail.com"
                },
                {
                  "domain": "msn.com"
                },
                {
                  "domain": "live.com"
                },
                {
                  "domain": "sbcglobal.net"
                },
                {
                  "domain": "verizon.net"
                },
                {
                  "domain": "yahoo.com"
                },
                {
                  "domain": "yahoo.co.uk"
                },
                {
                  "domain": "email.com"
                },
                {
                  "domain": "fastmail.fm"
                },
                {
                  "domain": "games.com"
                },
                {
                  "domain": "gmx.net"
                },
                {
                  "domain": "hush.com"
                },
                {
                  "domain": "hushmail.com"
                },
                {
                  "domain": "icloud.com"
                },
                {
                  "domain": "iname.com"
                },
                {
                  "domain": "inbox.com"
                },
                {
                  "domain": "lavabit.com"
                },
                {
                  "domain": "love.com"
                },
                {
                  "domain": "outlook.com"
                },
                {
                  "domain": "pobox.com"
                },
                {
                  "domain": "protonmail.ch"
                },
                {
                  "domain": "protonmail.com"
                },
                {
                  "domain": "tutanota.de"
                },
                {
                  "domain": "tutanota.com"
                },
                {
                  "domain": "tutamail.com"
                },
                {
                  "domain": "tuta.io"
                },
                {
                  "domain": "keemail.me"
                },
                {
                  "domain": "rocketmail.com"
                },
                {
                  "domain": "safe-mail.net"
                },
                {
                  "domain": "wow.com"
                },
                {
                  "domain": "ygm.com"
                },
                {
                  "domain": "ymail.com"
                },
                {
                  "domain": "zoho.com"
                },
                {
                  "domain": "yandex.com"
                },
                {
                  "domain": "bellsouth.net"
                },
                {
                  "domain": "charter.net"
                },
                {
                  "domain": "cox.net"
                },
                {
                  "domain": "earthlink.net"
                },
                {
                  "domain": "juno.com"
                },
                {
                  "domain": "btinternet.com"
                },
                {
                  "domain": "virginmedia.com"
                },
                {
                  "domain": "blueyonder.co.uk"
                },
                {
                  "domain": "freeserve.co.uk"
                },
                {
                  "domain": "live.co.uk"
                },
                {
                  "domain": "ntlworld.com"
                },
                {
                  "domain": "o2.co.uk"
                },
                {
                  "domain": "orange.net"
                },
                {
                  "domain": "sky.com"
                },
                {
                  "domain": "talktalk.co.uk"
                },
                {
                  "domain": "tiscali.co.uk"
                },
                {
                  "domain": "virgin.net"
                },
                {
                  "domain": "wanadoo.co.uk"
                },
                {
                  "domain": "bt.com"
                },
                {
                  "domain": "sina.com"
                },
                {
                  "domain": "sina.cn"
                },
                {
                  "domain": "qq.com"
                },
                {
                  "domain": "naver.com"
                },
                {
                  "domain": "hanmail.net"
                },
                {
                  "domain": "daum.net"
                },
                {
                  "domain": "nate.com"
                },
                {
                  "domain": "yahoo.co.jp"
                },
                {
                  "domain": "yahoo.co.kr"
                },
                {
                  "domain": "yahoo.co.id"
                },
                {
                  "domain": "yahoo.co.in"
                },
                {
                  "domain": "yahoo.com.sg"
                },
                {
                  "domain": "yahoo.com.ph"
                },
                {
                  "domain": "163.com"
                },
                {
                  "domain": "yeah.net"
                },
                {
                  "domain": "126.com"
                },
                {
                  "domain": "21cn.com"
                },
                {
                  "domain": "aliyun.com"
                },
                {
                  "domain": "foxmail.com"
                },
                {
                  "domain": "hotmail.fr"
                },
                {
                  "domain": "live.fr"
                },
                {
                  "domain": "laposte.net"
                },
                {
                  "domain": "yahoo.fr"
                },
                {
                  "domain": "wanadoo.fr"
                },
                {
                  "domain": "orange.fr"
                },
                {
                  "domain": "gmx.fr"
                },
                {
                  "domain": "sfr.fr"
                },
                {
                  "domain": "neuf.fr"
                },
                {
                  "domain": "free.fr"
                },
                {
                  "domain": "gmx.de"
                },
                {
                  "domain": "hotmail.de"
                },
                {
                  "domain": "live.de"
                },
                {
                  "domain": "online.de"
                },
                {
                  "domain": "t-online.de"
                },
                {
                  "domain": "web.de"
                },
                {
                  "domain": "yahoo.de"
                },
                {
                  "domain": "libero.it"
                },
                {
                  "domain": "virgilio.it"
                },
                {
                  "domain": "hotmail.it"
                },
                {
                  "domain": "aol.it"
                },
                {
                  "domain": "tiscali.it"
                },
                {
                  "domain": "alice.it"
                },
                {
                  "domain": "live.it"
                },
                {
                  "domain": "yahoo.it"
                },
                {
                  "domain": "email.it"
                },
                {
                  "domain": "tin.it"
                },
                {
                  "domain": "poste.it"
                },
                {
                  "domain": "teletu.it"
                },
                {
                  "domain": "mail.ru"
                },
                {
                  "domain": "rambler.ru"
                },
                {
                  "domain": "yandex.ru"
                },
                {
                  "domain": "ya.ru"
                },
                {
                  "domain": "list.ru"
                },
                {
                  "domain": "hotmail.be"
                },
                {
                  "domain": "live.be"
                },
                {
                  "domain": "skynet.be"
                },
                {
                  "domain": "voo.be"
                },
                {
                  "domain": "tvcablenet.be"
                },
                {
                  "domain": "telenet.be"
                },
                {
                  "domain": "hotmail.com.ar"
                },
                {
                  "domain": "live.com.ar"
                },
                {
                  "domain": "yahoo.com.ar"
                },
                {
                  "domain": "fibertel.com.ar"
                },
                {
                  "domain": "speedy.com.ar"
                },
                {
                  "domain": "arnet.com.ar"
                },
                {
                  "domain": "yahoo.com.mx"
                },
                {
                  "domain": "live.com.mx"
                },
                {
                  "domain": "hotmail.es"
                },
                {
                  "domain": "hotmail.com.mx"
                },
                {
                  "domain": "prodigy.net.mx"
                },
                {
                  "domain": "yahoo.ca"
                },
                {
                  "domain": "hotmail.ca"
                },
                {
                  "domain": "bell.net"
                },
                {
                  "domain": "shaw.ca"
                },
                {
                  "domain": "sympatico.ca"
                },
                {
                  "domain": "rogers.com"
                },
                {
                  "domain": "yahoo.com.br"
                },
                {
                  "domain": "hotmail.com.br"
                },
                {
                  "domain": "outlook.com.br"
                },
                {
                  "domain": "uol.com.br"
                },
                {
                  "domain": "bol.com.br"
                },
                {
                  "domain": "terra.com.br"
                },
                {
                  "domain": "ig.com.br"
                },
                {
                  "domain": "itelefonica.com.br"
                },
                {
                  "domain": "r7.com"
                },
                {
                  "domain": "zipmail.com.br"
                },
                {
                  "domain": "globo.com"
                },
                {
                  "domain": "globomail.com"
                },
                {
                  "domain": "oi.com.br"
                }
              ];
  
            return emldata;
   },
   /**
     * commonjsfunc
     * Function name : checkInvalidEmailIdDomain
     * Author        :
     * Created Date  : 14-2-2020
     * Purpose       : check if invalid emailid domain is present in raw json data
     * Params        : 
    **/
  checkInvalidEmailIdDomain: function(emlDomain) {  
        let foundInvalidEmailDomain = false;  
        let getInvalidEmailIdDomainData  = commonJsFuncModule.getInvalidEmailIdDomain();
        if(emlDomain && getInvalidEmailIdDomainData.length>0){
            
            for(let i=0;i<(getInvalidEmailIdDomainData.length);i++){              
              if(emlDomain == getInvalidEmailIdDomainData[i]['domain']){            
                foundInvalidEmailDomain = true;
                break;
              }
            }

          }
        return foundInvalidEmailDomain;
    
    },
  getStringInitials: function(strData){
    let acronym = "";
    if(strData!==undefined && strData!==null){
       acronym = strData.split(/\s/).reduce((response,word)=> response+=word.slice(0,1),'');
       acronym = acronym.toUpperCase();
    }
    
    return acronym;


  },
  /**
     * commonjsfunc
     * Function name : findElement
     * Author        :
     * Created Date  : 2-4-2020
     * Purpose       : Search a key from within a json array and send that key record 
     * Params        : 
    **/
   findElement :  function(arr, propName, propValue){
    if(arr.length>0){
      for (var i=0; i < arr.length; i++){
        //console.log("propValue=>>",propValue,"****",i," arr[i][propName]=====>>>>",arr[i][propName],"propValue=>>",propValue);
        if (arr[i][propName] == propValue){
         return {"indKey":i,"objData":arr[i]};
         break;
        }
          
      }
      
    }

    return null;      
  },

   /**
     * commonjsfunc
     * Function name : convertMinutueToHour
     * Author        :
     * Created Date  : 20-5-2020
     * Purpose       : Convert minute to hour 
     * Params        : 
    **/
  convertMinutueToHour :  function(num) { 

      let respObj = {"hours":"",
                  "hoursDisplayText1":"",
                  "hoursDisplayText2":""
                };

      if(num!==undefined && num!==null && isNaN(num)==false){
        let hours = Math.floor(num / 60);  
        let minutes = num % 60;

        

        let hrFrmtTxt1 = "";
        let minFrmtTxt1 = "";

        let hrFrmtTxt2 = "";
        let minFrmtTxt2 = "";

        if(hours>=0 && hours<=1){
        hrFrmtTxt1 = "0"+hours+" hr";
        hrFrmtTxt2 = "0"+hours+" Hour";

        }else if(hours>1 && hours<=9){
        hrFrmtTxt1 = "0"+hours+" hrs";
        hrFrmtTxt2 = "0"+hours+" Hours";
        }
        else if(hours>9){
        hrFrmtTxt1 = hours+" hrs";
        hrFrmtTxt2 = hours+" Hours";
        }

        if(minutes>=0 && minutes<=1){
        minFrmtTxt1 = "0"+minutes+" min";
        minFrmtTxt2 = "0"+minutes+" Min";
        }else if(minutes>1 && minutes<=9){
        minFrmtTxt1 = "0"+minutes+" mins";
        minFrmtTxt2 = "0"+minutes+" Mins";
        
        }else if(minutes>9 && minutes<=59){
        minFrmtTxt1 = minutes+" mins";
        minFrmtTxt2 = minutes+" Mins";
        }

        respObj.hours= hours + "." + minutes;
        respObj.hoursDisplayText1= hrFrmtTxt1+" "+minFrmtTxt1; 
        respObj.hoursDisplayText2= hrFrmtTxt2+" "+minFrmtTxt2;

        return respObj;
      }

      return null;

    },
    getFileNameOnly: function(fileName)
    {
      let name =  fileName.substr(0, fileName.lastIndexOf('.'));
      return name;
    },
    validateUrl: function(dataToValidate)
    {
      let regexpData =  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
      let chk = regexpData.test(dataToValidate);
      return chk;
    },
    validateEmail: function(dataToValidate){
      let regexpData =  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      let chk = regexpData.test(dataToValidate);
      return chk;
    },
    validateSpecialCharacterPresence: function(dataToValidate){
      let regexpData =  /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      let chk = regexpData.test(dataToValidate);
      return chk;
    },
    validatePhoneNumberPattern: function(dataToValidate)
    {
        var regexpData = /^[\+]?[0-9]{3}[0-9]{3}[0-9]{4,6}$/;       
        let chk = regexpData.test(dataToValidate);
        return chk;
    },
    validateBlankSpace: function(dataToValidate)
    {
        let regexpData = /^ *$/;      
        let chk = regexpData.test(dataToValidate);
        return chk;
    },
    validatePositiveInputNumber: (other) => {
      let data = other.data!==undefined?(other.data).toString():"";
      let chkV = /(^\d\d*\.?\d{0,2}$)/.test(data);
      return chkV;
    },
    validatePercentInputNumber: (other) => {
      let data = other.data!==undefined?(other.data).toString():"";
      let chkV = /(^(\d\d*){1,2}\.?\d{0,2}$)/.test(data);
      return chkV;
    },
    emailIdPresenceCheck: (data) =>{
      let chk =  data.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
          if(chk===null){
              return false;
          }else{
              return true;
          }
  
    },
    phonePresenceCheck: (data) =>{
       //reference https://voximplant.com/blog/javascript-regular-expression-for-phone-number-verification
       let chk =  data.match(/\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})/gi);
       if(chk===null){
           return false;
       }else{
           return true;
       }
  
    },
    getFormattedAmount: function(dataObj){
      let dataToFormat = dataObj.dataToFormat;
      let formatType = dataObj.formatType;

      if(formatType==1){
          return dataToFormat.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }else{
        return dataToFormat.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

      
    },   
    getAccessObj: ()=>{
      let userObj = commonJsFuncModule.getUserInfo();
      if(userObj!==null){
          let accessObjStr = userObj.accessObj!==null? commonJsFuncModule.custDecode(userObj.accessObj): null;

          if(accessObjStr){
            let accessObj = JSON.parse(accessObjStr);
            return accessObj;
          }

      }
      return null;
    },

    setAccessObj: (data)=>{
      let userObj = commonJsFuncModule.getUserInfo();
      if(userObj!==null){      
        if(data.bulk!==undefined && data.bulk!==null ){
          userObj["accessObj"] = commonJsFuncModule.custEncode(JSON.stringify(data.bulk));
         
        }else{
          if(data.key!==undefined && data.key!==null && data.keyValue!==undefined ){

            let accessObj =  userObj.accessObj!==undefined && userObj.accessObj!==null? JSON.parse(commonJsFuncModule.custDecode(userObj.accessObj)): null;

            if(accessObj){
              accessObj[data.key] = data.keyValue;
              
              accessObj = commonJsFuncModule.custEncode(JSON.stringify(accessObj));             

            }else{
              accessObj = {};
              accessObj[data.key] = data.keyValue;              
              accessObj = commonJsFuncModule.custEncode(JSON.stringify(accessObj)); 

            }
            userObj["accessObj"] = accessObj;            

          }
        }

        localStorage.setItem("laluser", JSON.stringify(userObj));
      }    
    },
    getSiteName: function(){    
      //return 3;
       return process.env.REACT_APP_API_SITENAME;
    },
    getDefaultCountryCode: function(){   
      return "IN";
    },
    getDateFormatForCal: function(paramObj){   
      let defaultCountryCode = commonJsFuncModule.getDefaultCountryCode();
      let countryCode = paramObj.countryCode!==undefined? paramObj.countryCode: defaultCountryCode;
      if(countryCode=="IN"){
        return "dd MMMM, yyyy";
      }else{
        return "dd MMMM, yyyy";
      }
      
    },
    blockConsoleLog : function(paramObj){
      if(paramObj.showFlag!==undefined && paramObj.showFlag==0 ){
        console.log = function() {};
      }
      
    },
    getHjIdSv: ()=>{
      let htarObj = {
        "hjid":process.env.REACT_APP_HOTJAR_HJID,
        "hjsv":process.env.REACT_APP_HOTJAR_HJSV,
      }
      return htarObj;
    },
    hjLoadFlag: ()=>{
      return process.env.REACT_APP_HOTJAR_LOAD;
    },
    getGoogleAnalyticsTrackId: ()=>{
      let trackObj = {
        "trackId":process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKID,     
      }
      return trackObj;
    },
    googleAnalyticsLoadFlag: ()=>{
      return process.env.REACT_APP_GOOGLE_ANALYTICS_LOAD;
      //return "";
    },
   
   

    




};

