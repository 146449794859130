/**
 * TaskAdd
 * Page/Class name   : TaskAdd
 * Author            :
 * Created Date      : 26-2-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : rendering dashboard
**/
import React, { Component, Suspense, lazy} from 'react';
import '../../../custom_resources/css/custom.css';
// Modal
import CustReactBootstrapModal from './CustReactBootstrapModal';


// scroll 
import { Scrollbars } from 'react-custom-scrollbars';

import {commonJsFuncModule as commonJsObj} from  '../../../common_utilities/commonjsfunc';
import {commonStaticOptionsJsFuncModule as commonStaticJsObj} from  '../../../common_utilities/commonstaticoptions';

import DraftDetailFetchService from '../../service/DraftDetailFetchService';
import TypeIdBasedOnCategoryService from '../../service/TypeIdBasedOnCategoryService';

//import {notify} from '../../messagebar/CustomMessageBar';
//import CustomMessageBar from '../../messagebar/CustomMessageBar';

//Newly added
import TaskAddSingleTaskStep1 from './TaskAddSingleTaskStep1.js';
import TaskAddSingleTaskStep2 from './TaskAddSingleTaskStep2.js';
import TaskAddSingleTaskStep3 from './TaskAddSingleTaskStep3.js';

import TaskAddMultiTaskStep1 from './TaskAddMultiTaskStep1.js';
import TaskAddSingleTaskStep2WithSubTask from './TaskAddSingleTaskStep2WithSubTask.js';

// for context
import CommonContext from '../../context/CommonContext';

const custEncode = commonJsObj.custEncode;
const getDateObjFromMilliseconds = commonJsObj.getDateObjFromMilliseconds;
const getTimestampInMillisecondsFromDateObj = commonJsObj.getTimestampInMillisecondsFromDateObj;

const getStaticCurrencies = commonStaticJsObj.getStaticCurrencies;
const getStaticExperiences = commonStaticJsObj.getStaticExperiences;


// const currencyStaticData = [
//     {"key":"INR","value":"INR"},
//     {"key":"EUR","value":"Pound"},
//     {"key":"USD","value":"Dollar"}
// ];
const currencyStaticData = getStaticCurrencies();

// const expStaticData = [
//     {"key":"1-4","value":"1-4"},
//     {"key":"5-10","value":"5-10"},
//     {"key":"11-15","value":"11-15"},
//     {"key":"16-25","value":"16-25"},
//     {"key":"26-50","value":"26-50"},
//     {"key":"50+","value":"50+"},
// ];
const expStaticData = getStaticExperiences();


const defaultSubTaskStaticKeys = {
    "name": "",
    "categoryId": "",
    "typeId": "",
    "budgetCurrency": "INR",
    "budget": "",
    "typeIdOptions": []
};



const defaultStateData = {
    taskTypeFlag: 0,  // 0=>Single Task, 1=> Multi Task flag for multitask
    subTaskFlag: 0, // this flag data to be used when taskTypeFLag=0 for tracking sub task scenario 
    step : 1,
    // single task step one related
    stepOneStLoaderFlag: false,
    stepOneStTaskName: '',
    stepOneStTaskDesc: '',
    stepOneStTaskDueDate: null,
    stepOneStDocuments: [],
    stepOneOffsetHour: null,
    stepOneOffsetMinute: null,

    // single task step two related
    typeOfProfessionalId: '',
    practiceAreasIds: [],
    categoryId:'',
    typeId: '',
    placeIds: [],
    budgetCurrency: 'INR',
    budget: '',
    workExperience: [],
    isOrganisationDetailsMasked: false,
    locSelected:[], // for display purpose
    currencyData: currencyStaticData, // for display purpose
    expData: expStaticData, // for display purpose
    
    // for subtask
    subtaskKeys:[],
    typeOfProfessionalIdSubT: '',
    practiceAreasIdsSubT: [], 
    locSelectedSubT:[], // for display purpose
    placeIdsSubT: [],
    workExperienceSubT: [],
    defaultSubTaskKeys: defaultSubTaskStaticKeys,
    expDataSubT: expStaticData,
    currencyDataSubT: currencyStaticData, // for display purpose
    categoryOptionsSubTStatic: [],
    minimizeFlag: false,

    
    // after task create step 
    taskCreateResp :{},
    taskId: '',
    multiTaskFileId: '',
    


    //multi task step one
    stepOneStDocumentsMt: [],
    typeOfProfessionalIdMt: '',
    practiceAreasIdsMt: [],
    categoryIdMt:'',
    typeIdMt: '',
    placeIdsMt: [],
    workExperienceMt: [],
    isOrganisationDetailsMaskedMt: false,
    locSelectedMt:[], // for display purpose    
    expDataMt: expStaticData, // for display purpose

    //for draft track
    singleTaskWithoutSubFlowD: false,
    singleTaskWithSubFlowD: false,
    multiTaskFlowD: false,
    draftId:"",
    draftIdM:"",   

}


class TaskAdd extends Component {

    // for context
    static contextType = CommonContext; 

    isComponentLoaded = false;
    /**
     * TaskAdd
     * Function name : constructor
     * Author        :
     * Created Date  : 27-2-2020
     * Purpose       : initializing state
     * Params        : props
    **/
    constructor(props) {
        super(props);
        this.state = defaultStateData;     
       
    }

    /**
     * TaskAdd
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 27-2-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentDidMount() {
        this.isComponentLoaded = true;     

        // determine which step to open for draft
        //********* for context starts *****                
        let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;
        //console.log(" From Task Tab Page sharedData===>",sharedData);
        if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){         

            if(sharedData.actionType=="DRAFT" && sharedData.taskId ){
                    //console.log("********* got taskId***********==>>",sharedData.taskId);
                    let paramObjData = {"data":{"draftId":sharedData.taskId}};      
        
                    let lservdrf = new DraftDetailFetchService();
                    lservdrf.getDraftDetail(paramObjData).then(data =>{ 

                        //********* for context starts *****
                        // updating data in context 
                        let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;  
                        if(setShare!==null){
                            setShare({}); 
                        }                  
                        //********* for context ends *****

                        let entityObj  = data.data.entity;
                        let paramData ={};

                        if(entityObj!==undefined){
                                if(entityObj.multiTaskFileDetails!==null){
                                    // multi task
                                    paramData.key = "MT";
                                    paramData.data = entityObj;
                                    this.setDataForDraft(paramData);
                                }
                                else{
                                    if(entityObj.subTasks && entityObj.subTasks.length>0){
                                        // single task with sub task
                                        paramData.key = "STWST";
                                        paramData.data = entityObj;
                                        this.setDataForDraft(paramData);

                                    }else{
                                        // single task without sub task
                                        paramData.key = "ST";
                                        paramData.data = entityObj;
                                        this.setDataForDraft(paramData);
                                    }
                                }
                        }                            
                                             
                    

                    }).catch((e)=>{                       
                        console.log("server error=>",e);
                    }).finally(()=>{
                       
                    });

                
                }
            }
        
            
        
        //********* for context ends *****   

    }

    /**
     * TaskAdd
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 27-2-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {  
        this.isComponentLoaded = false;
    }

    setDataForDraft = (otherParam)=>{
        
        switch(otherParam.key){
            case "ST":
                //for single task without sub task
                this.setSingleTaskWithoutSubTaskFromDraft(otherParam.data);
                break;
            case "STWST":
                //for single task with sub task                
                this.setSingleTaskWithSubTaskFromDraft(otherParam.data);
                break;
            case "MT":
                //for multitask
                this.setMultiTaskFromDraft(otherParam.data);
                break;
            
        }

    }

    setSingleTaskWithoutSubTaskFromDraft = (data) =>{
        // set data for single task without Sub Task

        this.setDraftFlag("draftId",data.id,{}); 

        let otherParam = {"inputFieldName":"stepOneStTaskName","value":data.taskName};
        this.setStatesForStep1ofST("handleChange","",otherParam);

        let otherParam2 = {"inputFieldName":"stepOneStTaskDesc","value":data.taskDescription};
        this.setStatesForStep1ofST("handleChange","",otherParam2);

        let otherParam3 = {};
        let stepOneStTaskDueDate = data.dueDate;
        this.setStatesForStep1ofST("setStepOneStTaskDueDate",stepOneStTaskDueDate,otherParam3);

        let otherParam4 = {"action":"push","bulk":[]};        
        let bulkDocsUploaded  = data.preAssignDocuments;
        otherParam4.bulk = bulkDocsUploaded?bulkDocsUploaded:[];
        this.setStatesForStep1ofST("setStepOneStDocuments","",otherParam4);        

        let otherParam5 = {};        
        let typeOfProfessionalId  = data.typeOfProfessional?data.typeOfProfessional.id:0;        
        this.setStatesForStep2ofST("typeOfProfessionalId",typeOfProfessionalId,otherParam5);

        let otherParam6 = {"action":"push","bulk":[]};        
        let practiceAreas  = data.practiceAreas?data.practiceAreas:[];
        let practiceAreasData = [];
        Object.keys(practiceAreas).map((e,i)=>{
            if(practiceAreas[i]){
                practiceAreasData.push(practiceAreas[i].practiceId);
            }

        });
        otherParam6.bulk = practiceAreasData?practiceAreasData:[];
        this.setStatesForStep2ofST("practiceAreasIds","",otherParam6);

        let otherParam7 = {};        
        let taskCategory  = data.taskCategory?data.taskCategory.id:0;        
        this.setStatesForStep2ofST("categoryId",taskCategory,otherParam7);

        let otherParam8 = {};        
        let categoryType  = data.categoryType?data.categoryType.id:0;        
        this.setStatesForStep2ofST("typeId",categoryType,otherParam8);

        let otherParam9 = {"action":"push","bulk":[], "bulk2":[]};        
        let locations  = data.locations?data.locations:[];
        let locationsData = [];let locationsData2 = [];      
          
        if(locations.length>0){
            Object.keys(locations).map((e,i)=>{
                  
                 if(locations[i]){
                     
                    locationsData.push(locations[i].placeId);
                    locationsData2.push({"placeName":locations[i].cityName,"placeId":locations[i].placeId,"city":locations[i].cityName,
                "state":locations[i].state,"country":locations[i].country,"lat":locations[i].latitude,"lng":locations[i].longitude});
                 }
    
            });
            otherParam9.bulk = locationsData?locationsData:[];
            otherParam9.bulk2 = locationsData2?locationsData2:[];        
            this.setStatesForStep2ofST("placeIds","",otherParam9);
        } 

        let otherParam10 = {"action":"push","bulk":[]};        
        let workExperiences  = data.workExperiences;        
        otherParam10.bulk = workExperiences?workExperiences:[];
        this.setStatesForStep2ofST("workExperience","",otherParam10);

        let otherParam11 = {};        
            let budgetCurrency  = data.budgetCurrency?data.budgetCurrency:"";
            this.setStatesForStep2ofST("budgetCurrency",budgetCurrency,otherParam11);

        setTimeout(()=>{
            let otherParam12 = {};        
            let budget  = data.budget?data.budget:"";
            //console.log(" set this budget==>",budget);
            this.setStatesForStep2ofST("budget",budget,otherParam12);

        },1100);

        let otherParam13 = {};        
            let isOrganisationDetailsMasked  = data.isOrganisationDetailsMasked?data.isOrganisationDetailsMasked:false;
            this.setStatesForStep2ofST("isOrganisationDetailsMasked",isOrganisationDetailsMasked,otherParam13);
        
        // show appropriate screen        
        this.changeTaskType(0,{});
        
    }

    setSingleTaskWithSubTaskFromDraft =  (data) => {
        // set data for single task with sub task  

        this.setDraftFlag("draftId",data.id,{});  

        let otherParam = {"inputFieldName":"stepOneStTaskName","value":data.taskName};
        this.setStatesForStep1ofST("handleChange","",otherParam);

        let otherParam2 = {"inputFieldName":"stepOneStTaskDesc","value":data.taskDescription};
        this.setStatesForStep1ofST("handleChange","",otherParam2);

        let otherParam3 = {};
        let stepOneStTaskDueDate = data.dueDate;
        this.setStatesForStep1ofST("setStepOneStTaskDueDate",stepOneStTaskDueDate,otherParam3);

        let otherParam4 = {"action":"push","bulk":[]};        
        let bulkDocsUploaded  = data.preAssignDocuments;
        otherParam4.bulk = bulkDocsUploaded?bulkDocsUploaded:[];
        this.setStatesForStep1ofST("setStepOneStDocuments","",otherParam4);

        // SubT seperate
        let otherParam5 = {};        
        let typeOfProfessionalId  = data.typeOfProfessional?data.typeOfProfessional.id:0;        
        this.setStatesForSubTask("typeOfProfessionalIdSubT",typeOfProfessionalId,otherParam5);

        let otherParam6 = {"action":"push","bulk":[]};        
        let practiceAreas  = data.practiceAreas?data.practiceAreas:[];
        let practiceAreasData = [];
        Object.keys(practiceAreas).map((e,i)=>{
            practiceAreasData.push(practiceAreas[i].practiceId);

        });
        otherParam6.bulk = practiceAreasData ? practiceAreasData: [];
        this.setStatesForSubTask("practiceAreasIdsSubT","",otherParam6);


        let otherParam7 = {"action":"push","bulk":[], "bulk2":[]};        
        let locations  = data.locations?data.locations:[];
        let locationsData = [];let locationsData2 = [];
        if(locations.length>0){
            Object.keys(locations).map((e,i)=>{
                    
                        if(locations[i]){
                            locationsData.push(locations[i].placeId);
                            locationsData2.push({"placeName":locations[i].city,"placeId":locations[i].placeId});
                        }
                        

                    });
                    otherParam7.bulk = locationsData?locationsData:[];
                    otherParam7.bulk2 = locationsData2?locationsData2:[];        
                    this.setStatesForSubTask("placeIdsSubT","",otherParam7);
        }        

        let otherParam8 = {"action":"push","bulk":[]};        
        let workExperiences  = data.workExperiences?data.workExperiences:[];        
        otherParam8.bulk = workExperiences?workExperiences:[];
        this.setStatesForSubTask("workExperienceSubT","",otherParam8);

        //*** for substasks special tricky and complex code starts
        let otherParam9 = {"action":"push","bulk":[]};        
        let subTasks  = data.subTasks;
        let subTasksData = [];       
        Object.keys(subTasks).map((e,i)=>{
           
             if(subTasks[i]){
                 let subT = {
                    "name": "",
                    "categoryId": "",
                    "typeId": "",
                    "budgetCurrency": "INR",
                    "budget": "",
                    "typeIdOptions": []
                };
                subT.name = subTasks[e].subTaskName?subTasks[e].subTaskName:"";
                subT.categoryId = subTasks[e].category?subTasks[e].category.id:0;
                subT.typeId = subTasks[e].categoryType?subTasks[e].categoryType.id:0;
                subT.budgetCurrency = subTasks[e].budgetCurrency?subTasks[e].budgetCurrency:"";
                subT.budget = subTasks[e].budget?subTasks[e].budget:"";

                subTasksData.push(subT);
             }
            

        });

        let requests = Object.keys(subTasksData).map((e,i)=>{
            return this.getTypesIdsFromCategoryService(subTasksData[e].categoryId); 
        });

        Promise.all(requests)
            .then(body => { 
            body.forEach((res,i) => {
            if (res)                               
                subTasksData[i].typeIdOptions = res.resp;
            })
        });

        
        otherParam9.bulk = subTasksData?subTasksData:[];               
        this.setStatesForSubTask("setSubTaskKeyData","",otherParam9);
        //*** for substasks special tricky and complex code ends
        
        

        // to tag this task has subtask
        this.changeSubTaskFlag(1,{});
        // show appropriate screen        
        this.changeTaskType(0,{});

    }

    setMultiTaskFromDraft = (data)=>{
        // set data for multi task
       


        // show appropriate screen
        // let otherParam = {};
        // this.changeTaskType(1,otherParam);
    }

    async getTypesIdsFromCategoryService(categId){
        
            let respData = {"resp":[]};
            let tpserv = new TypeIdBasedOnCategoryService();        
            let resp =  await tpserv.getTypeIdsBasedOnCategory(categId).then(data =>{
            if(data.status==200){

                if(data.data.entity!==undefined){                 
                    respData.resp = data.data.entity;                

                }           
            }
            }).catch((e)=>{
                ////console.log.log(" Something went wrong with api server=>",e);
            });

            return respData;
       
        
    }



    changeTaskType = (taskTypeFlag,otherParam) =>{
        this.isComponentLoaded && this.setState({"taskTypeFlag":taskTypeFlag});
    }

    changeSubTaskFlag = (subTaskFlag,otherParam) =>{
        this.isComponentLoaded && this.setState({"subTaskFlag":subTaskFlag});
    }

    nextStep = (otherParam)=>{
        //////console.log(" called me otherParam and otherParam=>",otherParam);
        let bypassToStepFlag = otherParam.bypassToStepFlag!==undefined?otherParam.bypassToStepFlag:false;
        let bypassToStep = otherParam.bypassToStep!==undefined?otherParam.bypassToStep:null;
        if(bypassToStepFlag){
                // will be useful for multi task flow                
                this.isComponentLoaded &&  this.setState({
                    step: bypassToStep
                },()=>{
                    //////console.log("Now bypass  step=>",this.state.step);
                });
        }else{

             // will be useful for single task flow   
             const {step} = this.state;
                this.isComponentLoaded &&  this.setState({
            step: step+1
            },()=>{
                //////console.log("Now next step=>",this.state.step);
            });
        }
        
    }

    prevStep = (otherParam)=>{

        let bypassToStepFlag = otherParam.bypassToStepFlag!==undefined?otherParam.bypassToStepFlag:false;
        let bypassToStep = otherParam.bypassToStep!==undefined?otherParam.bypassToStep:null;
        if(bypassToStepFlag){
                // will be useful for multi task flow                
                this.isComponentLoaded &&  this.setState({
                    step: bypassToStep
                },()=>{
                    //////console.log("Now bypass  step=>",this.state.step);
                });
        }else{

             // will be useful for single task flow   
            const {step} = this.state;
            this.isComponentLoaded &&  this.setState({
            step: step-1
            },()=>{
                //////console.log("Now prev step=>",this.state.step);
            });
        }
        
    }    

    cancelButtonAction = () =>{
        this.props.history.push("/tasks");
    }  
    

    // For screen ST screeen 1
    setStatesForStep1ofST = (stateKeyName,stateKeyValue, otherParam) =>{
        this.setDraftFlag("singleTaskWithoutSubFlowD",true,{});        
        switch(stateKeyName){
            case "setStepOneStDocuments":

                if(otherParam.action=="push"){

                    if(otherParam.bulk!==undefined){
                        let prevDoc = [];
                        let docs = otherParam.bulk;
                        Object.keys(docs).map(function(key, index) { 
                        // convert server response of file size from mb to bytes 
                        let fileSize = (docs[key].fileSize)?(docs[key].fileSize)*1024*1024:0;            
                        prevDoc.push({"id":docs[key].id,"name":docs[key].name,"size":fileSize})
                        });
                        this.isComponentLoaded &&  this.setState({"stepOneStDocuments":prevDoc});


                    }else{
                        let prevDoc = [...this.state.stepOneStDocuments];
                        let docs = stateKeyValue;
                        Object.keys(docs).map(function(key, index) { 
                        // convert server response of file size from mb to bytes 
                        let fileSize = (docs[key].fileSize)?(docs[key].fileSize)*1024*1024:0;            
                        prevDoc.push({"id":docs[key].id,"name":docs[key].name,"size":fileSize})
                        });
                        this.isComponentLoaded &&  this.setState({"stepOneStDocuments":prevDoc});

                    }
                    

              
                }else  if(otherParam.action=="remove"){
                    
                    let valuesData = [...this.state.stepOneStDocuments]; 
                    let indx = stateKeyValue;       
                    valuesData.splice(indx,1);        
                    this.isComponentLoaded &&  this.setState({"stepOneStDocuments":valuesData});
                }                
                break;   
            
            case "handleChange":
                let inputName = otherParam.inputFieldName;
                let valueData = otherParam.value;

                this.isComponentLoaded &&  this.setState({[inputName]:valueData});
                break;

            case "setStepOneStTaskDueDate":
                let dateData = stateKeyValue;
                this.isComponentLoaded &&  this.setState({"stepOneStTaskDueDate":dateData});
                break;
            case "setStepOneStTaskOffsets":
                let offsetDataObj = stateKeyValue;
                this.isComponentLoaded &&  this.setState({"stepOneOffsetHour": offsetDataObj.hr,
                "stepOneOffsetMinute": offsetDataObj.min});
                break;
        }
    }

    

    // For screen ST screeen 2
    setStatesForStep2ofST = (stateKeyName,stateKeyValue, otherParam) =>{
        this.setDraftFlag("singleTaskWithoutSubFlowD",true,{});
        
        switch(stateKeyName){
            case "typeOfProfessionalId":
                let step2defdata1 = {
                    "typeOfProfessionalId":stateKeyValue,
                    "practiceAreasIds": [],
                    "categoryId":'',
                    "typeId": '',
                    "budgetCurrency": 'INR',
                    "budget": '',                    
                    "isOrganisationDetailsMasked": false
                };
                this.isComponentLoaded && this.setState(step2defdata1,()=>{
                    ////console.log("==Now state===>>", this.state);
                });
                break;
            case "practiceAreasIds":
                let practiceAreasIds = [...this.state.practiceAreasIds];
                let step2defdata2 = {                    
                    "practiceAreasIds": [],
                    "categoryId":'',
                    "typeId": '',
                    "budgetCurrency": 'INR',
                    "budget": '',                   
                    "isOrganisationDetailsMasked": false
                };
                if(otherParam.action=="push"){
                    if(otherParam.bulk!==undefined){                          
                        step2defdata2.practiceAreasIds = otherParam.bulk;                  
                        this.isComponentLoaded && this.setState(step2defdata2);
                    }else{
                        practiceAreasIds.push(stateKeyValue.id);  
                        step2defdata2.practiceAreasIds = practiceAreasIds;                  
                        this.isComponentLoaded && this.setState(step2defdata2);
                    }
                    
                }else  if(otherParam.action=="remove"){
                    let freshPracticeAreasIds = [];
                    practiceAreasIds.map((e,i)=>{
                            if(e!==stateKeyValue.id){
                                freshPracticeAreasIds.push(e);
                            }

                            step2defdata2.practiceAreasIds = freshPracticeAreasIds;
                            this.isComponentLoaded && this.setState(step2defdata2);
                    });



                }
                
                break;
            case "categoryId":
                let step2defdata3 = {                    
                    "categoryId":stateKeyValue,
                    "typeId": '',
                    "budgetCurrency": 'INR',
                    "budget": '',
                    "isOrganisationDetailsMasked": false
                };
                this.isComponentLoaded && this.setState(step2defdata3);
                break;
            case "typeId":                
                this.isComponentLoaded && this.setState(
                    {"typeId":stateKeyValue,"budgetCurrency": 'INR',
                "budget": '',
                "isOrganisationDetailsMasked": false});
                break; 
            case "placeIds":
                let step2defdata5 = {                    
                    "placeIds": []
                    
                };                 
                     
                let locSelectedOld = [...this.state.locSelected];
                let placeIdsOld = [...this.state.placeIds];
                ////console.log(" 1 earlier==placeIdsOld>>",placeIdsOld);


                if(otherParam.action=="push"){
                    if(otherParam.bulk!==undefined){                          
                                      
                        this.isComponentLoaded && this.setState({"placeIds":otherParam.bulk,
                        "locSelected":otherParam.bulk2
                        });

                    }else{
                        placeIdsOld.push(stateKeyValue.placeId);  
                        locSelectedOld.push(stateKeyValue);                           
                        this.isComponentLoaded && this.setState({"placeIds":placeIdsOld,
                        "locSelected":locSelectedOld
                        });
                    }

                }else  if(otherParam.action=="remove"){
                    let freshlocSelected = [];
                    let freshlplaceId = [];
                    locSelectedOld.map((indiLocObj)=>{
                            
                            if(indiLocObj.placeId!==stateKeyValue.placeId){
                                freshlocSelected.push(indiLocObj);
                                freshlplaceId.push(indiLocObj.placeId);

                            }
                              
                    });

                    this.isComponentLoaded && this.setState({"placeIds":freshlplaceId,
                                "locSelected":freshlocSelected
                            });

                }                
                break;   
            case "budgetCurrency":
                this.isComponentLoaded && this.setState({"budgetCurrency":stateKeyValue,"budget":""});
                break;
            case "budget":
                this.isComponentLoaded && this.setState({"budget":stateKeyValue});
                break;
            case "workExperience":
                let workExperience = [...this.state.workExperience];
                if(otherParam.action=="push"){
                   
                    if(otherParam.bulk!==undefined){                         
                        
                        this.isComponentLoaded && this.setState({"workExperience":otherParam.bulk});
                    }
                    else{
                        workExperience.push(stateKeyValue.key);                              
                        this.isComponentLoaded && this.setState({"workExperience":workExperience});
                        
                    }


                }else  if(otherParam.action=="remove"){
                    let freshworkExperience = [];
                    workExperience.map((e,i)=>{
                            if(e!==stateKeyValue.key){
                                freshworkExperience.push(e);
                            }                            
                            
                    });
                    this.isComponentLoaded && this.setState({"workExperience":freshworkExperience,"isOrganisationDetailsMasked": false});

                }
                //this.isComponentLoaded && this.setState({"workExperience":stateKeyValue});
                break;
            case "isOrganisationDetailsMasked":
                this.isComponentLoaded && this.setState({"isOrganisationDetailsMasked":stateKeyValue});
                break;

        }
    }

    // For screen SubT screeen 2
    setStatesForSubTask = (stateKeyName,stateKeyValue, otherParam) =>{
        
        
        
        switch(stateKeyName){
            case "typeOfProfessionalIdSubT":
                this.setDraftFlag("singleTaskWithSubFlowD",true,{});
                let step2defdata1 = {
                    "typeOfProfessionalIdSubT":stateKeyValue,
                    "practiceAreasIdSubTs": [],                    
                };
                this.isComponentLoaded && this.setState(step2defdata1,()=>{   
                    //console.log("setted typeOfProfessionalIdSubT=>",this.state.typeOfProfessionalIdSubT);           
                });
                break;
            case "practiceAreasIdsSubT":
                this.setDraftFlag("singleTaskWithSubFlowD",true,{});
                let practiceAreasIds = [...this.state.practiceAreasIdsSubT];
                let step2defdata2 = {                    
                    "practiceAreasIdsSubT": []
                };
                if(otherParam.action=="push"){
                    if(otherParam.bulk!==undefined){                          
                        step2defdata2.practiceAreasIdsSubT = otherParam.bulk;                  
                        this.isComponentLoaded && this.setState(step2defdata2);
                    }else{
                        practiceAreasIds.push(stateKeyValue.id);  
                        step2defdata2.practiceAreasIdsSubT = practiceAreasIds;                  
                        this.isComponentLoaded && this.setState(step2defdata2);
                    }
                }else  if(otherParam.action=="remove"){
                    let freshPracticeAreasIds = [];
                    practiceAreasIds.map((e,i)=>{
                            if(e!==stateKeyValue.id){
                                freshPracticeAreasIds.push(e);
                            }

                            step2defdata2.practiceAreasIdsSubT = freshPracticeAreasIds;
                            this.isComponentLoaded && this.setState(step2defdata2);
                    });
                }
                
                break;
            
            case "placeIdsSubT":
                this.setDraftFlag("singleTaskWithSubFlowD",true,{});
                let step2defdata5 = {                    
                    "placeIdsSubT": []
                    
                };      
                     
                let locSelectedOld = [...this.state.locSelectedSubT];
                let placeIdsOld = [...this.state.placeIdsSubT];
                ////console.log(" 1 earlier==placeIdsOld>>",placeIdsOld);


                if(otherParam.action=="push"){
                    if(otherParam.bulk!==undefined){              
                        this.isComponentLoaded && this.setState({"placeIdsSubT":otherParam.bulk,
                        "locSelectedSubT":otherParam.bulk2
                        });

                    }else{
                        placeIdsOld.push(stateKeyValue.placeId);  
                        locSelectedOld.push(stateKeyValue);                           
                        this.isComponentLoaded && this.setState({"placeIdsSubT":placeIdsOld,
                        "locSelectedSubT":locSelectedOld
                        });
                    }

                }else  if(otherParam.action=="remove"){
                    ////console.log(" ==stateKeyValue==>",stateKeyValue)
                    let freshlocSelected = [];
                    let freshlplaceId = [];
                    locSelectedOld.map((indiLocObj)=>{
                            
                            if(indiLocObj.placeId!==stateKeyValue.placeId){
                                freshlocSelected.push(indiLocObj);
                                freshlplaceId.push(indiLocObj.placeId);

                            }
                              
                    });

                    this.isComponentLoaded && this.setState({"placeIdsSubT":freshlplaceId,
                                "locSelectedSubT":freshlocSelected
                            });

                }                
                break;           
            case "workExperienceSubT":
                this.setDraftFlag("singleTaskWithSubFlowD",true,{});
                let workExperience = [...this.state.workExperienceSubT];
                if(otherParam.action=="push"){

                    if(otherParam.bulk!==undefined){                     
                                                 
                        this.isComponentLoaded && this.setState({"workExperienceSubT":otherParam.bulk});
                    }
                    else{
                        workExperience.push(stateKeyValue.key);                              
                        this.isComponentLoaded && this.setState({"workExperienceSubT":workExperience});
                    }


                }else  if(otherParam.action=="remove"){
                    let freshworkExperience = [];
                    workExperience.map((e,i)=>{
                            if(e!==stateKeyValue.key){
                                freshworkExperience.push(e);
                            }                            
                            
                    });
                    this.isComponentLoaded && this.setState({"workExperienceSubT":freshworkExperience});

                }                
                break;  
            case "addSubTaskEntry":
                //console.log(" MA tara====");
                this.isComponentLoaded &&  this.setState(prevState => ({ subtaskKeys: [...prevState.subtaskKeys, stateKeyValue]}),()=>{
                    // logic for callback if any    
                    ////console.log("== after setting subtaskKeys",this.state.subtaskKeys);   
                    if(this.state.subtaskKeys.length!=1){
                        this.setDraftFlag("singleTaskWithSubFlowD",true,{});
                    }  
                          
                });
                break;
            case "removeSubTaskEntry":
                this.setDraftFlag("singleTaskWithSubFlowD",true,{});
                let valuesData = [...this.state.subtaskKeys];
                ////console.log("remove this key pos=>",stateKeyValue," from =>",valuesData);
                valuesData.splice(stateKeyValue,1);
                this.isComponentLoaded && this.setState({"subtaskKeys": valuesData },()=>{
                    ////console.log(" after removal=>>",this.state.subtaskKeys);
                });
                break;
            case "setSubTaskKeyData":
                this.setDraftFlag("singleTaskWithSubFlowD",true,{});

                if(otherParam.bulk!==undefined){  
                
                    this.isComponentLoaded && this.setState({ "subtaskKeys":otherParam.bulk },()=>{
                        
                    });

                }
                else{
                     // 1. Make a shallow copy of the items
                    let items = [...this.state.subtaskKeys];
                    // 2. Make a shallow copy of the item you want to mutate
                    let item = {...items[stateKeyValue.keyIndex]};                
                    // 3. Replace the property you're intested in                
                    if(stateKeyValue.keyName=="name"){
                        item.name = stateKeyValue.keyValue;
                    }

                    if(stateKeyValue.keyName=="categoryId"){
                        item.categoryId = stateKeyValue.keyValue;
                    }

                    if(stateKeyValue.keyName=="typeIdOptions"){
                        item.typeIdOptions = stateKeyValue.keyValue;
                    }

                    if(stateKeyValue.keyName=="typeId"){
                        item.typeId = stateKeyValue.keyValue;
                    }

                    if(stateKeyValue.keyName=="budget"){
                        item.budget = stateKeyValue.keyValue;
                    }

                    if(stateKeyValue.keyName=="budgetCurrency"){
                        item.budgetCurrency = stateKeyValue.keyValue;
                        //console.log(" item.budgetCurrency==>",item.budgetCurrency,stateKeyValue.keyValue)
                    }

                    //console.log(" items==>>", items," stateKeyValue.keyIndex=>",stateKeyValue.keyIndex," item=>",item);

                    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
                    items[stateKeyValue.keyIndex] = item;
                        
                    this.isComponentLoaded && this.setState({ "subtaskKeys":items },()=>{
                        //console.log(" Now state is=>", this.state.subtaskKeys);
                    });
                }
                break;
            case "categoryOptionsSubTStatic":
                this.isComponentLoaded && this.setState({ "categoryOptionsSubTStatic":stateKeyValue },()=>{
                });
                break;
            case "minimizeFlag":
                this.isComponentLoaded && this.setState({ "minimizeFlag":stateKeyValue },()=>{
                });
                break;
            case "resetSubTaskEntry":
                this.setDraftFlag("singleTaskWithSubFlowD",true,{});
                this.isComponentLoaded &&  this.setState({ subtaskKeys: [otherParam.freshEntry]},()=>{       
                });
                break;


        }
    }

    // For screen ST screeen 3
    setStatesForStep3ofTaskCreation = (stateKeyName,stateKeyValue, otherParam) =>{

        switch(stateKeyName){
            case "taskCreateResp":
                this.isComponentLoaded && this.setState({"taskCreateResp":stateKeyValue,"taskId":stateKeyValue.taskId,"multiTaskFileId":stateKeyValue.multiTaskFileId},()=>{
                    // ////console.log(" HEYAAA full state data==>", this.state);
                });
                break;
            
        }
        
    }

     // For screen MT screeen 1
     setStatesForStep1ofMT = (stateKeyName,stateKeyValue, otherParam) =>{
        
        this.setDraftFlag("multiTaskFlowD",true,{});
        switch(stateKeyName){
            case "setStepOneStDocumentsMt":

                if(otherParam.action=="push"){

                    let prevDoc = [...this.state.stepOneStDocumentsMt];
                    let docs = stateKeyValue;
                    Object.keys(docs).map(function(key, index) { 
                    // convert server response of file size from mb to bytes 
                    let fileSize = (docs[key].fileSize)?(docs[key].fileSize)*1024*1024:0;            
                    prevDoc.push({"id":docs[key].id,"name":docs[key].name,"size":fileSize})
                    });
                    this.isComponentLoaded &&  this.setState({"stepOneStDocumentsMt":prevDoc});

              
                }else  if(otherParam.action=="remove"){
                    
                    let valuesData = [...this.state.stepOneStDocumentsMt]; 
                    let indx = stateKeyValue.indx;       
                    valuesData.splice(indx,1);        
                    this.isComponentLoaded &&  this.setState({"stepOneStDocumentsMt":valuesData});
                }                
                break;   
            case "typeOfProfessionalIdMt":
                let step2defdata1 = {
                    "typeOfProfessionalIdMt":stateKeyValue,
                    "practiceAreasIdsMt": [],
                    "categoryIdMt":'',
                    "typeIdMt": '',                                        
                    "isOrganisationDetailsMaskedMt": false
                };
                this.isComponentLoaded && this.setState(step2defdata1,()=>{
                    ////console.log("==Now state===>>", this.state);
                });
                break;
            case "practiceAreasIdsMt":
                let practiceAreasIds = [...this.state.practiceAreasIds];
                let step2defdata2 = {                    
                    "practiceAreasIdsMt": [],
                    "categoryIdMt":'',
                    "typeIdMt": '',                                     
                    "isOrganisationDetailsMaskedMt": false
                };
                if(otherParam.action=="push"){
                    if(otherParam.bulk!==undefined){
                         
                        step2defdata2.practiceAreasIdsMt = otherParam.bulk;
                        this.isComponentLoaded && this.setState(step2defdata2);

                    }else{
                        practiceAreasIds.push(stateKeyValue.id);  
                        step2defdata2.practiceAreasIdsMt = practiceAreasIds;                  
                        this.isComponentLoaded && this.setState(step2defdata2);
                    }
                    
                }else  if(otherParam.action=="remove"){
                    let freshPracticeAreasIds = [];
                    practiceAreasIds.map((e,i)=>{
                            if(e!==stateKeyValue.id){
                                freshPracticeAreasIds.push(e);
                            }

                            step2defdata2.practiceAreasIdsMt = freshPracticeAreasIds;
                            this.isComponentLoaded && this.setState(step2defdata2);
                    });



                }
                
                break;
            case "categoryIdMt":
                let step2defdata3 = {                    
                    "categoryIdMt":stateKeyValue,
                    "typeIdMt": '',
                    "isOrganisationDetailsMaskedMt": false
                };
                this.isComponentLoaded && this.setState(step2defdata3);
                break;
            case "typeIdMt":                
                this.isComponentLoaded && this.setState(
                    {"typeIdMt":stateKeyValue,
                "isOrganisationDetailsMaskedMt": false});
                break; 
            case "placeIdsMt":
                let step2defdata5 = {                    
                    "placeIdsMt": []
                    
                };                 
                     
                let locSelectedOld = [...this.state.locSelectedMt];
                let placeIdsOld = [...this.state.placeIdsMt];
                ////console.log(" 1 earlier==placeIdsOld>>",placeIdsOld);


                if(otherParam.action=="push"){
                    placeIdsOld.push(stateKeyValue.placeId);  
                    locSelectedOld.push(stateKeyValue);                           
                    this.isComponentLoaded && this.setState({"placeIdsMt":placeIdsOld,
                    "locSelectedMt":locSelectedOld
                });

                }else  if(otherParam.action=="remove"){
                    let freshlocSelected = [];
                    let freshlplaceId = [];
                    locSelectedOld.map((indiLocObj)=>{
                            
                            if(indiLocObj.placeId!==stateKeyValue.placeId){
                                freshlocSelected.push(indiLocObj);
                                freshlplaceId.push(indiLocObj.placeId);

                            }
                              
                    });

                    this.isComponentLoaded && this.setState({"placeIdsMt":freshlplaceId,
                                "locSelectedMt":freshlocSelected
                            });

                }                
                break;           
            case "workExperienceMt":
                let workExperience = [...this.state.workExperience];
                if(otherParam.action=="push"){
                    workExperience.push(stateKeyValue.key);                              
                    this.isComponentLoaded && this.setState({"workExperienceMt":workExperience});
                }else  if(otherParam.action=="remove"){
                    let freshworkExperience = [];
                    workExperience.map((e,i)=>{
                            if(e!==stateKeyValue.key){
                                freshworkExperience.push(e);
                            }                            
                            
                    });
                    this.isComponentLoaded && this.setState({"workExperienceMt":freshworkExperience,"isOrganisationDetailsMaskedMt": false});

                }
                //this.isComponentLoaded && this.setState({"workExperience":stateKeyValue});
                break;
            case "isOrganisationDetailsMaskedMt":
                this.isComponentLoaded && this.setState({"isOrganisationDetailsMaskedMt":stateKeyValue});
                break;

            
            
        }
    }

    // For draft flag  set
    setDraftFlag = (stateKeyName,stateKeyValue, otherParam) => {

        switch(stateKeyName){            
            case "singleTaskWithoutSubFlowD":
                this.isComponentLoaded &&  this.setState({
                    "singleTaskWithoutSubFlowD":stateKeyValue,
                    
                });
                break;
            case "singleTaskWithSubFlowD":
                this.isComponentLoaded &&  this.setState({
                    
                    "singleTaskWithSubFlowD": stateKeyValue,
                    
                });
                break;
            case "multiTaskFlowD":
                this.isComponentLoaded &&  this.setState({
                    
                    "multiTaskFlowD": stateKeyValue
                });
                break;
            case "draftId":
                 this.isComponentLoaded &&  this.setState({                    
                    "draftId": stateKeyValue
                });
                break;
            case "draftIdM":
                 this.isComponentLoaded &&  this.setState({                    
                    "draftIdM": stateKeyValue
                });
                break;
        }
    }

    /**
     * TaskAdd
     * Function name : render
     * Author        :
     * Created Date  : 27-2-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() {       
        const {step,singleTaskWithoutSubFlowD,singleTaskWithSubFlowD,multiTaskFlowD,draftId,draftIdM} = this.state;
        //////console.log("this step is matched with switch case=>>",step);
        const { 
        // task type
        taskTypeFlag,
        // subtask flag
        subTaskFlag,
        // step 1 ST
        stepOneStLoaderFlag,stepOneStTaskName,stepOneStTaskDesc,stepOneStDocuments,stepOneStTaskDueDate,stepOneOffsetHour,stepOneOffsetMinute,
        // step 2 ST
        typeOfProfessionalId,practiceAreasIds,categoryId,typeId,placeIds,budgetCurrency,budget,workExperience,isOrganisationDetailsMasked,locSelected,currencyData,expData,
        // step 3 ST
        taskCreateResp,taskId,multiTaskFileId,

        // step 1 MT
        stepOneStDocumentsMt,typeOfProfessionalIdMt,practiceAreasIdsMt,categoryIdMt,typeIdMt,placeIdsMt,workExperienceMt,isOrganisationDetailsMaskedMt,locSelectedMt,expDataMt,

        // sub task
        subtaskKeys,typeOfProfessionalIdSubT,practiceAreasIdsSubT,locSelectedSubT,placeIdsSubT,workExperienceSubT,defaultSubTaskKeys,expDataSubT,categoryOptionsSubTStatic,minimizeFlag,currencyDataSubT
        } = this.state;

        // step 1 ST
        const valuesStepOneSt = { taskTypeFlag,subTaskFlag,stepOneStLoaderFlag,stepOneStTaskName,stepOneStTaskDesc,stepOneStDocuments,stepOneStTaskDueDate,stepOneOffsetHour,stepOneOffsetMinute,step,singleTaskWithoutSubFlowD,draftId};
        // step 2 ST
        const valuesStepTwoSt = { taskTypeFlag,subTaskFlag,typeOfProfessionalId,practiceAreasIds,categoryId,typeId,placeIds,budgetCurrency,budget,workExperience,isOrganisationDetailsMasked,locSelected,currencyData,expData,step,singleTaskWithoutSubFlowD,draftId};
        // step 3 ST
        const valuesStepThreeSt = { taskTypeFlag,subTaskFlag,taskCreateResp,taskId,multiTaskFileId,step };

         // step 1 MT
         const valuesStepOneMt = { taskTypeFlag,subTaskFlag,stepOneStDocumentsMt,typeOfProfessionalIdMt,practiceAreasIdsMt,categoryIdMt,typeIdMt,placeIdsMt,workExperienceMt,isOrganisationDetailsMaskedMt,locSelectedMt,expDataMt,step,multiTaskFlowD,draftIdM};

        
         // sub task
         const valuesSubTask = { subtaskKeys,typeOfProfessionalIdSubT,practiceAreasIdsSubT,locSelectedSubT,placeIdsSubT,workExperienceSubT,defaultSubTaskKeys,taskTypeFlag,subTaskFlag,expDataSubT,categoryOptionsSubTStatic,minimizeFlag,currencyDataSubT,step,singleTaskWithSubFlowD,draftId}

        //////console.log(" Now step for switch case =>>", step);
        
        
        switch(step){
            case 1:

                if(this.state.taskTypeFlag==0){
                    // for single task
                        return (
                    <TaskAddSingleTaskStep1 
                        nextStep = {this.nextStep}                        
                        values = {valuesStepOneSt}
                        valuesSecP = {valuesStepTwoSt}
                        valuesSubT = {valuesSubTask}
                        

                        cancelButtonAction = {this.cancelButtonAction} 
                        setStatesForStep1ofST = {this.setStatesForStep1ofST}
                        changeTaskType = {this.changeTaskType}
                        setDraftFlag = {this.setDraftFlag}
                    
                    />
                    )
                }
                else if(this.state.taskTypeFlag==1){
                    // for multi task
                        return (
                    <TaskAddMultiTaskStep1 
                        nextStep = {this.nextStep}                        
                        values = {valuesStepOneMt}
                        cancelButtonAction = {this.cancelButtonAction} 
                        setStatesForStep1ofMT = {this.setStatesForStep1ofMT}
                        changeTaskType = {this.changeTaskType}
                        setStatesForStep3ofTaskCreation = {this.setStatesForStep3ofTaskCreation}
                        setDraftFlag = {this.setDraftFlag}
                    
                    />
                    )
                }
                break;
                
            case 2:

                if(this.state.taskTypeFlag==0 && this.state.subTaskFlag==0){ 
                // if single task with no sub task
                return (
                    <TaskAddSingleTaskStep2 
                        nextStep = {this.nextStep}
                        prevStep = {this.prevStep}
                        setStatesForStep2ofST = {this.setStatesForStep2ofST}
                        setStatesForStep3ofTaskCreation = {this.setStatesForStep3ofTaskCreation}
                        values0 = {valuesStepOneSt}
                        values = {valuesStepTwoSt}
                        cancelButtonAction = {this.cancelButtonAction}
                        changeSubTaskFlag = {this.changeSubTaskFlag}
                        setDraftFlag = {this.setDraftFlag}
                    
                    />
                    )

                }else if(this.state.taskTypeFlag==0 && this.state.subTaskFlag==1){
                    // if single task with  sub task
                        return (
                    <TaskAddSingleTaskStep2WithSubTask 
                        nextStep = {this.nextStep}
                        prevStep = {this.prevStep}
                        setStatesForSubTask = {this.setStatesForSubTask}   
                        setStatesForStep3ofTaskCreation = {this.setStatesForStep3ofTaskCreation}       
                        values0 = {valuesStepOneSt}
                        values = {valuesSubTask}
                        cancelButtonAction = {this.cancelButtonAction}
                        changeSubTaskFlag = {this.changeSubTaskFlag}
                        setDraftFlag = {this.setDraftFlag}

                        
                    
                    />
                    )
                }
                break;
                
            case 3:
                return (
                    <TaskAddSingleTaskStep3 
                    nextStep = {this.nextStep}
                    prevStep = {this.prevStep}
                    cancelButtonAction = {this.cancelButtonAction}
                    values0 = {valuesStepTwoSt} 
                    valuesMt = {valuesStepOneMt}                                          
                    values = {valuesStepThreeSt}
                                                     
                    setStatesForStep3ofTaskCreation = {this.setStatesForStep3ofTaskCreation}
                    
                    />
                )    
        }       
    
    }    

  }
  export default TaskAdd;