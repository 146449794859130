/**
 * SubscriptionPayment
 * Page/Class name   : SubscriptionPayment
 * Author            :
 * Created Date      : 30-6-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : render master component
**/
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

// for realtime
// import SockJS from 'sockjs-client';

// for context
import CommonContext from '../../context/CommonContext';

// Services
import VirtualAccountBalanceService from '../../service/VirtualAccountBalanceService';
import MarkInvoicePaidService from '../../service/MarkInvoicePaidService';

//import  CustDate  from '../../../common_utilities/CustDate';
import { commonJsFuncModule as commonJsObj } from '../../../common_utilities/commonjsfunc';

import {notify} from '../../messagebar/CustomMessageBar';
import CustomMessageBar from '../../messagebar/CustomMessageBar';
import VbaCommonPart from './VbaCommonPart';
// common noti track
import NotiEmitTrack from "./NotiEmitTrack";
import {commonnotitrack} from "./NotiEmitTrack";




const getCcUserId = commonJsObj.getCcUserId;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getBaseUrl = commonJsObj.getBaseUrl;
const getFormattedAmount = commonJsObj.getFormattedAmount;

//for realtime websocket url
// const sockUrl = "/ws?token="+getTokenFromLocStorage();
//for realtime subscription url to receive notification
// const subscUrl1 = "/topic/notification."+getCcUserId();
//for realtime
// var Stomp = require('stompjs');

class SubscriptionPayment extends Component {

    isComponentLoaded =  false;
    // for context
    static contextType = CommonContext;

    /**  
     * SubscriptionPayment
     * Function name : constructor
     * Author        :
     * Created Date  : 30-6-2020
     * Purpose       : initializing children
     * Params        : props
    **/
    constructor(props) {
      super(props);    
      this.children = props.children;
      this.state = {
        

        // payment process flag
        "paymentProcessFlag":false,
        // subscription link generate success response
        "originalAmount": 0,
        "promoCodeApplied": null,
        "discountPercentage": null,
        "promoAmount": null,
        "payableAmount": 0,
        "paymentLink": null,


      };
      this.scrollerRef = React.createRef();
      // for realtime
      // this.clientsocket  = Stomp.over(new SockJS(sockUrl));
      
     
    }

    /**
     * SubscriptionPayment
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 30-6-2020
     * Purpose       : lifecycle method
     * Params        :
    **/
    componentDidMount() {
      this.isComponentLoaded = true;
      
      commonnotitrack("SCROLL_TO_MAIN_HEADER",{
        "notiType": "SCROLL_TO_MAIN_HEADER",
        "triggerCallback":true,
        "trigerredFrom":"SUBSCRIPTION_PAYMENT_FLOW"
       });
      //
      let subscriptionPaymentInfo = null;
      let triggeredFrom = "";
      
      
      //********* for context starts *****
      let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;

      console.log(" from subs payment page=>>",sharedData);
          
      if(sharedData!==undefined && sharedData!==null ){ 
        
        if(sharedData.other!==undefined && sharedData.other!==null){            
                
          triggeredFrom  = sharedData.other.triggeredFrom!==undefined?sharedData.other.triggeredFrom:"";

          subscriptionPaymentInfo  = sharedData.other.subscriptionPaymentInfo!==undefined?sharedData.other.subscriptionPaymentInfo:null;

       
          let chkDataAvailability = this.checkPaymentInfoAvailability(subscriptionPaymentInfo);
          
          if(chkDataAvailability){
            this.changePaymentOption({"paymentOpt":"OP"});
          }else{
            // no need to stay here go back
            this.triggerBackButton({});
          }    
          
        }else{
          this.triggerBackButton({});
        }

      }
      
      // for realtime
      // const ref = this;       
      // this.clientsocket.connect( {}, function (){
      //      //console.log("==Yes connected ==");
           
      //       // now subscribe to 
      //       this.subscribe(subscUrl1, (message) =>{
      //         // called when the client receives a STOMP message from the server
      //         ref.callbackSubscUrl1(message);
  
      //       });
 
         
      //    });


    
    }

    // for realtime
    // callbackSubscUrl1 = (message) =>{
    //   // called when the client receives a STOMP message from the server
    //   if (message.body) {
    //     //console.log("=====got message with body from bell noti compo =>>>",message.body)

    //     let jsonWsResp = (message.body!==undefined && message.body!==null)?JSON.parse(message.body):null;
    //     let webSocketTaskInfo = null;
    //     let webSocketNotificationInfo = null;
    //     let webSocketCommentInfo = null;
    //     let notificationId = 0;
    //     let taskIdFromNoti = 0;
    //     //taskOwner
    //     let ccUserIdFromNoti = 0;

    //     if(jsonWsResp!==null){

    //        webSocketTaskInfo = (jsonWsResp.webSocketTaskInfo!==undefined && jsonWsResp.webSocketTaskInfo!==null)?jsonWsResp.webSocketTaskInfo:null;

    //        webSocketNotificationInfo = (jsonWsResp.webSocketNotificationInfo!==undefined && jsonWsResp.webSocketNotificationInfo!==null)?jsonWsResp.webSocketNotificationInfo:null;

           

    //        webSocketCommentInfo = (jsonWsResp.webSocketCommentInfo!==undefined && jsonWsResp.webSocketCommentInfo!==null)?jsonWsResp.webSocketCommentInfo:null;


    //        //console.log("==paresed==>> webSocketTaskInfo=>",webSocketTaskInfo," webSocketNotificationInfo==>>",webSocketNotificationInfo, " webSocketCommentInfo==>>",webSocketCommentInfo," typeof(webSocketNotificationInfo=>)",typeof(webSocketNotificationInfo));
    //        notificationId = webSocketNotificationInfo.notificationId!==undefined?webSocketNotificationInfo.notificationId:0;

    //       if(webSocketNotificationInfo && webSocketNotificationInfo.userNotificationType!==undefined && notificationId ){
    //         let userNotificationType = webSocketNotificationInfo!==null && webSocketNotificationInfo.userNotificationType!==undefined && webSocketNotificationInfo.userNotificationType!==null?webSocketNotificationInfo.userNotificationType:"";

    //         let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;

    //             //console.log(" found =====>>",userNotificationType," webSocketNotificationInfo==>",webSocketNotificationInfo);
    //             if(userNotificationType=="SUBSCRIPTION_SUCCESS"){
    //               notify("Success!!",true,{
    //                 "successMsg":1,
    //                 "customWrapClass":"customerrmidlass",
    //                 "showCloseButton": true, 
            
    //                 "dismiss": {
    //                 "duration": 1500,
    //                 }
            
    //                 }); 

    //                 setTimeout(()=>{
    //                   this.triggerBackButton({"triggeredFrom":"REAL_TIME_NOTI"});
    //                 },1700);

    //             }
    //             else if(userNotificationType=="SUBSCRIPTION_FAILURE"){

    //               notify("Something went wrong, please try later!!",true,{
    //                 "successMsg":0,
    //                 "customWrapClass":"customerrmidlass",
    //                 "showCloseButton": true, 
            
    //                 "dismiss": {
    //                 "duration": 1500,
    //                 }
            
    //                 }); 

    //                 setTimeout(()=>{
    //                   this.triggerBackButton({"triggeredFrom":"REAL_TIME_NOTI"});
    //                 },1700);
                  
    //             }

            

    //       }


    //     }

    //   }
    // }

     // for realtime
     trackNotiEvent = (otherParam) =>{
      let notiType =  otherParam.notiType!==undefined? otherParam.notiType: "";
      let trigerredFrom =  otherParam.trigerredFrom!==undefined? otherParam.trigerredFrom: ""; 

      
      
      if(notiType=="SUBSCRIPTION_SUCCESS"){              
          
            notify("Success!!",true,{
              "successMsg":1,
              "customWrapClass":"customerrmidlass",
              "showCloseButton": true, 
      
              "dismiss": {
              "duration": 1500,
              }
      
              }); 

              setTimeout(()=>{
                this.triggerBackButton({"triggeredFrom":"REAL_TIME_NOTI"});
              },1700);

         
      }

      if(notiType=="SUBSCRIPTION_FAILURE"){              
          
            notify("Something went wrong, please try later!!",true,{
              "successMsg":0,
              "customWrapClass":"customerrmidlass",
              "showCloseButton": true, 
      
              "dismiss": {
              "duration": 1500,
              }
      
              }); 

              setTimeout(()=>{
                this.triggerBackButton({"triggeredFrom":"REAL_TIME_NOTI"});
              },1700);
         
      }

    }

    changePaymentOption = (paramObj) =>{
      let paymentOpt = paramObj.paymentOpt;
      this.isComponentLoaded && this.setState({"paymentOpt":paymentOpt},()=>{});
    }

    checkPaymentInfoAvailability = (subsPaymentInfo)=>{
     // console.log(" == from checkPaymentInfo=== ",subsPaymentInfo); 
           
      if(subsPaymentInfo!==undefined && subsPaymentInfo!==null ){
        this.isComponentLoaded && this.setState(subsPaymentInfo,()=>{
          console.log(" == from subs payment page checkPaymentInfoAvailability  this.state=== ",this.state)
        });
        return    true;    
      }  
      
      return false;

    }
  


    /**  
     * SubscriptionPayment
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {
      this.isComponentLoaded = false;

    }

    /**  
     * SubscriptionPayment
     * Function name : componentDidUpdate
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentDidUpdate(prevProps){

    }

   
    triggerBackButton = (otherParm) =>{

      let triggeredFrom =  otherParm.triggeredFrom!==undefined?otherParm.triggeredFrom:"";
       //********* for context starts *****
      // context share
      let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;
      let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;      
     
      //********* for context ends *****
      if(setShare && sharedData){

        if(triggeredFrom=="REAL_TIME_NOTI"){
          if(sharedData.other!==undefined && sharedData.other!==null){
            sharedData.actionType="APP_SETTINGS"
            sharedData.subHeaderTabToActive = "SUBSCRIPTION_PACKAGE";
            sharedData.purpose = "VIEW_PACKAGES";
            sharedData.other.triggeredFrom = "REAL_TIME_NOTI";
            setShare(sharedData);
           
            setTimeout(()=>{
              this.props.history.push("/application-settings");
            },1200);
          }else{
            this.props.history.push("/application-settings");
          }
          
        }else{
          if(sharedData.other!==undefined && sharedData.other!==null){
            sharedData.actionType="APP_SETTINGS"
            sharedData.subHeaderTabToActive = "SUBSCRIPTION_PACKAGE";
            sharedData.purpose = "VIEW_PACKAGES";
            sharedData.other.triggeredFrom = "SUBSCRIPTION_PAYMENT";
            setShare(sharedData);
           
            setTimeout(()=>{
              this.props.history.push("/application-settings");
            },1200);
          }else{
            this.props.history.push("/application-settings");
          }

        }

        
        
        
      }
    }
  
    loadMiddlePaymentStructure = () =>{
      
        if(this.state.paymentOpt=="OP"){
          let datasObj = {
            "vbaInfo":this.state.vbaInfo,
            "injectedIn":"SubscriptionPayment"
          };          
          return (
            <>
            <div className={"subscriptionPaymentDetails"}>
              <div className="pay_det_sec pay_det_color">
              <div className="pay_det_sec_p">
                <p>
                Supports Net Banking, Online NEFT/RTGS/IMPS, Debit Cards, Credit Cards
                </p>
              </div>
            </div>
            
            
          
            </div>
            </>
          );
        }else{
          return (<></>);
        }
    }
   

    loadRightSidePaymentBlock = () =>{
      
     return (
      <div className="payment_block">
      <div className="payment_gray_box">
        <div className="pay_row_adjust">
          <div className="pay_row">
            <div className="pay_row_l">Subscription amount inclusive of GST</div>
            <div className="pay_row_r">INR {getFormattedAmount({"dataToFormat":this.state.originalAmount,
      "formatType":1})}</div>
            <div className="clearboth" />
          </div>
          
          {this.state.promoCodeApplied && (
            <div className="pay_row">
            <div className="pay_row_l">Discount applied</div>
            <div className="pay_row_r">INR {getFormattedAmount({"dataToFormat":(this.state.originalAmount - this.state.payableAmount),
      "formatType":1})}</div>
            <div className="clearboth" />
          </div>
          )}
            

         
          
          
          

        </div>
        <div className="pay_row_total">
          <div className="pay_row">
            <div className="pay_row_l">Total</div>
            <div className="pay_row_r">INR {getFormattedAmount({"dataToFormat":this.state.payableAmount,
      "formatType":1})}</div>
            <div className="clearboth" />
          </div>
        </div>
        <div className="pay_row_button">
          <button  onClick={(evn)=>{
            evn.preventDefault();
            this.isComponentLoaded && this.setState({"paymentProcessFlag":true},()=>{
              this.callPaymentLink({});
            }); 
            
          }} disabled={(this.state.payableAmount && this.state.paymentLink)?false:true} className={`sub_grd_btn ${(this.state.payableAmount && this.state.paymentLink)?"":"disabled_section"}`}>
            <span>Complete Payment</span>
          </button>
        </div>
      </div>
    </div>
    
     );
    }

    loadLeftSidePaymentOptions = () => {
     return (
      <div className="invoPay_left_nav">
      {/* == left navigation start ==  */}
      <nav className="navbar navbar-expand-lg navbar-light invo_navbar">
        <div className="pay_side_heading">
          <h3 className="navbar-brand" href="" onClick={(evn)=>{evn.preventDefault();}} >
            Choose payment method
          </h3>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
        </div>
        <div
          className="collapse navbar-collapse invoPay_navbar"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav">
            <li key={0} className={`nav-item ${ this.state.paymentOpt=="OP"?"active":""}`}>
              <a className="nav-link" href="" onClick={(evn)=>{
                evn.preventDefault();
                this.changePaymentOption({"paymentOpt":"OP"});
                }}>
                <span className="pay_li_text">
                Make online payment
                </span>
              </a>
            </li>
            <li key={1} className="nav-item">
              <a className="nav-link" href="" onClick={(evn)=>{evn.preventDefault();}}>
                <span className="pay_li_text">
                Record offline payment
                </span>
                <span className="comming_soon">Coming soon</span>
              </a>
            </li>
          </ul>
        </div>
      </nav>
      {/* == left navigation end ==  */}
    </div>
  
     );
    }

    callPaymentLink = () => {
      let paymentLink  = this.state.paymentLink;
      window.open(paymentLink);
    }

    /**  
     * SubscriptionPayment
     * Function name : render
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() {    
      
     

      return (
        <>
            <NotiEmitTrack callbackfuncforcommontrackofnoti={this.trackNotiEvent} />
            <div className="payment_method_PagrBg">
              {/* Invoice Payment task header start */}
              <div className="task_header invoPay_header task_header_withTopHeader">
                <div className="taskstep_back_bg invoPay_backBg">
                  <button onClick={(evn)=>{
                    evn.preventDefault();
                    this.triggerBackButton({});
                  }}>
                    <img
                      className="back_img_icon"
                      src={getBaseUrl()+"assets/images/path-back-white.png"}
                    />
                    Back
                  </button>
                  <h4>
                    <span>Subscription </span> Level 02
                  </h4>
                </div>
              </div>
              {/* Invoice Payment task header end */}
              <CustomMessageBar />
              {/* Invoice Payment main body start */}
              {/* task body area start */}
              <div className="invoPay_mainBody_bg">
                <div className="cutom_container cutom_container_withTopHeader">
                  
                  {/* Invoice Payment method Body strat */}
                  <div className="invoPay_main_Section min_height_700">
                    <div className="invoPay_mainBody">
                      {/* == Payment Method left side bar start == */}
                      <div className="invoPay_left_bg">
                        {this.loadLeftSidePaymentOptions()}
                      </div>
                      {/* == Payment Method left side bar end == */}
                      {/* == Payment Method right ssection start == */}
                      <div className="invoPay_right_bg">
                        <div className="invoPay_right_section">
                          <div>
                          {/* -- pay details left start --  */}
                          <div className="pay_details_bg">
                            {this.loadMiddlePaymentStructure()}                           
                          </div>
                          {/* -- pay details left end --  */}
                          {/* -- pay details right start --  */}
                          {this.loadRightSidePaymentBlock()}
                          {/* -- pay details right end --  */}
                          </div>
                          <div className="clearboth" />
                        </div>
                      </div>
                      {/* == Payment Method right ssection end == */}
                      <div className="clearboth" />
                    </div>
                  </div>
                  {/* Invoice Payment method Body end */}
                  {/* Invoice Payment method footer strat */}
                  {/* 
                      <div class="task_details_btn_area invoPay_footer">
                          <div class="btn_fld">
                              <div class="panel50">
                                  <a href="#" class="btn_txt">Save as Draft</a>
                              </div>
                              <div class="panel50">
                                  <div class="cancel_continue_btn">
                                      <a href="#" class="btn_txt">Cancel</a>
                                      <button class="btn_appearance_none sub_grd_btn disabled_section"><span>Continue</span></button>
                                  </div>
                              </div>
                              <div class="clearboth"></div>
                          </div>
                      </div>
                      */}
                  {/* Invoice Payment method footer end */}
                </div>
              </div>
              {/* task body area end */}
              {/* Invoice Payment main body end */}
            </div>

        </>
      );
    }
}
export default withRouter(SubscriptionPayment);