/**  
 * ChatListOfNegotiationService
 * Page/Class name   : TaskListForSingleTaskService
 * Author            :
 * Created Date      : 21-4-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, getTweetRecords
 * Purpose           : api service page
**/
import {Component } from 'react';
// import {getEndPoint,getTokenFromLocStorage, callApi} from  '../../common_utilities/commonjsfunc';
// newly added axios
//import axios from 'axios';
import {axiosCustomObj} from '../customisedthirdparty/AxiosCustom';

import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
const callApi = commonJsObj.callApi;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;
const getMaxPageSize = commonJsObj.getMaxPageSize;


class ChatListOfNegotiationService extends Component {

    
    /**  
     * ChatListOfNegotiationService
     * Function name : constructor
     * Author        :
     * Created Date  : 21-4-2020
     * Purpose       : initializing state
     * Params        : 
    **/
    constructor() {
      super();
      this.state = {
          
      }

      //this.axiosObj = axios;
      this.axiosObj = null; 
      
      this.apiEndPoint = commonJsObj.getEndPoint()+'rest/chats/chat';
    }

    /**  
     * TaskListForSingleTaskService
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 24-3-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentDidMount() {
    }

    /**  
     * TypeOfProfessionalsService
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 4-3-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentWillUnmount() {  
    }

    

    /**  
     * TaskListForSingleTaskService
     * Function name : getLoginResp
     * Author        :
     * Created Date  : 24-3-2020
     * Purpose       : Login check to provide access token 
     * Params        : paramObj
    **/
    async getChatListOfNegotiation(paramObj){
      this.axiosObj = axiosCustomObj({});
      let accToken = getTokenFromLocStorage();   
      let offsetOfLocalDateTime = getOffsetOfLocalDateTime(new Date());

      try{
            let urldata =this.apiEndPoint; 
            console.log("from service file  paramObj=>>>",paramObj);

            const defaultPageSize = getMaxPageSize();
            

            let offsetHr = offsetOfLocalDateTime.hr;
            let offsetMin = offsetOfLocalDateTime.min;
           
            let data  = paramObj.data;
            let lpUserId = data.lpUserId?data.lpUserId:0;

            let pageNum = data.pageNum?data.pageNum:1;
            let pageSize = data.pageSize?data.pageSize:defaultPageSize;

            let taskIdOrSubTaskId = data.taskIdOrSubTaskId?data.taskIdOrSubTaskId:0;
            let taskOrSubTask = data.taskOrSubTask?data.taskOrSubTask:"";
            let chatOfBudgetOrDueDate =data.chatType?data.chatType:"";
            let lastCommentId = data.lastCommentId?data.lastCommentId:0;


            if(taskOrSubTask=="STASK"){
              urldata = urldata+"/chat_with/"+lpUserId+"/task/"+taskIdOrSubTaskId+"/?chatType="+chatOfBudgetOrDueDate+"&offset_hour="+offsetHr+"&offset_minute="+offsetMin+"&page_number="+pageNum+"&page_size="+pageSize+"&last_chat_id="+lastCommentId;
            }
            else if(taskOrSubTask=="SWSTASK"){
              urldata = urldata+"/chat_with/"+lpUserId+"/sub_task/"+taskIdOrSubTaskId+"/?chatType="+chatOfBudgetOrDueDate+"&offset_hour="+offsetHr+"&offset_minute="+offsetMin+"&page_number="+pageNum+"&page_size="+pageSize+"&last_chat_id="+lastCommentId;
            }
            

            //console.log("task single list api hitted with =>>",urldata);


            let headerOptn = {  
              "Content-Type": "application/json",
              "Authorization": accToken
            };          

            let respObj = this.axiosObj.get(urldata,{
            headers: headerOptn
            });

            return respObj;
    }
    catch(err){
        console.log("Some error occured===>",err); // TypeError: failed to fetch
    }
      

    }

    
}
export default ChatListOfNegotiationService;