/**  
 * FileUploadService
 * Page/Class name   : FileUploadService
 * Author            :
 * Created Date      : 17-2-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, getTweetRecords
 * Purpose           : api service page
**/
import {Component } from 'react';
// import {getEndPoint,getTokenFromLocStorage, callApi} from  '../../common_utilities/commonjsfunc';
// newly added axios
//import axios from 'axios';
import {axiosCustomObj} from '../customisedthirdparty/AxiosCustom';

import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
const callApi = commonJsObj.callApi;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;



class FileUploadService extends Component {

    
    /**  
     * FileUploadService
     * Function name : constructor
     * Author        :
     * Created Date  : 17-2-2020
     * Purpose       : initializing state
     * Params        : 
    **/
    constructor() {
      super();
      this.state = {
          
      }

       //this.axiosObj = axios;
       this.axiosObj = null;
      
      this.apiEndPoint = commonJsObj.getEndPoint()+'rest/files/file';
    }

    /**  
     * FileUploadService
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 17-2-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentDidMount() {
    }

    /**  
     * FileUploadService
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 17-2-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentWillUnmount() {  
    }



    /**  
     * FileUploadService
     * Function name : getLoginResp
     * Author        :
     * Created Date  : 17-2-2020
     * Purpose       : Login check to provide access token 
     * Params        : paramObj
    **/
    async uploadFiletoServer(paramObj){
      this.axiosObj = axiosCustomObj({});
      let accToken = getTokenFromLocStorage();     
      try{
            let urldata =this.apiEndPoint;  
            
            let dataObj = paramObj.data;
            let taskTypeFlag = paramObj.taskTypeFlag;

            // newly added
            let fileUploadedFromPageSection = paramObj.fileUploadedFromPageSection!==undefined?paramObj.fileUploadedFromPageSection:"";
            let fileUploadFor = paramObj.fileUploadFor!==undefined?paramObj.fileUploadFor:"";
            let taskAssigned = paramObj.taskAssigned!==undefined?paramObj.taskAssigned:null;

            let file_upload_type = 1;
            let upload_type_id = 0;
            let concanateUrlPart = "";

            /*
            
            if(taskTypeFlag==0){
              // single task
              urldata = urldata+ "?file_upload_type=1";
            }else if(taskTypeFlag==1){
              // multi task
              let cat_id = paramObj.cat_id;
              let type_id = paramObj.type_id;
              urldata = urldata+ "?file_upload_type=6&cat_id="+cat_id+"&type_id="+type_id;
            } else if(taskTypeFlag==8){
              let upload_type_id = paramObj.taskId;
              urldata = urldata+ "?file_upload_type=8&upload_type_id="+upload_type_id;
            }
            */

           if(taskTypeFlag==0){
            // for single task
              if(fileUploadedFromPageSection=="SINGLETASKCREATE"){

                    if(fileUploadFor=='TASKDOCUMENT'){
                      file_upload_type = 9;
                    }
              }
              else if(fileUploadedFromPageSection=="SINGLETASKEDIT"){
                if(fileUploadFor=='TASKDOCUMENT'){
                    if(taskAssigned){
                      file_upload_type = 4;
                      upload_type_id = paramObj.taskId;
                      concanateUrlPart = "&upload_type_id="+upload_type_id;
                    }else{
                      file_upload_type = 9;
                    }
                   
                }
               
              }
              else if(fileUploadedFromPageSection=="DOCUMENTTAB"){
                if(fileUploadFor=='DOCUMENT'){
                    
                    if(taskAssigned){
                      file_upload_type  = 8; 
                      upload_type_id = paramObj.taskId;
                      concanateUrlPart = "&upload_type_id="+upload_type_id;
                    }else{
                      file_upload_type  = 8; 
                      upload_type_id = paramObj.taskId;
                      concanateUrlPart = "&upload_type_id="+upload_type_id;
                    }


                }
               
              }

              urldata = urldata+ "?file_upload_type="+file_upload_type+concanateUrlPart;
              
            }
            else if(taskTypeFlag==1){
              // for multi task
              file_upload_type = 6;
              let cat_id = paramObj.cat_id;
              let type_id = paramObj.type_id;
              urldata = urldata+ "?file_upload_type="+file_upload_type+"&cat_id="+cat_id+"&type_id="+type_id;
            }

                   

            let headerOptn = {  
              "Content-Type": "application/x-www-form-urlencoded",
              "Authorization": accToken
            };   
            var formData = new FormData();           
            Object.keys(dataObj).map(function(key, index) {              
              formData.append("files", dataObj[key]);
            });
            //formData.append("files", paramObj[0]);           
            let respObj = this.axiosObj.post(urldata, formData, {
              headers: headerOptn
          });

          return respObj;
    }
    catch(err){
        console.log("Some error occured===>",err); // TypeError: failed to fetch
    }
      

    }

    
}
export default FileUploadService;