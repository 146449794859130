/**
 * SplitScreenPreAssingmentTaskDocuments
 * Page/Class name   : SplitScreenPreAssingmentTaskDocuments
 * Author            :
 * Created Date      : 14-05-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : rendering pre assingment documents
**/

import React, { Component } from 'react'
// for context
import CommonContext from '../../context/CommonContext';

import TaskPreAssingmentDocService from '../../service/TaskPreAssingmentDocService';
import  CustDate  from '../../../common_utilities/CustDate';
import { commonJsFuncModule as commonJsObj } from '../../../common_utilities/commonjsfunc';
// import { Scrollbars } from 'react-custom-scrollbars';
// For loading CustLoader
import CustLoader from './CustLoader';
import SplitScreenLeftMenu from "../../share/SplitScreenLeftMenu";
import ViewDocFileButton from './ViewDocFileButton';

import CustReactBootstrapModal from './CustReactBootstrapModal';
import CustomMessageBar from '../../messagebar/CustomMessageBar';
import DeleteDocService from '../../service/DeleteDocService';
import {notify} from '../../messagebar/CustomMessageBar';


const getBaseUrl = commonJsObj.getBaseUrl;

const getCcUserId = commonJsObj.getCcUserId;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getFileNameOnly = commonJsObj.getFileNameOnly;
const getStringInitials = commonJsObj.getStringInitials;


export class SplitScreenPreAssingmentTaskDocuments extends Component {
    isComponentLoaded =  false;
    // for context
    static contextType = CommonContext;
    /**
     * SplitScreenPreAssingmentTaskDocuments
     * Function name : constructor
     * Author        :
     * Created Date  : 14-05-2020
     * Purpose       : initializing state
     * Params        : props
    **/
    constructor(props) {
        super(props);
        this.children = props.children;
        this.state = {
          "taskId": "",
          "docListData": [],
          "pageNum": 1,
          "pageSize":4,
          "totalNumberOfPages": 1,
          "loaderFlag": true,
          "pageNumArTrack": [],
          "showDeleteModal": false,
          "filter":"",
          "assignedTo": null, 
        };
        this.scrollerRef = React.createRef();
        this.modalfortaskdelRef = React.createRef();
    }

    /**
     * SplitScreenPreAssingmentTaskDocuments
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 14-05-2020
     * Purpose       : lifecycle method
     * Params        :
    **/
    componentDidMount() {
        this.isComponentLoaded = true;
        let assignedTo = null;
        // console.log(this.context)
        let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;
        // if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){
        //     this.setState({taskId:sharedData.taskId},() => { this.getPreassingmentDoc(); })
        //     ;
        // }
        if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){
            let filterData = "";
            if(sharedData && sharedData.other!==undefined && sharedData.other!==null ){
                let otherData = sharedData.other;
                let splitScreenDocTabFilter = (otherData!==null && otherData.splitScreenDocTabFilter!==undefined)?otherData.splitScreenDocTabFilter:null;
                filterData = (splitScreenDocTabFilter!==null && splitScreenDocTabFilter.filter!==undefined)?splitScreenDocTabFilter.filter:"";
            
                let taskResp = otherData.taskResp;               
                assignedTo = (taskResp.assignedTo!==undefined && taskResp.assignedTo!==null)?taskResp.assignedTo:null;
            
            }
            this.setState({"taskId":sharedData.taskId,"filter":filterData,"assignedTo":assignedTo},() => { this.getPreassingmentDoc(); })
            ;
        }
    }
    /**
     * SplitScreenPreAssingmentTaskDocuments
     * Function name : getPreassingmentDoc
     * Author        :
     * Created Date  : 14-05-2020
     * Purpose       : call api for fetching  data
     * Params        :
    **/
    getPreassingmentDoc = ()=>{
        let lservdrf = new TaskPreAssingmentDocService();
        lservdrf.getTaskPreAssignmentDoc(this.state).then(data =>{
            let respData  = data.data.entity;
            console.log(respData)
            if(respData.documents!==undefined){
                if(respData.documents.length > 0){
                   // set list data to state
                   let prevRecords = [...this.state.docListData];
                   let currRecords = respData.documents;
                   let concatenateRecords = prevRecords.concat(currRecords);
                   this.isComponentLoaded && this.setState({"docListData":concatenateRecords,"totalNumberOfPages":respData.totalNumberOfPages},()=>{});
                }else{
                    if(this.state.docListData.length==0){
                      this.isComponentLoaded && this.setState({"docListData":[],"totalNumberOfPages":respData.totalNumberOfPages,"pageNum":1});
                    }
                }
            }else{}
        }).catch((e)=>{
            console.log("server error=>",e);
        }).finally(()=>{
            this.hideLoader();
        });
    }
    /**
     * SplitScreenPreAssingmentTaskDocuments
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 14-05-2020
     * Purpose       : lifecycle method
     * Params        :
    **/
    componentWillUnmount() {
    this.isComponentLoaded = false;

    }
    /**
     * SplitScreenPreAssingmentTaskDocuments
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 14-05-2020
     * Purpose       : close the right panel
     * Params        :
    **/
    closeSplit = ()=>{
            //********* for context starts *****
            let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;
            if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){

                if(sharedData.actionType=="SPLITSCREEN" ){

                let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;

                if(setShare){
                    let paramsCntxt = {
                    "taskId":"",
                    "actionType":"",
                    "leftTabToActive":"",
                    "purpose":"",
                    "other":{}
                    }
                    setShare(paramsCntxt);
                }


                }
            }
            //********* for context ends *****

    }
    /**
     * SplitScreenPreAssingmentTaskDocuments
     * Function name : loadLoader
     * Author        :
     * Created Date  : 14-05-2020
     * Purpose       : Load loader
     * Params        :
     **/
    loadLoader() {
        return (
            <CustLoader className={'smallLoaderGreen'} loaderText={'Loading..'} />
        );
    }
    /**
     * SplitScreenPreAssingmentTaskDocuments
     * Function name : hideLoader
     * Author        :
     * Created Date  : 14-05-2020
     * Purpose       : Hide loader
     * Params        :
     **/
    hideLoader() {
        this.isComponentLoaded && this.setState({ loaderFlag: false });
    }
    /**
     * SplitScreenPreAssingmentTaskDocuments
     * Function name : showLoader
     * Author        :
     * Created Date  : 27-3-2020
     * Purpose       : showLoader
     * Params        :
     **/
    showLoader() {
        this.isComponentLoaded && this.setState({ loaderFlag: true });
    }
  
    /**
     * SplitScreenPreAssingmentTaskDocuments
     * Function name : loadfooterOfDocument
     * Author        :
     * Created Date  : 14-05-2020
     * Purpose       : load footer portion
     * Params        :
    **/
    loadfooterOfDocument = () => {
        return (
            <>
            <div className="SlideS_bottombg flexbox-item Slide_chatBottomBg SlideS_bottombg_gray SlideS_bottombg_stickyFooterRightPart SlideS_bottombg_stickyFooterRightPart_documentPage">

            </div>

            </>
        );
    }
    /**
     * SplitScreenPreAssingmentTaskDocuments
     * Function name : showDocList
     * Author        :
     * Created Date  : 14-05-2020
     * Purpose       : load post assign document list component
     * Params        : param
    **/
    showDocList= (param)=>{

        let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;
            if(setShare){
                    setShare(param.paramsCntxt);
            }
    }
    /**
     * SplitScreenPreAssingmentTaskDocuments
     * Function name : deleteModalOpen
     * Author        :
     * Created Date  : 14-05-2020
     * Purpose       : To open delete modal
     * Params        :
    **/
    deleteModalOpen = (otherParam)=>{
        this.isComponentLoaded &&  this.setState({showDeleteModal:true},()=>{
            this.modalfortaskdelRef.current.showModal();
            this.getDeleteModalContent(otherParam);
        });

    }

    /**
     * SplitScreenPreAssingmentTaskDocuments
     * Function name : deleteModalClose
     * Author        :
     * Created Date  : 14-05-2020
     * Purpose       : To close Detail Modal
     * Params        :
    **/

    deleteModalClose = ()=>{
        this.isComponentLoaded && this.setState({showDeleteModal:false},()=>{
          this.modalfortaskdelRef.current.hideModal();
        });
    };

    /**
     * SplitScreenPreAssingmentTaskDocuments
     * Function name : getDeleteModalContent
     * Author        :
     * Created Date  : 14-05-2020
     * Purpose       : Get contrent of delete modal
     * Params        :otherParam
    **/
    getDeleteModalContent(otherParam) {

        let viewData = <div className="dash_modal">

                <>
            <div className="modal-header">
                <h4 className="modal-title modal_title_tool"> Delete Doc

                </h4>
                <button type="button" onClick={
                    ()=>{
                    this.deleteModalClose();

                }} className="close modal_close" data-dismiss="modal">&times;</button>
            </div>
            <CustomMessageBar />
            <div className="modal-body padding_top">
            <div className="popUP_text_p">
                    <p>
                Are you sure you want to delete this Doc?
                </p>
              </div>
            </div>
            <div className="modal-footer">
               
                <button disabled={this.state.continueDisabled}  type="button" className="btn btn-primary modal_btn_save" onClick={(e)=>{ e.preventDefault();
                   this.isComponentLoaded && this.setState({"continueDisabled":true},()=>{
                    let paramObj = {"taskId":otherParam.taskId,"docId":otherParam.docId}
                      this.deleteDoc(paramObj);

                   });
                } } data-dismiss="modal"><span>Confirm</span></button>
                
            </div>
          

          </>


        </div>;

        this.modalfortaskdelRef.current.updateStateContent(viewData);
        this.modalfortaskdelRef.current.showContent();
        return viewData;
    }
    /**
     * SplitScreenPreAssingmentTaskDocuments
     * Function name : deleteDoc
     * Author        :
     * Created Date  : 14-05-2020
     * Purpose       : Delete document of the doc
     * Params        : paramObj
    **/
    deleteDoc(paramObj){
        console.log(paramObj)
        let lservdrf = new DeleteDocService();
        lservdrf.deleteDoc(paramObj).then(data =>{

        // to fix scroll issue
        let scobj = this.scrollerRef.current;
        scobj.scrollToTop();

        let notiMsg = "Deleted successfully";
                notify(notiMsg,true,{
                "successMsg":1,
                "customWrapClass":"customerrmidlass",
                "showCloseButton": false,

                "dismiss": {
                "duration": 1000,
                }

                });

        this.isComponentLoaded &&   this.setState({"listData":[]},()=>{


                setTimeout(()=>{
                    // set list data to blank state
                    this.showLoader();
                    // call list api
                    let paramObj = {"pageNum":1,"docListData":[],"pageNumArTrack": [], "totalNumberOfPages":1,};
                    this.isComponentLoaded && this.setState(paramObj,()=>{
                    this.getPreassingmentDoc();

                  });
                },1200);



              });



              setTimeout(()=>{
                this.deleteModalClose();
              },1150);

              setTimeout(()=>{
                this.closeSplit();
              },1200);



          }).catch((err)=>{
            let errStatusCodeAr = [400,500]; // allowed error status code
            if(err.response && err.response.data){
                let erRespData = err.response.data;
                if(errStatusCodeAr.indexOf(erRespData.status) !== -1){
                    let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                    //console.log.log(" errMsg on task creation==>",errMsg);

                    let notiMsg = errMsg;
                    notify(notiMsg,true,{
                    "successMsg":0,
                    "customWrapClass":"customerrmidlass",
                    "showCloseButton": false,

                    "dismiss": {
                    "duration": 2500,
                    }

                    });

            }
            }
          }).finally(()=>{
               this.isComponentLoaded && this.setState({"continueDisabled":false},()=>{
                this.getPreassingmentDoc();
               });
          });
    }

    /**
     * SplitScreenPreAssingmentTaskDocuments
     * Function name : getDocListStructure
     * Author        :
     * Created Date  : 14-05-2020
     * Purpose       : get list of pre assingment doc
     * Params        :
    **/
    getDocListStructure() {
        return (
          
            this.state.docListData.length>0 && (   this.state.docListData.map((el, i) =>
                <div key={i} className="col-xl-6 col-lg-12 col-md-6 col-sm-12 col-12 col_docBlock">
                <div className="docBlock docBlock_file">
                    <div className="docBlock_top">
                        <div className="doc_leftImg">
                            <div className="doc_leftImg_box">
                                <img src="assets/images/file.svg" alt=""/>
                                <div className="docExtc"><h6>{el.fileType}</h6></div>
                            </div>
                        </div>
                        <div className="doc_cont">
                            {/* <h5 className="docFile_name">Document {i+1}</h5> */}
                            <h5 className="docFile_name">{getFileNameOnly(el.name)}</h5>
                            {/* <h5 className="docFile_date"><span>2.33pm</span>|<span>18 Oct 2019</span></h5> */}
                            <h5 className="docFile_date"><span>{el.createdAt ?CustDate.custFormatDate((new Date(el.createdAt)),4): "None"}</span></h5>

                            <p>Uploaded by - {getCcUserId()==el.owner.id ? 'Me' : el.owner.firstName +' '+el.owner.lastName} </p>
                        </div>
                        <div className="doc_box_right">

                            <div className="dropdown tasktop_statusbtn newAction_btn_blockSec">
                                <div className={"moreAction_tableSec newAction_btn_subSec"}>
                                    <button className="btn_appearance_none  moreAction_dropBtn" type="button" data-toggle="dropdown" aria-expanded="false">
                                        Actions
                                        {/* <i className="fa fa-ellipsis-h" aria-hidden="true"></i> */}
                                    </button>
                                    <div className="dropdown-menu newAction_btn_dropdown_menu_sec" aria-labelledby="dropdownMenuButton" x-placement="bottom-start">
                                    <div className="taskstat_dropmenu">
                                        <ul>
                                            <li><a href ={el.downloadAddress+'&auth='+getTokenFromLocStorage()} className="btn_appearance_none a_button" >Download</a></li>
                                            {/* <li><button className="btn_appearance_none" onClick={(evnt)=>{
                                                evnt.preventDefault();

                                                    let otherParam = {"taskId":this.state.taskId,"docId":el.id
                                                    };
                                                    this.deleteModalOpen(otherParam);

                                            }}>Delete</button></li> */}
                                            {/* <li><button className="btn_appearance_none disabled not_clickable">Share</button></li> */}
                                        </ul>
                                    </div>
                                </div>
                                </div>
                            </div>

                        </div>
                        <div className="clearboth"></div>
                    </div>
                    <div className="docBlock_bottom">
                        {/* <button className="btn_appearance_none docView_btn">View</button> */}
                        <ViewDocFileButton data={el} />
                    </div>
                </div>
                </div>

              )
            )
          
        );
    }

    /**
     * SplitScreenPreAssingmentTaskDocuments
     * Function name : trackScroll
     * Author        :
     * Created Date  : 14-05-2020
     * Purpose       : track scroll
     * Params        :
    **/
    // trackScroll = ()=>{

    //     let scobj = this.scrollerRef.current;
    //     let scrollTop = scobj.getValues().scrollTop;
    //     let scrollHeight = scobj.getScrollHeight();
    //     let clientHeight = scobj.getClientHeight();

    //     let prevPageNum = this.state.pageNum;
    //     let totalNumberOfPages = this.state.totalNumberOfPages;

    //     console.log(" scrollHeight=>",scrollHeight," clientHeight==>",clientHeight, " scrollTop=>>",scrollTop);

    //     if((scrollHeight-clientHeight)==scrollTop && prevPageNum < totalNumberOfPages){

    //       this.showLoader();

    //       setTimeout(()=>{
    //         // call list api
    //         let pageNumData = prevPageNum + 1;
    //         this.isComponentLoaded && this.setState((prevState)=>({ pageNum: prevState.pageNum+1 }),()=>{
    //           this.getPreassingmentDoc();
    //         });

    //         },900);


    //     }

    // }

    /**
     * SplitScreenPreAssingmentTaskDocuments
     * Function name : loadTheContent
     * Author        :
     * Created Date  : 14-05-2020
     * Purpose       : load the content
     * Params        :
    **/
    loadTheContent = ()=>{


        let lawyerName = '';
        let lawyerFname = '';
        let lawyerLname = '';
        let lawyerNamePrefix = '';
        let profilePictureURL = "";

        let forSplitScrnCls2Inject = "";
        let datasPassForAssignObj = {};
        //********* for context starts *****
        let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;      
        if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){         
  
            if(sharedData.actionType=="SPLITSCREEN" ){            
              forSplitScrnCls2Inject = "margin0"
            }

            
  
           
        }
        //********* for context ends *****

        if(this.state.assignedTo!==undefined && this.state.assignedTo!==null ){

            lawyerNamePrefix =  (this.state.assignedTo.namePrefix!==undefined && this.state.assignedTo.namePrefix!==null)?this.state.assignedTo.namePrefix:"";
    
            lawyerFname = (this.state.assignedTo.firstName!==undefined && this.state.assignedTo.firstName!==null)?this.state.assignedTo.firstName:"";
    
            lawyerLname = (this.state.assignedTo.lastName!==undefined && this.state.assignedTo.lastName!==null)?this.state.assignedTo.lastName:"";
    
            lawyerName = lawyerFname+" "+lawyerLname;
            lawyerName = !(lawyerNamePrefix)? lawyerNamePrefix+" "+lawyerName: lawyerName;
    
            profilePictureURL = (this.state.assignedTo.profilePictureURL!==undefined && this.state.assignedTo.profilePictureURL!==null)?this.state.assignedTo.profilePictureURL:null;
    
            
          }
        

  
        return (
          <>
          { /* Dashboard Slide Split Right start  */   }
          <div className={`splitscreen_area
                ${forSplitScrnCls2Inject?forSplitScrnCls2Inject:""}`
                  }>
                <div className="slideScreen_bg slideScreen_Chatbg slideScreen_bg_heightAdjust">
                    <SplitScreenLeftMenu />
                    <div className="slideScreen_rightbg flexbox-parent">
                    {/* === right slide section top start ===  */}
                    <div className="SlideS_topbg flexbox-item slide_chat_topbg">
                      <div className="slide_top_titlebg">
                        {/* <h4>Professionals Interested</h4> */}
                        {/* <div className="slideChat_head">
                         
                          <div className="slideChat_panel">
                            <h5 className="chatHeadUser">
                              Documents 
                            </h5>
                          </div>
                          
                        </div> */}
                         {/* newly added */}
                      <div className="titleText_withImageDropdown">
                          <div className="titleText_withImgDropBlock titleText_withImgDropBlock_staticText">
                            <span className="titleText_withImageDropdown_textSpan">
                              {this.state.assignedTo?"Document sharing with":" Document sharing with"}
                              </span>
                          </div>
                          <div className="titleText_withImgDropBlock titleText_withImgDropBlock_imgName">                       

                            <>
                            {this.state.assignedTo?(

                              <>
                                    {profilePictureURL!==null && (
                              <img className="titleText_withImage" src={profilePictureURL} />
                                )}
                                {profilePictureURL===null && ( 
                              <div className="user_name_text">
                                  <span>{getStringInitials(lawyerName)}</span>
                              </div>
                            )}

                            <button className="btn_appearance_none titleText_withImageDropdown_btn pointer_none" type="button" data-toggle="dropdown" aria-expanded="false">
                              {lawyerName}
                            </button>

                             {/* --- optional dropdown for title image text start ---     */}
                        {/* 
                                          <div class="dropdown-menu user_popDetails_block">
                                              <div class="user_popBox">
                                                  <div class="u_popBox_sec user_popBox_top">
                                                      <div class="user_popBox_topLeft">
                                                          <h4>Condition -1  Sourav Roy</h4>
                                                          <div class="userRating_block">
                                                              <div class="ass_userRating">
                                                                  <i class="fa fa-star" aria-hidden="true"></i>  4.5 
                                                              </div>
                                                              <div class="ass_userExp">
                                                                  Experience - 5 years
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div class="user_popBox_topRight">
                                                          <div class="user_name_text"><span>AM</span></div>
                                                      </div>
                                                  </div>
                                                  <div class="u_popBox_sec user_popBox_location">
                                                      <h5>Locations</h5>
                                                      <ul>
                                                          <li>Lorem ipsum dolor sit</li>
                                                          <li>Loremipsumdolorsit</li>
                                                          <li>Loremipsum dolorsit</li>
                                                          <li>Loremipsum dolorsit</li>
                                                      </ul>
                                                  </div>
                                                  <div class="u_popBox_sec user_popBox_pracArea">
                                                      <h5>Practice areas</h5>
                                                      <ul>
                                                          <li>Lorem ipsum dolor sit</li>
                                                          <li>Loremipsumdolorsit</li>
                                                          <li>Loremipsum dolorsit</li>
                                                          <li>Loremipsum dolorsit</li>
                                                      </ul>
                                                  </div>
                                                  <div class="u_popBox_sec user_popBox_about">
                                                      <h5>About</h5>
                                                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                      </p>
                                                  </div>
                                              </div>
                                          </div>
                                            */}
                        {/* --- optional dropdown for title image text end ---     */}

                               </>
                      
                            ):"" }
                            
                            
                            </>

                       
                      </div>
                      
                      
                      </div>

                     
                        <button onClick={(evn)=>{
                              evn.preventDefault();
                              //
                              this.closeSplit();
            
  
                            }} className="btn_appearance_none slideTop_cross_btn cross">
                          <img src="assets/images/red_cross.png" alt />
                        </button>
                      </div>
                    </div>
                    {/* === right slide section top end ===  */}
                    {/********* right slide section Middlie  start *****/}

                    <div className="SlideS_Midbg flexbox-item-grow SlideS_Midbg_chat SlideS_Midbg_heightAdjustment SlideS_Midbg_heightAdjustment_documentPage">

                        {/* <div className="s_midbox_scroll_adjust mCustomScrollbar height_900 reactTestScroll">
                        <div className="s_midbox_section s_midbox_section_chat"> */}
                            {/********   sprint - 5 chat structure start **********/}
                            {/****** Document list start *****/}
                                <div className="docFile_pageBG">
                                    <div className="fileList_bg subBox_scrollBg">

                                        <div className="docBox_bg docBoxFolder_bg">

                                            <div className="mCustomAdjustmentForStickFooter_insideBlock rightMidPartHeightAdjustment_inside rightMidPartHeightAdjustment_inside_documentPageBlock">
                                            {/* <Scrollbars
                                            ref={this.scrollerRef}
                                            style={{ height: 350 }}
                                            autoHeightMin={350}
                                            renderView={props => <div {...props} className="view tasklistscrollcustomclass"/>}

                                            onScrollStop={this.trackScroll}
                                            > */}
                                                <div className="docList_hd">
                                                    <div className="docList_hd_left">
                                                        <button className="btn_appearance_none" onClick={
                                                                      (e)=>{e.preventDefault();
                                                                        let paramsCntxt = {};
                                                                        paramsCntxt = {
                                                                          "taskId":this.state.taskId,
                                                                          "actionType":"SPLITSCREEN",
                                                                          "leftTabToActive":"DOCUMENTS",
                                                                          "purpose":"VIEW_DOC_LIST",
                                                                          "other":this.context.share.other,
                                                                        }
                                                                        let paramObjData = {"data":{"taskId":this.state.taskId}};
                                                                        let param = {"paramObjData":paramObjData,"paramsCntxt":paramsCntxt}
                                                                        this.showDocList(param);
                                                                      }
                                                                    }>
                                                            <img className=" back_img_icon" src="assets/images/Path-back.png"/>
                                                        </button>
                                                        <h4>Task Documents
                                                            <span className="top_sug_left">
                                                                <span className="input_tooltip btn_tooltip" aria-label="Task Documents" data-balloon-pos="down">
                                                                    <i className="fa fa-question-circle"></i>
                                                                </span>
                                                            </span>
                                                        </h4>
                                                    </div>

                                                    <div className="clearboth"></div>
                                                </div>
                                                <div className="docList_bdy">
                                                    <div className="row row_doc">
                                                        {
                                                            this.state.loaderFlag==false && this.state.docListData.length==0 && (
                                                                <div className="col-12 col_docBlock">
                                                                    <div className=" text-center no records found">
                                                                        No records found
                                                                    </div>
                                                                </div>
                                                            )
                                                        }

                                                        {this.getDocListStructure()}
                                                        <div className="col-12 col_docBlock">
                                                        <div className=" text-center ">
                                                            {this.state.loaderFlag ? this.loadLoader() : null}
                                                            {/* {this.loadLoader()} */}
                                                        </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            {/* </Scrollbars> */}
                                            
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            {/******** Document list end  *********/}
                            {/********   sprint - 5 chat structure end ********/}
                        {/* </div>
                        </div> */}

                    </div>

                    {/******** right slide section Middlie  end *******/}
                    {/* === right slide section Footer  start ===  */}
                    {this.loadfooterOfDocument()}
  
  
                    {/* === right slide section Footer  end ===  */}
                  </div>
                
                
                </div>
  
          </div>
  
  
          { /* Dashboard Slide Split Right end  */ }
          </>
        );
    }

    /**
     * SplitScreenPreAssingmentTaskDocuments
     * Function name : render
     * Author        :
     * Created Date  : 14-05-2020
     * Purpose       : lifecycle method
     * Params        :
    **/

    render() {
        return (
            <React.Fragment>
  
  
                {this.loadTheContent()}
  
                {/* Confirm delete modal start */}
                <CustReactBootstrapModal ref={this.modalfortaskdelRef}  backdrop={false} backdropClassName="custom_modal_class"   animation={false} show={this.state.showDeleteModal} onShow={()=>{}} onHide={()=>{}} modalcustdialog="custom_modal_lg"  loaderbodytitle="Organisation"  />
                {/* Confirm delete modal end */}
            </React.Fragment>
        );
    }
}

export default SplitScreenPreAssingmentTaskDocuments
