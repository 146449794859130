/**  
 * DeleteTaskService
 * Page/Class name   : DeleteTaskService
 * Author            :
 * Created Date      : 29-3-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, getTweetRecords
 * Purpose           : api service page
**/
import {Component } from 'react';
// import {getEndPoint,getTokenFromLocStorage, callApi} from  '../../common_utilities/commonjsfunc';
// newly added axios
//import axios from 'axios';
import {axiosCustomObj} from '../customisedthirdparty/AxiosCustom';

import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
const callApi = commonJsObj.callApi;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;



class DeleteTaskService extends Component {

    
    /**  
     * DeleteTaskService
     * Function name : constructor
     * Author        :
     * Created Date  : 29-3-2020
     * Purpose       : initializing state
     * Params        : 
    **/
    constructor() {
      super();
      this.state = {
          
      }

      //this.axiosObj = axios;
      this.axiosObj = null; 
      
      this.apiEndPoint = commonJsObj.getEndPoint()+'rest/tasks/tasks_with_sub_tasks';
    }

    /**  
     * DeleteTaskService
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 29-3-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentDidMount() {
    }

    /**  
     * DeleteTaskService
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 29-3-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentWillUnmount() {  
    }



    /**  
     * DeleteTaskService
     * Function name : saveDraftTask
     * Author        :
     * Created Date  : 29-3-2020
     * Purpose       : saveDraftTask 
     * Params        : paramObj
    **/
    async deleteTask(paramObj){
      this.axiosObj = axiosCustomObj({});
      let accToken = getTokenFromLocStorage();     
      try{
            let urldata =this.apiEndPoint;
            

            let dataObj = paramObj.data;
            console.log(paramObj);
            let headerOptn2 = {  
              
              "Content-Type": "application/json",
              "Authorization": accToken
            };            

            let respObj = this.axiosObj({
              method: 'DELETE',
              url: urldata,
              data: dataObj,
              headers: headerOptn2
            });
        

            return respObj;


            
    }
    catch(err){
        console.log("Some error occured===>",err); // TypeError: failed to fetch
    }
      

    }

    
}
export default DeleteTaskService;