/**
 * TaskListOfMultiTask
 * Page/Class name   : TaskListOfMultiBundleTask
 * Author            :
 * Created Date      : 1-11-2019
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : rendering loader structure
 **/
import React, { Component, Suspense, lazy } from "react";
import { Link, NavLink } from "react-router-dom";
import "../../../custom_resources/css/custom.css";

// for context
import CommonContext from "../../context/CommonContext";

import TaskStatusCodeComponent from "./TaskStatusCodeComponent";

// Modal
import CustReactBootstrapModal from "./CustReactBootstrapModal";

// scroll
// import { Scrollbars } from 'react-custom-scrollbars';

import { notify } from "../../messagebar/CustomMessageBar";
import CustomMessageBar from "../../messagebar/CustomMessageBar";

//Services
import TaskListRelatedToMultiTaskService from "../../service/TaskListRelatedToMultiTaskService";
import DeleteTaskService from "../../service/DeleteTaskService";
import TaskDetailsService from "../../service/TaskDetailsService";

// Newest1 - Start
import ProfileNewMod from "./ProfileNewMod";
// Newest1 - End

import { commonJsFuncModule as commonJsObj } from "../../../common_utilities/commonjsfunc";

import CustDate from "../../../common_utilities/CustDate";

// For loading CustLoader
import CustLoader from "./CustLoader";
import FeedbackAssistant from "./FeedbackAssistant";

// common noti track
import NotiEmitTrack from "./NotiEmitTrack";
import { commonnotitrack } from "./NotiEmitTrack";

import ee from "event-emitter";

// new scroll
import InfiniteScroll from "react-infinite-scroll-component";

const getBaseUrl = commonJsObj.getBaseUrl;
const getStringInitials = commonJsObj.getStringInitials;

const emitter = new ee();

export const notifymultitasklistontaskstatuschange = (
  optionName,
  otherParam
) => {
  emitter.emit("customnotifortaskstatuschange", optionName, otherParam);
};

class TaskListOfMultiTask extends Component {
  // for context
  static contextType = CommonContext;

  isComponentLoaded = false;

  /**
   * TaskListOfMultiTask
   * Function name : constructor
   * Author        :
   * Created Date  : 24-03-2020
   * Purpose       : initializing state
   * Params        : props
   **/
  constructor(props) {
    super(props);

    this.state = {
      listData: [],
      pageNum: 1,
      totalNumberOfPages: 1,
      loaderFlag: true,
      pageNumArTrack: [],

      // to use for view edit delete
      taskId: 0,
      taskIdName: "",
      // for delete modal
      showDeleteModal: false,
      // for confirm button to disable
      continueDisabled: false,

      // Newest1 - Start
      showPVDisclaimerModal: false,
      showPVDisplayModal: false,
      // Newest1 - End
    };

    this.scrollerRef = React.createRef();
    this.modalfortaskdelRef = React.createRef();

    // Newest1 - Start
    this.modalforpvdisclaimerRef = React.createRef();
    this.modalforpvdisplayRef = React.createRef();
    // Newest1 - End

    // for event emitter
    emitter.on("customnotifortaskstatuschange", (optionName, otherParam) => {
      //console.log(" GOT ======",optionName);
      switch (optionName) {
        case "CLOSED":
          this.loadListFromBeginning({});
          break;
        case "ASSIGNED":
          this.loadListFromBeginning({});
          break;
      }
    });
  }

  /**
   * TaskListOfMultiTask
   * Function name : componentDidMount
   * Author        :
   * Created Date  : 24-03-2020
   * Purpose       : lifecycle method
   * Params        :
   **/
  componentDidMount() {
    this.isComponentLoaded = true;
    const { values } = this.props;

    setTimeout(() => {
      let filtObj = null; //default will get set then
      this.props.setTaskListRelDataOfSingleTask(
        "setChangedFilterOption",
        filtObj,
        {}
      );
    }, 900);

    // set list data to blank state
    this.loadListFromBeginning({});
  }

  /**
   * TaskListOfMultiTask
   * Function name : componentWillUnmount
   * Author        :
   * Created Date  : 24-03-2020
   * Purpose       : lifecycle method
   * Params        :
   **/
  componentWillUnmount() {
    this.isComponentLoaded = false;
    commonnotitrack("CLOSE_SPLITSCREEN", {
      notiType: "CLOSE_SPLITSCREEN",
      triggerCallback: false,
      trigerredFrom: "MULTI_TASK_LIST",
    });
  }

  loadListFromBeginning = (paramObj) => {
    setTimeout(() => {
      // call list api
      let paramObj = {
        pageNum: 1,
        pageNumArTrack: [],
        totalNumberOfPages: 1,
        listData: [],
      };
      this.isComponentLoaded &&
        this.setState(paramObj, () => {
          this.showLoader();
          this.callServiceToFetchList(
            paramObj,
            "*****called from componentDidMount**** "
          );
        });
    }, 1005);
  };

  /**
   * TaskListOfMultiTask
   * Function name : callServiceToFetchList
   * Author        :
   * Created Date  : 27-03-2020
   * Purpose       : callServiceToFetchList
   * Params        :
   **/

  callServiceToFetchList = (paramObj, trackcalledfrom) => {
    //console.log(trackcalledfrom);

    const { values } = this.props;
    const defaultFilterOptn = values.defaultFilterOptn;
    const defaultSortOptn = values.defaultSortOptn;
    const multiTaskId = values.multiTaskId;

    let paramObjData = {};
    paramObjData.pageNum = paramObj.pageNum;
    paramObjData.filterOption = defaultFilterOptn;
    paramObjData.sortOption = defaultSortOptn;
    paramObjData.multiTaskId = multiTaskId;

    let pageNumArTrack = [...this.state.pageNumArTrack];

    // restrict repeated pagenum related api call
    if (pageNumArTrack.indexOf(paramObjData.pageNum) > -1) {
      //console.log("******Sorry cant hit api with page number => "+paramObjData.pageNum+" **********");
      return false;
    } else {
      pageNumArTrack.push(paramObjData.pageNum);
      this.isComponentLoaded &&
        this.setState({ pageNumArTrack: pageNumArTrack });
    }

    let stcservice = new TaskListRelatedToMultiTaskService();
    stcservice
      .getTasksListOfMultiTask(paramObjData)
      .then((data) => {
        // header key access sample
        //console.log("data ===>>>>>>",data," total page count=>",data["headers"]["total-page-count"],data["headers"]["expires"]);

        if (data.status == 200) {
          let respData = {};
          if (data.data.entity !== undefined) {
            respData = data.data.entity;

            if (respData.taskDetails !== undefined) {
              if (respData.taskDetails.length > 0) {
                // set list data to state
                let prevRecords = [...this.state.listData];
                let currRecords = respData.taskDetails;
                let concatenateRecords = prevRecords.concat(currRecords);
                this.isComponentLoaded &&
                  this.setState(
                    {
                      listData: concatenateRecords,
                      totalNumberOfPages: respData.totalNumberOfPages,
                    },
                    () => {}
                  );
              } else {
                //console.log("========== empty data=========");
                if (this.state.listData.length == 0) {
                  this.isComponentLoaded &&
                    this.setState({
                      listData: [],
                      totalNumberOfPages: respData.totalNumberOfPages,
                      pageNum: 1,
                    });
                }
              }
            } else {
            }
          }
        }
      })
      .catch((err) => {
        let errStatusCodeAr = [400, 500]; // allowed error status code
        if (err.response && err.response.data) {
          let erRespData = err.response.data;
          if (errStatusCodeAr.indexOf(erRespData.status) !== -1) {
            let errMsg = erRespData.message
              ? erRespData.message
              : "Some technical problem occurred.";
            console.log(errMsg);
          }
        }
      })
      .finally(() => {
        this.hideLoader();
      });
  };

  //newly added

  scrollToTop = () => {
    //console.log(" ***** called scrollToBottom *****");

    this.scrollerRef.current.scrollTop = 0;
  };
  /**
   * TaskListOfMultiTask
   * Function name : callSetChangedFilterOption
   * Author        :
   * Created Date  : 27-03-2020
   * Purpose       : callSetChangedFilterOption
   * Params        :
   **/

  callSetChangedFilterOption = (filtObj) => {
    // let scobj = this.scrollerRef.current;
    //  scobj.scrollToTop();

    //newly added
    this.scrollToTop();

    this.props.setTaskListRelDataOfSingleTask(
      "setChangedFilterOption",
      filtObj,
      {}
    );
    // set list data to blank state
    let paramObj = {
      listData: [],
      pageNum: 1,
      pageNumArTrack: [],
      totalNumberOfPages: 1,
    };
    this.isComponentLoaded &&
      this.setState(paramObj, () => {
        this.showLoader();

        setTimeout(() => {
          // call list api
          let paramObj1 = {};
          this.callServiceToFetchList(
            paramObj1,
            "*****called from callSetChangedFilterOption**** "
          );
        }, 1005);
      });
  };

  /**
   * TaskListOfMultiTask
   * Function name : callSetChangedSortOption
   * Author        :
   * Created Date  : 27-03-2020
   * Purpose       : callSetChangedSortOption
   * Params        :
   **/

  callSetChangedSortOption = (filtObj) => {
    // let scobj = this.scrollerRef.current;
    // scobj.scrollToTop();

    //newly added
    this.scrollToTop();

    this.props.setTaskListRelDataOfSingleTask(
      "setChangedSortOption",
      filtObj,
      {}
    );
    // set list data to blank state
    let paramObj = {
      listData: [],
      pageNum: 1,
      pageNumArTrack: [],
      totalNumberOfPages: 1,
    };
    this.isComponentLoaded &&
      this.setState(paramObj, () => {
        this.showLoader();
        setTimeout(() => {
          // call list api
          let paramObj1 = {};

          this.callServiceToFetchList(
            paramObj1,
            "*****called from callSetChangedSortOption **** "
          );
        }, 1005);
      });
  };

  /**
   * TaskListOfMultiTask
   * Function name : loadLoader
   * Author        :
   * Created Date  : 27-3-2020
   * Purpose       : Load loader
   * Params        :
   **/

  loadLoader() {
    return (
      <CustLoader className={"smallLoaderGreen"} loaderText={"Loading.."} />
    );
  }

  /**
   * TaskListOfMultiTask
   * Function name : hideLoader
   * Author        :
   * Created Date  : 27-3-2020
   * Purpose       : Hide loader
   * Params        :
   **/

  hideLoader() {
    this.isComponentLoaded && this.setState({ loaderFlag: false });
  }

  /**
   * TaskListOfMultiTask
   * Function name : showLoader
   * Author        :
   * Created Date  : 27-3-2020
   * Purpose       : showLoader
   * Params        :
   **/

  showLoader() {
    this.isComponentLoaded && this.setState({ loaderFlag: true });
  }

  // trackScroll = ()=>{

  //       let scobj = this.scrollerRef.current;
  //       let scrollTop = scobj.getValues().scrollTop;
  //       let scrollHeight = scobj.getScrollHeight();
  //       let clientHeight = scobj.getClientHeight();

  //       let prevPageNum = this.state.pageNum;
  //       let totalNumberOfPages = this.state.totalNumberOfPages;

  //       if((scrollHeight-clientHeight)==scrollTop && prevPageNum < totalNumberOfPages){

  //         this.showLoader();

  //         setTimeout(()=>{
  //           // call list api
  //           let pageNumData = prevPageNum + 1;
  //           let paramObj = {"pageNum":pageNumData};

  //           this.isComponentLoaded && this.setState({ pageNum: pageNumData },()=>{
  //             this.callServiceToFetchList(paramObj,"***called from scroll bar***");
  //           });

  //           },900);

  //       }

  // }

  fetchMoreData = () => {
    let prevPageNum = this.state.pageNum;
    let totalNumberOfPages = this.state.totalNumberOfPages;

    if (prevPageNum < totalNumberOfPages) {
      this.showLoader();

      setTimeout(() => {
        // call list api
        let pageNumData = prevPageNum + 1;
        let paramObj = { pageNum: pageNumData };

        this.isComponentLoaded &&
          this.setState({ pageNum: pageNumData }, () => {
            this.callServiceToFetchList(
              paramObj,
              "***called from scroll bar***"
            );
          });
      }, 900);
    }
  };

  getTaskDetailData = (param) => {
    let paramObjData = param.paramObjData;
    let paramsCntxt = param.paramsCntxt;

    let lservdrf = new TaskDetailsService();
    lservdrf
      .getTaskDetail(paramObjData)
      .then((data) => {
        let entityObj = data.data.entity;

        // context share
        let setShare =
          this.context !== undefined && this.context.setShare !== undefined
            ? this.context.setShare
            : null;
        if (setShare) {
          paramsCntxt["other"]["taskResp"] = entityObj;

          setShare(param.paramsCntxt);
        }
      })
      .catch((e) => {
        console.log("server error=>", e);
      })
      .finally(() => {});
  };

  // Newest1 - Start

  // loadLawyerInfoPopup = (i, otherParam) => {
  //   //console.log(this.state.listData[i].userDetails);
  //   let practiceAreas = [];
  //   let locations = [];
  //   let lawyerName = "";
  //   let lawyerFname = "";
  //   let lawyerLname = "";
  //   let bioData = "";
  //   let expData = "";
  //   let ratingData = "";

  //   let profilePictureURL = null;

  //   let userInfoObj =
  //     otherParam.assignedTo !== undefined ? otherParam.assignedTo : null;
  //   if (userInfoObj) {
  //     lawyerFname =
  //       userInfoObj.firstName !== undefined && userInfoObj.firstName !== null
  //         ? userInfoObj.firstName
  //         : "";

  //     lawyerLname =
  //       userInfoObj.lastName !== undefined && userInfoObj.lastName !== null
  //         ? userInfoObj.lastName
  //         : "";

  //     lawyerName = lawyerFname + " " + lawyerLname;

  //     practiceAreas =
  //       userInfoObj.practiceAreas !== undefined
  //         ? userInfoObj.practiceAreas
  //         : [];

  //     locations =
  //       userInfoObj.locations !== undefined ? userInfoObj.locations : [];

  //     profilePictureURL =
  //       userInfoObj.profilePictureURL !== undefined
  //         ? userInfoObj.profilePictureURL
  //         : null;

  //     bioData =
  //       userInfoObj.bio !== undefined && userInfoObj.bio !== null
  //         ? userInfoObj.bio
  //         : "";

  //     expData =
  //       userInfoObj.workExperience !== undefined &&
  //       userInfoObj.workExperience !== null
  //         ? userInfoObj.workExperience + " years"
  //         : "";

  //     ratingData =
  //       userInfoObj.rating !== undefined && userInfoObj.rating !== null
  //         ? userInfoObj.rating
  //         : "";
  //   }

  //   return (
  //     <div
  //       className="dropdown-menu user_popDetails_block"
  //       aria-labelledby="dropdownMenuButton"
  //     >
  //       <div className="user_popBox">
  //         <div className="u_popBox_sec user_popBox_top">
  //           <div className="user_popBox_topLeft">
  //             <h4>{lawyerName}</h4>
  //             <div className="userRating_block">
  //               <div className="ass_userRating">
  //                 <i className="fa fa-star" aria-hidden="true" /> {ratingData}
  //               </div>
  //               <div className="ass_userExp">Experience - {expData}</div>
  //             </div>
  //           </div>
  //           <div className="user_popBox_topRight">
  //             {profilePictureURL !== null && <img src={profilePictureURL} />}
  //             {profilePictureURL === null && (
  //               <div className="user_name_text">
  //                 <span>{getStringInitials(lawyerName)}</span>
  //               </div>
  //             )}
  //           </div>
  //         </div>
  //         <div className="u_popBox_sec user_popBox_location">
  //           <h5>Locations</h5>
  //           <ul>
  //             {locations !== null &&
  //               Object.keys(locations).map((incl, edt) => (
  //                 <li key={userInfoObj.userId + "0" + incl}>
  //                   {locations[incl]}
  //                 </li>
  //               ))}
  //           </ul>
  //         </div>
  //         <div className="u_popBox_sec user_popBox_pracArea">
  //           <h5>Practice areas</h5>
  //           <ul>
  //             {practiceAreas !== null &&
  //               Object.keys(practiceAreas).map((prci, e) => (
  //                 <li key={userInfoObj.userId + "0" + prci}>
  //                   {practiceAreas[prci]}
  //                 </li>
  //               ))}
  //           </ul>
  //         </div>
  //         <div className="u_popBox_sec user_popBox_about">
  //           <h5>About</h5>
  //           <p>{bioData}</p>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  /*** Professional View Display Modal starts  */

  /**
   * TaskListOfMultiTask
   * Function name : pVDisplayModalClose
   * Author        :
   * Created Date  : 9-7-2021
   * Purpose       : To close pVDisplay modal
   * Params        :
   **/
  pVDisplayModalClose = () => {
    this.isComponentLoaded &&
      this.setState({ showPVDisplayModal: false }, () => {
        this.modalforpvdisplayRef.current.hideModal();
      });
  };

  /**
   * TaskListOfMultiTask
   * Function name : getPVDisplayModalContent
   * Author        :
   * Created Date  : 9-7-2021
   * Purpose       : Get pVDisplay modal view with data
   * Params        : userId
   **/
  getPVDisplayModalContent = (userId) => {
    let viewData = (
      <ProfileNewMod
        closingFunction={this.pVDisplayModalClose}
        lprofileId={userId}
      />
    );

    this.modalforpvdisplayRef.current.updateStateContent(viewData);
    this.modalforpvdisplayRef.current.showContent();
    return viewData;
  };

  /**
   * TaskListOfMultiTask
   * Function name : pVDisplayModalOpen
   * Author        :
   * Created Date  : 9-7-2021
   * Purpose       : To open pVDisplay modal
   * Params        : userId
   **/
  pVDisplayModalOpen = (userId) => {
    this.isComponentLoaded &&
      this.setState({ showPVDisplayModal: true }, () => {
        this.modalforpvdisplayRef.current.showModal();
        this.getPVDisplayModalContent(userId);
      });
  };

  /*** Professional View Display Modal ends  */

  /*** Professional View Disclaimer Modal starts  */

  /**
   * TaskListOfMultiTask
   * Function name : pVDiscModCloseDispModOpen
   * Author        :
   * Created Date  : 9-7-2021
   * Purpose       : To close pVDisclaimer modal and conditionally open pVDisplay modal
   * Params        : shouldDisplay, userId
   **/
  pVDiscModCloseDispModOpen = (shouldDisplay, userId) => {
    this.isComponentLoaded &&
      this.setState({ showPVDisclaimerModal: false }, () => {
        this.modalforpvdisclaimerRef.current.hideModal();
        if (shouldDisplay) {
          setTimeout(() => {
            this.pVDisplayModalOpen(userId);
          }, 300);
        }
      });
  };

  /**
   * TaskListOfMultiTask
   * Function name : getPVDisclaimerModalContent
   * Author        :
   * Created Date  : 9-7-2021
   * Purpose       : Get pVDisclaimer modal view with data
   * Params        : userId
   **/
  getPVDisclaimerModalContent = (userId) => {
    let viewData = (
      <div className="dash_modal">
        <div className="modal-header">
          <h4 className="modal-title modal_title_tool">
            View Professional’s Profile
            {/*<div className="input_tooltip_bg">
                      <span className="input_tooltip" aria-label="Organisation's Details" data-balloon-pos="down">
                      <i className="fa fa-question-circle"></i>
                      </span>
                      </div>*/}
          </h4>
          <button
            type="button"
            onClick={() => {
              this.pVDiscModCloseDispModOpen(false, null);
            }}
            className="close modal_close"
            data-dismiss="modal"
          >
            &times;
          </button>
        </div>
        {/* <CustomMessageBar /> */}
        <div className="modal-body padding_top">
          <div className="fileUp_modalBox">
            <p>
              Disclaimer: By clicking on "View" you agree that you have sought
              this information of your own accord. You further agree that
              neither the Professional nor Flywork has solicited and/or
              advertised to induce you for work.
            </p>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              this.pVDiscModCloseDispModOpen(false, null);
            }}
            data-dismiss="modal"
            class="btn_appearance_none modal_text_btn"
          >
            <span>Cancel</span>
          </button>
          <button
            type="button"
            className="btn_appearance_none green_btn"
            onClick={(e) => {
              e.preventDefault();
              this.pVDiscModCloseDispModOpen(true, userId);
            }}
            data-dismiss="modal"
          >
            <span>View</span>
          </button>
        </div>
      </div>
    );

    this.modalforpvdisclaimerRef.current.updateStateContent(viewData);
    this.modalforpvdisclaimerRef.current.showContent();
    return viewData;
  };

  /**
   * TaskListOfMultiTask
   * Function name : pVDisclaimerModalOpen
   * Author        :
   * Created Date  : 9-7-2021
   * Purpose       : To open pVDisclaimer modal
   * Params        : userId
   **/
  pVDisclaimerModalOpen = (userId) => {
    this.isComponentLoaded &&
      this.setState({ showPVDisclaimerModal: true }, () => {
        this.modalforpvdisclaimerRef.current.showModal();
        this.getPVDisclaimerModalContent(userId);
      });
  };

  /*** Professional View Disclaimer Modal ends  */

  loadAssignedLi = (inc, otherParam) => {
    let assignedTo = otherParam.assignedTo;
    let lid = otherParam.lid;

    let lawyerNameData = "";
    let lawyerFnameData = "";
    let lawyerLnameData = "";
    let profilePictureURL = null;
    let userId = null;

    lawyerFnameData =
      assignedTo.firstName !== undefined && assignedTo.firstName !== null
        ? assignedTo.firstName
        : "";

    lawyerLnameData =
      assignedTo.lastName !== undefined && assignedTo.lastName !== null
        ? assignedTo.lastName
        : "";

    lawyerNameData = lawyerFnameData + " " + lawyerLnameData;

    profilePictureURL =
      assignedTo.profilePictureURL !== undefined
        ? assignedTo.profilePictureURL
        : null;

    userId = assignedTo.userId ? assignedTo.userId : null;

    if (profilePictureURL !== null) {
      return inc < 2 ? (
        <li key={lid}>
          <div className="onClick_userDetails onClick_userImg">
            <div className="ass_user_name ">
              <button
                className="btn_appearance_none assignUser_nameBtn"
                onClick={(evn) => {
                  evn.preventDefault();
                  this.pVDisclaimerModalOpen(userId);
                }}
                // type="button"
                // data-toggle="dropdown"
                // aria-expanded="true"
              >
                <img src={profilePictureURL} />
              </button>
              {/* {this.loadLawyerInfoPopup(inc, otherParam)} */}
            </div>
          </div>
        </li>
      ) : (
        ""
      );
    } else {
      return inc < 2 ? (
        <li key={lid}>
          <div className="onClick_userDetails onClick_userImg">
            <div className="ass_user_name ">
              <button
                className="btn_appearance_none assignUser_nameBtn"
                onClick={(evn) => {
                  evn.preventDefault();
                  this.pVDisclaimerModalOpen(userId);
                }}
                // type="button"
                // data-toggle="dropdown"
                // aria-expanded="true"
              >
                <div className="user_name_text">
                  <span>{getStringInitials(lawyerNameData)}</span>
                </div>
              </button>
              {/* {this.loadLawyerInfoPopup(inc, otherParam)} */}
            </div>
          </div>
        </li>
      ) : (
        ""
      );
    }
  };

  // Newest1 - End

  getListStructure() {
    const { values } = this.props;

    const defaultFilterOptn = values.defaultFilterOptn;
    const defaultSortOptn = values.defaultSortOptn;

    //*** get selected taskId for split screen starts ***
    let sharedData =
      this.context !== undefined && this.context.share !== undefined
        ? this.context.share
        : null;
    let taskIdForSplitScreen = 0;
    if (
      sharedData !== undefined &&
      sharedData !== null &&
      sharedData.actionType !== undefined
    ) {
      if (sharedData.actionType == "SPLITSCREEN") {
        taskIdForSplitScreen =
          sharedData.taskId !== undefined ? sharedData.taskId : 0;
      }
    }
    //*** get selected taskId for split screen ends ***

    let taskListStructAr = [];

    this.state.listData.length > 0 &&
      Object.keys(this.state.listData).map((e, i) => {
        let totalUnreadNegotiationMessages =
          this.state.listData[e].totalUnreadNegotiationMessages !== undefined
            ? this.state.listData[e].totalUnreadNegotiationMessages
            : 0;

        let totalUnreadComments =
          this.state.listData[e].totalUnreadComments !== undefined
            ? this.state.listData[e].totalUnreadComments
            : 0;

        let totalNewTimeLogs =
          this.state.listData[e].totalNewTimeLogs !== undefined
            ? this.state.listData[e].totalNewTimeLogs
            : 0;

        let totalNewDocsUploaded =
          this.state.listData[e].totalNewDocsUploaded !== undefined
            ? this.state.listData[e].totalNewDocsUploaded
            : 0;

        let totTaskListRelMsgCnt = 0;
        if (totalUnreadNegotiationMessages > 0) {
          ++totTaskListRelMsgCnt;
        }
        if (totalUnreadComments > 0) {
          ++totTaskListRelMsgCnt;
        }

        if (totalNewTimeLogs > 0) {
          ++totTaskListRelMsgCnt;
        }

        if (totalNewDocsUploaded > 0) {
          ++totTaskListRelMsgCnt;
        }

        taskListStructAr.push(
          <li
            key={i}
            className={`tasklist_li ${
              this.state.listData[e].taskType != "SAVED_DRAFT" &&
              taskIdForSplitScreen != this.state.listData[e].taskId
                ? ""
                : " active_dominosBlock "
            } `}
          >
            <div className="task_listing_block task_listing_block_multi">
              <div className="task_popup_bg">
                <div className="task_box">
                  {/* === tasktop right ===  */}
                  <div className="assign_img task_status_top">
                    <div className="dropdown tasktop_statusbtn">
                      <button
                        className="btn_appearance_none taskStatus_btn"
                        type="button"
                        data-toggle="dropdown"
                      >
                        <i className="fa fa-ellipsis-h" aria-hidden="true" />
                      </button>

                      {this.state.listData[e].taskType != "CLOSED_TASK" && (
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <div className="taskstat_dropmenu">
                            <ul>
                              <li>
                                <a
                                  href=""
                                  onClick={(evnt) => {
                                    evnt.preventDefault();

                                    // set taskid in context ends
                                    if (
                                      this.state.listData[e].taskType ==
                                      "SAVED_DRAFT"
                                    ) {
                                      // set taskid in context starts
                                      let setShare =
                                        this.context !== undefined &&
                                        this.context.setShare !== undefined
                                          ? this.context.setShare
                                          : null;

                                      let paramsCntxt = {};

                                      if (sharedData) {
                                        sharedData["taskId"] =
                                          this.state.listData[e].taskId;
                                        sharedData["actionType"] = "DRAFT";

                                        paramsCntxt = sharedData;
                                      } else {
                                        paramsCntxt = {
                                          taskId: this.state.listData[e].taskId,
                                          actionType: "DRAFT",
                                        };
                                      }

                                      if (setShare) {
                                        setShare(paramsCntxt);
                                      }

                                      // then go to task create panel
                                      this.props.goToCreateTaskPage();
                                    } else {
                                      // open right side panel to edit

                                      let paramsCntxt = {};
                                      //
                                      if (sharedData) {
                                        sharedData["taskId"] =
                                          this.state.listData[e].taskId;
                                        sharedData["actionType"] =
                                          "SPLITSCREEN";
                                        sharedData["leftTabToActive"] =
                                          "TASKDETAILS";
                                        sharedData["purpose"] = "EDIT";
                                        sharedData["other"] = {
                                          subTasksCount:
                                            this.state.listData[e]
                                              .subTasksCount,
                                        };

                                        paramsCntxt = sharedData;
                                      } else {
                                        paramsCntxt = {
                                          taskId: this.state.listData[e].taskId,
                                          actionType: "SPLITSCREEN",
                                          leftTabToActive: "TASKDETAILS",
                                          purpose: "EDIT",
                                          other: {
                                            subTasksCount:
                                              this.state.listData[e]
                                                .subTasksCount,
                                          },
                                        };
                                      }

                                      let paramObjData = {
                                        data: {
                                          taskId: this.state.listData[e].taskId,
                                        },
                                      };
                                      let param = {
                                        paramObjData: paramObjData,
                                        paramsCntxt: paramsCntxt,
                                      };

                                      this.getTaskDetailData(param);
                                    }
                                  }}
                                >
                                  Edit
                                </a>
                              </li>
                              {this.state.listData[e].assignedTo !==
                                undefined &&
                                this.state.listData[e].assignedTo === null && (
                                  <li>
                                    <a
                                      href=""
                                      onClick={(evnt) => {
                                        evnt.preventDefault();

                                        this.isComponentLoaded &&
                                          this.setState(
                                            {
                                              taskId:
                                                this.state.listData[e].taskId,
                                              taskIdName:
                                                this.state.listData[e].taskName,
                                            },
                                            () => {
                                              let otherParam = {
                                                taskType:
                                                  this.state.listData[e]
                                                    .taskType,
                                              };
                                              this.deleteModalOpen(otherParam);
                                            }
                                          );
                                      }}
                                    >
                                      Delete
                                    </a>
                                  </li>
                                )}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* Old taskKind commented starts */}
                    {/* <div className="taskstatus_color">
                  <span className={`taskstat_clr_span 
                  ${this.state.listData[e].taskKind=="DRAFTS"?"taskstat_clr_gray":""}
                  ${this.state.listData[e].taskKind=="TO_ASSIGN"?"taskstat_clr_orange":""}
                  ${this.state.listData[e].taskKind=="ASSIGNED"?"taskstat_clr_green":""}
                  ${this.state.listData[e].taskKind=="CLOSED"?"taskstat_clr_blue":""}
                  ` } />
                </div> */}
                    {/* Old taskKind commented ends */}
                    <div className="taskstatus_color">
                      <span
                        className={`taskstat_clr_span 
                              ${
                                this.state.listData[e].taskType == "SAVED_DRAFT"
                                  ? "taskstat_clr_gray"
                                  : ""
                              }
                              ${
                                this.state.listData[e].taskType == "OPEN_TASK"
                                  ? "taskstat_clr_sky"
                                  : ""
                              }
                              ${
                                this.state.listData[e].taskType ==
                                "TASK_OFFERED"
                                  ? "taskstat_clr_orange"
                                  : ""
                              }
                              ${
                                this.state.listData[e].taskType ==
                                "TASK_ASSIGNED"
                                  ? "taskstat_clr_green"
                                  : ""
                              }
                              ${
                                this.state.listData[e].taskType == "INVOICE_DUE"
                                  ? "taskstat_clr_red"
                                  : ""
                              }
                              ${
                                this.state.listData[e].taskType ==
                                "INVOICE_PAID"
                                  ? "taskstat_clr_deepGreen"
                                  : ""
                              } 
                              ${
                                this.state.listData[e].taskType == "CLOSED_TASK"
                                  ? "taskstat_clr_blue"
                                  : ""
                              }           

                           `}
                      >
                        <span className="taskstat_clr_spanName">
                          {this.state.listData[e].taskType == "SAVED_DRAFT"
                            ? "Saved Draft"
                            : ""}
                          {this.state.listData[e].taskType == "OPEN_TASK"
                            ? "Open Task"
                            : ""}
                          {this.state.listData[e].taskType == "TASK_OFFERED"
                            ? "Task Offered"
                            : ""}
                          {this.state.listData[e].taskType == "TASK_ASSIGNED"
                            ? "Task Assigned"
                            : ""}
                          {this.state.listData[e].taskType == "INVOICE_DUE"
                            ? "Invoice Due"
                            : ""}
                          {this.state.listData[e].taskType == "INVOICE_PAID"
                            ? "Invoice Paid"
                            : ""}
                        </span>
                      </span>
                    </div>
                    <div className="taskstatus_msg">
                      <p>Messages ({totTaskListRelMsgCnt})</p>
                    </div>
                    {totTaskListRelMsgCnt > 0 && (
                      <div className="taskstatus_color taskstatus_col_new">
                        <span className="taskstat_clr_span_new">New</span>
                      </div>
                    )}
                    {/* {this.state.listData[e].taskKind =="INVOICE_DUE" && (
                      <div className="taskstatus_flag">
                            <a href="" onClick={(evn)=>{evn.preventDefault();}}>
                              <img src={getBaseUrl()+"assets/images/invoice_due_flag.png"}    />
                            </a>
                      </div>

                ) } */}

                    <div className="clearboth" />
                  </div>
                  {/* === tasktop right ===  */}
                  <div className="panel60 task_des">
                    <div className="task_left_desc">
                      {/* <h1>filter=={defaultFilterOptn.key} ind=={i} taskid={this.state.listData[e].taskId} </h1> */}
                      <h1>
                        {this.state.listData[e].practiceAreas &&
                        this.state.listData[e].practiceAreas.length > 0
                          ? this.state.listData[e].practiceAreas[0].practiceArea
                          : ""}
                      </h1>
                      <h2>{this.state.listData[e].taskName}</h2>
                    </div>
                    <div className="task_assigned">
                      {defaultSortOptn.key == "DUE_DATE" && (
                        <h3>
                          Due Date -
                          {this.state.listData[e].dueDateMillis
                            ? CustDate.custFormatDate(
                                new Date(this.state.listData[e].dueDateMillis),
                                3
                              )
                            : "None"}
                        </h3>
                      )}

                      {defaultSortOptn.key == "CREATED_DATE" && (
                        <h3>
                          Created Date -
                          {this.state.listData[e].createdAtMillis
                            ? CustDate.custFormatDate(
                                new Date(
                                  this.state.listData[e].createdAtMillis
                                ),
                                3
                              )
                            : "None"}
                        </h3>
                      )}

                      {defaultSortOptn.key == "BUDGET" && (
                        <h3>
                          Created Date -
                          {this.state.listData[e].createdAtMillis
                            ? CustDate.custFormatDate(
                                new Date(
                                  this.state.listData[e].createdAtMillis
                                ),
                                3
                              )
                            : "None"}
                        </h3>
                      )}

                      {defaultSortOptn.key == "ASSIGNED_DATE" && (
                        <h3>
                          Created Date -
                          {this.state.listData[e].createdAtMillis
                            ? CustDate.custFormatDate(
                                new Date(
                                  this.state.listData[e].createdAtMillis
                                ),
                                3
                              )
                            : "None"}
                        </h3>
                      )}

                      <div className="assign_to_block">
                        <span className="t_as_span">Assigned to</span>
                        <span className="t_as_ul">
                          <ul>
                            {/* for assign data available */}
                            {this.state.listData[e] !== undefined &&
                              this.state.listData[e].assignedTo !== undefined &&
                              this.state.listData[e].assignedTo !== null &&
                              this.state.listData[e].assignedTo.map(
                                (ed, inc) => {
                                  let lawyerNameData = "";
                                  let lawyerFnameData = "";
                                  let lawyerLnameData = "";
                                  let profilePictureURL = null;

                                  lawyerFnameData =
                                    this.state.listData[e].assignedTo[inc]
                                      .firstName !== undefined &&
                                    this.state.listData[e].assignedTo[inc]
                                      .firstName !== null
                                      ? this.state.listData[e].assignedTo[inc]
                                          .firstName
                                      : "";

                                  lawyerLnameData =
                                    this.state.listData[e].assignedTo[inc]
                                      .lastName !== undefined &&
                                    this.state.listData[e].assignedTo[inc]
                                      .lastName !== null
                                      ? this.state.listData[e].assignedTo[inc]
                                          .lastName
                                      : "";

                                  lawyerNameData =
                                    lawyerFnameData + " " + lawyerLnameData;

                                  profilePictureURL =
                                    this.state.listData[e].assignedTo[inc]
                                      .profilePictureURL !== undefined
                                      ? this.state.listData[e].assignedTo[inc]
                                          .profilePictureURL
                                      : null;

                                  return this.loadAssignedLi(inc, {
                                    assignedTo:
                                      this.state.listData[e].assignedTo[inc],
                                    lid:
                                      "kassav1" +
                                      inc +
                                      i.toString() +
                                      this.state.listData[e].taskId,
                                  });

                                  // if(profilePictureURL!==null){
                                  //   return inc<2?(<li>
                                  //     <img src={this.state.listData[e].assignedTo[inc].profilePictureURL}    />
                                  //   </li>):"";
                                  // }else{
                                  //   return  (<li><div className="user_name_text">
                                  //               <span>{getStringInitials(lawyerNameData)}</span>
                                  //           </div></li>
                                  //   )
                                  // }
                                }
                              )}
                            {/* for assign data not available */}
                            <li>
                              {(this.state.listData[e] !== undefined &&
                                this.state.listData[e].assignedTo ===
                                  undefined) ||
                                (this.state.listData[e].assignedTo === null && (
                                  <span className="t_as_ul_text"> None</span>
                                ))}
                            </li>
                            {/* for assign data + more  */}
                            <li>
                              {this.state.listData[e] !== undefined &&
                              this.state.listData[e].assignedTo !== undefined &&
                              this.state.listData[e].assignedTo !== null &&
                              this.state.listData[e].assignedTo.length > 2
                                ? this.state.listData[e].assignedTo.length -
                                  2 +
                                  " more"
                                : ""}
                              <span className="t_as_ul_text"></span>
                            </li>
                            {/* for assign data empty array available */}
                            <li>
                              {this.state.listData[e] !== undefined &&
                              this.state.listData[e].assignedTo !== undefined &&
                              this.state.listData[e].assignedTo !== null &&
                              this.state.listData[e].assignedTo.length == 0
                                ? " None"
                                : ""}
                              <span className="t_as_ul_text"></span>
                            </li>
                          </ul>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="panel40 task_date">
                    <div className="taskfun_btnbg">
                      {this.state.listData[e].taskType != "SAVED_DRAFT" &&
                        taskIdForSplitScreen !=
                          this.state.listData[e].taskId && (
                          <button
                            onClick={(evnt) => {
                              evnt.preventDefault();

                              if (
                                this.state.listData[e].taskType == "SAVED_DRAFT"
                              ) {
                              } else {
                                // to open right side panel to view
                                // set taskid in context starts
                                // open right side panel to edit

                                let paramsCntxt = {};
                                //
                                if (sharedData) {
                                  sharedData["taskId"] =
                                    this.state.listData[e].taskId;
                                  sharedData["actionType"] = "SPLITSCREEN";
                                  sharedData["leftTabToActive"] = "TASKDETAILS";
                                  sharedData["purpose"] = "EDIT";
                                  sharedData["other"] = {
                                    subTasksCount:
                                      this.state.listData[e].subTasksCount,
                                  };

                                  paramsCntxt = sharedData;
                                } else {
                                  paramsCntxt = {
                                    taskId: this.state.listData[e].taskId,
                                    actionType: "SPLITSCREEN",
                                    leftTabToActive: "TASKDETAILS",
                                    purpose: "EDIT",
                                    other: {
                                      subTasksCount:
                                        this.state.listData[e].subTasksCount,
                                    },
                                  };
                                }

                                let paramObjData = {
                                  data: {
                                    taskId: this.state.listData[e].taskId,
                                  },
                                };
                                let param = {
                                  paramObjData: paramObjData,
                                  paramsCntxt: paramsCntxt,
                                };

                                this.getTaskDetailData(param);
                              }
                            }}
                            className="btn_appearance_none task_func_btn task_popup"
                          >
                            View Task
                          </button>
                        )}
                      {this.state.listData[e].taskType == "SAVED_DRAFT" && (
                        <button
                          onClick={(evnt) => {
                            evnt.preventDefault();
                            // set taskid in context starts
                            let setShare =
                              this.context !== undefined &&
                              this.context.setShare !== undefined
                                ? this.context.setShare
                                : null;

                            let paramsCntxt = {};

                            if (sharedData) {
                              sharedData["taskId"] =
                                this.state.listData[e].taskId;
                              sharedData["actionType"] = "DRAFT";
                            } else {
                              paramsCntxt = {
                                taskId: this.state.listData[e].taskId,
                                actionType: "DRAFT",
                              };
                            }

                            if (setShare) {
                              setShare(paramsCntxt);
                            }
                            // set taskid in context ends
                            if (
                              this.state.listData[e].taskType == "SAVED_DRAFT"
                            ) {
                              // then go to task create panel
                              this.props.goToCreateTaskPage();
                            }
                          }}
                          className="btn_appearance_none task_func_btn task_popup"
                        >
                          Edit Draft
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="clearboth" />
                </div>
              </div>
            </div>
          </li>
        );
      });

    return <>{taskListStructAr}</>;
  }

  deleteTask(paramObj) {
    let taskId = paramObj.taskId;
    let paramObjDeleteData = {};
    paramObjDeleteData[taskId] = [];

    let paramObjData = { data: paramObjDeleteData };
    let lservdrf = new DeleteTaskService();
    lservdrf
      .deleteTask(paramObjData)
      .then((data) => {
        // to fix scroll issue
        // let scobj = this.scrollerRef.current;
        // scobj.scrollToTop();

        //newly added
        this.scrollToTop();

        let notiMsg = "Deleted successfully";
        notify(notiMsg, true, {
          successMsg: 1,
          customWrapClass: "customerrmidlass",
          showCloseButton: false,

          dismiss: {
            duration: 1000,
          },
        });

        this.isComponentLoaded &&
          this.setState({ listData: [] }, () => {
            setTimeout(() => {
              // set list data to blank state
              this.showLoader();
              // call list api
              let paramObj = {
                pageNum: 1,
                pageNumArTrack: [],
                totalNumberOfPages: 1,
                taskId: 0,
                taskIdName: "",
              };
              this.isComponentLoaded &&
                this.setState(paramObj, () => {
                  this.callServiceToFetchList(
                    paramObj,
                    "*****called from deleteTask**** "
                  );
                });
            }, 1200);
          });

        setTimeout(() => {
          this.deleteModalClose();
        }, 1150);

        setTimeout(() => {
          this.closeSplit({ trigerredFrom: "MULTI_TASK_LIST" });
        }, 1200);
      })
      .catch((err) => {
        let errStatusCodeAr = [400, 500]; // allowed error status code
        if (err.response && err.response.data) {
          let erRespData = err.response.data;
          if (errStatusCodeAr.indexOf(erRespData.status) !== -1) {
            let errMsg = erRespData.message
              ? erRespData.message
              : "Some technical problem occurred.";
            //console.log.log(" errMsg on task creation==>",errMsg);

            let notiMsg = errMsg;
            notify(notiMsg, true, {
              successMsg: 0,
              customWrapClass: "customerrmidlass",
              showCloseButton: false,

              dismiss: {
                duration: 2500,
              },
            });
          }
        }
      })
      .finally(() => {
        this.isComponentLoaded &&
          this.setState({ continueDisabled: false }, () => {
            this.getDeleteModalContent(paramObj);
          });
      });
  }

  closeSplit = (objParam) => {
    //********* for context starts *****
    // let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;
    // if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){

    //     if(sharedData.actionType=="SPLITSCREEN" ){

    //       let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;

    //       if(setShare){
    //         let paramsCntxt = {
    //           "taskId":"",
    //           "actionType":"",
    //           "leftTabToActive":"",
    //           "purpose":"",
    //           "other":{}
    //         }
    //         setShare(paramsCntxt);
    //       }

    //     }
    // }
    //********* for context ends *****
    commonnotitrack("CLOSE_SPLITSCREEN", {
      notiType: "CLOSE_SPLITSCREEN",
      triggerCallback: false,
      trigerredFrom: "MULTI_TASK_LIST",
    });
  };

  /*** delete modal starts  */
  /**
   * TaskListOfMultiTask
   * Function name : TaskListOfMultiTask
   * Author        :
   * Created Date  : 29-3-2020
   * Purpose       : To open delete modal
   * Params        :
   **/
  deleteModalOpen = (otherParam) => {
    this.isComponentLoaded &&
      this.setState({ showDeleteModal: true }, () => {
        this.modalfortaskdelRef.current.showModal();
        this.getDeleteModalContent();
      });
  };

  /**
   * TaskAddSingleTaskStep2
   * Function name : organisationDetailModalClose
   * Author        :
   * Created Date  : 11-2-2020
   * Purpose       : To close organisationDetailModal
   * Params        :
   **/
  deleteModalClose = () => {
    this.isComponentLoaded &&
      this.setState({ showDeleteModal: false }, () => {
        this.modalfortaskdelRef.current.hideModal();
      });
  };

  /**
   * TaskAddSingleTaskStep2
   * Function name : getOrganisationDetailModalOpen
   * Author        :
   * Created Date  : 11-2-2020
   * Purpose       : Get modal view with data
   * Params        :
   **/
  getDeleteModalContent() {
    const { cancelButtonAction } = this.props;

    let viewData = (
      <div className="dash_modal">
        <>
          <div className="modal-header">
            <h4 className="modal-title modal_title_tool">
              Delete Task
              {/*<div className="input_tooltip_bg">
                      <span className="input_tooltip" aria-label="Organisation's Details" data-balloon-pos="down">
                      <i className="fa fa-question-circle"></i>
                      </span>
                      </div>*/}
            </h4>
            <button
              type="button"
              onClick={() => {
                this.deleteModalClose();
              }}
              className="close modal_close"
              data-dismiss="modal"
            >
              &times;
            </button>
          </div>
          <CustomMessageBar />
          <div className="modal-body padding_top">
            <div className="popUP_text_p">
              <p>
                Are you sure you want to delete this task &nbsp; "
                <b>{this.state.taskIdName}</b>"?
              </p>
            </div>
          </div>
          <div className="modal-footer">
            <button
              disabled={this.state.continueDisabled}
              type="button"
              className="btn btn-primary modal_btn_save"
              onClick={(e) => {
                e.preventDefault();

                this.isComponentLoaded &&
                  this.setState({ continueDisabled: true }, () => {
                    this.getDeleteModalContent();
                    let paramObj = { taskId: this.state.taskId };
                    this.deleteTask(paramObj);
                  });
              }}
              data-dismiss="modal"
            >
              <span>Confirm</span>
            </button>
          </div>
        </>
      </div>
    );

    this.modalfortaskdelRef.current.updateStateContent(viewData);
    this.modalfortaskdelRef.current.showContent();
    return viewData;
  }

  /*** delete  modal related ends  */

  // trackNotiForCloseSplit = (otherParam) =>{
  //   let notiType =  otherParam.notiType!==undefined? otherParam.notiType: "";
  //   let trigerredFrom =  otherParam.trigerredFrom!==undefined? otherParam.trigerredFrom: "";
  //   if(notiType=="CLOSE_SPLITSCREEN"){
  //       let closeSplitHeaderCallFrm = ["MULTI_TASK_LIST"];
  //       if(closeSplitHeaderCallFrm.indexOf(trigerredFrom)!=-1){
  //               console.log("trigerredFrom=>>",trigerredFrom);
  //       }

  //   }
  // }
  /**
   * TaskListOfMultiTask
   * Function name : render
   * Author        :
   * Created Date  : 24-03-2020
   * Purpose       : lifecycle method
   * Params        :
   **/
  render() {
    const { values } = this.props;
    //console.log(" values==>",values," defSortOptions==>>",values.defSortOptions);
    const defaultFilterOptn = values.defaultFilterOptn;
    const defaultSortOptn = values.defaultSortOptn;
    const defFilterOptions = values.defMtFilterOptions;
    const defSortOptions = values.defSortOptions;
    const taskSingleOrMultiFlag = values.step == 1 ? false : true;
    const multiTaskId = values.multiTaskId;
    //console.log("  taskSingleOrMultiFlag==>",taskSingleOrMultiFlag);

    return (
      <React.Fragment>
        <div>
          <NotiEmitTrack
            callbackfuncforcommontrackofnoti={this.trackNotiForCloseSplit}
          />
          {/* Dashboard Main Screen content area start */}
          <div className="dashboard_cont_area tasklist_cont_areabg">
            {/* ===  DashBoard Main Body Start ===  */}
            <div className="cutom_container">
              <div className="tab-content">
                <div id className="tab-pane fade in active">
                  <div className="taskbtn">
                    <button
                      onClick={() => {
                        this.props.goToCreateTaskPage();
                      }}
                      className="sub_grd_btn create_tsk_btn"
                    >
                      <span>
                        <img
                          src={getBaseUrl() + "assets/images/add_icon.png"}
                        />
                      </span>
                      <span>Create a new task</span>
                    </button>
                  </div>
                  <div className="create_task_area create_task_area_heightAdjustment">
                    {/* ===  task Listing filter start === */}
                    <div className="task_area_filterbg">
                      <div className="filter_area">
                        {/* ===  task top filter start ===  */}
                        <div className="top_filter_left">
                          <div className="top_filter_backbtn_bg">
                            <button
                              onClick={(ev) => {
                                // set multi task
                                let filtObj = { key: "MULTI_TASKS", value: "" }; //default will get set then
                                this.props.setTaskListRelDataOfSingleTask(
                                  "setChangedFilterOption",
                                  filtObj,
                                  {}
                                );

                                setTimeout(() => {
                                  this.props.gotoToStep({ step: 2 });
                                }, 500);
                              }}
                              className="btn_appearance_none"
                            >
                              <img
                                className=" back_img_icon"
                                src="assets/images/Path-back.png"
                              />
                              Multiple Tasks {multiTaskId}
                            </button>
                          </div>
                        </div>
                        <div className="top_filter_right">
                          <div className="top_filter top_filter_1">
                            <span className="span_title">Filter</span>
                            <button
                              className="btn btn-secondary dropdown-toggle btn_appearance_none"
                              type="button"
                              data-toggle="dropdown"
                            >
                              {defaultFilterOptn && defaultFilterOptn.value}
                            </button>
                            <div
                              className={`dropdown-menu dropdown-menu-right`}
                            >
                              <ul>
                                {defFilterOptions &&
                                  Object.keys(defFilterOptions).map((e, i) => (
                                    <li key={i}>
                                      <a
                                        href=""
                                        onClick={(ev) => {
                                          ev.preventDefault();

                                          let filtObj = defFilterOptions[i];
                                          this.callSetChangedFilterOption(
                                            filtObj
                                          );
                                        }}
                                        className={`dropdown-item ${
                                          defaultFilterOptn &&
                                          defaultFilterOptn.key ==
                                            defFilterOptions[i].key
                                            ? "active"
                                            : ""
                                        }`}
                                      >
                                        {defFilterOptions[i].value}
                                      </a>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </div>
                          <div className="top_filter top_filter_2">
                            <div className="dropdown">
                              <span className="span_title">Sort</span>
                              <button
                                className="btn btn-secondary dropdown-toggle btn_appearance_none"
                                type="button"
                                data-toggle="dropdown"
                              >
                                {defaultSortOptn && defaultSortOptn.value}
                              </button>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <ul>
                                  {defSortOptions &&
                                    Object.keys(defSortOptions).map((e, i) => (
                                      <li key={i}>
                                        <a
                                          href=""
                                          onClick={(ev) => {
                                            ev.preventDefault();

                                            let filtObj = defSortOptions[i];
                                            this.callSetChangedSortOption(
                                              filtObj
                                            );
                                          }}
                                          className={`dropdown-item ${
                                            defaultSortOptn &&
                                            defaultSortOptn.key ==
                                              defSortOptions[i].key &&
                                            defaultSortOptn.key2 ==
                                              defSortOptions[i].key2
                                              ? "active"
                                              : ""
                                          }`}
                                        >
                                          {defSortOptions[i].value}
                                        </a>
                                      </li>
                                    ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* ===  task top filter end === */}
                        <div className="clearboth" />
                      </div>
                    </div>
                    {/* ===  task Listing filter end === */}
                    {/* ===  task Listing start start === */}
                    <div className="task_area">
                      {/* ===  Task Listing Section Start === */}
                      <div className="task_listing_scrollbg">
                        {/* <Scrollbars 
                                  ref={this.scrollerRef}
                                  style={{ height: 550 }}                  
                                  autoHeightMin={550}
                                  renderView={props => <div {...props} className="view tasklistscrollcustomclass"/>}

                                  onScrollStop={this.trackScroll}
                              > */}
                        <div
                          ref={this.scrollerRef}
                          id="scrollableDivTaskListofFromBundle"
                          style={{
                            flexDirection: "column",
                          }}
                          className="task_listing_scroll_react mScroll_auto new_autoHeightAdjust new_autoHeightAdjust_leftTaskDominous"
                        >
                          <InfiniteScroll
                            dataLength={this.state.listData.length} //This is important field to render the next data
                            next={this.fetchMoreData}
                            hasMore={true}
                            // loader={<h4>Loading...</h4>}
                            scrollableTarget="scrollableDivTaskListofFromBundle"
                            inverse={false}
                          >
                            <div className="task_listing_area">
                              {this.state.loaderFlag == false &&
                                this.state.listData.length == 0 && (
                                  <div className=" text-center no records found">
                                    No records found
                                  </div>
                                )}
                              <ul>{this.getListStructure()}</ul>
                              <div className=" text-center ">
                                {this.state.loaderFlag
                                  ? this.loadLoader()
                                  : null}
                              </div>
                            </div>
                          </InfiniteScroll>
                        </div>
                        {/* </Scrollbars> */}
                      </div>
                      {/* ===  Task Listing Section end === */}
                    </div>
                    {/* ===  task Listing start end === */}
                  </div>
                </div>
              </div>
            </div>
            {/* ===  DashBoard Main Body End ===  */}
          </div>
          {/* Dashboard Main Screen content area end */}
          {/* ===  DashBoard Main Body Footer Start ===  */}
          <div className="dash_main_footerbg bottomStickFooter bottomStickFooter_leftPart">
            <div className="dash_main_footer">
              <div className="dash_foot_leftbg">
                <div className="taskstatus_bg">
                  {/* =====  new Task Status =====  */}
                  <TaskStatusCodeComponent />
                  {/* =====  new Task Status =====  */}
                </div>
              </div>
              <div className="dash_foot_rightbg">
                <FeedbackAssistant />
              </div>
              <div className="clearboth" />
            </div>
          </div>
          {/* ===  DashBoard Main Body Footer Start ===  */}
        </div>

        {/* Confirm delete modal start */}
        <CustReactBootstrapModal
          ref={this.modalfortaskdelRef}
          backdrop={false}
          backdropClassName="custom_modal_class"
          animation={false}
          show={this.state.showDeleteModal}
          onShow={() => {}}
          onHide={() => {}}
          modalcustdialog="custom_modal_lg"
          loaderbodytitle="Organisation"
        />
        {/* Confirm delete modal end */}

        {/* Newest1 - Start */}

        {/* Professional View Disclaimer Modal start */}
        <CustReactBootstrapModal
          ref={this.modalforpvdisclaimerRef}
          backdrop={false}
          backdropClassName="custom_modal_class"
          animation={false}
          show={this.state.showPVDisclaimerModal}
          onShow={() => {}}
          onHide={() => {}}
          modalcustdialog="custom_modal_lg modal-dialog-centered modal_newProfileViewConfirmation"
          loaderbodytitle="Organisation"
        />
        {/* Professional View Disclaimer Modal end */}

        {/* Professional View Display Modal start */}
        <CustReactBootstrapModal
          ref={this.modalforpvdisplayRef}
          backdrop={false}
          backdropClassName="custom_modal_class"
          animation={false}
          show={this.state.showPVDisplayModal}
          onShow={() => {}}
          onHide={() => {}}
          modalcustdialog="custom_modal_lg modal-dialog-centered modal_newProfileViewFullDetails"
          loaderbodytitle="Organisation"
        />
        {/* Professional View Display Modal end */}

        {/* Newest1 - End */}
      </React.Fragment>
    );
  }
}
export default TaskListOfMultiTask;
