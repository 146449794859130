/**
 * Dashboard
 * Page/Class name   : Dashboard
 * Author            :
 * Created Date      : 10-2-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : rendering dashboard
**/
import React, { Component, Suspense, lazy} from 'react';
import {withRouter} from 'react-router-dom';
import '../../../custom_resources/css/custom.css';
// Modal
import CustReactBootstrapModal from './CustReactBootstrapModal';


// scroll 
import { Scrollbars } from 'react-custom-scrollbars';

import {commonJsFuncModule as commonJsObj} from  '../../../common_utilities/commonjsfunc';
import LoggedinUserInfoService from '../../service/LoggedinUserInfoService';

import FeedbackAssistant from './FeedbackAssistant';
import TaskStatusCodeComponent from './TaskStatusCodeComponent';


import {notifyheaderpopup} from '../../share/Header';

// common noti track
import NotiEmitTrack from "./NotiEmitTrack";
//import {commonnotitrack} from "./NotiEmitTrack";


const getBaseUrl = commonJsObj.getBaseUrl;
const getAccessObj = commonJsObj.getAccessObj;
const setAccessObj = commonJsObj.setAccessObj;
const getSiteName = commonJsObj.getSiteName;



class Dashboard extends Component {

    isComponentLoaded = false;
    /**
     * Dashboard
     * Function name : constructor
     * Author        :
     * Created Date  : 10-2-2020
     * Purpose       : initializing state
     * Params        : props
    **/
    constructor(props) {
        super(props);
        this.state = {
            "isFirstTaskCreated":false,
            "isKycVerified": false,
            "isSubscriptionActive": false,
            
        };
        
      
    }

    /**
     * Dashboard
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 10-2-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/

   

    componentDidMount() {
       this.isComponentLoaded = true;   
       //console.log(" React.version==>",React.version," getAccessObj===>>",getAccessObj());     

       // for triggering logic for opening modal to add user information after 1st time login
       // its a big script for temporarily closing the trigger uncommented the one line below script
       // to clean the whole  script related to it need to be followed from header part and other components
       //this.checkBeforeCallingOrgModal();

       setTimeout(()=>{
        this.getUserInfo();
       },1200);
       
        

    }

    checkBeforeCallingOrgModal = () => {
        notifyheaderpopup("openOrgDetPopup",{
        });
        
    }

    /**
     * Dashboard
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 10-2-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {  
        this.isComponentLoaded = false;
        
    }

    getUserInfo = () =>{
                      
                
            let luiserv = new LoggedinUserInfoService();
            luiserv.getLoggedInUserInfo({}).then(data =>{

                let loggedInUserInfoResp = data.data.entity;
                let isFirstTaskCreated = loggedInUserInfoResp.isFirstTaskCreated!==undefined?loggedInUserInfoResp.isFirstTaskCreated:false;
                let isKycVerified = loggedInUserInfoResp.isKycVerified!==undefined?loggedInUserInfoResp.isKycVerified:false;
                let isSubscriptionActive = loggedInUserInfoResp.isSubscriptionActive!==undefined?loggedInUserInfoResp.isSubscriptionActive:false;


                
                this.isComponentLoaded &&  this.setState({
                    "isFirstTaskCreated":isFirstTaskCreated,
                    "isKycVerified":isKycVerified,
                    "isSubscriptionActive":isSubscriptionActive,
                },()=>{
                    setAccessObj({"key":"isKycVerified","keyValue":isKycVerified});
                    setAccessObj({"key":"isSubscriptionActive","keyValue":isSubscriptionActive});

                });

                
            }).catch(err=>{
                console.log("Some server related tech problem occured",err);
            });

         

    }


    trackNotiEvent = (otherParam) =>{
        let notiType =  otherParam.notiType!==undefined? otherParam.notiType: "";
        let trigerredFrom =  otherParam.trigerredFrom!==undefined? otherParam.trigerredFrom: ""; 
        if(notiType=="KYC"){
            this.getUserInfo();
        }
        
        if(notiType=="SUBSCRIPTION_SUCCESS"||notiType=="SUBSCRIPTION_FAILURE"){
            this.getUserInfo();          
           
        }
  
      }

    /**
     * Dashboard
     * Function name : render
     * Author        :
     * Created Date  : 11-2-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() {   

        let accessObj = getAccessObj(); 
        let isKycVerified = this.state.isKycVerified; //accessObj.isKycVerified!==undefined && accessObj.isKycVerified!==null?accessObj.isKycVerified: false;

        let isSubscriptionActive = this.state.isSubscriptionActive; //accessObj.isSubscriptionActive!==undefined && accessObj.isSubscriptionActive!==null?accessObj.isSubscriptionActive: false;        
        //console.log("====isKycVerified=>",isKycVerified," isSubscriptionActive==>",isSubscriptionActive);
       
       return (
            <React.Fragment>  
                   <div>
                   <NotiEmitTrack callbackfuncforcommontrackofnoti={this.trackNotiEvent} />
                        {/* Dashboard Main Screen content area start */}
                        <div className="dashboard_cont_area tasklist_cont_areabg dashboard_areabg dashboard_screen_cc dashboard_screen_heightAdjustment">
                            {/* ===  DashBoard Main Body Start ===  */}
                            <div className="cutom_container">
                            <div className="tab-content">
                                <div id="dashboard" className="tab-pane fade in active">
                                <div className="dashboard_block dashboard_block_cc stickyFooterHeightAdjustBlock_dashboardTabPanel">
                                    {isKycVerified===true && isSubscriptionActive===true && (

                                    <>
                                        <div className="panel60 paddingright15">
                                        <div className="notification_area notification_area_mCuston_defaultScrollbar">
                                        <Scrollbars style={{ height: 300 }}>
                                            <div className="notification_block">
                                    Welcome to {getSiteName()}! Discover, select, monitor and track external professionals in an easy and centralised way!
                                            {/* <a href="#">Know more.</a>
                                            <a href="#" className="blackcross">
                                                <img src={getBaseUrl()+"assets/images/balck_cross.png"} />
                                            </a> */}
                                            </div>

                                            <div className="notification_block">
                                            Create Tasks and get professional profiles matched by our AI bot. Before selecting the professional, you can negotiate budgets, due dates and finalise engagement.
                                            {/* <a href="#">Know more.</a>
                                            <a href="#" className="blackcross">
                                                <img src={getBaseUrl()+"assets/images/balck_cross.png"} />
                                            </a> */}
                                            </div>

                                            <div className="notification_block">
                                            Chat, access documents and timesheets,  receive invoices and make single click payments - with full encryption and security on the Cloud.
                                            {/* <a href="#">Know more.</a>
                                            <a href="#" className="blackcross">
                                                <img src={getBaseUrl()+"assets/images/balck_cross.png"} />
                                            </a> */}
                                            </div>
                                            
                                            {/* <div className="notification_block">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                            do eiusmod tempor incididunt ut labore et dolore magna{" "}
                                            <a href="#">Know more.</a>
                                            <a href="#" className="blackcross">
                                                <img src={getBaseUrl()+"assets/images/balck_cross.png"} />
                                            </a>
                                            </div>
                                            <div className="notification_block">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                            do eiusmod tempor incididunt ut labore et dolore magna{" "}
                                            <a href="#">Know more.</a>
                                            <a href="#" className="blackcross">
                                                <img src={getBaseUrl()+"assets/images/balck_cross.png"} />
                                            </a>
                                            </div>
                                            <div className="notification_block">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                            do eiusmod tempor incididunt ut labore et dolore magna{" "}
                                            <a href="#">Know more.</a>
                                            <a href="#" className="blackcross">
                                                <img src={getBaseUrl()+"assets/images/balck_cross.png"} />
                                            </a>
                                            </div>
                                            <div className="notification_block">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                            do eiusmod tempor incididunt ut labore et dolore magna{" "}
                                            <a href="#">Know more.</a>
                                            <a href="#" className="blackcross">
                                                <img src={getBaseUrl()+"assets/images/balck_cross.png"} />
                                            </a>
                                            </div>
                                            <div className="notification_block">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                            do eiusmod tempor incididunt ut labore et dolore magna{" "}
                                            <a href="#">Know more.</a>
                                            <a href="#" className="blackcross">
                                                <img src={getBaseUrl()+"assets/images/balck_cross.png"} />
                                            </a>
                                            </div>
                                            <div className="notification_block">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                            do eiusmod tempor incididunt ut labore et dolore magna{" "}
                                            <a href="#">Know more.</a>
                                            <a href="#" className="blackcross">
                                                <img src={getBaseUrl()+"assets/images/balck_cross.png"} />
                                            </a>
                                            </div>
                                            <div className="notification_block">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                            do eiusmod tempor incididunt ut labore et dolore magna{" "}
                                            <a href="#">Know more.</a>
                                            <a href="#" className="blackcross">
                                                <img src={getBaseUrl()+"assets/images/balck_cross.png"} />
                                            </a>
                                            </div>
                                            <div className="notification_block">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                            do eiusmod tempor incididunt ut labore et dolore magna{" "}
                                            <a href="#">Know more.</a>
                                            <a href="#" className="blackcross">
                                                <img src={getBaseUrl()+"assets/images/balck_cross.png"} />
                                            </a>
                                            </div>
                                            <div className="notification_block">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                            do eiusmod tempor incididunt ut labore et dolore magna{" "}
                                            <a href="#">Know more.</a>
                                            <a href="#" className="blackcross">
                                                <img src={getBaseUrl()+"assets/images/balck_cross.png"} />
                                            </a>
                                            </div>
                                         */}
                                        </Scrollbars>    
                                        </div>
                                        </div>
                                        <div className="panel40 paddingleft15">
                                    <div className="create_task_area">
                                        {(this.state.isFirstTaskCreated==false) && (
                                                <div className="mesg_txt">
                                                You have not created any tasks yet. Click on create &amp;
                                                assign task to create a new task
                                                
                                                </div>

                                        )}
                                        
                                        <div className="button_area">
                                            <button onClick={()=>{ 
                                            //this.organisationUserAddModalOpen()
                                            //this.organisationDetailModalOpen()
                                            this.props.history.push("/task-add")

                                            }} className="sub_grd_btn create_tsk_btn">
                                            <span>
                                            <img src={getBaseUrl()+"assets/images/add_icon.png"} />
                                            {" "}
                                            Create a new task
                                            </span>
                                            </button>
                                        </div>
                                    </div>
                                    </div>
                                    
                                    </>
                                    ) }

                                    {(isKycVerified===false || isSubscriptionActive===false) && (
                                    <div className="top_filter_left top_filter_left_task_msgBG">
                                        <div className="top_filter_left_task_msg">
                                            <p>
                                            Welcome to Flywork.io you can start using the full application of the
                                            platform and receive tasks once you complete your KYC &amp; subscription
                                            </p>
                                            <div className="top_filter_left_task_msg_btn">
                                                <button onClick={(evn)=>{
                                                        evn.preventDefault();
                                                        if(isSubscriptionActive===false){
                                                            this.props.history.push("/application-settings");
                                                        }else if(isKycVerified===false){
                                                            this.props.history.push("/profile-settings");
                                                        }

                                                }} className="btn_appearance_none sub_grd_btn">
                                                <span>Lets go!</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    )}

                                    
                                    
                                    <div className="clearboth" />
                                </div>
                                </div>
                            </div>
                            </div>
                            {/* ===  DashBoard Main Body End ===  */}
                        </div>
                        {/* Dashboard Main Screen content area end */}
                        {/* ===  DashBoard Main Body Footer Start ===  */}
                        <div className="dash_main_footerbg bottomStickFooter_dashboardScreen">
                            <div className="dash_main_footer">
                            <div className="dash_foot_leftbg">
                                <div className="taskstatus_bg">
                                {/* =====  new Task Status =====  */}
                                <TaskStatusCodeComponent />
                                {/* =====  new Task Status =====  */}
                                </div>
                            </div>
                            <div className="dash_foot_rightbg">
                                <FeedbackAssistant />
                            </div>
                            <div className="clearboth" />
                            </div>
                        </div>
                        {/* ===  DashBoard Main Body Footer Start ===  */}
                    </div>
            
            </React.Fragment>
            
        );
    }



  }
  export default withRouter(Dashboard);