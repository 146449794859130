/**
 * PaymentTab
 * Page/Class name   : PaymentTab
 * Author            :
 * Created Date      : 12-6-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : render master component
**/
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";


// for context
import CommonContext from '../../context/CommonContext';

// Services
import VirtualAccountBalanceService from '../../service/VirtualAccountBalanceService';
import MarkInvoicePaidService from '../../service/MarkInvoicePaidService';

//import  CustDate  from '../../../common_utilities/CustDate';
import { commonJsFuncModule as commonJsObj } from '../../../common_utilities/commonjsfunc';

import {notify} from '../../messagebar/CustomMessageBar';
import CustomMessageBar from '../../messagebar/CustomMessageBar';
import VbaCommonPart from './VbaCommonPart';

import {commonnotitrack} from "./NotiEmitTrack";

const getCcUserId = commonJsObj.getCcUserId;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getBaseUrl = commonJsObj.getBaseUrl;

class PaymentTab extends Component {

    isComponentLoaded =  false;
    // for context
    static contextType = CommonContext;

    /**  
     * PaymentTab
     * Function name : constructor
     * Author        :
     * Created Date  : 12-6-2020
     * Purpose       : initializing children
     * Params        : props
    **/
    constructor(props) {
      super(props);    
      this.children = props.children;
      this.state = {
        //vba
        "vbaInfo":null,
        "accountBalance":0,
        "currency":"",
        "lastFetchedFromTheBankAt":null,
        "virtualAccountNumber":"",
        "ifsc":"",
        "benificieryName":"",
        "qrCodeImageURL":null,
        "qrCodeImagePath":null,
        "qrCodeUPIAppOpener":null,

        //invoice
        "invoiceInfo":null,
        "invoiceId":0,
        "invoiceNumber":"",
        //"invoiceAmount":0,
        "totalInvoiceAmountToBePaid":0,
        "gstAdded":0,
        "isReverseGstApplicable":false,
        "tdsAmountDeducted":0,        

        // payment option
        "paymentOpt": "VBA", // VBA=> Virtual Bank Account, 

        // other important information
        "taskOrSubTask":"",
        "taskIdOrSubTaskId":0,
        "ccUserId":0,
        "lpUserId":0,

        // payment process flag
        "paymentProcessFlag":false






      };
      this.scrollerRef = React.createRef();
      
     
    }

    /**
     * PaymentTab
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 12-6-2020
     * Purpose       : lifecycle method
     * Params        :
    **/
    componentDidMount() {
      this.isComponentLoaded = true;

      commonnotitrack("SCROLL_TO_MAIN_HEADER",{
        "notiType": "SCROLL_TO_MAIN_HEADER",
        "triggerCallback":true,
        "trigerredFrom":"INVOICE_PAYMENT_FLOW"
       });
     

      //
      let invoiceInfo = null;
      let triggeredFrom = "";
      
     
     
      //********* for context starts *****
      let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;
          
      if(sharedData!==undefined && sharedData!==null ){ 
        
        if(sharedData.other!==undefined && sharedData.other!==null){            
                
          triggeredFrom  = sharedData.other.triggeredFrom!==undefined?sharedData.other.triggeredFrom:"";

          invoiceInfo  = sharedData.other.invoiceInfo!==undefined?sharedData.other.invoiceInfo:null;

          this.setOtherTaskInfoToState(sharedData);
          
          //console.log(" == call checkInvoice=== ",invoiceInfo); 
          let chkPaid = this.checkInvoicePaid(invoiceInfo);
          if(chkPaid){
            // no need to stay here go back
            this.triggerBackButton();
          }else{
            this.setInvoiceInfoStateData({"invoiceInfo":invoiceInfo});            
            this.callUserVirtualBankBalanceService({});
          }        
          
        }else{
          this.triggerBackButton();
        }

      }


    
    }

  

    changePaymentOption = (paramObj) =>{
      let paymentOpt = paramObj.paymentOpt;
      this.isComponentLoaded && this.setState({"paymentOpt":paymentOpt},()=>{});
    }

    checkInvoicePaid = (invoiceInfo)=>{
     // console.log(" == from checkInvoice=== ",invoiceInfo); 
      let isPaid = false;      
      if(invoiceInfo!==null && invoiceInfo.isPaid!==undefined && invoiceInfo.isPaid!==null ){
        isPaid = invoiceInfo.isPaid;         
      }  
      
      return isPaid;

    }

    setOtherTaskInfoToState = (sharedData) =>{     
      let taskOrSubTask = "";
      let taskIdOrSubTaskId = 0;
      let ccUserId = 0;
      let lpUserId = 0;
      console.log(" now sharedData==>",sharedData);
      if(sharedData!==undefined && sharedData!==null ){         
        if(sharedData.other!==undefined && sharedData.other!==null){  

          taskOrSubTask = (sharedData.other.subTasksCount!==undefined && sharedData.other.subTasksCount!==null && sharedData.other.subTasksCount>0)?"SWSTASK":"STASK"; 

          taskIdOrSubTaskId = (sharedData.other.taskResp!==undefined && sharedData.other.taskResp.id!==undefined && sharedData.other.taskResp.id!==null)?sharedData.other.taskResp.id:0;

          ccUserId = getCcUserId();
                   
          lpUserId = ((sharedData.other.taskResp!==undefined)&&(sharedData.other.taskResp.assignedTo!==undefined && sharedData.other.taskResp.assignedTo!==null)&&(sharedData.other.taskResp.assignedTo.userId!==undefined &&sharedData.other.taskResp.assignedTo.userId!==null)) ? sharedData.other.taskResp.assignedTo.userId:0;
          let paramStateObj = {
            "taskIdOrSubTaskId":taskIdOrSubTaskId,
            "taskOrSubTask":taskOrSubTask,
            "lpUserId":lpUserId,
            "ccUserId":ccUserId
          };
          this.isComponentLoaded && this.setState(paramStateObj,()=>{});

        }
      }
    }

    setUserVirtualBankBalanceStateData = (vbBalInfo,otherParam) => {

      if(vbBalInfo!==undefined && vbBalInfo!==null){

          let accountBalance = (vbBalInfo.accountBalance!==undefined && vbBalInfo.accountBalance!==null)?vbBalInfo.accountBalance:0;
  
          let currency = (vbBalInfo.currency!==undefined && vbBalInfo.currency!==null)?vbBalInfo.currency:"";
  
          let lastFetchedFromTheBankAt = (vbBalInfo.lastFetchedFromTheBankAt!==undefined && vbBalInfo.lastFetchedFromTheBankAt!==null)?vbBalInfo.lastFetchedFromTheBankAt:null;
  
          let virtualAccountNumber = (vbBalInfo.virtualAccountNumber!==undefined && vbBalInfo.virtualAccountNumber!==null)?vbBalInfo.virtualAccountNumber:0;
  
          let ifsc = (vbBalInfo.ifsc!==undefined && vbBalInfo.ifsc!==null)?vbBalInfo.ifsc:"";
  
          let benificieryName = (vbBalInfo.benificieryName!==undefined && vbBalInfo.benificieryName!==null)?vbBalInfo.benificieryName:"";
  
          let qrCodeImageURL = (vbBalInfo.qrCodeImageURL!==undefined && vbBalInfo.qrCodeImageURL!==null)?vbBalInfo.qrCodeImageURL:"";
  
          let qrCodeImagePath = (vbBalInfo.qrCodeImagePath!==undefined && vbBalInfo.qrCodeImagePath!==null)?vbBalInfo.qrCodeImagePath:null;
  
          let qrCodeUPIAppOpener = (vbBalInfo.qrCodeUPIAppOpener!==undefined && vbBalInfo.qrCodeUPIAppOpener!==null)?vbBalInfo.qrCodeUPIAppOpener:null;
  
          let vbaBalStateData = { 
          "vbaInfo":vbBalInfo,
          "accountBalance":accountBalance,
          "currency":currency,
          "lastFetchedFromTheBankAt":lastFetchedFromTheBankAt,
          "virtualAccountNumber":virtualAccountNumber,
          "ifsc":ifsc,
          "benificieryName":benificieryName,
          "qrCodeImageURL":qrCodeImageURL,
          "qrCodeImagePath":qrCodeImagePath,
          "qrCodeUPIAppOpener":qrCodeUPIAppOpener,
          };
          this.isComponentLoaded && this.setState(vbaBalStateData);
      }
      
    }

    setInvoiceInfoStateData = (otherParam) => {
      let invoiceInfo = otherParam.invoiceInfo;

      if(invoiceInfo!==null){
          let invoiceId = (invoiceInfo.invoiceId!==undefined && invoiceInfo.invoiceId!==null)?invoiceInfo.invoiceId:0;

          let invoiceNumber = (invoiceInfo.invoiceNumber!==undefined && invoiceInfo.invoiceNumber!==null)?invoiceInfo.invoiceNumber:"";
    
          //let invoiceAmount = (invoiceInfo.invoiceAmount!==undefined && invoiceInfo.invoiceAmount!==null)?invoiceInfo.invoiceAmount:0;
    
          let totalInvoiceAmountToBePaid = (invoiceInfo.totalInvoiceAmountToBePaid!==undefined && invoiceInfo.totalInvoiceAmountToBePaid!==null)?invoiceInfo.totalInvoiceAmountToBePaid:0;
    
          let gstAdded = (invoiceInfo.gstAdded!==undefined && invoiceInfo.gstAdded!==null)?invoiceInfo.gstAdded:0;
    
         
          let isReverseGstApplicable = (invoiceInfo.isReverseGstApplicable!==undefined && invoiceInfo.isReverseGstApplicable!==null)?invoiceInfo.isReverseGstApplicable:false;
    
          let tdsAmountDeducted = (invoiceInfo.tdsAmountDeducted!==undefined && invoiceInfo.tdsAmountDeducted!==null)?invoiceInfo.tdsAmountDeducted:0;
    
          let invStateData = { 
          "invoiceInfo":invoiceInfo,
          "invoiceId":invoiceId,
          "invoiceNumber":invoiceNumber,
          //"invoiceAmount":invoiceAmount,
          "totalInvoiceAmountToBePaid":totalInvoiceAmountToBePaid,
          "gstAdded":gstAdded,
          "isReverseGstApplicable":isReverseGstApplicable,
          "tdsAmountDeducted":tdsAmountDeducted,
          };
          this.isComponentLoaded && this.setState(invStateData,()=>{});
      }
    }

    callUserVirtualBankBalanceService = (paramObj) =>{
      let passParam = {
                      };
      

      let stcservice = new VirtualAccountBalanceService();
      stcservice.getVirtualBankBalanceInfo({"data":passParam}).then(data =>{
            if(data.status==200){
                let respData ={};
                if(data.data.entity!==undefined){
                  respData = data.data.entity; 
                  //console.log(" respData=>>",respData);
                  this.setUserVirtualBankBalanceStateData(respData);
                  
                  
            }
        }
        }).catch((err)=>{
                
                let errStatusCodeAr = [400,500]; // allowed error status code
                if(err.response && err.response.data){
                    let erRespData = err.response.data;
                    if(errStatusCodeAr.indexOf(erRespData.status) !== -1){         
                        let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                        console.log(errMsg);
     
                                         
                }
                } 
        }).finally(()=>{

                
                     
            
        });
    }

    callMarkInvoicePaidService = (otherParam) =>{

      console.log(" from callMarkInvoicePaidService this.state==>",this.state);
      
      let passParam = {
        "taskOrSubTask":this.state.taskOrSubTask,
        "taskIdOrSubTaskId":this.state.taskIdOrSubTaskId,
        "ccUserId":this.state.ccUserId,
        "lpUserId":this.state.lpUserId,
        "invoiceId":this.state.invoiceId
                      };

      
              console.log(" passParam=>",passParam);
      let stcservice = new MarkInvoicePaidService();
      stcservice.makeInvoicePaid({"data":passParam}).then(data =>{
            if(data.status==200){
                let respData ={};
                if(data.data.entity!==undefined){

                        let notiMsg = data.data.entity?data.data.entity:"Payment successfully made";
                        notify(notiMsg,true,{
                        "successMsg":1,
                        "customWrapClass":"customerrmidlass paymentpage",
                        "showCloseButton": false, 

                        "dismiss": {
                        "duration": 1250,
                        }

                        }); 

                        setTimeout(()=>{
                            this.gotoInvoiceHistoryPage();
                        },1500);
                  
                  
                  
            }
        }
        }).catch((err)=>{
                
                let errStatusCodeAr = [400,500]; // allowed error status code
                if(err.response && err.response.data){
                    let erRespData = err.response.data;
                    if(errStatusCodeAr.indexOf(erRespData.status) !== -1){         
                        let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                        console.log(errMsg);
                        let notiMsg = errMsg;
                        notify(notiMsg,true,{
                        "successMsg":0,
                        "customWrapClass":"customerrmidlass paymentpage",
                        "showCloseButton": false, 

                        "dismiss": {
                        "duration": 1500,
                        }

                        }); 
     
                                         
                }
                } 
        }).finally(()=>{

          setTimeout(()=>{
            this.isComponentLoaded && this.setState({"paymentProcessFlag":false});;  
          },5500);
              
                     
            
        });
    }

    gotoInvoiceHistoryPage = ()=>{

      this.props.history.push("/tasks");

      let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;
      let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;      
      if(setShare && sharedData){
        if(sharedData.other!==undefined && sharedData.other!==null){         
         
          setTimeout(()=>{
            sharedData.actionType="SPLITSCREEN"
            sharedData.leftTabToActive = "INVOICE";
            sharedData.purpose = "VIEW_INVOICE_LIST";
            sharedData.other.triggeredFrom = "PAYMENT_PAGE";      
            //sharedData.other.invoiceViewedPaidStatus = this.state.isPaid;
            sharedData.other.invoiceInfo = this.state.invoiceInfo;
            setShare(sharedData);
          },1200);

        }
      }
    }


    /**  
     * PaymentTab
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {
      this.isComponentLoaded = false;

    }

    /**  
     * PaymentTab
     * Function name : componentDidUpdate
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentDidUpdate(prevProps){

    }

   
    triggerBackButton = () =>{
       //********* for context starts *****
      // context share
      let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;
      let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;      
     
      //********* for context ends *****
      if(setShare && sharedData){
        if(sharedData.other!==undefined && sharedData.other!==null){
          
          this.props.history.push("/tasks");
          setTimeout(()=>{
            sharedData.actionType="SPLITSCREEN";
            sharedData.leftTabToActive = "INVOICE";
            sharedData.purpose = "VIEW_INVOICE";
            sharedData.other.triggeredFrom = "PAYMENT_PAGE";
            setShare(sharedData);
          },1200);
        }else{
          this.props.history.push("/tasks");
        }
        
        
      }
    }
  
    loadMiddlePaymentStructure = () =>{
      
        if(this.state.paymentOpt=="VBA"){
          let datasObj = {
            "vbaInfo":this.state.vbaInfo,
            "injectedIn":"PaymentTab"
          };          
          return (
            <>
            <div className={"vbapaymentoption"}>
            <div className="pay_det_sec pay_det_color">
              <div className="pay_det_sec_p">
                <p>
                  You can add money to your virtual bank account using UPI,
                  NEFT, RTGS and IMPS.

                </p>
              </div>
            </div>
            <div className="pay_det_sec">
              <div className="vBank_acc_block">
                <div className="vBank_left">
                  <p>Virtual bank account balance</p>
                </div>
                <div className="vBank_right">
                  <p>{this.state.currency} &nbsp; {this.state.accountBalance}</p>
                </div>
                <div className="clearboth" />
              </div>
              {this.state.accountBalance < this.state.totalInvoiceAmountToBePaid && ( 
                  <p className="vBank_error">
                    You have insufficient funds in your virtual bank account.
                    Please add money to continue
                  </p>
              ) }
              
            </div>
           <VbaCommonPart datas={datasObj} />
            
          
          </div>
            </>
          );
        }else{
          return (<></>);
        }
    }
   

    loadRightSidePaymentBlock = () =>{
      
     return (
      <div className="payment_block">
      <div className="payment_gray_box">
        <div className="pay_row_adjust">
          <div className="pay_row">
            <div className="pay_row_l">Amount</div>
            <div className="pay_row_r">{this.state.totalInvoiceAmountToBePaid}</div>
            <div className="clearboth" />
          </div>
          {this.state.isReverseGstApplicable==true && (
            <div className="pay_row">
              <div className="pay_row_l">GST</div>
              <div className="pay_row_r">No GST</div>
              <div className="clearboth" />
            </div>

          )}
          {this.state.isReverseGstApplicable==false && (
            <div className="pay_row">
              <div className="pay_row_l">GST</div>
              <div className="pay_row_r">{this.state.gstAdded}</div>
              <div className="clearboth" />
            </div>

          )}
          
          {(this.state.tdsAmountDeducted>0) && (
              <div className="pay_row">
              <div className="pay_row_l">TDS</div>
              <div className="pay_row_r">{"-"}&nbsp;{this.state.tdsAmountDeducted}</div>
              <div className="clearboth" />
            </div>

          )}
          

        </div>
        <div className="pay_row_total">
          <div className="pay_row">
            <div className="pay_row_l">Total</div>
            <div className="pay_row_r">{this.state.totalInvoiceAmountToBePaid}</div>
            <div className="clearboth" />
          </div>
        </div>
        <div className="pay_row_button">
          <button  onClick={(evn)=>{
            evn.preventDefault();
            this.isComponentLoaded && this.setState({"paymentProcessFlag":true},()=>{
              this.callMarkInvoicePaidService({});
            }); 
            
          }} disabled={(this.state.accountBalance < this.state.totalInvoiceAmountToBePaid)||(this.state.paymentProcessFlag)?true:false} className={`sub_grd_btn ${(this.state.accountBalance < this.state.totalInvoiceAmountToBePaid)||(this.state.paymentProcessFlag)?"disabled_section":""}`}>
            <span>Complete Payment</span>
          </button>
        </div>
      </div>
    </div>
    
     );
    }

    loadLeftSidePaymentOptions = () => {
     return (
      <div className="invoPay_left_nav">
      {/* == left navigation start ==  */}
      <nav className="navbar navbar-expand-lg navbar-light invo_navbar">
        <div className="pay_side_heading">
          <h3 className="navbar-brand" href="" onClick={(evn)=>{evn.preventDefault();}} >
            Choose payment method
          </h3>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
        </div>
        <div
          className="collapse navbar-collapse invoPay_navbar"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav">
            <li key={0} className={`nav-item ${ this.state.paymentOpt=="VBA"?"active":""}`}>
              <a className="nav-link" href="" onClick={(evn)=>{
                evn.preventDefault();
                this.changePaymentOption({"paymentOpt":"VBA"});
                }}>
                <span className="pay_li_text">
                  Pay via virtual bank account
                </span>
              </a>
            </li>
            <li key={1} className="nav-item">
              <a className="nav-link" href="" onClick={(evn)=>{evn.preventDefault();}}>
                <span className="pay_li_text">
                  Other payment option
                </span>
                <span className="comming_soon">Coming soon</span>
              </a>
            </li>
          </ul>
        </div>
      </nav>
      {/* == left navigation end ==  */}
    </div>
  
     );
    }

    /**  
     * PaymentTab
     * Function name : render
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() {    
      
     

      return (
        <>
            <div className="payment_method_PagrBg">
              {/* Invoice Payment task header start */}
              <div className="task_header invoPay_header task_header_withTopHeader">
                <div className="taskstep_back_bg invoPay_backBg">
                  <button onClick={(evn)=>{
                    evn.preventDefault();
                    this.triggerBackButton();
                  }}>
                    <img
                      className="back_img_icon"
                      src="assets/images/path-back-white.png"
                    />
                    Back
                  </button>
                  <h4>
                    <span>Invoice number</span> {this.state.invoiceNumber}
                  </h4>
                </div>
              </div>
              {/* Invoice Payment task header end */}
              <CustomMessageBar />
              {/* Invoice Payment main body start */}
              {/* task body area start */}
              <div className="invoPay_mainBody_bg">
                <div className="cutom_container cutom_container_withTopHeader">
                  
                  {/* Invoice Payment method Body strat */}
                  <div className="invoPay_main_Section min_height_700">
                    <div className="invoPay_mainBody">
                      {/* == Payment Method left side bar start == */}
                      <div className="invoPay_left_bg">
                        {this.loadLeftSidePaymentOptions()}
                      </div>
                      {/* == Payment Method left side bar end == */}
                      {/* == Payment Method right ssection start == */}
                      <div className="invoPay_right_bg">
                        <div className="invoPay_right_section">
                          <div>
                          {/* -- pay details left start --  */}
                          <div className="pay_details_bg">
                            {this.loadMiddlePaymentStructure()}                           
                          </div>
                          {/* -- pay details left end --  */}
                          {/* -- pay details right start --  */}
                          {this.loadRightSidePaymentBlock()}
                          {/* -- pay details right end --  */}
                          </div>
                          <div className="clearboth" />
                        </div>
                      </div>
                      {/* == Payment Method right ssection end == */}
                      <div className="clearboth" />
                    </div>
                  </div>
                  {/* Invoice Payment method Body end */}
                  {/* Invoice Payment method footer strat */}
                  {/* 
                      <div class="task_details_btn_area invoPay_footer">
                          <div class="btn_fld">
                              <div class="panel50">
                                  <a href="#" class="btn_txt">Save as Draft</a>
                              </div>
                              <div class="panel50">
                                  <div class="cancel_continue_btn">
                                      <a href="#" class="btn_txt">Cancel</a>
                                      <button class="btn_appearance_none sub_grd_btn disabled_section"><span>Continue</span></button>
                                  </div>
                              </div>
                              <div class="clearboth"></div>
                          </div>
                      </div>
                      */}
                  {/* Invoice Payment method footer end */}
                </div>
              </div>
              {/* task body area end */}
              {/* Invoice Payment main body end */}
            </div>

        </>
      );
    }
}
export default withRouter(PaymentTab);