/**
 * TaskAddSingleTaskStep1
 * Page/Class name   : TaskAddSingleTaskStep1
 * Author            :
 * Created Date      : 1-11-2019
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : rendering loader structure
**/
import React, { Component, Suspense, lazy} from 'react';
import '../../../custom_resources/css/custom.css';

// for context
import CommonContext from '../../context/CommonContext';

// Modal
import CustReactBootstrapModal from './CustReactBootstrapModal';

// Services
import TypeOfProfessionalsService from '../../service/TypeOfProfessionalsService';
import PracticeAreaOfProfessionService from '../../service/PracticeAreaOfProfessionService';
import CategoriesBasedOnPracticeAreaService from '../../service/CategoriesBasedOnPracticeAreaService';
import TypeIdBasedOnCategoryService from '../../service/TypeIdBasedOnCategoryService';
import FloorPriceBasedOnCategAndTypeService from '../../service/FloorPriceBasedOnCategAndTypeService';
import CreateTaskService from '../../service/CreateTaskService';


import { Multiselect } from 'multiselect-react-dropdown';

import NewCustomGoogleAutoComplete from '../../customisedthirdparty/NewCustomGoogleAutoComplete';

import {notify} from '../../messagebar/CustomMessageBar';
import CustomMessageBar from '../../messagebar/CustomMessageBar';

import DraftTaskService from '../../service/DraftTaskService';
import DeleteDraftTaskService from '../../service/DeleteDraftTaskService';

import {commonnotitrack} from "./NotiEmitTrack";



//
import { commonJsFuncModule as commonJsObj } from '../../../common_utilities/commonjsfunc';

const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;
const getBaseUrl = commonJsObj.getBaseUrl;
const validatePositiveInputNumber = commonJsObj.validatePositiveInputNumber;

class TaskAddSingleTaskStep2 extends Component {

    // for context
    static contextType = CommonContext;  

    isComponentLoaded = false;

    

    /**
     * TaskAddSingleTaskStep1
     * Function name : constructor
     * Author        :
     * Created Date  : 27-02-2020
     * Purpose       : initializing state
     * Params        : props
    **/
    constructor(props) {
        super(props);

        // rnd
        this.state = {
            typeOfProfessionals: [],
            practiceAreaOptions: [],
            categoryOptions: [],
            typeIdOptions: [],
            practiceAreaSelected: [],
            expDataSelected: [],

            locSelected: [],            
            locdisabledFlag: false,

            //For error messages           

            typeOfProfessionalIdErrMsg : '',
            practiceAreasIdsErrMsg :'',
            categoryIdErrMsg :'',
            typeIdErrMsg :'',
            budgetCurrencyErrMsg :'',
            budgetErrMsg :'',
            locSelectedErMsg:'', //placeIds
            workExperienceErrMsg :'',
            isOrganisationDetailsMaskedErrMsg :'',

            // for no lawyer found modal
            showNoLawyerFoundModal: false,

            // for continue button 
            continueDisabled: false

        };

        this.orgcitygaref = React.createRef();
        this.multiselectexpRef = React.createRef();
        this.multiselectprofareaRef  = React.createRef();
        this.budgetInputRef  = React.createRef();
        // for no lawyer found modal
        this.nolawyermodref = React.createRef();
        //to scroll to that position
        this.refouterdivstep2 = React.createRef();
           
    }

    onSelectPracticeArea=(selectedList, selectedItem)=>{
            //console.log.log("onSelectPracticeArea=>"," selectedList=>",selectedList, " selectedItem=>",selectedItem);
            this.setPracAreaInLocalState(selectedList);

            let otherParam = {"action":"push"};
            this.props.setStatesForStep2ofST("practiceAreasIds",selectedItem,otherParam);
            
            // set categories based on practice area selection    
            this.setCategFromPracticeArea(selectedList,otherParam);
            
            
    }

    onRemovePracticeArea = (selectedList, removedItem) => {
        ////console.log.log("from remove selectedList==>",selectedList);
         this.setPracAreaInLocalState(selectedList);
         let otherParam = {"action":"remove"};
         this.props.setStatesForStep2ofST("practiceAreasIds",removedItem,otherParam);
          // set categories based on practice area selection    
          this.setCategFromPracticeArea(selectedList,otherParam);
        
     }
     
    setPracAreaInLocalState(pracObjAr){
        this.isComponentLoaded &&  this.setState({"practiceAreaSelected":pracObjAr});
    }

    setCategFromPracticeArea(selectedList,otherParam){
         // reset all dependencies of practiceAreaSelected
         let otherParamReset = {};                               
         this.resetAllDependencies("practiceAreaSelected",otherParamReset);

      
                // get categories based on practice area selection            
        let selectedPrcAreaData = ""; let  selectedPrcAreaAr = [];
        if(selectedList.length>0){
            Object.keys(selectedList).map((e,i)=>{
                selectedPrcAreaAr.push(selectedList[e].id);
            });
            if(selectedPrcAreaAr.length>0){
                selectedPrcAreaData = selectedPrcAreaAr.join(",");
            }
            // set categories based on practice area selection
            let setPrevSel= false;
            this.getCategFromPracticeAreaService(selectedPrcAreaData,setPrevSel);
        }else{
              
        }
        
        
        
    }

    onSelectExp = (selectedList, selectedItem)=>{
            //console.log.log("onSelectExp=>"," selectedList=>",selectedList, " selectedItem=>",selectedItem);
            this.setExpInLocalState(selectedList);
            let otherParam = {"action":"push"};
            this.props.setStatesForStep2ofST("workExperience",selectedItem,otherParam);      
            
    }

    onRemoveExp = (selectedList, selectedItem)=>{
            //console.log.log("onRemoveExp=>"," selectedList=>",selectedList, " selectedItem=>",selectedItem);
            this.setExpInLocalState(selectedList);
            let otherParam = {"action":"remove"};
            this.props.setStatesForStep2ofST("workExperience",selectedItem,otherParam);      
            
    }

    
    setExpInLocalState(expObjAr){
        this.isComponentLoaded && this.setState({"expDataSelected":expObjAr});
    }

    saveDraft(){
        let offsetOfLocalDateTime = getOffsetOfLocalDateTime(new Date());
        let offsetHr = offsetOfLocalDateTime.hr;
        let offsetMin = offsetOfLocalDateTime.min;

        let paramObj= {
            "taskName":"",
            "taskDescription":"",
            "dueDate": null,
            "categoryId":"",
            "typeId":"",
            "budget":"",
            "budgetCurrency":"",
            "taskDocIds":[],
            "lawyerDiscoveryParams":
                {
                    "typeOfProfessionalId":"",
                    "practiceAreasIds":[],
                    "placeIds":[],
                    "workExperience":[],
                    "isOrganisationDetailsMasked":false,
                    "googleCityDetails":[]
                },
            "offsetHour":null,
            "offsetMinute":null
            };
    
            
            
            
            const {values,values0, cancelButtonAction} = this.props;
            const draftId = values.draftId; 

            //previous page values        
            const taskName = values0.stepOneStTaskName;
            const taskDescription = values0.stepOneStTaskDesc;
            const dueDate = values0.stepOneStTaskDueDate;
            const taskDocIds = values0.stepOneStDocuments;
            const offsetHour = values0.stepOneOffsetHour?values0.stepOneOffsetHour:offsetHr;
            const offsetMinute = values0.stepOneOffsetMinute?values0.stepOneOffsetMinute:offsetMin;       
    
    
            //current page values 
            const typeOfProfessionalId = parseInt(values.typeOfProfessionalId);
            const practiceAreasIds = values.practiceAreasIds;
            const categoryId = parseInt(values.categoryId);
            const typeId = parseInt(values.typeId);        
            const budgetCurrency =  values.budgetCurrency;
            const budget =  values.budget;
            const placeIds = values.placeIds; 
            const locSelected = values.locSelected;
            const workExperience = values.workExperience;              
            const isOrganisationDetailsMasked = values.isOrganisationDetailsMasked; 
            
            let taskTypeFlag = values.taskTypeFlag;
    
    
            let taskDocIdsAr = [];
            if(taskDocIds.length>0){
                
                Object.keys(taskDocIds).map((e,i)=>{
                    taskDocIdsAr.push(taskDocIds[e].id);
                });           
    
            }

            // googleCityDetails related modifications for param to pass to save
            let googleCityDetails = [];
            if(locSelected.length>0){
                

                Object.keys(locSelected).map((e,i)=>{

                    let googleCityDetailsObj = {
                        "cityName":null,
                        "state":null,
                        "country":null,
                        "latitude":null,
                        "longitude":null,
                        "placeId":null
                    };
                    let locObj = locSelected[e];
                    googleCityDetailsObj.cityName = locObj.city!==undefined?locObj.city:null;
                    googleCityDetailsObj.state = locObj.state!==undefined?locObj.state:null;
                    googleCityDetailsObj.country = locObj.country!==undefined?locObj.country:null;
                    googleCityDetailsObj.latitude = locObj.lat!==undefined?locObj.lat:null;
                    googleCityDetailsObj.longitude = locObj.lng!==undefined?locObj.lng:null;
                    googleCityDetailsObj.placeId = locObj.placeId!==undefined?locObj.placeId:null;


                    googleCityDetails.push(googleCityDetailsObj);
                });           

            }
    
            paramObj.taskName = taskName;
            paramObj.taskDescription = taskDescription;
            paramObj.dueDate = dueDate;
            paramObj.offsetHour = offsetHour;
            paramObj.offsetMinute = offsetMinute;
            paramObj.taskDocIds = taskDocIdsAr;
    
            paramObj.categoryId = categoryId;
            paramObj.typeId = typeId;
            paramObj.budget = budget;
            paramObj.budgetCurrency = budgetCurrency;
            paramObj.lawyerDiscoveryParams.typeOfProfessionalId = typeOfProfessionalId;
            paramObj.lawyerDiscoveryParams.practiceAreasIds = practiceAreasIds;
            paramObj.lawyerDiscoveryParams.placeIds = placeIds;
            paramObj.lawyerDiscoveryParams.workExperience = workExperience;
            paramObj.lawyerDiscoveryParams.isOrganisationDetailsMasked = isOrganisationDetailsMasked;
            paramObj.lawyerDiscoveryParams.googleCityDetails = googleCityDetails;
                
            let regexBlankSpace = /^ *$/;       
      
            if(!(taskName)||(regexBlankSpace.test(taskName)==true) ){
                //console.log.log("task name cannot be blank");
                return false;
            }         


            let paramObjData = {
                "draftId" : draftId,
                "data": paramObj
             };
            
            let lservdrf = new DraftTaskService();
            lservdrf.saveDraftTask(paramObjData).then(data =>{
                    //this.setStepOneStDocuments();
                    //console.log.log("success resp=>",data);
                    let entityObj  = data.data.entity;       
                    //console.log.log(" resp =>>",entityObj);
                    // set draft Id
                    let otherParam = {};
                    this.props.setDraftFlag("draftId",entityObj,otherParam);

                    // make draft button disabled
                    this.props.setDraftFlag("singleTaskWithoutSubFlowD",false,{});

                    //********* for context starts *****
                    // updating data in context 
                    let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;  
                    // example
                    if(setShare!==null){
                        // note it is a special case for draft
                        setShare({actionType:"DRAFT"}); 
                        setTimeout(()=>{
                            cancelButtonAction();
                        },1100);
                        
                    }                      
                    
                    //********* for context ends *****
                     


                }).catch((e)=>{
                    // make draft button enabled
                    this.props.setDraftFlag("singleTaskWithoutSubFlowD",true,{});
                        //console.log.log("server error=>",e);
                }).finally(()=>{
                     
                });

    }

    deleteDraftTask(paramObj){

        // let paramObjData = {"data":paramObj};
        // let lservdrf = new DeleteDraftTaskService();
        // lservdrf.deleteDraftTask(paramObjData).then(data =>{   
        //         // success
        //     }).catch((e)=>{              
        //             console.log("server error=>",e);
        //     }).finally(()=>{
        //     });
        }

    callCreateTaskService(){      
        
        let offsetOfLocalDateTime = getOffsetOfLocalDateTime(new Date());
        let offsetHr = offsetOfLocalDateTime.hr;
        let offsetMin = offsetOfLocalDateTime.min;

        this.isComponentLoaded && this.setState({"continueDisabled":true});
        let paramObj= {
        "taskName":"",
        "taskDescription":"",
        "dueDate": null,
        "categoryId":"",
        "typeId":"",
        "budget":"",
        "budgetCurrency":"",
        "taskDocIds":[],
        "lawyerDiscoveryParams":
            {
                "typeOfProfessionalId":"",
                "practiceAreasIds":[],
                "placeIds":[],
                "workExperience":[],
                "isOrganisationDetailsMasked":false,
                "googleCityDetails":[]
            },
        "offsetHour":null,
        "offsetMinute":null
        };

        const {values,values0} = this.props;
        //previous page values        
        const taskName = values0.stepOneStTaskName;
        const taskDescription = values0.stepOneStTaskDesc;
        const dueDate = values0.stepOneStTaskDueDate;
        const taskDocIds = values0.stepOneStDocuments;
        const offsetHour = values0.stepOneOffsetHour?values0.stepOneOffsetHour:offsetHr;
        const offsetMinute = values0.stepOneOffsetMinute?values0.stepOneOffsetMinute:offsetMin;       


        //current page values 
        const typeOfProfessionalId = parseInt(values.typeOfProfessionalId);
        const practiceAreasIds = values.practiceAreasIds;
        const categoryId = parseInt(values.categoryId);
        const typeId = parseInt(values.typeId);        
        const budgetCurrency =  values.budgetCurrency;
        const budget =  values.budget;
        const placeIds = values.placeIds; 
        const locSelected = values.locSelected;
        const workExperience = values.workExperience;              
        const isOrganisationDetailsMasked = values.isOrganisationDetailsMasked; 
        
        let taskTypeFlag = values.taskTypeFlag;


        let taskDocIdsAr = [];
        if(taskDocIds.length>0){
            
            Object.keys(taskDocIds).map((e,i)=>{
                taskDocIdsAr.push(taskDocIds[e].id);
            });           

        }

        // googleCityDetails related modifications for param to pass to save
        let googleCityDetails = [];
        if(locSelected.length>0){
            

            Object.keys(locSelected).map((e,i)=>{

                let googleCityDetailsObj = {
                    "cityName":null,
                    "state":null,
                    "country":null,
                    "latitude":null,
                    "longitude":null,
                    "placeId":null
                };
                let locObj = locSelected[e];
                googleCityDetailsObj.cityName = locObj.city!==undefined?locObj.city:null;
                googleCityDetailsObj.state = locObj.state!==undefined?locObj.state:null;
                googleCityDetailsObj.country = locObj.country!==undefined?locObj.country:null;
                googleCityDetailsObj.latitude = locObj.lat!==undefined?locObj.lat:null;
                googleCityDetailsObj.longitude = locObj.lng!==undefined?locObj.lng:null;
                googleCityDetailsObj.placeId = locObj.placeId!==undefined?locObj.placeId:null;


                googleCityDetails.push(googleCityDetailsObj);
            });           

        }


        paramObj.taskName = taskName;
        paramObj.taskDescription = taskDescription;
        paramObj.dueDate = dueDate;
        paramObj.offsetHour = offsetHour;
        paramObj.offsetMinute = offsetMinute;
        paramObj.taskDocIds = taskDocIdsAr;

        paramObj.categoryId = categoryId;
        paramObj.typeId = typeId;
        paramObj.budget = budget;
        paramObj.budgetCurrency = budgetCurrency;
        paramObj.lawyerDiscoveryParams.typeOfProfessionalId = typeOfProfessionalId;
        paramObj.lawyerDiscoveryParams.practiceAreasIds = practiceAreasIds;
        paramObj.lawyerDiscoveryParams.placeIds = placeIds;
        paramObj.lawyerDiscoveryParams.workExperience = workExperience;
        paramObj.lawyerDiscoveryParams.isOrganisationDetailsMasked = isOrganisationDetailsMasked;
        paramObj.lawyerDiscoveryParams.googleCityDetails = googleCityDetails;
        

        let draftId = values.draftId?values.draftId:0; 

        let paramObjData = {
                "taskTypeFlag" : taskTypeFlag,
                "data": paramObj,
                "draftId":draftId
        };

        //console.log(" paramObjData==>",paramObjData); return false;

        let stcservice = new CreateTaskService();
        stcservice.createTask(paramObjData).then(data =>{
            if(data.status==200){
                let respData ={};
                if(data.data.entity!==undefined){
                   //console.log.log("resp received after task creation===>>",data.data.entity);
                   respData  = data.data.entity;
                   let otherParam = {};
                   let taskIdData = respData.taskId!==undefined?respData.taskId:"";
                   this.props.setStatesForStep3ofTaskCreation("taskCreateResp",respData,otherParam);
                   //this.props.setStatesForStep3ofTaskCreation("taskId",taskIdData,otherParam);
                   //this.props.setStatesForStep3ofTaskCreation("multiTaskFileId","",otherParam);

                   if(respData.topUserDetails!==undefined){
                        if(respData.topUserDetails.length>0){
                            // proceed to next step of lawyer listing and send request
                            let nOtherParam = {};
                            this.props.nextStep(nOtherParam);
                            
                                
                        }else{
                            //********* for context starts *****
                            // updating data in context 
                            let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;  
                            // example
                            if(taskTypeFlag==0){
                                setShare({actionType:"SINGLETASK"}); 
                            }                      
                          
                            //********* for context ends *****  

                            let otherParamNlf1 = {};
                            this.noLawyerFoundModalOpen(otherParamNlf1);
                            //this.props.nextStep(otherParamNlf1);
                        }
                    }else{

                            //********* for context starts *****
                            // updating data in context 
                            let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;  
                            // example
                            if(taskTypeFlag==0){
                                setShare({actionType:"SINGLETASK"}); 
                            }                      
                          
                            //********* for context ends ***** 
                            
                            let otherParamNlf2 = {};
                            this.noLawyerFoundModalOpen(otherParamNlf2);  
                    }
                }

               
        }
        }).catch((err)=>{
                let errStatusCodeAr = [400,500]; // allowed error status code
                if(err.response && err.response.data){
                    let erRespData = err.response.data;
                    if(errStatusCodeAr.indexOf(erRespData.status) !== -1){         
                        let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                        //console.log.log(" errMsg on task creation==>",errMsg);

                        let notiMsg = errMsg;
                        notify(notiMsg,true,{
                        "successMsg":0,
                        "customWrapClass":"customerrmidlass",
                        "showCloseButton": false, 

                        "dismiss": {
                        "duration": 1500,
                        }

                        }); 
                                         
                }
                } 
        }).finally(()=>{
            this.isComponentLoaded &&  this.setState({"continueDisabled":false});
        });
    }

    continue = e =>{
        e.preventDefault();
        //this.props.nextStep();
        setTimeout(()=>{
            let chkValidate = this.custValidate();
            if(chkValidate){
                //this.props.nextStep();
                this.callCreateTaskService();
            }
        },1200);
        
    }

    previousStep = e =>{
        e.preventDefault();
        let pOtherParam = {};
        this.props.prevStep(pOtherParam);
    }

    custValidate =()=>{
        const {values} = this.props;
        //For error messages
        const typeOfProfessionalId = values.typeOfProfessionalId;
        const practiceAreasIds = values.practiceAreasIds;
        const categoryId = values.categoryId;
        const typeId = values.typeId;        
        const budgetCurrency =  values.budgetCurrency;
        const budget =  values.budget;
        const placeIds = values.placeIds; 
        const workExperience = values.workExperience;              
        const isOrganisationDetailsMasked = values.isOrganisationDetailsMasked;        
        
        let typeOfProfessionalIdErrMsg = '';
        let practiceAreasIdsErrMsg = '';
        let categoryIdErrMsg = '';
        let typeIdErrMsg = '';
        let budgetCurrencyErrMsg = '';
        let budgetErrMsg = '';
        let locSelectedErMsg = ''; //placeIds
        let workExperienceErrMsg = '';
        let isOrganisationDetailsMaskedErrMsg = '';

        let totErrCountTrack = [];       

        let regexBlankSpace = /^ *$/;   
        ////console.log.log("==values==>>",values);     
        
        if(typeOfProfessionalId.length==0||(regexBlankSpace.test(typeOfProfessionalId)==true)){
            typeOfProfessionalIdErrMsg = " Type of professional required.";
            totErrCountTrack.push(typeOfProfessionalIdErrMsg);
        }

        if(practiceAreasIds.length==0){
            practiceAreasIdsErrMsg = " Practice area required.";
            totErrCountTrack.push(practiceAreasIdsErrMsg);
        }

        if(categoryId.length==0||(regexBlankSpace.test(categoryId)==true)){
            categoryIdErrMsg = " Category area required.";
            totErrCountTrack.push(categoryIdErrMsg);
        }

        if(typeId.length==0||(regexBlankSpace.test(typeId)==true)){
            typeIdErrMsg = " Type is required.";
            totErrCountTrack.push(typeIdErrMsg);
        }

        if(budgetCurrency.length==0||(regexBlankSpace.test(budgetCurrency)==true)){
            budgetCurrencyErrMsg = " Currency is required.";
            totErrCountTrack.push(budgetCurrencyErrMsg);
        }

       

        if(this.state.budgetErrMsg){
            budgetErrMsg = this.state.budgetErrMsg;
            totErrCountTrack.push(budgetErrMsg);
          }else if(budget.length==0||(regexBlankSpace.test(budget)==true)){
              budgetErrMsg = " Budget is required.";
              totErrCountTrack.push(budgetErrMsg);
          }

        // if(placeIds.length==0){
        //     locSelectedErMsg = " Location is required.";
        //     totErrCountTrack.push(locSelectedErMsg);
        // }
        
        // if(isOrganisationDetailsMasked==""||isOrganisationDetailsMasked==false){
        //     isOrganisationDetailsMaskedErrMsg = " isOrganisationDetailsMasked is required.";
        //     totErrCountTrack.push(isOrganisationDetailsMaskedErrMsg);
        // }

        // Work Experience Required temporarily hidden
        
        // if(workExperience.length==0){
        //     workExperienceErrMsg = " Work Experience is required.";
        //     totErrCountTrack.push(workExperienceErrMsg);
        // }
        
        this.isComponentLoaded && this.setState({
            "typeOfProfessionalIdErrMsg":typeOfProfessionalIdErrMsg,
            "practiceAreasIdsErrMsg":practiceAreasIdsErrMsg,
            "categoryIdErrMsg":categoryIdErrMsg,
            "typeIdErrMsg":typeIdErrMsg,
            "budgetCurrencyErrMsg":budgetCurrencyErrMsg,
            "budgetErrMsg":budgetErrMsg,
            "locSelectedErMsg":locSelectedErMsg,
            "workExperienceErrMsg":workExperienceErrMsg,
            "isOrganisationDetailsMaskedErrMsg":isOrganisationDetailsMaskedErrMsg
        });


        if(totErrCountTrack.length>0){            
          return false;
        }     
      
        return true;
        }

    selectedGoogleDataInfo = (googpplcselectedinfo)=>{            
            let locSelected = [...this.state.locSelected];
            let locSelectedErMsg = "";
            let dupliLocAdded = [];
            //console.log("googpplcselectedinfo=>>>",googpplcselectedinfo);
            if(googpplcselectedinfo.city!==undefined && googpplcselectedinfo.city){
                if(locSelected.length<3){
                    // get duplicate if any
                    Object.keys(locSelected).map((e, i) => {
                        if(locSelected[e].city == googpplcselectedinfo.city){
                            dupliLocAdded.push(googpplcselectedinfo.city);
                        }
                    });
                    
                    // if not duplicate then push
                    if(dupliLocAdded.length==0  ){
                        locSelected.push(googpplcselectedinfo);
                        this.setState({"locSelected":locSelected},()=>{

                            if(this.state.locSelected.length>3){
                                this.setState({"locSelectedErMsg":locSelectedErMsg,"locdisabledFlag":true});
                            }else{                                 
                                //push location data to parent
                                
                                    let otherParam = {"action":"push","locSelected":googpplcselectedinfo};

                                    this.props.setStatesForStep2ofST("placeIds",googpplcselectedinfo,otherParam);
                                    ///console.log(" got this in step 2 googpplcselectedinfo=>>",googpplcselectedinfo);
                                    
                                    this.setLocaDisabled(this.state.locSelected);
                                
                                
                            }
                            
                        });
                    }                   
                    
                }else{
                    locSelectedErMsg = "Max 3 location can be selected.";
                    this.setState({"locSelectedErMsg":locSelectedErMsg,"locdisabledFlag":true});
                }
                
            }

            

            

    }

    setLocaDisabled(locSelected){
        let locSelectedErMsg = '';
        if(locSelected.length==3){
            this.setState({"locSelectedErMsg":locSelectedErMsg,"locdisabledFlag":true});
        }else{
            this.setState({"locSelectedErMsg":locSelectedErMsg,"locdisabledFlag":false});
        }
    }

    removeSelLocation(i,locSelected){
        let valuesData = [...this.state.locSelected];        
        valuesData.splice(i,1);  
            
        //console.log.log("11 locSelected==>",locSelected);

        //remove location data from parent
        let otherParam = {"action":"remove","locSelected":locSelected};
        this.props.setStatesForStep2ofST("placeIds",locSelected,otherParam); 
        
        this.isComponentLoaded && this.setState({ "locSelected":valuesData,"locdisabledFlag":false,"locSelectedErMsg":""});  
        
    }

    professionalOptionChangeTrack = (e) =>{
        let typeOfProfessionalIdValue = e.target.value;
        this.props.setStatesForStep2ofST("typeOfProfessionalId",typeOfProfessionalIdValue);
        // call service api to set practice areas
        let setPrevSel=false;
        this.getPracticeAreasFromService(typeOfProfessionalIdValue, setPrevSel);
    }

    categoryOptionChangeTrack = (e) =>{
        let categoryIdValue = e.target.value;
        this.props.setStatesForStep2ofST("categoryId",categoryIdValue);
        //console.log.log("categoryIdValue=>",categoryIdValue);
        // call service api to set type ids based on category 
        let setPrevSel=false;
        this.getTypesIdsFromCategoryService(categoryIdValue, setPrevSel);
    }

    typeOfCategOptionChangeTrack = (e) =>{
        let typeIdValue = e.target.value;
        this.props.setStatesForStep2ofST("typeId",typeIdValue);        
    }
    
    currencyOptionChangeTrack = (e) =>{
        let currencyValue = e.target.value;
        this.props.setStatesForStep2ofST("budgetCurrency",currencyValue);
        // set focus to budget input       
        this.budgetInputRef.current.focus();
    }

    budgetChangeTrack = (e) =>{
        let budgetValue = e.target.value;
        const {values} = this.props;
        let budgetPrevData = values.budget;
        if(budgetValue){
            if((validatePositiveInputNumber({"data":e.target.value}))){              
                budgetValue = e.target.value;
                         
            }
            else{
                budgetValue = budgetPrevData; 
            }
          }
        this.props.setStatesForStep2ofST("budget",budgetValue);       
    }

    budgetOnBlurTrack = async (e) =>{
        let budgetInputValue = e.target.value;
        let otherParam = {
            categoryId: 0,
            typeId: 0,
            budgetCurrency: ''
        }; 
        const {values} = this.props;        
        const categoryId = values.categoryId;
        const typeId = values.typeId;
        const budgetCurrency =  values.budgetCurrency;
        const budget =  values.budget;
        ////console.log.log(" categoryId==>"+categoryId," typeId=>"+typeId, " budgetCurrency=>"+budgetCurrency, " values =>", values );
        let errMsg = "";
        if(categoryId!='' && typeId!='' && budget!='' && budgetCurrency!=''){
            otherParam.categoryId = categoryId;
            otherParam.typeId = typeId;
            otherParam.budgetCurrency = budgetCurrency;

            let chkResp = await this.budgetFloorValueCheck(budgetInputValue, otherParam);
            if(chkResp){
                if(chkResp.checkStatus==false){
                    // error
                    errMsg = chkResp.errMsg?chkResp.errMsg:"Something went wrong, please try again.";
                    this.isComponentLoaded && this.setState({"budgetErrMsg":errMsg});
                }else{
                    // ok proceed
                    this.isComponentLoaded && this.setState({"budgetErrMsg":errMsg});
                }
            }
            
        }else{            
            //error
            errMsg = "Input for either Category of Task or Type of Task or Budget or Budget Currency is missing."; 
            this.isComponentLoaded && this.setState({"budgetErrMsg":errMsg});
        }

               
    }

    async budgetFloorValueCheck(budgetInputValue,otherParam){
        // From here
        let qryStrng = otherParam.categoryId+"/"+otherParam.typeId+"?currency="+otherParam.budgetCurrency;
        const respObj = {
            "floorPrice":0,
            "checkStatus": false,
            "errMsg": ""
        }

        let fpservice = new FloorPriceBasedOnCategAndTypeService();        
        await fpservice.getFloorPrice(qryStrng).then(data =>{
            if(data.status==200){
                if(data.data.entity!==undefined){
                    let floorPrice = data.data.entity;
                    if(budgetInputValue>=floorPrice){
                            //ok
                            respObj.floorPrice = floorPrice;
                            respObj.checkStatus = true;
                    }else{
                        // not ok
                        respObj.floorPrice = floorPrice;
                        respObj.checkStatus = false;
                        respObj.errMsg = "Budget cannot be less than floor price "+otherParam.budgetCurrency+" "+floorPrice;
                    }
                }
            }
        }).catch((err)=>{
                let errStatusCodeAr = [400,500]; // allowed error status code
                if(err.response && err.response.data){
                    let erRespData = err.response.data;
                    if(errStatusCodeAr.indexOf(erRespData.status) !== -1){         
                        let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                        // not ok
                        respObj.floorPrice = 0;
                        respObj.checkStatus = false;
                        respObj.errMsg = errMsg;                       
                }
                } 
        });

        return respObj;

        
    }

    workExperienceOptionChangeTrack = (e) =>{
        let workExperience = e.target.value;
        this.props.setStatesForStep2ofST("workExperience",workExperience);        
    }
   
    getPracticeAreasFromService(profId,setPrevSel){
       // reset all dependencies of professionalSelected
       let otherParam = {};                               
       this.resetAllDependencies("professionalSelected",otherParam); 

        if(profId!=''){
            let tpserv = new PracticeAreaOfProfessionService();        
            tpserv.getPracticeAreasOfProfession(profId).then(data =>{
            if(data.status==200){
                if(data.data.entity!==undefined){
                    this.isComponentLoaded && this.setState({"practiceAreaOptions":data.data.entity},()=>{
                              
                            if(setPrevSel==true){
    
                                const {values} = this.props;
                                const practiceAreasIds = values.practiceAreasIds;
    
                                if(practiceAreasIds.length>0 && this.state.practiceAreaOptions.length>0){
    
                                    let prevSelPracticeAreasObjs=[];
                                    Object.keys(this.state.practiceAreaOptions).map((e,i)=>{
                                        if(practiceAreasIds.indexOf(this.state.practiceAreaOptions[e].id)!==-1){
                                            prevSelPracticeAreasObjs.push(this.state.practiceAreaOptions[e]);
                                        }
                                    });
                        
                                    if(prevSelPracticeAreasObjs.length>0){
                                        this.isComponentLoaded &&  this.setState({"practiceAreaSelected":prevSelPracticeAreasObjs});
                                    }
    
                                }
                            }else{                   
                                
                            }
                                
                            
    
                    });
                }           
            }
            }).catch((e)=>{
                //console.log.log(" Something went wrong with api server=>",e);
            });
        }else{

        }
        
    }

    getCategFromPracticeAreaService(practicAreas,setPrevSel){
       
        // reset all practiceAreaSelected dependencies
        let otherParam = {};                               
        this.resetAllDependencies("practiceAreaSelected",otherParam);
        
        if(practicAreas!=''){
            let tpserv = new CategoriesBasedOnPracticeAreaService();        
            tpserv.getCategBasedOnPracticeArea(practicAreas).then(data =>{
            if(data.status==200){
                if(data.data.entity!==undefined){
                    this.isComponentLoaded && this.setState({"categoryOptions":data.data.entity},()=>{                           
    
                    });
                }           
            }
            }).catch((e)=>{
                //console.log.log(" Something went wrong with api server=>",e);
            });
        }else{
                        
        }
        
    }

    getTypesIdsFromCategoryService(categId,setPrevSel){
        // reset all dependencies of categorySelected
        let otherParam = {};                               
        this.resetAllDependencies("categorySelected",otherParam);

        if(categId!=''){
            let tpserv = new TypeIdBasedOnCategoryService();        
            tpserv.getTypeIdsBasedOnCategory(categId).then(data =>{
            if(data.status==200){
                if(data.data.entity!==undefined){
                    this.isComponentLoaded && this.setState({"typeIdOptions":data.data.entity},()=>{                           
    
                    });
                }           
            }
            }).catch((e)=>{
                //console.log.log(" Something went wrong with api server=>",e);
            });
        }else{            
                      
        }
        
    }

    async resetAllDependencies(keyname,otherParam){
        switch(keyname){
             case "professionalSelected":             
                this.isComponentLoaded &&  this.setState({"practiceAreaOptions":[],"practiceAreaSelected":[],
                "categoryOptions":[],"typeIdOptions":[]
                }, ()=>{
                    // reset multiselect plugin
                    this.multiselectprofareaRef.current.resetSelectedValues();
                });
                break;
            case "practiceAreaSelected":
                this.isComponentLoaded &&  this.setState({
                "categoryOptions":[],"typeIdOptions":[]
                });
                break;
            case "categorySelected":
                this.isComponentLoaded &&  this.setState({"typeIdOptions":[]});
                break; 
            case "typeIdSelected":
                ///this.isComponentLoaded &&  this.setState({"typeIdOptions":[]});
                break; 
              
        }
    }

    isOrganisationDetailsMaskedTrack = (e) =>{
        ////console.log.log("chk=>>",   e.target.value);
        const {values} = this.props;
        let isOrganisationDetailsMasked = (values.isOrganisationDetailsMasked);
        // if(isOrganisationDetailsMasked==""){
        //     isOrganisationDetailsMasked = false;
        // }
        isOrganisationDetailsMasked = !(isOrganisationDetailsMasked);

        this.props.setStatesForStep2ofST("isOrganisationDetailsMasked",isOrganisationDetailsMasked);  
    }

    /*** No lawyer found related starts  */
    /**
     * TaskAddSingleTaskStep2
     * Function name : organisationDetailModalOpen
     * Author        :
     * Created Date  : 11-2-2020
     * Purpose       : To open organisationDetailModal
     * Params        : 
    **/  
   noLawyerFoundModalOpen = (otherParam)=>{    
    this.isComponentLoaded &&  this.setState({showNoLawyerFoundModal:true},()=>{
      this.nolawyermodref.current.showModal();
      this.getNoLawyerFoundContentModalOpen();
    });    
      
  }

  /**
     * TaskAddSingleTaskStep2
     * Function name : organisationDetailModalClose
     * Author        :
     * Created Date  : 11-2-2020
     * Purpose       : To close organisationDetailModal
     * Params        : 
    **/
   noLawyerFoundModalClose = ()=>{    
    this.isComponentLoaded && this.setState({showNoLawyerFoundModal:false},()=>{
      this.nolawyermodref.current.hideModal();
    });
    }

    /**
   * TaskAddSingleTaskStep2
   * Function name : getOrganisationDetailModalOpen
   * Author        :
   * Created Date  : 11-2-2020
   * Purpose       : Get modal view with data
   * Params        : 
  **/
    getNoLawyerFoundContentModalOpen() {   
        const {cancelButtonAction} = this.props;

        let viewData = <div className="dash_modal">
        
                <>
            <div className="modal-header">
                <h4 className="modal-title modal_title_tool">New Task Created
                {/*<div className="input_tooltip_bg">
                        <span className="input_tooltip" aria-label="Organisation's Details" data-balloon-pos="down">
                        <i className="fa fa-question-circle"></i>
                        </span>
                        </div>*/}
                </h4>
                <button type="button" onClick={
                    ()=>{
                    this.noLawyerFoundModalClose();
                    setTimeout(()=>{
                        cancelButtonAction();
                    },250);                   

                }} className="close modal_close" data-dismiss="modal">&times;</button>
            </div>
            
            <div className="modal-body padding_top">
                <div className="popUP_text_p">
                        <p>

                  Thank you for creating the task request. We will get in touch with you.
                  </p>

              </div>
            </div>
            <div className="modal-footer">
               
                <button  type="button" className="btn btn-primary modal_btn_save" onClick={(e)=>{ e.preventDefault();
                    this.noLawyerFoundModalClose();
                    setTimeout(()=>{
                       cancelButtonAction();
                    },250); 

                } } data-dismiss="modal"><span>Continue</span></button>
                
            </div>
          
          
          </>
        
                        
        </div>;
    
        this.nolawyermodref.current.updateStateContent(viewData);
        this.nolawyermodref.current.showContent();
        return viewData;
    }    

    /*** No lawyer found  modal related ends  */

    trackSubTaskOrNot = (e)=>{
        const {values} = this.props;
        const taskTypeFlag = values.taskTypeFlag;
        const subTaskFlag = values.subTaskFlag;

        let subTaskFlagData = subTaskFlag==0?1:0;
        let otherParam = {};
        this.props.changeSubTaskFlag(subTaskFlagData,otherParam);

    }

    /**
     * TaskAddSingleTaskStep2
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 27-02-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentDidMount() {

        //
        // let notiMsg = "Manna ..";
        // notify(notiMsg,true,{
        // "successMsg":0,
        // "customWrapClass":"customerrmidlass",
        // "showCloseButton": false, 

        // "dismiss": {
        // // "duration": 1500,
        // }

        // }); 

        //
        this.isComponentLoaded =  true; 
        // scroll top
        //window.scrollTo(0, this.refouterdivstep2.current.offsetTop);
        commonnotitrack("SCROLL_TO_MAIN_HEADER",{
            "notiType": "SCROLL_TO_MAIN_HEADER",
            "triggerCallback":true,
            "trigerredFrom":"TASK_CREATION_FLOW"
           });
        

        let tpserv = new TypeOfProfessionalsService();
        let paramsData = [];
        tpserv.getTypeOfProfessional(paramsData).then(data =>{
        if(data.status==200){
            if(data.data.entity!==undefined){
                this.isComponentLoaded && this.setState({"typeOfProfessionals":data.data.entity});
            }           
        }
        }).catch((e)=>{
            //console.log.log(" Something went wrong with api server=>",e);
        });
        
        const {values} = this.props;
        const practiceAreasIds = values.practiceAreasIds;
        const typeOfProfessionalId = values.typeOfProfessionalId;
        const categoryId = values.categoryId;
        const locSelected = values.locSelected; 
        const workExperience = values.workExperience; 
        const expData =  values.expData;

        if(typeOfProfessionalId!=''){
            let setPrevSel=true;
            this.getPracticeAreasFromService(typeOfProfessionalId,setPrevSel);
        }

        if(practiceAreasIds.length>0){           
            let setPrevSel= false;
            let practiceAreasIdsStr = practiceAreasIds.join(",");
            this.getCategFromPracticeAreaService(practiceAreasIdsStr,setPrevSel);
        }

        if(categoryId!=''){           
            let setPrevSel= false;            
            this.getTypesIdsFromCategoryService(categoryId,setPrevSel);
        }

        if(locSelected.length>0){
            this.isComponentLoaded && this.setState({"locSelected":locSelected});
            this.setLocaDisabled(locSelected);
        }

        if(workExperience.length>0){
            let matchedExpObjAr = [];
            Object.keys(expData).map((e,i)=>{
                if(workExperience.indexOf(expData[e].key)!==-1){
                    matchedExpObjAr.push(expData[e]);
                }
            });

            if(matchedExpObjAr.length>0){
                this.setState({"expDataSelected":matchedExpObjAr});
                    
            }else{
                //reset if no selected found
                this.multiselectexpRef.current.resetSelectedValues();
            }

        }     
        
    }

    

    /**
     * TaskAddSingleTaskStep2
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 27-02-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {  
        this.isComponentLoaded =  false;
    }    

    

    /**
     * TaskAddSingleTaskStep1
     * Function name : render
     * Author        :
     * Created Date  : 27-2-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() {    
        const {values, cancelButtonAction} = this.props;

        const typeOfProfessionalId = values.typeOfProfessionalId;
        const categoryId = values.categoryId;
        const typeId = values.typeId;
        const currencyStaticData =  values.currencyData;
        const budgetCurrency =  values.budgetCurrency;
        const budget =  values.budget;
        const expData =  values.expData;        
        const isOrganisationDetailsMasked = values.isOrganisationDetailsMasked; 

        const taskTypeFlag = values.taskTypeFlag;
        const subTaskFlag = values.subTaskFlag;  
        
        const singleTaskWithoutSubFlowD = values.singleTaskWithoutSubFlowD;
        //console.log.log("ST 2 singleTaskWithoutSubFlowD=>"+singleTaskWithoutSubFlowD);
        
        //console.log.log(" subTaskFlagv==>",subTaskFlag);
        //console.log("v===>",values);
        //console.log("****** singleTaskWithoutSubFlowD==>",singleTaskWithoutSubFlowD);
       return (
                <React.Fragment>  
                    <div ref={this.refouterdivstep2}>
                        {/* task header start */}
                        <div className="task_header task_header_withTopHeader">
                            Enter Task &amp; Professional Details
                            <span className="step_block">
                            <span>Step 02 / 03 </span>
                            <span className="progress">
                                <div className="progress-bar" style={{ width: "66%" }} />
                            </span>
                            </span>
                            <span className="taskstep_back_bg">
                            <button onClick={this.previousStep}>
                            <img className="back_img_icon" src={getBaseUrl()+"assets/images/path-back-white.png"} />
                                Back
                            </button>
                            </span>
                        </div>
                        {/* task header end */}
                        {/* task body area start */}
                        <div className="cutom_container cutom_container_withTopHeader">
                             
                            <div className="task_create_form_container task_big_formbox task_container_withbox new_autoHeightAdjust_taskCreate_bg">
                                <div className="createTask_newWrapping_singleMulti_bg">
                                    <div className="split_mid_box split_mid_multibox">
                                    {/* <form> */}
                                    { this.state.showNoLawyerFoundModal==false && ( <CustomMessageBar />) }
                                    <div className="task_form_box">
                                    <div className="task_top_suggestions toggle_btn_suggestion">
                                    {/* <span className="top_sug_left">
                                        <p>Do you want to add Sub-tasks for this task?</p>
                                        <div className="switch_btn">
                                        <label className="switch">
                                            <input type="checkbox" checked={(subTaskFlag==1)?true:false} onChange={this.trackSubTaskOrNot} />
                                            <span className="slider round" />
                                        </label>
                                        </div>
                                        <p />
                                    </span> */}
                                    <div className="clearboth" />
                                    </div>
                                    {/* === enter task box start ===  */}
                                    <div className="scroll_subtask_bg">
                                    <div className="enter_task_box">
                                        <h3 className="taskbox_title">Task &amp; Professional Details</h3>
                                        {/* <div className="allert_messg">
                                        All fields are multi-select. You can select more than one option
                                        for any of the fields below
                                        </div> */}
                                        <div className="main_form_box">
                                        <div className="form_block form_block_half">
                                            <div className="panel50 panel_half paddingright15 form__group_normar">
                                            <label htmlFor="name" className="form__label">
                                                <b>Type of Professional</b> &nbsp;
                                                <span className="top_sug_left">
                                                <span
                                                    className="input_tooltip btn_tooltip"
                                                    aria-label="Type of Professional"
                                                    data-balloon-pos="down"
                                                >
                                                    <i className="fa fa-question-circle" />
                                                </span>
                                                </span>
                                            </label>
                                            <div className={`form__group field ${ this.state.typeOfProfessionalIdErrMsg?"form_group_error": ""}` }>
                                                <select
                                                
                                                className="selectpicker dropup form__field"
                                                data-header="Organisation Size"
                                                value={typeOfProfessionalId} 
                                                onChange={this.professionalOptionChangeTrack}
                                                
                                                >
                                                <option  value="">Select</option>
                                                {Object.keys(this.state.typeOfProfessionals).map((e,i)=> <option key={i}  value={this.state.typeOfProfessionals[e].id}>{this.state.typeOfProfessionals[e].value}</option>) }
                                                    
                                                </select>                                            
                                                <span className="error_span">{this.state.typeOfProfessionalIdErrMsg}</span>
                                            </div>
                                            
                                            
                                            </div>
                                            <div className={`panel50 panel_half paddingleft15 form__group_normar  `} >
                                            <label htmlFor="name" className="form__label">
                                                <b>Practice Areas (Default Any)</b> &nbsp;
                                                <span className="top_sug_left">
                                                <span
                                                    className="input_tooltip btn_tooltip"
                                                    aria-label="Practice Areas - Max 3 inputs"
                                                    data-balloon-pos="down"
                                                >
                                                    <i className="fa fa-question-circle" />
                                                </span>
                                                </span>
                                            
                                            </label>
                                            <div className="form__group field">
                                                <div className={`multiselectouterdivcls ${ (this.state.practiceAreaOptions.length==0)|| (this.state.practiceAreaSelected.length==3)?"disabled disabled_without_dim":""} ${this.state.practiceAreasIdsErrMsg?"form_group_error":""}  `}>
                                                    <Multiselect
                                                    options={this.state.practiceAreaOptions} // Options to display in the dropdown
                                                    selectedValues={this.state.practiceAreaSelected} // Preselected value to persist in dropdown
                                                    
                                                    displayValue="value" 

                                                    selectionLimit="3"   
                                                    
                                                    onSelect={this.onSelectPracticeArea} 
                                                    onRemove={this.onRemovePracticeArea} 
                                                    placeholder="Select"
                                                    ref={this.multiselectprofareaRef}
                                                    />
                                                    
                                                </div>
                                                <span className="error_span">{this.state.practiceAreasIdsErrMsg}</span>

                                            </div>
                                            
                                            </div>
                                            <div className="clearboth" />
                                        </div>
                                        
                                        
                                        <div className="form_block form_block_half">
                                            <div
                                            className={`panel50 panel_half paddingright15 form__group_normar ${this.state.categoryOptions.length>0?"":"disabled"} `}
                                            
                                            >
                                            <label htmlFor="name" className="form__label">
                                                <b>Category of Task</b> &nbsp;
                                            </label>
                                            <div className={`form__group field ${ this.state.categoryIdErrMsg?"form_group_error": ""}` }>
                                                <select
                                                className="selectpicker dropup form__field"
                                                data-header="Organisation Size"
                                                disabled={this.state.categoryOptions.length>0?false:true}
                                                value={categoryId} 
                                                onChange={this.categoryOptionChangeTrack}

                                                >
                                                <option  value="">Select</option>
                                                {Object.keys(this.state.categoryOptions).map((e,i)=> <option key={i}  value={this.state.categoryOptions[e].id}>{this.state.categoryOptions[e].category}</option>) }
                                                </select>
                                                <span className="error_span">{this.state.categoryIdErrMsg}</span>
                                            </div>
                                            </div>
                                            <div className={`panel50 panel_half paddingleft15 form__group_normar ${ this.state.typeIdErrMsg?"form_group_error": ""}
                                            ${this.state.typeIdOptions.length==0?"disabled":""}
                                            ` } >
                                            <label htmlFor="name" className="form__label">
                                                <b>Type of task</b> &nbsp;
                                            </label>
                                            <div className="form__group field">
                                            <select
                                                className="selectpicker dropup form__field"
                                                data-header="Organisation Size"
                                                disabled={this.state.typeIdOptions.length>0?false:true}
                                                value={typeId} 
                                                onChange={this.typeOfCategOptionChangeTrack}

                                                >
                                                <option  value="">Select</option>
                                                {Object.keys(this.state.typeIdOptions).map((e,i)=> <option key={i}  value={this.state.typeIdOptions[e].id}>{this.state.typeIdOptions[e].categoryType}</option>) }
                                                </select>
                                                <span className="error_span">{this.state.typeIdErrMsg}</span>
                                            </div>
                                            </div>
                                            <div className="clearboth" />
                                        </div>
                                        <div className="form_block form_block_half">
                                        
                                        <div className="panel50 panel_half paddingright15 form__group_normar">
                                            <label htmlFor="name" className="form__label">
                                                <b>Location (Default - Any)</b> &nbsp;
                                                <span className="top_sug_left">
                                                    <span
                                                        className="input_tooltip btn_tooltip"
                                                        aria-label="Select max 3 inputs"
                                                        data-balloon-pos="down"
                                                    >
                                                        <i className="fa fa-question-circle" />
                                                    </span>
                                                    
                                                </span>
                                            </label>
                                            <div className={`form__group field rndselect ${this.state.locSelectedErMsg?"form_group_error":""}`}>
                                                
                                                <NewCustomGoogleAutoComplete ref={this.orgcitygaref} placeholder="Max 3 location can be selected" idname="orgcityid" textboxcustclass="orgdetgoogautocomptxtbxcls form__field" selectedGoogleDataInfo={this.selectedGoogleDataInfo}
                                                disabledFlag={this.state.locdisabledFlag}
                                                removeTextFlag="YES"
                                                />
                                                <span className="error_span">{this.state.locSelectedErMsg}</span>
                                            {this.state.locSelected.length>0 && (
                                                        <div className="locstselouterdiv">
                                                        <ul className="custclsforlocselectedforst">{Object.keys(this.state.locSelected).map((e,i)=>(
                                                            <li key={i}>
                                                                <span className="locselsttxtname">{this.state.locSelected[e].city}</span>
                                                                <span className="locselstbtnname"><button onClick={this.removeSelLocation.bind(this,i,(this.state.locSelected[e]))}></button></span>
                                                            </li>))}
                                                        </ul>
                                                    </div>

                                            )}  
                                                

                                            </div>
                                            </div>                                   
                                            
                                            
                                            <div className="panel50 panel_half paddingleft15 form__group_normar">
                                            <label htmlFor="name" className="form__label">
                                                <b>Budget</b>
                                                &nbsp;
                                                <span className="top_sug_left">
                                                <span
                                                    className="input_tooltip btn_tooltip"
                                                    aria-label="Professional's fee only; does not include your Platform Usage Fee. Please refer to Calculate Invoice to calculate the estimated overall invoice."
                                                    data-balloon-pos="down"
                                                >
                                                    <i className="fa fa-question-circle" />
                                                </span>
                                                </span>
                                            </label>
                                            <div className="form__group field">
                                                <div className={`price_input_grup_bg ${this.state.budgetErrMsg?"form_group_error":""}`}>
                                                <div className="price_input_grup">
                                                    <div className="price_input_grup_left">
                                                    <input maxLength="10" ref={this.budgetInputRef} onChange={this.budgetChangeTrack} value={budget} onBlur={this.budgetOnBlurTrack} type="text" placeholder="Budget" />
                                                    </div>
                                                    <div className="price_input_grup_right">
                                                    <select value={budgetCurrency} 
                                                onChange={this.currencyOptionChangeTrack} >
                                                    {Object.keys(currencyStaticData).map((e,i)=> <option key={i}  value={currencyStaticData[e].key}>{currencyStaticData[e].value}</option>) }
                                                    </select>
                                                    </div>
                                                </div>
                                                <span className="error_span">
                                                    {/* Please enter a minimum budget of INR 40000 for this Task. */}
                                                    {this.state.budgetErrMsg}
                                                </span>
                                                </div>
                                            </div>
                                            </div>

                                                        
                                            
                                        
                                            <div className="clearboth" />
                                        </div>

                                        {/* Work Experience Required temporarily hidden */}
                                                            
                                        {/* <div className="form_block form_block_half">
                                            

                                            <div className="panel50 panel_half paddingright15 form__group_normar">
                                            <label htmlFor="name" className="form__label">
                                                <b>Work Experience Required (Years)</b> &nbsp;
                                                <span className="top_sug_left">
                                                <span
                                                    className="input_tooltip btn_tooltip"
                                                    aria-label="Work Experience"
                                                    data-balloon-pos="down"
                                                >
                                                    <i className="fa fa-question-circle" />
                                                </span>
                                                </span>
                                            </label>
                                            <div className="form__group field">                

                                                <div className={`multiselectouterdivcls ${ (expData.length==0)|| (this.state.expDataSelected.length==3)?"disabled disabled_without_dim":""} ${this.state.workExperienceErrMsg?"form_group_error":""}`}>
                                                    <Multiselect
                                                    options={expData} // Options to display in the dropdown
                                                    selectedValues={this.state.expDataSelected} // Preselected value to persist in dropdown
                                                    
                                                    displayValue="value"
                                                    selectionLimit="3"                      
                                                    onSelect={this.onSelectExp} 
                                                    onRemove={this.onRemoveExp}
                                                    ref={this.multiselectexpRef}
                                                    />
                                                </div>          
                                                
                                                <span className="error_span">{this.state.workExperienceErrMsg}</span>
                                            </div>
                                            </div>
                                            
                                            
                                            <div className="clearboth" />
                                        </div> */}
                                        



                                        </div>
                                        {/* === enter task box end ===  */}
                                    </div>
                                    </div>
                                    {/* === enter task box end ===  */}
                                    
                                    {/* === form checkbox start ===  */}
                                    <div className="taskform_adjustbox">
                                        <div className="form_block">
                                        <div className="panel100">
                                            <div className="form__group field ">
                                            <div className={`formgroup_checkbox  ${ isOrganisationDetailsMasked===false?'':''}`}>
                                                <input onChange={this.isOrganisationDetailsMaskedTrack} type="checkbox" checked={isOrganisationDetailsMasked} id="html" />
                                                <label htmlFor="html">
                                                <p>
                                                    Keep my organisation identity confidential until I offer the task to the professional.
                                                </p>
                                                </label>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    {/* === form checkbox end ===  */}

                                </div>
                                                                
                                    {/* </form> */}
                                    </div>
                                </div>
                            </div>
                            {/* submit area button area strat */}
                            <div className="task_details_btn_area bottomStickFooter_taskCreate">
                            <div className="btn_fld">
                                <div className="panel50">
                                    <button onClick={(e)=>{
                                    e.preventDefault();
                                    this.saveDraft();
                                    }} disabled={singleTaskWithoutSubFlowD==false?true:false} className="btn_appearance_none saveDraftBtn">
                                    Save as Draft
                                    </button>
                                </div>
                                <div className="panel50">
                                <div className="cancel_continue_btn">
                                    <a href="" onClick={(e)=>{
                            e.preventDefault();
                            cancelButtonAction();
                        }} className="btn_txt">
                                    Cancel
                                    </a>
                                    <button disabled={this.state.continueDisabled} onClick={this.continue} className="sub_grd_btn" >
                                    <span>Continue</span>
                                    </button>
                                </div>
                                </div>
                                <div className="clearboth" />
                            </div>
                            </div>
                            {/* submit area button area end */}
                        </div>
                        {/* task body area end */}
                    </div>

                    {/* No lawyer found for task creation ST modal start */}
                    <CustReactBootstrapModal ref={this.nolawyermodref}  backdrop={false} backdropClassName="custom_modal_class"   animation={false} show={this.state.showNoLawyerFoundModal} onShow={()=>{}} onHide={()=>{}} modalcustdialog="custom_modal_lg"  loaderbodytitle="Organisation"  />
                    {/* No lawyer found for task creation ST modal end */}

                </React.Fragment>            
        );
    }

  }
  export default TaskAddSingleTaskStep2;