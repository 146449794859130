/**
 * SubscriptionPackage
 * Page/Class name   : SubscriptionPackage
 * Author            :
 * Created Date      : 30-6-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : render master component
**/
import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';

// for realtime
// import SockJS from 'sockjs-client';

import {notify} from '../../messagebar/CustomMessageBar';
import CustomMessageBar from '../../messagebar/CustomMessageBar';

// Services
import LoggedinUserInfoService from '../../service/LoggedinUserInfoService';
import SubscriptionPaymentLinkGenerateService from '../../service/SubscriptionPaymentLinkGenerateService';

// for context
import CommonContext from '../../context/CommonContext';

////import  CustDate  from '../../../common_utilities/CustDate';
import { commonJsFuncModule as commonJsObj } from '../../../common_utilities/commonjsfunc';

import  CustDate  from '../../../common_utilities/CustDate';

// common noti track
import NotiEmitTrack from "./NotiEmitTrack";
//import {commonnotitrack} from "./NotiEmitTrack";

const validateBlankSpace = commonJsObj.validateBlankSpace;
const getBaseUrl = commonJsObj.getBaseUrl;
const getFormattedAmount = commonJsObj.getFormattedAmount;
const getCcUserId = commonJsObj.getCcUserId;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const setAccessObj = commonJsObj.setAccessObj;

//for realtime websocket url
// const sockUrl = "/ws?token="+getTokenFromLocStorage();
//for realtime subscription url to receive notification
// const subscUrl1 = "/topic/notification."+getCcUserId();
//for realtime
// var Stomp = require('stompjs');

class SubscriptionPackage extends Component {

    isComponentLoaded =  false;
    // for context
    static contextType = CommonContext;

    /**  
     * SplitScreenTaskDocuments
     * Function name : constructor
     * Author        :
     * Created Date  : 30-6-2020
     * Purpose       : initializing children
     * Params        : props
    **/
    constructor(props) {
      super(props);
      this.children = props.children;
      this.state = {
            "loaderFlag": false,            
            "packageOriginalAmount": "25000",
            // subscription active related
            "isSubscriptionActive": false,
            "subscriptionEndDate": null,
            // subscription link generation related param
            "shouldEmailTheLink":false,
            "shouldSMSTheLink":false,
            "promoCode":null,

            // subscription link generate success response
            "originalAmount": 0,
            "promoCodeApplied": null,
            "discountPercentage": null,
            "promoAmount": null,
            "payableAmount": 0,
            "paymentLink": null,

            // error message related
            "promoCodeErMsg":""
      };

      // for realtime
      //this.clientsocket  = Stomp.over(new SockJS(sockUrl));
      
     
    }

    /**
     * SplitScreenTaskDocuments
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 30-6-2020
     * Purpose       : lifecycle method
     * Params        :
    **/
    componentDidMount() {
      this.isComponentLoaded = true;

     

      this.checkBeforeSet();

      // for realtime
      // const ref = this;       
      // this.clientsocket.connect( {}, function (){
      //      //console.log("==Yes connected ==");
           
      //       // now subscribe to 
      //       this.subscribe(subscUrl1, (message) =>{
      //         // called when the client receives a STOMP message from the server
      //         ref.callbackSubscUrl1(message);
  
      //       });
 
         
      // });
      
      
    }

   


    /**  
     * SplitScreenTaskDocuments
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 30-6-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {
      this.isComponentLoaded = false;

      // unset some data from share of context related to subscription
      let triggeredFrom = "";
      let subHeaderTabToActive = "";
      
      let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;
      let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;
      console.log(" from checkbeforeset sharedData=>>",sharedData);

      if(sharedData!==undefined && sharedData!==null ){ 
        
        subHeaderTabToActive = sharedData.subHeaderTabToActive!==undefined?sharedData.subHeaderTabToActive:"";
        

        if(sharedData.other!==undefined && sharedData.other!==null){
          triggeredFrom = sharedData.other.triggeredFrom!==undefined?sharedData.other.triggeredFrom:"";

          // unset for scenario => user came back from sub payment page and wants to navigate to other page as triggeredFrom="SUBSCRIPTION_PAYMENT" only when user comes back from subs payment page to subs payment package page
          if((triggeredFrom=="SUBSCRIPTION_PAYMENT" || triggeredFrom=="REAL_TIME_NOTI") && subHeaderTabToActive=="SUBSCRIPTION_PACKAGE"){ 
            sharedData.actionType=""
            sharedData.subHeaderTabToActive = "";
            sharedData.purpose = "";
            sharedData.other.triggeredFrom = "";
            sharedData.other.subscriptionPaymentInfo = null;
            setShare(sharedData); 

          }


        }
      }


    }

    // for realtime
    // callbackSubscUrl1 = (message) =>{
    //   // called when the client receives a STOMP message from the server
    //   if (message.body) {
    //     //console.log("=====got message with body from bell noti compo =>>>",message.body)

    //     let jsonWsResp = (message.body!==undefined && message.body!==null)?JSON.parse(message.body):null;
    //     let webSocketTaskInfo = null;
    //     let webSocketNotificationInfo = null;
    //     let webSocketCommentInfo = null;
    //     let notificationId = 0;
    //     let taskIdFromNoti = 0;
    //     //taskOwner
    //     let ccUserIdFromNoti = 0;

    //     if(jsonWsResp!==null){

    //        webSocketTaskInfo = (jsonWsResp.webSocketTaskInfo!==undefined && jsonWsResp.webSocketTaskInfo!==null)?jsonWsResp.webSocketTaskInfo:null;

    //        webSocketNotificationInfo = (jsonWsResp.webSocketNotificationInfo!==undefined && jsonWsResp.webSocketNotificationInfo!==null)?jsonWsResp.webSocketNotificationInfo:null;

           

    //        webSocketCommentInfo = (jsonWsResp.webSocketCommentInfo!==undefined && jsonWsResp.webSocketCommentInfo!==null)?jsonWsResp.webSocketCommentInfo:null;


    //        //console.log("==paresed==>> webSocketTaskInfo=>",webSocketTaskInfo," webSocketNotificationInfo==>>",webSocketNotificationInfo, " webSocketCommentInfo==>>",webSocketCommentInfo," typeof(webSocketNotificationInfo=>)",typeof(webSocketNotificationInfo));
    //        notificationId = webSocketNotificationInfo.notificationId!==undefined?webSocketNotificationInfo.notificationId:0;

    //       if(webSocketNotificationInfo && webSocketNotificationInfo.userNotificationType!==undefined && notificationId ){
    //         let userNotificationType = webSocketNotificationInfo!==null && webSocketNotificationInfo.userNotificationType!==undefined && webSocketNotificationInfo.userNotificationType!==null?webSocketNotificationInfo.userNotificationType:"";

    //         let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;

    //             //console.log(" found =====>>",userNotificationType," webSocketNotificationInfo==>",webSocketNotificationInfo);
    //             if(userNotificationType=="SUBSCRIPTION_SUCCESS"){
    //               let paramSuc = {};
    //               paramSuc.notiMsg = "Success!!";
    //               paramSuc.successOrError = 1; // 0 or 1
    //               paramSuc.dismissDuration = 1500; // generally 1500
    //               paramSuc.showCloseButton = true;
    //               this.props.showsuccessorerrormessage(paramSuc);

    //                 setTimeout(()=>{
    //                   this.getUserInfo();
    //                 },1900);

    //             }
    //             else if(userNotificationType=="SUBSCRIPTION_FAILURE"){

                 
    //               let paramSuc = {};
    //               paramSuc.notiMsg = "Something went wrong, please try later!!";
    //               paramSuc.successOrError = 0; // 0 or 1
    //               paramSuc.dismissDuration = 1900; // generally 1500
    //               paramSuc.showCloseButton = true;
    //               this.props.showsuccessorerrormessage(paramSuc);

    //                 setTimeout(()=>{
    //                   this.getUserInfo();
    //                 },1700);
                  
    //             }

            

    //       }


    //     }

    //   }
    // }


    /**  
     * SplitScreenTaskDocuments
     * Function name : componentDidUpdate
     * Author        :
     * Created Date  : 30-6-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentDidUpdate(prevProps){

    }

    getUserInfo = () =>{
      let luiserv = new LoggedinUserInfoService();
      luiserv.getLoggedInUserInfo({}).then(data =>{
          
      let respUser = data.data.entity;
      this.setUserSubscriptionData(respUser,{});

          
      }).catch(err=>{
          console.log("Some server related tech problem occured",err);
      });
    }

    checkBeforeSet = () => {
      let subHeaderTabToActive = "";
      let triggeredFrom = "";
      let subscriptionPaymentInfo = null;

      let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;
      //console.log(" from checkbeforeset sharedData=>>",sharedData);

      if(sharedData!==undefined && sharedData!==null ){ 
        
        subHeaderTabToActive = sharedData.subHeaderTabToActive!==undefined?sharedData.subHeaderTabToActive:"";

        if(sharedData.other!==undefined && sharedData.other!==null){
          triggeredFrom = sharedData.other.triggeredFrom;
          subscriptionPaymentInfo  = sharedData.other.subscriptionPaymentInfo!==undefined?sharedData.other.subscriptionPaymentInfo:null;
        }
      }

      if(triggeredFrom=="SUBSCRIPTION_PAYMENT" && subHeaderTabToActive=="SUBSCRIPTION_PACKAGE"){  
        if(subscriptionPaymentInfo!==undefined && subscriptionPaymentInfo!==null){
          

          let isSubscriptionActive = subscriptionPaymentInfo.isSubscriptionActive!==undefined && subscriptionPaymentInfo.isSubscriptionActive!==null?subscriptionPaymentInfo.isSubscriptionActive: false;

          let promoCode = subscriptionPaymentInfo.promoCode!==undefined && subscriptionPaymentInfo.promoCode!==null?subscriptionPaymentInfo.promoCode: "";

          this.isComponentLoaded && this.setState({"isSubscriptionActive":isSubscriptionActive, 
          "promoCode":promoCode},()=>{
            this.setSubscriptionLinkSuccData(subscriptionPaymentInfo,{});
          });
          
        }else{
          this.getUserInfo();
        }     
        
      }   
      else{
        console.log(" -------- here----");
        this.getUserInfo();
      }
      
    }


    handleChange = (e) => {
      this.isComponentLoaded &&  this.setState({[e.target.name]: e.target.value}); 
    }

    setUserSubscriptionData = (respUser,otherData) =>{
      if(respUser!==undefined && respUser!==null){
        
        let isSubscriptionActive = respUser.isSubscriptionActive!==undefined && respUser.isSubscriptionActive!==null?respUser.isSubscriptionActive:false;
        let subscriptionEndDate = respUser.subscriptionEndDate!==undefined && respUser.subscriptionEndDate!==null?respUser.subscriptionEndDate:null;

        let isKycVerified = respUser.isKycVerified!==undefined?respUser.isKycVerified:false;
        
        this.isComponentLoaded && this.setState({"isSubscriptionActive":isSubscriptionActive,"subscriptionEndDate":subscriptionEndDate},()=>{
          setAccessObj({"key":"isSubscriptionActive","keyValue":isSubscriptionActive});
          setAccessObj({"key":"isKycVerified","keyValue":isKycVerified});
          

        });

      }
    }

    setSubscriptionLinkSuccData = (subSuccData,otherData) =>{
      if(subSuccData!==undefined && subSuccData!==null){

        let subSuccParam = {
        "originalAmount": 0,
        "promoCodeApplied": null,
        "discountPercentage": null,
        "promoAmount": null,
        "payableAmount": 0,
        "paymentLink": null,
      
        "promoCodeErMsg":""
      };

        subSuccParam.originalAmount = subSuccData.originalAmount!==undefined?subSuccData.originalAmount:0;

        subSuccParam.promoCodeApplied = subSuccData.promoCodeApplied!==undefined?subSuccData.promoCodeApplied:null;

        subSuccParam.discountPercentage = subSuccData.discountPercentage!==undefined?subSuccData.discountPercentage:null;

        subSuccParam.promoAmount = subSuccData.promoAmount!==undefined?subSuccData.promoAmount:null;

        subSuccParam.payableAmount = subSuccData.payableAmount!==undefined?subSuccData.payableAmount:0;

        subSuccParam.paymentLink = subSuccData.paymentLink!==undefined?subSuccData.paymentLink:null;


        this.isComponentLoaded && this.setState(subSuccParam,()=>{
          if(otherData.calledFrom=="SUBSCRIBE_BUTTON"){
              // set share data and go to subscription payment 
              this.gotoToSubscriptionPayment({});

          }

          
        });
        

      }
    }

    gotoToSubscriptionPayment = (paramOther) =>{
      let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;
      let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;      
     
      //********* for context ends *****
      if(setShare && sharedData){
        let subscriptionPaymentInfo = {
          "originalAmount": this.state.originalAmount,
          "promoCodeApplied": this.state.promoCodeApplied,
          "discountPercentage": this.state.discountPercentage,
          "promoAmount": this.state.promoAmount,
          "payableAmount": this.state.payableAmount,
          "paymentLink": this.state.paymentLink,
          "isSubscriptionActive": this.state.isSubscriptionActive, 
          "promoCode": this.state.promoCode,       
         
        };

        if(sharedData.other!==undefined && sharedData.other!==null){   
            sharedData.actionType="SUBSCRIPTION_PAYMENT"
            sharedData.subHeaderTabToActive = "";
            sharedData.purpose = "VIEW_SUBSCRIPTION_PAYMENT";
            sharedData.other.triggeredFrom = "SUBSCRIPTION_PACKAGE";
            sharedData.other.subscriptionPaymentInfo = subscriptionPaymentInfo;
            setShare(sharedData);
        }else{
          let sharedDataNew = {
            "actionType":"SUBSCRIPTION_PAYMENT",
            "subHeaderTabToActive" : "",
            "purpose" : "VIEW_SUBSCRIPTION_PAYMENT",
            "other": {  
                        "triggeredFrom" : "SUBSCRIPTION_PACKAGE",
                        "subscriptionPaymentInfo" : subscriptionPaymentInfo 
                  }
          }       
          setShare(sharedDataNew);
        }

        setTimeout(()=>{
          this.props.history.push("/subscription-payment");
        },1500);
        

      }

    }

    checkPaymentLinkInState = () =>{
      let paymentLink = this.state.paymentLink;
      if(paymentLink){
        // this is helpful to reduce hit on payment link generate api call
        //this.gotoToSubscriptionPayment({});
        let calledParam = {"calledFrom":"SUBSCRIBE_BUTTON"};
        setTimeout(()=>{
          this.callToGenerateSubsLink(calledParam);
        },1200);

      }else{
        let calledParam = {"calledFrom":"SUBSCRIBE_BUTTON"};
        setTimeout(()=>{
          this.callToGenerateSubsLink(calledParam);
        },1200);
      }

    }

    setStateOfPromoErrMsg = (paramObj) =>{
      paramObj["payableAmount"] = 0;
      paramObj["promoCodeApplied"] = null;
      this.isComponentLoaded && this.setState(paramObj);

    }

    callToGenerateSubsLink = (calledParam) => {        
      let creds = {
        "postData":{ "shouldEmailTheLink":this.state.shouldEmailTheLink,
        "shouldSMSTheLink":this.state.shouldSMSTheLink,
        "promoCode":this.state.promoCode,}                    
       
                };
    

      ////console.log(" hit save api==>>",creds);
      //return false;

      let lserv = new SubscriptionPaymentLinkGenerateService();
      lserv.generateLink({"data":creds}).then(data =>{
          ////console.log("data=>",data,data.status);        
          if(data.status==200){              
            let respSubSucc = data.data.entity;
            // let paramSuc = {};
            // paramSuc.notiMsg = "Upadted successfully";
            // paramSuc.successOrError = 1; // 0 or 1
            // paramSuc.dismissDuration = 1500; // generally 1500
            // paramSuc.showCloseButton = false;
            // this.props.showsuccessorerrormessage(paramSuc);

            this.setSubscriptionLinkSuccData(respSubSucc,calledParam);
      
                          
          }                   
      }).catch(err=>{
        let errStatusCodeAr = [400,500]; // allowed error status code
        if(err.response && err.response.data){
            let erRespData = err.response.data;
            if(errStatusCodeAr.indexOf(erRespData.status) !== -1){     
              
                if(erRespData.status==400){
                  // it is invalid promocode
                  this.setStateOfPromoErrMsg({
                    "promoCodeErMsg" : (erRespData.message?erRespData.message:"Invalid subscription-code")});
                  
                }else{
                  let errMsg = erRespData.message?erRespData.message:"Some technical problem occurred from server. Please try again.";

                
                  let paramEr = {};
                  paramEr.notiMsg = errMsg;
                  paramEr.successOrError = 0; // 0 or 1
                  paramEr.dismissDuration = 1500; // generally 1500
                  paramEr.showCloseButton = false;
                  this.props.showsuccessorerrormessage(paramEr);
                }

                

          }
        }        
  
      }).finally(()=>{
        setTimeout(()=>{
          this.isComponentLoaded && this.setState({loaderFlag:false},()=>{
            });
        },1500);
        
      });
  
    }



    custValidate = ()=>{     
        let promocodeErMsg = "";
    
        let totErrCountTrack = [];   
          let stateErData = {
            "promocodeErMsg": promocodeErMsg,
    
          }
        
          this.isComponentLoaded && this.setState(stateErData);
      
          if(totErrCountTrack.length>0){          
                return false;
          }
          return true;
    }

    // for realtime
    trackNotiEvent = (otherParam) =>{
      let notiType =  otherParam.notiType!==undefined? otherParam.notiType: "";
      let trigerredFrom =  otherParam.trigerredFrom!==undefined? otherParam.trigerredFrom: ""; 

      if(notiType=="KYC"){
          this.getUserInfo();
      }
      
      if(notiType=="SUBSCRIPTION_SUCCESS"){              
          
          let paramSuc = {};
          paramSuc.notiMsg = "Success!!";
          paramSuc.successOrError = 1; // 0 or 1
          paramSuc.dismissDuration = 1500; // generally 1500
          paramSuc.showCloseButton = true;
          this.props.showsuccessorerrormessage(paramSuc);

            setTimeout(()=>{
              this.getUserInfo();
            },1900);

         
      }

      if(notiType=="SUBSCRIPTION_FAILURE"){              
          
          let paramSuc = {};
          paramSuc.notiMsg = "Something went wrong, please try later!!";
          paramSuc.successOrError = 0; // 0 or 1
          paramSuc.dismissDuration = 1900; // generally 1500
          paramSuc.showCloseButton = true;
          this.props.showsuccessorerrormessage(paramSuc);

            setTimeout(()=>{
              this.getUserInfo();
            },1700);
         
      }

    }
  

    /**  
     * SubscriptionPackage
     * Function name : render
     * Author        :
     * Created Date  : 30-6-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() {  

      let pkgAmtDisp  = (this.state.packageOriginalAmount && this.state.payableAmount>0)?this.state.payableAmount:this.state.packageOriginalAmount;
      let formatedPkgAmtDisp =  getFormattedAmount({"dataToFormat":pkgAmtDisp,
      "formatType":1});

      return (
        <>
          <NotiEmitTrack callbackfuncforcommontrackofnoti={this.trackNotiEvent} />
          <div className="kyc_section_part_02">
              <div className="dashboard_cont_area tasklist_cont_areabg setting_cont_areabg setting_main_body_section">
                {/* ===  DashBoard Main Body Start ===  */}
                <div className="cutom_container cutom_container_userSetting">
                  <div className="create_task_area userSetting_area">
                    {/* ===  task Listing filter start === */}
                    {/* ===  task Listing filter end === */}
                    {/* ===========  Setting Page main body start =============   */}
                    {/* ===  task Listing start start === */}
                    <div className="task_area userSetting_task_area">
                      {/* === User Setting Section / Task Listing Section Start === */}
                      {/* ============== Start =================   */}
                      <div className="userSetting_section">
                        {/* -----  scroll adjust for settting screens start -----   */}
                        <div className="task_listing_scrollbg task_listing_scrollbg_proSetting">
                          {/* ==============   User Setting Section Start =================   */}
                          <div className="userSetting_area_bg">
                            {/* =====  User KYC page body start =====  */}
                            <div className="userSetting_area user_page_area">
                              <div className="userSetting_scroll">
                                {/* ====  Subscription start ===  */}
                                <div className="subscription_sec_bg subscription_bg_cc">
                                  <div className="subscription_title">
                                    <h4>Subscription</h4>
                                  </div>
                                  <div className="subscription_mid_block">
                                    <div className="subs_block_topStatus">
                                                                                                                        {this.state.isSubscriptionActive 
                                                                                                                         && (

                                          <h4 className="greenTik_text greenTik_blackText">
                                          <span className="subGreenTik">
                                              <img src="assets/images/green_tick.png" />
                                          </span>
                                          <span className="subGreenTik_text">
                                              Subscribed
                                          </span>
                                          </h4> 
                                                                                                                        )}
                                        
                                                                     
                                    </div>
                                    {/* == subscription blue box start ==  */}
                                    <div className="subs_blueBox_bg">
                                      <div className="subsBlueBox_top">
                                        <h5>Elite</h5>
                                      </div>
                                      <div className="subsBlueBox_ammount_block">
                                        <div className="subsBlueBox_ammount">
                                          <img src="assets/images/sub_rupee_icon.png"  />
                                          <span> {formatedPkgAmtDisp} / year</span>
                                        </div>
                                        <h5>(inclusive of GST)</h5>
                                      </div>
                                      <div className="subsBlueBox_list_block">
                                        <ul>
                                          <li>
                                            <h4 className="greenTik_text greenTik_whiteText">
                                              <span className="subGreenTik_text">
                                                Give Tasks
                                              </span>
                                            </h4>
                                          </li>
                                          <li>
                                            <h4 className="greenTik_text greenTik_whiteText">
                                              <span className="subGreenTik_text">
                                                Full access to knowledge centre
                                              </span>
                                            </h4>
                                          </li>
                                          {/*                                                                     
                                                                              <li>
                                                                                  <h4 class="greenTik_text greenTik_whiteText">
                                                                                      <span class="subGreenTik_text">
                                                                                          Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                                                      </span>
                                                                                  </h4>
                                                                              </li> 
                                                                              */}
                                        </ul>
                                      </div>
                                      {this.state.isSubscriptionActive 
                                                                                                                         && (
                                      <div class="subsBlueBox_validity_block">

                                          <div class="subsBlueBox_validity_box">

                                          <p>Current plan valid till                                             {/* 7th July 2021 */}
                                            {this.state.subscriptionEndDate?CustDate.custFormatDate((new Date(this.state.subscriptionEndDate)),7):""}



                                          </p>

                                          </div>

                                      </div>
                                                                                                                         )}
                                      {this.state.isSubscriptionActive==false 
                                                                                                                         && (
                                                                                                                           <>
                                      <div className="subsBlueBox_promoCode_block">
                                        <div className="panel100 form__group_normar">
                                          <label htmlFor="name" className="form__label">
                                            <b>Have a subscription code?</b>&nbsp;
                                            <div className="clearboth" />
                                          </label>
                                          <div className={`form__group field ${this.state.promoCodeErMsg?"form_group_error":""}`}>
                                            <div className="multi_promo_input_block">
                                              <input
                                                className="promo_input"
                                                placeholder="Enter subscription code"
                                                type="text"
                                                name="promoCode"
                                                value={this.state.promoCode!==null?this.state.promoCode:""}
                                                onChange={this.handleChange}
                                              />
                                              <button 
                                              disabled={this.state.loaderFlag} 
                                              onClick={(evn)=>{evn.preventDefault();
                                              this.isComponentLoaded && this.setState({"loaderFlag":true},()=>{
                                                  let calledParam = {"calledFrom":"PROMO_CODE_APPLY"};
                                                setTimeout(()=>{
                                                  this.callToGenerateSubsLink(calledParam);
                                                },1200);


                                              });  
                                              
                                              
                                              }}
                                              className="btn_appearance_none greenText_btn promo_input_btn">
                                                Apply
                                              </button>
                                              <div className="clearboth" />
                                            </div>
                                            <span className="error_span">
                                             {this.state.promoCodeErMsg}
                                            </span>
                                            <span className="success_span">
                                              { this.state.promoCodeApplied!==null?"Subscription code applied!!":""}</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="subsBlueBox_button_block">
                                        <button disabled={this.state.loaderFlag} 
                                              onClick={(evn)=>{evn.preventDefault();
                                              this.isComponentLoaded && this.setState({"loaderFlag":true},()=>{
                                                this.checkPaymentLinkInState();


                                              });  
                                              
                                              
                                              }}  className="btn_appearance_none sub_grd_btn">
                                          <span>Subscribe</span>
                                        </button>
                                      </div>
                                            </>
                                            )}
                                    
                                    </div>
                                    {/* == subscription blue box start ==  */}
                                  </div>
                                </div>
                                {/* ====  Subscription end ===  */}
                                {/* ====  User KYC start ===  */}
                                {/* ====  User KYC end ===  */}
                              </div>
                            </div>
                            {/* =====  User KYC page body end =====  */}
                            {/* =====  Virtual account page body start =====  */}
                            {/* =====  Virtual account page page body end =====  */}
                          </div>
                          {/* ==============   User Setting Section end =================   */}
                        </div>
                        {/* -----  scroll adjust for settting screens end -----   */}
                      </div>
                      {/* ============== end =================   */}
                      {/* === User Setting Section / Task Listing Section end === */}
                    </div>
                    {/* ===  task Listing start end === */}
                    {/* ===========  Setting Page main body end =============   */}
                  </div>
                </div>
                {/* ===  DashBoard Main Body End ===  */}
              </div>
              {/* ===  DashBoard Main Body Footer Start ===  */}
              {/* User Setting footer strat */}
              {/* *****  Put as Dispaly: None ***** */}
              <div
                className="task_details_btn_area invoPay_footer kyc_footer"
                style={{ display: "none" }}
              >
                <div className="btn_fld">
                  <div className="panel50">
                    <button className="btn_appearance_none green_btn">
                      <span>Save as draft</span>
                    </button>
                  </div>
                  <div className="panel50">
                    <div className="cancel_continue_btn">
                      <button
                        type="button"
                        data-dismiss="modal"
                        className="btn_appearance_none modal_text_btn"
                      >
                        <span>Cancel</span>
                      </button>
                      <button className="btn_appearance_none sub_grd_btn">
                        <span>Submit</span>
                      </button>
                    </div>
                  </div>
                  <div className="clearboth" />
                </div>
              </div>
              {/* *****  Put as Dispaly: None ***** */}
              {/* User Setting footer end */}
              {/* ===  DashBoard Main Body Footer end ===  */}
          </div>

                  
        </>
      );
    }
}
export default withRouter(SubscriptionPackage);