/**  
 * NotiRemoveService
 * Page/Class name   : NotiRemoveService
 * Author            :
 * Created Date      : 9-7-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, getTweetRecords
 * Purpose           : api service page
**/
import {Component } from 'react';
// import {getEndPoint,getTokenFromLocStorage, callApi} from  '../../common_utilities/commonjsfunc';
// newly added axios
//import axios from 'axios';
import {axiosCustomObj} from '../customisedthirdparty/AxiosCustom';

import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
const callApi = commonJsObj.callApi;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;



class NotiRemoveService extends Component {

    
    /**  
     * NotiRemoveService
     * Function name : constructor
     * Author        :
     * Created Date  : 9-7-2020
     * Purpose       : initializing state
     * Params        : 
    **/
    constructor() {
      super();
      this.state = {
          
      }

       //this.axiosObj = axios;
       this.axiosObj = null;
      
      this.apiEndPoint = commonJsObj.getEndPoint()+'rest/user/notifications';
    }

    /**  
     * DraftTaskService
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 9-7-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentDidMount() {
    }

    /**  
     * CreateTaskService
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 9-7-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentWillUnmount() {  
    }



    /**  
     * updateNotiStatus
     * Function name : deleteNotification
     * Author        :
     * Created Date  : 9-7-2020
     * Purpose       : delete Notification 
     * Params        : paramObj
    **/
    async deleteNotification(paramObj){
      this.axiosObj = axiosCustomObj({});
      //console.log(" paramObj to save draft in service==>", paramObj);
      let accToken = getTokenFromLocStorage();     
      try{
            let urldata =this.apiEndPoint;
            

            let dataObj = paramObj.paramToPost;  
            console.log(" ==dataObj==>",dataObj," accToken=>>",accToken);
           

            let headerOptn = {  
              "Content-Type": "application/json",
              "Authorization": accToken
            };

            
            let respObj = this.axiosObj({
              method: 'DELETE',
              url: urldata,
              data: dataObj,
              headers: headerOptn
            });
        

            return respObj;


            
    }
    catch(err){
        console.log("Some error occured===>",err); // TypeError: failed to fetch
    }
      

    }

    
}
export default NotiRemoveService;