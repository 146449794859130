/** 
 * SigninOtp
 * Page/Class name   : SigninOtp
 * Author            :
 * Created Date      : 10-2-2020
 * Functionality     : Signin-otp
 * Purpose           : render Signup component
**/
import React, {Component} from 'react';


import CustLoader from '../after_login/CustLoader';
import { withRouter } from "react-router-dom";
import {commonJsFuncModule as commonJsObj} from  '../../../common_utilities/commonjsfunc';

import UserLoginService from '../../service/UserLoginService';
import UserLoginOtpResendService from '../../service/UserLoginOtpResendService';
import LoggedinUserInfoService from '../../service/LoggedinUserInfoService';

import {notify} from '../../messagebar/CustomMessageBar';

const custEncode = commonJsObj.custEncode;
const getUserEmailBeforeLogin = commonJsObj.getUserEmailBeforeLogin;
const removeUserInfoBeforeLogin = commonJsObj.removeUserInfoBeforeLogin;





class SigninOtp extends Component {

  isComponentLoaded = false;

  

  /**
   * SigninOtp
   * Function name : constructor
   * Author        :
   * Created Date  : 10-2-2020
   * Purpose       : initializing state
   * Params        : props
  **/
  constructor(props) {
      super(props);

      this.state = {
        emailId: '',
        otp:'',
        tokenRespData:{},
        loggedInUserInfoResp: {},
        loaderFlag: false,
        rloaderFlag: false,

        emailIdErMsg: '',
        otpErMsg: ''
        
      }
      
    
  }

  /**
     * SigninOtp
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 10-2-2020
     * Purpose       : life cycle method, calling api to get week tweet count
     * Params        : 
    **/

  componentWillMount() {      
  }
  componentDidMount() {
      this.isComponentLoaded = true;    
      let emailIdData = (getUserEmailBeforeLogin()!==null)?getUserEmailBeforeLogin():'';
      //console.log("from emailIdData-=>",emailIdData);
      this.isComponentLoaded && this.setState({emailId:emailIdData});   
  }

  handlePlaceChanged(){
    const place = this.autocomplete.getPlace();
    this.props.onPlaceLoaded(place);
  }

  
  /**
     * SigninOtp
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 10-2-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
   componentWillUnmount() {  
    this.isComponentLoaded = false; 
  }

  /**
   * SigninOtp
   * Function name : showLoader
   * Author        :
   * Created Date  : 10-2-2020
   * Purpose       : Used to show loader
   * Params        : 
  **/
  showLoader(){
    this.isComponentLoaded && this.setState({loaderFlag: true});
  }

  /**
   * SigninOtp
   * Function name : hideLoader
   * Author        :
   * Created Date  : 10-2-2020
   * Purpose       : Used to hide loader
   * Params        : 
  **/
  hideLoader(){
    this.isComponentLoaded && this.setState({loaderFlag: false});
  }

  /**
   * SigninOtp
   * Function name : loadLoader
   * Author        :
   * Created Date  : 10-2-2020
   * Purpose       : Used to load loader
   * Params        : 
  **/
  loadLoader(){
      return (
                <CustLoader className={'miniButnLoaderWhite'} loaderText={''}  />
      );
  }

  /**
   * SigninOtp
   * Function name : render
   * Author        :
   * Created Date  : 10-2-2020
   * Purpose       : lifecycle method
   * Params        : 
  **/
  render(){
      return (
       <>
        <div className="signup_form_area">
                <div className="sign_up_top">
                  <h1>Enter OTP</h1>
                  <div className="subheading">
                    Please enter the OTP that was sent to your email ID
                  </div>
                </div>
                <div className="form_area">
                  {/*===  form start === */}
                  <form>
                    <div className="form_block">
                      <div className="panel100 paddingleftright15 tooltip_input_bg">
                        <div className={`form__group field  ${ this.state.otpErMsg?'form_group_error':''}`}>
                          <input
                            type="input"
                            className="form__field"
                            placeholder="Enter OTP"
                            name="otp"
                            id="otp"
                            onChange={ this.handleChange }
                            autoComplete="off"

                            onKeyDown={(evn)=>{ 
                              if(evn.keyCode==13){
                              evn.preventDefault();
                              } 
                            }}

                          />
                          <label htmlFor="name" className="form__label">
                            Enter OTP
                          </label>
                          <span className="error_span">{this.state.otpErMsg}</span>
                        </div>
                        <div className="input_tooltip_bg">
                          <span
                            className="input_tooltip"
                            aria-label="OTP"
                            data-balloon-pos="down"
                          >
                            <i className="fa fa-question-circle" />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="form_block form_block_link">
                      <div className="paddingleftright15">
                        
                        <button disabled={this.state.rloaderFlag} onClick={this.resendOtpFunc} className="resendOtpButnCls" >
                        <span>{`${this.state.rloaderFlag?'Please wait...':'Resend OTP'}`}
                        </span>
                        </button>
                      </div>
                    </div>
                    <div className="form_block">
                      <div className="panel100 paddingleftright15 button_ip_panel">
                        <button disabled={this.state.loaderFlag}   onClick={this.handleClick}  type="button" className="btn btn-primary sub_grd_btn">
                          <span>Login</span>
                        </button>
                      </div>
                    </div>
                  </form>
                  {/*===  form end === */}
                  {/* ===  login bottm === */}
                  <div className="login_page_bottom">
                    <div className="log_bottm_sec">
                      {/* <p>Don’t have an account?</p>
                      <div className="log_bottom_btn">
                        <button className="log_page_btn" onClick={()=>{this.props.history.push("/signup");}} >
                          <span>Sign up</span>
                        </button>
                      </div> */}
                    </div>
                  </div>
                  {/* ===  login bottm === */}
                </div>
              </div>          
       </>
      
      );  
  }

  /**
   * Signin-otp
   * Function name : handleChange
   * Author        :
   * Created Date  : 14-2-2020
   * Purpose       : Used to handle change event
   * Params        : 
  **/
 handleChange = (e) => {
  this.isComponentLoaded &&  this.setState({[e.target.name]: e.target.value}); 
  }

  /**
   * Signin-otp
   * Function name : custValidate
   * Author        :
   * Created Date  : 14-2-2020
   * Purpose       : validate data before submit
   * Params        : 
  **/
  custValidate = ()=>{
    
  let otpErMsg = '';   
  let totErrCountTrack = [];   
  
  let regexBlankSpace = /^ *$/;   

  if(!(this.state.otp)||(this.state.otp.length==0) || (regexBlankSpace.test(this.state.otp)==true) ){
    otpErMsg = "OTP is required.";        
    this.isComponentLoaded && this.setState({ "otpErMsg": otpErMsg});
    totErrCountTrack.push(otpErMsg);
  } 
  else{
    this.isComponentLoaded &&  this.setState({ "otpErMsg":''});  
  }

  if(!(this.state.emailId)||(this.state.emailId.length==0)){
    let erMsg = "Sorry cannot proceed!";
      totErrCountTrack.push(erMsg);
      
      notify(erMsg,true,{
      'successMsg':0,
      'customWrapClass':'customsuccclass'});

      setTimeout(()=>{
        this.props.history.push("/signin");},
      1800);     
    
  }  

  
  if(totErrCountTrack.length>0){
    return false;
  }     

  return true;
  }

  /**
   * Signin-otp
   * Function name : handleClick
   * Author        :
   * Created Date  : 14-2-2020
   * Purpose       : validate data before submit
   * Params        : 
  **/
  handleClick = (event) => {
  event.preventDefault();

  this.isComponentLoaded && this.setState({loaderFlag:true});
  const isValid = this.custValidate();

  if(isValid){
    const creds = { "otp": this.state.otp, "emailId": this.state.emailId  };
    let lserv = new UserLoginService();
    lserv.getLoginResp(creds).then(data =>{
        if(data.status==200){
            this.isComponentLoaded &&  this.setState({tokenRespData:data.data});
            //**** set token and user info and redirect to dashboard page ****            
            let userInfoLoc = {             
                ltoken: custEncode(this.state.tokenRespData.access_token),
                expires_at: custEncode(this.state.tokenRespData.expires_at),
                refresh_token: custEncode(this.state.tokenRespData.refresh_token),
                role: custEncode(this.state.tokenRespData.role),                 
                userId: custEncode(this.state.tokenRespData.userId),
              
              };
              localStorage.setItem("laluser", JSON.stringify(userInfoLoc));
              
              setTimeout(()=>{                
                
                let luiserv = new LoggedinUserInfoService();
                luiserv.getLoggedInUserInfo({}).then(data =>{
                    
                  this.isComponentLoaded &&  this.setState({loggedInUserInfoResp:data.data.entity});

                  userInfoLoc.userName = custEncode(this.state.loggedInUserInfoResp.userName);
                  userInfoLoc.userEmailId = custEncode(this.state.loggedInUserInfoResp.emailId);
                  //userInfoLoc.organisationName = custEncode(this.state.loggedInUserInfoResp.organisationName);

                  userInfoLoc.organisationMaxCapacity = custEncode(this.state.loggedInUserInfoResp.organisationDetails.organisationMaxCapacity);

                  userInfoLoc.organisationCurrentCapacity = custEncode(this.state.loggedInUserInfoResp.organisationDetails.organisationCurrentCapacity);

                  let orgObj= this.state.loggedInUserInfoResp.organisationDetails!==undefined?this.state.loggedInUserInfoResp.organisationDetails:null;

                  let orgLogoObj= (orgObj && orgObj.organisationLogo!==undefined)?this.state.loggedInUserInfoResp.organisationDetails.organisationLogo:null;

                  let filePath = (orgLogoObj!==null && orgLogoObj.filePath!==undefined)? orgLogoObj.filePath:null;
                  userInfoLoc.userImage = custEncode(filePath);

                  let orgName = (orgObj!==null && orgObj.organisationName!==undefined)? orgObj.organisationName:null;
                  userInfoLoc.organisationName = custEncode(orgName);



                  userInfoLoc.shouldShowAddUserPopUp = custEncode(this.state.loggedInUserInfoResp.shouldShowAddUserPopUp);
                  userInfoLoc.shouldShowOrganisationPopUp = custEncode(this.state.loggedInUserInfoResp.shouldShowOrganisationPopUp);

                  userInfoLoc.isProfileUpdated = custEncode(this.state.loggedInUserInfoResp.isProfileUpdated);

                  

                  let isSubscriptionActive = (this.state.loggedInUserInfoResp.isSubscriptionActive!==undefined && this.state.loggedInUserInfoResp.isSubscriptionActive!==null)?this.state.loggedInUserInfoResp.isSubscriptionActive: false;

                  let isKycVerified = (this.state.loggedInUserInfoResp.isKycVerified!==undefined && this.state.loggedInUserInfoResp.isKycVerified!==null)?this.state.loggedInUserInfoResp.isKycVerified: false;

                  let isOrganisationKycVerified = (this.state.loggedInUserInfoResp.isOrganisationKycVerified!==undefined && this.state.loggedInUserInfoResp.isOrganisationKycVerified!==null)?this.state.loggedInUserInfoResp.isOrganisationKycVerified: false;

                  let accessObj = {
                    "isSubscriptionActive":isSubscriptionActive,
                    "isKycVerified":isKycVerified,
                    "isOrganisationKycVerified":isOrganisationKycVerified,

                  };

                  accessObj  = custEncode(JSON.stringify(accessObj));
                  userInfoLoc.accessObj = accessObj;
                  
                  
                  localStorage.setItem("laluser", JSON.stringify(userInfoLoc));

                  let succMsg = "Success";
                    notify(succMsg,true,{
                    'successMsg':1,
                    'customWrapClass':'customsuccclass'});
                    removeUserInfoBeforeLogin();
                   setTimeout(()=>{
                        if(this.state.loggedInUserInfoResp.shouldShowOrganisationPopUp!==undefined && this.state.loggedInUserInfoResp.shouldShowOrganisationPopUp==true){
                          this.props.history.push("/dashboard");
                        }else{
                          this.props.history.push("/tasks");
                        }
                        
                    },1200);

                    
                }).catch(err=>{
                    console.log("Some server related tech problem occured",err);
                });

              },250);
          }
      
    }).catch(err=>{
      
      let errStatusCodeAr = [400,500]; // allowed error status code
      if(err.response && err.response.data){
          let erRespData = err.response.data;
          if(errStatusCodeAr.indexOf(erRespData.status) !== -1){         
              let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
              notify(errMsg,true,{
              'successMsg':0,
              'customWrapClass':'customerrclass'});
        }
      }         

    }).finally(()=>{
      setTimeout(()=>{
        this.isComponentLoaded && this.setState({loaderFlag:false},()=>{
            //this.props.history.push("/signin-otp");
          });
      },1500);
      
    });
  }else{
    this.isComponentLoaded && this.setState({loaderFlag:false});      
  }   
  
  } 

  getEmailIdPresence(){
    let presenceFlag = true;
    if(!(this.state.emailId)||(this.state.emailId.length==0)){
      presenceFlag = false;
    }
    return presenceFlag;
  }
  resendOtpFunc = (event)=>{
    event.preventDefault();
    this.isComponentLoaded && this.setState({rloaderFlag:true});  
    const isValid = this.getEmailIdPresence();

    if(isValid){
      const creds = { emailId: this.state.emailId };
      let lserv = new UserLoginOtpResendService();
      
      lserv.getLoginOtpResp(creds).then(data =>{
        
          if(data.status==200){
            let succMsg = data.data.message;            

            notify(succMsg,true,{
              'successMsg':1,
              'customWrapClass':'customsuccclass'});             
            }
        
      }).catch(err=>{
        
        let errStatusCodeAr = [400,500]; // allowed error status code
        if(err.response && err.response.data){
            let erRespData = err.response.data;
            if(errStatusCodeAr.indexOf(erRespData.status) !== -1){         
                let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                notify(errMsg,true,{
                'successMsg':0,
                'customWrapClass':'customerrclass'});
          }
        }         
  
      }).finally(()=>{
        setTimeout(()=>{
          this.isComponentLoaded && this.setState({rloaderFlag:false},()=>{
            });
        },1500);
        
      });
    }else{
      this.isComponentLoaded && this.setState({rloaderFlag:false});      
    } 
    
  }

 

}

export default withRouter(SigninOtp);
