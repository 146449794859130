/**
 * TaskAddMultiTaskStep1
 * Page/Class name   : TaskAddMultiTaskStep1
 * Author            :
 * Created Date      : 1-11-2019
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : rendering loader structure
**/
import React, { Component, Suspense, lazy} from 'react';
import {Link, NavLink} from 'react-router-dom';
import '../../../custom_resources/css/custom.css';


// for context
import CommonContext from '../../context/CommonContext';

// Modal
import CustReactBootstrapModal from './CustReactBootstrapModal';

import TypeOfProfessionalsService from '../../service/TypeOfProfessionalsService';
import PracticeAreaOfProfessionService from '../../service/PracticeAreaOfProfessionService';
import CategoriesBasedOnPracticeAreaService from '../../service/CategoriesBasedOnPracticeAreaService';
import TypeIdBasedOnCategoryService from '../../service/TypeIdBasedOnCategoryService';
import FloorPriceBasedOnCategAndTypeService from '../../service/FloorPriceBasedOnCategAndTypeService';
import CreateTaskService from '../../service/CreateTaskService';
import FileUploadService from '../../service/FileUploadService';

import { Multiselect } from 'multiselect-react-dropdown';

import NewCustomGoogleAutoComplete from '../../customisedthirdparty/NewCustomGoogleAutoComplete';

import {notify} from '../../messagebar/CustomMessageBar';
import CustomMessageBar from '../../messagebar/CustomMessageBar';
import DraftTaskService from '../../service/DraftTaskService';
import {commonnotitrack} from "./NotiEmitTrack";

import { commonJsFuncModule as commonJsObj } from '../../../common_utilities/commonjsfunc';

const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;

const getBaseUrl = commonJsObj.getBaseUrl;    

class TaskAddMultiTaskStep1 extends Component {

    // for context
    static contextType = CommonContext; 

    isComponentLoaded = false;

    

    /**
     * TaskAddSingleTaskStep1
     * Function name : constructor
     * Author        :
     * Created Date  : 27-02-2020
     * Purpose       : initializing state
     * Params        : props
    **/
    constructor(props) {
        super(props);

        
        this.state = {
            typeOfProfessionals: [],
            practiceAreaOptions: [],
            categoryOptions: [],
            typeIdOptions: [],
            practiceAreaSelected: [],
            expDataSelected: [],

            locSelected: [],            
            locdisabledFlag: false,

            //For error messages  
            typeOfProfessionalIdErrMsg : '',
            practiceAreasIdsErrMsg :'',
            categoryIdErrMsg :'',
            typeIdErrMsg :'',
            locSelectedErMsg:'', //placeIds
            workExperienceErrMsg :'',
            isOrganisationDetailsMaskedErrMsg :'',

            // for no lawyer found modal
            showNoLawyerFoundModal: false,

            // specially for this page
            stepOneMtFileUplErMsg : "",
            continueDisabled: false,
            uploadingFlag: false

        };

        this.orgcitygaref = React.createRef();
        this.multiselectexpRef = React.createRef();
        this.multiselectprofareaRef  = React.createRef();
        this.budgetInputRef  = React.createRef();
        // for no lawyer found modal
        this.nolawyermodref = React.createRef();
        this.fileUpl = React.createRef();
        this.refouterdivstep1mt = React.createRef();
           
    }   

    removeDocOnClick(i){               
      let otherParam = {"action":"remove"};
      this.props.setStatesForStep1ofMT("setStepOneStDocumentsMt",i,otherParam);
      
    }

    trackFileSelection = e =>{
      e.preventDefault();
      let fileObj = e.target.files;        
      let chkFileValidation =  this.validateFileSelected(fileObj);
      ////console.log("==chkFileValidation=>>",chkFileValidation, fileObj);
      //this.calUploadFileService(fileObj); 
      if(chkFileValidation){
          this.calUploadFileService(fileObj);
      }else{
        this.fileUpl.current.value = "";
      }    
    }

    validateFileSelected(fileObj){
      const maxFileUploadAllowed = 1;
      const totAllowedMaxFileUploadSize = 500 * 1024 * 1024; // 500 MB
      const allowedFileExt = ['xlsx'];
      
      const {values} = this.props;        
      const docsData = values.stepOneStDocumentsMt;
      let respdataFlag =  true;
      let stepOneMtFileUplErMsg = "";
      if(docsData!==undefined){
          if(docsData.length==maxFileUploadAllowed){
              //error
              respdataFlag = false;
              stepOneMtFileUplErMsg = "Sorry you cannot upload more than "+maxFileUploadAllowed+"  files in whole.";
              
          }else{
              if(docsData.length < maxFileUploadAllowed){
                  let nowtotAllowedCntOfFileUpl = maxFileUploadAllowed - docsData.length;
                  if(fileObj.length <= nowtotAllowedCntOfFileUpl){
                      // check file validations like size and extension
                      let totPrevSize  = 0; 
                      Object.keys(docsData).map((k,i)=>{
                          totPrevSize = totPrevSize + docsData[k].size;                 
                      });
                      //////console.log("Total attempted size=>",totSize);

                      let totCurSize  = 0; let totalInvalidExtAr = [];
                      Object.keys(fileObj).map((k,i)=>{
                          totCurSize = totCurSize + fileObj[k].size; 
                          let nameFl = fileObj[k].name;
                          let extnReceived = nameFl.split('.').pop();
                          if(allowedFileExt.indexOf(extnReceived)==-1){
                              totalInvalidExtAr.push(extnReceived);
                          }  
                      });

                      //////console.log("chk size=>> totPrevSize=>"+totPrevSize+" totCurSize=>"+totCurSize, "Totl size now=>>"+(totCurSize + totPrevSize), " totAllowedMaxFileUploadSize=>>>"+totAllowedMaxFileUploadSize );
                      if( (totCurSize + totPrevSize) > totAllowedMaxFileUploadSize){
                          //error
                          respdataFlag = false;
                          stepOneMtFileUplErMsg = "You have crossed total maximum upload file size.";                            
                      }

                      
                      if(totalInvalidExtAr.length>0){
                          
                          //error
                          respdataFlag = false;
                          stepOneMtFileUplErMsg = "Invalid file extention."; 
                      }
                      

                  }else{
                      //error
                      respdataFlag = false;
                      stepOneMtFileUplErMsg = "You cannot upload more than "+maxFileUploadAllowed+" files in total (It includes previously uploaded files if any).";
                      
                  }
  
              }
          }
      }else{
          respdataFlag = false;
          stepOneMtFileUplErMsg = "Something went wrong. Please try again.";
      }   

      this.isComponentLoaded && this.setState({ "stepOneMtFileUplErMsg": stepOneMtFileUplErMsg});
      return respdataFlag;
    }

    async calUploadFileService(fileObj){
      //////console.log("chk file fileObj=>>>",fileObj);
      let lserv = new FileUploadService();
      this.isComponentLoaded && this.setState({"uploadingFlag":true});

      const {values} = this.props;
      const taskTypeFlag = values.taskTypeFlag;
      const categoryId = values.categoryIdMt;
      const typeId = values.typeIdMt;
      
      let stepOneMtFileUplErMsg = "";

      let paramObjData = {
          "taskTypeFlag" : taskTypeFlag,
          "data": fileObj,
          "cat_id":categoryId,
          "type_id":typeId,
     };

      lserv.uploadFiletoServer(paramObjData).then(data =>{
          //this.setStepOneStDocuments();
          ////console.log("success xlsx resp=>",data);
          let entityObj  = data.data.entity;       

          let otherParam = {"action":"push"};
          this.props.setStatesForStep1ofMT("setStepOneStDocumentsMt",entityObj,otherParam);


      }).catch(
        (err)=>{
          let errStatusCodeAr = [400,500]; // allowed error status code
          if(err.response && err.response.data){
              let erRespData = err.response.data;
              if(errStatusCodeAr.indexOf(erRespData.status) !== -1){         
                   stepOneMtFileUplErMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                  ////console.log(" errMsg on task creation==>",stepOneMtFileUplErMsg);
                                  
                                   
              }
          } 
        }
      ).finally(()=>{
        this.isComponentLoaded && this.setState({ "stepOneMtFileUplErMsg": stepOneMtFileUplErMsg});

        this.isComponentLoaded && this.setState({"uploadingFlag":false});
        this.fileUpl.current.value = ""; 
        
      });
  }

    onSelectPracticeArea=(selectedList, selectedItem)=>{
            ////console.log("onSelectPracticeArea=>"," selectedList=>",selectedList, " selectedItem=>",selectedItem);
            this.setPracAreaInLocalState(selectedList);

            let otherParam = {"action":"push"};
            this.props.setStatesForStep1ofMT("practiceAreasIdsMt",selectedItem,otherParam);
            
            // set categories based on practice area selection    
            this.setCategFromPracticeArea(selectedList,otherParam);
            
            
    }

    onRemovePracticeArea = (selectedList, removedItem) => {
        //////console.log("from remove selectedList==>",selectedList);
         this.setPracAreaInLocalState(selectedList);
         
         let otherParam = {"action":"remove"};
         this.props.setStatesForStep1ofMT("practiceAreasIdsMt",removedItem,otherParam);
          // set categories based on practice area selection    
          this.setCategFromPracticeArea(selectedList,otherParam);
        
     }
     setPracAreaInLocalState(pracObjAr){
      this.isComponentLoaded &&  this.setState({"practiceAreaSelected":pracObjAr});
    }

    setCategFromPracticeArea(selectedList,otherParam){
         // reset all dependencies of practiceAreaSelected
         let otherParamReset = {};                               
         this.resetAllDependencies("practiceAreaSelected",otherParamReset);

      
                // get categories based on practice area selection            
        let selectedPrcAreaData = ""; let  selectedPrcAreaAr = [];
        if(selectedList.length>0){
            Object.keys(selectedList).map((e,i)=>{
                selectedPrcAreaAr.push(selectedList[e].id);
            });
            if(selectedPrcAreaAr.length>0){
                selectedPrcAreaData = selectedPrcAreaAr.join(",");
            }
            // set categories based on practice area selection
            let setPrevSel= false;
            this.getCategFromPracticeAreaService(selectedPrcAreaData,setPrevSel);
        }else{
              
        }
        
        
        
    }

    onSelectExp = (selectedList, selectedItem)=>{
            ////console.log("onSelectExp=>"," selectedList=>",selectedList, " selectedItem=>",selectedItem);
            this.setExpInLocalState(selectedList);
            let otherParam = {"action":"push"};
            this.props.setStatesForStep1ofMT("workExperienceMt",selectedItem,otherParam);      
            
    }

    onRemoveExp = (selectedList, selectedItem)=>{
            ////console.log("onRemoveExp=>"," selectedList=>",selectedList, " selectedItem=>",selectedItem);
            this.setExpInLocalState(selectedList);
            let otherParam = {"action":"remove"};
            this.props.setStatesForStep1ofMT("workExperienceMt",selectedItem,otherParam);      
            
    }

    setExpInLocalState(expObjAr){
        this.setState({"expDataSelected":expObjAr});
    }

    saveDraft(){
    
      let offsetOfLocalDateTime = getOffsetOfLocalDateTime(new Date());
      let offsetHr = offsetOfLocalDateTime.hr;
      let offsetMin = offsetOfLocalDateTime.min;

      let paramObj= {        
        "categoryId":"",
        "typeId":"",       
         "lawyerDiscoveryParams":
            {
                "typeOfProfessionalId":"",
                "practiceAreasIds":[],
                "placeIds":[],
                "workExperience":[],
                "isOrganisationDetailsMasked":false
            },
          "multiTaskFileId": 0 ,
          "offsetHour":"",
          "offsetMinute":"", 
        };

       
  
        

        const {values} = this.props;      

        const draftIdM = values.draftIdM; 

        //current page values 
        const typeOfProfessionalId = values.typeOfProfessionalIdMt;
        const practiceAreasIds = values.practiceAreasIdsMt;
        const categoryId = values.categoryIdMt;
        const typeId = values.typeIdMt;       
        const placeIds = values.placeIdsMt; 
        const workExperience = values.workExperienceMt;              
        const isOrganisationDetailsMasked = values.isOrganisationDetailsMaskedMt; 
        const taskDocIds = values.stepOneStDocumentsMt;

        const taskTypeFlag = values.taskTypeFlag;

        let taskDocIdsAr = []; let multiTaskFileId=0;
        if(taskDocIds.length>0){
            
            Object.keys(taskDocIds).map((e,i)=>{
                taskDocIdsAr.push(taskDocIds[e].id);
            });           
            multiTaskFileId =  taskDocIdsAr[0];
        }       

        paramObj.categoryId = categoryId;
        paramObj.typeId = typeId;        
        paramObj.lawyerDiscoveryParams.typeOfProfessionalId = typeOfProfessionalId;
        paramObj.lawyerDiscoveryParams.practiceAreasIds = practiceAreasIds;
        paramObj.lawyerDiscoveryParams.placeIds = placeIds;
        paramObj.lawyerDiscoveryParams.workExperience = workExperience;
        paramObj.lawyerDiscoveryParams.isOrganisationDetailsMasked = isOrganisationDetailsMasked;
        paramObj.multiTaskFileId = multiTaskFileId;
        paramObj.offsetHour = offsetHr;
        paramObj.offsetMinute = offsetMin;

        let paramObjData = {
          "draftId" : draftIdM,
          "data": paramObj
        };
          //console.log(" from multitask page draft paramObjData==>",paramObjData);
          let lservdrf = new DraftTaskService();
          lservdrf.saveDraftTask(paramObjData).then(data =>{
                  //this.setStepOneStDocuments();
                  ////console.log("success resp=>",data);
                  let entityObj  = data.data.entity;       
                  ////console.log(" resp =>>",entityObj);
                  // set draft Id
                  let otherParam = {};
                  this.props.setDraftFlag("draftIdM",entityObj,otherParam);

                  // make draft button disabled
                  this.props.setDraftFlag("multiTaskFlowD",false,{});
                   


              }).catch((e)=>{
                  // make draft button enabled
                  this.props.setDraftFlag("multiTaskFlowD",true,{});
                      ////console.log("server error=>",e);
              }).finally(()=>{
                   
              });

  }

    callCreateTaskService(){   
      
      let offsetOfLocalDateTime = getOffsetOfLocalDateTime(new Date());
      let offsetHr = offsetOfLocalDateTime.hr;
      let offsetMin = offsetOfLocalDateTime.min;

        let paramObj= {        
        "categoryId":"",
        "typeId":"",       
         "lawyerDiscoveryParams":
            {
                "typeOfProfessionalId":"",
                "practiceAreasIds":[],
                "placeIds":[],
                "workExperience":[],
                "isOrganisationDetailsMasked":false,
                "googleCityDetails":[]
            },
          "multiTaskFileId": 0,
          "offsetHour":"",
          "offsetMinute":"",
        };

        const {values} = this.props;      


        //current page values 
        const typeOfProfessionalId = values.typeOfProfessionalIdMt;
        const practiceAreasIds = values.practiceAreasIdsMt;
        const categoryId = values.categoryIdMt;
        const typeId = values.typeIdMt;       
        const placeIds = values.placeIdsMt; 
        const locSelected = values.locSelectedMt;
        const workExperience = values.workExperienceMt;              
        const isOrganisationDetailsMasked = values.isOrganisationDetailsMaskedMt; 
        const taskDocIds = values.stepOneStDocumentsMt;

        const taskTypeFlag = values.taskTypeFlag;

        let taskDocIdsAr = []; let multiTaskFileId=0;
        if(taskDocIds.length>0){
            
            Object.keys(taskDocIds).map((e,i)=>{
                taskDocIdsAr.push(taskDocIds[e].id);
            });           
            multiTaskFileId =  taskDocIdsAr[0];
        } 
        
         // googleCityDetails related modifications for param to pass to save
         let googleCityDetails = [];
         if(locSelected.length>0){            
 
             Object.keys(locSelected).map((e,i)=>{
 
                 let googleCityDetailsObj = {
                     "cityName":null,
                     "state":null,
                     "country":null,
                     "latitude":null,
                     "longitude":null,
                     "placeId":null
                 };
                 let locObj = locSelected[e];
                 googleCityDetailsObj.cityName = locObj.city!==undefined?locObj.city:null;
                 googleCityDetailsObj.state = locObj.state!==undefined?locObj.state:null;
                 googleCityDetailsObj.country = locObj.country!==undefined?locObj.country:null;
                 googleCityDetailsObj.latitude = locObj.lat!==undefined?locObj.lat:null;
                 googleCityDetailsObj.longitude = locObj.lng!==undefined?locObj.lng:null;
                 googleCityDetailsObj.placeId = locObj.placeId!==undefined?locObj.placeId:null;
 
 
                 googleCityDetails.push(googleCityDetailsObj);
             });           
 
         }
 

        paramObj.categoryId = categoryId;
        paramObj.typeId = typeId;        
        paramObj.lawyerDiscoveryParams.typeOfProfessionalId = typeOfProfessionalId;
        paramObj.lawyerDiscoveryParams.practiceAreasIds = practiceAreasIds;
        paramObj.lawyerDiscoveryParams.placeIds = placeIds;
        paramObj.lawyerDiscoveryParams.workExperience = workExperience;
        paramObj.lawyerDiscoveryParams.isOrganisationDetailsMasked = isOrganisationDetailsMasked;
        paramObj.multiTaskFileId = multiTaskFileId;
        paramObj.offsetHour = offsetHr;
        paramObj.offsetMinute = offsetMin;
        paramObj.lawyerDiscoveryParams.googleCityDetails = googleCityDetails;

        let paramObjData = {
          "taskTypeFlag" : taskTypeFlag,
          "data": paramObj
        };

        let stcservice = new CreateTaskService();
        stcservice.createTask(paramObjData).then(data =>{
            if(data.status==200){
                let respData ={};
                if(data.data.entity!==undefined){
                   ////console.log("multi task respective resp received after task creation===>>",data.data.entity);
                   respData  = data.data.entity;
                  //  let multiTaskFileId = respData.multiTaskFileId!==undefined?respData.multiTaskFileId:"";
                   let otherParam = {};
                   this.props.setStatesForStep3ofTaskCreation("taskCreateResp",respData,otherParam);                   
                   if(respData.topUserDetails!==undefined){                     
                        if(respData.topUserDetails.length>0){                           

                            // proceed to next step of lawyer listing and send request
                            let nOtherParam = {"bypassToStepFlag":true,"bypassToStep":3};
                            this.props.nextStep(nOtherParam);                           
                        }else{
                              //********* for context starts *****
                              // updating data in context 
                              let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;  
                              // example
                              // if(taskTypeFlag==1){
                              //     setShare({actionType:"MULTITASK"});
                              // }
                              //********* for context ends *****
                            
                              let otherParamNlf1 = {};
                              this.noLawyerFoundModalOpen(otherParamNlf1);
                        }
                    }else{

                           //********* for context starts *****
                            // updating data in context 
                            let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;  
                            // example
                            if(taskTypeFlag==1){
                                setShare({actionType:"MULTITASK"});
                            }
                            //********* for context ends *****

                            let otherParamNlf2 = {};
                            this.noLawyerFoundModalOpen(otherParamNlf2);  
                    }
            }
        }
        }).catch((err)=>{
                let errStatusCodeAr = [400,500]; // allowed error status code
                if(err.response && err.response.data){
                    let erRespData = err.response.data;
                    if(errStatusCodeAr.indexOf(erRespData.status) !== -1){         
                        let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                        ////console.log(" errMsg on task creation==>",errMsg);

                        let notiMsg = errMsg;
                        notify(notiMsg,true,{
                        "successMsg":0,
                        "customWrapClass":"customerrlass",
                        "showCloseButton": false, 

                        "dismiss": {
                        "duration": 1500,
                        }

                        }); 
                                         
                }
                } 
        });
    }

    continue = e =>{
        e.preventDefault();
        //this.props.nextStep();
        let chkValidate = this.custValidate();
        ////console.log("=chkValidate=>",chkValidate);
        if(chkValidate){
            //this.props.nextStep();
            //alert("call create service");
            this.callCreateTaskService();
        }
    }

    previousStep = e =>{
        e.preventDefault();
        let pOtherParam = {};
        this.props.prevStep(pOtherParam);
    }

    custValidate =()=>{
        const {values} = this.props;
        //For error messages
        const typeOfProfessionalId = values.typeOfProfessionalIdMt;
        const practiceAreasIds = values.practiceAreasIdsMt;
        const categoryId = values.categoryIdMt;
        const typeId = values.typeIdMt; 
        const taskDocIds = values.stepOneStDocumentsMt;       
        
        const placeIds = values.placeIdsMt; 
        const workExperience = values.workExperienceMt;              
        const isOrganisationDetailsMasked = values.isOrganisationDetailsMaskedMt;        
        
        let typeOfProfessionalIdErrMsg = '';
        let practiceAreasIdsErrMsg = '';
        let categoryIdErrMsg = '';
        let typeIdErrMsg = '';
        
        let locSelectedErMsg = ''; //placeIds
        let workExperienceErrMsg = '';
        let isOrganisationDetailsMaskedErrMsg = '';
        let stepOneMtFileUplErMsg = "";

        let totErrCountTrack = [];       

        let regexBlankSpace = /^ *$/;   
        //////console.log("==values==>>",values);     
        
        if(typeOfProfessionalId.length==0||(regexBlankSpace.test(typeOfProfessionalId)==true)){
            typeOfProfessionalIdErrMsg = " Type of professional required.";
            totErrCountTrack.push(typeOfProfessionalIdErrMsg);
        }

        if(practiceAreasIds.length==0){
            practiceAreasIdsErrMsg = " Practice area required.";
            totErrCountTrack.push(practiceAreasIdsErrMsg);
        }

        if(categoryId.length==0||(regexBlankSpace.test(categoryId)==true)){
            categoryIdErrMsg = " Category area required.";
            totErrCountTrack.push(categoryIdErrMsg);
        }

        if(typeId.length==0||(regexBlankSpace.test(typeId)==true)){
            typeIdErrMsg = " Type is required.";
            totErrCountTrack.push(typeIdErrMsg);
        }

        if(taskDocIds.length==0){
          stepOneMtFileUplErMsg = " File not uploaded.";
            totErrCountTrack.push(stepOneMtFileUplErMsg);
        }
       
        // if(placeIds.length==0){
        //     locSelectedErMsg = " Location is required.";
        //     totErrCountTrack.push(locSelectedErMsg);
        // }
        
        // if(isOrganisationDetailsMasked==""||isOrganisationDetailsMasked==false){
        //     isOrganisationDetailsMaskedErrMsg = " isOrganisationDetailsMasked is required.";
        //     totErrCountTrack.push(isOrganisationDetailsMaskedErrMsg);
        // }

        // Work Experience Required temporarily hidden
        // if(workExperience.length==0){
        //     workExperienceErrMsg = " Work Experience is required.";
        //     totErrCountTrack.push(workExperienceErrMsg);
        // }
        
        this.isComponentLoaded && this.setState({
            "typeOfProfessionalIdErrMsg":typeOfProfessionalIdErrMsg,
            "practiceAreasIdsErrMsg":practiceAreasIdsErrMsg,
            "categoryIdErrMsg":categoryIdErrMsg,
            "typeIdErrMsg":typeIdErrMsg,           
            "locSelectedErMsg":locSelectedErMsg,
            "workExperienceErrMsg":workExperienceErrMsg,
            "isOrganisationDetailsMaskedErrMsg":isOrganisationDetailsMaskedErrMsg,
            "stepOneMtFileUplErMsg": stepOneMtFileUplErMsg
        });

        ////console.log(" totErrCountTrack=>",totErrCountTrack);
        if(totErrCountTrack.length>0){            
          return false;
        }     
      
        return true;
        }

    selectedGoogleDataInfo = (googpplcselectedinfo)=>{            
            let locSelected = [...this.state.locSelected];
            let locSelectedErMsg = "";
            let dupliLocAdded = [];
            if(googpplcselectedinfo.city!==undefined && googpplcselectedinfo.city){
                if(locSelected.length<3){
                    // get duplicate if any
                    Object.keys(locSelected).map((e, i) => {
                        if(locSelected[e].city == googpplcselectedinfo.placeName){
                            dupliLocAdded.push(googpplcselectedinfo.placeName);
                        }
                      });
                    
                    // if not duplicate then push
                    if(dupliLocAdded.length==0){
                        locSelected.push(googpplcselectedinfo);
                        this.setState({"locSelected":locSelected},()=>{

                            if(this.state.locSelected.length>3){
                                this.setState({"locSelectedErMsg":locSelectedErMsg,"locdisabledFlag":true});
                            }else{                                 
                                //push location data to parent
                                console.log(" googpplcselectedinfo==>",googpplcselectedinfo);
                                let otherParam = {"action":"push","locSelected":googpplcselectedinfo};
                                this.props.setStatesForStep1ofMT("placeIdsMt",googpplcselectedinfo,otherParam);              
                                
                                this.setLocaDisabled(this.state.locSelected);
                                
                            }
                            
                        });
                    }                   
                    
                }else{
                    locSelectedErMsg = "Max 3 location can be selected.";
                    this.setState({"locSelectedErMsg":locSelectedErMsg,"locdisabledFlag":true});
                }
                
            }

            

            

    }

    setLocaDisabled(locSelected){
        let locSelectedErMsg = '';
        if(locSelected.length==3){
            this.setState({"locSelectedErMsg":locSelectedErMsg,"locdisabledFlag":true});
        }else{
            this.setState({"locSelectedErMsg":locSelectedErMsg,"locdisabledFlag":false});
        }
    }

    removeSelLocation(i,locSelected){
        let valuesData = [...this.state.locSelected];        
        valuesData.splice(i,1);  
            
        ////console.log("11 locSelected==>",locSelected);

        //remove location data from parent
        let otherParam = {"action":"remove","locSelected":locSelected};
        this.props.setStatesForStep1ofMT("placeIdsMt",locSelected,otherParam); 
        
        this.isComponentLoaded && this.setState({ "locSelected":valuesData,"locdisabledFlag":false,"locSelectedErMsg":""});  
        
    }

    professionalOptionChangeTrack = (e) =>{
        let typeOfProfessionalIdValue = e.target.value;
        this.props.setStatesForStep1ofMT("typeOfProfessionalIdMt",typeOfProfessionalIdValue);
        // call service api to set practice areas
        let setPrevSel=false;
        this.getPracticeAreasFromService(typeOfProfessionalIdValue, setPrevSel);
    }

    categoryOptionChangeTrack = (e) =>{
        let categoryIdValue = e.target.value;
        this.props.setStatesForStep1ofMT("categoryIdMt",categoryIdValue);
        ////console.log("categoryIdValue=>",categoryIdValue);
        // call service api to set type ids based on category 
        let setPrevSel=false;
        this.getTypesIdsFromCategoryService(categoryIdValue, setPrevSel);
    }

    typeOfCategOptionChangeTrack = (e) =>{
        let typeIdValue = e.target.value;
        this.props.setStatesForStep1ofMT("typeIdMt",typeIdValue);        
    }
    
   

    workExperienceOptionChangeTrack = (e) =>{
        let workExperience = e.target.value;
        this.props.setStatesForStep2ofST("workExperience",workExperience);        
    }
   
    getPracticeAreasFromService(profId,setPrevSel){
       // reset all dependencies of professionalSelected
       let otherParam = {};                               
       this.resetAllDependencies("professionalSelected",otherParam); 

        if(profId!=''){
            let tpserv = new PracticeAreaOfProfessionService();        
            tpserv.getPracticeAreasOfProfession(profId).then(data =>{
            if(data.status==200){
                if(data.data.entity!==undefined){
                    this.isComponentLoaded && this.setState({"practiceAreaOptions":data.data.entity},()=>{
                              
                            if(setPrevSel==true){
    
                                const {values} = this.props;
                                const practiceAreasIds = values.practiceAreasIdsMt;
    
                                if(practiceAreasIds.length>0 && this.state.practiceAreaOptions.length>0){
    
                                    let prevSelPracticeAreasObjs=[];
                                    Object.keys(this.state.practiceAreaOptions).map((e,i)=>{
                                        if(practiceAreasIds.indexOf(this.state.practiceAreaOptions[e].id)!==-1){
                                            prevSelPracticeAreasObjs.push(this.state.practiceAreaOptions[e]);
                                        }
                                    });
                        
                                    if(prevSelPracticeAreasObjs.length>0){
                                        this.isComponentLoaded &&  this.setState({"practiceAreaSelected":prevSelPracticeAreasObjs});
                                    }
    
                                }
                            }else{                   
                                
                            }
                                
                            
    
                    });
                }           
            }
            }).catch((e)=>{
                ////console.log(" Something went wrong with api server=>",e);
            });
        }else{

        }
        
    }

    getCategFromPracticeAreaService(practicAreas,setPrevSel){
       
        // reset all practiceAreaSelected dependencies
        let otherParam = {};                               
        this.resetAllDependencies("practiceAreaSelected",otherParam);
        
        if(practicAreas!=''){
            let tpserv = new CategoriesBasedOnPracticeAreaService();        
            tpserv.getCategBasedOnPracticeArea(practicAreas).then(data =>{
            if(data.status==200){
                if(data.data.entity!==undefined){
                    this.isComponentLoaded && this.setState({"categoryOptions":data.data.entity},()=>{                           
    
                    });
                }           
            }
            }).catch((e)=>{
                ////console.log(" Something went wrong with api server=>",e);
            });
        }else{
                        
        }
        
    }

    getTypesIdsFromCategoryService(categId,setPrevSel){
        // reset all dependencies of categorySelected
        let otherParam = {};                               
        this.resetAllDependencies("categorySelected",otherParam);

        if(categId!=''){
            let tpserv = new TypeIdBasedOnCategoryService();        
            tpserv.getTypeIdsBasedOnCategory(categId).then(data =>{
            if(data.status==200){
                if(data.data.entity!==undefined){
                    this.isComponentLoaded && this.setState({"typeIdOptions":data.data.entity},()=>{                           
    
                    });
                }           
            }
            }).catch((e)=>{
                ////console.log(" Something went wrong with api server=>",e);
            });
        }else{            
                      
        }
        
    }

    async resetAllDependencies(keyname,otherParam){
        switch(keyname){
             case "professionalSelected":             
                this.isComponentLoaded &&  this.setState({"practiceAreaOptions":[],"practiceAreaSelected":[],
                "categoryOptions":[],"typeIdOptions":[]
                }, ()=>{
                    // reset multiselect plugin
                    this.multiselectprofareaRef.current.resetSelectedValues();
                });
                break;
            case "practiceAreaSelected":
                this.isComponentLoaded &&  this.setState({
                "categoryOptions":[],"typeIdOptions":[]
                });
                break;
            case "categorySelected":
                this.isComponentLoaded &&  this.setState({"typeIdOptions":[]});
                break; 
            case "typeIdSelected":
                ///this.isComponentLoaded &&  this.setState({"typeIdOptions":[]});
                break; 
              
        }
    }

    isOrganisationDetailsMaskedTrack = (e) =>{
        //////console.log("chk=>>",   e.target.value);
        const {values} = this.props;
        let isOrganisationDetailsMasked = (values.isOrganisationDetailsMaskedMt);
        if(isOrganisationDetailsMasked==""){
            isOrganisationDetailsMasked = false;
        }
        isOrganisationDetailsMasked = !(isOrganisationDetailsMasked);

        this.props.setStatesForStep1ofMT("isOrganisationDetailsMaskedMt",isOrganisationDetailsMasked);  
    }

    /*** No lawyer found related starts  */
    /**
     * TaskAddSingleTaskStep2
     * Function name : organisationDetailModalOpen
     * Author        :
     * Created Date  : 11-2-2020
     * Purpose       : To open organisationDetailModal
     * Params        : 
    **/  
   noLawyerFoundModalOpen = (otherParam)=>{    
    this.isComponentLoaded &&  this.setState({showNoLawyerFoundModal:true},()=>{
      this.nolawyermodref.current.showModal();
      this.getNoLawyerFoundContentModalOpen();
    });    
      
  }

  /**
     * TaskAddSingleTaskStep2
     * Function name : organisationDetailModalClose
     * Author        :
     * Created Date  : 11-2-2020
     * Purpose       : To close organisationDetailModal
     * Params        : 
    **/
   noLawyerFoundModalClose = ()=>{    
    this.isComponentLoaded && this.setState({showNoLawyerFoundModal:false},()=>{
      this.nolawyermodref.current.hideModal();
    });
    }

    /**
   * TaskAddSingleTaskStep2
   * Function name : getOrganisationDetailModalOpen
   * Author        :
   * Created Date  : 11-2-2020
   * Purpose       : Get modal view with data
   * Params        : 
  **/
    getNoLawyerFoundContentModalOpen() {   
        const {cancelButtonAction,values} = this.props;
        const taskTypeFlag = values.taskTypeFlag;

        let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null; 

        let viewData = <div className="dash_modal">
        
                <>
            <div className="modal-header">
                <h4 className="modal-title modal_title_tool">New Task Created
                {/*<div className="input_tooltip_bg">
                        <span className="input_tooltip" aria-label="Organisation's Details" data-balloon-pos="down">
                        <i className="fa fa-question-circle"></i>
                        </span>
                        </div>*/}
                </h4>
                <button type="button" onClick={
                    (e)=>{
                      e.preventDefault();
                      if(taskTypeFlag==1){
                         setShare({actionType:"MULTITASK"});
                       } 
                       
                       setTimeout(()=>{                     
                         cancelButtonAction();
                          
                       },1100);                 

                }} className="close modal_close" data-dismiss="modal">&times;</button>
            </div>
           
            <div className="modal-body padding_top">
              <div className="row">

                  Thank you for creating the task request. We will get in touch with you.
                  

              </div>
            </div>
            <div className="modal-footer">
               
                <button  type="button" className="btn btn-primary modal_btn_save" onClick={(e)=>{ e.preventDefault();
                   if(taskTypeFlag==1){
                      setShare({actionType:"MULTITASK"});
                    } 
                    
                    setTimeout(()=>{                     
                      cancelButtonAction();
                       
                    },1100); 

                } } data-dismiss="modal"><span>Continue</span></button>
                
            </div>
          
          
          </>
        
                        
        </div>;
    
        this.nolawyermodref.current.updateStateContent(viewData);
        this.nolawyermodref.current.showContent();
        return viewData;
    }    

    /*** No lawyer found  modal related ends  */

    /**
     * TaskAddSingleTaskStep2
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 27-02-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentDidMount() {
        this.isComponentLoaded =  true; 
        // scroll top
        //window.scrollTo(0, this.refouterdivstep1mt.current.offsetTop);   
        commonnotitrack("SCROLL_TO_MAIN_HEADER",{
          "notiType": "SCROLL_TO_MAIN_HEADER",
          "triggerCallback":true,
          "trigerredFrom":"TASK_CREATION_FLOW"
         });
            
        let tpserv = new TypeOfProfessionalsService();
        let paramsData = [];
        tpserv.getTypeOfProfessional(paramsData).then(data =>{
        if(data.status==200){
            if(data.data.entity!==undefined){
                this.isComponentLoaded && this.setState({"typeOfProfessionals":data.data.entity});
            }           
        }
        }).catch((e)=>{
            ////console.log(" Something went wrong with api server=>",e);
        });
        
        const {values} = this.props;
        const practiceAreasIds = values.practiceAreasIdsMt;
        const typeOfProfessionalId = values.typeOfProfessionalIdMt;
        const categoryId = values.categoryIdMt;
        const locSelected = values.locSelectedMt; 
        const workExperience = values.workExperienceMt; 
        const expData =  values.expDataMt;

        if(typeOfProfessionalId!=''){
            let setPrevSel=true;
            this.getPracticeAreasFromService(typeOfProfessionalId,setPrevSel);
        }

        if(practiceAreasIds.length>0){           
            let setPrevSel= false;
            let practiceAreasIdsStr = practiceAreasIds.join(",");
            this.getCategFromPracticeAreaService(practiceAreasIdsStr,setPrevSel);
        }

        if(categoryId!=''){           
            let setPrevSel= false;            
            this.getTypesIdsFromCategoryService(categoryId,setPrevSel);
        }

        if(locSelected.length>0){
            this.isComponentLoaded && this.setState({"locSelected":locSelected});
            this.setLocaDisabled(locSelected);
        }

        if(workExperience.length>0){
            let matchedExpObjAr = [];
            Object.keys(expData).map((e,i)=>{
                if(workExperience.indexOf(expData[e].key)!==-1){
                    matchedExpObjAr.push(expData[e]);
                }
            });

            if(matchedExpObjAr.length>0){
                this.setState({"expDataSelected":matchedExpObjAr});
                    
            }else{
                //reset if no selected found
                this.multiselectexpRef.current.resetSelectedValues();
            }

        }     
        
    }

    

    /**
     * TaskAddSingleTaskStep2
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 27-02-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {  
        this.isComponentLoaded =  false;
    }    

    

    /**
     * TaskAddSingleTaskStep1
     * Function name : render
     * Author        :
     * Created Date  : 27-2-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() {    
        const {values, cancelButtonAction} = this.props;

        const typeOfProfessionalId = values.typeOfProfessionalIdMt;
        const categoryId = values.categoryIdMt;
        const typeId = values.typeIdMt;        
        const expData =  values.expDataMt;        
        const isOrganisationDetailsMasked = values.isOrganisationDetailsMaskedMt;

        const taskTypeFlag = values.taskTypeFlag;
        // const subTaskFlag = values.subTaskFlag;       
        const docsData = values.stepOneStDocumentsMt;
        const multiTaskFlowD = values.multiTaskFlowD;
        
       return (
                <React.Fragment>  
                    <div ref={this.refouterdivstep1mt}>
                      {/* task header start */}
                      <div className="task_header task_header_withTopHeader">
                        Enter Task &amp; Professional Details
                        <span className="step_block">
                          <span>Step 01 / 02 </span>
                          <span className="progress">
                            <div className="progress-bar" style={{ width: "66%" }} />
                          </span>
                        </span>
                        {/*}
                        <span className="taskstep_back_bg">
                          <button>
                            <img className="back_img_icon" src="assets/images/Path-back.png" />
                            Bank
                          </button>
                        </span>
                        {*/}
                      </div>
                      {/* task header end */}
                      {/* task body area start */}
                      <div className="cutom_container cutom_container_withTopHeader">
                      
                        <div className="task_create_form_container task_big_formbox task_container_withbox new_autoHeightAdjust_taskCreate_bg">
                        { this.state.showNoLawyerFoundModal==false && ( <CustomMessageBar />) }
                          
                          <div className="createTask_newWrapping_singleMulti_bg">

                              <div className="split_mid_box split_mid_multibox">
                                <div className="task_form_box">
                                    <form>
                                          <div className="main_form_box">
                                  <div className="task_form_box custom_radio_field">
                                    <div className="form_block form_block_half margin_bottom_0">
                                      <div className="form__group field ">
                                        <div className="panel50 panel_half">
                                          <div className="custom-control custom-radio custom-control-inline">
                                            <input
                                            type="radio"
                                            className="custom-control-input"
                                            id="customRadio1"
                                            name="example1"
                                            checked={taskTypeFlag==0?true:false}
                                            onChange={()=>{
                                                let otherParam = {};
                                                this.props.changeTaskType(0,otherParam);
                                            }}
                                            />
                                            <label
                                              className="custom-control-label"
                                              htmlFor="customRadio1"
                                            >
                                              Create a single Task
                                            </label>
                                          </div>
                                        </div>
                                        <div className="panel50 panel_half">
                                          <div className="custom-control custom-radio custom-control-inline">
                                            <input
                                            type="radio"
                                            className="custom-control-input"
                                            id="customRadio2"
                                            name="example1"
                                            checked={taskTypeFlag==1?true:false}
                                            onChange={()=>{
                                                let otherParam = {};
                                                this.props.changeTaskType(1,otherParam);
                                            }}
                                            />

                                            <label
                                              className="custom-control-label"
                                              htmlFor="customRadio2"
                                            >
                                              Create multiple Task
                                            </label>
                                          </div>
                                        </div>
                                        <div className="clearboth" />
                                      </div>
                                    </div>
                                  </div>
                                  {/* === scroll task section start ===  */}
                                  <div className="scroll_task_bg">
                                    {/* === enter main task box start ===  */}
                                    <div className="scroll_maintask_bg">
                                      <div className="enter_task_box sub_task_addbg">
                                        {/* 
                                                  <div class="task_title_head">
                                                      <h3 class="taskbox_title">Task & Professional Details
                                                      </h3>
                                                  </div> 
                                                  */}
                                        <div className="main_form_box">
                                          <div className="form_block form_block_half">
                                            <div className="panel50 panel_half paddingright15 form__group_normar">
                                              <label htmlFor="name" className="form__label">
                                                <b>Type of Professional</b> &nbsp;
                                                <span className="top_sug_left">
                                                  <span
                                                    className="input_tooltip btn_tooltip"
                                                    aria-label="Type of Professional"
                                                    data-balloon-pos="down"
                                                  >
                                                    <i className="fa fa-question-circle" />
                                                  </span>
                                                </span>
                                              </label>
                                              
                                              <div className={`form__group field ${ this.state.typeOfProfessionalIdErrMsg?"form_group_error": ""}` }>
                                                  <select
                                                  
                                                  className="selectpicker dropup form__field"
                                                  data-header="Organisation Size"
                                                  value={typeOfProfessionalId} 
                                                  onChange={this.professionalOptionChangeTrack}
                                                  
                                                  >
                                                  <option  value="">Select</option>
                                                  {Object.keys(this.state.typeOfProfessionals).map((e,i)=> <option key={i}  value={this.state.typeOfProfessionals[e].id}>{this.state.typeOfProfessionals[e].value}</option>) }
                                                        
                                                  </select>                                            
                                                  <span className="error_span">{this.state.typeOfProfessionalIdErrMsg}</span>
                                            </div>
                                            
                                            
                                            </div>
                                            <div className="panel50 panel_half paddingleft15 form__group_normar">
                                              <label htmlFor="name" className="form__label">
                                                <b>Practice Areas (Default - Any)</b>
                                                &nbsp;
                                                <span className="top_sug_left">
                                                  <span
                                                    className="input_tooltip btn_tooltip"
                                                    aria-label="Practice Areas"
                                                    data-balloon-pos="down"
                                                  >
                                                    <i className="fa fa-question-circle" />
                                                  </span>
                                                </span>
                                              </label>
                                              <div className="form__group field">
                                                <div className={`multiselectouterdivcls ${ (this.state.practiceAreaOptions.length==0)|| (this.state.practiceAreaSelected.length==3)?"disabled disabled_without_dim":""} ${this.state.practiceAreasIdsErrMsg?"form_group_error":""}  `}>
                                                <Multiselect
                                                options={this.state.practiceAreaOptions} // Options to display in the dropdown
                                                selectedValues={this.state.practiceAreaSelected} // Preselected value to persist in dropdown
                                                
                                                displayValue="value" 

                                                selectionLimit="3"   
                                                
                                                onSelect={this.onSelectPracticeArea} 
                                                onRemove={this.onRemovePracticeArea} 
                                                placeholder="Select"
                                                ref={this.multiselectprofareaRef}
                                                />
                                                
                                            </div>
                                            <span className="error_span">{this.state.practiceAreasIdsErrMsg}</span>

                                        </div>
                                            
                                            
                                            
                                            </div>
                                            <div className="clearboth" />
                                          </div>
                                          <div className="form_block form_block_half">
                                            <div className="panel50 panel_half paddingright15 form__group_normar ">
                                              <label htmlFor="name" className="form__label">
                                                <b>Category of Task</b> &nbsp;
                                              </label>
                                              <div className={`form__group field ${ this.state.categoryIdErrMsg?"form_group_error": ""}` }>
                                                  <select
                                                  className="selectpicker dropup form__field"
                                                  data-header="Organisation Size"
                                                  disabled={this.state.categoryOptions.length>0?false:true}
                                                  value={categoryId} 
                                                  onChange={this.categoryOptionChangeTrack}

                                                  >
                                                  <option  value="">Select</option>
                                                  {Object.keys(this.state.categoryOptions).map((e,i)=> <option key={i}  value={this.state.categoryOptions[e].id}>{this.state.categoryOptions[e].category}</option>) }
                                                  </select>
                                                  <span className="error_span">{this.state.categoryIdErrMsg}</span>
                                              </div>
                                            
                                            
                                            </div>
                                            

                                        <div className={`panel50 panel_half paddingleft15 form__group_normar ${ this.state.typeIdErrMsg?"form_group_error": ""}
                                        ${this.state.typeIdOptions.length==0?"disabled":""}
                                        ` } >
                                        <label htmlFor="name" className="form__label">
                                            <b>Type of task</b> &nbsp;
                                        </label>
                                        <div className="form__group field">
                                            <select
                                                className="selectpicker dropup form__field"
                                                data-header="Organisation Size"
                                                disabled={this.state.typeIdOptions.length>0?false:true}
                                                value={typeId} 
                                                onChange={this.typeOfCategOptionChangeTrack}

                                                >
                                                <option  value="">Select</option>
                                                {Object.keys(this.state.typeIdOptions).map((e,i)=> <option key={i}  value={this.state.typeIdOptions[e].id}>{this.state.typeIdOptions[e].categoryType}</option>) }
                                                </select>
                                                <span className="error_span">{this.state.typeIdErrMsg}</span>
                                        </div>
                                        </div>
                                            
                                            
                                            <div className="clearboth" />
                                          </div>
                                          <div className="form_block form_block_half">                                            
                                            <div className="panel50 panel_half paddingright15 form__group_normar">
                                        <label htmlFor="name" className="form__label">
                                            <b>Location (Default - Any)</b> &nbsp;
                                            <span className="top_sug_left">
                                                <span
                                                    className="input_tooltip btn_tooltip"
                                                    aria-label="Maximum 3 locations can be selected."
                                                    data-balloon-pos="down"
                                                >
                                                    <i className="fa fa-question-circle" />
                                                </span>
                                                
                                            </span>
                                        </label>
                                        <div className={`form__group field rndselect ${this.state.locSelectedErMsg?"form_group_error":""}`}>
                                            
                                            <NewCustomGoogleAutoComplete ref={this.orgcitygaref} placeholder="Select max 3 inputs" idname="orgcityid" textboxcustclass="orgdetgoogautocomptxtbxcls form__field" selectedGoogleDataInfo={this.selectedGoogleDataInfo}
                                            disabledFlag={this.state.locdisabledFlag}
                                            removeTextFlag="YES"
                                            />
                                            <span className="error_span">{this.state.locSelectedErMsg}</span>
                                          {this.state.locSelected.length>0 && (
                                                    <div className="locstselouterdiv">
                                                    <ul className="custclsforlocselectedforst">{Object.keys(this.state.locSelected).map((e,i)=>(
                                                        <li key={i}>
                                                            <span className="locselsttxtname">{this.state.locSelected[e].city}</span>
                                                            <span className="locselstbtnname"><button onClick={this.removeSelLocation.bind(this,i,(this.state.locSelected[e]))}></button></span>
                                                        </li>))}
                                                    </ul>
                                                </div>

                                          )}  
                                            

                                        </div>
                                        </div>  
                                        {/* Work Experience Required temporarily hidden */}
                                            {/* <div className="panel50 panel_half paddingleft15  form__group_normar">
                                              <label htmlFor="name" className="form__label">
                                                <b>Work Experience Required (Years)</b>
                                                &nbsp;
                                                <span className="top_sug_left">
                                                  <span
                                                    className="input_tooltip btn_tooltip"
                                                    aria-label="Work Experience"
                                                    data-balloon-pos="down"
                                                  >
                                                    <i className="fa fa-question-circle" />
                                                  </span>
                                                </span>
                                              </label>
                                              <div className="form__group field">
                                                  <div className={`multiselectouterdivcls ${ (expData.length==0)|| (this.state.expDataSelected.length==3)?"disabled disabled_without_dim":""} ${this.state.workExperienceErrMsg?"form_group_error":""}`}>
                                                      <Multiselect
                                                      options={expData} // Options to display in the dropdown
                                                      selectedValues={this.state.expDataSelected} // Preselected value to persist in dropdown
                                                      placeholder="Any"
                                                      displayValue="value"
                                                      selectionLimit="3"                      
                                                      onSelect={this.onSelectExp} 
                                                      onRemove={this.onRemoveExp}
                                                      ref={this.multiselectexpRef}
                                                      />
                                                  </div>          
                                                  
                                                  <span className="error_span">{this.state.workExperienceErrMsg}</span>
                                              </div>


                                            </div> */}
                                            <div className="clearboth" />
                                          </div>
                                        </div>
                                        {/* === enter task box end ===  */}
                                      </div>
                                      <div className="taskform_adjustbox margin_top_20">
                                        <div className="form_block">
                                          <div className="panel100">
                                            <div className="form__group field">
                                              <div className="formgroup_checkbox">
                                              <input onChange={this.isOrganisationDetailsMaskedTrack} type="checkbox" checked={isOrganisationDetailsMasked} id="html" />
                                                <label htmlFor="html">
                                                  <p>
                                                  Keep my organisation identity confidential until I offer the task to the professional.
                                                  </p>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="form_block form_block_half margin_bottom_0">
                                        <div className="task_updown_btnbg">
                                          <div className="updown_btnbg">
                                            
                                          <Link className="btn_appearance_none updown_btn down_temp_btn" to="download/Template_Multi_Task_CC_Flow.xlsx" target="_blank" download>Download Multi-Tasks Template</Link>
                                            
                                            <span className="top_sug_left">
                                              <span
                                                className="input_tooltip btn_tooltip"
                                                aria-label="Multi-Task Template"
                                                data-balloon-pos="down"
                                              >
                                                <i className="fa fa-question-circle" />
                                              </span>
                                            </span>
                                          </div>
                                          <div className="updown_btnbg">
                                            <div className="upload-btn-wrapper">
                                                <button className="btn">Upload Template</button>
                                                <input ref={this.fileUpl} multiple onChange={this.trackFileSelection} type="file" name="files" />
                                            </div>
                                            <span className="top_sug_left">
                                                <span className="input_tooltip btn_tooltip" aria-label="Upload Template" data-balloon-pos="down">
                                                    <i className="fa fa-question-circle"></i>
                                                </span>
                                            </span>
                                        </div>
                                          
                                        </div>
                                        <div className="file_list_sec">
                                          {this.state.uploadingFlag && (
                                          <div>Uploading...</div>
                                          )} 
                                          <ul>
                                          {Object.keys(docsData).length > 0 &&
                                              Object.keys(docsData).map((k, i) => (                                                  <li  key={i}>
                                                  <div className="file_list_box">
                                                    <span className="xlfile_name">
                                                    {docsData[k].name}
                                                    </span>
                                                    <span className="xlfile_cross">
                                                      <button onClick={(e)=>{
                                                        e.preventDefault();
                                                        this.removeDocOnClick(i)}}  />
                                                    </span>
                                                  </div>
                                                  </li>

                                              ))
                                          }


                                            
                                          </ul>
                                        </div>
                                        <div className="padding-top-10">
                                          <span className="error_span ">
                                            {/* Error! Invalid input in{" "}
                                            <b>“Task Name” row 12, “Budget” row 14</b> */}
                                            {this.state.stepOneMtFileUplErMsg}
                                          </span>
                                        </div>
                                        <div className="clearboth" />
                                      </div>
                                    </div>
                                    {/* === enter main task box end ===  */}
                                    {/* === enter sub task box start ===  */}
                                    {/* === enter sub task box end ===  */}
                                  </div>
                                  {/* === scroll task section end ===  */}
                                </div>
                                    </form>
                              </div>
                              </div>
                          </div>
                          
                        </div>
                        {/* submit area button area strat */}
                        <div className="task_details_btn_area bottomStickFooter_taskCreate">
                          <div className="btn_fld">
                            <div className="panel50">
                            {/* <button onClick={(e)=>{
                                    e.preventDefault();
                                    this.saveDraft();
                                    }} disabled={multiTaskFlowD==false?true:false} className="btn_appearance_none saveDraftBtn">
                                    Save as Draft
                                    </button> */}
                            </div>
                            <div className="panel50">
                              <div className="cancel_continue_btn">
                                <a href=""  onClick={(e)=>{
                            e.preventDefault();
                            cancelButtonAction();
                        }} className="btn_txt">
                                  Cancel
                                </a>
                                <button disabled={this.state.continueDisabled} onClick={this.continue} className="sub_grd_btn" >
                                  <span>Continue</span>
                                </button>
                              </div>
                            </div>
                            <div className="clearboth" />
                          </div>
                        </div>
                        {/* submit area button area end */}
                      </div>
                      {/* task body area end */}
                    </div>


                    {/* No lawyer found for task creation ST modal start */}
                    <CustReactBootstrapModal ref={this.nolawyermodref}  backdrop={false} backdropClassName="custom_modal_class"   animation={false} show={this.state.showNoLawyerFoundModal} onShow={()=>{}} onHide={()=>{}} modalcustdialog="custom_modal_lg"  loaderbodytitle="Organisation"  />
                    {/* No lawyer found for task creation ST modal end */}

                </React.Fragment>            
        );
        }
    }
  export default TaskAddMultiTaskStep1;