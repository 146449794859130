/**
 * VirtualBankAccount
 * Page/Class name   : VirtualBankAccount
 * Author            :
 * Created Date      : 18-6-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : rendering dashboard
**/
import React, { Component, Suspense, lazy} from 'react';
import '../../../custom_resources/css/custom.css';

import { withRouter } from "react-router-dom";


// for context
import CommonContext from '../../context/CommonContext';

// Services
import VirtualAccountBalanceService from '../../service/VirtualAccountBalanceService';


//import  CustDate  from '../../../common_utilities/CustDate';
import { commonJsFuncModule as commonJsObj } from '../../../common_utilities/commonjsfunc';

import {notify} from '../../messagebar/CustomMessageBar';
import CustomMessageBar from '../../messagebar/CustomMessageBar';
import VbaCommonPart from './VbaCommonPart';


const getCcUserId = commonJsObj.getCcUserId;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getBaseUrl = commonJsObj.getBaseUrl;

var stateVar = {
    //vba
    "vbaInfo":null,
    "accountBalance":0,
    "currency":"",
    "lastFetchedFromTheBankAt":null,
    "virtualAccountNumber":"",
    "ifsc":"",
    "benificieryName":"",
    "qrCodeImageURL":null,
    "qrCodeImagePath":null,
    "qrCodeUPIAppOpener":null,

    

    // reload process flag
    "reLoadFlag":false
  };

class VirtualBankAccount extends Component {

    isComponentLoaded = false;
    /**
     * VirtualBankAccount
     * Function name : constructor
     * Author        :
     * Created Date  : 18-6-2020
     * Purpose       : initializing state
     * Params        : props
    **/
    constructor(props) {
        super(props);
        this.state = stateVar
    }

    /**
     * VirtualBankAccount
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 18-6-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/

   

    componentDidMount() {
       this.isComponentLoaded = true;        

       this.callUserVirtualBankBalanceService({});
        

    }

    

    /**
     * VirtualBankAccount
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 18-6-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {  
        this.isComponentLoaded = false;
        
    }

    reloadTrigger = () =>{

        let stateVarMod = stateVar;
        stateVarMod.reLoadFlag  = true;

        console.log(" stateVarMod=>>",stateVarMod);

        this.isComponentLoaded && this.setState(stateVarMod,()=>{
              this.callUserVirtualBankBalanceService({"trigFrom":"reLoad"});

        });
    }

    callUserVirtualBankBalanceService = (paramObj) =>{
        let passParam = {
                        };
        let trigFrom = paramObj.trigFrom!==undefined?paramObj.trigFrom:"";
  
        let stcservice = new VirtualAccountBalanceService();
        stcservice.getVirtualBankBalanceInfo({"data":passParam}).then(data =>{
              if(data.status==200){
                  let respData ={};
                  if(data.data.entity!==undefined){
                    respData = data.data.entity; 
                    //console.log(" respData=>>",respData);
                    this.setUserVirtualBankBalanceStateData(respData,{"trigFrom":trigFrom});
                    
                    
              }
          }
          }).catch((err)=>{
                  
                  let errStatusCodeAr = [400,500]; // allowed error status code
                  if(err.response && err.response.data){
                      let erRespData = err.response.data;
                      if(errStatusCodeAr.indexOf(erRespData.status) !== -1){         
                          let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                          console.log(errMsg);
       
                                           
                  }
                  } 
          }).finally(()=>{
  
                setTimeout(()=>{
                    this.isComponentLoaded && this.setState({"reLoadFlag":false});
                },1500);
                  
                       
              
          });
      }

    setUserVirtualBankBalanceStateData = (vbBalInfo,otherParam) => {

        if(vbBalInfo!==undefined && vbBalInfo!==null){
  
            let accountBalance = (vbBalInfo.accountBalance!==undefined && vbBalInfo.accountBalance!==null)?vbBalInfo.accountBalance:0;
    
            let currency = (vbBalInfo.currency!==undefined && vbBalInfo.currency!==null)?vbBalInfo.currency:"";
    
            let lastFetchedFromTheBankAt = (vbBalInfo.lastFetchedFromTheBankAt!==undefined && vbBalInfo.lastFetchedFromTheBankAt!==null)?vbBalInfo.lastFetchedFromTheBankAt:null;
    
            let virtualAccountNumber = (vbBalInfo.virtualAccountNumber!==undefined && vbBalInfo.virtualAccountNumber!==null)?vbBalInfo.virtualAccountNumber:0;
    
            let ifsc = (vbBalInfo.ifsc!==undefined && vbBalInfo.ifsc!==null)?vbBalInfo.ifsc:"";
            if(otherParam.trigFrom!==undefined && otherParam.trigFrom=="reLoad"){
                ifsc = "OM";
            }
    
            let benificieryName = (vbBalInfo.benificieryName!==undefined && vbBalInfo.benificieryName!==null)?vbBalInfo.benificieryName:"";
    
            let qrCodeImageURL = (vbBalInfo.qrCodeImageURL!==undefined && vbBalInfo.qrCodeImageURL!==null)?vbBalInfo.qrCodeImageURL:"";
    
            let qrCodeImagePath = (vbBalInfo.qrCodeImagePath!==undefined && vbBalInfo.qrCodeImagePath!==null)?vbBalInfo.qrCodeImagePath:null;
    
            let qrCodeUPIAppOpener = (vbBalInfo.qrCodeUPIAppOpener!==undefined && vbBalInfo.qrCodeUPIAppOpener!==null)?vbBalInfo.qrCodeUPIAppOpener:null;
    
            let vbaBalStateData = { 
            "vbaInfo":vbBalInfo,
            "accountBalance":accountBalance,
            "currency":currency,
            "lastFetchedFromTheBankAt":lastFetchedFromTheBankAt,
            "virtualAccountNumber":virtualAccountNumber,
            "ifsc":ifsc,
            "benificieryName":benificieryName,
            "qrCodeImageURL":qrCodeImageURL,
            "qrCodeImagePath":qrCodeImagePath,
            "qrCodeUPIAppOpener":qrCodeUPIAppOpener,
            };
            this.isComponentLoaded && this.setState(vbaBalStateData);
        }
        
      }
    /**
     * VirtualBankAccount
     * Function name : render
     * Author        :
     * Created Date  : 11-2-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() {    
       
        let vbaInfo = this.state.vbaInfo;
        let datasObj = {
            "vbaInfo":vbaInfo,
            "invoiceInfo": {},
            "sharedData": {},
            "injectedIn":"VirtualBankAccount"
          };  
       return (
            <React.Fragment>  
                <div className="dashboard_cont_area tasklist_cont_areabg setting_cont_areabg">
                    {/* ===  DashBoard Main Body Start ===  */}
                     <div className="cutom_container cutom_container_userSetting">
                    <div className="tab-content">
                    <div id className="tab-pane fade in active">
                        <div className="create_task_area userSetting_area">
                        {/* ===  task Listing filter start === */}
                        <div className="task_area_filterbg task_area_filterbg_lp">
                            <div className="filter_area ">
                            {/* ===  task top filter start ===  */}
                            <div className="top_filter_left top_filter_left_lp">
                                <div className="top_filter_backbtn_bg top_filter_backbtn_bg_lp">
                                {/* == profile setting ul start ==   */}
                                
                                {/* == application setting ul end ==   */}
                                {/* == profile setting ul start ==   */}
                                <div className="profile_setting_ulbg vrAcc_set_ulbg">
                                    <ul>
                                    <li>
                                        <button disabled={this.state.reLoadFlag} onClick={(evn)=>{
                                            evn.preventDefault();
                                            this.isComponentLoaded && this.setState({"reLoadFlag":true},()=>{
                                                        setTimeout(()=>{
                                                            this.reloadTrigger();
                                                        },1750);
                                            })
                                           
                                        }} className="btn_appearance_none vrAcc_reload_btn">
                                        <span className="task_ass_span">
                                            <img src="assets/images/reload-icon-01.png" alt />
                                            Reload
                                        </span>
                                        </button>
                                    </li>
                                    <li>
                                        <div className="vrAcc_blanace_block">
                                        <span className="vrAcc_text">
                                            <img
                                            src="assets/images/business-and-finance.png"
                                            alt
                                            />
                                            Virtual bank account balance
                                        </span>
       <span className="vrAcc_ammount">&nbsp;{this.state.currency}&nbsp;{this.state.accountBalance}</span>
                                        </div>
                                    </li>
                                    </ul>
                                </div>
                                {/* == profile setting ul end ==   */}
                                <div className="clearboth" />
                                </div>
                            </div>
                            {/* ===  task top filter end === */}
                            <div className="clearboth" />
                            </div>
                        </div>
                        {/* ===  task Listing filter end === */}
                        {/* ===========  Setting Page main body start =============   */}
                        {/* ===  task Listing start start === */}
                        <div className="task_area userSetting_task_area">
                            {/* === User Setting Section / Task Listing Section Start === */}
                            {/* ============== Start =================   */}
                            <div className="userSetting_section">
                            {/* -----  scroll adjust for settting screens start -----   */}
                            <div className="task_listing_scrollbg task_listing_scrollbg_proSetting">
                                {/* ==============   User Setting Section Start =================   */}
                                <div className="userSetting_area_bg">
                                {/* =====  Virtual account page body start =====  */}
                                <div className="userSetting_area vAcc_page_area">
                                    <div className="userSetting_scroll">
                                    {/* ====  usser acc scroll start ===  */}
                                    <div className="react_use_set_scroll vAcc_scroll mCustomScrollbar height_550">
                                        <div className="vAcc_whitebox">
                                        <div className="vAcc_header">
                                            <div className="vAcc_header_block">
                                            <div className="vacc_hd_left">
                                                <h5>Add money to your virtual bank account</h5>
                                            </div>
                                            <div className="vacc_hd_right">
                                                {/* <button className="btn_appearance_none greenText_btn">
                                                All transactions
                                                </button> */}
                                            </div>
                                            <div className="clearboth" />
                                            </div>
                                      
                                        </div>
                                        <div className="vAcc_body">
                                            {/* ===  V Acc yellow body start ===  */}
                                            {/* <div className="pay_det_sec pay_det_color">
                                            <div className="pay_det_sec_p">
                                                <p>
                                                You can add money to your virtual bank account
                                                using UPI, NEFT, RTGS and IMPS or use share
                                                details below to ask accounts team to add
                                                money to virtual bank account
                                                </p>
                                            </div>
                                            <div className="qr_block_bg">
                                                <div className="qr_block_left">
                                                <div className="qr_block_row">
                                                    <div className="qr_l">Account Number</div>
                                                    <div className="qr_r">
                                                    PAY0076752 * * * *
                                                    </div>
                                                    <div className="clearboth" />
                                                </div>
                                                <div className="qr_block_row">
                                                    <div className="qr_l">IFSC</div>
                                                    <div className="qr_r">
                                                    YESBOCM00X * * * * *
                                                    </div>
                                                    <div className="clearboth" />
                                                </div>
                                                <div className="qr_block_row">
                                                    <div className="qr_l">Benificiary Name</div>
                                                    <div className="qr_r">
                                                    Yashwanth 1 1 1 1 1 1 1 1 1 1 1 1 1 1 K
                                                    </div>
                                                    <div className="clearboth" />
                                                </div>
                                                </div>
                                                <div className="qr_block_right">
                                                <a href="javascript:void(0),">
                                                    <img
                                                    className="qr_img"
                                                    src="assets/images/qr-01.png"
                                                    alt
                                                    />
                                                </a>
                                                </div>
                                                <div className="clearboth" />
                                            </div>
                                            <div className="qr_block_btn">
                                                <button className="sub_grd_btn">
                                                <span>Share Details</span>
                                                </button>
                                            </div>
                                            <div className="pay_det_sec_p">
                                                <p>
                                                Some of the payment modes may take a while to
                                                be processed and hence there could be a delay
                                                to reflect the amount in your virtual bank
                                                account. You will receive a notification once
                                                the virtual bank account balance is updated
                                                </p>
                                            </div>
                                            </div>
                                             */}

                                            <VbaCommonPart datas={datasObj} />

                                            {/* ===  V Acc yellow body end ===  */}
                                            {/* ===  V Acc table body start ===  */}
                                            {/* ===  V Acc table body end ===  */}
                                        </div>
                                        </div>
                                    </div>
                                    {/* ====  usser acc scroll end ===  */}
                                    </div>
                                </div>
                                {/* =====  Virtual account page page body end =====  */}
                                </div>
                                {/* ==============   User Setting Section end =================   */}
                            </div>
                            {/* -----  scroll adjust for settting screens end -----   */}
                            </div>
                            {/* ============== end =================   */}
                            {/* === User Setting Section / Task Listing Section end === */}
                        </div>
                        {/* ===  task Listing start end === */}
                        {/* ===========  Setting Page main body end =============   */}
                        </div>
                    </div>
                    </div>
                </div>
                    {/* ===  DashBoard Main Body End ===  */}
                </div>
                {/* <div className="task_details_btn_area invoPay_footer">
                    <div className="btn_fld" style={{ display: "none" }}>
                        <div className="panel50">
                        <button className="btn_appearance_none green_btn">
                            <span>Save as draft</span>
                        </button>
                        </div>
                        <div className="panel50">
                        <div className="cancel_continue_btn">
                            <button
                            type="button"
                            data-dismiss="modal"
                            className="btn_appearance_none modal_text_btn"
                            >
                            <span>Cancel</span>
                            </button>
                            <button className="btn_appearance_none sub_grd_btn disabled_section">
                            <span>Submit</span>
                            </button>
                        </div>
                        </div>
                        <div className="clearboth" />
                    </div>
                </div> */}

            
            </React.Fragment>
            
        );
    }



  }
  export default VirtualBankAccount;