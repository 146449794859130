/**
 * TaskListForSingleTaskService
 * Page/Class name   : TaskPreAssingmentDocService
 * Author            :
 * Created Date      : 14-05-2020
 * Functionality     : getTaskPreAssignmentDoc
 * Purpose           : api service page
**/
import React, { Component } from 'react'
//import axios from 'axios';
import {axiosCustomObj} from '../customisedthirdparty/AxiosCustom';

import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;
const getMaxPageSize = commonJsObj.getMaxPageSize;
export class TaskPreAssingmentDocService extends Component {
    /**
     * TaskPreAssingmentDocService
     * Function name : constructor
     * Author        :
     * Created Date  : 14-05-2020
     * Purpose       : initializing state
     * Params        :
    **/
    constructor() {
        super();
        this.state = {

        }

        //this.axiosObj = axios;
       this.axiosObj = null;

        this.apiEndPoint = commonJsObj.getEndPoint()+'rest/tasks/documents/task';
    }

    /**
     * TaskPreAssingmentDocService
     * Function name : getTaskPreAssignmentDoc
     * Author        :
     * Created Date  : 14-05-2020
     * Purpose       : fetch pre assingment doc
     * Params        : param
    **/

    async getTaskPreAssignmentDoc(param){
        this.axiosObj = axiosCustomObj({});
         console.log(param.taskId,param.pageNum,param.pageSize)
        let accToken = getTokenFromLocStorage();
        let offsetOfLocalDateTime = getOffsetOfLocalDateTime(new Date());

        try{
                let offsetHr = offsetOfLocalDateTime.hr;
                let offsetMin = offsetOfLocalDateTime.min;
                let taskId = param.taskId;
                let pageNumber = param.pageNum;
                let urldata =this.apiEndPoint;
                const defaultPageSize = getMaxPageSize();
                let pageSize = defaultPageSize;
                let filter = param.filter?param.filter:"";
                urldata = urldata+"/"+taskId+"?"+"page_number="+pageNumber+"&page_size="+pageSize+"&offset_hour="+offsetHr+"&offset_minute="+offsetMin+"&only_pre_assignment=true";


                if(filter){
                    urldata = urldata+"&filter="+filter;
                }
                let headerOptn = {
                    "Content-Type": "application/json",
                    "Authorization": accToken
                };

                let respObj = this.axiosObj.get(urldata,{
                headers: headerOptn
                });

                return respObj;
        }
        catch(err){
            console.log("Some error occured===>",err); // TypeError: failed to fetch
        }
    }
}

export default TaskPreAssingmentDocService
