import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import {BrowserRouter as Router, Switch, Route, withRouter} from 'react-router-dom';
import { createBrowserHistory } from "history";


import {CommonProvider} from './components/context/CommonContext';
import { commonJsFuncModule as commonJsObj } from './common_utilities/commonjsfunc';

import loadCustRoutes from './custom_routes/CustomRoutes';
const browserHistory = createBrowserHistory();  
let routes = loadCustRoutes();
const shareParamData={};
const blockConsoleLog = commonJsObj.blockConsoleLog;

// hide console.log message display
blockConsoleLog({"showFlag":0});



// ReactDOM.render(<App />, document.getElementById('root'));
ReactDOM.render(
    <CommonProvider value={shareParamData}>
        <Router history={browserHistory} path="/" component={App}>
            <Switch>
            {routes}
            </Switch>
        </Router>
    </CommonProvider>
    
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
