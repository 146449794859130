/**
 * TaskAddSingleTaskStep1
 * Page/Class name   : TaskAddSingleTaskStep1
 * Author            :
 * Created Date      : 1-11-2019
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : rendering loader structure
**/
import React, { Component, Suspense, lazy} from 'react';
import '../../../custom_resources/css/custom.css';

// import DateRangePickerClass from '../../customisedthirdparty/DateRangePickerClass';
import { commonJsFuncModule as commonJsObj } from '../../../common_utilities/commonjsfunc';
import {commonStaticOptionsJsFuncModule as commonStaticJsObj} from  '../../../common_utilities/commonstaticoptions';

import FileUploadService from '../../service/FileUploadService';
import DraftTaskService from '../../service/DraftTaskService';

import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";

// for context
import CommonContext from '../../context/CommonContext';
import  CustDate  from '../../../common_utilities/CustDate';
import {commonnotitrack} from "./NotiEmitTrack";


//const getCustomLocalUtcDate = commonJsObj.getCustomLocalUtcDate;
//const getUtcTimestampFromDateObj = commonJsObj.getUtcTimestampFromDateObj;

const getDateObjFromMilliseconds = commonJsObj.getDateObjFromMilliseconds;
const getTimestampInMillisecondsFromDateObj = commonJsObj.getTimestampInMillisecondsFromDateObj;
const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;
const getCommonAllowedFileExt = commonStaticJsObj.getCommonAllowedFileExt;
const getDateFormatForCal = commonJsObj.getDateFormatForCal;


const ExampleCustomInput = ({ value, onClick }) => (    
    <input
    type="text"
    className="form__field customdatefld"
    placeholder="Task Due Date (Optional)"
    value={value}
    onClick={onClick}
    />
  );

class TaskAddSingleTaskStep1 extends Component {
    // for context
    static contextType = CommonContext;  
    
    isComponentLoaded = false;
    /**
     * TaskAddSingleTaskStep1
     * Function name : constructor
     * Author        :
     * Created Date  : 27-02-2020
     * Purpose       : initializing state
     * Params        : props
    **/
    constructor(props) {
        super(props);
        this.state = { 
            stepOneStTaskNameErMsg: '',
            stepOneStTaskDescErMsg: '',
            stepOneStTaskDueDateErMsg: '',
            stepOneStFileUplErMsg: '',            
            uploadingFlag: false

        }

        this.fileUpl = React.createRef();
        this.refouterdivstep1st = React.createRef();
        
    }

    continue = e =>{
        e.preventDefault();
        let chkValidate = this.custValidate();
        ////console.log.log("=chkValidate==>",chkValidate);
        if(chkValidate){
            let nOtherParam = {};
            this.props.nextStep(nOtherParam);
        }
        
    }

    trackFileSelection = e =>{
        e.preventDefault();
        let fileObj = e.target.files;    
        
        let chkFileValidation =  this.validateFileSelected(fileObj);
        ////console.log.log("==chkFileValidation=>>",chkFileValidation);
        //this.calUploadFileService(fileObj); 
        if(chkFileValidation){
            this.calUploadFileService(fileObj);
        }else{
            this.fileUpl.current.value = "";
        }    
    }

    validateFileSelected(fileObj){
        const maxFileUploadAllowed = 5;
        const totAllowedMaxFileUploadSize = 500 * 1024 * 1024; // 500 MB
        const allowedFileExt = getCommonAllowedFileExt();
        
        const {values} = this.props;        
        const docsData = values.stepOneStDocuments;
        let respdataFlag =  true;
        let stepOneStFileUplErMsg = "";
        if(docsData!==undefined){
            if(docsData.length==maxFileUploadAllowed){
                //error
                respdataFlag = false;
                stepOneStFileUplErMsg = "Sorry you cannot upload more than "+maxFileUploadAllowed+"  files in whole.";
                
            }else{
                if(docsData.length < maxFileUploadAllowed){
                    let nowtotAllowedCntOfFileUpl = maxFileUploadAllowed - docsData.length;
                    if(fileObj.length <= nowtotAllowedCntOfFileUpl){
                        // check file validations like size and extension
                        let totPrevSize  = 0; 
                        Object.keys(docsData).map((k,i)=>{
                            totPrevSize = totPrevSize + docsData[k].size;                 
                        });
                        ////console.log.log("Total attempted size=>",totSize);

                        let totCurSize  = 0; let totalInvalidExtAr = [];
                        Object.keys(fileObj).map((k,i)=>{
                            totCurSize = totCurSize + fileObj[k].size; 
                            let nameFl = fileObj[k].name;
                            let extnReceived = nameFl.split('.').pop();
                            if(allowedFileExt.indexOf(extnReceived)==-1){
                                totalInvalidExtAr.push(extnReceived);
                            }  
                        });

                        ////console.log.log("chk size=>> totPrevSize=>"+totPrevSize+" totCurSize=>"+totCurSize, "Totl size now=>>"+(totCurSize + totPrevSize), " totAllowedMaxFileUploadSize=>>>"+totAllowedMaxFileUploadSize );
                        if( (totCurSize + totPrevSize) > totAllowedMaxFileUploadSize){
                            //error
                            respdataFlag = false;
                            stepOneStFileUplErMsg = "You have crossed total maximum upload file size.";                            
                        }

                        
                        if(totalInvalidExtAr.length>0){
                            //console.log.log("totalInvalidExtAr=>",totalInvalidExtAr);
                            //error
                            respdataFlag = false;
                            stepOneStFileUplErMsg = "These file type(s) "+totalInvalidExtAr.toString()+" are not allowed.";

                        }
                        

                    }else{
                        //error
                        respdataFlag = false;
                        stepOneStFileUplErMsg = "You cannot upload more than "+maxFileUploadAllowed+" files in total (It includes previously uploaded files if any).";
                        
                    }
    
                }
            }
        }else{
            respdataFlag = false;
            stepOneStFileUplErMsg = "Something went wrong. Please try again.";
        }   

        this.isComponentLoaded && this.setState({ "stepOneStFileUplErMsg": stepOneStFileUplErMsg});
        return respdataFlag;
    }

    async calUploadFileService(fileObj){
        ////console.log.log("chk file fileObj=>>>",fileObj);
        let lserv = new FileUploadService();
        this.isComponentLoaded && this.setState({"uploadingFlag":true});

        const {values} = this.props;
        let taskTypeFlag = values.taskTypeFlag;

        let paramObjData = {
            "taskTypeFlag" : taskTypeFlag,
            "data": fileObj,
            "fileUploadedFromPageSection":"SINGLETASKCREATE",
            "fileUploadFor": "TASKDOCUMENT"
       };

        lserv.uploadFiletoServer(paramObjData).then(data =>{
            //this.setStepOneStDocuments();
            //console.log.log("success resp=>",data);
            let entityObj  = data.data.entity;       

            let otherParam = {"action":"push"};
            this.props.setStatesForStep1ofST("setStepOneStDocuments",entityObj,otherParam);


        }).catch((e)=>{
                //console.log.log("server error=>",e);
        }).finally(()=>{
            this.fileUpl.current.value = "";  
            this.isComponentLoaded && this.setState({"uploadingFlag":false});
        });
    }

    saveDraft(){
        let offsetOfLocalDateTime = getOffsetOfLocalDateTime(new Date());
        let offsetHr = offsetOfLocalDateTime.hr;
        let offsetMin = offsetOfLocalDateTime.min;

        const {values,valuesSecP,valuesSubT, cancelButtonAction} = this.props;     
        const taskTypeFlag = values.taskTypeFlag;            
        const subTaskFlag = values.subTaskFlag;   

        let paramObj= {
            "taskName":"",
            "taskDescription":"",
            "dueDate": null,
            "categoryId":"",
            "typeId":"",
            "budget":"",
            "budgetCurrency":"",
            "taskDocIds":[],
            "lawyerDiscoveryParams":
                {
                    "typeOfProfessionalId":"",
                    "practiceAreasIds":[],
                    "placeIds":[],
                    "workExperience":[],
                    "isOrganisationDetailsMasked":false,
                    "googleCityDetails":[]
                },
            "offsetHour":null,
            "offsetMinute":null
            };
    
            
            const draftId = values.draftId;        
            const taskName = values.stepOneStTaskName;
            const taskDescription = values.stepOneStTaskDesc;
            const dueDate = values.stepOneStTaskDueDate;
            const taskDocIds = values.stepOneStDocuments;
            const offsetHour = values.stepOneOffsetHour?values.stepOneOffsetHour :offsetHr;
            const offsetMinute = values.stepOneOffsetMinute?values.stepOneOffsetMinute:offsetMin;  


           
            

            let taskDocIdsAr = [];
            if(taskDocIds.length>0){
                
                Object.keys(taskDocIds).map((e,i)=>{
                    taskDocIdsAr.push(taskDocIds[e].id);
                });
            }

            
            
            let regexBlankSpace = /^ *$/;       
      
            if(!(taskName)||(regexBlankSpace.test(taskName)==true) ){
                //console.log.log("task name cannot be blank");
                return false;
            }

            paramObj.taskName = taskName;
            paramObj.taskDescription = taskDescription;
            paramObj.dueDate = dueDate;
            paramObj.offsetHour = offsetHour;
            paramObj.offsetMinute = offsetMinute;
            paramObj.taskDocIds = taskDocIdsAr;
            //console.log("1111111===from single task page1 taskTypeFlag==>>",taskTypeFlag," subTaskFlag==>>",subTaskFlag,"values==>",values)
            if(taskTypeFlag==0){
                if(subTaskFlag==0){
                    //without subtask
                   
                     //if this also exist 
                    const typeOfProfessionalId = parseInt(valuesSecP.typeOfProfessionalId);
                    const practiceAreasIds = valuesSecP.practiceAreasIds;
                    const categoryId = parseInt(valuesSecP.categoryId);
                    const typeId = parseInt(valuesSecP.typeId);        
                    const budgetCurrency =  valuesSecP.budgetCurrency;
                    const budget =  valuesSecP.budget;
                    const placeIds = valuesSecP.placeIds; 
                    const locSelected = valuesSecP.locSelected;
                    const workExperience = valuesSecP.workExperience;              
                    const isOrganisationDetailsMasked = valuesSecP.isOrganisationDetailsMasked; 

                    // googleCityDetails related modifications for param to pass to save
                    let googleCityDetails = [];
                    if(locSelected.length>0){
                        

                        Object.keys(locSelected).map((e,i)=>{

                            let googleCityDetailsObj = {
                                "cityName":null,
                                "state":null,
                                "country":null,
                                "latitude":null,
                                "longitude":null,
                                "placeId":null
                            };
                            let locObj = locSelected[e];
                            googleCityDetailsObj.cityName = locObj.city!==undefined?locObj.city:null;
                            googleCityDetailsObj.state = locObj.state!==undefined?locObj.state:null;
                            googleCityDetailsObj.country = locObj.country!==undefined?locObj.country:null;
                            googleCityDetailsObj.latitude = locObj.lat!==undefined?locObj.lat:null;
                            googleCityDetailsObj.longitude = locObj.lng!==undefined?locObj.lng:null;
                            googleCityDetailsObj.placeId = locObj.placeId!==undefined?locObj.placeId:null;


                            googleCityDetails.push(googleCityDetailsObj);
                        });           

                    }
                    
            

                    //if this also exist 
                    paramObj.categoryId = categoryId;
                    paramObj.typeId = typeId;
                    paramObj.budget = budget;
                    paramObj.budgetCurrency = budgetCurrency;
                    paramObj.lawyerDiscoveryParams.typeOfProfessionalId = typeOfProfessionalId;
                    paramObj.lawyerDiscoveryParams.practiceAreasIds = practiceAreasIds;
                    paramObj.lawyerDiscoveryParams.placeIds = placeIds;
                    paramObj.lawyerDiscoveryParams.workExperience = workExperience;
                    paramObj.lawyerDiscoveryParams.isOrganisationDetailsMasked = isOrganisationDetailsMasked;
                    paramObj.lawyerDiscoveryParams.googleCityDetails = googleCityDetails;


                }else if(subTaskFlag==1){
                    //with subtask

                    const typeOfProfessionalId = valuesSubT.typeOfProfessionalIdSubT;
                    const practiceAreasIds = valuesSubT.practiceAreasIdsSubT;
                    
                    const placeIds = valuesSubT.placeIdsSubT; 
                    const workExperience = valuesSubT.workExperienceSubT;              
                    const isOrganisationDetailsMasked = false; 

                    const subtaskKeys = valuesSubT.subtaskKeys;  
                     


                    paramObj.lawyerDiscoveryParams.typeOfProfessionalId = typeOfProfessionalId;
                    paramObj.lawyerDiscoveryParams.practiceAreasIds = practiceAreasIds;
                    paramObj.lawyerDiscoveryParams.placeIds = placeIds;
                    paramObj.lawyerDiscoveryParams.workExperience = workExperience;
                    paramObj.lawyerDiscoveryParams.isOrganisationDetailsMasked = isOrganisationDetailsMasked;
          
                    paramObj.subTasks = subtaskKeys;

                    
                }


            }

            
            //console.log(" before saving in draft==>",paramObj);
            

            let paramObjData = {
                "draftId" : draftId,
                "data": paramObj
             };
            
            let lservdrf = new DraftTaskService();
            lservdrf.saveDraftTask(paramObjData).then(data =>{
                    //this.setStepOneStDocuments();
                    //console.log.log("success resp=>",data);
                    let entityObj  = data.data.entity;       
                    //console.log.log(" resp =>>",entityObj);
                    // set draft Id
                    let otherParam = {};
                    this.props.setDraftFlag("draftId",entityObj,otherParam);

                    // make draft button disabled
                    this.props.setDraftFlag("singleTaskWithoutSubFlowD",false,{});
                                        
                    //********* for context starts *****
                    // updating data in context 
                    let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;  
                    // example
                    if(setShare!==null){
                        // note it is a special case for draft
                        setShare({actionType:"DRAFT"}); 
                        setTimeout(()=>{
                            cancelButtonAction();
                        },1100);
                        
                    }                      
                    
                    //********* for context ends *****  
                    

                }).catch((e)=>{
                    // make draft button enabled
                    this.props.setDraftFlag("singleTaskWithoutSubFlowD",true,{});
                        //console.log.log("server error=>",e);
                }).finally(()=>{
                     
                });

    }

    custValidate=()=>{
    
        const {values} = this.props;

        let stepOneStTaskNameErMsg = '';
        let stepOneStTaskDescErMsg = '';
        let stepOneStTaskDueDateErMsg= '';  
        let totErrCountTrack = [];   
        
        let regexBlankSpace = /^ *$/;   
        ////console.log.log("==values==>>",values);

        let taskDueDate = values.stepOneStTaskDueDate;
      
        if(!(values.stepOneStTaskName)||(values.stepOneStTaskName==0) || (regexBlankSpace.test(values.stepOneStTaskName)==true) ){
          stepOneStTaskNameErMsg = "Task Name is required.";        
          //this.isComponentLoaded && this.setState({ "stepOneStTaskNameErMsg": stepOneStTaskNameErMsg});
          totErrCountTrack.push(stepOneStTaskNameErMsg);
        }else{
            if(values.stepOneStTaskName && values.stepOneStTaskName.length>120){
                stepOneStTaskNameErMsg = "Task Name cannot be more than 120 characters."; 
                totErrCountTrack.push(stepOneStTaskNameErMsg);
            }
        } 
       
        if(!(values.stepOneStTaskDesc)||(values.stepOneStTaskDesc==0) || (regexBlankSpace.test(values.stepOneStTaskDesc)==true) ){
            stepOneStTaskDescErMsg = "Task Description is required."; 
            totErrCountTrack.push(stepOneStTaskDescErMsg);
        }
        else if(values.stepOneStTaskDesc && values.stepOneStTaskDesc.length>300 ){
            stepOneStTaskDescErMsg = "Maximum 300 characters are allowed."; 
            totErrCountTrack.push(stepOneStTaskDescErMsg);
        } 

        let todayDate = (new Date()).setHours(0, 0, 0, 0); // with 0 hour minute second
        let todayDateTs =  CustDate.custFormatDate(todayDate,'ts');
        todayDateTs = parseInt(todayDateTs);

        taskDueDate =  taskDueDate?parseInt(taskDueDate): null;

        //console.log(" todayDateTs==>>",todayDateTs," todayDateTs as date=>>",(new Date(todayDateTs)), " taskDueDate==>>>",todayDateTs," taskDueDate as date=>>",(new Date(taskDueDate)),(taskDueDate < todayDateTs));

        if(taskDueDate && (taskDueDate < todayDateTs)){
            stepOneStTaskDueDateErMsg = "Task due date should be greater than todays date";
            totErrCountTrack.push(stepOneStTaskDueDateErMsg);
        }
       

        this.isComponentLoaded &&  this.setState({ 
            "stepOneStTaskNameErMsg":stepOneStTaskNameErMsg,
            "stepOneStTaskDescErMsg":stepOneStTaskDescErMsg,
            "stepOneStTaskDueDateErMsg":stepOneStTaskDueDateErMsg,
            });  
        

        if(totErrCountTrack.length>0){            
          return false;
        }     
      
        return true;
        }

    setCalDate=(dt)=>{
        ////console.log.log("selected date=>>",dt);
        let dtTs = getTimestampInMillisecondsFromDateObj(dt);
        let dttOfset = getOffsetOfLocalDateTime(dt);       

        let otherParam1 = {};
        this.props.setStatesForStep1ofST("setStepOneStTaskDueDate",dtTs,otherParam1);
        let otherParam2 = {};
        this.props.setStatesForStep1ofST("setStepOneStTaskOffsets",dttOfset,otherParam2);
    }

    setStepOneStDocuments=(docs)=>{        
        this.props.setStepOneStDocuments(docs);
    }

    removeDocOnClick(i){               
        let otherParam = {"action":"remove"};
        this.props.setStatesForStep1ofST("setStepOneStDocuments",i,otherParam);
    }

    customDate = (defaultDate)=>{        
           
            return (
              <DatePicker
                selected={defaultDate}
                onChange={date => {this.setCalDate(date)}}
                calendarClassName="customtaskaddclassstep1calcls"         
                customInput={<ExampleCustomInput   />}
                dateFormat={getDateFormatForCal({"countryCode":"IN"})}
                minDate={new Date()}
              />
            );
    }

    

    /**
     * TaskAddSingleTaskStep1
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 27-02-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentDidMount() {
        this.isComponentLoaded = true;
        // scroll top
        //window.scrollTo(0, this.refouterdivstep1st.current.offsetTop);
        commonnotitrack("SCROLL_TO_MAIN_HEADER",{
            "notiType": "SCROLL_TO_MAIN_HEADER",
            "triggerCallback":true,
            "trigerredFrom":"TASK_CREATION_FLOW"
           });
        

    }

    /**
     * TaskAddSingleTaskStep1
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 27-02-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {  
        this.isComponentLoaded = false;
    }

   

    /**
     * TaskAddSingleTaskStep1
     * Function name : render
     * Author        :
     * Created Date  : 27-2-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() {    
        const {values, valuesSubT, cancelButtonAction} = this.props;
        const defaultDateData = values.stepOneStTaskDueDate;//new Date();
        const defaultDate = defaultDateData!==null?getDateObjFromMilliseconds(defaultDateData):null;
        const docsData = values.stepOneStDocuments;

        const stepOneOffsetHour = values.stepOneOffsetHour;
        const stepOneOffsetMinute = values.stepOneOffsetMinute;
        
        
        const taskTypeFlag = values.taskTypeFlag;
        const subTaskFlag = values.subTaskFlag; 
        const singleTaskWithoutSubFlowD = values.singleTaskWithoutSubFlowD;
        const singleTaskWithSubFlowD = valuesSubT.singleTaskWithSubFlowD;
        //console.log.log("ST 1 singleTaskWithoutSubFlowD=>"+singleTaskWithoutSubFlowD);

        //console.log("****** singleTaskWithoutSubFlowD==>",singleTaskWithoutSubFlowD," singleTaskWithSubFlowD==>",singleTaskWithSubFlowD);

        const draftId = values.draftId?values.draftId:0;  

       return (
                <React.Fragment>  
        <div ref={this.refouterdivstep1st}>
            {/* task header start */}
            <div className="task_header task_header_withTopHeader">
                Fill task details
                <span className="step_block">
                <span>Step 01 / 03 </span>
                <span className="progress">
                    <div className="progress-bar" style={{ width: "30%" }} />
                </span>
                </span>
            </div>
            {/* task header end */}
            {/* task body area start */}
            <div className="cutom_container cutom_container_withTopHeader">
                <div className="task_create_form_container task_big_formbox task_container_withbox new_autoHeightAdjust_taskCreate_bg">
                    <div className="createTask_newWrapping_singleMulti_bg">   
                        <div className="split_mid_box">
                            <div className="task_form_box">
                                <form>
                        <div className="allert_messg">
                            All fields are mandatory except for the one's marked as{" "}
                            <strong>(Optional)</strong>
                        </div>
                        <div className="main_form_box">
                            <div className="form_block form_block_half">
                            <div className="form__group field">
                                <div className="panel50 panel_half">
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input
                                    type="radio"
                                    className="custom-control-input"
                                    id="customRadio1"
                                    name="example1"
                                    checked={taskTypeFlag==0?true:false}
                                    onChange={()=>{
                                        let otherParam = {};
                                        this.props.changeTaskType(0,otherParam);
                                    }}
                                    />
                                    <label
                                    className="custom-control-label"
                                    htmlFor="customRadio1"
                                    >
                                    Create a single Task
                                    </label>
                                </div>
                                </div>
                                <div className="panel50 panel_half">
                                {draftId===0 && (
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input
                                    type="radio"
                                    className="custom-control-input"
                                    id="customRadio2"
                                    name="example1"
                                    checked={taskTypeFlag==1?true:false}
                                    onChange={()=>{
                                        let otherParam = {};
                                        this.props.changeTaskType(1,otherParam);
                                    }}
                                    />
                                    <label
                                    className="custom-control-label"
                                    htmlFor="customRadio2"
                                    >
                                    Create multiple Task
                                    </label>
                                </div>
                                )}
                                </div>
                                <div className="clearboth" />
                            </div>
                            </div>
                            <div className="form_block">
                            <div className={`form__group field${this.state.stepOneStTaskNameErMsg?"form_group_error":""}  `}>
                                <input
                                type="input"
                                className="form__field"
                                placeholder="Task Name"
                                name="stepOneStTaskName"
                                autoComplete={"off"}
                                onChange ={(e)=>{
                                    let otherParam = {"inputFieldName":"stepOneStTaskName","value":e.target.value};
                                    this.props.setStatesForStep1ofST("handleChange","",otherParam);
                                    
                                }
                            }
                                defaultValue={values.stepOneStTaskName}                          
                                
                                />
                                <label htmlFor="name" className="form__label">
                                Task Name
                                </label>
                                <span className="error_span">{this.state.stepOneStTaskNameErMsg}</span>
                            </div>
                            </div>
                            <div className="form_block">
                            <div className={`form__group field${this.state.stepOneStTaskDescErMsg?"form_group_error":""}  `}>
                                <textarea
                                type="input"
                                className="form__txtareafield"
                                placeholder="Task Description "
                                name="stepOneStTaskDesc"
                                
                                onChange ={(e)=>{
                                    let otherParam = {"inputFieldName":"stepOneStTaskDesc","value":e.target.value};
                                    this.props.setStatesForStep1ofST("handleChange","",otherParam);
                                    
                                }
                                }
                                defaultValue={values.stepOneStTaskDesc}
                                />
                                <label htmlFor="name" className="form__label">
                                Task Description
                                </label>
                                <span className="error_span">{this.state.stepOneStTaskDescErMsg}</span>
                            </div>
                            </div>
                            <div className="form_block">
                            <div className="panel50 panel_half">
                                <div className={`form__group field ${this.state.stepOneStTaskDueDateErMsg?"form_group_error":""}`}>
                                <div className="input-group date" id="sandbox-container">
                                
                                    {/* <input
                                    type="text"
                                    className="form__field customdatefld"
                                    placeholder="Task Due Date (Optional)"
                                    /> */}
                                    {this.customDate(defaultDate)}
                                    
                                </div>
                                <span className="error_span">
                                    {this.state.stepOneStTaskDueDateErMsg}
                                    </span>
                                </div>
                            </div>
                            <div className="clearboth" />
                            </div>
                            {/* == file upload new start ==   */}
                            <div className="form_block">
                            <div className="form__group field">
                                <div className="custom_file_up">
                                <div className="upload-btn-wrapper">
                                    <label className="new_upbutton" htmlFor="upload">
                                    <button className="uploadbtn">
                                        <span>
                                        <img src="assets/images/upload_icon.png" />
                                        </span>
                                        Upload Task Documents (Optional)
                                    </button>
                                    </label>
                                    <input ref={this.fileUpl} multiple onChange={this.trackFileSelection} type="file" name="files"  />
                                    <span className="error_span">{this.state.stepOneStFileUplErMsg}</span>
                                    <div className="clearboth" />
                                </div>
                                <div className="custom_fileup_name">
                                {this.state.uploadingFlag && (
                                    <div>Uploading...</div>
                                )} 
                                
                                    {Object.keys(docsData).length > 0 &&
                                        Object.keys(docsData).map((k, i) => (
                                    <div key={i} className="upfile_name_box">
                                        <span className="upfile_name">{docsData[k].name}</span>
                                        <button onClick={(e)=>{
                                            e.preventDefault();
                                            this.removeDocOnClick(i)}} className="upfile_name_cross btn_appearance_none">
                                            <img src="assets/images/error.png" />
                                        </button>
                                    </div>

                                ))
                            }
                                    
                                    
                                </div>
                                </div>
                            </div>
                            <div className="clearboth" />
                            </div>
                            {/* == file upload new end ==   */}
                        </div>
                        </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/* submit area button area strat */}
                <div className="task_details_btn_area bottomStickFooter_taskCreate">
                <div className="btn_fld">
                    <div className="panel50">
                    <button onClick={(e)=>{
                        e.preventDefault();
                        this.saveDraft();
                    }} disabled={(singleTaskWithoutSubFlowD==false)?true:false} className="btn_appearance_none saveDraftBtn">
                        Save as Draft
                    </button>
                    </div>
                    <div className="panel50">
                    <div className="cancel_continue_btn">
                        <a href="" onClick={(e)=>{
                            e.preventDefault();
                            cancelButtonAction();
                        }} className="btn_txt">
                        Cancel
                        </a>
                        <button  onClick={this.continue} className="sub_grd_btn" >
                        <span>Continue</span>
                        </button>
                    </div>
                    </div>
                    <div className="clearboth" />
                </div>
                </div>
                {/* submit area button area end */}
            </div>
            {/* task body area end */}
        </div>            
    </React.Fragment>
            
        );
    }

  }
  export default TaskAddSingleTaskStep1;