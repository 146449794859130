/**
 * TaskAddSingleTaskStep3
 * Page/Class name   : TaskAddSingleTaskStep3
 * Author            :
 * Created Date      : 27-02-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : rendering loader structure
**/
import React, { Component, Suspense, lazy} from 'react';
import '../../../custom_resources/css/custom.css';

import SendTaskRequestService from '../../service/SendTaskRequestService';
import {notify} from '../../messagebar/CustomMessageBar';
import CustomMessageBar from '../../messagebar/CustomMessageBar';

// Modal
import CustReactBootstrapModal from './CustReactBootstrapModal';

// For loading CustLoader
import CustLoader from './CustLoader';

// for context
import CommonContext from '../../context/CommonContext';
import { commonJsFuncModule as commonJsObj } from '../../../common_utilities/commonjsfunc';
import {commonnotitrack} from "./NotiEmitTrack";

const getBaseUrl = commonJsObj.getBaseUrl;


class TaskAddSingleTaskStep3 extends Component {

    isComponentLoaded = false;
    // for context
    static contextType = CommonContext;  

    /**
     * TaskAddSingleTaskStep3
     * Function name : constructor
     * Author        :
     * Created Date  : 27-02-2020
     * Purpose       : initializing state
     * Params        : props
    **/
    constructor(props) {
        super(props);
        this.state = {
            "showModalMsgForTskRqSent": false,
            "continueDisabled": false,
            "loaderFlag": true,
            "topUserDetails": []
         } 
        // for modal for msg after task request sent
        this.tskreqsentmsgmodref = React.createRef();   
        this.refouterdivstep3 = React.createRef(); 
        
    }

    continue = e =>{
        e.preventDefault();
        let nOtherParam = {};
        this.props.nextStep(nOtherParam);
    }

    previousStep = e =>{
        e.preventDefault();

        const {values} = this.props;
        const taskTypeFlag = values.taskTypeFlag;
        const subTaskFlag = values.subTaskFlag;

        // 0 => for single task , 1=> for multi task
        if(taskTypeFlag==0){
            let pOtherParam1 = {};
            this.props.prevStep(pOtherParam1);
        }else if(taskTypeFlag==1){
            let pOtherParam2 = {
                "bypassToStep": 1,
                "bypassToStepFlag": true,

            };
            this.props.prevStep(pOtherParam2);
        }

        
    }

    /**
     * TaskAddSingleTaskStep3
     * Function name : loadLoader
     * Author        :
     * Created Date  : 13-3-2020
     * Purpose       : Load loader
     * Params        : 
     **/
    loadLoader() {
        return (
            <CustLoader className={'smallLoaderGreen'} loaderText={'Loading..'} />
        );
    }

    /**
     * TaskAddSingleTaskStep3
     * Function name : hideLoader
     * Author        :
     * Created Date  : 13-3-2020
     * Purpose       : Hide loader
     * Params        : 
     **/

    hideLoader() {
        this.isComponentLoaded && this.setState({ loaderFlag: false });
      }

     /*** No lawyer found related starts  */
    /**
     * TaskAddSingleTaskStep2
     * Function name : organisationDetailModalOpen
     * Author        :
     * Created Date  : 11-2-2020
     * Purpose       : To open organisationDetailModal
     * Params        : 
    **/  
   showModalMessgForTaskReqSentOpen = (otherParam)=>{    
    this.isComponentLoaded &&  this.setState({showModalMsgForTskRqSent:true},()=>{
      this.tskreqsentmsgmodref.current.showModal();
      this.getshowModalMessgForTaskReqSentContent();
    });    
      
  }

  /**
     * TaskAddSingleTaskStep2
     * Function name : organisationDetailModalClose
     * Author        :
     * Created Date  : 11-2-2020
     * Purpose       : To close organisationDetailModal
     * Params        : 
    **/
   showModalMessgForTaskReqSentClose = ()=>{    
    this.isComponentLoaded && this.setState({showModalMsgForTskRqSent:false},()=>{
      this.tskreqsentmsgmodref.current.hideModal();
    });
    }

    /**
   * TaskAddSingleTaskStep2
   * Function name : getOrganisationDetailModalOpen
   * Author        :
   * Created Date  : 11-2-2020
   * Purpose       : Get modal view with data
   * Params        : 
  **/
 getshowModalMessgForTaskReqSentContent() {   
        const {cancelButtonAction} = this.props;

        let viewData = <div className="dash_modal">
        
                <>
            <div className="modal-header">
                <h4 className="modal-title modal_title_tool">New Task Created
                {/*<div className="input_tooltip_bg">
                        <span className="input_tooltip" aria-label="Organisation's Details" data-balloon-pos="down">
                        <i className="fa fa-question-circle"></i>
                        </span>
                        </div>*/}
                </h4>
                <button type="button" onClick={
                    ()=>{
                    this.showModalMessgForTaskReqSentClose();
                    setTimeout(()=>{
                        cancelButtonAction();
                    },250);                   

                }} className="close modal_close" data-dismiss="modal">&times;</button>
            </div> 
            { this.state.showModalMsgForTskRqSent==false && ( <CustomMessageBar />) }
            <div className="modal-body padding_top">
            <div className="popUP_text_p">
                        <p>

                  Thank you for sending the task request. We will get in touch with you shortly.
                  </p>

              </div>
            </div>
            <div className="modal-footer">
               
                <button  type="button" className="btn btn-primary modal_btn_save" onClick={(e)=>{ e.preventDefault();
                    this.showModalMessgForTaskReqSentClose();
                    setTimeout(()=>{
                       cancelButtonAction();
                    },250); 

                } } data-dismiss="modal"><span>Continue</span></button>
                
            </div>
          
          
          </>
        
                        
        </div>;
    
        this.tskreqsentmsgmodref.current.updateStateContent(viewData);
        this.tskreqsentmsgmodref.current.showContent();
        return viewData;
    }    

    /*** No lawyer found  modal related ends  */

    sendTaskRequestToLawyers = (e)=>{
        e.preventDefault();
        
        this.isComponentLoaded && this.setState({"continueDisabled":true},()=>{
            this.callSendTaskRequestToLawyers();
        });
        

    }

    callSendTaskRequestToLawyers = ()=>{
            //console.log(" called callSendTaskRequestToLawyers");
            
            // call service
            const {values,values0,valuesMt} = this.props;
            //console.log(" values==>",values," values0==>",values0, " valuesMt=>>",valuesMt," multiTaskFileId==>>>>",values.multiTaskFileId);
            // from values
            const taskTypeFlag = values.taskTypeFlag;
            const taskId = values.taskId;
            const multiTaskFileId = values.multiTaskFileId;

            let typeOfProfessionalId = "";
            let practiceAreasIds = "";           
            let placeIds = "";
            let workExperience = "";             
            let isOrganisationDetailsMasked = "";
            
            if(taskTypeFlag==0){
                // from values 0 for single task scenario
                 typeOfProfessionalId = parseInt(values0.typeOfProfessionalId);
                 practiceAreasIds = values0.practiceAreasIds;            
                 placeIds = values0.placeIds; 
                 workExperience = values0.workExperience;              
                 isOrganisationDetailsMasked = values0.isOrganisationDetailsMasked; 
            }else if(taskTypeFlag==1){
                // from values 1 for multi task scenario
                 typeOfProfessionalId = parseInt(valuesMt.typeOfProfessionalIdMt);
                 practiceAreasIds = valuesMt.practiceAreasIdsMt;            
                 placeIds = valuesMt.placeIdsMt; 
                 workExperience = valuesMt.workExperienceMt;              
                 isOrganisationDetailsMasked = valuesMt.isOrganisationDetailsMaskedMt;
            }
            

            let paramObj = {
                        "taskTypeFlag": taskTypeFlag,
                        "taskId": taskId,
                        "multiTaskFileId": multiTaskFileId,                    
                        "data":{
                            "typeOfProfessionalId": typeOfProfessionalId,
                            "practiceAreasIds":practiceAreasIds,
                            "placeIds":placeIds,
                            "workExperience":workExperience,
                            "isOrganisationDetailsMasked":isOrganisationDetailsMasked
                        }
                    }   
            let serv = new SendTaskRequestService();                     
            serv.sendTaskRequest(paramObj).then(data =>{
                if(data.status==200){
                    let respData ={};
                    if(data.data.entity!==undefined){
                       //console.log("resp received after task request sent===>>",data.data.entity);
                       respData  = data.data.entity;
                       //console.log("SUccEss=>",respData);

                       
                       //********* for context starts *****
                        // updating data in context 
                         let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;  
                        // example
                        if(taskTypeFlag==0){
                            setShare({actionType:"SINGLETASK"}); 
                        }
                        else if(taskTypeFlag==1){
                            setShare({actionType:"MULTITASK"});
                        }
                        
                         
                        //********* for context ends *****      
                       
                       let otherParamNlf1 = {};
                       this.showModalMessgForTaskReqSentOpen(otherParamNlf1);

                }
            }
            }).catch((err)=>{
                    let errStatusCodeAr = [400,500]; // allowed error status code
                    if(err.response && err.response.data){
                        let erRespData = err.response.data;
                        if(errStatusCodeAr.indexOf(erRespData.status) !== -1){         
                            let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                            console.log(" errMsg on task request sent==>",errMsg);
    
                            let notiMsg = errMsg;
                            notify(notiMsg,true,{
                            "successMsg":0,
                            "customWrapClass":"customerrlass",
                            "showCloseButton": false, 
    
                            "dismiss": {
                            "duration": 1500,
                            }
    
                            }); 
                                             
                    }
                    } 
            }).finally(()=>{
                this.isComponentLoaded && this.setState({"continueDisabled":false});
            });

    }
    /**
     * TaskAddSingleTaskStep1
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 27-02-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentDidMount() {
        this.isComponentLoaded =true;

        // scroll top
        //window.scrollTo(0, this.refouterdivstep3.current.offsetTop); 
        commonnotitrack("SCROLL_TO_MAIN_HEADER",{
            "notiType": "SCROLL_TO_MAIN_HEADER",
            "triggerCallback":true,
            "trigerredFrom":"TASK_CREATION_FLOW"
           });

        const {values} = this.props;
        let  taskCreateResp = values.taskCreateResp;      

        let topUserDetails = taskCreateResp.topUserDetails;

        setTimeout(()=>{                
                this.setState({"topUserDetails":topUserDetails},()=>{
                    this.hideLoader();
                });
            
        },1500);
        
        
    }

    /**
     * TaskAddSingleTaskStep1
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 27-02-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {
        this.isComponentLoaded = false;  
    }

   

    /**
     * TaskAddSingleTaskStep1
     * Function name : render
     * Author        :
     * Created Date  : 27-2-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() {    
        const {values, values0,valuesMt, handleChange, cancelButtonAction} = this.props;
        const taskCreateResp = values.taskCreateResp;
        const step = values.step;
        // const taskId = values.taskId;
        // const multitaskFileId = values.multitaskFileId;
         const taskTypeFlag = values.taskTypeFlag;
        // const subTaskFlag = values.subTaskFlag;      
       

        const topUserDetails = this.state.topUserDetails;// taskCreateResp.topUserDetails;
        const totalLawyersFound = taskCreateResp.totalLawyersFound;

       return (
                <React.Fragment>  
                    <div ref={this.refouterdivstep3}>
                        {/* task header start */}
                        <div className="task_header task_header_withTopHeader">
                            Send Requests to Professionals
                            <span className="step_block">
                            <span> {taskTypeFlag==0?"Step 03 / 03":"Step 02 / 02"} </span>
                            <span className="progress">
                                <div className="progress-bar" style={{ width: "100%" }} />
                            </span>
                            </span>
                            <span className="taskstep_back_bg">
                            <button onClick={this.previousStep}>
                                <img className="back_img_icon" src={getBaseUrl()+"assets/images/path-back-white.png"} />
                                Back
                            </button>
                            </span>
                        </div>
                        {/* task header end */}
                        {/* task body area start */}
                        <div className="cutom_container cutom_container_withTopHeader">
                            <div className="task_create_form_container task_big_formbox task_container_withbox new_autoHeightAdjust_taskCreate_bg">
                                    <div className="createTask_newWrapping_singleMulti_bg">
                                        <div className="send_req_bodybg">
                                        {/* === send request professional main bg start ===  */}
                                        <div className="send_req_pro_container">

                                        {totalLawyersFound>0 && (    
                                            <div className="sendreq_noti_bg">
                                                <div className="allert_messg">
                                                    <span>
                                                    We have found{" "}
                                        <strong>{totalLawyersFound} curated and verified {totalLawyersFound>1?"professionals":"professional"}</strong> that match
                                                    your criteria. Shown below is a sample set.
                                                    </span>
                                                    <span>
                                                    <div className="input_tooltip_bg">
                                                        <span
                                                        className="input_tooltip"
                                                        aria-label="Verified professionals"
                                                        data-balloon-pos="down"
                                                        >
                                                        <i className="fa fa-question-circle" />
                                                        </span>
                                                    </div>
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                        </div>
                                        {/* === send request professional main bg end ===  */}
                                        {/* ===  professional listing section start ===  */}
                                        <div className="send_req_pro_container">
                                            <div className="proferssional_listing_bg">
                                                <div className=" text-center marginInMap">
                                                    {this.state.loaderFlag ? this.loadLoader() : null}
                                                </div>
                                                <ul>
                                                    {topUserDetails.length>0 && Object.keys(topUserDetails).map((e,i)=> <li key={i}>
                                                    <div className="pro_listing_box">
                                                        <div className="pro_listing_head">
                                                        <div className="pro_list_left">
                                                            {/* <img
                                                            className="pro_list_img"
                                                            src="assets/images/pro-pic.png"
                                                            /> */}

                                                        {topUserDetails[e].profilePictureURL && (

                                                                    <img
                                                                    className="pro_list_img"
                                                                    src={topUserDetails[e].profilePictureURL}
                                                                    onError="no-img-found.png"
                                                                    width="37"
                                                                    height="37"
                                                                    />
                                                        )} 

                                                            {topUserDetails[e].profilePictureURL==null && (

                                                            <img
                                                            className="pro_list_img"                 
                                                            src="no-img-found.png"
                                                            width="37"
                                                            height="37"
                                                            />
                                                            )}


                                                        


                                                        </div>
                                                        <div className="pro_list_right">
                                                            <div className="pro_list_name">{(topUserDetails[e].firstName?topUserDetails[e].firstName:"")+" "+(topUserDetails[e].lastName?topUserDetails[e].lastName:"")}</div>
                                                            <div className="pro_list_rating">
                                                            <span className="pro_rating">
                                                                <i className="fa fa-star" aria-hidden="true" /> &nbsp;
                                                                {topUserDetails[e].rating}
                                                            </span>
                                                            <span className="pro_exp">Experience - {(topUserDetails[e].workExperience!==undefined && topUserDetails[e].workExperience)?(topUserDetails[e].workExperience+" years"):""} </span>
                                                            </div>
                                                        </div>
                                                        </div>
                                                        <div className="pro_listing_body">
                                                        <p>
                                                            {(topUserDetails[e].bio!==undefined && topUserDetails[e].bio) ? ((topUserDetails[e].bio).length>225?(topUserDetails[e].bio).substring(0,224)+"...":topUserDetails[e].bio):"" }
                                                        </p>
                                                        </div>
                                                    </div>
                                                    </li>

                                                    )}                                    
                                                    
                                                </ul>
                                                
                                            </div>
                                        </div>
                                        {/* ===  professional listing section end ===  */}
                                </div>
                                    </div>
                                </div>
                            {/* submit area button area strat */}
                            <div className="task_details_btn_area bottomStickFooter_taskCreate">
                            <div className="btn_fld">
                                <div className="panel50">
                                &nbsp;
                                {/* <a href="#" className="btn_txt">
                                    Save as Draft
                                </a> */}
                                </div>
                                <div className="panel50">
                                <div className="cancel_continue_btn">
                                    <a href="" onClick={(e)=>{
                            e.preventDefault();
                            cancelButtonAction();
                        }} className="btn_txt">
                                    Cancel
                                    </a>
                                    <button disabled={this.state.continueDisabled} onClick={this.sendTaskRequestToLawyers} disabled={topUserDetails.length==0?true:false} className="sub_grd_btn">
                                    <span>Send Request</span>
                                    </button>
                                </div>
                                </div>
                                <div className="clearboth" />
                            </div>
                            </div>
                            {/* submit area button area end */}
                        </div>
                        {/* task body area end */}
                    </div>
                    {/* Task request sent success msg modal start */}
                    <CustReactBootstrapModal ref={this.tskreqsentmsgmodref}  backdrop={false} backdropClassName="custom_modal_class"   animation={false} show={this.state.showModalMsgForTskRqSent} onShow={()=>{}} onHide={()=>{}} modalcustdialog="custom_modal_lg"  loaderbodytitle="Organisation"  />
                    {/* Task request sent success msg modal ends */}
                </React.Fragment>            
        );
    }

  }
  export default TaskAddSingleTaskStep3;