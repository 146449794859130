/**  
 * SplitScreenProfessionalsNegotiationChat
 * Page/Class name   : MasterLayout
 * Author            :
 * Created Date      : 7-2-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : render master component
**/
import React, { Component } from 'react';
// import Header from '../../share/Header';
// import SideBar from '../../share/SideBar';

import {notify} from '../../messagebar/CustomMessageBar';
import CustomMessageBar from '../../messagebar/CustomMessageBar';

import CalculateInvoice from "./CalculateInvoice";

// for context
import CommonContext from '../../context/CommonContext';
// scroll 
// import { Scrollbars } from 'react-custom-scrollbars';
// newly added
import { throttle, debounce } from "throttle-debounce";



//services
import ChatListOfNegotiationService from '../../service/ChatListOfNegotiationService';
import ChatReplyService from '../../service/ChatReplyService';
import TaskDetailsService from '../../service/TaskDetailsService';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import  CustDate  from '../../../common_utilities/CustDate';
import { commonJsFuncModule as commonJsObj } from '../../../common_utilities/commonjsfunc';

//AssignButton
import AssignButton from "./AssignButton";

// OfferTaskButton
import OfferTaskButton from "./OfferTaskButton";

// For loading CustLoader
import CustLoader from './CustLoader';

import SplitScreenLeftMenu from "../../share/SplitScreenLeftMenu";


// common noti track
import NotiEmitTrack from "./NotiEmitTrack";
import {commonnotitrack} from "./NotiEmitTrack";

const getDateObjFromMilliseconds = commonJsObj.getDateObjFromMilliseconds;
const getTimestampInMillisecondsFromDateObj = commonJsObj.getTimestampInMillisecondsFromDateObj;
const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;
const sortArrayOfJsonByKey = commonJsObj.sortArrayOfJsonByKey;
const getBaseUrl = commonJsObj.getBaseUrl;
const getDateFormatForCal = commonJsObj.getDateFormatForCal;
const findElement = commonJsObj.findElement;
const getOrgName = commonJsObj.getOrgName;
const validateBlankSpace = commonJsObj.validateBlankSpace;
const validatePositiveInputNumber = commonJsObj.validatePositiveInputNumber;
const emailIdPresenceCheck = commonJsObj.emailIdPresenceCheck;
const phonePresenceCheck = commonJsObj.phonePresenceCheck;



const ExampleCustomInput = ({ value, onClick }) => (
  <input
  type="text"
  className="form__field customdatefld"
  placeholder="Select Due Date"
  defaultValue={value}
  onClick={onClick}
  readOnly={true}
  />
);

class SplitScreenProfessionalsNegotiationChat extends Component {

    isComponentLoaded =  false;
    // for context
    static contextType = CommonContext; 

    /**  
     * SplitScreenSingleTaskDetails
     * Function name : constructor
     * Author        :
     * Created Date  : 7-2-2020
     * Purpose       : initializing children
     * Params        : props
    **/
    constructor(props) {
      super(props);
      this.children = props.children;

      this.state = {
        "listData": [],
        "pageNum": 1,
        "totalNumberOfPages": 1,
        "loaderFlag": true,
        "pageNumArTrack": [], 
        // for hitting chat list api
        "taskOrSubTask":"", 
        "taskIdOrSubTaskId":0,
        "lpUserId":0,
        "lpUserName":"",


        // for confirm button to disable 
        "continueDisabled": false,
        // negotiation option type
        "negoOn":"",// 0=> on budget, 1=> on date

        "negoOnBudgetSend":false,// for budget
        "negoBudgetClickFlag":false,// for budget

        "negoOnDateSend":false,// for date
        "negoDateClickFlag":false,// for budget
        
        //
        "isTermsAcceptedByLP": false,
        "isTaskOfferReceived": false,

        //
        "proposedBudget":"",
        "proposedBudgetCurrency":"",
        "proposedDueDate":"",
        "budgetNegotiationStatus":"",
        "dueDateNegotiationStatus": "",
        "conclusionMessage": "",

        // lpuser latest chat record
        "lpUserLatestChatRecord":null,
        // budget input
        "budgetInput":"",
        // Due date input
        "dueDateInput":null,
        "assignedTo":null,       
        "notificationIdAr": [],
        "lastCommentId": 0,
        "scrollToNewNotiMsgFlag": false,
        "tabDisFlag": true,

        "assignRel": null,

        // new messsage control related 
        "budgetUpdationDescription":"",
        "dueDateUpdationDescription":"",
        "budgetDueDateCommonMsg":"",
        "budgetDueDateCommonMsgErr":"",

        "budgetErMsg":"",
        "dueDateErMsg":"",

      };
      this.scrollerRef = React.createRef();
      this.scrollerRef2 = React.createRef();

      // newly added
      this.slowDownScrollRelHit = debounce(500, this.trackScrollNewFunc);
    
    }

    /**  
     * SplitScreenSingleTaskDetails
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 7-2-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentDidMount() {
      this.isComponentLoaded = true;

      setTimeout(()=>{
        this.scrollerRef && this.scrollerRef.current!==null &&  this.scrollerRef.current.addEventListener("scroll", this.slowDownScrollRelHit);
            }        
        ,5000);

      // reload chat list
      this.reloadDataOfChatList( {"calledFrom":"componentDidMount"});   

      
     
      
    }

    /**  
     * SplitScreenSingleTaskDetails
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 7-2-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {
      this.isComponentLoaded = false;

       // newly added
       this.scrollerRef && this.scrollerRef.current!==null && this.scrollerRef.current.removeEventListener("scroll", this.slowDownScrollRelHit);
       
     
      
    }

    componentDidUpdate(prevProps){

    }

    offerTaskButtonCallback = (data) =>{
      let activity =  data.activity!==undefined?data.activity:"";
      if(activity=="OFFER_SENT_SUCCESS"){
            // show green success message 

            notify("Offer has been sent to the professional",true,{
              "successMsg":1,
              "customWrapClass":"customerrmidlass alert_section_top_zero",
              "msgKeyData": "negoChatNotiK1",
              "showCloseButton": false, 
  
              "dismiss": {
              "duration": 1700,
              }
  
              }); 

              this.reloadDataOfChatList( {"calledFrom":"offerTaskButtonCallback"});  
      }
    }

    customDate = (defaultDate)=>{

      return (
        <DatePicker
          selected={defaultDate}
          onChange={date => {this.setCalDate(date)}}
          calendarClassName="customtaskaddclassstep1calcls"         
          customInput={<ExampleCustomInput   />}
          dateFormat={getDateFormatForCal({"countryCode":"IN"})}
        />
      );
    }

    setCalDate=(dt)=>{
      ////console.log.log("selected date=>>",dt);
      let dtTs = getTimestampInMillisecondsFromDateObj(dt);
      //let dttOfset = getOffsetOfLocalDateTime(dt);
      this.isComponentLoaded && this.setState({"dueDateInput":dtTs});
    }

    // trackScroll = ()=>{

    //   let scobj = this.scrollerRef.current;
    //   let scrollTop = scobj.getValues().scrollTop;
    //   let scrollHeight = scobj.getScrollHeight();
    //   let clientHeight = scobj.getClientHeight();
  
    //   let prevPageNum = this.state.pageNum;
    //   let totalNumberOfPages = this.state.totalNumberOfPages;
      
    //   //console.log(" scrollHeight=>",scrollHeight," clientHeight==>",clientHeight, " scrollTop=>>",scrollTop);
      
    //   // if scrolled up to extreme point
    //   if(scrollTop==0 && prevPageNum < totalNumberOfPages){
        
    //     this.showLoader();
    //     this.disableTab();

    //     setTimeout(()=>{
    //       // call list api                         
    //       let pageNumData = prevPageNum + 1;
    //       let paramObj = {"pageNum":pageNumData, "taskIdOrSubTaskId":this.state.taskIdOrSubTaskId,"taskOrSubTask":this.state.taskOrSubTask,"lpUserId":this.state.lpUserId,"lpUserName":this.state.lpUserName,"ccUserId":this.state.ccUserId,"negoOn":this.state.negoOn};       
        

    //       this.isComponentLoaded && this.setState(paramObj,()=>{
    //         this.callServiceToFetchNegoChatRespList(paramObj,"NEGOCHATSCROLLUP");
    //       });
          
    //       },900);
      

    //   }
      

    // }
    

    // newly added
    
    trackScrollNewFunc = () => {
      if (
        this.scrollerRef!==null && this.scrollerRef.current.scrollTop == 0
      ) {
        //this.scrollerRef.current.scrollTop = 75;
        this.fetchMoreData();
      }
    }


    fetchMoreData = ()=>{

      let prevPageNum = this.state.pageNum;
      let totalNumberOfPages = this.state.totalNumberOfPages;
      
      //console.log(" scrollHeight=>",scrollHeight," clientHeight==>",clientHeight, " scrollTop=>>",scrollTop);
      
      // if scrolled up to extreme point
      if(prevPageNum < totalNumberOfPages){
        
        this.showLoader();
        this.disableTab();

        setTimeout(()=>{
          // call list api                         
          let pageNumData = prevPageNum + 1;
          let paramObj = {"pageNum":pageNumData, "taskIdOrSubTaskId":this.state.taskIdOrSubTaskId,"taskOrSubTask":this.state.taskOrSubTask,"lpUserId":this.state.lpUserId,"lpUserName":this.state.lpUserName,"ccUserId":this.state.ccUserId,"negoOn":this.state.negoOn};       
        

          this.isComponentLoaded && this.setState(paramObj,()=>{
            this.callServiceToFetchNegoChatRespList(paramObj,"NEGOCHATSCROLLUP");
          });
          
          },900);
      

      }
  
     
      

    }

     // newly added
     scrollToBottom = () => {
      //console.log(" ***** called scrollToBottom *****");
      
      this.scrollerRef.current.scrollTop = this.scrollerRef.current.scrollHeight;
     
    }

    callServiceToFetchNegoChatRespList = (paramObj,trackcalledfrom)=>{
      

      let paramObjData = {};
      paramObjData.pageNum = paramObj.pageNum;
      paramObjData.taskOrSubTask = paramObj.taskOrSubTask;
      paramObjData.taskIdOrSubTaskId = paramObj.taskIdOrSubTaskId;
      paramObjData.lpUserId = paramObj.lpUserId;
      paramObjData.chatType = paramObj.negoOn==0?"BUDGET":"DUE_DATE";
      paramObjData.lastCommentId = this.state.lastCommentId;
      
      let pageNumArTrack = [...this.state.pageNumArTrack];
      //console.log(" paramObjData=>>>",paramObjData," pageNumArTrack==>>>",pageNumArTrack);

      // restrict repeated pagenum related api call
      if(pageNumArTrack.indexOf(paramObjData.pageNum)>-1){
        //console.log("******Sorry cant hit api with page number => "+paramObjData.pageNum+" **********");
          return false;
      }else{
        pageNumArTrack.push(paramObjData.pageNum);
        this.isComponentLoaded && this.setState({"pageNumArTrack":pageNumArTrack});
      }
      
      
      
      let stcservice = new ChatListOfNegotiationService();
      stcservice.getChatListOfNegotiation({"data":paramObjData}).then(data =>{
            if(data.status==200){
                let respData ={};
                if(data.data.entity!==undefined){
                  
                   respData  = data.data.entity;
                   if(respData.chats!==undefined){
                        if(respData.chats.length > 0){

                         

                          let prevRecords = [...this.state.listData];
                          let currRecords = (respData.chats).reverse();
                          let concatenateRecords = []; 
                          let lastCommentId= 0;
                          if(trackcalledfrom=="NEGOCHATSCROLLUP"){

                             concatenateRecords = currRecords.concat(prevRecords);

                             //console.log(" trackcalledfrom=>",trackcalledfrom," currRecords=>",currRecords, " prevRecords=>",prevRecords," concatenateRecords=>",concatenateRecords);
                            
                          }else{
                             concatenateRecords = prevRecords.concat(currRecords);
                          }

                          lastCommentId = concatenateRecords[0]?concatenateRecords[0].id:0;
                          
                          let assignRel = {
                            "taskOrSubTask":this.state.assignRel.taskOrSubTask,
                            "taskIdOrSubTaskId":this.state.assignRel.taskIdOrSubTaskId,
                            "taskOrSubTaskName":this.state.assignRel.taskOrSubTaskName,
                            "taskDesc":this.state.assignRel.taskDesc,
                            "lpUserId":this.state.assignRel.lpUserId,
                            "lpUserName":this.state.assignRel.lpUserName,
                            "organisationName":getOrgName(),
                            "isOrganisationDetailsMasked":(respData.isOrganisationDetailsMasked!==undefined?respData.isOrganisationDetailsMasked:false),
                            "conflictCheckStatus":(respData.conflictCheckStatus!==undefined?respData.conflictCheckStatus:""),
                            "budgetStatus":(respData.budgetNegotiationStatus!==undefined?respData.budgetNegotiationStatus:""),
                            "dueDateStatus":(respData.dueDateNegotiationStatus!==undefined?respData.dueDateNegotiationStatus:""),
                            "agreedBudget":(respData.agreedBudget!==undefined?respData.agreedBudget:""),
                            "agreedBudgetCurrency":(respData.agreedBudgetCurrency!==undefined?respData.agreedBudgetCurrency:""),
                            "agreedDueDate":(respData.agreedDueDate!==undefined?respData.agreedDueDate:null),
    
                          };
                           let updtStateKeys1 = {
                            "listData":concatenateRecords,
                            "totalNumberOfPages":respData.totalNumberOfPages,
                            "proposedBudget":(respData.proposedBudget!==undefined?respData.proposedBudget:""),
                            "proposedBudgetCurrency":(respData.budgetCurrency!==undefined?respData.budgetCurrency:""),
                            "proposedDueDate":(respData.proposedDueDate!==undefined?respData.proposedDueDate:""),
                            "budgetNegotiationStatus":(respData.budgetNegotiationStatus!==undefined?respData.budgetNegotiationStatus:""),
                            "dueDateNegotiationStatus": (respData.dueDateNegotiationStatus!==undefined?respData.dueDateNegotiationStatus:""),
                            "conclusionMessage": (respData.conclusionMessage!==undefined?respData.conclusionMessage:""),
                             "isTermsAcceptedByLP": (respData.isTermsAcceptedByLP!==undefined?respData.isTermsAcceptedByLP:false),
                             "isTaskOfferReceived": (respData.isTaskOfferReceived!==undefined?respData.isTaskOfferReceived:false),
                             "lastCommentId":lastCommentId,
                             "assignRel":assignRel
                           };

                           this.isComponentLoaded && this.setState(updtStateKeys1,()=>{ 

                          

                            // make scroller scroll down for this scenario
                             
                            if(trackcalledfrom=="NEGOCHATSCROLLUP"){                             
                          

                              }else{
                                //scobj.scrollToBottom();
                                //newly added
                                this.scrollToBottom();
                              }
                                  
                               

                                  

                                  
                                    

                            
                           });
                        }
                        // else{   
                          
                        //   let updtStateKeys2 = {
                        //     "listData":[],
                        //     "totalNumberOfPages":respData.totalNumberOfPages,
                        //     "proposedBudget":(respData.proposedBudget!==undefined?respData.proposedBudget:""),
                        //     "proposedBudgetCurrency":(respData.budgetCurrency!==undefined?respData.budgetCurrency:""),
                        //     "proposedDueDate":(respData.proposedDueDate!==undefined?respData.proposedDueDate:""),
                        //     "budgetNegotiationStatus":(respData.budgetNegotiationStatus!==undefined?respData.budgetNegotiationStatus:""),
                        //     "dueDateNegotiationStatus": (respData.dueDateNegotiationStatus!==undefined?respData.dueDateNegotiationStatus:""),
                        //     "isTermsAcceptedByLP": (respData.isTermsAcceptedByLP!==undefined?respData.isTermsAcceptedByLP:false),
                        //     "lastCommentId":0
                        //    }

                        //     if(this.state.listData.length==0){
                        //       this.isComponentLoaded && this.setState(updtStateKeys2);
                        //     }
                        // }


                        }else{                          
                      }
            }
        }
        }).catch((err)=>{
                
                let errStatusCodeAr = [400,500]; // allowed error status code
                if(err.response && err.response.data){
                    let erRespData = err.response.data;
                    if(errStatusCodeAr.indexOf(erRespData.status) !== -1){         
                        let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                        console.log(errMsg);
                                         
                }
                } 
        }).finally(()=>{
            this.hideLoader();  
            this.enableTab({});   
            
        });
    }

    disableTab = (paramObj) =>{
      this.isComponentLoaded && this.setState({"tabDisFlag":true});   
    }

    enableTab = (paramObj) =>{
      this.isComponentLoaded && this.setState({"tabDisFlag":false});   
    }

    closeSplit = ()=>{
      //console.log.log("close")
          //********* for context starts *****
          // let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;      
          // if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){         

          //     if(sharedData.actionType=="SPLITSCREEN" ){

          //       let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;

          //       if(setShare){                              
          //         let paramsCntxt = {
          //           "taskId":"",
          //           "actionType":"",
          //           "leftTabToActive":"",
          //           "purpose":"",
          //           "other":{}
          //         } 
          //         setShare(paramsCntxt);                              
          //       }

                
          //     }
          // }
          //********* for context ends *****
          commonnotitrack("CLOSE_SPLITSCREEN",{
            "notiType": "CLOSE_SPLITSCREEN",
            "triggerCallback":false,
            "trigerredFrom":"PROFESSIONALS_INTERSESTED_LIST"
           });

    }

    getTaskDetailData = (param)=>{

      let paramObjData = param.paramObjData;
      let paramsCntxt = param.paramsCntxt;
      let calledFrom = param.calledFrom;

      let lservdrf = new TaskDetailsService();
      lservdrf.getTaskDetail(paramObjData).then(data =>{ 

          let entityObj  = data.data.entity;

          // context share
          let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;
          if(setShare){ 
                  paramsCntxt["other"]["taskResp"]= entityObj;
                  //console.log(" paramsCntxt===>>",paramsCntxt);
                  setShare(param.paramsCntxt); 
                  
          }
                                     
                               
      

      }).catch((e)=>{                       
          console.log("server error=>",e);
      }).finally(()=>{
         
      });
    }

    /**
     * TaskListOfSingleTask
     * Function name : loadLoader
     * Author        :
     * Created Date  : 27-3-2020
     * Purpose       : Load loader
     * Params        : 
     **/
    
    loadLoader() {
      return (
          <CustLoader className={'smallLoaderGreen'} loaderText={'Loading..'} />
      );
    }

    /**
     * TaskListOfSingleTask
     * Function name : hideLoader
     * Author        :
     * Created Date  : 27-3-2020
     * Purpose       : Hide loader
     * Params        : 
     **/

    hideLoader() {
      this.isComponentLoaded && this.setState({ loaderFlag: false });
    }

    /**
     * TaskListOfSingleTask
     * Function name : showLoader
     * Author        :
     * Created Date  : 27-3-2020
     * Purpose       : showLoader
     * Params        : 
     **/

    showLoader() {
      this.isComponentLoaded && this.setState({ loaderFlag: true });
    }
    reloadDataOfChatList = (otherParam) =>{

      let calledFrom = otherParam.calledFrom!==undefined?otherParam.calledFrom:"";
      let forUseExistingStateAr = ["trackNegoChatListOnRealTimeNoti","chooseNegoTab","offerTaskButtonCallback"];
      if(calledFrom && forUseExistingStateAr.indexOf(calledFrom)!=-1){
              // call list api      

              let paramObj = {"listData":[],"pageNum":1,"pageNumArTrack": [], "totalNumberOfPages":1,"taskIdOrSubTaskId":this.state.taskIdOrSubTaskId,"taskOrSubTask":this.state.taskOrSubTask,"lpUserId":this.state.lpUserId,"lpUserName":this.state.lpUserName,"ccUserId":this.state.ccUserId,"negoOn":this.state.negoOn,"negoBudgetClickFlag":false,"negoDateClickFlag":false,"lpUserLatestChatRecord":null,"budgetInput":"", "dueDateInput":null,"notificationIdAr":[],"lastCommentId":0, "scrollToNewNotiMsgFlag": false, "tabDisFlag": true, "isTaskOfferReceived":this.state.isTaskOfferReceived};
              this.isComponentLoaded && this.setState(paramObj,()=>{

                this.showLoader();

                setTimeout(()=>{
                  this.callServiceToFetchNegoChatRespList(paramObj,"*****called from reloadChatList**** ");
                },900);
                

              });
      }
      else if(calledFrom && calledFrom=="componentDidMount" )
      {
          // set list data to blank state
          this.showLoader();
          setTimeout(()=>{

          
          let taskOrSubTask = "";
          let taskIdOrSubTaskId = 0;
          let lpUserId = 0;
          let ccUserId = 0;
          let isTermsAcceptedByLP = false;
          let isTaskOfferReceived = false;
          let assignedTo = null;
          let negoOn = 0;
          let budgetStatus = "";
          let dueDateStatus = "";
          let assignRel = null;
          let lpUserName = "";
        
          //********* for context starts *****
          let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;      
          if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){   
            //console.log(" HEYAAAA sharedData==>",sharedData);

            if(sharedData.actionType=="SPLITSCREEN" ){
              if(sharedData.other!==undefined && sharedData.other.negoChat!==undefined){            
              
                let negoChatCntxt = sharedData.other.negoChat;           
              
                if(negoChatCntxt && negoChatCntxt.taskOrSubTask!==undefined){  
                  taskOrSubTask = negoChatCntxt.taskOrSubTask;   
                }
                if(negoChatCntxt && negoChatCntxt.taskIdOrSubTaskId!==undefined){  
                  taskIdOrSubTaskId = negoChatCntxt.taskIdOrSubTaskId;   
                }
                if(negoChatCntxt && negoChatCntxt.lpUserId!==undefined){  
                  lpUserId = negoChatCntxt.lpUserId;   
                }     
                if(negoChatCntxt && negoChatCntxt.lpUserName!==undefined){  
                  lpUserName = negoChatCntxt.lpUserName;   
                }   
                if(negoChatCntxt && negoChatCntxt.ccUserId!==undefined){  
                  ccUserId = negoChatCntxt.ccUserId;   
                }  
                
                let negotiationStatus = (negoChatCntxt && negoChatCntxt.lpUserInterestedData!==undefined && negoChatCntxt.lpUserInterestedData!==null && negoChatCntxt.lpUserInterestedData.negotiationStatus!==undefined)?negoChatCntxt.lpUserInterestedData.negotiationStatus: null;

                assignRel = (negoChatCntxt && negoChatCntxt.assignRel!==undefined && negoChatCntxt.assignRel!==null)?negoChatCntxt.assignRel: null;

                console.log(" 1100 negoChat =>>>>>> assignRel=>>",assignRel,negoChatCntxt);
                
                ////console.log.log("***** Now negoChatCntxt=>",negoChatCntxt);
                if(negoChatCntxt && negotiationStatus){
                    

                    if(taskOrSubTask=="STASK"){
                      isTermsAcceptedByLP  = (negotiationStatus && negotiationStatus.termsAcceptanceStatus!==undefined && negotiationStatus.termsAcceptanceStatus.isTermsAcceptedByLP!==undefined)? negotiationStatus.termsAcceptanceStatus.isTermsAcceptedByLP: false;

                      isTaskOfferReceived  = (negoChatCntxt && negoChatCntxt.lpUserInterestedData!==undefined && negoChatCntxt.lpUserInterestedData!==null && negoChatCntxt.lpUserInterestedData.isTaskOfferReceived!==undefined)?negoChatCntxt.lpUserInterestedData.isTaskOfferReceived: false;

                      assignedTo =  (negotiationStatus && negotiationStatus.assignedTo!==undefined && negotiationStatus.assignedTo!==null)?negotiationStatus.assignedTo: null;

                      budgetStatus = (negotiationStatus && negotiationStatus.budgetStatus!==undefined && negotiationStatus.budgetStatus!==null)?negotiationStatus.budgetStatus: "";

                      dueDateStatus =  (negotiationStatus && negotiationStatus.dueDateStatus!==undefined && negotiationStatus.dueDateStatus!==null)?negotiationStatus.dueDateStatus: "";

                      if(budgetStatus=="NEGOTIATE" && dueDateStatus=="NEGOTIATE" ){
                        negoOn = 0; // make budget tab active
                      }
                      else if(budgetStatus=="NEGOTIATE" && dueDateStatus=="AGREE" ){
                        negoOn = 0; // make budget tab active
                      }
                      else if(budgetStatus=="AGREE" && dueDateStatus=="NEGOTIATE" ){
                        negoOn = 1; // make due date tab active
                      }
                       else if(budgetStatus=="AGREE" && dueDateStatus=="AGREE" ){
                        negoOn = 0; // make budget tab active
                      }




                    }
                    else if(taskOrSubTask=="SWSTASK"){
                      isTermsAcceptedByLP  = (negotiationStatus && negotiationStatus.termsAcceptanceStatus!==undefined && negotiationStatus.termsAcceptanceStatus.isTermsAcceptedByLP!==undefined)? negotiationStatus.termsAcceptanceStatus.isTermsAcceptedByLP: false;

                      assignedTo =  (negotiationStatus && negotiationStatus.assignedTo!==undefined && negotiationStatus.assignedTo!==null)?negotiationStatus.assignedTo: null;

                      budgetStatus = (negotiationStatus && negotiationStatus.budgetStatus!==undefined && negotiationStatus.budgetStatus!==null)?negotiationStatus.budgetStatus: "";

                      dueDateStatus =  (negotiationStatus && negotiationStatus.dueDateStatus!==undefined && negotiationStatus.dueDateStatus!==null)?negotiationStatus.dueDateStatus: "";

                      if(budgetStatus=="NEGOTIATE" && dueDateStatus=="NEGOTIATE" ){
                        negoOn = 0; // make budget tab active
                      }
                      else if(budgetStatus=="NEGOTIATE" && dueDateStatus=="AGREE" ){
                        negoOn = 0; // make budget tab active
                      }
                      else if(budgetStatus=="AGREE" && dueDateStatus=="NEGOTIATE" ){
                        negoOn = 1; // make due date tab active
                      }
                       else if(budgetStatus=="AGREE" && dueDateStatus=="AGREE" ){
                        negoOn = 0; // make budget tab active
                      }
                      
                    }



                }
                
                
              }
              else if(sharedData.other!==undefined && sharedData.other.negoChatBellNoti!==undefined){

                let negoChatBellNoti = sharedData.other.negoChatBellNoti; 
                taskOrSubTask =   negoChatBellNoti.taskOrSubTask;
                taskIdOrSubTaskId =   negoChatBellNoti.taskIdOrSubTaskId;
                lpUserId =   negoChatBellNoti.lpUserId;
                lpUserName =   negoChatBellNoti.lpUserName;
                ccUserId =   negoChatBellNoti.ccUserId;
                isTermsAcceptedByLP =   negoChatBellNoti.isTermsAcceptedByLP;
                isTaskOfferReceived =   negoChatBellNoti.isTaskOfferReceived;
                assignedTo =   negoChatBellNoti.assignedTo;
                budgetStatus =   negoChatBellNoti.budgetNegotiationStatus;
                dueDateStatus =   negoChatBellNoti.dueDateNegotiationStatus;

                assignRel = negoChatBellNoti.assignRel; 

                if(budgetStatus=="NEGOTIATE" && dueDateStatus=="NEGOTIATE" ){
                  negoOn = 0; // make budget tab active
                }
                else if(budgetStatus=="NEGOTIATE" && dueDateStatus=="AGREE" ){
                  negoOn = 0; // make budget tab active
                }
                else if(budgetStatus=="AGREE" && dueDateStatus=="NEGOTIATE" ){
                  negoOn = 1; // make due date tab active
                }
                 else if(budgetStatus=="AGREE" && dueDateStatus=="AGREE" ){
                  negoOn = 0; // make budget tab active
                }

                //console.log("1111 negoChatBellNoti =>>>>>> assignRel=>>",assignRel,negoChatBellNoti);

              }
        
        }



          }

          // call list api         
          let paramObj = {"pageNum":1,"pageNumArTrack": [], "totalNumberOfPages":1,"taskIdOrSubTaskId":taskIdOrSubTaskId,"taskOrSubTask":taskOrSubTask,"lpUserId":lpUserId,"lpUserName":lpUserName,"ccUserId":ccUserId,"negoOn":negoOn,"isTermsAcceptedByLP":isTermsAcceptedByLP,"isTaskOfferReceived":isTaskOfferReceived,"assignedTo":assignedTo,"notificationIdAr":[],"lastCommentId":0,"scrollToNewNotiMsgFlag": false, "tabDisFlag": true, "assignRel":assignRel};
          this.isComponentLoaded && this.setState(paramObj,()=>{
            //console.log(" Helloooo --------=>>>",this.state);
            this.callServiceToFetchNegoChatRespList(paramObj,"*****called from componentDidMount**** ");

          });
        
        },1005);
        
      }

        

    }

    trackNegoChatListOnRealTimeNoti = (paramObj) =>{

      //console.log(" ===== REaltime track paramObj=>>>>>>",paramObj);

      let notiType =   (paramObj.notiType!==undefined && paramObj.notiType) ? paramObj.notiType:"";
      let taskId =  (paramObj.taskId!==undefined && paramObj.taskId) ? paramObj.taskId:0;
      let notificationId =  (paramObj.notificationId!==undefined && paramObj.notificationId) ? paramObj.notificationId:0;
      let notifiSenderId =  (paramObj.notifiSenderId!==undefined && paramObj.notifiSenderId) ? paramObj.notifiSenderId:0;
      let notificationIdAr = [...this.state.notificationIdAr];
      

      //console.log("11111 taskOrSubTask=>>>>",this.state.taskOrSubTask," taskIdOrSubTaskId==>",this.state.taskIdOrSubTaskId," taskId=====>",taskId," notiType==>>",notiType," notificationId===>>");

      // if single task and single task id matches with noti task id
      if(this.state.taskOrSubTask=="STASK" && (this.state.taskIdOrSubTaskId==taskId && this.state.lpUserId==notifiSenderId) ){
        if(notiType=="TASK_TERMS"){
          let otherParam = {"calledFrom":"trackNegoChatListOnRealTimeNoti"};
          // reload chat list
          this.reloadDataOfChatList(otherParam);
        }
        else if(notiType=="TASK_ACCEPTED"){

          let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;
          let chkNotiIdPres = notificationIdAr.indexOf(notificationId);
           if(chkNotiIdPres==-1){ 
             
            notificationIdAr.push(notificationId);
            this.isComponentLoaded && this.setState({"notificationIdAr":notificationIdAr},()=>{
              if(sharedData.leftTabToActive==="PROFESSIONALTASKNEGOTIATIONCHAT"){
                  console.log("TASK_ACCEPTED"," === realtime called from trackNegoChatListOnRealTimeNoti from nego=====");
                  // update the response of task details in context
                
                  sharedData.leftTabToActive = "MONITORTASK";
                  sharedData.purpose = "VIEW_RESPONSE_LIST";
                  sharedData.other.triggeredFrom = "PROFESSIONAL_INTERESTED_LIST";           
      
                  let paramObjData = {"data":{"taskId":this.state.taskIdOrSubTaskId}};      
                  let param = {"paramObjData":paramObjData,"paramsCntxt":sharedData,"calledFrom":"trackNegoChatListOnRealTimeNoti"};
                //  console.log("-------TASK_ACCEPTED "," getTaskDetailData param=>>>>",param);
                  this.getTaskDetailData(param);
                }
              }); 
              
            }
            
         
        }
        else if(notiType=="TASK_DECLINED"){
          // update the response of task details in context
          let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;  
          let chkNotiIdPres = notificationIdAr.indexOf(notificationId);
           if(chkNotiIdPres==-1){  

            notificationIdAr.push(notificationId);
            this.isComponentLoaded && this.setState({"notificationIdAr":notificationIdAr},()=>{
              if(sharedData.leftTabToActive==="PROFESSIONALTASKNEGOTIATIONCHAT"){
                sharedData.leftTabToActive = "PROFESSIONALSINTERESTED";
                sharedData.purpose = "VIEW_RESPONSE_LIST";         

                let paramObjData = {"data":{"taskId":this.state.taskIdOrSubTaskId}};      
                let param = {"paramObjData":paramObjData,"paramsCntxt":sharedData,"calledFrom":"trackNegoChatListOnRealTimeNoti"};
                // console.log("-------TASK_DECLINED "," getTaskDetailData param=>>>>",param);
                this.getTaskDetailData(param);
            }
            }); 


              
            }
            
       }
       else if(notiType=="NEGOTIATION"){
            let chat =  (paramObj.chat!==undefined && paramObj.chat) ? paramObj.chat:null;
            if(chat && notificationId){
              
              let chkNotiIdPres = notificationIdAr.indexOf(notificationId);
              let assignRel = (paramObj.assignRel!==undefined && paramObj.assignRel) ? paramObj.assignRel:null;
              //console.log("222222  NEGOTIATION chkNotiIdPres=>>>",chkNotiIdPres,notificationId,notificationIdAr);
              if(chat.budget && chat.budgetCurrency && this.state.negoOn==0){
                // if currently budget tab is active & noti is received on budget
                if(chkNotiIdPres==-1){
                 
                  notificationIdAr.push(notificationId);
                  //console.log("222 333===Scenario if currently budget tab is active & noti is received on budget=>>> notificationIdAr",notificationIdAr, " paramObj=>>>",paramObj);
                  this.isComponentLoaded && this.setState({"notificationIdAr":notificationIdAr,"assignRel":assignRel},()=>{
                    paramObj["calledFrom"]="REAL_TIME_NOTIFICATION_TRACK";
                    this.pushChatToList(paramObj);
                  });
                  
                }
                
              }
              else if(chat.dueDate && chat.dueDateInMillis && this.state.negoOn==1){
                // if currently due date tab is active & noti is received on due date
                if(chkNotiIdPres==-1){
                  notificationIdAr.push(notificationId);
                  //console.log("222 444===if currently due date tab is active & noti is received on due date=>>> updatedNotiIdDudNegArNow",updatedNotiIdDudNegArNow, " paramObj=>>>",paramObj);
                  this.isComponentLoaded && this.setState({"notificationIdAr":notificationIdAr},()=>{
                    paramObj["calledFrom"]="REAL_TIME_NOTIFICATION_TRACK";
                    this.pushChatToList(paramObj);
                  });
                }
              }
            }
            
            
         
        }
      else if(notiType=="CLOSE_SPLITSCREEN"){
          //this.trackNotiForCloseSplit(paramObj);
        }

      }
      

      
    }

    // trackNotiForCloseSplit = (otherParam) =>{
     
    //   let notiType =  otherParam.notiType!==undefined? otherParam.notiType: "";
    //   let trigerredFrom =  otherParam.trigerredFrom!==undefined? otherParam.trigerredFrom: ""; 
    //   if(notiType=="CLOSE_SPLITSCREEN"){
    //       let closeSplitHeaderCallFrm = ["NEGOTIATION_CHAT_LIST"];
    //       if(closeSplitHeaderCallFrm.indexOf(trigerredFrom)!=-1){
    //               console.log("*******trigerredFrom=>>",trigerredFrom);
    //       }
  
  
    //   }
    // }

    pushChatToList = (paramObj) =>{
      
      let chat =  (paramObj.chat!==undefined && paramObj.chat) ? paramObj.chat:null;    
      let budgetNegotiationStatus = (paramObj.budgetNegotiationStatus!==undefined && paramObj.budgetNegotiationStatus) ?paramObj.budgetNegotiationStatus:"";
      let dueDateNegotiationStatus = (paramObj.dueDateNegotiationStatus!==undefined && paramObj.dueDateNegotiationStatus) ?paramObj.dueDateNegotiationStatus:"";
      let conclusionMessage = (paramObj.conclusionMessage!==undefined && paramObj.conclusionMessage) ?paramObj.conclusionMessage:""; 
      //let notiType =   (paramObj.notiType!==undefined && paramObj.notiType) ? paramObj.notiType:"";
      let calledFrom =   (paramObj.calledFrom!==undefined && paramObj.calledFrom) ? paramObj.calledFrom:"";

      let prevData = [...this.state.listData]; 

      let fndObj = findElement(prevData,"id",chat.id); 
      if(fndObj===null){

            prevData.push(chat);
            let updatedRecNow = prevData;

            let scrollToNewNotiMsgFlag = true;
            let scobj = this.scrollerRef.current;
            let scrollTop = 0;
            let scrollHeight = 0;
            let clientHeight = 0;

            if(scobj!==null){
              // scrollTop = scobj.getValues().scrollTop;
              // scrollHeight = scobj.getScrollHeight();
              // clientHeight = scobj.getClientHeight();
            }

          

            if(calledFrom=="REAL_TIME_NOTIFICATION_TRACK" ){
              // if user is at the bottom so no need to show new msg arrived
              //console.log(" scrollHeight=>",scrollHeight," clientHeight==>",clientHeight, " scrollTop=>>",scrollTop, "(scrollHeight-clientHeight)==>",(scrollHeight-clientHeight));
              
              // if(((scrollHeight-clientHeight)== scrollTop)){
              //   scrollToNewNotiMsgFlag = false;
              // }

              if(this.scrollerRef.current.scrollTop>=0 && this.scrollerRef.current.scrollTop <= 100 ){
                scrollToNewNotiMsgFlag = false;
              }

            }
            else if(calledFrom=="NEGOTIATION_POSTED" ){
              scrollToNewNotiMsgFlag = false;
            }


            //console.log(" hello pushChatToList  prevData=>>",prevData, " updatedRecNow==>>>",updatedRecNow);
          
            this.isComponentLoaded && this.setState({"listData":updatedRecNow,"scrollToNewNotiMsgFlag":scrollToNewNotiMsgFlag,"negoBudgetClickFlag":false,"negoDateClickFlag":false,"lpUserLatestChatRecord":null,"budgetInput":"", "dueDateInput":null, "budgetNegotiationStatus":budgetNegotiationStatus,
            "dueDateNegotiationStatus":dueDateNegotiationStatus,
            "conclusionMessage":conclusionMessage},()=>{
              //console.log("****** real time updated state listData=>>>>",this.state.listData," chat=>>",chat);

            });
        }



    }

    chooseNegoTab  = (tabOptn,otherParam) =>{

       let stateData = {...this.state};
       if(tabOptn=="BUDGET"){

          stateData.negoOn = 0;
          stateData.negoOnBudgetSend = false;
          stateData.budgetDueDateCommonMsg = "";
          stateData.budgetDueDateCommonMsgErr = "";
          stateData.budgetErMsg = "";

          this.isComponentLoaded &&  this.setState(stateData,()=>{

            // reload chat list
            this.reloadDataOfChatList( {"calledFrom":"chooseNegoTab"});


          });
       }
       else if(tabOptn=="DATE"){
          stateData.negoOn = 1;
          stateData.negoOnDateSend = false;
          stateData.budgetDueDateCommonMsg = "";
          stateData.budgetDueDateCommonMsgErr = "";
          stateData.dueDateErMsg = "";
          this.isComponentLoaded &&  this.setState(stateData,()=>{

                // reload chat list
                this.reloadDataOfChatList( {"calledFrom":"chooseNegoTab"});
          });
         
       }
    }

   

    
    nowPerformAction = (keyOptn,otherParam) => {

      let keyAction = otherParam.keyAction?otherParam.keyAction:"";

      if(keyOptn=="BUDGET"){
          switch(keyAction){
            case "NEGOTIATE":
              let lpUserLatestChatRecord1 = otherParam.lpUserLatestChatRecord?otherParam.lpUserLatestChatRecord:null;

              this.isComponentLoaded && this.setState(
                { "negoBudgetClickFlag": true,
                  "lpUserLatestChatRecord":lpUserLatestChatRecord1 
                },()=>{
                    //console.log.log("now from nowPerformAction for BUDGET NEGOTIATE state=>>",this.state);

              });
              break;
            case "AGREE":
                let lpUserLatestChatRecord2 = otherParam.lpUserLatestChatRecord?otherParam.lpUserLatestChatRecord:null;
  
                this.isComponentLoaded && this.setState(
                  { 
                    "lpUserLatestChatRecord":lpUserLatestChatRecord2 
                  },()=>{
                     // console.log("now from nowPerformAction for BUDGET AGREE state=>>",this.state);

                    this.chkBeforeSend({"for":"BUDGET","action":"AGREE"});
  
                });
                break;
            case "REJECT":
                let lpUserLatestChatRecord3 = otherParam.lpUserLatestChatRecord?otherParam.lpUserLatestChatRecord:null;
  
                this.isComponentLoaded && this.setState(
                  { 
                    "lpUserLatestChatRecord":lpUserLatestChatRecord3 
                  },()=>{
                      //console.log.log("now from nowPerformAction for BUDGET REJECT state=>>",this.state);
                      this.chkBeforeSend({"for":"BUDGET","action":"REJECT"});
  
                });
                break;
                
            

          }      

      }
      else if(keyOptn=="DUE_DATE"){
        switch(keyAction){
          case "NEGOTIATE":
            let lpUserLatestChatRecord11 = otherParam.lpUserLatestChatRecord?otherParam.lpUserLatestChatRecord:null;            
            this.isComponentLoaded && this.setState({ 
              "negoDateClickFlag": true, "lpUserLatestChatRecord":lpUserLatestChatRecord11
              },()=>{
              //console.log.log("now from nowPerformAction for DUE_DATE NEGOTIATE state=>>",this.state);

              });
            break;
          case "AGREE":
            let lpUserLatestChatRecord22 = otherParam.lpUserLatestChatRecord?otherParam.lpUserLatestChatRecord:null;            
            this.isComponentLoaded && this.setState({ 
              "lpUserLatestChatRecord":lpUserLatestChatRecord22
              },()=>{
              //console.log.log("now from nowPerformAction for DUE_DATE AGREE state=>>",this.state);
              this.chkBeforeSend({"for":"DUE_DATE","action":"AGREE"});

              });
            break;
          case "REJECT":
            let lpUserLatestChatRecord33 = otherParam.lpUserLatestChatRecord?otherParam.lpUserLatestChatRecord:null;            
            this.isComponentLoaded && this.setState({ 
               "lpUserLatestChatRecord":lpUserLatestChatRecord33
              },()=>{
              //console.log.log("now from nowPerformAction for DUE_DATE REJECT state=>>",this.state);
              this.chkBeforeSend({"for":"DUE_DATE","action":"REJECT"});

              });
            break;

        }     
      }
      else if(keyOptn=="BUDGETINPUTCHANGE"){
        // let budgetInputData = otherParam.budgetInput?otherParam.budgetInput:"";
        // this.isComponentLoaded && this.setState({"budgetInput":budgetInputData});
        let budgetInputData = otherParam.budgetInput;
        if(budgetInputData){
          if((validatePositiveInputNumber({"data":otherParam.budgetInput}))){              
            budgetInputData = otherParam.budgetInput;
                       
          }
          else{
            budgetInputData = this.state.budgetInput; 
          }
        }          
        this.isComponentLoaded && this.setState({"budgetInput":budgetInputData});
      }
      
    }

    trackChangeOfNewNegoMessageBox = (paramObj) =>{
      let keyName = paramObj.keyName?paramObj.keyName:"";
      let keyValue = paramObj.keyValue?paramObj.keyValue:"";

      if(keyName=="budgetDueDateCommonMsg"){
          this.isComponentLoaded && this.setState({"budgetDueDateCommonMsg":keyValue});

      }


  }

    custValidate = (paramObj) =>{
      let forData = paramObj.for? paramObj.for :""; // BUDGET DUE_DATE
      let budgetDueDateCommonMsgErr = "";
      let budgetErMsg = "";
      let dueDateErMsg = "";
      let errTrackAr = [];
      
      if(this.state.budgetDueDateCommonMsg){
      //   if(emailIdPresenceCheck(this.state.budgetDueDateCommonMsg)){
      //     budgetDueDateCommonMsgErr = "Message cannot contain emailid."; 
      //     errTrackAr.push(budgetDueDateCommonMsgErr);                    
 
      //  }
      //  else if(phonePresenceCheck(this.state.budgetDueDateCommonMsg)){
      //    budgetDueDateCommonMsgErr = "Message cannot contain phone number."; 
      //    errTrackAr.push(budgetDueDateCommonMsgErr);                        
 
      //  }
       if(this.state.budgetDueDateCommonMsg && this.state.budgetDueDateCommonMsg.length>120){
         budgetDueDateCommonMsgErr = "Message cannot be more than 120 characters.";  
         errTrackAr.push(budgetDueDateCommonMsgErr);                       
 
       }
      }

      if(forData=="BUDGET"){
        let budget = paramObj.budget;
        let tt = validatePositiveInputNumber({"data":budget});
                
        if( validateBlankSpace(budget)) {
          budgetErMsg = "Budget is required.";  
          errTrackAr.push(budgetErMsg);  
        }
        else if(!(validatePositiveInputNumber({"data":budget}))){
          budgetErMsg = "Budget should be postive number.";  
          errTrackAr.push(budgetErMsg);
          
        }
        
        

      }
      else if(forData=="DUE_DATE"){
        let dueDate = paramObj.dueDate;        
        if(!(dueDate) || (validateBlankSpace(dueDate)==true)){
          
          dueDateErMsg = "Due date is required.";  
          errTrackAr.push(dueDateErMsg);
        }
        
      }
      

      this.setState({"budgetDueDateCommonMsgErr":budgetDueDateCommonMsgErr,"budgetErMsg":budgetErMsg,"dueDateErMsg":dueDateErMsg}); 
      if(errTrackAr.length==0){
        return true;

      }else{
        return false;
      }
    }

    chkBeforeSend = (otherParam) =>{

      if(otherParam.for=="BUDGET"){
          let previousChatId = (this.state.lpUserLatestChatRecord && this.state.lpUserLatestChatRecord.id)? this.state.lpUserLatestChatRecord.id: 0;

          let budgetCurrency = (this.state.lpUserLatestChatRecord && this.state.lpUserLatestChatRecord.budgetCurrency)? this.state.lpUserLatestChatRecord.budgetCurrency: "";

          let budgetInput = "";
          if(otherParam.action=="NEGOTIATE"){
            budgetInput = this.state.budgetInput;
            // check for validation 
            let chkvalid = this.custValidate({"for":"BUDGET","budget":budgetInput});
            if(!(chkvalid)){
                return false;

            }
          }else{
            budgetInput = (this.state.lpUserLatestChatRecord && this.state.lpUserLatestChatRecord.budget)? this.state.lpUserLatestChatRecord.budget: "";
          }

    
    
          let taskOrSubTask = this.state.taskOrSubTask;
          let taskIdOrSubTaskId = this.state.taskIdOrSubTaskId;
          let lpUserId = this.state.lpUserId;
          let chatType = this.state.negoOn==0?"BUDGET":"DUE_DATE";
          let budgetUpdationDescription =  this.state.budgetDueDateCommonMsg?this.state.budgetDueDateCommonMsg:"";
            
          
          let chatReplyData = {
            "status":otherParam.action,
            "previousChatId":previousChatId,
            "budget":budgetInput,
            "budgetCurrency":budgetCurrency,
            "budgetUpdationDescription":budgetUpdationDescription
          };
    
          let paramToPost = {"data":{
                "chatReplyData":chatReplyData,
                "taskOrSubTask":taskOrSubTask,
                "taskIdOrSubTaskId":taskIdOrSubTaskId,
                "lpUserId":lpUserId,
                "chatType":chatType,

                
    
          }};
          //console.log.log("**** from chkBeforeSendNego this.state now =>",this.state,"paramToPost==>",paramToPost);
    
          this.isComponentLoaded && this.setState({"negoOnBudgetSend":true,"budgetInput":"","dueDateInput":null,"budgetErMsg":"",
          "dueDateErMsg":"","budgetDueDateCommonMsg":"",
          "budgetDueDateCommonMsgErr":""},()=>{

            setTimeout(()=>{
                   // send reply to lp user
                  this.sendReplyToChat(paramToPost,otherParam);
            },1900);

          });

         
      }
      else if(otherParam.for=="DUE_DATE"){

        let previousChatId = (this.state.lpUserLatestChatRecord && this.state.lpUserLatestChatRecord.id)? this.state.lpUserLatestChatRecord.id: 0;

          let budgetCurrency = (this.state.lpUserLatestChatRecord && this.state.lpUserLatestChatRecord.budgetCurrency)? this.state.lpUserLatestChatRecord.budgetCurrency: "";

          let dueDateInput = "";
          if(otherParam.action=="NEGOTIATE"){
            dueDateInput = this.state.dueDateInput;
             // check for validation 
             let chkvalid = this.custValidate({"for":"DUE_DATE","dueDate":dueDateInput});
             if(!(chkvalid)){
                 return false;

             }
          }else{
            dueDateInput = (this.state.lpUserLatestChatRecord && this.state.lpUserLatestChatRecord.dueDateInMillis)? this.state.lpUserLatestChatRecord.dueDateInMillis: "";
          }

    
    
          let taskOrSubTask = this.state.taskOrSubTask;
          let taskIdOrSubTaskId = this.state.taskIdOrSubTaskId;
          let lpUserId = this.state.lpUserId;
          let chatType = this.state.negoOn==0?"BUDGET":"DUE_DATE";
          let budgetUpdationDescription =  this.state.budgetDueDateCommonMsg?this.state.budgetDueDateCommonMsg:"";
          
          let chatReplyData = {
            "status":otherParam.action,
            "previousChatId":previousChatId,
            "dueDate":dueDateInput,
            "dueDateUpdationDescription":budgetUpdationDescription
          };
    
          let paramToPost = {"data":{
                "chatReplyData":chatReplyData,
                "taskOrSubTask":taskOrSubTask,
                "taskIdOrSubTaskId":taskIdOrSubTaskId,
                "lpUserId":lpUserId,
                "chatType":chatType,
    
          }};
          //console.log.log("**** from chkBeforeSendNego this.state now =>",this.state,"paramToPost==>",paramToPost);
          
          this.isComponentLoaded && this.setState({"negoOnDateSend":true,"budgetInput":"","dueDateInput":null,"budgetErMsg":"",
          "dueDateErMsg":"","budgetDueDateCommonMsg":"",
          "budgetDueDateCommonMsgErr":""},()=>{

            setTimeout(()=>{
                   // send reply to lp user
                  this.sendReplyToChat(paramToPost,otherParam);
            },1900);

          });
        
      }

     


    }

    sendReplyToChat = (paramToPost,otherData) =>{

      // if(otherData.for=="BUDGET"){
      //   this.isComponentLoaded && this.setState({"negoOnBudgetSend":true});
      // }else{
      //   this.isComponentLoaded && this.setState({"negoOnDateSend":true});
      // }

      let stcservice = new ChatReplyService();
      stcservice.makeChatReply(paramToPost).then(data =>{
            if(data.status==200){
                let respData ={};
                if(data.data.entity!==undefined){
                  respData = data.data.entity;
                  let chat = (respData.chat!==undefined && respData.chat) ?respData.chat:null;
                  let budgetNegotiationStatus = (respData.budgetNegotiationStatus!==undefined && respData.budgetNegotiationStatus) ?respData.budgetNegotiationStatus:"";
                  let dueDateNegotiationStatus = (respData.dueDateNegotiationStatus!==undefined && respData.dueDateNegotiationStatus) ?respData.dueDateNegotiationStatus:"";
                  let conclusionMessage = (respData.conclusionMessage!==undefined && respData.conclusionMessage) ?respData.conclusionMessage:"";


                  // reload chat list
                  //this.reloadDataOfChatList({"calledFrom":"sendReplyToChat"});
                  this.pushChatToList({
                    "calledFrom":"NEGOTIATION_POSTED",
                    "chat":chat,
                    "budgetNegotiationStatus":budgetNegotiationStatus,
                    "dueDateNegotiationStatus":dueDateNegotiationStatus,
                    "conclusionMessage":conclusionMessage
                  }
                    );

                  
            }
        }
        }).catch((err)=>{
                
                let errStatusCodeAr = [400,500]; // allowed error status code
                if(err.response && err.response.data){
                    let erRespData = err.response.data;
                    if(errStatusCodeAr.indexOf(erRespData.status) !== -1){
                        let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                        console.log(errMsg);

                        let notiMsg = errMsg;
                        notify(notiMsg,true,{
                        "successMsg":0,
                        "customWrapClass":"customerrmidlass for budgetduedatenegomsgclass",
                        "showCloseButton": false,

                        "dismiss": {
                        "duration": 2500,
                        }

                        });
                                         
                }
                }
        }).finally(()=>{

          if(otherData.for=="BUDGET"){
              this.isComponentLoaded && this.setState({"negoOnBudgetSend":false});
          }else{
            this.isComponentLoaded && this.setState({"negoOnDateSend":false});
          }
                     

        });

    }

    loadfooterOfBudgetNego = () => {


      let datasPassForAssignObj = {};
      datasPassForAssignObj = this.state.assignRel;

      return (
        <>
        <CustomMessageBar />
               
        
        
              <div className="SlideS_bottombg flexbox-item Slide_chatBottomBg SlideS_bottombg_gray SlideS_bottombg_stickyFooterRightPart SlideS_bottombg_stickyFooterRightPart_budgetDueDate">
               
                <div className="chat_bottom_left"> 

                {(this.state.negoBudgetClickFlag===false) && (this.state.assignedTo===null && this.state.budgetNegotiationStatus!="AGREE" && (<p>Select a response from above to continue</p>))}

                { this.state.negoBudgetClickFlag===true && (
                  <div className="chat_bottom_inputBg">
                    {/*  budget input grup start */}
                    
                    <div className="chatFoot_inputGrp chatFoot_textMsg_grp_for_reNego">
                        <div className="cht_input_grp cht_input_grp_reNego cht_input_grp_reNego_budget">
                          <div className={`cht_input_grp_reNego_block ${this.state.budgetErMsg?"form_group_error":""} `}>
                          {this.state.proposedBudgetCurrency=="INR" && (
                          <img src={getBaseUrl()+"assets/images/rupee-icon.png"}  />
                          )}

                          {this.state.proposedBudgetCurrency=="USD" && (
                            <img src={getBaseUrl()+"assets/images/dollar-icon.png"}  />
                          )}  

                          {this.state.proposedBudgetCurrency=="EUR" && (
                            <img src={getBaseUrl()+"assets/images/pound-icon.png"}  />
                          )}
                          
                            <input type="text" onChange={(evn)=>{
                              evn.preventDefault();
                              let budgetInputData = evn.target.value;
                              this.nowPerformAction("BUDGETINPUTCHANGE",{"budgetInput":budgetInputData})
                          }} maxLength="10" value={this.state.budgetInput} placeholder="Enter new amount to negotitate" />
                            <span className="top_sug_left">
                              <span className="input_tooltip btn_tooltip" aria-label="Budget Amount" data-balloon-pos="up">
                                <i className="fa fa-question-circle" />
                              </span>
                            </span>
                          </div>
                        <span className="error_span">{this.state.budgetErMsg}</span>
                        </div>
                        <div className="cht_input_grp cht_input_grp_reNego cht_input_grp_reNego_msg">
                          <div className={`fileUpMsg_input ${this.state.budgetDueDateCommonMsgErr?"form_group_error":""} `}>
                            <div className="cht_input_grp_reNego_block form_group_error---">
                              <textarea name="budgetDueDateCommonMsg"  value={this.state.budgetDueDateCommonMsg} onChange={(ev)=>{ev.preventDefault();
                                                      this.trackChangeOfNewNegoMessageBox({"keyName":"budgetDueDateCommonMsg","keyValue":ev.target.value})
                                                      }} placeholder="(Optional) Enter message upto 120 characters only, Sharing of phone no. or personal details will disqualify you for the platform" 
                              />
                              <span className="top_sug_left">
                                <span className="input_tooltip btn_tooltip" aria-label="Optional Message" data-balloon-pos="up">
                                  <i className="fa fa-question-circle" />
                                </span>
                              </span>
                            </div>
                                                    <span className="error_span">{this.state.budgetDueDateCommonMsgErr} </span>
                          </div>
                        </div>
                      </div>
                    
                    {/*  budget input grup start */}
                  
                    
                  </div>
                                                      
                  )}
                </div>
               

                                                      
                <div className="s_footer_btnbg">
                  { this.state.negoBudgetClickFlag===true && (
                    <>
                      <button onClick={(evn)=>{
                          evn.preventDefault();
                          this.callBackBtn();
                      }} className="btn_appearance_none blank_btn">
                        <span>Cancel</span>
                      </button>
                      <button disabled={this.state.negoOnBudgetSend} onClick={(evn)=>{
                          evn.preventDefault();
                          this.chkBeforeSend({"for":"BUDGET","action":"NEGOTIATE"});
                      }} className="btn_appearance_none sub_grd_btn">
                        <span>Send</span>
                      </button>
                      </>
                  )}

                  {this.state.negoBudgetClickFlag===false && (this.state.budgetNegotiationStatus=="AGREE" && this.state.dueDateNegotiationStatus=="AGREE") && (this.state.isTaskOfferReceived===false) && (this.state.assignedTo===null ) && (
                          
                          <OfferTaskButton custclassname="btn_appearance_none sub_grd_btn" controltype="BUTTON" 
                      controlnametext="Offer Task"
                      datas={ datasPassForAssignObj }
                      callbackfunc={this.offerTaskButtonCallback}  />

                        )}

               
              </div>
              
              </div>
    

        
                
        </>
      );
      
    }

    loadfooterOfDateNego = () => {

      const defaultDateData = this.state.dueDateInput;//new Date();
      const defaultDate = defaultDateData!==null?getDateObjFromMilliseconds(defaultDateData):null;

      let datasPassForAssignObj = {};
      datasPassForAssignObj = this.state.assignRel;

      return (
        <>
        <CustomMessageBar />
                      
            <div className="SlideS_bottombg flexbox-item Slide_chatBottomBg SlideS_bottombg_gray SlideS_bottombg_stickyFooterRightPart SlideS_bottombg_stickyFooterRightPart_budgetDueDate">
                        <div className="chat_bottom_left">
                          {/* <p>Select a response from above to continue</p> */}

                          {this.state.negoDateClickFlag==false && (this.state.assignedTo===null && this.state.dueDateNegotiationStatus!=="AGREE" && (<p>Select a response from above to continue</p>))
                          }
                          
                          {this.state.negoDateClickFlag==true && (
                          <div className="chat_bottom_inputBg">
                            {/*  budget input grup start */}                        
                            {/*  budget input grup start */}
                            {/*  date input grup start */}
                            
                            <div className="chatFoot_inputGrp chatFoot_textMsg_grp_for_reNego">
                                <div className="cht_input_grp cht_input_grp_reNego cht_input_grp_reNego_dueDate">
                                    <div className={`cht_input_grp_reNego_block ${this.state.dueDateErMsg?"form_group_error":""} `}>
                                    <div className="cht_btm_icon">
                                                          <img src="assets/images/calendar-black.png" alt="" />
                                    </div>
                                    <div className="cht_input_grp cht_input_grp_date">
                                                          <div className="date" id="sandbox-container">
                                                          {this.customDate(defaultDate)}
                                                          
                                                              {/* <input type="text" placeholder="Select Due Date" /> */}
                                                          
                                                          </div>
                                    </div>
                                  
                                    <span className="top_sug_left">
                                      <span className="input_tooltip btn_tooltip" aria-label="Due Date" data-balloon-pos="up">
                                        <i className="fa fa-question-circle" />
                                      </span>
                                    </span>
                                  </div>
                                <span className="error_span">{this.state.dueDateErMsg}</span>
                                </div>
                                <div className="cht_input_grp cht_input_grp_reNego cht_input_grp_reNego_msg">
                        <div className={`fileUpMsg_input ${this.state.budgetDueDateCommonMsgErr?"form_group_error":""} `}>
                          <div className="cht_input_grp_reNego_block form_group_error---">
                            <textarea name="budgetDueDateCommonMsg"  value={this.state.budgetDueDateCommonMsg} onChange={(ev)=>{ev.preventDefault();
                                                    this.trackChangeOfNewNegoMessageBox({"keyName":"budgetDueDateCommonMsg","keyValue":ev.target.value})
                                                    }} placeholder="(Optional) Enter message upto 120 characters only, Sharing of phone no. or personal details will disqualify you for the platform" 
                             />
                            <span className="top_sug_left">
                              <span className="input_tooltip btn_tooltip" aria-label="Optional Message" data-balloon-pos="up">
                                <i className="fa fa-question-circle" />
                              </span>
                            </span>
                          </div>
                                                  <span className="error_span">{this.state.budgetDueDateCommonMsgErr} </span>
                        </div>
                      </div>
                            </div>
                    

                            {/*  date input grup end */}
                          </div>
                          )}
                        </div>
                        <div className="s_footer_btnbg">
                        {this.state.negoDateClickFlag==true && (
                          <>
                                <button onClick={(evn)=>{
                          evn.preventDefault();
                          this.callBackBtn();
                      }} className="btn_appearance_none blank_btn">
                                  <span>Cancel</span>
                                </button>
                                <button disabled={this.state.negoOnDateSend} onClick={(evn)=>{
                          evn.preventDefault();
                          this.chkBeforeSend({"for":"DUE_DATE","action":"NEGOTIATE"});
                      }} className="btn_appearance_none sub_grd_btn">
                                  <span>Send</span>
                                </button>
                                </>

                          )}

                        {this.state.negoBudgetClickFlag===false && (this.state.budgetNegotiationStatus=="AGREE" && this.state.dueDateNegotiationStatus=="AGREE") && (this.state.isTaskOfferReceived===false) && (this.state.assignedTo===null ) && (
                          
                          <OfferTaskButton custclassname="btn_appearance_none sub_grd_btn" controltype="BUTTON" 
                      controlnametext="Offer Task"
                      datas={ datasPassForAssignObj }
                      callbackfunc={this.offerTaskButtonCallback}  />

                        )}
                        </div>
                      </div>
            
        </>
                  
      );
      
    }

    loadChatListOfDateNego = () => {
      
      return (
        <>
          <div ref={this.scrollerRef}  className="s_midbox_scroll_adjust reactTestScroll mCustomAdjustmentForStickFooter rightMidPartHeightAdjustment rightMidPartHeightAdjustment_budgetDueDate">
            {/* <Scrollbars 
                            ref={this.scrollerRef}
                            style={{ height: 675 }}                  
                            autoHeightMin={675}
                            renderView={props => <div {...props} className="view duedatenegochatlistclass"/>}
                            onScrollStop={this.trackScroll}
            > */}
              
              <div className="s_midbox_section s_midbox_section_chat">
            {/* ====   sprint - 4 chat structure start ====  */}
            {/* chat mid body start  */}
            <div className="chatTab_sec">
              <div className="chatBody_sec">
                <div className="chatBody_ulBg">  
                <div className=" text-center ">
                    {this.state.loaderFlag ? this.loadLoader() : null}
                </div>
                <ul>
                    {
                      this.state.listData.length>0 && (   Object.keys(this.state.listData).map((e, i) => (
                    <li key={i}>
                      <div className={`chatBody_box ${this.state.listData[i].fromUserId==this.state.ccUserId?"chatBody_box_gray":"chatBody_box_green"}`}>
                        <div className="chatbody_text">
                          <div className="chatbody_text_newNego">
                            <p>
                            {this.getHighlightedText(this.state.listData[i].message,this.state.listData[i].highlightContents)}
                            </p>
                          </div>
                          {(this.state.listData[i].dueDateUpdationDescription) && (
                            <div className="chatbody_text_newNego_descp_p">
                              <p>
                              {this.state.listData[i].dueDateUpdationDescription?this.state.listData[i].dueDateUpdationDescription:""}
                              </p>
                            </div>
                          )}                        
                          <div className="monichatdatetime">                          
                            {(this.state.listData[i].createdAt!==undefined && this.state.listData[i].createdAt!==null)?CustDate.custFormatDate((new Date(this.state.listData[i].createdAt)),6):""}
                          </div>
                        </div>

                        
                        <div className="chatBody_btn_sec">
                        {(this.state.listData[i].status=="NEGOTIATE") && (this.state.listData[i].fromUserId!=this.state.ccUserId) &&((this.state.listData.length-1)==i) &&(
                          <>
                          <button disabled={this.state.negoOnDateSend} onClick={(evn)=>{
                            evn.preventDefault();
                            let keyOptn = "DUE_DATE";
                            let otherParam = {"keyAction":"AGREE","lpUserLatestChatRecord":this.state.listData[i]};
                            this.nowPerformAction(keyOptn,otherParam);
                          }} className="btn_appearance_none chatBtn_agree">
                            Agree
                          </button>
                          <button onClick={(evn)=>{
                            evn.preventDefault();
                            let keyOptn = "DUE_DATE";
                            let otherParam = {"keyAction":"NEGOTIATE","lpUserLatestChatRecord":this.state.listData[i]};
                            this.nowPerformAction(keyOptn,otherParam);
                          }} className="btn_appearance_none chatBtn_nego">
                            Negotiate
                          </button>
                          <button disabled={this.state.negoOnDateSend} onClick={(evn)=>{
                            evn.preventDefault();
                            let keyOptn = "DUE_DATE";
                            let otherParam = {"keyAction":"REJECT","lpUserLatestChatRecord":this.state.listData[i]};
                            this.nowPerformAction(keyOptn,otherParam);
                          }} className="btn_appearance_none chatBtn_reject">
                            Reject
                          </button>
                          </>
                          )}
                          { (this.state.listData[i].fromUserId==this.state.ccUserId) && ((this.state.listData.length-1)==i) && (this.state.dueDateNegotiationStatus=="NEGOTIATE") &&(
                         <>
                          <button disabled={this.state.negoOnDateSend} onClick={(evn)=>{
                          evn.preventDefault();
                          let keyOptn = "DUE_DATE";
                          let otherParam = {"keyAction":"NEGOTIATE","lpUserLatestChatRecord":this.state.listData[i]};
                          this.nowPerformAction(keyOptn,otherParam);
                          }} className="btn_appearance_none chatBtn_reNegotiate">
                          Update Due Date
                          </button>
                          </>
                        )}
                          <div className="clearboth" />
                        </div>
                      
                      
                      
                      </div>
                    </li>
                      )
                      ))
                  }
                    
                    
                  
                  </ul>
                  
                  
                  {/* <div className="chat_refrash_btn">
                    <button onClick={(evn)=>{
                      evn.preventDefault();
                      
                      this.reloadDataOfChatList({"calledFrom":"refreshButton"});
                      
                    }} className={`btn_appearance_none rf_btn ${this.state.loaderFlag==true?"active":""}` }>
                      <i className="fa fa-refresh" aria-hidden="true" />
                    </button>
                  </div> */}
                  
                                      
                  
                </div>
              </div>
            </div>
            {/* chat mid body end  */}
            {/* ====   sprint - 4 chat structure end ====  */}
          </div>
          
          
          {/* </Scrollbars> */}
          
          </div>
        
          {(this.state.conclusionMessage!==undefined && this.state.conclusionMessage!== null && this.state.conclusionMessage!==""  )&& (
            <div className="chat_bottom_msg_new_bg">
              <div className="chat_bottom_msg_new">
              <p>{this.state.conclusionMessage}
              </p>
              </div>
             
            </div>
            

          )}
                    
          {
                this.state.scrollToNewNotiMsgFlag && (
                  <>
                  <div className="chat_bottom_msg_new_bg">
                    <div onClick={(evn)=>{evn.preventDefault();
                        this.scrollDownToNewMessages({});
                    }} className="chat_bottom_msg_new">
                      <p>
                        New message received
                      </p>
                    </div>
                  </div>
                  <audio autoPlay hidden  >
                  <source  src={getBaseUrl()+"notify.mp3"} type="audio/mpeg" />          
                  </audio>
                  </>
                )
              }
              
          
          
        </>
                    
      
      );
    }

    loadChatListOfBudgetNego = () => {
      return (
        <>
        <div ref={this.scrollerRef} className="s_midbox_scroll_adjust reactTestScroll mCustomAdjustmentForStickFooter rightMidPartHeightAdjustment rightMidPartHeightAdjustment_budgetDueDate">
          {/* <Scrollbars 
                          ref={this.scrollerRef}
                          style={{ height: 675 }}                  
                          autoHeightMin={675}
                          renderView={props => <div {...props} className="view budgetnegochatlistclass"/>}
                          onScrollStop={this.trackScroll}
          > */}
            <div className="s_midbox_section s_midbox_section_chat">
          {/* ====   sprint - 4 chat structure start ====  */}
          {/* chat mid body start  */}
          <div className="chatTab_sec">
            <div className="chatBody_sec">
              <div className="chatBody_ulBg">
              <div className=" text-center ">
                  {this.state.loaderFlag ? this.loadLoader() : null}
              </div>
              <ul>
                  {
                    this.state.listData.length>0 && (   Object.keys(this.state.listData).map((e, i) => (
                  <li key={i}>
                    <div className={`chatBody_box ${this.state.listData[i].fromUserId==this.state.ccUserId?"chatBody_box_gray":"chatBody_box_green"}`}>
                      <div className="chatbody_text">
                        <div className="chatbody_text_newNego">
                          <p>                          
                          {this.getHighlightedText(this.state.listData[i].message,this.state.listData[i].highlightContents)}
                          </p>
                        </div>
                        {(this.state.listData[i].budgetUpdationDescription) && (
                        <div className="chatbody_text_newNego_descp_p">
                          <p>
                          {this.state.listData[i].budgetUpdationDescription?this.state.listData[i].budgetUpdationDescription:""}
                          </p>
                        </div>
                        )}                        
                        <div className="monichatdatetime">                          
                          {(this.state.listData[i].createdAt!==undefined && this.state.listData[i].createdAt!==null)?CustDate.custFormatDate((new Date(this.state.listData[i].createdAt)),6):""}
                        </div>
                      </div>

                      
                      <div className="chatBody_btn_sec">
                      {(this.state.listData[i].status=="NEGOTIATE") && (this.state.listData[i].fromUserId!=this.state.ccUserId) &&((this.state.listData.length-1)==i) &&(
                        <>
                        <button disabled={this.state.negoOnBudgetSend} onClick={(evn)=>{
                          evn.preventDefault();
                          let keyOptn = "BUDGET";
                          let otherParam = {"keyAction":"AGREE","lpUserLatestChatRecord":this.state.listData[i]};
                          this.nowPerformAction(keyOptn,otherParam);
                        }} className="btn_appearance_none chatBtn_agree">
                          Agree
                        </button>
                        <button onClick={(evn)=>{
                          evn.preventDefault();
                          let keyOptn = "BUDGET";
                          let otherParam = {"keyAction":"NEGOTIATE","lpUserLatestChatRecord":this.state.listData[i]};
                          this.nowPerformAction(keyOptn,otherParam);
                        }} className="btn_appearance_none chatBtn_nego">
                          Negotiate
                        </button>
                        <button disabled={this.state.negoOnBudgetSend} onClick={(evn)=>{
                          evn.preventDefault();
                          let keyOptn = "BUDGET";
                          let otherParam = {"keyAction":"REJECT","lpUserLatestChatRecord":this.state.listData[i]};
                          this.nowPerformAction(keyOptn,otherParam);
                        }} className="btn_appearance_none chatBtn_reject">
                          Reject
                        </button>   
                        </>

                        )}
                        { (this.state.listData[i].fromUserId==this.state.ccUserId) && ((this.state.listData.length-1)==i) && (this.state.budgetNegotiationStatus=="NEGOTIATE") &&(
                         <>
                          <button disabled={this.state.negoOnBudgetSend} onClick={(evn)=>{
                          evn.preventDefault();
                          let keyOptn = "BUDGET";
                          let otherParam = {"keyAction":"NEGOTIATE","lpUserLatestChatRecord":this.state.listData[i]};
                          this.nowPerformAction(keyOptn,otherParam);
                          }} className="btn_appearance_none chatBtn_reNegotiate">
                          Update Budget
                          </button>
                          </>
                        )}
                        <div className="clearboth" />
                      </div>
                    
                    
                    
                    </div>
                  </li>
                    )
                    ))
                }
                  
                  
                
                
                </ul>
                
                {/* <div className="chat_refrash_btn">
                  <button onClick={(evn)=>{
                    evn.preventDefault();
                    
                    this.reloadDataOfChatList({"calledFrom":"refreshButton"});
                    
                  }} className={`btn_appearance_none rf_btn ${this.state.loaderFlag==true?"active":""}` }>
                    <i className="fa fa-refresh" aria-hidden="true" />
                  </button>
                </div> */}
                
               
               
              </div>
            </div>
          </div>
          {/* chat mid body end  */}
          {/* ====   sprint - 4 chat structure end ====  */}
        </div>
          {/* </Scrollbars> */}

        </div>

        {(this.state.conclusionMessage!==undefined && this.state.conclusionMessage!== null && this.state.conclusionMessage!==""  )&& (
          <div className="chat_bottom_msg_new_bg">
            <div className="chat_bottom_msg_new">
              <p>{this.state.conclusionMessage}
              </p>
            </div>
            
          </div>

        )} 
         {
            this.state.scrollToNewNotiMsgFlag && (
                  <>
                  <div className="chat_bottom_msg_new_bg">
                    <div onClick={(evn)=>{evn.preventDefault();
                        this.scrollDownToNewMessages({});
                    }} className="chat_bottom_msg_new">
                      <p>
                        New message received
                      </p>
                    </div>
                  </div>
                  <audio autoPlay hidden  >
                  <source  src={getBaseUrl()+"notify.mp3"} type="audio/mpeg" />          
                  </audio>
                  </>
                )
              }

        </> 
      
      
      
      );
    }

    scrollDownToNewMessages = (other)=>{
      this.isComponentLoaded && this.setState({"scrollToNewNotiMsgFlag":false},()=>{
        // scroll bottom
        setTimeout(()=>{
        // let scobj = this.scrollerRef.current;
        //   scobj.scrollToBottom();
        // newly added 
        this.scrollToBottom();
        
        },1200);
      })
  }

    callBackBtn = () =>{
      // context share
      let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;

      let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;

      if(setShare && sharedData){
        sharedData.leftTabToActive = "PROFESSIONALSINTERESTED";
        sharedData.purpose = "VIEW_RESPONSE_LIST";
        setShare(sharedData);
        }

    }

    loadTheContent = ()=>{

      let lawyerName = this.state.lpUserName;
      let taskOrSubTask = "";
      let taskOrSubTaskName = "";
      

      let forSplitScrnCls2Inject = "";
      let datasPassForAssignObj = {};

      datasPassForAssignObj = this.state.assignRel;

    

      //********* for context starts *****
      let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;      
      if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){         

          if(sharedData.actionType=="SPLITSCREEN" ){            
            forSplitScrnCls2Inject = "margin0"
          }

          

         
      }
      //********* for context ends *****
      
      

      return (
        <>
        { /* Dashboard Slide Split Right start  */   }
        <div className={`splitscreen_area 
              ${forSplitScrnCls2Inject?forSplitScrnCls2Inject:""}`
                }>
              <div className="slideScreen_bg slideScreen_Chatbg slideScreen_bg_heightAdjust">
                  <SplitScreenLeftMenu />
                  <div className="slideScreen_rightbg flexbox-parent">
                  {/* === right slide section top start ===  */}
                  <div className="SlideS_topbg flexbox-item slide_chat_topbg">
                    <div className="slide_top_titlebg">
                      {/* <h4>Professionals Interested</h4> */}
                      <div className="slideChat_head">
                        <div className="slideChat_panel">
                          <button onClick={(evn)=>{
                            evn.preventDefault();
                            //
                            this.callBackBtn();
          

                          }} className="btn_appearance_none chatHeadBack">
                            <span>Back</span>
                          </button>
                        </div>
                        <div className="slideChat_panel">
                          <h5 className="chatHeadUser">
                            {lawyerName}
                          </h5>
                        </div>
                        <div className="slideChat_panel">
                        {/* {(this.state.budgetNegotiationStatus=="AGREE" && this.state.dueDateNegotiationStatus=="AGREE") && (this.state.isTermsAcceptedByLP==true) && (this.state.assignedTo===null )   && (
                          
                         
                          <AssignButton callbackfunc={()=>{
                            //this.reloadInterestedList({});
                          }} datas={ datasPassForAssignObj } custclassname="btn_appearance_none sub_grd_btn"  />
    

                        )} */}

                     


                        </div>
                        <div className="chatHead_calInv_btnBg">
                        <CalculateInvoice custclassnamebtn={"btn_appearance_none calInvBtn"} custclassnamebtnspan={"calInvBtn_text"} textname={"Calculate Invoice"} budget={""} budgetcurrency={"INR"} />
                            {/* <button className="btn_appearance_none calInvBtn">
                              <span className="calInvBtn_text">Calculate Invoice</span>
                            </button> */}
                        </div>

                      </div>
                      <button onClick={(evn)=>{
                            evn.preventDefault();
                            this.closeSplit({"trigerredFrom":"NEGOTIATION_CHAT_LIST"});
                            

                          }} className="btn_appearance_none slideTop_cross_btn cross">
                        <img src="assets/images/red_cross.png" alt />
                      </button>
                    </div>
                  </div>
                  {/* === right slide section top end ===  */}
                  {/* === right slide section Middlie  start ===  */}
                  <div className="SlideS_Midbg flexbox-item-grow SlideS_Midbg_chat SlideS_Midbg_heightAdjustment SlideS_Midbg_heightAdjustment_budgetDueDate">
                    {taskOrSubTask=="SWSTASK" && (
                            <div className="chatTab_topText_sec">
                            <h4 className="chatTab_top_text">{taskOrSubTaskName}</h4>
                          </div>

                    )}
                    
                    <div className="chatBody_topSec">
                      <div className="chatTab_top_btnBG">
                        <button disabled={this.state.tabDisFlag} onClick={(env)=>{
                          env.preventDefault();
                          this.chooseNegoTab("BUDGET",{});

                        }} className={`btn_appearance_none chatTabBtn ${this.state.negoOn==0?"active":""}` }>
                          Budget
                          {/* <span className="chatTab_new">New</span> */}
                        </button>
                        <button disabled={this.state.tabDisFlag} onClick={(env)=>{
                          env.preventDefault();
                          this.chooseNegoTab("DATE",{});
                          
                        }} className={`btn_appearance_none chatTabBtn ${this.state.negoOn==1?"active":""}` }>
                          Due Date
                          {/* <span className="chatTab_new">New</span> */}
                        </button>
                        <div className="clearboth" />
                      </div>
                      <CustomMessageBar keydata={"negoChatNotiK1"} />
                      <div className="chatBody_top">
                        <p className="chatBody_top_p">
                          Proposed Budget - <b>{this.state.proposedBudgetCurrency+" "+this.state.proposedBudget}</b>
                          <span className="nego_ChatScreen_tickSpan">                            
                                &nbsp;
                            {this.state.budgetNegotiationStatus=="AGREE"?
                                  (<img src={getBaseUrl()+"/assets/images/green_tick.png"} />):(<img src={getBaseUrl()+"/assets/images/orange-negotiate.png"}  />)
                            }

                          </span>
                        </p>
                        <p className="chatBody_top_p">
                          Proposed Due Date - <b>{this.state.proposedDueDate}</b>
                          <span className="nego_ChatScreen_tickSpan">
                          &nbsp; 
                            {this.state.dueDateNegotiationStatus=="AGREE"?
                                 (<img src={getBaseUrl()+"/assets/images/green_tick.png"} />):(<img src={getBaseUrl()+"/assets/images/orange-negotiate.png"}  />)
                            }
                          </span>
                        </p>
                      </div>
                    </div>
                   
                    {this.state.negoOn==0 ? this.loadChatListOfBudgetNego(): this.loadChatListOfDateNego()}

                  </div>
                  {/* === right slide section Middlie  end ===  */}
                  {/* === right slide section Footer  start ===  */}
                  {this.state.negoOn==0 ? this.loadfooterOfBudgetNego(): this.loadfooterOfDateNego()}

                  
                  {/* === right slide section Footer  end ===  */}
                </div>
              
              
              </div>

        </div>


        { /* Dashboard Slide Split Right end  */ }
        </>
      );
    }

    loadLawyerInfoPopup =  (i,otherParam)=>{

      let practiceAreas = [];
      let locations = [];
      let lawyerName = "";
      let lawyerFname = "";
      let lawyerLname = "";
      
      if(this.state.listData[i] && this.state.listData[i].userDetails!==undefined){

        lawyerFname = this.state.listData[i].userDetails.firstName!==undefined?this.state.listData[i].userDetails.firstName:"";

        lawyerLname = this.state.listData[i].userDetails.lastName!==undefined?this.state.listData[i].userDetails.lastName:"";

        lawyerName = lawyerFname+" "+lawyerLname;

        practiceAreas = this.state.listData[i].userDetails.practiceAreas!==undefined?this.state.listData[i].userDetails.practiceAreas:[];

        locations = this.state.listData[i].userDetails.locations!==undefined?this.state.listData[i].userDetails.locations:[];

      }

     

      return (
        <div
            className="dropdown-menu user_popDetails_block"
            aria-labelledby="dropdownMenuButton"
          >
        <div className="user_popBox">
        <div className="u_popBox_sec user_popBox_top">
          <div className="user_popBox_topLeft">
            <h4>
            {lawyerName}
            </h4>
            <div className="userRating_block">
              <div className="ass_userRating">
                <i
                  className="fa fa-star"
                  aria-hidden="true"
                />{" "}
                4.5
              </div>
              <div className="ass_userExp">
                Experience - {(this.state.listData[i].userDetails.experience?(this.state.listData[i].userDetails.experience+" years"):"0 years")}
              </div>
            </div>
          </div>
          <div className="user_popBox_topRight">
            {/* <img src="assets/images/pic.png" alt=""> */}
            <div className="user_name_text">
              <span>AM</span>
            </div>
          </div>
        </div>
        <div className="u_popBox_sec user_popBox_location">
          <h5>Locations</h5>
          <ul>
              {Object.keys(locations).map((i,e)=><li>{
                  locations[i]}</li>)
              }
            
            
          </ul>
        </div>
        <div className="u_popBox_sec user_popBox_pracArea">
          <h5>Practice areas</h5>
          <ul>
              {Object.keys(practiceAreas).map((i,e)=><li>{
                  practiceAreas[i]}</li>)
              }
            
            
          </ul>
        </div>
        <div className="u_popBox_sec user_popBox_about">
          <h5>About</h5>
          <p>
          {(this.state.listData[i].userDetails.bio?(this.state.listData[i].userDetails.bio):"")}
          </p>
        </div>
      </div>
    
        </div>
      );
    }   

    setStateData = (entityObj) =>{

      /*
      let paramData = stateStaticData;

      if(entityObj!==undefined){
        
        this.isComponentLoaded && this.setState(paramData,()=>{     
        });
              
      }   
      */  

    }

    
    getHighlightedText = (text, highlight) => {

      // Split on highlight term and include term into parts, ignore case

      if( (text!==undefined && text!==null) && (highlight!==undefined && highlight!==null) && (highlight.length>0)){

        let textData = text;
        let textDataHash = text;
        let partsHighlighted = [];          
        let partsFoundObj = [];          

        highlight.map((part)=>{
      
          let regex = new RegExp(`(${part})`, 'gi');
          
          let result = [];
           // since  highlight key can appear multiple place in the string if atleast one match found then enter in this block
           while( result = regex.exec(textData)) {
              // construct the bold wrapped html object
              let keyObject = <b onClick={()=>{}} key={"k"+result.index} >{ part }</b>;  

              // create hash string when match is found and update it
              let splitItAr =  textDataHash.split(part);
              textDataHash = splitItAr.join("#####");    
              let objPush = {"keyName":part, "keyObject":keyObject,"positionNumber":result.index}; 
              // check whether positionNumber is already used or not if used then check whether it the data is part of another big part data then remove that position no. related element from the array and push the parent string data and if positionNumber has not been used then push it   
             
              let fndObj = findElement(partsFoundObj,"positionNumber",result.index); 
              if(fndObj===null){
                partsFoundObj.push(objPush);
              }else{
                
                let foundKeyNameData =  fndObj.objData.keyName; 
                // foundKeyNameData is already present in the array and  it is substring  of part let s say 12 is present in the array and is found that it is part of 12.5
                
                if(part.indexOf(foundKeyNameData)>-1){                   
                  partsFoundObj.splice(fndObj.indKey,1);                    
                  partsFoundObj.push(objPush);
                }
                
              }           
              
  
            }
  
          
        });

        // sort the part found array object on positionNumber so that it can be used in the text as it appears
        //console.log(" 1 textData===>",textData, " partsFoundObj==>>",partsFoundObj)
        partsFoundObj = partsFoundObj.sort(sortArrayOfJsonByKey('positionNumber','ASC'));
        //console.log("11 textData===>",textData, " partsFoundObj==>>",partsFoundObj)
        let expldStringHash = []; let lenpartsFoundObj= partsFoundObj.length;
        expldStringHash = textDataHash.split("#####");
        //console.log("2 textDataHash===>",textDataHash);
        let trackObjInjCn = 0;
        // now inject those object into a fresh array with other text
        if(expldStringHash && expldStringHash.length>0){
          expldStringHash.map((e,i)=>{
            // push 1st exploded string into the array to be displayed
            partsHighlighted.push(expldStringHash[i]);
            if(trackObjInjCn<lenpartsFoundObj){
              // push dynamic bold object created into the array to be displayed
              partsHighlighted.push(partsFoundObj[trackObjInjCn].keyObject);
              trackObjInjCn= trackObjInjCn+1;
            }


          });
        }

        return <>{partsHighlighted}</>;


      }
      else if((text!==undefined && text!==null) && (highlight!==undefined && highlight!==null) && (highlight.length==0)){
         return text;

      }
      else{
        return "";
      }

  }



    
    /**  
     * SplitScreenSingleTaskDetails
     * Function name : render
     * Author        :
     * Created Date  : 7-2-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() {    
      
       
      
      return (
          <React.Fragment>
            <NotiEmitTrack callbackfuncforcommontrackofnoti={this.trackNegoChatListOnRealTimeNoti} />
            
            {this.loadTheContent()}
          
          </React.Fragment>
      );
    }
}
export default SplitScreenProfessionalsNegotiationChat;

