/**  
 * CalculateInvoiceService
 * Page/Class name   : CalculateInvoiceService
 * Author            :
 * Created Date      : 5-10-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, getTweetRecords
 * Purpose           : api service page
**/
import {Component } from 'react';
// import {getEndPoint,getTokenFromLocStorage, callApi} from  '../../common_utilities/commonjsfunc';
// newly added axios
//import axios from 'axios';
import {axiosCustomObj} from '../customisedthirdparty/AxiosCustom';

import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
//const callApi = commonJsObj.callApi;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;



class CalculateInvoiceService extends Component {

    
    /**  
     * CalculateInvoiceService
     * Function name : constructor
     * Author        :
     * Created Date  : 5-10-2020
     * Purpose       : initializing state
     * Params        : 
    **/
    constructor() {
      super();
      this.state = {
          
      }

      //this.axiosObj = axios;
      this.axiosObj = null;
      
      this.apiEndPoint = commonJsObj.getEndPoint()+'rest/invoice/preview';
    }

    /**  
     * CalculateInvoiceService
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 5-10-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentDidMount() {
    }

    /**  
     * CalculateInvoiceService
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 5-10-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentWillUnmount() {  
    }



    /**  
     * CalculateInvoiceService
     * Function name : fetchCalInvoice
     * Author        :
     * Created Date  : 5-10-2020
     * Purpose       : Login check to provide access token 
     * Params        : paramObj
    **/
    async fetchCalInvoice(paramObj){
      //console.log(" paramObj==>",paramObj);
      this.axiosObj = axiosCustomObj({});
      let accToken = getTokenFromLocStorage();         

      try{
            let urldata =this.apiEndPoint; 

            let data =  paramObj.data;
            let currency = data.currency;
            let amount = data.amount;
            let is_reverse_gst_applicable = data.is_reverse_gst_applicable;
            
                     
            urldata = urldata+"/"+currency+"/"+amount+"?&is_reverse_gst_applicable="+is_reverse_gst_applicable;
            
            let headerOptn = {  
              "Content-Type": "application/json",
              "Authorization": accToken
            };          

            let respObj = this.axiosObj.get(urldata,{
            headers: headerOptn
            });

            return respObj;
    }
    catch(err){
        console.log("Some error occured===>",err); // TypeError: failed to fetch
    }
      

    }


    
}
export default CalculateInvoiceService;