/**  
 * UserLoginOtpService
 * Page/Class name   : UserLoginOtpService
 * Author            :
 * Created Date      : 12-2-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, getTweetRecords
 * Purpose           : api service page
**/
import {Component } from 'react';
// import {getEndPoint,getTokenFromLocStorage, callApi} from  '../../common_utilities/commonjsfunc';
// newly added axios
//import axios from 'axios';
import {axiosCustomObj} from '../customisedthirdparty/AxiosCustom';

import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
const callApi = commonJsObj.callApi;



class UserLoginOtpResendService extends Component {

    
    /**  
     * UserLoginOtpService
     * Function name : constructor
     * Author        :
     * Created Date  : 12-2-2020
     * Purpose       : initializing state
     * Params        : 
    **/
    constructor() {
      super();
      this.state = {
          
      }

      //this.axiosObj = axios;
      this.axiosObj = null;
      
      this.apiEndPoint = commonJsObj.getEndPoint()+'rest/noauth/otp';
    }

    /**  
     * UserLoginOtpService
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 12-2-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentDidMount() {
    }

    /**  
     * UserLoginOtpService
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 12-2-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentWillUnmount() {  
    }



    /**  
     * UserLoginOtpService
     * Function name : getLoginResp
     * Author        :
     * Created Date  : 12-2-2020
     * Purpose       : Login check to provide access token 
     * Params        : paramObj
    **/
    async getLoginOtpResp(paramObj){
      this.axiosObj = axiosCustomObj({});
           
      try{
            let urldata =this.apiEndPoint+"/"+(paramObj.emailId)+"/";
            let headerOptn = { 'Access-Control-Allow-Origin':'*','Content-Type': 'application/json' };
            let respObj = this.axiosObj({
            method: 'post',
            url: urldata ,
            data: {},
            headerOptn
            });

            return respObj;
    }
    catch(err){
        console.log("Some error occured===>",err); // TypeError: failed to fetch
    }
      

    }

    
}
export default UserLoginOtpResendService;