/**  
 * OfferTaskService
 * Page/Class name   : OfferTaskService
 * Author            :
 * Created Date      : 26-3-2021
 * Functionality     : constructor, componentDidMount , componentWillUnmount, getTweetRecords
 * Purpose           : api service page
**/
import {Component } from 'react';
// import {getEndPoint,getTokenFromLocStorage, callApi} from  '../../common_utilities/commonjsfunc';
// newly added axios
//import axios from 'axios';
import {axiosCustomObj} from '../customisedthirdparty/AxiosCustom';

import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
//const callApi = commonJsObj.callApi;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;



class OfferTaskService extends Component {

    
    /**  
     * OfferTaskService
     * Function name : constructor
     * Author        :
     * Created Date  : 26-3-2021
     * Purpose       : initializing state
     * Params        : 
    **/
    constructor() {
      super();
      this.state = {
          
      }

      //this.axiosObj = axios;
      this.axiosObj = null; 
      
      this.apiEndPoint = commonJsObj.getEndPoint()+'rest/tasks';
    }

    /**  
     * DraftTaskService
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 26-3-2021
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentDidMount() {
    }

    /**  
     * CreateTaskService
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 26-3-2021
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentWillUnmount() {  
    }



    /**  
     * CreateTaskService
     * Function name : saveDraftTask
     * Author        :
     * Created Date  : 26-3-2021
     * Purpose       : saveDraftTask 
     * Params        : paramObj
    **/
    async sendOfferTask(paramObj){
      //console.log(" paramObj to save draft in service==>", paramObj);
      this.axiosObj = axiosCustomObj({});
      let accToken = getTokenFromLocStorage();   
      let offsetOfLocalDateTime = getOffsetOfLocalDateTime(new Date());  
      let offsetHr = offsetOfLocalDateTime.hr;
      let offsetMin = offsetOfLocalDateTime.min;

      try{
            let urldata =this.apiEndPoint;
            
            //console.log(" ==dataObj==>",dataObj);

            let dataObj = paramObj.data;  
            let taskId =(dataObj && dataObj.taskId!==undefined)?dataObj.taskId:0;
            let lpUserId =(dataObj && dataObj.lpUserId!==undefined)?dataObj.lpUserId:0;

            urldata = urldata + "/task_offer_to/"+lpUserId+"/task/"+taskId+"?"+"offset_hour="+offsetHr+"&offset_minute="+offsetMin;
            
            let headerOptn = {  
              "Content-Type": "application/json",
              "Authorization": accToken
            };          

            let respObj = this.axiosObj.post(urldata,{}, {
            headers: headerOptn
            });

            return respObj;


            
    }
    catch(err){
        console.log("Some error occured===>",err); // TypeError: failed to fetch
    }
      

    }

    
}
export default OfferTaskService;