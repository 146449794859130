/**
 * CalculateInvoice
 * Page/Class name   : CalculateInvoice
 * Author            :
 * Created Date      : 5-10-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : rendering loader structure
**/
import React, { Component, Suspense, lazy} from 'react';
import '../../../custom_resources/css/custom.css';

// Modal
import CustReactBootstrapModal from './CustReactBootstrapModal';
import {notify} from '../../messagebar/CustomMessageBar';
import CustomMessageBar from '../../messagebar/CustomMessageBar';
// Services
import CalculateInvoiceService from '../../service/CalculateInvoiceService';


// for context
import CommonContext from '../../context/CommonContext';


import { commonJsFuncModule as commonJsObj } from '../../../common_utilities/commonjsfunc';
import {commonStaticOptionsJsFuncModule as commonStaticJsObj} from  '../../../common_utilities/commonstaticoptions';

const validateBlankSpace = commonJsObj.validateBlankSpace;
const getBaseUrl = commonJsObj.getBaseUrl;

const getStaticCurrencies = commonStaticJsObj.getStaticCurrencies;

const stateVarDefData  = {
    "showCustomModal": false,
    "currency": "INR",
    "amount": "",
    "is_reverse_gst_applicable":false,
    "sendButtonFlag":false,
    "manualChangeApplied": false,

    // "professionalBaseFee":0,
    // "professionalCgst":0,
    // "professionalSgst":0,
    // "professionalIgst":0,
    // "platformBaseFee":0,
    // "platformSgst":0,
    // "platformCgst":0,
    // "platformIgst":0,

    // display purpose
    "currencyDisp": "",
    "profFeeDisp":"",
    "gstOnProfFeeDisp":"",
    "platformFeeDisp":"",
    "totDisp": "",



    //error messages
    "amountErMsg":"",
   
    
};

class CalculateInvoice extends Component {
    /**
     * CalculateInvoice
     * Function name : constructor
     * Author        :
     * Created Date  : 
     * Purpose       : initializing state
     * Params        : props
    **/

   isComponentLoaded = false;

   // for context
   static contextType = CommonContext; 

    constructor(props) {
        super(props);
        this.state = stateVarDefData;

        this.modalcustomRef = React.createRef();
        this.fileUpl = React.createRef();
    }

    /**
     * CalculateInvoice
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentDidMount() {
        this.isComponentLoaded = true;
    }
    

    /**
     * CalculateInvoice
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {  
        this.isComponentLoaded = false;
    }

    static getDerivedStateFromProps(props, state) {
    
    
        let budget = props.budget!==undefined?props.budget:"";
        let budgetcurrency = props.budgetcurrency!==undefined?props.budgetcurrency:"";
         

        let budgetChangeFoundFlag =  false;
        let budgetCurrChangeFoundFlag =  false;

        if ((budget !== state.amount)) {
            budgetChangeFoundFlag = true;
            budget  = state.manualChangeApplied==true? state.amount: budget;
        }
        
        if ((budgetcurrency !== state.currency)) {
            budgetCurrChangeFoundFlag = true;
            budgetcurrency  = state.manualChangeApplied==true? state.currency: budgetcurrency;
        }

        if(budgetChangeFoundFlag==true || budgetCurrChangeFoundFlag==true ){

            return {
                "amount":budget,
                "currency":budgetcurrency
            }

        }
    
        // Return null to indicate no change to state.
        return null;
      }

   

   

    handleChange = (e) => {
        this.isComponentLoaded &&  this.setState({[e.target.name]: e.target.value,"manualChangeApplied":true},()=>{
            this.getCustomModalContent({});
        }); 
      }

     /**
         * CalculateInvoice
         * Function name : custValidate
         * Author        :
         * Created Date  : 5-10-2020
         * Purpose       : validate data before submit
         * Params        : 
    **/
    custValidate = ()=>{

    let totErrCountTrack = [];  
    
    let amountErMsg = "";  
    

    if(!(this.state.amount)||(this.state.amount.length==0)|| (validateBlankSpace(this.state.amount)==true)){
        amountErMsg = "Amount is required.";  
        totErrCountTrack.push(amountErMsg);
      }
      else if(isNaN(this.state.amount)){
        amountErMsg = "Invalid input provided.";
        totErrCountTrack.push(amountErMsg);
      }
      else if(Number(this.state.amount) <= 0){
        amountErMsg = "Provide amount greater than zero.";
        totErrCountTrack.push(amountErMsg);
      }
  
      let stateErData = {
        "amountErMsg": amountErMsg
      }
     
      this.isComponentLoaded && this.setState(stateErData);
  
    if(totErrCountTrack.length>0){ 

            // notify(notiMsg,true,{
            // "successMsg":0,
            // "customWrapClass":"customerrmidlass",
            // "msgKeyData": "msgNotiCalInvK1",
            // "showCloseButton": false, 

            // "dismiss": {
            // "duration": 1700,
            // }

            // });        
            return false;
      }
      return true;
    }

    callCalculateInvService = (paramObj) =>{

       
        let passParam ={
            "amount":this.state.amount,
        "currency":this.state.currency,
        "is_reverse_gst_applicable":this.state.is_reverse_gst_applicable,       

         };
        
        let refObj = this;
        let stcservice = new CalculateInvoiceService();
        stcservice.fetchCalInvoice({"data":passParam}).then(data =>{
              if(data.status==200){
                  let respData ={};
                  if(data.data.entity!==undefined){
                    respData = data.data.entity; 
                    let currencyDisp = respData.currency!==undefined?respData.currency:0;
                    let professionalBaseFee = respData.professionalBaseFee!==undefined?respData.professionalBaseFee:0;
                    let professionalCgst= respData.professionalCgst!==undefined?respData.professionalCgst:0;
                    let professionalSgst= respData.professionalSgst!==undefined?respData.professionalSgst:0;
                    let professionalIgst= respData.professionalIgst!==undefined?respData.professionalIgst:0;
                    let platformBaseFee= respData.platformBaseFee!==undefined?respData.platformBaseFee:0;
                    let platformSgst= respData.platformSgst!==undefined?respData.platformSgst:0;
                    let platformCgst= respData.platformCgst!==undefined?respData.platformCgst:0;
                    let platformIgst= respData.platformIgst!==undefined?respData.platformIgst:0;

                    let profFeeDisp= (professionalBaseFee!==null)? parseFloat(professionalBaseFee):0;
                    
                    profFeeDisp = (profFeeDisp).toFixed(2); 
                    
                    let gstOnProfFeeDisp=  (professionalCgst!==null && professionalSgst!==null && professionalIgst!==null) ? parseFloat(professionalCgst) + parseFloat(professionalSgst) + parseFloat(professionalIgst) :0;

                    gstOnProfFeeDisp = (gstOnProfFeeDisp).toFixed(2); 

                    
                    let platformFeeDisp= (platformBaseFee!==null && platformSgst!==null && platformCgst!==null && platformIgst!==null)? (parseFloat(platformBaseFee) + parseFloat(platformSgst) + parseFloat(platformCgst )+ parseFloat(platformIgst)): 0;

                    platformFeeDisp = (platformFeeDisp).toFixed(2); 

                    let totDisp = (parseFloat(profFeeDisp) + parseFloat(gstOnProfFeeDisp) + parseFloat(platformFeeDisp)) ;   
                    
                    totDisp =  totDisp.toFixed(2);

                    refObj.isComponentLoaded && refObj.setState({
                                                "currencyDisp": currencyDisp,
                                                "profFeeDisp":profFeeDisp,
                                                "gstOnProfFeeDisp":gstOnProfFeeDisp,
                                                "platformFeeDisp":platformFeeDisp,
                                                "totDisp":totDisp,
                                               
                                            });
                    
              }
          }
          }).catch((err)=>{
                  
                  let errStatusCodeAr = [400,500]; // allowed error status code
                  if(err.response && err.response.data){
                      let erRespData = err.response.data;
                      if(errStatusCodeAr.indexOf(erRespData.status) !== -1){  
                        refObj.isComponentLoaded && refObj.setState({
                            "currencyDisp": "",
                            "profFeeDisp":"",
                            "gstOnProfFeeDisp":"",
                            "platformFeeDisp":"",
                            "totDisp":"",
                           
                        }); 
                        
                        
                          let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                          console.log(errMsg);

                          notify(errMsg,true,{
                            "successMsg":0,
                            "customWrapClass":"customerrmidlass",
                            "msgKeyData": "msgNotiCalInvK1",
                            "showCloseButton": false, 
                
                            "dismiss": {
                            "duration": 1700,
                            }
                
                            });       
                            
                                           
                  }
                  } 
          }).finally(()=>{

            setTimeout(()=>{
                this.isComponentLoaded && this.setState({"sendButtonFlag":false},()=>{
                    this.getCustomModalContent({});
                });            
                          
            },1500);
  
            
                       
              
          });
    }

    preCallOfInvCalculate = (paramObj) =>{        
       
        // make any validation
        let chk = this.custValidate(paramObj);
        if(chk){
            this.isComponentLoaded && this.setState({"sendButtonFlag":true},()=>{
                this.getCustomModalContent({});
                setTimeout(()=>{
                    this.callCalculateInvService({});
                },900);
                
            });
            
        }else{
            this.isComponentLoaded && this.setState({"sendButtonFlag":false},()=>{
                this.getCustomModalContent({});
            });
        }
        
        
    }

    checkToOpenModal = (otherParam) =>{

        this.isComponentLoaded && this.setState(stateVarDefData,()=>{
            this.triggerCustomModalOpen(otherParam);

        });

        


    }

  

   
    
     
  
  


    /*** Feedback modal starts  */
    /**
     * CalculateInvoice
     * Function name : triggerCustomModalOpen
     * Author        :
     * Created Date  : 5-10-2020
     * Purpose       : To open conflict modal
     * Params        : 
    **/  
   triggerCustomModalOpen = (otherParam)=>{    
    this.isComponentLoaded &&  this.setState({showCustomModal:true},()=>{
      this.modalcustomRef.current.showModal();
      this.getCustomModalContent({});
    });    
      
  }

  /**
     * CalculateInvoice
     * Function name : triggerCustomModalClose
     * Author        :
     * Created Date  : 5-10-2020
     * Purpose       : To close conflict modal
     * Params        : 
    **/
   triggerCustomModalClose = ()=>{    
    this.isComponentLoaded && this.setState({showCustomModal:false},()=>{
      this.modalcustomRef.current.hideModal();
    });
    }

    /**
   * CalculateInvoice
   * Function name : getCustomModalContent
   * Author        :
   * Created Date  : 5-10-2020
   * Purpose       : Get modal view with data
   * Params        : 
  **/
    getCustomModalContent(otherParam) {  
        
       let currencyStaticData  = getStaticCurrencies();
       let budgetCurrency = this.state.currency;

        let viewData = <div className="dash_modal conflictpopbdyoutercls">
        
                <>
            <div className="modal-header">
                <h4 className="modal-title modal_title_tool"> Estimated Invoice Calculator
                {/*<div className="input_tooltip_bg">
                        <span className="input_tooltip" aria-label="Organisation's Details" data-balloon-pos="down">
                        <i className="fa fa-question-circle"></i>
                        </span>
                        </div>*/}
                </h4>
                <button type="button" onClick={
                    (evn)=>{
                        evn.preventDefault();
                    this.triggerCustomModalClose();

                }} className="close modal_close" data-dismiss="modal">&times;</button>


            </div>
            <CustomMessageBar keydata={"msgNotiCalInvK1"} />
            <div className="modal-body padding_top modalBody_invoiceDetails">
                <div className="modal_invoice_breakup_bg">
                        <div className="modal_invoice_breakup_fromBg">
                            <div className="form__group field">
                            <div className={`price_input_grup_bg ${ this.state.amountErMsg?'form_group_error':''}`}>
                                <div className="price_input_grup">
                                <div className="price_input_grup_right invoiceBrkupPriceInputGrup_left">
                                
                                <select value={budgetCurrency} 
                                        name="currency"

                                        onChange={this.handleChange} >
                                                {Object.keys(currencyStaticData).map((e,i)=> <option key={i}  value={currencyStaticData[e].key}>{currencyStaticData[e].value}</option>) }
                                                </select>
                                </div>
                                <div className="price_input_grup_left invoiceBrkupPriceInputGrup_right">
                                    <input
                                    type="text"
                                    autoComplete="off"

                                    placeholder="Enter amount in numerals without commas"
                                    name="amount"

                                            onChange={this.handleChange}
                                            value={this.state.amount}
                                    />
                                </div>
                                </div>
                                <span className="error_span">
                                {this.state.amountErMsg}
                                </span>
                            </div>
                            </div>
                            <div className="invoiceBrkup_btnBG">
                            <button
                                disabled={this.state.sendButtonFlag}
                                type="button"
                                className="btn_appearance_none sub_grd_btn"
                                data-dismiss="modal"
                                onClick={(evn)=>{
                                    evn.preventDefault();
                                    this.preCallOfInvCalculate();
                                }}
                            >
                                <span>Calculate</span>
                            </button>
                            </div>
                        </div>
                        {this.state.totDisp!=="" && (

                        
                        <div className="modal_invoice_breakup_detailsText">
                            <div className="modal_payment_splite_box">
                            <div className="pay_row_adjust">
                                <div className="pay_row">
                                <div className="pay_row_l">Professional's Fee</div>
                                <div className="pay_row_r">
                                    {/* INR 20000 */}
                                    {this.state.currencyDisp+" "+this.state.profFeeDisp}
                                    </div>
                                <div className="clearboth" />
                                </div>
                                <hr />
                                <div className="pay_row">
                                <div className="pay_row_l">GST on Professional's Fee</div>
                                <div className="pay_row_r">
                                    {/* INR 1000 */}
                                    {this.state.currencyDisp+" "+this.state.gstOnProfFeeDisp}
                                    </div>
                                <div className="clearboth" />
                                </div>
                                <div className="pay_row">
                                <div className="pay_row_l">Platform fee (Inclusive of GST)</div>
                                <div className="pay_row_r">
                                    {/* INR 5000 */}
                                    {this.state.currencyDisp+" "+this.state.platformFeeDisp}
                                    </div>
                                <div className="clearboth" />
                                </div>
                            </div>
                            <div className="pay_row_total">
                                <div className="pay_row">
                                <div className="pay_row_l">Total</div>
                                <div className="pay_row_r">
                                    {/* INR 26000 */}
                                     {this.state.currencyDisp+" "+this.state.totDisp}
                                    </div>
                                <div className="clearboth" />
                                </div>
                            </div>
                            </div>
                        </div>
                        ) }
                        <div className="popUP_text_p">
                            <p className="popUp_yellow_text">
                            Note - GST on Professional's Fees may be charged, if applicable. Under
                            reverse charge mechanism, for legal services, GST is payable by
                            organisations directly to the government.
                            </p>
                        </div>
                        </div> 

            </div>
            
            <div className="modal-footer">
                {/* <button onClick={
                    (evn)=>{
                        evn.preventDefault();
                    this.triggerCustomModalClose();

                }}  type="button"  className="btn_appearance_none modal_text_btn"><span>Cancel</span></button>
                       
                <button disabled={this.state.sendButtonFlag}  type="button" className="btn btn-primary modal_btn_save" onClick={(e)=>{ e.preventDefault();  
                
                

                } } ><span>Send</span></button> */}
            </div>
          
          
          </>
        
                        
        </div>;
    
        this.modalcustomRef.current.updateStateContent(viewData);
        this.modalcustomRef.current.showContent();
        return viewData;
    }    

    /*** Feedback modal related ends  */


   

    /**
     * CalculateInvoice
     * Function name : render
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() {    
       
       return (
             <>                
                

                <button key={this.props.custkeyid?this.props.custkeyid:"calculateinv001"} onClick={(evn)=>{
                        evn.preventDefault();
                        //console.log(" from listing page==>"," datas=>>",this.props.datas);
                        this.checkToOpenModal({});

                      }} className={`${this.props.custclassnamebtn?this.props.custclassnamebtn:""}`} ><span className={`${this.props.custclassnamebtnspan?this.props.custclassnamebtnspan:""}`}>{this.props.textname?this.props.textname:"Calculate Invoice"}</span></button>
               

                {/* ConflictInfo Modal start */}
                <CustReactBootstrapModal ref={this.modalcustomRef}  backdrop={false} backdropClassName="custom_modal_class "   animation={false} show={this.state.showCustomModal} onShow={()=>{}} onHide={()=>{}} modalcustdialog="custom_modal_lg taskinvsharepopupcls"  loaderbodytitle="Organisation"  />
                {/* ConflictInfo Modal end */}

               


             </>
            
        );
    }

  }
  export default CalculateInvoice;