
// google ga
import ReactGA from 'react-ga';

let reactGaActiveFlag = "YES";

if(reactGaActiveFlag=="YES"){
    ReactGA.initialize('UA-190345080-1');
}
console.log(" ----- LOADED------");

export let customizedRGA = {
    "rga":ReactGA,
     "setUserId": (paramObj) => {      

                if(reactGaActiveFlag=="YES" && customizedRGA && customizedRGA!==undefined && paramObj){                   
                   (customizedRGA).rga.set(paramObj);
                }
    },
    "sendEventData" : (paramObj) => {
                if(reactGaActiveFlag=="YES" &&  customizedRGA && customizedRGA!==undefined && paramObj){
                    (customizedRGA).rga.event(paramObj);
                }
                
    },
    "sendPageViewData" : (paramObj) => {

        if(reactGaActiveFlag=="YES" && customizedRGA && customizedRGA!==undefined){
            let path = paramObj && paramObj.path!==undefined?paramObj.path:"";
            if(path){
                (customizedRGA).rga.pageview(path);
            }
        }        
        
    }

}