import React, {Component} from 'react';
/* global google */

import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';

// If you want to use the provided css
import 'react-google-places-autocomplete/dist/assets/index.css';

// load google map library
import {LoadGoogleMap} from './LoadGoogleMap';

class NewCustomGoogleAutoComplete extends Component {
  isComponentLoaded =  false;
  /**
   * Signup
   * Function name : constructor
   * Author        :
   * Created Date  : 6-2-2020
   * Purpose       : initializing state
   * Params        : props 
  **/
 constructor(props) {
  super(props);
  this.state = {
    'googleplacelibloaded':false,
    'manualChangeApplied': false,
    'lat': '',
    'lng': '',
    'placeName': '',
    'placeId': '',
    'formattedAddress':'',
    'textboxtext':'',
    'city':'',
    'state':'',
    'country':'',
   
  };
  
  this.autocompleteInput = React.createRef();
    this.autocomplete = null;
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this);


}

 


componentDidMount() {
    console.log(" ===0000===");
    this.isComponentLoaded = true;
    LoadGoogleMap.loadGoogleMapLibrary(() => {
          // Work to do after the library loads.
          this.isComponentLoaded && this.setState({ googleplacelibloaded: true },()=>{                        
          });

        });  

        setTimeout(()=>{         
              this.initGoogleMapCust();
        },1200);

    
   
  }

  componentWillUnmount() {
    this.isComponentLoaded = false;

  }

initGoogleMapCust(){

      this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.current,
      {"types": ['(cities)']});
      this.autocomplete.addListener('place_changed', this.handlePlaceChanged);  
      
      
  }

  compIsType(t, s) { 
    for(let z = 0; z < t.length; ++z) 
       if(t[z] == s)
          return true;
 
    return false;
 }

  handlePlaceChanged(){      
    const place = this.autocomplete.getPlace();
    //console.log(" from google auto complete place===>",place);
    let lat, lng, addrSel, placeName, placeId =''; 
    let country, state, city =null;  
    if(place.geometry!==undefined ){
      const plcGeom = place.geometry;
      if(plcGeom.location!==undefined){
        lat = plcGeom.location ? plcGeom.location.lat():'';
        lng = plcGeom.location ? plcGeom.location.lng():'';
      }      
    }   
    
    addrSel  = place.formatted_address!==undefined?place.formatted_address:"";
    placeName  = place.name!==undefined?place.name:"";
    placeId = place.place_id!==undefined?place.place_id:"";

    if(place.address_components!==undefined){
      let addrComp = place.address_components;
      for(let i = 0; i < addrComp.length; ++i)
      {
        var typ = addrComp[i].types;
        if(this.compIsType(typ, 'administrative_area_level_1'))
            state = addrComp[i].long_name; //store the state
        else if(this.compIsType(typ, 'locality'))
            city = addrComp[i].long_name; //store the city
        else if(this.compIsType(typ, 'country'))
          country = addrComp[i].long_name; //store the country        

        //we can break early if we find all three data
        if(state != null && city != null && country != null) break;
      }
      
      //addrSel  = city!==null? city: addrSel;



    }

    let nameData = "";
      if(this.props.showKey!==undefined ){

        
        if(this.props.showKey=="CITY"){
          nameData = city;
        }
        else if(this.props.showKey=="STATE"){
          nameData = state;
        }
        else if(this.props.showKey=="COUNTRY"){
          nameData = country;
        }
         else if(this.props.showKey=="PLACE_NAME"){
          nameData = country;
        }
        else if(this.props.showKey=="FORMATTED_ADDRESS"){
          nameData = addrSel;
        }
         else if(this.props.showKey=="PLACE_ID"){
          nameData = placeId;
        }
        else{
          nameData = addrSel;
        }
       
      }else{
        
        nameData = addrSel;
      }

      

      if(this.props.removeTextFlag!==undefined && this.props.removeTextFlag=="YES" ){
        nameData = "";
      }

    let stateResp = {
      'lat':lat,
      'lng': lng,
      'formattedAddress':addrSel, 
      'placeName': placeName,
      'placeId': placeId,
      'city':city,
      'state':state,
      'country':country,
      'textboxtext':nameData
    };
    this.setState(stateResp,()=>{
     this.props.selectedGoogleDataInfo!==undefined && this.props.selectedGoogleDataInfo(stateResp);    
    });
    //console.log(place);
    //console.log("==lat==>", lat," ==lng===>",lng, "==addrSel==>",addrSel);
    //this.props.onPlaceLoaded(place);
  }

  getSelectedPlaceFromGoogAuto(){
    return {
      'lat':this.state.lat,
      'lng': this.state.lng,
      'formattedAddress':this.state.formattedAddress,
      'placeName': this.state.placeName,
      'placeId': this.state.placeId,
      'city': this.state.city,
      'state': this.state.state,
      'country': this.state.country,

    }
  }

  setTextBoxData(nameData){
    this.isComponentLoaded && this.setState({"textboxtext":nameData},()=>{
      console.log("in setTextBoxData nameData==>",nameData," this.state.textboxtext==>",this.state.textboxtext);
          
    });
  }



  static getDerivedStateFromProps(props, state) {
    
    
    let textboxtext = props.textboxtext!==undefined?props.textboxtext:"";
    if (textboxtext !== state.textboxtext) {
       
       textboxtext  = state.manualChangeApplied==true? state.textboxtext: textboxtext  ;

      return {
        "textboxtext": textboxtext
      };
    }

    // Return null to indicate no change to state.
    return null;
  }


  onChangeTrack = (e) =>{
    let valueInp = e.target.value;
    this.isComponentLoaded && this.setState({"textboxtext":valueInp,"manualChangeApplied":true},()=>{
      //console.log(" valueInp==>>",valueInp);

      let stateResp = {
        'lat': '',
        'lng': '',
        'placeName': '',
        'placeId': '',
        'formattedAddress':'',
        'textboxtext':'',
        'city':'',
        'state':'',
        'country':'',
      };
      if(valueInp==""){
        this.props.selectedGoogleDataInfo!==undefined && this.props.selectedGoogleDataInfo(stateResp);  
      }
      
    });
  }

  render() {
    
    return (
        
         <input disabled={this.props.disabledFlag!==undefined?this.props.disabledFlag:false} onKeyDown={this.handleKeyDown} ref={this.autocompleteInput} className={this.props.textboxcustclass!==undefined?this.props.textboxcustclass:''} key={this.props.idname?this.props.idname:'defid1'}  id={this.props.idname?this.props.idname:'defid1'} placeholder={this.props.placeholder?this.props.placeholder:"Provide input"}
         type="text" value={this.state.textboxtext} onChange={this.onChangeTrack} autocomplete="off"  />
    );
  }

  /**
   * NewCustomGoogleAutoComplete
   * Function name : handleKeyDown
   * Author        :
   * Created Date  : 18-2-2020
   * Purpose       : Used to handle key down event
   * Params        : 
  **/
 handleKeyDown = (e) => {
  if (e.key === 'Enter') {      
    e.preventDefault();
  }
}


}

export default NewCustomGoogleAutoComplete;
