/**  
 * TaskStatusUpdateService
 * Page/Class name   : TaskStatusUpdateService
 * Author            :
 * Created Date      : 17-6-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, getTweetRecords
 * Purpose           : api service page
**/
import {Component } from 'react';
// import {getEndPoint,getTokenFromLocStorage, callApi} from  '../../common_utilities/commonjsfunc';
// newly added axios
//import axios from 'axios';
import {axiosCustomObj} from '../customisedthirdparty/AxiosCustom';

import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
const callApi = commonJsObj.callApi;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;



class TaskStatusUpdateService extends Component {

    
    /**  
     * TaskStatusUpdateService
     * Function name : constructor
     * Author        :
     * Created Date  : 17-6-2020
     * Purpose       : initializing state
     * Params        : 
    **/
    constructor() {
      super();
      this.state = {
          
      }

      //this.axiosObj = axios;
      this.axiosObj = null;
      
      this.apiEndPoint = commonJsObj.getEndPoint()+'rest/tasks/status';
    }

    /**  
     * TaskStatusUpdateService
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 17-6-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentDidMount() {
    }

    /**  
     * TaskStatusUpdateService
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 17-6-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentWillUnmount() {  
    }



    /**  
     * TaskInvoiceShareService
     * Function name : updateTaskStatus
     * Author        :
     * Created Date  : 17-6-2020
     * Purpose       : saveOrgDetail check to provide access token 
     * Params        : paramObj
    **/
    async updateTaskStatus(paramObj){
      this.axiosObj = axiosCustomObj({});
      let accToken = getTokenFromLocStorage();     
      try{
          let urldata =this.apiEndPoint;            
          
          let dataObj = paramObj.data;
          let lpUserId = dataObj.lpUserId?dataObj.lpUserId:0;
          let taskIdOrSubTaskId = dataObj.taskIdOrSubTaskId?dataObj.taskIdOrSubTaskId:0;
          let taskOrSubTask = dataObj.taskOrSubTask?dataObj.taskOrSubTask:"";
          let status = dataObj.status?dataObj.status:"";

          if(taskOrSubTask=="STASK"){
            urldata = urldata+"/task/"+taskIdOrSubTaskId+"?status="+status;
          }
          else if(taskOrSubTask=="SWSTASK"){
            urldata = urldata+"/sub_task/"+taskIdOrSubTaskId+"?status="+status;
          }

         
          
          
          //console.log(" ==dataObj==>",dataObj);


          let headerOptn = {  
            "Content-Type": "application/json",
            "Authorization": accToken
          };          

          let respObj = this.axiosObj.put(urldata,dataObj.postData, {
          headers: headerOptn
          });

          return respObj;
      }
      catch(err){
        console.log("Some error occured===>",err); // TypeError: failed to fetch
      }
      

    }

    
}
export default TaskStatusUpdateService;