/**
 * SplitScreenProfessionalsInterested
 * Page/Class name   : MasterLayout
 * Author            :
 * Created Date      : 7-2-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : render master component
 **/
import React, { Component } from "react";
// import Header from '../../share/Header';
// import SideBar from '../../share/SideBar';

// Modal
import CustReactBootstrapModal from "./CustReactBootstrapModal";

import { notify } from "../../messagebar/CustomMessageBar";
import CustomMessageBar from "../../messagebar/CustomMessageBar";

// for context
import CommonContext from "../../context/CommonContext";
// scroll
import { Scrollbars } from "react-custom-scrollbars";

// new scroll
import InfiniteScroll from "react-infinite-scroll-component";

//services
import ProfessionalInterestedRespListService from "../../service/ProfessionalInterestedRespListService";
import TaskDetailsService from "../../service/TaskDetailsService";
import ConflictCheckService from "../../service/ConflictCheckService";

import CustDate from "../../../common_utilities/CustDate";
import { commonJsFuncModule as commonJsObj } from "../../../common_utilities/commonjsfunc";

// For loading CustLoader
import CustLoader from "./CustLoader";

import SplitScreenLeftMenu from "../../share/SplitScreenLeftMenu";

// Newest1 - Start
import ProfileNewMod from "./ProfileNewMod";
// Newest1 - End

// //AssignButton
// import AssignButton from "./AssignButton";
// OfferTaskButton
import OfferTaskButton from "./OfferTaskButton";

//Tags
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";

// common noti track
import NotiEmitTrack from "./NotiEmitTrack";
import { commonnotitrack } from "./NotiEmitTrack";

const getStringInitials = commonJsObj.getStringInitials;
const getCcUserId = commonJsObj.getCcUserId;
const getBaseUrl = commonJsObj.getBaseUrl;
const getOrgName = commonJsObj.getOrgName;

class SplitScreenProfessionalsInterested extends Component {
  isComponentLoaded = false;
  // for context
  static contextType = CommonContext;

  /**
   * SplitScreenSingleTaskDetails
   * Function name : constructor
   * Author        :
   * Created Date  : 7-2-2020
   * Purpose       : initializing children
   * Params        : props
   **/
  constructor(props) {
    super(props);
    this.children = props.children;

    this.state = {
      listData: [],
      pageNum: 1,
      totalNumberOfPages: 1,
      loaderFlag: true,
      pageNumArTrack: [],
      taskId: 0,
      // for confirm button to disable
      confContinueDisabled: false,
      // conflict modal
      showConflictModal: false,
      //
      lawyerUserIdForConflChk: 0,
      lawyerNameForConflChk: "",
      lawyerConflictCheckStatus: "",
      //
      tags: [],
      isOrganisationDetailsMasked: false,
      notificationIdAr: [],
      assignedTo: null,

      // Newest1 - Start
      showPVDisclaimerModal: false,
      showPVDisplayModal: false,
      // Newest1 - End
    };

    this.modalconflictRef = React.createRef();
    this.scrollerRef = React.createRef();

    // Newest1 - Start
    this.modalforpvdisclaimerRef = React.createRef();
    this.modalforpvdisplayRef = React.createRef();
    // Newest1 - End
  }

  /**
   * SplitScreenSingleTaskDetails
   * Function name : componentDidMount
   * Author        :
   * Created Date  : 7-2-2020
   * Purpose       : lifecycle method
   * Params        :
   **/
  componentDidMount() {
    this.isComponentLoaded = true;

    let taskId = 0;
    let assignedTo = null;
    let sharedData =
      this.context !== undefined && this.context.share !== undefined
        ? this.context.share
        : null;
    if (
      sharedData !== undefined &&
      sharedData !== null &&
      sharedData.actionType !== undefined
    ) {
      if (sharedData.actionType == "SPLITSCREEN") {
        taskId = sharedData.taskId;
        if (sharedData.other !== undefined && sharedData.other !== null) {
          let taskResp =
            sharedData.other.taskResp !== undefined
              ? sharedData.other.taskResp
              : null;
          assignedTo =
            taskResp.assignedTo !== undefined ? taskResp.assignedTo : null;
        }

        this.isComponentLoaded &&
          this.setState({ taskId: taskId, assignedTo: assignedTo });
      }
    }

    // set list data to blank state
    this.showLoader();
    setTimeout(() => {
      // call list api
      let paramObj = {
        pageNum: 1,
        pageNumArTrack: [],
        totalNumberOfPages: 1,
        taskId: taskId,
      };
      this.isComponentLoaded &&
        this.setState(paramObj, () => {
          this.callServiceToFetchInterestedProfRespList(
            paramObj,
            "*****called from componentDidMount**** "
          );
        });
    }, 1005);
  }

  /**
   * SplitScreenSingleTaskDetails
   * Function name : componentWillUnmount
   * Author        :
   * Created Date  : 7-2-2020
   * Purpose       : lifecycle method
   * Params        :
   **/
  componentWillUnmount() {
    this.isComponentLoaded = false;
  }

  componentDidUpdate(prevProps) {}

  reloadInterestedList = (otherParam) => {
    // call list api
    let paramObj = {
      listData: [],
      pageNum: 1,
      pageNumArTrack: [],
      totalNumberOfPages: 1,
      taskId: this.state.taskId,
      isOrganisationDetailsMasked: false,
    };
    this.isComponentLoaded &&
      this.setState(paramObj, () => {
        this.showLoader();
        setTimeout(() => {
          this.callServiceToFetchInterestedProfRespList(
            paramObj,
            "*****called from reloadInterestedList**** "
          );
        }, 1100);
      });
  };

  reloadProfListOnRealTimeNoti = (paramObj) => {
    let notiType =
      paramObj.notiType !== undefined && paramObj.notiType
        ? paramObj.notiType
        : "";
    let taskId =
      paramObj.taskId !== undefined && paramObj.taskId ? paramObj.taskId : 0;
    let notificationId =
      paramObj.notificationId !== undefined && paramObj.notificationId
        ? paramObj.notificationId
        : 0;

    let sharedData =
      this.context !== undefined && this.context.share !== undefined
        ? this.context.share
        : null;
    let notificationIdAr = [...this.state.notificationIdAr];
    let chkNotiIdPres = notificationIdAr.indexOf(notificationId);

    if (sharedData.leftTabToActive === "PROFESSIONALSINTERESTED") {
      if (this.state.taskId == taskId) {
        if (
          notiType == "TASK_TERMS" ||
          notiType == "TASK_ACCEPTED" ||
          notiType == "TASK_DECLINED"
        ) {
          if (chkNotiIdPres == -1) {
            notificationIdAr.push(notificationId);
            this.isComponentLoaded &&
              this.setState({ notificationIdAr: notificationIdAr }, () => {
                this.reloadInterestedList({});

                //console.log(notiType," === realtime called from reloadProfListOnRealTimeNoti from prof intersted=====");
                // update the response of task details in context
                let sharedData =
                  this.context !== undefined && this.context.share !== undefined
                    ? this.context.share
                    : null;
                let paramObjData = { data: { taskId: this.state.taskId } };
                let param = {
                  paramObjData: paramObjData,
                  paramsCntxt: sharedData,
                  calledFrom: "reloadProfListOnRealTimeNoti",
                };
                this.getTaskDetailData(param);
              });
          }
        } else if (notiType == "CLOSE_SPLITSCREEN") {
          //this.trackNotiForCloseSplit(paramObj);
        }
      }
    }
  };

  // trackNotiForCloseSplit = (otherParam) =>{

  //   let notiType =  otherParam.notiType!==undefined? otherParam.notiType: "";
  //   let trigerredFrom =  otherParam.trigerredFrom!==undefined? otherParam.trigerredFrom: "";
  //   if(notiType=="CLOSE_SPLITSCREEN"){
  //       let closeSplitHeaderCallFrm = ["PROFESSIONALS_INTERSESTED_LIST"];
  //       if(closeSplitHeaderCallFrm.indexOf(trigerredFrom)!=-1){
  //               console.log("*******trigerredFrom=>>",trigerredFrom);
  //       }

  //   }
  // }

  callServiceToFetchInterestedProfRespList = (paramObj, trackcalledfrom) => {
    let paramObjData = {};
    paramObjData.pageNum = paramObj.pageNum;
    paramObjData.taskId = paramObj.taskId;

    let pageNumArTrack = [...this.state.pageNumArTrack];

    // restrict repeated pagenum related api call
    if (pageNumArTrack.indexOf(paramObjData.pageNum) > -1) {
      //console.log("******Sorry cant hit api with page number => "+paramObjData.pageNum+" **********");
      return false;
    } else {
      pageNumArTrack.push(paramObjData.pageNum);
      this.isComponentLoaded &&
        this.setState({ pageNumArTrack: pageNumArTrack });
    }

    let stcservice = new ProfessionalInterestedRespListService();
    stcservice
      .getProfessionalInterestedResponseList(paramObjData)
      .then((data) => {
        if (data.status == 200) {
          let respData = {};
          if (data.data.entity !== undefined) {
            respData = data.data.entity;
            if (respData.interestedUsers !== undefined) {
              if (respData.interestedUsers.length > 0) {
                // let scobj = this.scrollerRef.current;
                // let scrollToTop = (scobj.getValues().scrollTop) -10;
                // scobj.scrollTop(scrollToTop);
                // scobj.hideTracksWhenNotNeeded = true

                // set list data to state
                let prevRecords = [...this.state.listData];
                let currRecords = respData.interestedUsers;
                let isOrganisationDetailsMasked =
                  respData.isOrganisationDetailsMasked !== undefined
                    ? respData.isOrganisationDetailsMasked
                    : false;
                let concatenateRecords = prevRecords.concat(currRecords);
                this.isComponentLoaded &&
                  this.setState(
                    {
                      listData: concatenateRecords,
                      totalNumberOfPages: respData.totalNumberOfPages,
                      isOrganisationDetailsMasked: isOrganisationDetailsMasked,
                    },
                    () => {}
                  );
              } else {
                if (this.state.listData.length == 0) {
                  this.isComponentLoaded &&
                    this.setState({
                      listData: [],
                      totalNumberOfPages: respData.totalNumberOfPages,
                      pageNum: 1,
                      isOrganisationDetailsMasked: false,
                    });
                }
              }
            } else {
            }
          }
        }
      })
      .catch((err) => {
        let errStatusCodeAr = [400, 500]; // allowed error status code
        if (err.response && err.response.data) {
          let erRespData = err.response.data;
          if (errStatusCodeAr.indexOf(erRespData.status) !== -1) {
            let errMsg = erRespData.message
              ? erRespData.message
              : "Some technical problem occurred.";
            console.log(errMsg);
          }
        }
      })
      .finally(() => {
        this.hideLoader();
      });
  };

  closeSplit = (objParam) => {
    //********* for context starts *****
    // let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;
    // if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){

    //     if(sharedData.actionType=="SPLITSCREEN" ){

    //       let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;

    //       if(setShare){
    //         let paramsCntxt = {
    //           "taskId":"",
    //           "actionType":"",
    //           "leftTabToActive":"",
    //           "purpose":"",
    //           "other":{}
    //         }
    //         setShare(paramsCntxt);
    //       }

    //     }
    // }
    //********* for context ends *****
    commonnotitrack("CLOSE_SPLITSCREEN", {
      notiType: "CLOSE_SPLITSCREEN",
      triggerCallback: false,
      trigerredFrom: "PROFESSIONALS_INTERSESTED_LIST",
    });
  };

  getTaskDetailData = (param) => {
    let paramObjData = param.paramObjData;
    let paramsCntxt = param.paramsCntxt;
    let calledFrom = param.calledFrom;

    let lservdrf = new TaskDetailsService();
    lservdrf
      .getTaskDetail(paramObjData)
      .then((data) => {
        let entityObj = data.data.entity;
        let assignedTo =
          entityObj.assignedTo !== undefined ? entityObj.assignedTo : null;

        // context share
        let setShare =
          this.context !== undefined && this.context.setShare !== undefined
            ? this.context.setShare
            : null;
        if (setShare) {
          paramsCntxt["other"]["taskResp"] = entityObj;
          //console.log(" paramsCntxt===>>",paramsCntxt);

          this.setState({ assignedTo: assignedTo }, () => {
            setShare(param.paramsCntxt);
          });
        }
      })
      .catch((e) => {
        console.log("server error=>", e);
      })
      .finally(() => {});
  };

  /**
   * SplitScreenProfessionalsInterested
   * Function name : loadLoader
   * Author        :
   * Created Date  : 27-3-2020
   * Purpose       : Load loader
   * Params        :
   **/

  loadLoader() {
    return (
      <CustLoader className={"smallLoaderGreen"} loaderText={"Loading.."} />
    );
  }

  /**
   * SplitScreenProfessionalsInterested
   * Function name : hideLoader
   * Author        :
   * Created Date  : 27-3-2020
   * Purpose       : Hide loader
   * Params        :
   **/

  hideLoader() {
    this.isComponentLoaded && this.setState({ loaderFlag: false });
  }

  /**
   * SplitScreenProfessionalsInterested
   * Function name : showLoader
   * Author        :
   * Created Date  : 27-3-2020
   * Purpose       : showLoader
   * Params        :
   **/

  showLoader() {
    this.isComponentLoaded && this.setState({ loaderFlag: true });
  }

  goToNegotiationChat = (otherParam, taskOrSubTask, taskIdOrSubTaskId) => {
    let sharedData =
      this.context !== undefined && this.context.share !== undefined
        ? this.context.share
        : null;

    let setShare =
      this.context !== undefined && this.context.setShare !== undefined
        ? this.context.setShare
        : null;

    let otherData = sharedData.other !== undefined ? sharedData.other : {};
    //console.log(" otherData=>",otherData);

    otherData["negoChat"] = {};
    otherData["negoChat"]["taskOrSubTask"] = otherParam.taskOrSubTask;
    otherData["negoChat"]["taskIdOrSubTaskId"] = otherParam.taskIdOrSubTaskId;
    otherData["negoChat"]["taskOrSubTaskName"] = otherParam.taskOrSubTaskName;
    otherData["negoChat"]["lpUserInterestedData"] = otherParam.data;
    otherData["negoChat"]["lpUserId"] = otherParam.lpUserId;
    otherData["negoChat"]["lpUserName"] = otherParam.lpUserName;
    otherData["negoChat"]["ccUserId"] = otherParam.ccUserId;
    otherData["negoChat"]["conflictCheckStatus"] =
      otherParam.conflictCheckStatus;
    otherData["negoChat"]["assignRel"] = otherParam.assignRel;

    let paramsCntxt = {
      taskId: this.state.taskId,
      actionType: "SPLITSCREEN",
      leftTabToActive: "PROFESSIONALTASKNEGOTIATIONCHAT",
      purpose: "VIEW_NEGOCHAT_LIST",
      other: otherData,
    };

    if (setShare && sharedData) {
      setShare(paramsCntxt);
    }
  };

  // trackScroll = ()=>{

  //   let scobj = this.scrollerRef.current;
  //   let scrollTop = scobj.getValues().scrollTop;
  //   let scrollHeight = scobj.getScrollHeight();
  //   let clientHeight = scobj.getClientHeight();

  //   let prevPageNum = this.state.pageNum;
  //   let totalNumberOfPages = this.state.totalNumberOfPages;

  //   //console.log(" scrollHeight=>",scrollHeight," clientHeight==>",clientHeight, " scrollTop=>>",scrollTop);

  //   if((scrollHeight-clientHeight)==scrollTop && prevPageNum < totalNumberOfPages){

  //     this.showLoader();

  //     setTimeout(()=>{
  //       // call list api
  //       let pageNumData = prevPageNum + 1;
  //       let paramObj = {"pageNum":pageNumData,"taskId":this.state.taskId};

  //       this.isComponentLoaded && this.setState({ pageNum: pageNumData },()=>{
  //         this.callServiceToFetchInterestedProfRespList(paramObj,"***called from scroll bar***");
  //       });

  //       },900);

  //   }

  // }

  fetchMoreData = () => {
    let prevPageNum = this.state.pageNum;
    let totalNumberOfPages = this.state.totalNumberOfPages;

    //console.log(" scrollHeight=>",scrollHeight," clientHeight==>",clientHeight, " scrollTop=>>",scrollTop);

    if (prevPageNum < totalNumberOfPages) {
      this.showLoader();

      setTimeout(() => {
        // call list api
        let pageNumData = prevPageNum + 1;
        let paramObj = { pageNum: pageNumData, taskId: this.state.taskId };

        this.isComponentLoaded &&
          this.setState({ pageNum: pageNumData }, () => {
            this.callServiceToFetchInterestedProfRespList(
              paramObj,
              "***called from scroll bar***"
            );
          });
      }, 900);
    }
  };

  loadTheContent = () => {
    let taskName = "";
    let forSplitScrnCls2Inject = "";
    //********* for context starts *****
    let sharedData =
      this.context !== undefined && this.context.share !== undefined
        ? this.context.share
        : null;
    if (
      sharedData !== undefined &&
      sharedData !== null &&
      sharedData.actionType !== undefined
    ) {
      if (sharedData.actionType == "SPLITSCREEN") {
        forSplitScrnCls2Inject = "margin0";
      }

      if (
        sharedData.other !== undefined &&
        sharedData.other.taskResp !== undefined &&
        sharedData.other.taskResp.taskName !== undefined
      ) {
        taskName = sharedData.other.taskResp.taskName
          ? sharedData.other.taskResp.taskName
          : "";
      }
    }
    //********* for context ends *****

    //console.log(" sharedData=>>",sharedData);

    return (
      <>
        {/* Dashboard Slide Split Right start  */}
        <div
          className={`splitscreen_area 
              ${forSplitScrnCls2Inject ? forSplitScrnCls2Inject : ""}`}
        >
          <div className="slideScreen_bg slideScreen_bg_heightAdjust">
            <SplitScreenLeftMenu />
            <div className="slideScreen_rightbg flexbox-parent">
              {/* === right slide section top start ===  */}
              <div className="SlideS_topbg flexbox-item">
                <div className="slide_top_titlebg">
                  <h4>Professionals Interested</h4>
                  <button
                    onClick={(evn) => {
                      evn.preventDefault();
                      this.closeSplit({
                        trigerredFrom: "PROFESSIONALS_INTERSESTED_LIST",
                      });
                    }}
                    className="btn_appearance_none slideTop_cross_btn cross"
                  >
                    <img src="assets/images/red_cross.png" />
                  </button>
                </div>
              </div>
              {/* === right slide section top end ===  */}
              <CustomMessageBar keydata={"profInterrestedNotiK1"} />

              {/* === right slide section Middlie  start ===  */}
              <div
                ref={this.scrollerRef}
                id="scrollableDivProfInt"
                style={{
                  flexDirection: "column",
                }}
                className="SlideS_Midbg flexbox-item-grow  SlideS_Midbg_heightAdjustment SlideS_Midbg_heightAdjustment_profIntarestPage"
              >
                <div className="s_midbox_scroll_adjust reactTestScroll mCustomScrollbar_height_demoTest mCustomAdjustmentForStickFooter rightMidPartHeightAdjustment rightMidPartHeightAdjustment_profIntarestPage">
                  {/* <Scrollbars 
                          ref={this.scrollerRef}
                          style={{ height: 730 }}                  
                          autoHeightMin={730}
                          renderView={props => <div {...props} className="view profinterestedlistscrollclass profinterestedlistscrollclass_height_demoTest"/>}

                          onScrollStop={this.trackScroll}
                      > */}

                  <InfiniteScroll
                    dataLength={this.state.listData.length} //This is important field to render the next data
                    next={this.fetchMoreData}
                    hasMore={true}
                    // loader={<h4>Loading...</h4>}
                    scrollableTarget="scrollableDivProfInt"
                    inverse={false}
                  >
                    <div className="s_midbox_section">
                      <div className="s_midbox_title">
                        <h5>{taskName}</h5>
                      </div>
                      {/* ====   sprint - 4 structure start ====  */}
                      {!this.state.assignedTo && (
                        <div className="assignList_topMsg_block">
                          <p className="msg_yellow_textBlock">
                            Professionals who are interested will show up here.
                            You can offer task to multiple professionals by
                            agreeing to different budgets and due dates with
                            different professionals. Professional who accepts
                            task first, is assigned to the task.
                          </p>
                        </div>
                      )}

                      {this.state.loaderFlag == false &&
                        this.state.listData.length == 0 && (
                          <div className=" text-center no records found">
                            No records found
                          </div>
                        )}
                      <div className="assign_list_bg">
                        {this.getListStructure()}
                      </div>

                      <div className=" text-center ">
                        {this.state.loaderFlag ? this.loadLoader() : null}
                      </div>

                      {/* ====   sprint - 4 structure end ====  */}
                    </div>
                  </InfiniteScroll>
                  {/* </Scrollbars> */}
                </div>
              </div>
              {/* === right slide section Middlie  end ===  */}
              {/* === right slide section Footer  start ===  */}
              {/* <div className="SlideS_bottombg flexbox-item SlideS_bottombg_gray">
                  <div className="s_footer_btnbg">
                    <button className="btn_appearance_none green_btn">
                      <span>Edit</span>
                    </button>
                  </div>
                </div>
                 */}
              {/* === right slide section Footer  end ===  */}
            </div>
          </div>
        </div>

        {/* Dashboard Slide Split Right end  */}
      </>
    );
  };

  // Newest1 - Start

  // loadLawyerInfoPopup = (i, otherParam) => {
  //   //console.log(this.state.listData[i].userDetails);
  //   let practiceAreas = [];
  //   let locations = [];
  //   let lawyerName = "";
  //   let lawyerFname = "";
  //   let lawyerLname = "";
  //   let bioData = "";
  //   let expData = "";
  //   let ratingData = "";

  //   let profilePictureURL = null;

  //   if (
  //     this.state.listData[i] &&
  //     this.state.listData[i].userDetails !== undefined
  //   ) {
  //     lawyerFname =
  //       this.state.listData[i].userDetails.firstName !== undefined &&
  //       this.state.listData[i].userDetails.firstName !== null
  //         ? this.state.listData[i].userDetails.firstName
  //         : "";

  //     lawyerLname =
  //       this.state.listData[i].userDetails.lastName !== undefined &&
  //       this.state.listData[i].userDetails.lastName !== null
  //         ? this.state.listData[i].userDetails.lastName
  //         : "";

  //     lawyerName = lawyerFname + " " + lawyerLname;

  //     practiceAreas =
  //       this.state.listData[i].userDetails.practiceAreas !== undefined
  //         ? this.state.listData[i].userDetails.practiceAreas
  //         : [];

  //     locations =
  //       this.state.listData[i].userDetails.locations !== undefined
  //         ? this.state.listData[i].userDetails.locations
  //         : [];

  //     profilePictureURL =
  //       this.state.listData[i].userDetails.profilePictureURL !== undefined
  //         ? this.state.listData[i].userDetails.profilePictureURL
  //         : null;

  //     bioData =
  //       this.state.listData[i].userDetails.bio !== undefined &&
  //       this.state.listData[i].userDetails.bio !== null
  //         ? this.state.listData[i].userDetails.bio
  //         : "";

  //     expData =
  //       this.state.listData[i].userDetails.workExperience !== undefined &&
  //       this.state.listData[i].userDetails.workExperience !== null
  //         ? this.state.listData[i].userDetails.workExperience + " years"
  //         : "";

  //     ratingData =
  //       this.state.listData[i].userDetails.rating !== undefined &&
  //       this.state.listData[i].userDetails.rating !== null
  //         ? this.state.listData[i].userDetails.rating
  //         : "";
  //   }

  //   return (
  //     <div
  //       className="dropdown-menu user_popDetails_block"
  //       aria-labelledby="dropdownMenuButton"
  //     >
  //       <div className="user_popBox">
  //         <div className="u_popBox_sec user_popBox_top">
  //           <div className="user_popBox_topLeft">
  //             <h4>{lawyerName}</h4>
  //             <div className="userRating_block">
  //               <div className="ass_userRating">
  //                 <i className="fa fa-star" aria-hidden="true" /> {ratingData}
  //               </div>
  //               <div className="ass_userExp">Experience - {expData}</div>
  //             </div>
  //           </div>
  //           <div className="user_popBox_topRight">
  //             {profilePictureURL !== null && <img src={profilePictureURL} />}
  //             {profilePictureURL === null && (
  //               <div className="user_name_text">
  //                 <span>{getStringInitials(lawyerName)}</span>
  //               </div>
  //             )}
  //           </div>
  //         </div>
  //         <div className="u_popBox_sec user_popBox_location">
  //           <h5>Locations</h5>
  //           <ul>
  //             {locations !== null &&
  //               Object.keys(locations).map((incl, edt) => (
  //                 <li
  //                   key={this.state.listData[i].userDetails.userId + "0" + incl}
  //                 >
  //                   {locations[incl]}
  //                 </li>
  //               ))}
  //           </ul>
  //         </div>
  //         <div className="u_popBox_sec user_popBox_pracArea">
  //           <h5>Practice areas</h5>
  //           <ul>
  //             {practiceAreas !== null &&
  //               Object.keys(practiceAreas).map((prci, e) => (
  //                 <li
  //                   key={this.state.listData[i].userDetails.userId + "0" + prci}
  //                 >
  //                   {practiceAreas[prci]}
  //                 </li>
  //               ))}
  //           </ul>
  //         </div>
  //         <div className="u_popBox_sec user_popBox_about">
  //           <h5>About</h5>
  //           <p>{bioData}</p>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  /*** Professional View Display Modal starts  */

  /**
   * SplitScreenProfessionalsInterested
   * Function name : pVDisplayModalClose
   * Author        :
   * Created Date  : 9-7-2021
   * Purpose       : To close pVDisplay modal
   * Params        :
   **/
  pVDisplayModalClose = () => {
    this.isComponentLoaded &&
      this.setState({ showPVDisplayModal: false }, () => {
        this.modalforpvdisplayRef.current.hideModal();
      });
  };

  /**
   * SplitScreenProfessionalsInterested
   * Function name : getPVDisplayModalContent
   * Author        :
   * Created Date  : 9-7-2021
   * Purpose       : Get pVDisplay modal view with data
   * Params        : userId
   **/
  getPVDisplayModalContent = (userId) => {
    let viewData = (
      <ProfileNewMod
        closingFunction={this.pVDisplayModalClose}
        lprofileId={userId}
      />
    );

    this.modalforpvdisplayRef.current.updateStateContent(viewData);
    this.modalforpvdisplayRef.current.showContent();
    return viewData;
  };

  /**
   * SplitScreenProfessionalsInterested
   * Function name : pVDisplayModalOpen
   * Author        :
   * Created Date  : 9-7-2021
   * Purpose       : To open pVDisplay modal
   * Params        : userId
   **/
  pVDisplayModalOpen = (userId) => {
    this.isComponentLoaded &&
      this.setState({ showPVDisplayModal: true }, () => {
        this.modalforpvdisplayRef.current.showModal();
        this.getPVDisplayModalContent(userId);
      });
  };

  /*** Professional View Display Modal ends  */

  /*** Professional View Disclaimer Modal starts  */

  /**
   * SplitScreenProfessionalsInterested
   * Function name : pVDiscModCloseDispModOpen
   * Author        :
   * Created Date  : 9-7-2021
   * Purpose       : To close pVDisclaimer modal and conditionally open pVDisplay modal
   * Params        : shouldDisplay, userId
   **/
  pVDiscModCloseDispModOpen = (shouldDisplay, userId) => {
    this.isComponentLoaded &&
      this.setState({ showPVDisclaimerModal: false }, () => {
        this.modalforpvdisclaimerRef.current.hideModal();
        if (shouldDisplay) {
          setTimeout(() => {
            this.pVDisplayModalOpen(userId);
          }, 300);
        }
      });
  };

  /**
   * SplitScreenProfessionalsInterested
   * Function name : getPVDisclaimerModalContent
   * Author        :
   * Created Date  : 9-7-2021
   * Purpose       : Get pVDisclaimer modal view with data
   * Params        : userId
   **/
  getPVDisclaimerModalContent = (userId) => {
    let viewData = (
      <div className="dash_modal">
        <div className="modal-header">
          <h4 className="modal-title modal_title_tool">
            View Professional’s Profile
            {/*<div className="input_tooltip_bg">
                      <span className="input_tooltip" aria-label="Organisation's Details" data-balloon-pos="down">
                      <i className="fa fa-question-circle"></i>
                      </span>
                      </div>*/}
          </h4>
          <button
            type="button"
            onClick={() => {
              this.pVDiscModCloseDispModOpen(false, null);
            }}
            className="close modal_close"
            data-dismiss="modal"
          >
            &times;
          </button>
        </div>
        {/* <CustomMessageBar /> */}
        <div className="modal-body padding_top">
          <div className="fileUp_modalBox">
            <p>
              Disclaimer: By clicking on "View" you agree that you have sought
              this information of your own accord. You further agree that
              neither the Professional nor Flywork has solicited and/or
              advertised to induce you for work.
            </p>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              this.pVDiscModCloseDispModOpen(false, null);
            }}
            data-dismiss="modal"
            class="btn_appearance_none modal_text_btn"
          >
            <span>Cancel</span>
          </button>
          <button
            type="button"
            className="btn_appearance_none green_btn"
            onClick={(e) => {
              e.preventDefault();
              this.pVDiscModCloseDispModOpen(true, userId);
            }}
            data-dismiss="modal"
          >
            <span>View</span>
          </button>
        </div>
      </div>
    );

    this.modalforpvdisclaimerRef.current.updateStateContent(viewData);
    this.modalforpvdisclaimerRef.current.showContent();
    return viewData;
  };

  /**
   * SplitScreenProfessionalsInterested
   * Function name : pVDisclaimerModalOpen
   * Author        :
   * Created Date  : 9-7-2021
   * Purpose       : To open pVDisclaimer modal
   * Params        : userId
   **/
  pVDisclaimerModalOpen = (userId) => {
    this.isComponentLoaded &&
      this.setState({ showPVDisclaimerModal: true }, () => {
        this.modalforpvdisclaimerRef.current.showModal();
        this.getPVDisclaimerModalContent(userId);
      });
  };

  /*** Professional View Disclaimer Modal ends  */

  getListStructure() {
    let respARObj = [];

    let taskName = "";
    let taskDesc = "";
    let sharedData =
      this.context !== undefined && this.context.share !== undefined
        ? this.context.share
        : null;
    let setShare =
      this.context !== undefined && this.context.setShare !== undefined
        ? this.context.setShare
        : null;

    if (
      sharedData.other !== undefined &&
      sharedData.other.taskResp !== undefined &&
      sharedData.other.taskResp.taskName !== undefined
    ) {
      taskName = sharedData.other.taskResp.taskName
        ? sharedData.other.taskResp.taskName
        : "";
    }

    if (
      sharedData.other !== undefined &&
      sharedData.other.taskResp !== undefined &&
      sharedData.other.taskResp.description !== undefined
    ) {
      taskDesc = sharedData.other.taskResp.description
        ? sharedData.other.taskResp.description
        : "";
    }

    this.state.listData.length > 0 &&
      Object.keys(this.state.listData).map((e, i) => {
        let lawyerNameData = "";
        let lawyerFnameData = "";
        let lawyerLnameData = "";
        let profilePictureURL = null;
        let userId = null;

        lawyerFnameData =
          this.state.listData[i].userDetails.firstName !== undefined &&
          this.state.listData[i].userDetails.firstName !== null
            ? this.state.listData[i].userDetails.firstName
            : "";

        lawyerLnameData =
          this.state.listData[i].userDetails.lastName !== undefined &&
          this.state.listData[i].userDetails.lastName !== null
            ? this.state.listData[i].userDetails.lastName
            : "";

        lawyerNameData = lawyerFnameData + " " + lawyerLnameData;

        profilePictureURL =
          this.state.listData[i].userDetails.profilePictureURL !== undefined
            ? this.state.listData[i].userDetails.profilePictureURL
            : null;

        let chatStButtonDisableFlag =
          (this.state.listData[i].negotiationStatus.assignedTo !== undefined &&
            this.state.listData[i].negotiationStatus.assignedTo !== null &&
            this.state.listData[i].negotiationStatus.assignedTo.userId !=
              this.state.listData[i].userDetails.userId) ||
          this.state.listData[i].isTaskOfferDeclined === true
            ? true
            : false;

        userId = this.state.listData[i].userDetails.userId
          ? this.state.listData[i].userDetails.userId
          : null;

        respARObj.push(
          <div key={i} className="assing_listBox_bg">
            <div className="assList_topSec">
              <div className="assList_topLeft assList_topLeft_new">
                <div className="ass_user_block">
                  <div className="user_imgName">
                    <div className="ass_user_img">
                      {profilePictureURL !== null && (
                        <img src={profilePictureURL} />
                      )}
                      {profilePictureURL === null && (
                        <div className="user_name_text">
                          <span>{getStringInitials(lawyerNameData)}</span>
                        </div>
                      )}
                    </div>
                    <div className="ass_user_name">
                      <button
                        className="btn_appearance_none assignUser_nameBtn"
                        // type="button"
                        // data-toggle="dropdown"
                        onClick={(evn) => {
                          evn.preventDefault();
                          this.pVDisclaimerModalOpen(userId);
                        }}
                      >
                        {lawyerNameData}
                      </button>
                      {/* {this.loadLawyerInfoPopup(i, {})} */}
                    </div>
                    <div className="ass_userName_new">
                      {((this.state.listData[i].unreadBudgetChat !==
                        undefined &&
                        this.state.listData[i].unreadBudgetChat > 0) ||
                        (this.state.listData[i].unreadDueDateChat !==
                          undefined &&
                          this.state.listData[i].unreadDueDateChat > 0)) && (
                        <span className="taskstat_clr_span_new">New</span>
                      )}

                      {/* Task offered or Task assigned text  */}

                      {(this.state.listData[i].isTaskOfferReceived == true ||
                        (this.state.listData[i].negotiationStatus.assignedTo !==
                          undefined &&
                          this.state.listData[i].negotiationStatus
                            .assignedTo !== null &&
                          this.state.listData[i].negotiationStatus.assignedTo
                            .userId ==
                            this.state.listData[i].userDetails.userId)) && (
                        <span
                          className={`taskstat_clr_span professional_tag
                                ${
                                  this.state.listData[i].isTaskOfferReceived ===
                                    true &&
                                  this.state.listData[i].isTaskOfferDeclined ===
                                    false &&
                                  this.state.listData[i].negotiationStatus
                                    .assignedTo !== undefined &&
                                  ((this.state.listData[i].negotiationStatus
                                    .assignedTo !== null &&
                                    this.state.listData[i].negotiationStatus
                                      .assignedTo.userId !==
                                      this.state.listData[i].userDetails
                                        .userId) ||
                                    this.state.listData[i].negotiationStatus
                                      .assignedTo === null)
                                    ? " taskstat_clr_orange "
                                    : ""
                                }
                                 ${
                                   this.state.listData[i].negotiationStatus
                                     .assignedTo !== undefined &&
                                   this.state.listData[i].negotiationStatus
                                     .assignedTo !== null &&
                                   this.state.listData[i].negotiationStatus
                                     .assignedTo.userId ==
                                     this.state.listData[i].userDetails.userId
                                     ? " taskstat_clr_green "
                                     : ""
                                 } 
                                 
                                ${
                                  this.state.listData[i].isTaskOfferReceived ===
                                    true &&
                                  this.state.listData[i].isTaskOfferDeclined ===
                                    true
                                    ? " taskstat_clr_gray_declinedBy_lp "
                                    : ""
                                }                                 
                                
                                `}
                        >
                          <span class="taskstat_clr_spanName">
                            {this.state.listData[i].isTaskOfferReceived ===
                              true &&
                            this.state.listData[i].isTaskOfferDeclined ===
                              false &&
                            this.state.listData[i].negotiationStatus
                              .assignedTo !== undefined &&
                            ((this.state.listData[i].negotiationStatus
                              .assignedTo !== null &&
                              this.state.listData[i].negotiationStatus
                                .assignedTo.userId !==
                                this.state.listData[i].userDetails.userId) ||
                              this.state.listData[i].negotiationStatus
                                .assignedTo === null)
                              ? " Task Offered "
                              : ""}
                            {this.state.listData[i].negotiationStatus
                              .assignedTo !== undefined &&
                            this.state.listData[i].negotiationStatus
                              .assignedTo !== null &&
                            this.state.listData[i].negotiationStatus.assignedTo
                              .userId ==
                              this.state.listData[i].userDetails.userId
                              ? " Task Assigned "
                              : ""}
                            {this.state.listData[i].isTaskOfferReceived ===
                              true &&
                            this.state.listData[i].isTaskOfferDeclined === true
                              ? " Offer Declined "
                              : ""}
                          </span>
                        </span>
                      )}

                      {/* <span className={`taskstat_clr_span professional_tag
                              ${this.state.listData[e].taskType=="SAVED_DRAFT"?"taskstat_clr_gray":""}
                              ${this.state.listData[e].taskType=="OPEN_TASK"?"taskstat_clr_sky":""}
                              ${(this.state.listData[e].taskType=="TASK_OFFERED")?"taskstat_clr_orange":""}
                              ${(this.state.listData[e].taskType=="TASK_ASSIGNED" )?"taskstat_clr_green":""}
                              ${(this.state.listData[e].taskType=="INVOICE_DUE" )?"taskstat_clr_red":""}
                              ${(this.state.listData[e].taskType=="INVOICE_PAID")?"taskstat_clr_deepGreen":""} 
                              ${(this.state.listData[e].taskType=="CLOSED_TASK")?"taskstat_clr_blue":""}           

                           ` }> 
                           <span className="taskstat_clr_spanName">
                            {this.state.listData[e].taskType=="SAVED_DRAFT"?"Saved Draft":""}
                            {this.state.listData[e].taskType=="OPEN_TASK"?"Open task":""}
                            {(this.state.listData[e].taskType=="TASK_OFFERED")?"Task offered":""}
                            {(this.state.listData[e].taskType=="TASK_ASSIGNED"   )?"Task assigned":""}
                            {(this.state.listData[e].taskType=="INVOICE_DUE" )?"Invoice due":""}
                            {(this.state.listData[e].taskType=="INVOICE_PAID")?"Invoic paid":""}
                            {(this.state.listData[e].taskType=="CLOSED_TASK")?"Closed task":""}                   
                           </span>
                        </span> */}
                    </div>
                  </div>
                </div>
                <div className="userRating_block userRating_block_sec">
                  <div className="userRating_block userRating_block_left">
                    <div className="ass_userRating">
                      <i className="fa fa-star" aria-hidden="true" />
                      {this.state.listData[i].userDetails.rating
                        ? this.state.listData[i].userDetails.rating
                        : "0"}
                    </div>
                    <div className="ass_userExp">
                      Experience -
                      {this.state.listData[i].userDetails.workExperience
                        ? this.state.listData[i].userDetails.workExperience +
                          " years"
                        : "0 years"}
                    </div>
                  </div>
                  {/* from here for tick */}

                  <div class="userRating_block_right">
                    <div class="prof_int_greenTick_block">
                      {/* budget data related structure */}
                      {this.state.listData[i].negotiationStatus !== undefined &&
                        this.state.listData[i].negotiationStatus
                          .budgetStatus !== undefined &&
                        this.state.listData[i].negotiationStatus
                          .budgetStatus && (
                          <div class="profInt_greenTick profInt_greenTick_budget">
                            <span class="profInt_greenTick_spantext">
                              {this.state.listData[i].negotiationStatus !==
                                undefined &&
                              this.state.listData[i].negotiationStatus
                                .budgetStatus !== undefined &&
                              this.state.listData[i].negotiationStatus
                                .budgetStatus == "AGREE"
                                ? this.state.listData[i].negotiationStatus
                                    .agreedBudgetCurrency + " "
                                : this.state.listData[i].negotiationStatus !==
                                    undefined &&
                                  this.state.listData[i].negotiationStatus
                                    .budgetStatus !== undefined &&
                                  this.state.listData[i].negotiationStatus
                                    .budgetStatus != "AGREE"
                                ? this.state.listData[i].negotiationStatus
                                    .previousNegotiatedBudgetCurrency + " "
                                : ""}

                              {this.state.listData[i].negotiationStatus !==
                                undefined &&
                              this.state.listData[i].negotiationStatus
                                .budgetStatus !== undefined &&
                              this.state.listData[i].negotiationStatus
                                .budgetStatus == "AGREE"
                                ? this.state.listData[i].negotiationStatus
                                    .agreedBudget
                                : this.state.listData[i].negotiationStatus !==
                                    undefined &&
                                  this.state.listData[i].negotiationStatus
                                    .budgetStatus !== undefined &&
                                  this.state.listData[i].negotiationStatus
                                    .budgetStatus != "AGREE"
                                ? this.state.listData[i].negotiationStatus
                                    .previousNegotiatedBudget
                                : ""}
                            </span>
                            <span class="profInt_greenTick_spanimg">
                              {this.state.listData[i].negotiationStatus !==
                                undefined &&
                                this.state.listData[i].negotiationStatus
                                  .budgetStatus !== undefined &&
                                this.state.listData[i].negotiationStatus
                                  .budgetStatus == "AGREE" && (
                                  <img
                                    src={
                                      getBaseUrl() +
                                      "/assets/images/green_tick.png"
                                    }
                                    className="mCS_img_loaded"
                                  />
                                )}
                              {this.state.listData[i].negotiationStatus !==
                                undefined &&
                                this.state.listData[i].negotiationStatus
                                  .budgetStatus !== undefined &&
                                this.state.listData[i].negotiationStatus
                                  .budgetStatus != "AGREE" && (
                                  <img
                                    src={
                                      getBaseUrl() +
                                      "/assets/images/orange-negotiate.png"
                                    }
                                    className="mCS_img_loaded"
                                  />
                                )}
                            </span>
                          </div>
                        )}

                      {/* due date data related structure */}
                      {this.state.listData[i].negotiationStatus !== undefined &&
                        this.state.listData[i].negotiationStatus
                          .dueDateStatus !== undefined &&
                        this.state.listData[i].negotiationStatus
                          .dueDateStatus && (
                          <div class="profInt_greenTick profInt_greenTick_date">
                            <span class="profInt_greenTick_spantext">
                              <span class="profInt_greenTick_spantext">
                                {this.state.listData[i].negotiationStatus !==
                                  undefined &&
                                this.state.listData[i].negotiationStatus
                                  .dueDateStatus !== undefined &&
                                this.state.listData[i].negotiationStatus
                                  .dueDateStatus == "AGREE"
                                  ? this.state.listData[i].negotiationStatus
                                      .agreedDueDate
                                    ? CustDate.custFormatDate(
                                        new Date(
                                          this.state.listData[
                                            i
                                          ].negotiationStatus.agreedDueDate
                                        ),
                                        3
                                      )
                                    : "None"
                                  : this.state.listData[i].negotiationStatus !==
                                      undefined &&
                                    this.state.listData[i].negotiationStatus
                                      .dueDateStatus !== undefined &&
                                    this.state.listData[i].negotiationStatus
                                      .dueDateStatus != "AGREE"
                                  ? CustDate.custFormatDate(
                                      new Date(
                                        this.state.listData[
                                          i
                                        ].negotiationStatus.previousNegotiatedDueDate
                                      ),
                                      3
                                    )
                                  : ""}
                              </span>
                            </span>
                            <span class="profInt_greenTick_spanimg">
                              {this.state.listData[i].negotiationStatus !==
                                undefined &&
                                this.state.listData[i].negotiationStatus
                                  .dueDateStatus !== undefined &&
                                this.state.listData[i].negotiationStatus
                                  .dueDateStatus == "AGREE" && (
                                  <img
                                    src={
                                      getBaseUrl() +
                                      "/assets/images/green_tick.png"
                                    }
                                    className="mCS_img_loaded"
                                  />
                                )}
                              {this.state.listData[i].negotiationStatus !==
                                undefined &&
                                this.state.listData[i].negotiationStatus
                                  .dueDateStatus !== undefined &&
                                this.state.listData[i].negotiationStatus
                                  .dueDateStatus != "AGREE" && (
                                  <img
                                    src={
                                      getBaseUrl() +
                                      "/assets/images/orange-negotiate.png"
                                    }
                                    className="mCS_img_loaded"
                                  />
                                )}
                            </span>
                          </div>
                        )}
                    </div>
                    <div class="clearboth"></div>
                  </div>

                  <div className="clearboth"></div>
                </div>
              </div>
              <div className="assList_topRight assList_topRight_new">
                <a
                  className={`${
                    this.state.listData[i].negotiationStatus.assignedTo !==
                      undefined &&
                    this.state.listData[i].negotiationStatus.assignedTo !== null
                      ? "pointer_none"
                      : ""
                  }`}
                  href=""
                  onClick={(evn) => {
                    evn.preventDefault();

                    this.isComponentLoaded &&
                      this.setState({ tags: [] }, () => {
                        let taskRespObj = this.state.listData[i];
                        this.callConflictModalOpen(taskRespObj);
                      });
                  }}
                >
                  <span
                    className={`confilct_condition ${
                      this.state.listData[i].conflictCheckStatus !==
                        undefined &&
                      this.state.listData[i].conflictCheckStatus ==
                        "NOT_STARTED"
                        ? " green_text "
                        : ""
                    }`}
                  >
                    {this.state.listData[i].conflictCheckStatusMessage
                      ? this.state.listData[i].conflictCheckStatusMessage
                      : ""}
                  </span>
                  <span className="top_sug_left">
                    <span
                      className="input_tooltip btn_tooltip"
                      aria-label="Conflict check"
                      data-balloon-pos="left"
                    >
                      <i className="fa fa-question-circle" />
                    </span>
                  </span>
                </a>
              </div>
            </div>

            {/* For task without subtask starts */}
            {this.state.listData[i].negotiationStatus !== undefined &&
              this.state.listData[i].negotiationStatus.subTasks !== undefined &&
              this.state.listData[i].negotiationStatus.subTasks.length == 0 && (
                <div className="assList_bottomSec">
                  <div className="ass_bottomLeft">
                    <p>
                      {this.state.listData[i].negotiationStatus
                        .negotiationMessage !== undefined
                        ? this.state.listData[i].negotiationStatus
                            .negotiationMessage
                        : ""}
                    </p>
                    <p className="termacceptstatusmsgstclass">
                      {this.state.listData[i].negotiationStatus
                        .termsAcceptanceStatus !== undefined &&
                      this.state.listData[i].negotiationStatus
                        .termsAcceptanceStatus.messageToBeDisplayed !==
                        undefined
                        ? this.state.listData[i].negotiationStatus
                            .termsAcceptanceStatus.messageToBeDisplayed
                        : ""}
                    </p>
                  </div>
                  <div className="ass_bottomRight">
                    {/* {(this.state.listData[i].negotiationStatus!==undefined) && (this.state.listData[i].negotiationStatus.budgetStatus!==undefined && this.state.listData[i].negotiationStatus.budgetStatus=="AGREE") && (this.state.listData[i].negotiationStatus.dueDateStatus!==undefined && this.state.listData[i].negotiationStatus.dueDateStatus=="AGREE") && (this.state.listData[i].negotiationStatus.termsAcceptanceStatus!==undefined && this.state.listData[i].negotiationStatus.termsAcceptanceStatus.isTermsAcceptedByLP!==undefined && this.state.listData[i].negotiationStatus.termsAcceptanceStatus.isTermsAcceptedByLP==true) && (this.state.listData[i].negotiationStatus.assignedTo!==undefined && this.state.listData[i].negotiationStatus.assignedTo===null ) && (
                      
                      <AssignButton custclassname="btn_appearance_none sub_grd_btn" callbackfunc={()=>{
                        //this.reloadInterestedList({});
                      }} datas={  {
                        "taskOrSubTask":"STASK",
                        "taskIdOrSubTaskId":this.state.taskId,
                        "taskOrSubTaskName":taskName,
                        "lpUserId":this.state.listData[i].userDetails.userId,
                        "lpUserName":lawyerNameData,
                        "organisationName":getOrgName(),
                        "isTermsAcceptedByLP":((this.state.listData[i].negotiationStatus.termsAcceptanceStatus!==undefined && this.state.listData[i].negotiationStatus.termsAcceptanceStatus.isTermsAcceptedByLP!==undefined && this.state.listData[i].negotiationStatus.termsAcceptanceStatus.isTermsAcceptedByLP!==null)?this.state.listData[i].negotiationStatus.termsAcceptanceStatus.isTermsAcceptedByLP:false),
                        "termsAcceptedOn":((this.state.listData[i].negotiationStatus.termsAcceptanceStatus!==undefined && this.state.listData[i].negotiationStatus.termsAcceptanceStatus.termsAcceptedOn!==undefined && this.state.listData[i].negotiationStatus.termsAcceptanceStatus.termsAcceptedOn!==null)?this.state.listData[i].negotiationStatus.termsAcceptanceStatus.termsAcceptedOn:null),
                        "conflictCheckStatus":this.state.listData[i].conflictCheckStatus,
                        "budgetStatus":((this.state.listData[i].negotiationStatus.budgetStatus!==undefined && this.state.listData[i].negotiationStatus.budgetStatus!==null)?this.state.listData[i].negotiationStatus.budgetStatus:""),
                        "dueDateStatus":((this.state.listData[i].negotiationStatus.dueDateStatus!==undefined && this.state.listData[i].negotiationStatus.dueDateStatus!==null)?this.state.listData[i].negotiationStatus.dueDateStatus:""),
                        "agreedBudget":((this.state.listData[i].negotiationStatus.agreedBudget!==undefined && this.state.listData[i].negotiationStatus.agreedBudget!==null)?this.state.listData[i].negotiationStatus.agreedBudget:null),
                        "agreedBudgetCurrency":((this.state.listData[i].negotiationStatus.agreedBudgetCurrency!==undefined && this.state.listData[i].negotiationStatus.agreedBudgetCurrency!==null)?this.state.listData[i].negotiationStatus.agreedBudgetCurrency:null),
                        "agreedDueDate":((this.state.listData[i].negotiationStatus.agreedDueDate!==undefined && this.state.listData[i].negotiationStatus.agreedDueDate!==null)?this.state.listData[i].negotiationStatus.agreedDueDate:null),

                      
                      } }  />


                      ) } */}

                    {this.state.listData[i].negotiationStatus !== undefined &&
                      this.state.listData[i].negotiationStatus.budgetStatus !==
                        undefined &&
                      this.state.listData[i].negotiationStatus.budgetStatus ==
                        "AGREE" &&
                      this.state.listData[i].negotiationStatus.dueDateStatus !==
                        undefined &&
                      this.state.listData[i].negotiationStatus.dueDateStatus ==
                        "AGREE" &&
                      this.state.listData[i].negotiationStatus.assignedTo !==
                        undefined &&
                      this.state.listData[i].negotiationStatus.assignedTo ===
                        null &&
                      this.state.listData[i].isTaskOfferReceived !==
                        undefined &&
                      this.state.listData[i].isTaskOfferReceived === false && (
                        <OfferTaskButton
                          custclassname="btn_appearance_none sub_grd_btn"
                          controltype="BUTTON"
                          controlnametext="Offer Task"
                          callbackfunc={this.offerTaskButtonCallback}
                          datas={{
                            taskOrSubTask: "STASK",
                            taskIdOrSubTaskId: this.state.taskId,
                            taskOrSubTaskName: taskName,
                            taskDesc: taskDesc,
                            lpUserId: this.state.listData[i].userDetails.userId,
                            lpUserName: lawyerNameData,
                            organisationName: getOrgName(),
                            isOrganisationDetailsMasked:
                              this.state.isOrganisationDetailsMasked !==
                                undefined &&
                              this.state.isOrganisationDetailsMasked !== null
                                ? this.state.isOrganisationDetailsMasked
                                : false,
                            conflictCheckStatus:
                              this.state.listData[i].conflictCheckStatus,
                            budgetStatus:
                              this.state.listData[i].negotiationStatus
                                .budgetStatus !== undefined &&
                              this.state.listData[i].negotiationStatus
                                .budgetStatus !== null
                                ? this.state.listData[i].negotiationStatus
                                    .budgetStatus
                                : "",
                            dueDateStatus:
                              this.state.listData[i].negotiationStatus
                                .dueDateStatus !== undefined &&
                              this.state.listData[i].negotiationStatus
                                .dueDateStatus !== null
                                ? this.state.listData[i].negotiationStatus
                                    .dueDateStatus
                                : "",
                            agreedBudget:
                              this.state.listData[i].negotiationStatus
                                .agreedBudget !== undefined &&
                              this.state.listData[i].negotiationStatus
                                .agreedBudget !== null
                                ? this.state.listData[i].negotiationStatus
                                    .agreedBudget
                                : null,
                            agreedBudgetCurrency:
                              this.state.listData[i].negotiationStatus
                                .agreedBudgetCurrency !== undefined &&
                              this.state.listData[i].negotiationStatus
                                .agreedBudgetCurrency !== null
                                ? this.state.listData[i].negotiationStatus
                                    .agreedBudgetCurrency
                                : null,
                            agreedDueDate:
                              this.state.listData[i].negotiationStatus
                                .agreedDueDate !== undefined &&
                              this.state.listData[i].negotiationStatus
                                .agreedDueDate !== null
                                ? this.state.listData[i].negotiationStatus
                                    .agreedDueDate
                                : null,
                          }}
                        />
                      )}

                    {((this.state.listData[i].negotiationStatus.assignedTo !==
                      undefined &&
                      this.state.listData[i].negotiationStatus.assignedTo ===
                        null) ||
                      (this.state.listData[i].negotiationStatus.assignedTo !==
                        undefined &&
                        this.state.listData[i].negotiationStatus.assignedTo !==
                          null &&
                        this.state.listData[i].negotiationStatus.assignedTo
                          .userId !=
                          this.state.listData[i].userDetails.userId)) && (
                      <button
                        disabled={chatStButtonDisableFlag}
                        onClick={(evn) => {
                          evn.preventDefault();
                          //console.log(" interested userId=>>",this.state.listData[i].userDetails.userId);

                          let dataResp = {
                            data: this.state.listData[i],
                            taskOrSubTask: "STASK",
                            taskIdOrSubTaskId: this.state.taskId,
                            taskOrSubTaskName: taskName,
                            lpUserId: this.state.listData[i].userDetails.userId,
                            lpUserName: lawyerNameData,
                            ccUserId: getCcUserId(),
                            conflictCheckStatus:
                              this.state.listData[i].conflictCheckStatus,
                            assignRel: {
                              taskOrSubTask: "STASK",
                              taskIdOrSubTaskId: this.state.taskId,
                              taskOrSubTaskName: taskName,
                              taskDesc: taskDesc,
                              lpUserId:
                                this.state.listData[i].userDetails.userId,
                              lpUserName: lawyerNameData,
                              organisationName: getOrgName(),
                              isOrganisationDetailsMasked:
                                this.state.isOrganisationDetailsMasked !==
                                  undefined &&
                                this.state.isOrganisationDetailsMasked !== null
                                  ? this.state.isOrganisationDetailsMasked
                                  : false,
                              conflictCheckStatus:
                                this.state.listData[i].conflictCheckStatus,
                              budgetStatus:
                                this.state.listData[i].negotiationStatus
                                  .budgetStatus !== undefined &&
                                this.state.listData[i].negotiationStatus
                                  .budgetStatus !== null
                                  ? this.state.listData[i].negotiationStatus
                                      .budgetStatus
                                  : "",
                              dueDateStatus:
                                this.state.listData[i].negotiationStatus
                                  .dueDateStatus !== undefined &&
                                this.state.listData[i].negotiationStatus
                                  .dueDateStatus !== null
                                  ? this.state.listData[i].negotiationStatus
                                      .dueDateStatus
                                  : "",
                              agreedBudget:
                                this.state.listData[i].negotiationStatus
                                  .agreedBudget !== undefined &&
                                this.state.listData[i].negotiationStatus
                                  .agreedBudget !== null
                                  ? this.state.listData[i].negotiationStatus
                                      .agreedBudget
                                  : null,
                              agreedBudgetCurrency:
                                this.state.listData[i].negotiationStatus
                                  .agreedBudgetCurrency !== undefined &&
                                this.state.listData[i].negotiationStatus
                                  .agreedBudgetCurrency !== null
                                  ? this.state.listData[i].negotiationStatus
                                      .agreedBudgetCurrency
                                  : null,
                              agreedDueDate:
                                this.state.listData[i].negotiationStatus
                                  .agreedDueDate !== undefined &&
                                this.state.listData[i].negotiationStatus
                                  .agreedDueDate !== null
                                  ? this.state.listData[i].negotiationStatus
                                      .agreedDueDate
                                  : null,
                            },
                          };
                          console.log("hello  dataResp==>", dataResp);
                          this.goToNegotiationChat(dataResp);
                        }}
                        className={`btn_appearance_none green_btn ${
                          chatStButtonDisableFlag ? "pointer_none" : ""
                        }`}
                      >
                        <span>Chat</span>
                      </button>
                    )}

                    {this.state.listData[i].negotiationStatus !== undefined &&
                      this.state.listData[i].negotiationStatus.budgetStatus !==
                        undefined &&
                      this.state.listData[i].negotiationStatus.budgetStatus ==
                        "AGREE" &&
                      this.state.listData[i].negotiationStatus.dueDateStatus !==
                        undefined &&
                      this.state.listData[i].negotiationStatus.dueDateStatus ==
                        "AGREE" &&
                      this.state.listData[i].negotiationStatus
                        .termsAcceptanceStatus !== undefined &&
                      this.state.listData[i].negotiationStatus
                        .termsAcceptanceStatus.isTermsAcceptedByLP !==
                        undefined &&
                      this.state.listData[i].negotiationStatus
                        .termsAcceptanceStatus.isTermsAcceptedByLP == true &&
                      this.state.listData[i].negotiationStatus.assignedTo !==
                        undefined &&
                      this.state.listData[i].negotiationStatus.assignedTo !==
                        null &&
                      this.state.listData[i].negotiationStatus.assignedTo
                        .userId ==
                        this.state.listData[i].userDetails.userId && (
                        <button
                          onClick={(evn) => {
                            evn.preventDefault();

                            sharedData.leftTabToActive = "MONITORTASK";
                            sharedData.purpose = "VIEW_RESPONSE_LIST";
                            sharedData.other.triggeredFrom =
                              "PROFESSIONAL_INTERESTED_LIST";
                            setShare(sharedData);
                          }}
                          className="btn_appearance_none sub_grd_btn"
                        >
                          <span>Task Mailbox </span>
                        </button>
                      )}
                  </div>
                </div>
              )}
            {/* For task without subtask ends */}

            {/* For task with subtask  starts*/}
            {this.state.listData[i].negotiationStatus !== undefined &&
              this.state.listData[i].negotiationStatus.subTasks !== undefined &&
              this.state.listData[i].negotiationStatus.subTasks.length > 0 &&
              Object.keys(
                this.state.listData[i].negotiationStatus.subTasks
              ).map((e, ind) => (
                <div key={indexedDB} className="assList_bottomSec">
                  <div className="ass_bottomLeft">
                    <p>
                      <b>
                        {this.state.listData[i].negotiationStatus.subTasks[ind]
                          .subTaskName
                          ? this.state.listData[i].negotiationStatus.subTasks[
                              ind
                            ].subTaskName
                          : ""}
                      </b>
                    </p>
                    <p>
                      {this.state.listData[i].negotiationStatus.subTasks[ind]
                        .negotiationMessage
                        ? this.state.listData[i].negotiationStatus.subTasks[ind]
                            .negotiationMessage
                        : ""}
                    </p>
                    <p className="termacceptstatusmsgsstclass">
                      {this.state.listData[i].negotiationStatus.subTasks[ind]
                        .termsAcceptanceStatus !== undefined &&
                      this.state.listData[i].negotiationStatus.subTasks[ind]
                        .termsAcceptanceStatus.messageToBeDisplayed !==
                        undefined
                        ? this.state.listData[i].negotiationStatus.subTasks[ind]
                            .termsAcceptanceStatus.messageToBeDisplayed
                        : ""}
                    </p>
                  </div>
                  <div className="ass_bottomRight">
                    {/* { (this.state.listData[i].negotiationStatus.subTasks[ind].budgetStatus!==undefined && this.state.listData[i].negotiationStatus.subTasks[ind].budgetStatus=="AGREE") && (this.state.listData[i].negotiationStatus.subTasks[ind].dueDateStatus!==undefined && this.state.listData[i].negotiationStatus.subTasks[ind].dueDateStatus=="AGREE")  && (this.state.listData[i].negotiationStatus.subTasks[ind].termsAcceptanceStatus!==undefined && this.state.listData[i].negotiationStatus.subTasks[ind].termsAcceptanceStatus.isTermsAcceptedByLP!==undefined && this.state.listData[i].negotiationStatus.subTasks[ind].termsAcceptanceStatus.isTermsAcceptedByLP==true) && (this.state.listData[i].negotiationStatus.subTasks[ind].assignedTo!==undefined && this.state.listData[i].negotiationStatus.subTasks[ind].assignedTo===null ) && (
                      

                      <AssignButton custclassname="btn_appearance_none sub_grd_btn" callbackfunc={()=>{
                        //this.reloadInterestedList({});
                      }} datas={  {
                        "lpUserInterestedData":{"userDetails":this.state.listData[i].userDetails,"negotiationStatus":this.state.listData[i].negotiationStatus.subTasks[ind],"conflictCheckStatus":this.state.listData[i].conflictCheckStatus,"conflictCheckStatusMessage":this.state.listData[i].conflictCheckStatusMessage },
                        "taskOrSubTask":"SWSTASK",
                        "taskIdOrSubTaskId":this.state.listData[i].negotiationStatus.subTasks[ind].id,
                        "taskOrSubTaskName":((this.state.listData[i].negotiationStatus.subTasks[ind].subTaskName)? (this.state.listData[i].negotiationStatus.subTasks[ind].subTaskName): ""),
                        "taskName":taskName,
                        "lpUserId":this.state.listData[i].userDetails.userId,
                        "lpUserName":lawyerNameData,
                        "ccUserId": getCcUserId()
                    } }  />


                  ) } */}

                    {((this.state.listData[i].negotiationStatus.subTasks[ind]
                      .assignedTo !== undefined &&
                      this.state.listData[i].negotiationStatus.subTasks[ind]
                        .assignedTo !== null &&
                      this.state.listData[i].negotiationStatus.subTasks[ind]
                        .assignedTo.userId !=
                        this.state.listData[i].userDetails.userId) ||
                      (this.state.listData[i].negotiationStatus.subTasks[ind]
                        .assignedTo !== undefined &&
                        this.state.listData[i].negotiationStatus.subTasks[ind]
                          .assignedTo === null)) && (
                      <button
                        disabled={
                          (this.state.listData[i].negotiationStatus.subTasks[
                            ind
                          ].assignedTo !== undefined &&
                            this.state.listData[i].negotiationStatus.subTasks[
                              ind
                            ].assignedTo !== null &&
                            this.state.listData[i].negotiationStatus.subTasks[
                              ind
                            ].assignedTo.userId ==
                              this.state.listData[i].userDetails.userId) ||
                          (this.state.listData[i].negotiationStatus.subTasks[
                            ind
                          ].assignedTo !== undefined &&
                            this.state.listData[i].negotiationStatus.subTasks[
                              ind
                            ].assignedTo === null)
                            ? false
                            : true
                        }
                        onClick={(evn) => {
                          evn.preventDefault();
                          //console.log(" sub interested userId=>>",this.state.listData[i].userDetails.userId);

                          let lawyerName = "";
                          let lawyerFname = "";
                          let lawyerLname = "";

                          lawyerFname =
                            this.state.listData[i].userDetails.firstName !==
                              undefined &&
                            this.state.listData[i].userDetails.firstName !==
                              null
                              ? this.state.listData[i].userDetails.firstName
                              : "";

                          lawyerLname =
                            this.state.listData[i].userDetails.lastName !==
                              undefined &&
                            this.state.listData[i].userDetails.lastName !== null
                              ? this.state.listData[i].userDetails.lastName
                              : "";

                          lawyerName = lawyerFname + " " + lawyerLname;

                          let subTaskName = "";
                          subTaskName = this.state.listData[i].negotiationStatus
                            .subTasks[ind].subTaskName
                            ? this.state.listData[i].negotiationStatus.subTasks[
                                ind
                              ].subTaskName
                            : "";

                          let dataResp = {
                            data: {
                              userDetails: this.state.listData[i].userDetails,
                              negotiationStatus:
                                this.state.listData[i].negotiationStatus
                                  .subTasks[ind],
                              conflictCheckStatus:
                                this.state.listData[i].conflictCheckStatus,
                              conflictCheckStatusMessage:
                                this.state.listData[i]
                                  .conflictCheckStatusMessage,
                            },
                            taskOrSubTask: "SWSTASK",
                            taskIdOrSubTaskId:
                              this.state.listData[i].negotiationStatus.subTasks[
                                ind
                              ].id,
                            taskOrSubTaskName: subTaskName,
                            taskName: taskName,
                            lpUserId: this.state.listData[i].userDetails.userId,
                            lpUserName: lawyerName,
                            ccUserId: getCcUserId(),
                          };

                          this.goToNegotiationChat(dataResp);
                        }}
                        className="btn_appearance_none green_btn"
                      >
                        <span>Chat</span>
                      </button>
                    )}

                    {this.state.listData[i].negotiationStatus.subTasks[ind]
                      .budgetStatus !== undefined &&
                      this.state.listData[i].negotiationStatus.subTasks[ind]
                        .budgetStatus == "AGREE" &&
                      this.state.listData[i].negotiationStatus.subTasks[ind]
                        .dueDateStatus !== undefined &&
                      this.state.listData[i].negotiationStatus.subTasks[ind]
                        .dueDateStatus == "AGREE" &&
                      this.state.listData[i].negotiationStatus.subTasks[ind]
                        .termsAcceptanceStatus !== undefined &&
                      this.state.listData[i].negotiationStatus.subTasks[ind]
                        .termsAcceptanceStatus.isTermsAcceptedByLP !==
                        undefined &&
                      this.state.listData[i].negotiationStatus.subTasks[ind]
                        .termsAcceptanceStatus.isTermsAcceptedByLP == true &&
                      this.state.listData[i].negotiationStatus.subTasks[ind]
                        .assignedTo !== undefined &&
                      this.state.listData[i].negotiationStatus.subTasks[ind]
                        .assignedTo !== null && (
                        <button
                          onClick={(evn) => {
                            evn.preventDefault();

                            sharedData.leftTabToActive = "MONITORTASK";
                            sharedData.purpose = "VIEW_RESPONSE_LIST";
                            sharedData.other.triggeredFrom =
                              "PROFESSIONAL_INTERESTED_LIST";
                            setShare(sharedData);
                          }}
                          className="btn_appearance_none sub_grd_btn"
                        >
                          <span>Task Mailbox</span>
                        </button>
                      )}
                  </div>
                </div>
              ))}
            {/* For task with subtask  ends*/}
          </div>
        );
      });

    //

    // );

    return <>{respARObj} </>;
  }

  // Newest1 - End

  offerTaskButtonCallback = (data) => {
    let activity = data.activity !== undefined ? data.activity : "";
    if (activity == "OFFER_SENT_SUCCESS") {
      // show green success message

      notify("Offer has been sent to the professional", true, {
        successMsg: 1,
        customWrapClass: "customerrmidlass alert_section_top_zero",
        msgKeyData: "profInterrestedNotiK1",
        showCloseButton: false,

        dismiss: {
          duration: 1700,
        },
      });

      this.reloadInterestedList({});
    }
  };

  handleTagChange = (tags) => {
    let tagsData = tags;
    let confContinueDisabled = tagsData.length > 0 ? false : true;

    this.isComponentLoaded &&
      this.setState(
        { tags: tags, confContinueDisabled: confContinueDisabled },
        () => {
          let paramObj = {
            lawyerUserIdForConflChk: this.state.lawyerUserIdForConflChk,
            lawyerNameForConflChk: this.state.lawyerNameForConflChk,
            taskId: this.state.taskId,
            lawyerConflictCheckStatus: this.state.lawyerConflictCheckStatus,
          };
          this.getConflictModalContent(paramObj);
        }
      );
  };

  callConflictModalOpen = (taskRespObj) => {
    let lawyerFname =
      taskRespObj.userDetails.firstName !== undefined &&
      taskRespObj.userDetails.firstName !== null
        ? taskRespObj.userDetails.firstName
        : "";

    let lawyerLname =
      taskRespObj.userDetails.lastName !== undefined &&
      taskRespObj.userDetails.lastName !== null
        ? taskRespObj.userDetails.lastName
        : "";

    let lawyerName = lawyerFname + " " + lawyerLname;
    //lawyerName = getStringInitials(lawyerName);

    let userId =
      taskRespObj.userDetails !== undefined &&
      taskRespObj.userDetails.userId !== undefined
        ? taskRespObj.userDetails.userId
        : 0;

    //console.log(" taskRespObj==>",taskRespObj, " taskRespObj.userDetails==>",taskRespObj.userDetails);
    if (
      taskRespObj !== undefined &&
      taskRespObj !== null &&
      taskRespObj.conflictCheckStatus !== undefined &&
      taskRespObj.conflictCheckStatus == "NOT_STARTED"
    ) {
      let paramObj = {
        lawyerUserIdForConflChk: userId,
        lawyerNameForConflChk: lawyerName,
        taskId: this.state.taskId,
        lawyerConflictCheckStatus: taskRespObj.conflictCheckStatus,
        confContinueDisabled: true,
      };
      this.isComponentLoaded &&
        this.setState(paramObj, () => {
          this.conflictModalOpen(paramObj);
        });
    } else if (
      taskRespObj !== undefined &&
      taskRespObj !== null &&
      taskRespObj.conflictCheckStatus !== undefined &&
      taskRespObj.conflictCheckStatus == "FAIL"
    ) {
      let paramObj = {
        lawyerUserIdForConflChk: userId,
        lawyerNameForConflChk: lawyerName,
        taskId: this.state.taskId,
        lawyerConflictCheckStatus: taskRespObj.conflictCheckStatus,
        confContinueDisabled: true,
      };
      this.isComponentLoaded &&
        this.setState(paramObj, () => {
          this.conflictModalOpen(paramObj);
        });
    }
  };

  callCheckConflictService = (otherParam) => {
    let paramObjData = [];
    paramObjData["organisationNames"] = this.state.tags;
    paramObjData["userId"] = otherParam.lawyerUserIdForConflChk;
    paramObjData["taskId"] = otherParam.taskId;

    let paramObj = { data: paramObjData };

    let lservdrf = new ConflictCheckService();
    lservdrf
      .initiateConflictCheck(paramObj)
      .then((data) => {
        let entityObj = data.data.entity;
        //console.log("entityObj=>",entityObj);

        // show success

        let notiMsg = "Conflict check request submitted successfully.";
        notify(notiMsg, true, {
          successMsg: 1,
          customWrapClass: "customerrmidlass",
          showCloseButton: false,

          dismiss: {
            duration: 1500,
          },
        });

        // reload listing

        setTimeout(() => {
          // close the modal
          this.conflictModalClose();

          // set list data to blank state
          this.showLoader();

          // call list api  but before that reset it
          let paramObj = {
            listData: [],
            pageNum: 1,
            pageNumArTrack: [],
            totalNumberOfPages: 1,
            taskId: this.state.taskId,
            lawyerUserIdForConflChk: 0,
            lawyerNameForConflChk: "",
            tags: [],
          };
          this.isComponentLoaded &&
            this.setState(paramObj, () => {
              setTimeout(() => {
                this.callServiceToFetchInterestedProfRespList(
                  paramObj,
                  "*****called from componentDidMount**** "
                );
              }, 750);
            });
        }, 1700);
      })
      .catch((err) => {
        let errStatusCodeAr = [400, 500]; // allowed error status code
        if (err.response && err.response.data) {
          let erRespData = err.response.data;
          if (errStatusCodeAr.indexOf(erRespData.status) !== -1) {
            let errMsg = erRespData.message
              ? erRespData.message
              : "Some technical problem occurred.";
            //console.log.log(" errMsg on task creation==>",errMsg);

            let notiMsg = errMsg;
            notify(notiMsg, true, {
              successMsg: 0,
              customWrapClass: "customerrmidlass",
              showCloseButton: false,

              dismiss: {
                duration: 1500,
              },
            });
          }
        }
      })
      .finally(() => {
        this.isComponentLoaded &&
          this.setState({ confContinueDisabled: false }, () => {
            this.getConflictModalContent(otherParam);
          });
      });
  };

  chkBeforeConflictService = (otherParam) => {
    // validation if needed can be put

    this.isComponentLoaded &&
      this.setState({ confContinueDisabled: true }, () => {
        this.getConflictModalContent(otherParam);
      });
    this.callCheckConflictService(otherParam);
  };

  /*** Conflict modal starts  */
  /**
   * SplitScreenProfessionalsInterested
   * Function name : conflictModalOpen
   * Author        :
   * Created Date  : 29-3-2020
   * Purpose       : To open conflict modal
   * Params        :
   **/
  conflictModalOpen = (otherParam) => {
    this.isComponentLoaded &&
      this.setState({ showConflictModal: true }, () => {
        this.modalconflictRef.current.showModal();
        this.getConflictModalContent(otherParam);
      });
  };

  /**
   * SplitScreenProfessionalsInterested
   * Function name : conflictModalClose
   * Author        :
   * Created Date  : 11-2-2020
   * Purpose       : To close conflict modal
   * Params        :
   **/
  conflictModalClose = () => {
    this.isComponentLoaded &&
      this.setState({ showConflictModal: false }, () => {
        this.modalconflictRef.current.hideModal();
      });
  };

  /**
   * SplitScreenProfessionalsInterested
   * Function name : getConflictModalContent
   * Author        :
   * Created Date  : 11-2-2020
   * Purpose       : Get modal view with data
   * Params        :
   **/
  getConflictModalContent(otherParam) {
    console.log(" from getConflictModalContent otherParam==>", otherParam);

    let lawyerConflictCheckStatus =
      otherParam.lawyerConflictCheckStatus !== undefined
        ? otherParam.lawyerConflictCheckStatus
        : "";

    let viewData = (
      <div className="dash_modal conflictpopbdyoutercls">
        <>
          <div className="modal-header">
            <h4 className="modal-title modal_title_tool">
              Conflict check for
              {otherParam.lawyerNameForConflChk
                ? otherParam.lawyerNameForConflChk
                : ""}
              {/*<div className="input_tooltip_bg">
                        <span className="input_tooltip" aria-label="Organisation's Details" data-balloon-pos="down">
                        <i className="fa fa-question-circle"></i>
                        </span>
                        </div>*/}
            </h4>
            <button
              type="button"
              onClick={() => {
                this.conflictModalClose();
              }}
              className="close modal_close"
              data-dismiss="modal"
            >
              &times;
            </button>
          </div>
          <CustomMessageBar />
          <div className="modal-body padding_top">
            <div className="row conflctpopcontentcls">
              <div className="conflict_popText">
                {lawyerConflictCheckStatus === "FAIL" && <p>Lorem Lipsum ..</p>}
                {lawyerConflictCheckStatus === "NOT_STARTED" && (
                  <p>
                    Please enter the
                    <b>full name of the organisation(s) / person(s)</b> that you
                    want to check conflict with. Flywork will perform a manual
                    check and will revert within 2 working days. We will be
                    using only the information that you have provided and not
                    any popular variations. When you click on "Start Conflict
                    Check", you will automatically be charged the equivalent of
                    <b>INR 500 per name</b>. Please check Terms and Conditions -
                    Conflict Check for further conditions that may apply here.
                  </p>
                )}
                <p>
                  Type organisation name & hit enter to add multiple
                  organisation names
                </p>
              </div>

              {lawyerConflictCheckStatus === "NOT_STARTED" && (
                <>
                  <div className="conflict_inputBox">
                    <TagsInput
                      value={this.state.tags}
                      onChange={this.handleTagChange}
                    />
                  </div>
                  <div className="conflict_tramCond_sec">
                    <a
                      href={
                        getBaseUrl() + "terms_privacy/terms_organisation.pdf"
                      }
                      target="_blank"
                    >
                      Terms and Conditions - Conflict Check
                    </a>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="modal-footer">
            {lawyerConflictCheckStatus === "NOT_STARTED" && (
              <button
                disabled={this.state.confContinueDisabled}
                type="button"
                className="btn btn-primary modal_btn_save"
                onClick={(e) => {
                  e.preventDefault();

                  this.chkBeforeConflictService(otherParam);
                }}
                data-dismiss="modal"
              >
                <span>Start Conflict Check</span>
              </button>
            )}
          </div>
        </>
      </div>
    );

    this.modalconflictRef.current.updateStateContent(viewData);
    this.modalconflictRef.current.showContent();
    return viewData;
  }

  /*** Conflict  modal related ends  */

  /**
   * SplitScreenSingleTaskDetails
   * Function name : render
   * Author        :
   * Created Date  : 7-2-2020
   * Purpose       : lifecycle method
   * Params        :
   **/
  render() {
    return (
      <React.Fragment>
        <NotiEmitTrack
          callbackfuncforcommontrackofnoti={this.reloadProfListOnRealTimeNoti}
        />

        {this.loadTheContent()}

        {/* Conflict modal start */}
        <CustReactBootstrapModal
          ref={this.modalconflictRef}
          backdrop={false}
          backdropClassName="custom_modal_class"
          animation={false}
          show={this.state.showConflictModal}
          onShow={() => {}}
          onHide={() => {}}
          modalcustdialog="custom_modal_lg custom_conflict_popupcls"
          loaderbodytitle="Organisation"
        />
        {/* Conflict delete modal end */}

        {/* Newest1 - Start */}

        {/* Professional View Disclaimer Modal start */}
        <CustReactBootstrapModal
          ref={this.modalforpvdisclaimerRef}
          backdrop={false}
          backdropClassName="custom_modal_class"
          animation={false}
          show={this.state.showPVDisclaimerModal}
          onShow={() => {}}
          onHide={() => {}}
          modalcustdialog="custom_modal_lg modal-dialog-centered modal_newProfileViewConfirmation"
          loaderbodytitle="Organisation"
        />
        {/* Professional View Disclaimer Modal end */}

        {/* Professional View Display Modal start */}
        <CustReactBootstrapModal
          ref={this.modalforpvdisplayRef}
          backdrop={false}
          backdropClassName="custom_modal_class"
          animation={false}
          show={this.state.showPVDisplayModal}
          onShow={() => {}}
          onHide={() => {}}
          modalcustdialog="custom_modal_lg modal-dialog-centered modal_newProfileViewFullDetails"
          loaderbodytitle="Organisation"
        />
        {/* Professional View Display Modal end */}

        {/* Newest1 - End */}
      </React.Fragment>
    );
  }
}

export default SplitScreenProfessionalsInterested;
