/**  
 * TaskListForSingleTaskService
 * Page/Class name   : TaskListForSingleTaskService
 * Author            :
 * Created Date      : 26-3-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, getTweetRecords
 * Purpose           : api service page
**/
import {Component } from 'react';
// import {getEndPoint,getTokenFromLocStorage, callApi} from  '../../common_utilities/commonjsfunc';
// newly added axios
//import axios from 'axios';
import {axiosCustomObj} from '../customisedthirdparty/AxiosCustom';

import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
const callApi = commonJsObj.callApi;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;
const getMaxPageSize = commonJsObj.getMaxPageSize;


class TaskListForSingleTaskService extends Component {

    
    /**  
     * TaskListForSingleTaskService
     * Function name : constructor
     * Author        :
     * Created Date  : 24-3-2020
     * Purpose       : initializing state
     * Params        : 
    **/
    constructor() {
      super();
      this.state = {
          
      }

      //this.axiosObj = axios;
      this.axiosObj = null;
      
      this.apiEndPoint = commonJsObj.getEndPoint()+'rest/tasks/dashboard_tasks';
    }

    /**  
     * TaskListForSingleTaskService
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 24-3-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentDidMount() {
    }

    /**  
     * TypeOfProfessionalsService
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 4-3-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentWillUnmount() {  
    }



    /**  
     * TaskListForSingleTaskService
     * Function name : getLoginResp
     * Author        :
     * Created Date  : 24-3-2020
     * Purpose       : Login check to provide access token 
     * Params        : paramObj
    **/
    async getSingleTaskList(paramObj){
      this.axiosObj = axiosCustomObj({});
      let accToken = getTokenFromLocStorage();   
      let offsetOfLocalDateTime = getOffsetOfLocalDateTime(new Date());

      try{
            let urldata =this.apiEndPoint; 

            const defaultPageSize = getMaxPageSize();
            let pageNum = paramObj.pageNum?paramObj.pageNum:1;
            let pageSize = paramObj.pageSize?paramObj.pageSize:defaultPageSize;

            let offsetHr = offsetOfLocalDateTime.hr;
            let offsetMin = offsetOfLocalDateTime.min;


            let filterOptionKey = paramObj.filterOption.key;
            let sortOptionKey = paramObj.sortOption.key;
            let sortOptionDir = paramObj.sortOption.key2;

            urldata = urldata+"/page_number/"+pageNum+"/page_size/"+pageSize;
            urldata = urldata+"?kind="+filterOptionKey+"&sort_on="+sortOptionKey+"&sort_dir="+sortOptionDir+"&offset_hour="+offsetHr+"&offset_minute="+offsetMin;

            //console.log("task single list api hitted with =>>",urldata);


            let headerOptn = {  
              "Content-Type": "application/json",
              "Authorization": accToken
            };          

            let respObj = this.axiosObj.get(urldata,{
            headers: headerOptn
            });

            return respObj;
    }
    catch(err){
        console.log("Some error occured===>",err); // TypeError: failed to fetch
    }
      

    }

    
}
export default TaskListForSingleTaskService;