/**  
 * TaskUpdateService
 * Page/Class name   : TaskUpdateService
 * Author            :
 * Created Date      : 13-3-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, getTweetRecords
 * Purpose           : api service page
**/
import {Component } from 'react';
// import {getEndPoint,getTokenFromLocStorage, callApi} from  '../../common_utilities/commonjsfunc';
// newly added axios
//import axios from 'axios';
import {axiosCustomObj} from '../customisedthirdparty/AxiosCustom';

import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
const callApi = commonJsObj.callApi;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;



class TaskUpdateService extends Component {

    
    /**  
     * TaskUpdateService
     * Function name : constructor
     * Author        :
     * Created Date  : 13-3-2020
     * Purpose       : initializing state
     * Params        : 
    **/
    constructor() {
      super();
      this.state = {
          
      }

      //this.axiosObj = axios;
      this.axiosObj = null;
      
      this.apiEndPoint = commonJsObj.getEndPoint()+'rest/tasks/task';
    }

    /**  
     * DraftTaskService
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 13-3-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentDidMount() {
    }

    /**  
     * CreateTaskService
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 13-3-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentWillUnmount() {  
    }



    /**  
     * CreateTaskService
     * Function name : saveDraftTask
     * Author        :
     * Created Date  : 13-3-2020
     * Purpose       : saveDraftTask 
     * Params        : paramObj
    **/
    async updateTask(paramObj){
      this.axiosObj = axiosCustomObj({});
      //console.log(" paramObj to update task in service==>", paramObj);
      let accToken = getTokenFromLocStorage();     
      try{
            let urldata =this.apiEndPoint;
            //console.log(" ==dataObj==>",dataObj);

            let dataObj = paramObj.data;            
            let taskId = paramObj.taskId;

            if(taskId!==undefined && taskId!==""){
                // update              
                urldata = urldata+ "/"+taskId;
                let headerOptn = {  
                  "Content-Type": "application/json",
                  "Authorization": accToken
                };          
    
                let respObj = this.axiosObj.put(urldata,dataObj, {
                headers: headerOptn
                });
    
                return respObj;

            }else{
                // create
                let headerOptn2 = {  
                  "Content-Type": "application/json",
                  "Authorization": accToken
                };          
    
                let respObj = this.axiosObj.post(urldata,dataObj, {
                headers: headerOptn2
                });

                return respObj;

            }


            
    }
    catch(err){
        console.log("Some error occured===>",err); // TypeError: failed to fetch
    }
      

    }

    
}
export default TaskUpdateService;