/**
 * SplitScreenTaskDocuments
 * Page/Class name   : SplitScreenTaskDocuments
 * Author            :
 * Created Date      : 7-5-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : render master component
**/
import React, { Component } from 'react';
import Header from '../../share/Header';
import SideBar from '../../share/SideBar';
import {notify} from '../../messagebar/CustomMessageBar';
import CustomMessageBar from '../../messagebar/CustomMessageBar';

import CustReactBootstrapModal from './CustReactBootstrapModal';
import DeleteDocService from '../../service/DeleteDocService';

import ViewDocFileButton from './ViewDocFileButton';

// for context
import CommonContext from '../../context/CommonContext';
// scroll 
// import { Scrollbars } from 'react-custom-scrollbars';

//services
import TaskDocumentServices from '../../service/TaskDocumentServices';
import FileUploadService from '../../service/FileUploadService';
import  CustDate  from '../../../common_utilities/CustDate';
import { commonJsFuncModule as commonJsObj } from '../../../common_utilities/commonjsfunc';
import {commonStaticOptionsJsFuncModule as commonStaticJsObj} from  '../../../common_utilities/commonstaticoptions';


// new scroll
import InfiniteScroll from 'react-infinite-scroll-component';

// For loading CustLoader
import CustLoader from './CustLoader';

import SplitScreenLeftMenu from "../../share/SplitScreenLeftMenu";

// common noti track
import NotiEmitTrack from "./NotiEmitTrack";
import {commonnotitrack} from "./NotiEmitTrack";



const getDateObjFromMilliseconds = commonJsObj.getDateObjFromMilliseconds;
const getTimestampInMillisecondsFromDateObj = commonJsObj.getTimestampInMillisecondsFromDateObj;
const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;
const sortArrayOfJsonByKey = commonJsObj.sortArrayOfJsonByKey;
const getCcUserId = commonJsObj.getCcUserId;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getFileNameOnly = commonJsObj.getFileNameOnly;
const getCommonAllowedFileExt = commonStaticJsObj.getCommonAllowedFileExt;
const getBaseUrl = commonJsObj.getBaseUrl;
const getStringInitials = commonJsObj.getStringInitials;

const filterOptions = commonStaticJsObj.getSplitScreenDocTabFilterOptions();

class SplitScreenTaskDocuments extends Component {

    isComponentLoaded =  false;
    // for context
    static contextType = CommonContext;

    /**  
     * SplitScreenTaskDocuments
     * Function name : constructor
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : initializing children
     * Params        : props
    **/
    constructor(props) {
      super(props);
      this.children = props.children;
      this.fileUpl = React.createRef();
      this.state = {
        "taskId": "",
        "docListData": [],
        "pageNum": 1,
        "pageSize":5,
        "totalNumberOfPages": 1,
        "loaderFlag": true,
        "pageNumArTrack": [],
        "preAssignmentDocumentCount":0,
        "preAssignmentDocumentOwner":'',
        "uploadingFlag": false,
        "isTaskCompleted": false,
        "assignedTo": null,        
        "filter":"" // UPLOADED_BY_ME


      };
      this.scrollerRef = React.createRef();
      this.modalfortaskdelRef = React.createRef();
     
    }

    /**
     * SplitScreenTaskDocuments
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : lifecycle method
     * Params        :
    **/
    componentDidMount() {
      this.isComponentLoaded = true;
      
      //console.log(this.context,"context")
      let taskId = 0;
      let isTaskCompleted = false;
      let assignedTo = null;
     
      let filterData = "";

      let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;
      if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){

        if(sharedData && sharedData.other!==undefined && sharedData.other!==null ){
          let otherData = sharedData.other;
        
          if(otherData){
          

            let taskResp = otherData.taskResp;
            isTaskCompleted = (taskResp.isTaskCompleted!==undefined && taskResp.isTaskCompleted!==null)?taskResp.isTaskCompleted:false;

            taskId = (taskResp.id!==undefined && taskResp.id!==null)?taskResp.id:false;
            assignedTo = (taskResp.assignedTo!==undefined && taskResp.assignedTo!==null)?taskResp.assignedTo:null;
           

            let splitScreenDocTabFilter = (otherData!==null && otherData.splitScreenDocTabFilter!==undefined)?otherData.splitScreenDocTabFilter:null;
                filterData = (splitScreenDocTabFilter!==null && splitScreenDocTabFilter.filter!==undefined)?splitScreenDocTabFilter.filter:"";

                if(filterData!==""){
                    // set to empty to filter from other of context                    
                    otherData["splitScreenDocTabFilter"] =  {"filter":""};   
                    // update to other key               
                    sharedData["other"]=otherData;

                    let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;
                    if(setShare){
                      // set to context
                      setTimeout(()=>{
                        setShare(sharedData);
                      },1200);
                      
                    }
                }
            }
          }
          
      }

      this.setState({"taskId":taskId,"isTaskCompleted":isTaskCompleted,"assignedTo":assignedTo,
       "filter":filterData},() => { console.log("assignedTo=>>>",this.state); this.getAssingmentDoc(); })
    }

   

    getAssingmentDoc = ()=>{
        let lservdrf = new TaskDocumentServices();
        lservdrf.getTaskAssignmentDoc(this.state).then(data =>{

            let respData  = data.data.entity;
            console.log(respData)
            this.setState({
                preAssignmentDocumentCount:respData.preAssignmentDocumentCount,
                preAssignmentDocumentOwner: respData.preAssignmentDocumentOwner
            });
            if(respData.documents!==undefined){
                if(respData.documents.length > 0){
                   // set list data to state
                   let prevRecords = [...this.state.docListData];
                   let currRecords = respData.documents;
                   let concatenateRecords = prevRecords.concat(currRecords);
                   this.isComponentLoaded && this.setState({"docListData":concatenateRecords,"totalNumberOfPages":respData.totalNumberOfPages},()=>{

                   });
                }else{

                    if(this.state.docListData.length==0){
                      this.isComponentLoaded && this.setState({"docListData":[],"totalNumberOfPages":respData.totalNumberOfPages,"pageNum":1});
                    }
                }

            }else{

            }

        }).catch((e)=>{
            console.log("server error=>",e);
        }).finally(()=>{
            this.hideLoader();
        });
    }

    /**  
     * SplitScreenTaskDocuments
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {
      this.isComponentLoaded = false;

    }

    /**  
     * SplitScreenTaskDocuments
     * Function name : componentDidUpdate
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentDidUpdate(prevProps){

    }

    /**  
     * SplitScreenTaskDocuments
     * Function name : closeSplit
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : closeSplit method to close the split screen
     * Params        : 
    **/

    closeSplit = (objParam)=>{
          //********* for context starts *****
          // let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;
          // if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){

          //     if(sharedData.actionType=="SPLITSCREEN" ){

          //       let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;

          //       if(setShare){                              
          //         let paramsCntxt = {
          //           "taskId":"",
          //           "actionType":"",
          //           "leftTabToActive":"",
          //           "purpose":"",
          //           "other":{}
          //         }
          //         setShare(paramsCntxt);
          //       }


          //     }
          // }
          //********* for context ends *****

          commonnotitrack("CLOSE_SPLITSCREEN",{
            "notiType": "CLOSE_SPLITSCREEN",
            "triggerCallback":false,
            "trigerredFrom":"TASK_DOCUMENTS_TAB_SCREEN"
           });

    }


     /**  
     * SplitScreenTaskDocuments
     * Function name : loadLoader
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : load Loader 
     * Params        : 
    **/
    
    loadLoader() {
      return (
          <CustLoader className={'smallLoaderGreen'} loaderText={'Loading..'} />
      );
    }

    /**
     * SplitScreenTaskDocuments
     * Function name : hideLoader
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : Hide loader
     * Params        : 
     **/

    hideLoader() {
      this.isComponentLoaded && this.setState({ loaderFlag: false });
    }

    /**
     * SplitScreenTaskDocuments
     * Function name : showLoader
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : showLoader
     * Params        : 
     **/

    showLoader() {
      this.isComponentLoaded && this.setState({ loaderFlag: true });
    }


    loadfooterOfDocument = () => {

      return (
        <>
          <div className="SlideS_bottombg flexbox-item Slide_chatBottomBg SlideS_bottombg_gray SlideS_bottombg_stickyFooterRightPart SlideS_bottombg_stickyFooterRightPart_documentPage">

          </div>

        </>
      );

    }
    showPreAssingmentDoc= (param)=>{

        let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;
        if(setShare){
                setShare(param.paramsCntxt);
        }
    }

    trackFileSelection = e =>{
        e.preventDefault();
        let fileObj = e.target.files;
  
        let chkFileValidation =  this.validateFileSelected(fileObj);
  
        //this.calUploadFileService(fileObj);
        if(chkFileValidation){
            this.calUploadFileService(fileObj);
        }else{
          this.fileUpl.current.value = "";
        }
    }
    validateFileSelected(fileObj){
        const maxFileUploadAllowed = 5;
        const totAllowedMaxFileUploadSize = 500 * 1024 * 1024; // 500 MB
        const allowedFileExt = getCommonAllowedFileExt();

        let respdataFlag =  true;
        let stepOneStFileUplErMsg = "";

        let nowtotAllowedCntOfFileUpl = maxFileUploadAllowed ;
        if(fileObj.length <= nowtotAllowedCntOfFileUpl){
            let totCurSize  = 0; let totalInvalidExtAr = [];
            Object.keys(fileObj).map((k,i)=>{
                totCurSize = totCurSize + fileObj[k].size;
                let nameFl = fileObj[k].name;
                let extnReceived = nameFl.split('.').pop();
                if(allowedFileExt.indexOf(extnReceived)==-1){
                    totalInvalidExtAr.push(extnReceived);
                }  
            });

            if( (totCurSize) > totAllowedMaxFileUploadSize){
                //error
                respdataFlag = false;
                stepOneStFileUplErMsg = "You have crossed total maximum upload file size.";
            }

        }else{
            //error
            respdataFlag = false;
            stepOneStFileUplErMsg = "You cannot upload more than "+maxFileUploadAllowed+" files in total (It includes previously uploaded files if any).";

        }
        this.isComponentLoaded && this.setState({ "fileUplErMsg": stepOneStFileUplErMsg});
        return respdataFlag;
    }
    async calUploadFileService(fileObj){
        ////console.log.log("chk file fileObj=>>>",fileObj);
        let lserv = new FileUploadService();
        this.isComponentLoaded && this.setState({"uploadingFlag":true});
  
  
        let taskTypeFlag = 0;
  
        let paramObjData = {
            "taskTypeFlag" : taskTypeFlag,
            "data": fileObj,
            "taskId":this.state.taskId,
            "fileUploadedFromPageSection":"DOCUMENTTAB",
            "fileUploadFor": "DOCUMENT",
            "taskAssigned": (this.state.taskId?this.state.taskId:0),
       };
  
        lserv.uploadFiletoServer(paramObjData).then(data =>{
            //this.setStepOneStDocuments();
            //console.log.log("success resp=>",data);
            let entityObj  = data.data.entity;       
            console.log(entityObj)
            // if(data.status==200){
            //     let notiMsg = "Success";
            //     notify(notiMsg,true,{
            //     "successMsg":1,
            //     "customWrapClass":"customerrlass",
            //     "showCloseButton": false,
            //     // "callbackClose": ()=>{
            //     //     this.props.calladdorgusermodalclose();
            //     // },

            //     "dismiss": {
            //     "duration": 1500,
            //     }

            //     });

            // }
            this.setState({docListData:[],pageNum:1,filter:""},()=>{ this.getAssingmentDoc();})

  
        }).catch((err)=>{
                //console.log.log("server error=>",e);
                let errStatusCodeAr = [400,500,403]; // allowed error status code
                if(err.response && err.response.data){
                    let erRespData = err.response.data;
                    if(errStatusCodeAr.indexOf(erRespData.status) !== -1){
                        let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                        //console.log.log(" errMsg on task creation==>",errMsg);
      
                        let notiMsg = errMsg;
                        notify(notiMsg,true,{
                        "successMsg":0,
                        "customWrapClass":"customerrmidlass",
                        "showCloseButton": false,
      
                        "dismiss": {
                        "duration": 2500,
                        }
      
                        });
                    }
                }
        }).finally(()=>{
            this.fileUpl.current.value = "";
            this.isComponentLoaded && this.setState({"uploadingFlag":false});
        });
    }
    deleteModalOpen = (otherParam)=>{
        this.isComponentLoaded &&  this.setState({showDeleteModal:true},()=>{
            this.modalfortaskdelRef.current.showModal();
            this.getDeleteModalContent(otherParam);
        });

    }
    deleteModalClose = ()=>{
        this.isComponentLoaded && this.setState({showDeleteModal:false},()=>{
          this.modalfortaskdelRef.current.hideModal();
        });
    };
    getDeleteModalContent(otherParam) {

        let viewData = <div className="dash_modal">

                <>
            <div className="modal-header">
                <h4 className="modal-title modal_title_tool"> Delete Doc

                </h4>
                <button type="button" onClick={
                    ()=>{
                    this.deleteModalClose();

                }} className="close modal_close" data-dismiss="modal">&times;</button>
            </div>
            <CustomMessageBar />
            <div className="modal-body padding_top">
              <div className="popUP_text_p">
                    <p>
                Are you sure you want to delete this Doc?
                </p>
              </div>
            </div>
            <div className="modal-footer">
               
                <button disabled={this.state.continueDisabled}  type="button" className="btn btn-primary modal_btn_save" onClick={(e)=>{ e.preventDefault();
                   this.isComponentLoaded && this.setState({"continueDisabled":true},()=>{
                    let paramObj = {"taskId":otherParam.taskId,"docId":otherParam.docId}
                      this.deleteDoc(paramObj);

                   });
                } } data-dismiss="modal"><span>Confirm</span></button>
                
            </div>
          

          </>


        </div>;

        this.modalfortaskdelRef.current.updateStateContent(viewData);
        this.modalfortaskdelRef.current.showContent();
        return viewData;
    }

    deleteDoc(paramObj){
        console.log(paramObj)
        let lservdrf = new DeleteDocService();
        lservdrf.deleteDoc(paramObj).then(data =>{

        // to fix scroll issue
        let scobj = this.scrollerRef.current;
        scobj.scrollToTop();

        let notiMsg = "Deleted successfully";
                notify(notiMsg,true,{
                "successMsg":1,
                "customWrapClass":"customerrmidlass",
                "showCloseButton": false,

                "dismiss": {
                "duration": 1000,
                }

                });

        this.isComponentLoaded &&   this.setState({"listData":[]},()=>{


                setTimeout(()=>{
                    // set list data to blank state
                    this.showLoader();
                    // call list api

                    this.isComponentLoaded && this.setState({docListData:[],pageNum:1},()=>{
                    this.getAssingmentDoc();
                  });
                },1200);



              });



              setTimeout(()=>{
                this.deleteModalClose();
              },1150);

            //   setTimeout(()=>{
            //     this.closeSplit();
            //   },1200);



          }).catch((err)=>{
            let errStatusCodeAr = [400,500]; // allowed error status code
            if(err.response && err.response.data){
                let erRespData = err.response.data;
                if(errStatusCodeAr.indexOf(erRespData.status) !== -1){
                    let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                    //console.log.log(" errMsg on task creation==>",errMsg);

                    let notiMsg = errMsg;
                    notify(notiMsg,true,{
                    "successMsg":0,
                    "customWrapClass":"customerrmidlass",
                    "showCloseButton": false,
                    "dismiss": {
                    "duration": 2500,
                    }
                    });

            }
            }
          }).finally(()=>{
               this.isComponentLoaded && this.setState({"continueDisabled":false},()=>{
                //this.getAssingmentDoc();
               });
          });
      }
   
    getDocListStructure() {
        return (
          
            this.state.docListData.length>0 && (   this.state.docListData.map((el, i) =>
                                <div  key={i} className="col-xl-6 col-lg-12 col-md-6 col-sm-12 col-12 col_docBlock">
                                <div className="docBlock docBlock_file">
                                    <div className="docBlock_top">
                                        <div className="doc_leftImg">
                                            <div className="doc_leftImg_box">
                                                <img src="assets/images/file.svg" alt=""/>
                                                <div className="docExtc"><h6>{el.fileType}</h6></div>
                                            </div>
                                        </div>
                                        <div className="doc_cont">
                                            {/* <h5 className="docFile_name">Document {i+1}</h5> */}
                                            <h5 className="docFile_name">{getFileNameOnly(el.name)}</h5>
                                            <h5 className="docFile_date"><span>{el.createdAt ?CustDate.custFormatDate((new Date(el.createdAt)),4): "None"}</span></h5>
                                            <p>Uploaded by -{getCcUserId()==el.owner.id ? 'Me' : el.owner.firstName +' '+el.owner.lastName}</p>
                                        </div>
                                        <div className="doc_box_right">

                                            <div className="dropdown tasktop_statusbtn newAction_btn_blockSec">
                                              <div className={"moreAction_tableSec newAction_btn_subSec"}>
                                                <button className="btn_appearance_none  moreAction_dropBtn" type="button" data-toggle="dropdown" aria-expanded="false">
                                                    {/* <i className="fa fa-ellipsis-h" aria-hidden="true"></i> */}
                                                    Actions
                                                </button>
                                                {this.state.isTaskCompleted==false && (

                                                <div className="dropdown-menu newAction_btn_dropdown_menu_sec" aria-labelledby="dropdownMenuButton" x-placement="bottom-start" >
                                                    <div className="taskstat_dropmenu">
                                                        <ul>
                                                        <li><a href ={el.downloadAddress+'&auth='+getTokenFromLocStorage()} className="btn_appearance_none a_button" >Download</a></li>
                                                        {
                                                            getCcUserId()!=el.owner.id ?'': <li > <button className="btn_appearance_none" onClick={(evnt)=>{
                                                                evnt.preventDefault();
    
                                                                    let otherParam = {"taskId":this.state.taskId,"docId":el.id
                                                                    };
                                                                    this.deleteModalOpen(otherParam);
    
                                                            }}>Delete</button></li>
                                                        }

                                                        {/* <li><button className="btn_appearance_none disabled not_clickable">Share</button></li> */}
                                                        </ul>
                                                    </div>
                                                </div>
                                                )}
                                                </div>
                                            </div>

                                        </div>
                                        <div className="clearboth"></div>
                                    </div>
                                    <div className="docBlock_bottom">
                                        {/* <button onClick={(evn)=>{
                                            evn.preventDefault();
                                            

                                        }} className="btn_appearance_none docView_btn">View</button> */}

                                        <ViewDocFileButton data={el} />
                                    </div>
                                </div>
                            </div>

              )
            )
          
        );
    }

    // trackScroll = ()=>{

    //     let scobj = this.scrollerRef.current;
    //     let scrollTop = scobj.getValues().scrollTop;
    //     let scrollHeight = scobj.getScrollHeight();
    //     let clientHeight = scobj.getClientHeight();

    //     let prevPageNum = this.state.pageNum;
    //     let totalNumberOfPages = this.state.totalNumberOfPages;

    //    // console.log(" scrollHeight=>",scrollHeight," clientHeight==>",clientHeight, " scrollTop=>>",scrollTop);

    //     if((scrollHeight-clientHeight)>scrollTop-5 && prevPageNum < totalNumberOfPages){

    //       this.showLoader();

    //       setTimeout(()=>{
    //         // call list api
    //         let pageNumData = prevPageNum + 1;
    //         this.isComponentLoaded && this.setState((prevState)=>({ pageNum: prevState.pageNum+1 }),()=>{
    //           this.getAssingmentDoc();
    //         });

    //         },900);


    //     }

    // }

    fetchMoreData = ()=>{

     

      let prevPageNum = this.state.pageNum;
      let totalNumberOfPages = this.state.totalNumberOfPages;

      if(prevPageNum < totalNumberOfPages){
        
        this.showLoader();

        setTimeout(()=>{
          // call list api                         
          let pageNumData = prevPageNum + 1;
          this.isComponentLoaded && this.setState((prevState)=>({ pageNum: prevState.pageNum+1 }),()=>{
            this.getAssingmentDoc();
          });
          
          },900);
      }
      

    }

    filterChange = (e)=>{
      let filterData = e.target.value;
      //console.log("filterData =>>>",filterData);
      this.isComponentLoaded && this.setState({"filter":filterData,docListData:[],pageNum:1},()=>{
        this.showLoader();
        setTimeout(()=>{
          this.getAssingmentDoc();
        },1200);
        
      });


  }

    loadTheContent = ()=>{

      let lawyerName = '';
      let lawyerFname = '';
      let lawyerLname = '';
      let lawyerNamePrefix = '';
      let profilePictureURL = "";

      let forSplitScrnCls2Inject = "";
      let datasPassForAssignObj = {};
      //********* for context starts *****
      let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;      
      if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){         

          if(sharedData.actionType=="SPLITSCREEN" ){            
            forSplitScrnCls2Inject = "margin0"
          }

      }     

      
      //********* for context ends *****

      if(this.state.assignedTo!==undefined && this.state.assignedTo!==null ){

        lawyerNamePrefix =  (this.state.assignedTo.namePrefix!==undefined && this.state.assignedTo.namePrefix!==null)?this.state.assignedTo.namePrefix:"";

        lawyerFname = (this.state.assignedTo.firstName!==undefined && this.state.assignedTo.firstName!==null)?this.state.assignedTo.firstName:"";

        lawyerLname = (this.state.assignedTo.lastName!==undefined && this.state.assignedTo.lastName!==null)?this.state.assignedTo.lastName:"";

        lawyerName = lawyerFname+" "+lawyerLname;
        lawyerName = !(lawyerNamePrefix)? lawyerNamePrefix+" "+lawyerName: lawyerName;

        profilePictureURL = (this.state.assignedTo.profilePictureURL!==undefined && this.state.assignedTo.profilePictureURL!==null)?this.state.assignedTo.profilePictureURL:null;

        
      }

      return (
        <>
        { /* Dashboard Slide Split Right start  */   }
        <div className={`splitscreen_area
              ${forSplitScrnCls2Inject?forSplitScrnCls2Inject:""}`
                }>
              <div className="slideScreen_bg slideScreen_Chatbg">
                  <SplitScreenLeftMenu />
                  <div className="slideScreen_rightbg flexbox-parent">
                  {/* === right slide section top start ===  */}
                  <div className="SlideS_topbg flexbox-item slide_chat_topbg">

                    
                    <div className="slide_top_titlebg">
                      {/* newly added */}
                      <div className="titleText_withImageDropdown">
                          <div className="titleText_withImgDropBlock titleText_withImgDropBlock_staticText">
                            <span className="titleText_withImageDropdown_textSpan">
                              {this.state.assignedTo?"Document sharing with":" Document sharing with"}
                              </span>
                          </div>
                          <div className="titleText_withImgDropBlock titleText_withImgDropBlock_imgName">                       

                            <>
                            {this.state.assignedTo?(

                              <>
                                    {profilePictureURL!==null && (
                              <img className="titleText_withImage" src={profilePictureURL} />
                                )}
                                {profilePictureURL===null && ( 
                              <div className="user_name_text">
                                  <span>{getStringInitials(lawyerName)}</span>
                              </div>
                            )}

                            <button className="btn_appearance_none titleText_withImageDropdown_btn pointer_none" type="button" data-toggle="dropdown" aria-expanded="false">
                              {lawyerName}
                            </button>

                             {/* --- optional dropdown for title image text start ---     */}
                        {/* 
                                          <div class="dropdown-menu user_popDetails_block">
                                              <div class="user_popBox">
                                                  <div class="u_popBox_sec user_popBox_top">
                                                      <div class="user_popBox_topLeft">
                                                          <h4>Condition -1  Sourav Roy</h4>
                                                          <div class="userRating_block">
                                                              <div class="ass_userRating">
                                                                  <i class="fa fa-star" aria-hidden="true"></i>  4.5 
                                                              </div>
                                                              <div class="ass_userExp">
                                                                  Experience - 5 years
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div class="user_popBox_topRight">
                                                          <div class="user_name_text"><span>AM</span></div>
                                                      </div>
                                                  </div>
                                                  <div class="u_popBox_sec user_popBox_location">
                                                      <h5>Locations</h5>
                                                      <ul>
                                                          <li>Lorem ipsum dolor sit</li>
                                                          <li>Loremipsumdolorsit</li>
                                                          <li>Loremipsum dolorsit</li>
                                                          <li>Loremipsum dolorsit</li>
                                                      </ul>
                                                  </div>
                                                  <div class="u_popBox_sec user_popBox_pracArea">
                                                      <h5>Practice areas</h5>
                                                      <ul>
                                                          <li>Lorem ipsum dolor sit</li>
                                                          <li>Loremipsumdolorsit</li>
                                                          <li>Loremipsum dolorsit</li>
                                                          <li>Loremipsum dolorsit</li>
                                                      </ul>
                                                  </div>
                                                  <div class="u_popBox_sec user_popBox_about">
                                                      <h5>About</h5>
                                                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                      </p>
                                                  </div>
                                              </div>
                                          </div>
                                            */}
                        {/* --- optional dropdown for title image text end ---     */}

                               </>
                      
                            ):"" }
                            
                            
                            </>

                       
                      </div>
                      
                      
                      </div>

                      <button onClick={(evn)=>{
                            evn.preventDefault();
                            //
                            this.closeSplit({ "trigerredFrom":"TASK_DOCUMENTS_TAB_SCREEN"});
                           
                      
          

                          }} className="btn_appearance_none slideTop_cross_btn cross">
                        <img src="assets/images/red_cross.png" alt />
                      </button>
                    </div>
                  </div>
                  {/* === right slide section top end ===  */}

                  {/* === right slide section Middlie  start ===  */}
                  <div  className="SlideS_Midbg flexbox-item-grow SlideS_Midbg_chat SlideS_Midbg_heightAdjustment SlideS_Midbg_heightAdjustment_documentPage">
                    
                    <div ref={this.scrollerRef}  id="scrollableDivSpDoc"
                                    style={{
                                     
                                      flexDirection: 'column',
                                    }}   className="s_midbox_scroll_adjust reactTestScroll mCustomAdjustmentForStickFooter rightMidPartHeightAdjustment rightMidPartHeightAdjustment_documentPage">
                            <div className="s_midbox_section s_midbox_section_chat">
                                {/*****   sprint - 5 chat structure start *****/}
                                {/****** Document list start ******/}
                                <div className="docFile_pageBG">
                                    
                                    <div className="docUp_top">
                                        <div className="row">
                                            <div className="col-md-6 col-sm-12 col-12">
                                                <div className="custom_select_bg">
                                                    {/* <select className="customSelect form__field">
                                                        <option>Uploaded By Me</option>
                                                        <option>Uploaded By Professional</option>
                                                    </select> */}
                                                     <select value={this.state.filter} name="filter" onChange={this.filterChange} className="customSelect form__field">
                                                      {Object.keys(filterOptions).map((e,i)=>{

                                                      return  <option value={filterOptions[i].value}>{filterOptions[i].key}</option>
                                                      })}
                                                       {/* <option>Uploaded By Me</option>
                                                        <option>uploaded by task creator</option> */}
                                                        
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <CustomMessageBar /> */}
                                    <div className="fileList_bg subBox_scrollBg">

                                        <div className="docBox_bg">
                                            <div className=" mCustomAdjustmentForStickFooter_insideBlock rightMidPartHeightAdjustment_inside rightMidPartHeightAdjustment_inside_documentPageBlock"
                                            
                                           
                                            
                                            >
                                            {/* <Scrollbars
                                            ref={this.scrollerRef}
                                            style={{ height: 520 }}
                                            autoHeightMin={520}
                                            renderView={props => <div {...props} className="view tasklistscrollcustomclass"/>}
                                            onScrollStop={this.trackScroll}
                                            > */}

                              <InfiniteScroll
                                dataLength={this.state.docListData.length} //This is important field to render the next data
                                next={this.fetchMoreData}
                                hasMore={true}
                                 
                                // loader={<h4>Loading...</h4>}
                                scrollableTarget="scrollableDivSpDoc"
                                inverse={false}
                                
                                >


                                            
                                                <div className="docList_hd">
                                                    <div className="docList_hd_left">

                                                    </div>
                                                    {/* <div className="docList_hd_right">
                                                        <div className="upload-btn-wrapper">
                                                            <label
                                                                className="new_upbutton"
                                                                htmlFor="upload"
                                                            >
                                                                <button className="btn_appearance_none sub_grd_btn">
                                                                 <span>{this.state.uploadingFlag? 'Uploading...': 'Upload'}</span>
                                                                </button>
                                                               
                                                            </label>
                                                            <input ref={this.fileUpl} multiple onChange={this.trackFileSelection} type="file" name="files" />
                                                            <div className="clearboth" />
                                                        </div>
                                                        
                                                    </div> */}

                                                    
                                                    
                                                    <div className="docList_hd_right">
                                                    {this.state.isTaskCompleted==false && (
                                                        <div className="upload-btn-wrapper">
                                                        <label className="new_upbutton" htmlFor="upload">
                                                          <button className="btn_appearance_none sub_grd_btn">
                                                          <span>{this.state.uploadingFlag? 'Uploading...': 'Upload'}</span>
                                                          </button>
                                                        </label>
                                                        <input ref={this.fileUpl} multiple onChange={this.trackFileSelection} type="file" name="files" />
                                                        <div className="clearboth" />
                                                        </div>
                                                      )}
                                                    </div>
                                                    
                                                    <div className="clearboth"></div>
                                                </div>
                                                <div className="docList_bdy">
                                                    <div className="row row_doc">

                                                        <div className="col-xl-6 col-lg-12 col-md-6 col-sm-12 col-12 col_docBlock">
                                                            <div className="docBlock docBlock_folder">
                                                                <div className="docBlock_top">
                                                                    <div className="doc_leftImg">
                                                                        <div className="doc_leftImg_box">
                                                                            <img src="assets/images/folder.svg" alt=""/>
                                                                        </div>
                                                                    </div>
                                                                    <div className="doc_cont">
                                                                        <h5 className="docFile_name">Task Creation Documents</h5>
                                                                        <h5 className="docFile_date"><span>{this.state.preAssignmentDocumentCount} files</span> </h5>
                                                                        <p>Uploaded by - {this.state.preAssignmentDocumentOwner}</p>
                                                                    </div>
                                                                    <div className="doc_box_right">
                                                                    </div>
                                                                    <div className="clearboth"></div>
                                                                </div>
                                                                <div className="docBlock_bottom">
                                                                    <button className="btn_appearance_none docView_btn" onClick={
                                                                      (e)=>{e.preventDefault();
                                                                     

                                                                        let paramsCntxt = {};

                                                                        let  otherData = {};
                                                                        let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;
                                                                        if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){
                                                                          
                                                                          if(sharedData && sharedData.other!==undefined && sharedData.other!==null ){
                                                                              otherData = sharedData.other;
                                                                              otherData["splitScreenDocTabFilter"] =  {"filter":this.state.filter} ;

                                                                          }else{
                                                                            otherData = {
                                                                              "splitScreenDocTabFilter":{"filter":this.state.filter} 
                                                                            };
                                                                          }
                                                                        }

                                                                       
                                                                        
                                                                        paramsCntxt = {
                                                                          "taskId":this.state.taskId,
                                                                          "actionType":"SPLITSCREEN",
                                                                          "leftTabToActive":"DOCUMENTS",
                                                                          "purpose":"SHOW_PRE_ASSINGMENT_DOC",
                                                                          "other":otherData
                                                                        }
                                                                        let paramObjData = {"data":{"taskId":this.state.taskId}};
                                                                        let param = {"paramObjData":paramObjData,"paramsCntxt":paramsCntxt}
                                                                        this.showPreAssingmentDoc(param);
                                                                      }
                                                                    }>Open</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* {
                                                            this.state.loaderFlag==false && this.state.docListData.length==0 && (
                                                            <div className=" text-center no records found">
                                                                No records found
                                                            </div>
                                                            )
                                                        } */}

                                                        {this.getDocListStructure()}
                                                        <div className="col-12 col_docBlock">
                                                        <div className=" text-center ">
                                                            {this.state.loaderFlag ? this.loadLoader() : null}
                                                        </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </InfiniteScroll>
                                            {/* </Scrollbars> */}

                                            </div>
                                        </div>
                                        
                                    </div>

                                </div>
                                {/****** Document list end  ****/}
                                {/******   sprint - 5 chat structure end ******/}
                            </div>
                            </div>

                  </div>
                  {/* === right slide section Middlie  end ===  */}
                  {/* === right slide section Footer  start ===  */}
                  {this.loadfooterOfDocument()}


                  {/* === right slide section Footer  end ===  */}
                </div>
              
              
              </div>

        </div>


        { /* Dashboard Slide Split Right end  */ }
        </>
      );
    }

    // trackNotiForCloseSplit = (otherParam) =>{
     
    //   let notiType =  otherParam.notiType!==undefined? otherParam.notiType: "";
    //   let trigerredFrom =  otherParam.trigerredFrom!==undefined? otherParam.trigerredFrom: ""; 
    //   if(notiType=="CLOSE_SPLITSCREEN"){
    //       let closeSplitHeaderCallFrm = ["TASK_DOCUMENTS_TAB_SCREEN"];
    //       if(closeSplitHeaderCallFrm.indexOf(trigerredFrom)!=-1){
    //               console.log("*******trigerredFrom=>>",trigerredFrom);
    //       }
  
  
    //   }
    // }

    /**  
     * SplitScreenSingleTaskDetails
     * Function name : render
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() {    
      
      return (
          <React.Fragment>

            {/* <NotiEmitTrack callbackfuncforcommontrackofnoti={this.trackNotiForCloseSplit} /> */}
            {this.loadTheContent()}

            {/* Confirm delete modal start */}
            <CustReactBootstrapModal ref={this.modalfortaskdelRef}  backdrop={false} backdropClassName="custom_modal_class"   animation={false} show={this.state.showDeleteModal} onShow={()=>{}} onHide={()=>{}} modalcustdialog="custom_modal_lg"  loaderbodytitle="Organisation"  />
            {/* Confirm delete modal end */}            

          </React.Fragment>
      );
    }
}
export default SplitScreenTaskDocuments;

