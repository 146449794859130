
import React, { Component, Suspense, lazy, Form, FormInput} from 'react';
import OrgDetailSaveService from '../../service/OrgDetailSaveService';
import {notify} from '../../messagebar/CustomMessageBar';
import CustomMessageBar from '../../messagebar/CustomMessageBar';
import NewCustomGoogleAutoComplete from '../../customisedthirdparty/NewCustomGoogleAutoComplete';

import {commonJsFuncModule as commonJsObj} from  '../../../common_utilities/commonjsfunc';
import {commonStaticOptionsJsFuncModule as commonStaticJsObj} from  '../../../common_utilities/commonstaticoptions';

const getRole = commonJsObj.getRole;

const getDataShouldShowAddUserPopUp = commonJsObj.getDataShouldShowAddUserPopUp;
const getDataShouldShowOrganisationPopUp = commonJsObj.getDataShouldShowOrganisationPopUp;
const updateShouldShowAddUserPopUp = commonJsObj.updateShouldShowAddUserPopUp;
const updateShouldShowOrganisationPopUp = commonJsObj.updateShouldShowOrganisationPopUp;
const validateBlankSpace = commonJsObj.validateBlankSpace;


const getBaseUrl = commonJsObj.getBaseUrl;

const getStaticOrgSize = commonStaticJsObj.getStaticOrgSize;
const getStaticTeamSize = commonStaticJsObj.getStaticTeamSize;

// const orgSizeData = [
//   {"size":{"key":"","value":"Organization Size"}},
//   {"size":{"key":"1-4 persons","value":"1-4 persons"}},
//   {"size":{"key":"5-19 persons","value":"5-19 persons"}},
//   {"size":{"key":"20-99 persons","value":"20-99 persons"}},
//   {"size":{"key":"100-999 persons","value":"100-999 persons"}},
//   {"size":{"key":"1000+ persons","value":"1000+ persons"}},
// ];
const orgSizeData = getStaticOrgSize();


// const teamSizeData = [
//   {"size":{"key":"","value":"Department Size"}},
//   {"size":{"key":"1-4 persons","value":"1-4 persons"}},
//   {"size":{"key":"5-19 persons","value":"5-19 persons"}},
//   {"size":{"key":"20-99 persons","value":"20-99 persons"}},
//   {"size":{"key":"100-999 persons","value":"100-999 persons"}},
//   {"size":{"key":"1000+ persons","value":"1000+ persons"}},
// ];
const teamSizeData = getStaticTeamSize();

const orgDetailDataObj = {
  
  loaderFlag: false,
  isOrganisationDetailsProvided: false,
  

  // for organisation detail modal
  designation: '',
  orgName: '',
  organisationSize: '',
  phoneNumber: '',
  teamSize: '',
  city: {},
  getOrgCityData:{},

  // for organisation detail err msg
  designationErMsg: '',
  orgNameErMsg: '',
  organisationSizeErMsg: '',
  phoneNumberErMsg: '',
  teamSizeErMsg: '',
  cityErMsg: '',

  // for organisation loader
  loaderFlagOrgDet: false,

}

class OrgDetailInfoComp extends Component {

    isComponentLoaded = false;

    constructor(props) {
    super(props);
    this.state = orgDetailDataObj;

    this.orgcitygaref = React.createRef();
  }

  /**
     * OrgDetailInfoComp
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 10-2-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
   componentDidMount() {
    this.isComponentLoaded = true;     

   
    setTimeout(()=>{
      this.isComponentLoaded && this.setState({"orgName":this.props.orgnamedata()});
     },900);
   }
   

  /**
    * Dashboard
    * Function name : handleChange
    * Author        :
    * Created Date  : 17-2-2020
    * Purpose       : Used to handle change event for organisation
    * Params        : 
    **/
   handleChange = (e) => {
    this.isComponentLoaded &&  this.setState({[e.target.name]: e.target.value}); 
  }
  
  

  componentWillUnmount() {  
    this.isComponentLoaded = false;
  }

  /**
  * Signup
  * Function name : callSaveOrgDetail
  * Author        :
  * Created Date  : 17-2-2020
  * Purpose       : Used to call serice getLoginResp function
  * Params        : 
  **/
  callSaveOrgDetail = (e) => {
    e.preventDefault();
    
    this.isComponentLoaded && this.setState({loaderFlag:true});
    const isValid = this.custValidate(); 
    //console.log(" =isValidisValid=>>>>",isValid);
    
    
    if(isValid){
        const creds = {
                  "designation": this.state.designation,
                  "organisationName": this.state.orgName,
                  "organisationSize": this.state.organisationSize,
                  "phoneNumber": this.state.phoneNumber,
                  "teamSize": this.state.teamSize,
                  "city": this.state.city
                  };
        //console.log(" === creds=>>>>",creds); return false;
        let lserv = new OrgDetailSaveService();
        lserv.saveOrgDetail(creds).then(data =>{
            //console.log("data=>",data,data.status);        
            if(data.status==200){              
                
                let notiMsg = "Success";
                notify(notiMsg,true,{
                "successMsg":1,
                "customWrapClass":"customsuccclass",
                "showCloseButton": false,                     
                "dismiss": {
                    "duration": 1500,
                }

              });

              setTimeout(()=>{
                this.props.callorginfomodalclose();
              },1900);


              setTimeout(()=>{
                if(getRole()=="CC_ADMIN" && getDataShouldShowAddUserPopUp()=="true"){
                  updateShouldShowAddUserPopUp(false);
                  this.props.calladdorgusermodal();
                }                
              },2100);              
                        
                            
            }                   
        }).catch(err=>{
          let errStatusCodeAr = [400,500]; // allowed error status code
          if(err.response && err.response.data){
              let erRespData = err.response.data;
              if(errStatusCodeAr.indexOf(erRespData.status) !== -1){         
                  let errMsg = erRespData.message?erRespData.message:"Some technical problem occurred from server. Please try again.";

                  let notiMsg = errMsg;
                   notify(notiMsg,true,{
                    "successMsg":0,
                    "customWrapClass":"customsuccclass",
                    "showCloseButton": false,
                    "dismiss": {
                        "duration": 1500,
                    }

                  });

                 

                  

            }
          }        
    
        }).finally(()=>{
          setTimeout(()=>{
            this.isComponentLoaded && this.setState({loaderFlag:false},()=>{
              });
          },1500);
          
        });
      
    }else{
      this.isComponentLoaded && this.setState({loaderFlag:false}); 
    } 

  }

 
  selectedGoogleDataInfo = (googpplcselectedinfo)=>{  

   //console.log(" googpplcselectedinfo=>>",googpplcselectedinfo);

   let cityObj =  {
      "cityName":googpplcselectedinfo.placeName,               
      "latitude": googpplcselectedinfo.lat,
      "longitude": googpplcselectedinfo.lng,
      "placeId": googpplcselectedinfo.placeId
    };     

    this.isComponentLoaded && this.setState({"city":cityObj}); 

  }
  /**
 * orgDetailDataObj
 * Function name : custValidate
 * Author        :
 * Created Date  : 18-2-2020
 * Purpose       : validate data before submit
 * Params        : 
**/
custValidate = ()=>{


  let designationErMsg = '';
  let orgNameErMsg = '';
  let organisationSizeErMsg = '';
  let phoneNumberErMsg = '';
  let teamSizeErMsg = '';
  let cityErMsg = '';

  let cityDataObj ={
    "cityName":"",               
    "latitude": "",
    "longitude": "",
    "placeId": ""
  };      

  let totErrCountTrack = [];   
  let regexBlankSpace = /^ *$/;    
    
  //let getOrgCityData = this.orgcitygaref.current.getSelectedPlaceFromGoogAuto();
  let getOrgCityData = this.state.city;

    if(!(this.state.designation)||(this.state.designation.length==0)|| (regexBlankSpace.test(this.state.designation)==true)){
      designationErMsg = "Designation is required.";        
      //this.isComponentLoaded && this.setState({ "designationErMsg": designationErMsg});
      totErrCountTrack.push(designationErMsg);
    } 
    else{
      //this.isComponentLoaded &&  this.setState({ "designationErMsg":''});  
    }

    if(!(this.state.orgName)||(this.state.orgName.length==0)|| (regexBlankSpace.test(this.state.orgName)==true)){
      let orgNameErMsg = "Organisation name is required.";
      // this.isComponentLoaded && this.setState({ "orgNameErMsg": orgNameErMsg},()=>{       
      //     // console.log(this.state.orgNameErMsg);
      // });
      totErrCountTrack.push(orgNameErMsg);
        
        
    }else{
      //this.isComponentLoaded &&  this.setState({ "orgNameErMsg":''});  
    }      

    if(validateBlankSpace(this.state.phoneNumber)==true){
      phoneNumberErMsg = "Phone number is required.";
      //this.isComponentLoaded && this.setState({ "phoneNumberErMsg": phoneNumberErMsg});
      totErrCountTrack.push(phoneNumberErMsg);          
        
    }else{
        let phnNmbrData = this.state.phoneNumber;
        let chkPhn  = this.validatePhoneNumberPattern(phnNmbrData);
        if(chkPhn==false){
          phoneNumberErMsg = "Phone number is in improper format.";
          //this.isComponentLoaded && this.setState({ "phoneNumberErMsg": phoneNumberErMsg});
          totErrCountTrack.push(phoneNumberErMsg);  
        }
        else{
          //this.isComponentLoaded &&  this.setState({ "phoneNumberErMsg":''});  
        }
        
    }

    if(validateBlankSpace(this.state.teamSize)==true){
       teamSizeErMsg = "Department Size is required.";
      //this.isComponentLoaded && this.setState({ "teamSizeErMsg": teamSizeErMsg});
      totErrCountTrack.push(teamSizeErMsg);          
        
    }else{
      //this.isComponentLoaded &&  this.setState({ "teamSizeErMsg":''});  
    }

    if(validateBlankSpace(this.state.organisationSize)==true){
       organisationSizeErMsg = "Organization Size is required.";
      //this.isComponentLoaded && this.setState({ "organisationSizeErMsg": organisationSizeErMsg});
      totErrCountTrack.push(organisationSizeErMsg);          
        
    }else{
     //this.isComponentLoaded &&  this.setState({ "organisationSizeErMsg":''});  
    }   

    
    //console.log("getOrgCityData====>>>>",getOrgCityData);
    if(!(getOrgCityData.latitude)||!(getOrgCityData.longitude)||!(getOrgCityData.placeId)||!(getOrgCityData.cityName)){
          cityErMsg = "City not selected.";
          //this.isComponentLoaded && this.setState({ "cityErMsg": cityErMsg});         
          totErrCountTrack.push(cityErMsg);
    }else{
       cityDataObj ={
          "cityName":getOrgCityData.cityName,               
          "latitude": getOrgCityData.latitude,
          "longitude": getOrgCityData.longitude,
          "placeId": getOrgCityData.placeId
      }      
      // this.isComponentLoaded && this.setState({ "city": cityDataObj});
      // this.isComponentLoaded &&  this.setState({ "cityErMsg":''}); 

    }

    // console.log("totErrCountTrack=>>",totErrCountTrack);
    // console.log("Checking data setted==>",this.state);

    this.isComponentLoaded && this.setState({ 
      "city": cityDataObj,
      "cityErMsg": cityErMsg,
      "organisationSizeErMsg": organisationSizeErMsg,
      "teamSizeErMsg": teamSizeErMsg,
      "phoneNumberErMsg": phoneNumberErMsg,
      "orgNameErMsg": orgNameErMsg,
      "designationErMsg": designationErMsg    
    });

    if(totErrCountTrack.length>0){          
          return false;
    }
    return true;
}

  validatePhoneNumberPattern(inputtxt)
  {
      var phonenoPattern = /^[\+]?[0-9]{3}[0-9]{3}[0-9]{4,6}$/;
      //var phonenoPattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
      let chkPatt = phonenoPattern.test(inputtxt);
      return chkPatt;
  }

  render() {
    return (
      	  <>
            <div className="modal-header">
                <h4 className="modal-title modal_title_tool">Organisation's Details
                <div className="input_tooltip_bg">
                        <span className="input_tooltip" aria-label="Organisation's Details" data-balloon-pos="down">
                        <i className="fa fa-question-circle"></i>
                        </span>
                        </div>
                </h4>
                <button type="button" onClick={()=>{this.props.callorginfomodalclose();}} className="close modal_close" data-dismiss="modal">&times;</button>
            </div>
            <CustomMessageBar />
            <div className="modal-body padding_top from_modal_bg modal_organisation">
              <div className="row">

                  <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className={`modal_input form__group field  ${ this.state.orgNameErMsg?'form_group_error':''}`}>
                          <input defaultValue={this.state.orgName} onChange={ this.handleChange } type="input" className="form__field" placeholder="Organisation name" name="orgName" id='orgName'   />
                          <label  htmlFor="orgName" className="form__label">Organisation name </label>
                          <span className="error_span"> {this.state.orgNameErMsg} </span>
                        </div>
                  </div>

                  <div className="col-md-6 col-sm-6 col-xs-12">               

                      <div className={`modal_input modal_select  ${ this.state.organisationSizeErMsg?'form_group_error':''}`}>
                          <select onChange={ this.handleChange } name="organisationSize" id="organisationSize" className="selectpicker dropup" data-header="Organisation Size">
                              {Object.keys(orgSizeData).length > 0 &&
                              Object.keys(orgSizeData).map((e, i) => (
                                  <option key={i} value={orgSizeData[e].size.key} > {orgSizeData[e].size.value}</option>
                              ))
                          }                       
                              
                          </select>
                          <label htmlFor="organisationSize" className="form__label">Organisation Size </label>
                          <span className="error_span"> {this.state.organisationSizeErMsg} </span>
                      </div>

                  </div>

                  <div className="col-md-6 col-sm-6 col-xs-12">
                      <div className={`modal_input form__group field  ${ this.state.cityErMsg?'form_group_error':''}`}>
                          
                          <NewCustomGoogleAutoComplete ref={this.orgcitygaref} placeholder="City" idname="orgcityid" textboxcustclass="orgdetgoogautocomptxtbxcls form__field"
                          
                          selectedGoogleDataInfo={this.selectedGoogleDataInfo}
                          />
                          
                          <label htmlFor="city" className="form__label"> City </label>
                          <span className="error_span"> {this.state.cityErMsg} </span>
                      </div>
                  </div>

                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className={`modal_input form__group field  ${ this.state.designationErMsg?'form_group_error':''}`}>
                      <input onChange={ this.handleChange } type="input" className="form__field" placeholder="Enter your designation" name="designation" id='designation' required />
                      <label htmlFor="designation" className="form__label">Enter your designation</label>
                      <span className="error_span"> {this.state.designationErMsg} </span>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-6 col-xs-12">
                      <div className={`modal_input modal_select  ${ this.state.teamSizeErMsg?'form_group_error':''}`}>
                          <select onChange={ this.handleChange } name="teamSize" id="teamSize" className="selectpicker dropup" data-header="Department Size">
                          {Object.keys(teamSizeData).length > 0 &&
                          Object.keys(teamSizeData).map((e, i) => (
                              <option key={i} value={teamSizeData[e].size.key} > {teamSizeData[e].size.value}</option>
                          ))
                        }
                              
                              
                          </select>
                          <label htmlFor="phoneNumber" className="form__label">Department Size </label>
                          <span className="error_span"> {this.state.teamSizeErMsg} </span>
                      </div>
                  </div>

                  <div className="col-md-6 col-sm-6 col-xs-12">
                      <div className={`modal_input form__group field  ${ this.state.phoneNumberErMsg?'form_group_error':''}`}>
                          <input onChange={ this.handleChange } type="input" className="form__field" placeholder="Phone Number" name="phoneNumber" id='phoneNumber' required />
                          <label htmlFor="phoneNumber" className="form__label">Phone Number </label>
                          <span className="error_span"> {this.state.phoneNumberErMsg} </span>
                      </div>
                  </div>

                  

              </div>
            </div>
            <div className="modal-footer">
                <a href="" onClick={(e)=>{ e.preventDefault(); 
                setTimeout(()=>{
                  ///console.log("==>>>",this.props.shouldshowadduserpopup());

                  if(getRole()=="CC_ADMIN" && getDataShouldShowAddUserPopUp()=="true"){
                    this.props.callorginfomodalclose();
                    setTimeout(()=>{
                      updateShouldShowAddUserPopUp(false);
                      this.props.calladdorgusermodal();
                    },350)
                    
                  }
                  
                },350);
                }} className="modal_link_btn">Skip</a>
                <button disabled={this.state.loaderFlag}  type="button" className="btn btn-primary modal_btn_save" onClick={this.callSaveOrgDetail } data-dismiss="modal"><span>Save Details</span></button>
                
            </div>
          
          
          </>
      
    );
  }
}
export default OrgDetailInfoComp;