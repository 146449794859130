/**
 * SplitScreenTaskInvoice
 * Page/Class name   : SplitScreenTaskInvoice
 * Author            :
 * Created Date      : 7-5-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : render master component
 **/
import React, { Component } from "react";

// for context
import CommonContext from "../../context/CommonContext";

import TaskInvoiceHistoryService from "../../service/TaskInvoiceHistoryService";
//import  CustDate  from '../../../common_utilities/CustDate';
import { commonJsFuncModule as commonJsObj } from "../../../common_utilities/commonjsfunc";

import SplitScreenLeftMenu from "../../share/SplitScreenLeftMenu";

const getDateObjFromMilliseconds = commonJsObj.getDateObjFromMilliseconds;
const getTimestampInMillisecondsFromDateObj =
  commonJsObj.getTimestampInMillisecondsFromDateObj;
const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;
const sortArrayOfJsonByKey = commonJsObj.sortArrayOfJsonByKey;
const getCcUserId = commonJsObj.getCcUserId;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;

class SplitScreenTaskInvoice extends Component {
  isComponentLoaded = false;
  // for context
  static contextType = CommonContext;

  /**
   * SplitScreenTaskDocuments
   * Function name : constructor
   * Author        :
   * Created Date  : 7-5-2020
   * Purpose       : initializing children
   * Params        : props
   **/
  constructor(props) {
    super(props);
    this.children = props.children;
    this.fileUpl = React.createRef();
    this.state = {
      lpUserId: 0,
      ccUserId: 0,
      taskOrSubTask: "",
      taskIdOrSubTaskId: 0,
      listData: [],
      pageNum: 1,
      totalNumberOfPages: 1,
      loaderFlag: true,
      pageNumArTrack: [],
      uploadingFlag: false,
      invoiceInfo: null,
      pageNumber: 1,
      numPages: null,
      pdfLoadErr: false,
      isTaskCompleted: false,
    };
    this.scrollerRef = React.createRef();
    this.modalfortaskdelRef = React.createRef();
  }

  /**
   * SplitScreenTaskDocuments
   * Function name : componentDidMount
   * Author        :
   * Created Date  : 7-5-2020
   * Purpose       : lifecycle method
   * Params        :
   **/
  componentDidMount() {
    this.isComponentLoaded = true;

    //
    let taskOrSubTask = "";
    let taskIdOrSubTaskId = 0;
    let lpUserId = 0;
    let ccUserId = 0;
    let triggeredFrom = "";
    let isTaskCompleted = false;

    //********* for context starts *****
    let sharedData =
      this.context !== undefined && this.context.share !== undefined
        ? this.context.share
        : null;
    if (
      sharedData !== undefined &&
      sharedData !== null &&
      sharedData.actionType !== undefined
    ) {
      if (sharedData.actionType == "SPLITSCREEN") {
        //console.log(" 22 MEEEE===>>>>",sharedData);
        if (sharedData.other !== undefined && sharedData.other !== null) {
          //console.log(" 33 MEEEE===>>>>",sharedData);
          triggeredFrom =
            sharedData.other.triggeredFrom !== undefined
              ? sharedData.other.triggeredFrom
              : "";
          //console.log("======Entered triggeredFrom=>>>",triggeredFrom);
          //*** when user comes by clicking on monitor icon from split screen left menu starts
          if (
            triggeredFrom == "TASK_SPLIT_SCREEN_LEFT_MENU" ||
            triggeredFrom == "BELL_NOTIFICATION_MENU" ||
            triggeredFrom == "TASK_INVOICE_HISTORY"
          ) {
            //console.log("**** from inv master sharedData.other==>",sharedData.other);

            taskOrSubTask =
              sharedData.other.subTasksCount !== undefined &&
              sharedData.other.subTasksCount !== null &&
              sharedData.other.subTasksCount > 0
                ? "SWSTASK"
                : "STASK";

            if (taskOrSubTask == "STASK") {
              if (
                sharedData.other.taskResp !== undefined &&
                sharedData.other.taskResp !== null
              ) {
                let taskResp = sharedData.other.taskResp;

                taskIdOrSubTaskId =
                  taskResp.id !== undefined && taskResp.id !== null
                    ? taskResp.id
                    : 0;

                lpUserId =
                  taskResp.assignedTo !== undefined &&
                  taskResp.assignedTo !== null &&
                  taskResp.assignedTo.userId !== undefined &&
                  taskResp.assignedTo.userId !== null
                    ? taskResp.assignedTo.userId
                    : 0;

                isTaskCompleted =
                  taskResp.isTaskCompleted !== undefined &&
                  taskResp.isTaskCompleted !== null
                    ? taskResp.isTaskCompleted
                    : false;
              }
            } else if (taskOrSubTask == "SWSTASK") {
              // this scenario i.e task with sub task is not in sprint 5
            }

            ccUserId = getCcUserId();
          }
          //*** when user comes by clicking on monitor icon from split screen let menu ends
          //*** when user comes by clicking on monitor icon from professional interested list starts

          //*** when user comes by clicking on monitor icon from professional interested list ends
        }
      }

      ////console.log(" Now taskOrSubTask=>",taskOrSubTask," taskIdOrSubTaskId=>",taskIdOrSubTaskId, " lpUserId=>",lpUserId," ccUserId==>",ccUserId," triggeredFrom=>",triggeredFrom);
    }

    // call list api
    let paramObj = {
      pageNum: 1,
      pageNumArTrack: [],
      listData: [],
      totalNumberOfPages: 1,
      taskIdOrSubTaskId: taskIdOrSubTaskId,
      taskOrSubTask: taskOrSubTask,
      lpUserId: lpUserId,
      ccUserId: ccUserId,
      invoiceInfo: null,
      pageNumber: 1,
      numPages: null,
      pdfLoadErr: false,
      isTaskCompleted: isTaskCompleted,
    };
    this.isComponentLoaded &&
      this.setState(paramObj, () => {
        //console.log(" before call to callServiceToFetchTaskInvoiceHistorySingle paramObj===>",paramObj);

        this.callServiceToFetchTaskInvoiceHistorySingle(
          paramObj,
          "INVOICEVIEWCOMPONENTLOADED"
        );
      });

    //
  }

  /**
   * SplitScreenTaskDocuments
   * Function name : componentWillUnmount
   * Author        :
   * Created Date  : 7-5-2020
   * Purpose       : lifecycle method
   * Params        :
   **/
  componentWillUnmount() {
    this.isComponentLoaded = false;
  }

  /**
   * SplitScreenTaskDocuments
   * Function name : componentDidUpdate
   * Author        :
   * Created Date  : 7-5-2020
   * Purpose       : lifecycle method
   * Params        :
   **/
  componentDidUpdate(prevProps) {}

  /**
   * SplitScreenTaskDocuments
   * Function name : closeSplit
   * Author        :
   * Created Date  : 7-5-2020
   * Purpose       : closeSplit method to close the split screen
   * Params        :
   **/

  closeSplit = () => {
    //********* for context starts *****
    let sharedData =
      this.context !== undefined && this.context.share !== undefined
        ? this.context.share
        : null;
    if (
      sharedData !== undefined &&
      sharedData !== null &&
      sharedData.actionType !== undefined
    ) {
      if (sharedData.actionType == "SPLITSCREEN") {
        let setShare =
          this.context !== undefined && this.context.setShare !== undefined
            ? this.context.setShare
            : null;

        if (setShare) {
          let paramsCntxt = {
            taskId: "",
            actionType: "",
            leftTabToActive: "",
            purpose: "",
            other: {},
          };
          setShare(paramsCntxt);
        }
      }
    }
    //********* for context ends *****
  };

  callServiceToFetchTaskInvoiceHistorySingle = (paramObj, trackcalledfrom) => {
    // context share
    let setShare =
      this.context !== undefined && this.context.setShare !== undefined
        ? this.context.setShare
        : null;
    let sharedData =
      this.context !== undefined && this.context.share !== undefined
        ? this.context.share
        : null;
    let triggeredFrom = "";
    if (setShare && sharedData) {
      triggeredFrom =
        sharedData.other !== undefined &&
        sharedData.other &&
        sharedData.other.triggeredFrom !== undefined
          ? sharedData.other.triggeredFrom
          : "";
    }

    let paramObjData = {};
    paramObjData.pageNum = paramObj.pageNum;
    paramObjData.taskOrSubTask = paramObj.taskOrSubTask;
    paramObjData.taskIdOrSubTaskId = paramObj.taskIdOrSubTaskId;
    paramObjData.lpUserId = paramObj.lpUserId;

    //console.log(" trackcalledfrom=>",trackcalledfrom," paramObjData=>",paramObjData);

    let stcservice = new TaskInvoiceHistoryService();
    stcservice
      .getTaskInvoiceHistoryList({ data: paramObjData })
      .then((data) => {
        if (data.status == 200) {
          let respData = {};
          if (data.data.entity !== undefined) {
            respData = data.data.entity;
            if (
              respData.invoices !== undefined &&
              respData.invoices !== null &&
              respData.invoices.length > 0
            ) {
              let isPaid = false;
              let invoiceInfo = respData.invoices[0]; // assuming one record is there always for Sprint 6
              if (
                invoiceInfo &&
                invoiceInfo.isPaid !== undefined &&
                invoiceInfo.isPaid !== null
              ) {
                isPaid = invoiceInfo.isPaid;
                if (isPaid) {
                  // if  landing from bell icon or left menu of split screen and invoice is paid then redirect to history

                  if (
                    triggeredFrom == "TASK_SPLIT_SCREEN_LEFT_MENU" ||
                    triggeredFrom == "BELL_NOTIFICATION_MENU"
                  ) {
                    if (setShare && sharedData) {
                      sharedData.leftTabToActive = "INVOICE";
                      sharedData.purpose = "VIEW_INVOICE_LIST";
                      sharedData.other.invoiceInfo = invoiceInfo;
                      setShare(sharedData);
                    }
                  } else {
                    if (setShare && sharedData) {
                      // go to invoice view page
                      sharedData.leftTabToActive = "INVOICE";
                      sharedData.purpose = "VIEW_INVOICE";
                      sharedData.other.invoiceInfo = invoiceInfo;
                      setShare(sharedData);
                    }
                  }
                } else {
                  if (setShare && sharedData) {
                    // go to invoice view page
                    sharedData.leftTabToActive = "INVOICE";
                    sharedData.purpose = "VIEW_INVOICE";
                    sharedData.other.invoiceInfo = invoiceInfo;
                    setShare(sharedData);
                  }
                }
              } else {
                // go to task invoice history
                if (setShare && sharedData) {
                  sharedData.leftTabToActive = "INVOICE";
                  sharedData.purpose = "VIEW_INVOICE_LIST";
                  sharedData.other.invoiceInfo = invoiceInfo;
                  setShare(sharedData);
                }
              }
            } else {
              // go to task invoice history
              if (setShare && sharedData) {
                sharedData.leftTabToActive = "INVOICE";
                sharedData.purpose = "VIEW_INVOICE_LIST";
                sharedData.other.triggeredFrom = "VIEW_INVOICE_SCREEN";
                sharedData.other.invoiceInfo = null;
                setShare(sharedData);
              }
            }
          } else {
            // go to task invoice history
            if (setShare && sharedData) {
              sharedData.leftTabToActive = "INVOICE";
              sharedData.purpose = "VIEW_INVOICE_LIST";
              sharedData.other.triggeredFrom = "VIEW_INVOICE_SCREEN";
              sharedData.other.invoiceInfo = null;
              setShare(sharedData);
            }
          }
        }
      })
      .catch((err) => {
        let errStatusCodeAr = [400, 500]; // allowed error status code
        if (err.response && err.response.data) {
          let erRespData = err.response.data;
          if (errStatusCodeAr.indexOf(erRespData.status) !== -1) {
            let errMsg = erRespData.message
              ? erRespData.message
              : "Some technical problem occurred.";
            console.log(errMsg);
          }
        }
      })
      .finally(() => {});
  };

  /**
   * SplitScreenTaskInvoice
   * Function name : render
   * Author        :
   * Created Date  : 7-5-2020
   * Purpose       : lifecycle method
   * Params        :
   **/
  render() {
    let forSplitScrnCls2Inject = "";
    //********* for context starts *****
    // context share
    let setShare =
      this.context !== undefined && this.context.setShare !== undefined
        ? this.context.setShare
        : null;
    let sharedData =
      this.context !== undefined && this.context.share !== undefined
        ? this.context.share
        : null;
    if (
      sharedData !== undefined &&
      sharedData !== null &&
      sharedData.actionType !== undefined
    ) {
      if (sharedData.actionType == "SPLITSCREEN") {
        forSplitScrnCls2Inject = "margin0";
      }
    }

    //********* for context ends *****

    let listObjAr = [];

    return (
      <>
        {/* Dashboard Slide Split Right start  */}
        <div
          className={`splitscreen_area
              ${forSplitScrnCls2Inject ? forSplitScrnCls2Inject : ""}`}
        >
          {/* Newest1 - Start */}
          {/* <div className="slideScreen_bg slideScreen_Chatbg"> */}
          <div className="slideScreen_bg slideScreen_Chatbg slideScreen_bg_heightAdjust">
            {/* Newest1 - End */}
            <SplitScreenLeftMenu />

            <div className="slideScreen_rightbg flexbox-parent"></div>
          </div>
        </div>
      </>
    );
  }
}
export default SplitScreenTaskInvoice;
