/**  
 * CustomRoutes
 * Page/Class name   : CustomRoutes
 * Author            :
 * Created Date      : 22-11-2019
 * Functionality     : 
 * Purpose           : It contains front end routes
**/
import React from 'react';

import {BrowserRouter as Router, Switch, Route, withRouter, Redirect} from 'react-router-dom';


import MasterLayout from '../components/layout/MasterLayout';
import BeforeLoginLayout from '../components/layout/BeforeLoginLayout';
import ErrorComp from '../components/error/error';
import Signup from '../components/content/before_login/Signup';
import SigninOtp from '../components/content/before_login/SigninOtp';
import Signin from '../components/content/before_login/Signin';
import Dashboard from '../components/content/after_login/Dashboard';
import TaskTabPage from '../components/content/after_login/TaskTabPage';
import TaskAdd from '../components/content/after_login/TaskAdd';
import PaymentTab from '../components/content/after_login/PaymentTab';
import VirtualBankAccount from '../components/content/after_login/VirtualBankAccount';
import ProfileSettings from '../components/content/after_login/ProfileSettings';
import ApplicationSettings from '../components/content/after_login/ApplicationSettings';
import SubscriptionPayment from '../components/content/after_login/SubscriptionPayment';
import SpecialDevToReset from '../components/content/before_login/SpecialDevToReset';


// import Rnd from '../components/rnd/Rnd';
// import CacheRnd from '../components/rnd/CacheRnd';

//import { Redirect } from 'react-router-dom';

import {commonJsFuncModule as commonJsObj} from  '../common_utilities/commonjsfunc';
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;

const MasterLayoutRoute = ({component: Component, ...rest}) => {
    
          
    if(getTokenFromLocStorage()==null){
        return <Redirect to='/signin' />;
    }
    else{
        return (        
            <Route {...rest} render={matchProps => (              
                <MasterLayout>
                  <Component {...matchProps} />
                </MasterLayout>       
            )} />
          );
    }

    
  };
  
const BeforeLoginLayoutRoute = ({component: Component, ...rest}) => {

    if(getTokenFromLocStorage()!==null){
        return <Redirect to='/dashboard' />;
    }
    else{
        return (
            <Route {...rest} render={matchProps => (                
                <BeforeLoginLayout>
                  <Component {...matchProps} />
                </BeforeLoginLayout>       
            )} />
          );
    }
   
  };

const NoLayoutRoute = ({component: Component, ...rest}) => {
    return (
        <Route {...rest} render={matchProps => (                
            <>
              <Component {...matchProps} />
            </>       
        )} />
      )
  }

const routeArr = [
    {
        path : '',
        component: Signup,
        layout : 'BeforeLoginLayoutRoute'
        
    },
    {
        path : 'signup',
        component: Signup,
        layout : 'BeforeLoginLayoutRoute'
    },
    {
        path : 'signin-otp',
        component: SigninOtp,
        layout : 'BeforeLoginLayoutRoute'
    },
    {
        path : 'signin',
        component: Signin,
        layout : 'BeforeLoginLayoutRoute'
    },

    {
        path : 'specialfeaturefordev/:param1?',
        component: SpecialDevToReset,
        layout : 'NoLayoutRoute'
    },
    // {
    //     path : 'rnd',
    //     component: Rnd,
    //     layout : 'BeforeLoginLayoutRoute'
    // },
    // {
    //     path : 'cachernd',
    //     component: CacheRnd,
    //     layout : 'NoLayoutRoute'
    // },
    {
        path : 'dashboard',
        component: Dashboard,
        layout : 'MasterLayoutRoute'
    },
    {
        path: 'tasks/:param1?',
        component: TaskTabPage,
        layout: 'MasterLayoutRoute'
    },
    {
        path: 'task-add',
        component: TaskAdd,
        layout: 'MasterLayoutRoute'
    },
    {
        path: 'payment',
        component: PaymentTab,
        layout: 'MasterLayoutRoute'
    },
    // {
    //     path: 'virtual-bank-account',
    //     component: VirtualBankAccount,
    //     layout: 'MasterLayoutRoute'
    // },
    {
        path: 'profile-settings',
        component: ProfileSettings,
        layout: 'MasterLayoutRoute'
    },
    {
        path: 'application-settings',
        component: ApplicationSettings,
        layout: 'MasterLayoutRoute'
    },
    {
        path: 'subscription-payment',
        component: SubscriptionPayment,
        layout: 'MasterLayoutRoute'
    },    
    {
        path : '*',
        component: ErrorComp,
        layout : 'BeforeLoginLayoutRoute'
    },

];

const loadCustRoutes = function() {

    let routes = [];    
    routeArr.forEach((item, index)=>{
        if(item.layout=="MasterLayoutRoute"){
            routes.push(<MasterLayoutRoute key={index} path={'/'+item.path} exact component={item.component} /> );
        }
        else if(item.layout=="BeforeLoginLayoutRoute"){
            routes.push(<BeforeLoginLayoutRoute key={index} path={'/'+item.path} exact component={item.component} /> );
        }
        else if(item.layout=="NoLayoutRoute"){
            routes.push(<NoLayoutRoute key={index} path={'/'+item.path} exact component={item.component} /> );
        }
        
    });    
    
    return routes;
};

export default loadCustRoutes;