/** 
 * Signin
 * Page/Class name   : Signin
 * Author            :
 * Created Date      : 10-2-2020
 * Functionality     : Signin
 * Purpose           : render Signup component
**/
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import UserLoginOtpService from '../../service/UserLoginOtpService';
import CustLoader from '../after_login/CustLoader';
import { withRouter } from "react-router-dom";

// for notification
// import { store } from 'react-notifications-component';
import {commonJsFuncModule as commonJsObj} from  '../../../common_utilities/commonjsfunc';
import {notify} from '../../messagebar/CustomMessageBar';


const custEncode = commonJsObj.custEncode;
const setUserInfoBeforeLogin = commonJsObj.setUserInfoBeforeLogin;
const checkInvalidEmailIdDomain = commonJsObj.checkInvalidEmailIdDomain;

class Signin extends Component {

  isComponentLoaded = false;

  

  /**
   * Signin
   * Function name : constructor
   * Author        :
   * Created Date  : 10-2-2020
   * Purpose       : initializing state
   * Params        : props
  **/
  constructor(props) {
      super(props);  
      
      this.state = {
        emailId: '',
        emailIdErMsg: '',
        loaderFlag: false,
      }
    
  }

  /**
     * Signin
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 10-2-2020
     * Purpose       : life cycle method, calling api to get week tweet count
     * Params        : 
    **/
  
  componentDidMount() {
      this.isComponentLoaded = true; 
      

      
  }

  handlePlaceChanged(){
    const place = this.autocomplete.getPlace();
    this.props.onPlaceLoaded(place);
  }

  
  /**
     * Signin
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 10-2-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
   componentWillUnmount() {  
    this.isComponentLoaded = false; 
  }

  /**
   * Signin
   * Function name : showLoader
   * Author        :
   * Created Date  : 10-2-2020
   * Purpose       : Used to show loader
   * Params        : 
  **/
  showLoader(){
    this.isComponentLoaded && this.setState({loaderFlag: true});
  }

  /**
   * Signin
   * Function name : hideLoader
   * Author        :
   * Created Date  : 10-2-2020
   * Purpose       : Used to hide loader
   * Params        : 
  **/
  hideLoader(){
    this.isComponentLoaded && this.setState({loaderFlag: false});
  }

  /**
   * Signin
   * Function name : loadLoader
   * Author        :
   * Created Date  : 10-2-2020
   * Purpose       : Used to load loader
   * Params        : 
  **/
  loadLoader(){
      return (
                <CustLoader className={'miniButnLoaderWhite'} loaderText={''}  />
      );
  }

  

  /**
   * Signin
   * Function name : render
   * Author        :
   * Created Date  : 10-2-2020
   * Purpose       : lifecycle method
   * Params        : 
  **/
  render(){   
    
      return (
       <>
          <div className="signup_form_area">
                  <div className="sign_up_top">
                    <h1>Sign in</h1>
                    <div className="subheading">
                      Quickly sign in using an OTP sent to your email ID
                    
                    </div>
                  </div>
                  <div className="form_area">
                    {/*===  form start === */}
                    <form>
                      <div className="form_block">
                        <div className="panel100 paddingleftright15 tooltip_input_bg">
                          <div className={`form__group field  ${ this.state.emailIdErMsg?'form_group_error':''}`}>
                            <input
                              type="input"
                              className="form__field"
                              placeholder="Organisation Email ID"
                              name="emailId"
                              id="emailId"       
                              autoComplete="off"
                              aut
                              ref='emailId' 
                              onChange={ this.handleChange }
                              onKeyDown={(evn)=>{ 
                                if(evn.keyCode==13){
                                evn.preventDefault();
                                } 
                              }}
                            />
                            <label htmlFor="name" className="form__label">
                              Organisation Email ID
                            </label>
                            <span className="error_span">{this.state.emailIdErMsg}</span>
                          </div>
                          <div className="input_tooltip_bg">
                            <span
                              className="input_tooltip"
                              aria-label="Organisation Email ID"
                              data-balloon-pos="down"
                            >
                              <i className="fa fa-question-circle" />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="form_block">
                        <div className="panel100 paddingleftright15 button_ip_panel">          
                          <button disabled={this.state.loaderFlag} onClick={(event) => this.handleClick(event)}   type="button" className="btn btn-primary sub_grd_btn">
                            <span>Send OTP</span>
                          </button>
                        </div>
                      </div>
                    </form>
                    {/*===  form end === */}
                    {/* ===  login bottm === */}
                    <div className="login_page_bottom">
                      <div className="log_bottm_sec">
                        <p>Don’t have an account?</p>
                        <div className="log_bottom_btn">
                          <button onClick={()=>{this.props.history.push("/signup");}}  className="log_page_btn">
                            <span>Sign up</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* ===  login bottm === */}
                  </div>
                </div>         
       </>
      
      );  
  }

  /**
   * Signin
   * Function name : handleChange
   * Author        :
   * Created Date  : 10-2-2020
   * Purpose       : Used to handle change event
   * Params        : 
  **/
  handleChange = (e) => {
  this.isComponentLoaded &&  this.setState({[e.target.name]: e.target.value}); 
  }

   /**
   * Signin
   * Function name : custValidate
   * Author        :
   * Created Date  : 12-2-2020
   * Purpose       : validate data before submit
   * Params        : 
  **/
  custValidate = ()=>{
    
    let emailIdErMsg = '';   
    let totErrCountTrack = [];   
    let regexBlankSpace = /^ *$/;   

    if(!(this.state.emailId)||(this.state.emailId.length==0)|| (regexBlankSpace.test(this.state.emailId)==true)){
      emailIdErMsg = "Email-ID is required.";        
      this.isComponentLoaded && this.setState({ "emailIdErMsg": emailIdErMsg});
      totErrCountTrack.push(emailIdErMsg);
    }    
    else if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.emailId)==false)
    {
        emailIdErMsg = "Provide a proper Email-ID.";        
        this.isComponentLoaded && this.setState({ "emailIdErMsg": emailIdErMsg});
        totErrCountTrack.push(emailIdErMsg);
    }
    else{
          // check this emailid domain are allowed or not
          let splitEmailAr = (this.state.emailId).split("@");
          let foundInvalidEmailDomain = false;         

          if(splitEmailAr.length>1){
            let emlDomain = splitEmailAr[1];
            if(checkInvalidEmailIdDomain(emlDomain)==true){
                foundInvalidEmailDomain = true;
            }
          }  

          if(foundInvalidEmailDomain){
            emailIdErMsg = "Personal email addresses are not allowed.";        
            this.isComponentLoaded && this.setState({ "emailIdErMsg": emailIdErMsg});
            totErrCountTrack.push(emailIdErMsg);
          }
          else{
            this.isComponentLoaded &&  this.setState({ "emailIdErMsg":''});
          }      
    }

    
    if(totErrCountTrack.length>0){
      return false;
    }     

    return true;
  }

  handleClick = (event) => {
    event.preventDefault();

    this.isComponentLoaded && this.setState({loaderFlag:true});
    const isValid = this.custValidate();

    if(isValid){
      const creds = { emailId: this.state.emailId };
      let lserv = new UserLoginOtpService();
      lserv.getLoginOtpResp(creds).then(data =>{
          if(data.status==200){
              //set emailId to ls for otp                 
              let userInfoLocBl = {
                bl_emailid: custEncode(this.state.emailId),                
              };
              setUserInfoBeforeLogin(userInfoLocBl);

              let succMsg = "Success";
              notify(succMsg,true,{
                'successMsg':1,
                'customWrapClass':'customsuccclass'});
                setTimeout(()=>{
                    this.props.history.push("/signin-otp");
                },900);              
            }
        
      }).catch(err=>{
        
        let errStatusCodeAr = [400,500]; // allowed error status code
        if(err.response && err.response.data){
            let erRespData = err.response.data;
            if(errStatusCodeAr.indexOf(erRespData.status) !== -1){         
                let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                notify(errMsg,true,{
                'successMsg':0,
                'customWrapClass':'customerrclass'});
          }
        }         
  
      }).finally(()=>{
        setTimeout(()=>{
          this.isComponentLoaded && this.setState({loaderFlag:false},()=>{
              //this.props.history.push("/signin-otp");
            });
        },1500);
        
      });
    }else{
      this.isComponentLoaded && this.setState({loaderFlag:false});      
    }   
    
  } 

 

}

export default withRouter(Signin);