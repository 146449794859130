/**
 * SplitScreenTaskInvoiceView
 * Page/Class name   : SplitScreenTaskInvoiceView
 * Author            :
 * Created Date      : 7-5-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : render master component
 **/
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { notify } from "../../messagebar/CustomMessageBar";
import CustomMessageBar from "../../messagebar/CustomMessageBar";
import CustReactBootstrapModal from "./CustReactBootstrapModal";
import TaskInvoiceShare from "./TaskInvoiceShare";

// for context
import CommonContext from "../../context/CommonContext";
// scroll
// Newest1 - Start
import { Scrollbars } from "react-custom-scrollbars";
// Newest1 - End

//services
import TaskInvoiceHistoryService from "../../service/TaskInvoiceHistoryService";
//import  CustDate  from '../../../common_utilities/CustDate';
import { commonJsFuncModule as commonJsObj } from "../../../common_utilities/commonjsfunc";
// For loading CustLoader
import CustLoader from "./CustLoader";
// Left Menu
import SplitScreenLeftMenu from "../../share/SplitScreenLeftMenu";

// common noti track
import NotiEmitTrack from "./NotiEmitTrack";
import { commonnotitrack } from "./NotiEmitTrack";

// view pdf related
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const getCcUserId = commonJsObj.getCcUserId;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getBaseUrl = commonJsObj.getBaseUrl;

class SplitScreenTaskInvoiceView extends Component {
  isComponentLoaded = false;
  // for context
  static contextType = CommonContext;

  /**
   * SplitScreenTaskInvoiceView
   * Function name : constructor
   * Author        :
   * Created Date  : 7-5-2020
   * Purpose       : initializing children
   * Params        : props
   **/
  constructor(props) {
    super(props);
    this.children = props.children;
    this.fileUpl = React.createRef();
    this.state = {
      lpUserId: 0,
      ccUserId: 0,
      taskOrSubTask: "",
      taskIdOrSubTaskId: 0,
      listData: [],
      pageNum: 1,
      totalNumberOfPages: 1,
      loaderFlag: true,
      pageNumArTrack: [],
      uploadingFlag: false,
      invoiceInfo: null,
      pageNumber: 1,
      numPages: null,
      pdfLoadErr: false,
      isTaskCompleted: false,
      isPaid: false,
      downloadUrl: null,
      // for confirm modal
      showConfirmModal: false,
    };
    this.scrollerRef = React.createRef();
    // for confirm modal
    this.modalforconfirmRef = React.createRef();
  }

  /**
   * SplitScreenTaskInvoiceView
   * Function name : componentDidMount
   * Author        :
   * Created Date  : 7-5-2020
   * Purpose       : lifecycle method
   * Params        :
   **/
  componentDidMount() {
    this.isComponentLoaded = true;

    //
    let taskOrSubTask = "";
    let taskIdOrSubTaskId = 0;
    let lpUserId = 0;
    let ccUserId = 0;
    let triggeredFrom = "";
    let isTaskCompleted = false;

    //********* for context starts *****
    let sharedData =
      this.context !== undefined && this.context.share !== undefined
        ? this.context.share
        : null;
    if (
      sharedData !== undefined &&
      sharedData !== null &&
      sharedData.actionType !== undefined
    ) {
      if (sharedData.actionType == "SPLITSCREEN") {
        if (sharedData.other !== undefined && sharedData.other !== null) {
          triggeredFrom =
            sharedData.other.triggeredFrom !== undefined
              ? sharedData.other.triggeredFrom
              : "";
          //*** when user comes by clicking on monitor icon from split screen left menu starts
          if (
            triggeredFrom == "TASK_SPLIT_SCREEN_LEFT_MENU" ||
            triggeredFrom == "BELL_NOTIFICATION_MENU" ||
            triggeredFrom == "TASK_INVOICE_HISTORY" ||
            triggeredFrom == "PAYMENT_PAGE"
          ) {
            //console.log.log(" sharedData.other==>",sharedData.other);

            taskOrSubTask =
              sharedData.other.subTasksCount !== undefined &&
              sharedData.other.subTasksCount !== null &&
              sharedData.other.subTasksCount > 0
                ? "SWSTASK"
                : "STASK";

            if (taskOrSubTask == "STASK") {
              if (
                sharedData.other.taskResp !== undefined &&
                sharedData.other.taskResp !== null
              ) {
                let taskResp = sharedData.other.taskResp;

                taskIdOrSubTaskId =
                  taskResp.id !== undefined && taskResp.id !== null
                    ? taskResp.id
                    : 0;

                lpUserId =
                  taskResp.assignedTo !== undefined &&
                  taskResp.assignedTo !== null &&
                  taskResp.assignedTo.userId !== undefined &&
                  taskResp.assignedTo.userId !== null
                    ? taskResp.assignedTo.userId
                    : 0;

                isTaskCompleted =
                  taskResp.isTaskCompleted !== undefined &&
                  taskResp.isTaskCompleted !== null
                    ? taskResp.isTaskCompleted
                    : false;
              }
            } else if (taskOrSubTask == "SWSTASK") {
              // this scenario i.e task with sub task is not in sprint 5
            }

            ccUserId = getCcUserId();
          }
          //*** when user comes by clicking on monitor icon from split screen let menu ends
          //*** when user comes by clicking on monitor icon from professional interested list starts

          //*** when user comes by clicking on monitor icon from professional interested list ends
        }
      }

      ////console.log.log(" Now taskOrSubTask=>",taskOrSubTask," taskIdOrSubTaskId=>",taskIdOrSubTaskId, " lpUserId=>",lpUserId," ccUserId==>",ccUserId," triggeredFrom=>",triggeredFrom);
    }

    // call list api
    let paramObj = {
      pageNum: 1,
      pageNumArTrack: [],
      listData: [],
      totalNumberOfPages: 1,
      taskIdOrSubTaskId: taskIdOrSubTaskId,
      taskOrSubTask: taskOrSubTask,
      lpUserId: lpUserId,
      ccUserId: ccUserId,
      invoiceInfo: null,
      pageNumber: 1,
      numPages: null,
      pdfLoadErr: false,
      isTaskCompleted: isTaskCompleted,
    };
    this.isComponentLoaded &&
      this.setState(paramObj, () => {
        this.callServiceToFetchTaskInvoiceHistory(
          paramObj,
          "INVOICEVIEWCOMPONENTLOADED"
        );
      });

    //
  }

  /**
   * SplitScreenTaskInvoiceView
   * Function name : componentWillUnmount
   * Author        :
   * Created Date  : 7-5-2020
   * Purpose       : lifecycle method
   * Params        :
   **/
  componentWillUnmount() {
    this.isComponentLoaded = false;
  }

  /**
   * SplitScreenTaskInvoiceView
   * Function name : componentDidUpdate
   * Author        :
   * Created Date  : 7-5-2020
   * Purpose       : lifecycle method
   * Params        :
   **/
  componentDidUpdate(prevProps) {}

  /**
   * SplitScreenTaskInvoiceView
   * Function name : closeSplit
   * Author        :
   * Created Date  : 7-5-2020
   * Purpose       : closeSplit method to close the split screen
   * Params        :
   **/

  closeSplit = (objParam) => {
    //********* for context starts *****
    // let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;
    // if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){

    //     if(sharedData.actionType=="SPLITSCREEN" ){

    //       let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;

    //       if(setShare){
    //         let paramsCntxt = {
    //           "taskId":"",
    //           "actionType":"",
    //           "leftTabToActive":"",
    //           "purpose":"",
    //           "other":{}
    //         }
    //         setShare(paramsCntxt);
    //       }

    //     }
    // }
    //********* for context ends *****

    commonnotitrack("CLOSE_SPLITSCREEN", {
      notiType: "CLOSE_SPLITSCREEN",
      triggerCallback: false,
      trigerredFrom: "TASK_INVOICE_VIEW_SCREEN",
    });
  };

  callServiceToFetchTaskInvoiceHistory = (paramObj, trackcalledfrom) => {
    let paramObjData = {};
    paramObjData.pageNum = paramObj.pageNum;
    paramObjData.taskOrSubTask = paramObj.taskOrSubTask;
    paramObjData.taskIdOrSubTaskId = paramObj.taskIdOrSubTaskId;
    paramObjData.lpUserId = paramObj.lpUserId;

    //console.log.log(" trackcalledfrom=>",trackcalledfrom," paramObjData=>",paramObjData);

    let stcservice = new TaskInvoiceHistoryService();
    stcservice
      .getTaskInvoiceHistoryList({ data: paramObjData })
      .then((data) => {
        if (data.status == 200) {
          let respData = {};
          if (data.data.entity !== undefined) {
            respData = data.data.entity;
            if (respData.invoices !== undefined && respData.invoices !== null) {
              if (respData.invoices.length > 0) {
                let invoiceInfo = respData.invoices[0];
                let downloadUrl = null;
                let isPaid = false;
                if (
                  invoiceInfo &&
                  invoiceInfo.downloadUrl !== undefined &&
                  invoiceInfo.downloadUrl !== null
                ) {
                  downloadUrl =
                    invoiceInfo.downloadUrl +
                    "&auth=" +
                    getTokenFromLocStorage();
                }

                if (
                  invoiceInfo &&
                  invoiceInfo.isPaid !== undefined &&
                  invoiceInfo.isPaid !== null
                ) {
                  isPaid = invoiceInfo.isPaid;
                }

                this.isComponentLoaded &&
                  this.setState(
                    {
                      listData: respData.invoices,
                      totalNumberOfPages: respData.totalNumberOfPages,
                      invoiceInfo: invoiceInfo,
                      downloadUrl: downloadUrl,
                      isPaid: isPaid,
                    },
                    () => {
                      //console.log.log(" state invoiceInfo=>",this.state);
                    }
                  );
              }
            } else {
            }
          }
        }
      })
      .catch((err) => {
        let errStatusCodeAr = [400, 500]; // allowed error status code
        if (err.response && err.response.data) {
          let erRespData = err.response.data;
          if (errStatusCodeAr.indexOf(erRespData.status) !== -1) {
            let errMsg = erRespData.message
              ? erRespData.message
              : "Some technical problem occurred.";
            console.log(errMsg);
          }
        }
      })
      .finally(() => {
        this.hideLoader();
      });
  };

  /**
   * SplitScreenTaskInvoiceView
   * Function name : loadLoader
   * Author        :
   * Created Date  : 7-5-2020
   * Purpose       : load Loader
   * Params        :
   **/

  loadLoader() {
    return (
      <CustLoader className={"smallLoaderGreen"} loaderText={"Loading.."} />
    );
  }

  /**
   * SplitScreenTaskDocuments
   * Function name : hideLoader
   * Author        :
   * Created Date  : 7-5-2020
   * Purpose       : Hide loader
   * Params        :
   **/

  hideLoader() {
    this.isComponentLoaded && this.setState({ loaderFlag: false });
  }

  /**
   * SplitScreenTaskInvoiceView
   * Function name : showLoader
   * Author        :
   * Created Date  : 7-5-2020
   * Purpose       : showLoader
   * Params        :
   **/

  showLoader() {
    this.isComponentLoaded && this.setState({ loaderFlag: true });
  }

  /**
   * SplitScreenTaskInvoiceView
   * Function name : checkConfirmPopupToOpen
   * Author        :
   * Created Date  : 12-6-2020
   * Purpose       : pre check to open popup
   * Params        :
   **/
  checkConfirmPopupToOpen = (otherParam) => {
    this.confirmModalOpen(otherParam);
  };

  /**
   * SplitScreenTaskInvoiceView
   * Function name : checkConfirmPopupToOpen
   * Author        :
   * Created Date  : 12-6-2020
   * Purpose       : load footer
   * Params        :
   **/

  loadfooterOfInvoiceView = () => {
    let downloadUrl = this.state.downloadUrl;
    let isPaid = this.state.isPaid;
    let invoiceInfo = this.state.invoiceInfo;
    let otherParam = invoiceInfo;

    return (
      <>
        {/* === right slide section Footer  start ===  */}
        {/* Newest1 - Start */}
        {/* <div className="SlideS_bottombg flexbox-item Slide_chatBottomBg invoice_footer"> */}
        <div className="SlideS_bottombg flexbox-item Slide_chatBottomBg SlideS_bottombg_gray SlideS_bottombg_stickyFooterRightPart SlideS_bottombg_stickyFooterRightPart_invoice_footer">
          {/* Newest1 - End */}
          <div className="chat_bottom_left inv_bottom_left">
            {/* CC-823 */}
            {/* {this.state.taskOrSubTask=="STASK" && this.state.isTaskCompleted==false && isPaid==false && downloadUrl && (
                            <button onClick={(evn)=>{ evn.preventDefault();
                              this.checkConfirmPopupToOpen(otherParam);
                            }}
                            
                            className="btn_appearance_none sub_grd_btn">
                            <span>Make payment</span>
                            </button>
                          )} */}
          </div>
          <div className="s_footer_btnbg">
            <button
              onClick={(evn) => {
                evn.preventDefault();
                if (downloadUrl) {
                  //window.location.href = downloadUrl;
                  let winDwnObj = window.open(downloadUrl, "_blank");
                  winDwnObj.focus();
                }
              }}
              className="btn_appearance_none greenText_btn"
            >
              <span>Download</span>
            </button>
            {isPaid == false && (
              <TaskInvoiceShare
                custkeyid={"1"}
                custclassname="btn_appearance_none greenText_btn"
                datas={{
                  invoiceInfo: invoiceInfo,
                  taskOrSubTask: this.state.taskOrSubTask,
                  taskIdOrSubTaskId: this.state.taskIdOrSubTaskId,
                  lpUserId: this.state.lpUserId,
                  ccUserId: this.state.ccUserId,
                }}
                buttontextname={"Share Details"}
              />
            )}

            {/* <button className="btn_appearance_none greenText_btn">
                            <span>Share Invoice</span>
                          </button> */}
          </div>
        </div>
        {/* === right slide section Footer  end ===  */}
      </>
    );
  };

  /**
   * SplitScreenTaskInvoiceView
   * Function name : onDocumentLoadSuccess
   * Author        :
   * Created Date  : 12-6-2020
   * Purpose       : callback function on load pdf success
   * Params        :
   **/

  // view pdf file
  onDocumentLoadSuccess = ({ numPages }) => {
    this.isComponentLoaded &&
      this.setState({ numPages }, () => {
        let otherParam = this.state.specificFileInfo;
      });
  };

  /**
   * SplitScreenTaskInvoiceView
   * Function name : onDocumentLoadErr
   * Author        :
   * Created Date  : 12-6-2020
   * Purpose       : callback function for load pdf error
   * Params        :
   **/

  // view pdf file
  onDocumentLoadErr = (error) => {
    //console.log(" === error=>>",error);
    this.isComponentLoaded &&
      this.setState({ pdfLoadErr: true }, () => {
        let otherParam = this.state.specificFileInfo;
      });
  };

  /**
   * SplitScreenTaskInvoiceView
   * Function name : triggerPrevNextPdfPage
   * Author        :
   * Created Date  : 12-6-2020
   * Purpose       : for viewing pdf prev next page
   * Params        :
   **/

  // view pdf file
  triggerPrevNextPdfPage = (action, param) => {
    let pageNumber = this.state.pageNumber;
    let numPages = this.state.numPages;
    if (action == "PREV") {
      if (pageNumber > 1) {
        pageNumber = pageNumber - 1;
      }
    } else if (action == "NEXT") {
      if (numPages !== null && pageNumber != numPages) {
        pageNumber = pageNumber + 1;
      }
    }
    this.isComponentLoaded &&
      this.setState({ pageNumber: pageNumber }, () => {
        let otherParam = this.state.specificFileInfo;
      });
  };

  /**
   * SplitScreenTaskInvoiceView
   * Function name : loadTheContent
   * Author        :
   * Created Date  : 12-6-2020
   * Purpose       : load the content on the task voice view
   * Params        :
   **/

  loadTheContent = () => {
    const { pageNumber, numPages, pdfLoadErr } = this.state;

    let forSplitScrnCls2Inject = "";
    let datasPassForAssignObj = {};
    //********* for context starts *****
    // context share
    let setShare =
      this.context !== undefined && this.context.setShare !== undefined
        ? this.context.setShare
        : null;
    let sharedData =
      this.context !== undefined && this.context.share !== undefined
        ? this.context.share
        : null;
    if (
      sharedData !== undefined &&
      sharedData !== null &&
      sharedData.actionType !== undefined
    ) {
      if (sharedData.actionType == "SPLITSCREEN") {
        forSplitScrnCls2Inject = "margin0";
      }
    }
    //********* for context ends *****

    return (
      <>
        {/* Dashboard Slide Split Right start  */}
        <div
          className={`splitscreen_area
              ${forSplitScrnCls2Inject ? forSplitScrnCls2Inject : ""}`}
        >
          {/* Newest1 - Start */}
          {/* <div className="slideScreen_bg slideScreen_Chatbg"> */}
          <div className="slideScreen_bg slideScreen_Chatbg slideScreen_bg_heightAdjust">
            {/* Newest1 - End */}
            <SplitScreenLeftMenu />

            <div className="slideScreen_rightbg flexbox-parent">
              {/* === right slide section top start ===  */}
              <div className="SlideS_topbg flexbox-item slide_chat_topbg splitInvoiceTop">
                <div className="slide_top_titlebg">
                  <div className="slideChat_head">
                    {/* 
                                      <div class="slideChat_panel">
                                          <button class="btn_appearance_none chatHeadBack"><span>Back</span></button>
                                      </div> 
                                      */}
                    <div className="slideChat_panel">
                      <h5>Pay Invoice</h5>
                    </div>
                    <div className="invo_top_btnbg">
                      <button
                        onClick={(evn) => {
                          evn.preventDefault();
                          if (setShare && sharedData) {
                            // invoiceViewedPaidStatus is injected into context from SplitscreenTaskInvoice page if the invoice voice is unpaid

                            sharedData.leftTabToActive = "INVOICE";
                            sharedData.purpose = "VIEW_INVOICE_LIST";
                            sharedData.other.triggeredFrom =
                              "VIEW_INVOICE_SCREEN";
                            sharedData.other.invoiceViewedPaidStatus =
                              this.state.isPaid;
                            sharedData.other.invoiceInfo =
                              this.state.invoiceInfo;
                            setShare(sharedData);
                          }
                        }}
                        className="btn_appearance_none greenText_btn"
                      >
                        Invoice history
                      </button>
                    </div>
                    <div className="clearboth" />
                  </div>
                  <button
                    onClick={(evn) => {
                      evn.preventDefault();
                      this.closeSplit({
                        trigerredFrom: "TASK_INVOICE_VIEW_SCREEN",
                      });
                    }}
                    className="btn_appearance_none slideTop_cross_btn cross"
                  >
                    <img src={getBaseUrl() + "assets/images/red_cross.png"} />
                  </button>
                </div>
              </div>
              {/* === right slide section top end ===  */}
              {/* === right slide section Middlie  start ===  */}
              {/* Newest1 - Start */}
              {/* <div className="SlideS_Midbg flexbox-item-grow SlideS_Midbg_chat SlideS_Midbg_invoice Pdf__invoice_show_bg"> */}
              <div class="SlideS_Midbg flexbox-item-grow SlideS_Midbg_chat SlideS_Midbg_invoice SlideS_Midbg_heightAdjustment SlideS_Midbg_heightAdjustment_invoicePdfView">
                {/* <div className="s_midbox_scroll_adjust  reactTestScroll scrollInvoiceBg"> */}
                <div
                  ref={this.scrollerRef}
                  className="s_midbox_scroll_adjust reactTestScroll scrollInvoiceBg mCustomAdjustmentForStickFooter rightMidPartHeightAdjustment rightMidPartHeightAdjustment_scrollInvoiceBg_pdfView"
                >
                  {/* <Scrollbars
                    ref={this.scrollerRef}
                    style={{ height: 675 }}
                    autoHeightMin={675}
                    renderView={(props) => (
                      <div
                        {...props}
                        className="view invoiceviewscreenscrollclass"
                      />
                    )}
                    onScrollStop={this.trackScroll}
                  > */}
                  {/* Newest1 - End */}
                  <div className="s_midbox_section s_midbox_section_inv">
                    {/* ====   sprint - 6 invoice main structure start ====  */}
                    {/* invoice import body start  */}
                    <div className="invoice_importbg">
                      <div className="invoice_import_box">
                        {/* === Dynamic Invoice Import section start ===    */}

                        {this.state.invoiceInfo === null ||
                          (this.state.invoiceInfo !== null &&
                            (this.state.invoiceInfo.base64 === undefined ||
                              (this.state.invoiceInfo.base64 !== undefined &&
                                this.state.invoiceInfo.base64 === null)) && (
                              <div className="text-center">No record found</div>
                            ))}
                        {this.state.invoiceInfo !== null &&
                          this.state.invoiceInfo.base64 !== undefined &&
                          this.state.invoiceInfo.base64 !== null && (
                            //  <embed src={`data:application/pdf;base64,${this.state.invoiceInfo.base64!==undefined && this.state.invoiceInfo.base64!==null?this.state.invoiceInfo.base64:""}`}  type="application/pdf" width="100%" height="auto"></embed>

                            <div className="invoiceviewpage forpdfviewcustclass">
                              {pdfLoadErr == false && (
                                <div className="okPdf">
                                  {numPages > 1 && (
                                    <div className="pdfpagi">
                                      <p>
                                        Page {pageNumber} of {numPages}
                                      </p>
                                      <button
                                        className="prevClass"
                                        onClick={(evn) => {
                                          evn.preventDefault();
                                          this.triggerPrevNextPdfPage(
                                            "PREV",
                                            {}
                                          );
                                        }}
                                      >
                                        Previous{" "}
                                      </button>
                                      <button
                                        className="nextClass"
                                        onClick={(evn) => {
                                          evn.preventDefault();
                                          this.triggerPrevNextPdfPage(
                                            "NEXT",
                                            {}
                                          );
                                        }}
                                      >
                                        Next
                                      </button>
                                    </div>
                                  )}
                                  <Document
                                    file={`data:application/pdf;base64,${
                                      this.state.invoiceInfo.base64 !==
                                        undefined &&
                                      this.state.invoiceInfo.base64 !== null
                                        ? this.state.invoiceInfo.base64
                                        : ""
                                    }`}
                                    onLoadSuccess={this.onDocumentLoadSuccess}
                                    onLoadError={this.onDocumentLoadErr}
                                  >
                                    <Page pageNumber={pageNumber} />
                                  </Document>
                                </div>
                              )}
                              {pdfLoadErr == true && (
                                <div className="errPdf text-center">
                                  Something went wrong, please try later.
                                </div>
                              )}
                            </div>
                          )}

                        {/* === Dynamic Invoice Import section end ===    */}
                      </div>
                    </div>
                    {/* invoice import body end  */}
                    {/* ====   sprint - 6 invoice main structure end ====  */}
                  </div>
                  {/* Newest1 - Start */}
                  {/* </Scrollbars> */}
                  {/* Newest1 - End */}
                </div>
              </div>
              {/* === right slide section Middlie  end ===  */}
              {this.loadfooterOfInvoiceView()}
            </div>
          </div>
        </div>

        {/* Dashboard Slide Split Right end  */}
      </>
    );
  };

  confirmButtonClicked = (otherParam) => {
    this.confirmModalClose();
    setTimeout(() => {
      //********* for context starts *****
      let sharedData =
        this.context !== undefined && this.context.share !== undefined
          ? this.context.share
          : null;
      if (
        sharedData !== undefined &&
        sharedData !== null &&
        sharedData.actionType !== undefined
      ) {
        let setShare =
          this.context !== undefined && this.context.setShare !== undefined
            ? this.context.setShare
            : null;

        if (setShare) {
          // only close split screen keeping other context data intact
          sharedData.actionType = "";
          setShare(sharedData);
        }
      }
      //********* for context ends *****
    }, 250);
    setTimeout(() => {
      this.props.history.push("/payment");
    }, 1200);
  };

  /*** Confirm Modal  starts  */
  /**
   * SplitScreenTaskInvoiceView
   * Function name : confirmModalOpen
   * Author        :
   * Created Date  : 12-6-2020
   * Purpose       : To open delete modal
   * Params        :
   **/
  confirmModalOpen = (otherParam) => {
    this.isComponentLoaded &&
      this.setState({ showConfirmModal: true }, () => {
        this.modalforconfirmRef.current.showModal();
        this.getConfirmModalContent(otherParam);
      });
  };

  /**
   * SplitScreenTaskInvoiceView
   * Function name : confirmModalClose
   * Author        :
   * Created Date  : 12-6-2020
   * Purpose       : To close organisationDetailModal
   * Params        :
   **/
  confirmModalClose = () => {
    this.isComponentLoaded &&
      this.setState({ showConfirmModal: false }, () => {
        this.modalforconfirmRef.current.hideModal();
      });
  };

  /**
   * SplitScreenTaskInvoiceView
   * Function name : getConfirmModalContent
   * Author        :
   * Created Date  : 12-6-2020
   * Purpose       : Get modal view with data
   * Params        :
   **/
  getConfirmModalContent(otherParam) {
    let viewData = (
      <div className="dash_modal">
        <>
          <div className="modal-header">
            <h4 className="modal-title modal_title_tool">
              {" "}
              Task Completion Check
              {/*<div className="input_tooltip_bg">
                        <span className="input_tooltip" aria-label="Organisation's Details" data-balloon-pos="down">
                        <i className="fa fa-question-circle"></i>
                        </span>
                        </div>*/}
            </h4>
            <button
              type="button"
              onClick={(evn) => {
                evn.preventDefault();
                this.confirmModalClose();
              }}
              className="close modal_close"
              data-dismiss="modal"
            >
              &times;
            </button>
          </div>
          <CustomMessageBar />
          <div className="modal-body padding_top">
            <div className="popUP_text_p">
              <p>
                Please review the final task budget, deadline & the documents
                uploaded by the lawyer under documents tab for your task. Click
                on continue if you acknowledge reviewing everything.
              </p>
            </div>
          </div>
          <div className="modal-footer">
            <button
              onClick={(evn) => {
                evn.preventDefault();
                this.confirmModalClose();
              }}
              type="button"
              data-dismiss="modal"
              className="btn_appearance_none modal_text_btn"
            >
              <span>Cancel</span>
            </button>
            <button
              disabled={this.state.continueDisabled}
              type="button"
              className="btn btn-primary modal_btn_save"
              onClick={(e) => {
                e.preventDefault();
                this.confirmButtonClicked({});
              }}
              data-dismiss="modal"
            >
              <span>Confirm</span>
            </button>
          </div>
        </>
      </div>
    );

    this.modalforconfirmRef.current.updateStateContent(viewData);
    this.modalforconfirmRef.current.showContent();
    return viewData;
  }

  /*** Confirm Modal ends  */
  // trackNotiForCloseSplit = (otherParam) =>{

  //   let notiType =  otherParam.notiType!==undefined? otherParam.notiType: "";
  //   let trigerredFrom =  otherParam.trigerredFrom!==undefined? otherParam.trigerredFrom: "";
  //   if(notiType=="CLOSE_SPLITSCREEN"){
  //       let closeSplitHeaderCallFrm = ["TASK_INVOICE_VIEW_SCREEN"];
  //       if(closeSplitHeaderCallFrm.indexOf(trigerredFrom)!=-1){
  //               console.log("*******trigerredFrom=>>",trigerredFrom);
  //       }

  //   }
  // }
  /**
   * SplitScreenTaskInvoiceView
   * Function name : render
   * Author        :
   * Created Date  : 12-6-2020
   * Purpose       : lifecycle method
   * Params        :
   **/
  render() {
    return (
      <React.Fragment>
        <NotiEmitTrack
          callbackfuncforcommontrackofnoti={this.trackNotiForCloseSplit}
        />
        {this.loadTheContent()}

        {/* Confirm  modal start */}
        <CustReactBootstrapModal
          ref={this.modalforconfirmRef}
          backdrop={false}
          backdropClassName="custom_modal_class"
          animation={false}
          show={this.state.showConfirmModal}
          onShow={() => {}}
          onHide={() => {}}
          modalcustdialog="custom_modal_lg"
          loaderbodytitle="Organisation"
        />
        {/* Confirm  modal end */}
      </React.Fragment>
    );
  }
}
export default withRouter(SplitScreenTaskInvoiceView);
