/**
 * BellNotificationList
 * Page/Class name   : BellNotificationList
 * Author            :
 * Created Date      : 3-7-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : rendering loader structure
 **/
import React, { Component, Suspense, lazy } from "react";
//import Dropdown from 'react-bootstrap/Dropdown';
//import DropdownButton from 'react-bootstrap/DropdownButton';
import { withRouter } from "react-router-dom";
import "../../../custom_resources/css/custom.css";

import { commonJsFuncModule as commonJsObj } from "../../../common_utilities/commonjsfunc";

// For loading CustLoader
import CustLoader from "./CustLoader";

// scroll
import { Scrollbars } from "react-custom-scrollbars";

// Services
import NotificationListFetchService from "../../service/NotificationListFetchService";
import NotiStatusUpdateService from "../../service/NotiStatusUpdateService";
import NotiAllStatusUpdateService from "../../service/NotiAllStatusUpdateService";
import NotiRemoveService from "../../service/NotiRemoveService";
import NotiAllRemoveService from "../../service/NotiAllRemoveService";
import TaskDetailsService from "../../service/TaskDetailsService";

// for realtime
import SockJS from "sockjs-client";

// for context
import CommonContext from "../../context/CommonContext";

import CustDate from "../../../common_utilities/CustDate";

// common noti track
//import NotiEmitTrack from "./NotiEmitTrack";
import { commonnotitrack } from "./NotiEmitTrack";

const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getCcUserId = commonJsObj.getCcUserId;
const getBaseUrl = commonJsObj.getBaseUrl;
const getOrgName = commonJsObj.getOrgName;

//for realtime websocket url
const sockUrl = "/ws?token=" + getTokenFromLocStorage();
//for realtime subscription url to receive notification
const subscUrl1 = "/topic/notification." + getCcUserId();

const setAccessObj = commonJsObj.setAccessObj;

//for realtime
var Stomp = require("stompjs");

class BellNotificationList extends Component {
  /**
   * BellNotificationList
   * Function name : constructor
   * Author        :
   * Created Date  :
   * Purpose       : initializing state
   * Params        : props
   **/

  isComponentLoaded = false;

  //
  clientsocket = null;

  // for context
  static contextType = CommonContext;

  constructor(props) {
    super(props);
    this.state = {
      notiListOpenFlag: false,
      bellButtonFlag: false,
      markAllReadButtonFlag: false,
      removeAllNotiButtonFlag: false,

      // list related
      listData: [],
      pageNum: 1,
      totalNumberOfPages: 1,
      loaderFlag: true,
      pageNumArTrack: [],
      totalUnreadNotificationCount: 0,
      //
    };

    this.menuDropDownDivRef = React.createRef();
    this.menuDropDownDivOrigRef = React.createRef();
    this.scrollerRef = React.createRef();
    // for realtime
    this.clientsocket = Stomp.over(new SockJS(sockUrl));
  }

  /**
   * BellNotificationList
   * Function name : componentDidMount
   * Author        :
   * Created Date  :
   * Purpose       : lifecycle method
   * Params        :
   **/
  componentDidMount() {
    this.isComponentLoaded = true;

    setTimeout(() => {
      this.reloadBellData();
    }, 1500);

    //console.log(sockUrl);

    // for realtime
    const ref = this;
    this.clientsocket.connect({}, function () {
      //console.log("==Yes connected ==");
      // share clientsocket object
      /*
            let setShare  = ref.context!==undefined && ref.context.setShare!==undefined?ref.context.setShare:null;
            let sharedData  = ref.context!==undefined && ref.context.share!==undefined?ref.context.share:null;

            if(sharedData!==undefined && sharedData!==null && setShare!==undefined && setShare!==null){

              sharedData.clientsocket  = ref.clientsocket;
              //console.log(" Now set sharedData===>>>>",sharedData);
              setShare(sharedData);
            }
            */

      // now subscribe to
      this.subscribe(subscUrl1, (message) => {
        // called when the client receives a STOMP message from the server
        ref.callbackSubscUrl1(message);
      });
    });
    //
  }

  /**
   * BellNotificationList
   * Function name : componentWillUnmount
   * Author        :
   * Created Date  :
   * Purpose       : lifecycle method
   * Params        :
   **/
  componentWillUnmount() {
    this.isComponentLoaded = false;
  }

  reloadBellData = () => {
    // call list api
    let paramObj = {
      listData: [],
      pageNum: 1,
      totalNumberOfPages: 1,
      loaderFlag: true,
      pageNumArTrack: [],
      totalUnreadNotificationCount: 0,
    };
    this.isComponentLoaded &&
      this.setState(paramObj, () => {
        this.callServiceToFetchNotiList(
          paramObj,
          "*****called from componentDidMount**** "
        );
      });
  };

  // for realtime
  callbackSubscUrl1 = (message) => {
    // called when the client receives a STOMP message from the server
    if (message.body) {
      console.log(
        "=====got message with body from bell noti compo =>>>",
        message.body
      );

      let jsonWsResp =
        message.body !== undefined && message.body !== null
          ? JSON.parse(message.body)
          : null;
      let webSocketTaskInfo = null;
      let webSocketNotificationInfo = null;
      let webSocketCommentInfo = null;
      let webSocketNegotiationChatInfo = null;
      let notificationId = 0;
      let taskIdFromNoti = 0;
      let notificationTriggeredBy = null;
      //taskOwner
      let ccUserIdFromNoti = 0;

      if (jsonWsResp !== null) {
        webSocketTaskInfo =
          jsonWsResp.webSocketTaskInfo !== undefined &&
          jsonWsResp.webSocketTaskInfo !== null
            ? jsonWsResp.webSocketTaskInfo
            : null;

        webSocketNotificationInfo =
          jsonWsResp.webSocketNotificationInfo !== undefined &&
          jsonWsResp.webSocketNotificationInfo !== null
            ? jsonWsResp.webSocketNotificationInfo
            : null;

        webSocketNegotiationChatInfo =
          jsonWsResp.webSocketNegotiationChatInfo !== undefined &&
          jsonWsResp.webSocketNegotiationChatInfo !== null
            ? jsonWsResp.webSocketNegotiationChatInfo
            : null;

        webSocketCommentInfo =
          jsonWsResp.webSocketCommentInfo !== undefined &&
          jsonWsResp.webSocketCommentInfo !== null
            ? jsonWsResp.webSocketCommentInfo
            : null;

        //console.log("==paresed==>> webSocketTaskInfo=>",webSocketTaskInfo," webSocketNotificationInfo==>>",webSocketNotificationInfo, " webSocketCommentInfo==>>",webSocketCommentInfo," typeof(webSocketNotificationInfo=>)",typeof(webSocketNotificationInfo));
        notificationId =
          webSocketNotificationInfo.notificationId !== undefined
            ? webSocketNotificationInfo.notificationId
            : 0;

        notificationTriggeredBy =
          webSocketNotificationInfo !== null &&
          webSocketNotificationInfo.notificationTriggeredBy !== undefined &&
          webSocketNotificationInfo.notificationTriggeredBy !== null
            ? webSocketNotificationInfo.notificationTriggeredBy
            : null;

        let notifiSenderId =
          notificationTriggeredBy !== null &&
          notificationTriggeredBy.id !== undefined &&
          notificationTriggeredBy.id !== null
            ? notificationTriggeredBy.id
            : 0;

        let firstName =
          notificationTriggeredBy !== null &&
          notificationTriggeredBy.firstName !== undefined &&
          notificationTriggeredBy.firstName !== null
            ? notificationTriggeredBy.firstName
            : "";

        let lastName =
          notificationTriggeredBy !== null &&
          notificationTriggeredBy.lastName !== undefined &&
          notificationTriggeredBy.lastName !== null
            ? notificationTriggeredBy.lastName
            : "";

        let lpUserName = firstName + " " + lastName;

        if (
          webSocketNotificationInfo &&
          webSocketNotificationInfo.userNotificationType !== undefined &&
          notificationId
        ) {
          this.closeTheListDropDown({ calledFrom: "REAL_TIME_NOTI" });

          let userNotificationType =
            webSocketNotificationInfo !== null &&
            webSocketNotificationInfo.userNotificationType !== undefined &&
            webSocketNotificationInfo.userNotificationType !== null
              ? webSocketNotificationInfo.userNotificationType
              : "";

          if (userNotificationType == "KYC") {
            setAccessObj({ key: "isKycVerified", keyValue: true });
            setTimeout(() => {
              commonnotitrack("KYC", {
                notiType: "KYC",
                notificationId: notificationId,
              });
            }, 1200);
          }

          if (
            userNotificationType == "SUBSCRIPTION_SUCCESS" ||
            userNotificationType == "SUBSCRIPTION_WAIVER"
          ) {
            setAccessObj({ key: "isSubscriptionActive", keyValue: true });
            setTimeout(() => {
              commonnotitrack("SUBSCRIPTION_SUCCESS", {
                notiType: "SUBSCRIPTION_SUCCESS",
                notificationId: notificationId,
              });
            }, 1200);
          }

          if (userNotificationType == "SUBSCRIPTION_FAILURE") {
            setAccessObj({ key: "isSubscriptionActive", keyValue: false });
            setTimeout(() => {
              commonnotitrack("SUBSCRIPTION_FAILURE", {
                notiType: "SUBSCRIPTION_FAILURE",
                notificationId: notificationId,
              });
            }, 1200);
          }

          if (userNotificationType == "TASK_TERMS") {
            //console.log("1 from bell noti userNotificationType=>>",userNotificationType);
            // lp has taken the task
            commonnotitrack("TASK_TERMS", {
              notiType: "TASK_TERMS",
              taskId:
                webSocketTaskInfo &&
                webSocketTaskInfo.taskId !== undefined &&
                webSocketTaskInfo.taskId
                  ? webSocketTaskInfo.taskId
                  : 0,
              notificationId: notificationId,
              notifiSenderId: notifiSenderId,
            });
          }

          if (userNotificationType == "TASK_ACCEPTED") {
            //console.log("1 from bell noti userNotificationType=>>",userNotificationType);
            // lp has taken the task
            commonnotitrack("TASK_ACCEPTED", {
              notiType: "TASK_ACCEPTED",
              taskId:
                webSocketTaskInfo &&
                webSocketTaskInfo.taskId !== undefined &&
                webSocketTaskInfo.taskId
                  ? webSocketTaskInfo.taskId
                  : 0,
              notificationId: notificationId,
              notifiSenderId: notifiSenderId,
            });
          }

          if (userNotificationType == "TASK_DECLINED") {
            //console.log("1 from bell noti userNotificationType=>>",userNotificationType);
            // lp has taken the task
            commonnotitrack("TASK_DECLINED", {
              notiType: "TASK_DECLINED",
              taskId:
                webSocketTaskInfo &&
                webSocketTaskInfo.taskId !== undefined &&
                webSocketTaskInfo.taskId
                  ? webSocketTaskInfo.taskId
                  : 0,
              notificationId: notificationId,
              notifiSenderId: notifiSenderId,
            });
          }

          if (userNotificationType == "NEGOTIATION") {
            commonnotitrack("NEGOTIATION", {
              notiType: "NEGOTIATION",
              taskId:
                webSocketTaskInfo &&
                webSocketTaskInfo.taskId !== undefined &&
                webSocketTaskInfo.taskId
                  ? webSocketTaskInfo.taskId
                  : 0,
              taskName:
                webSocketTaskInfo &&
                webSocketTaskInfo.taskName !== undefined &&
                webSocketTaskInfo.taskName
                  ? webSocketTaskInfo.taskName
                  : "",
              notificationId: notificationId,
              notifiSenderId: notifiSenderId,
              chat:
                webSocketNegotiationChatInfo &&
                webSocketNegotiationChatInfo.chat !== undefined &&
                webSocketNegotiationChatInfo.chat
                  ? webSocketNegotiationChatInfo.chat
                  : null,
              budgetNegotiationStatus:
                webSocketNegotiationChatInfo &&
                webSocketNegotiationChatInfo.budgetNegotiationStatus !==
                  undefined &&
                webSocketNegotiationChatInfo.budgetNegotiationStatus
                  ? webSocketNegotiationChatInfo.budgetNegotiationStatus
                  : "",
              dueDateNegotiationStatus:
                webSocketNegotiationChatInfo &&
                webSocketNegotiationChatInfo.dueDateNegotiationStatus !==
                  undefined &&
                webSocketNegotiationChatInfo.dueDateNegotiationStatus
                  ? webSocketNegotiationChatInfo.dueDateNegotiationStatus
                  : "",
              conclusionMessage:
                webSocketNegotiationChatInfo &&
                webSocketNegotiationChatInfo.conclusionMessage !== undefined &&
                webSocketNegotiationChatInfo.conclusionMessage
                  ? webSocketNegotiationChatInfo.conclusionMessage
                  : "",
              assignRel: {
                taskOrSubTask: "STASK",
                taskIdOrSubTaskId:
                  webSocketTaskInfo &&
                  webSocketTaskInfo.taskId !== undefined &&
                  webSocketTaskInfo.taskId
                    ? webSocketTaskInfo.taskId
                    : 0,
                taskOrSubTaskName:
                  webSocketTaskInfo &&
                  webSocketTaskInfo.taskName !== undefined &&
                  webSocketTaskInfo.taskName
                    ? webSocketTaskInfo.taskName
                    : "",
                budgetStatus:
                  webSocketNegotiationChatInfo &&
                  webSocketNegotiationChatInfo.budgetNegotiationStatus !==
                    undefined &&
                  webSocketNegotiationChatInfo.budgetNegotiationStatus !== null
                    ? webSocketNegotiationChatInfo.budgetNegotiationStatus
                    : null,
                dueDateStatus:
                  webSocketNegotiationChatInfo &&
                  webSocketNegotiationChatInfo.dueDateNegotiationStatus !==
                    undefined &&
                  webSocketNegotiationChatInfo.dueDateNegotiationStatus !== null
                    ? webSocketNegotiationChatInfo.dueDateNegotiationStatus
                    : null,
                conflictCheckStatus:
                  webSocketNegotiationChatInfo &&
                  webSocketNegotiationChatInfo.conflictCheckStatus !==
                    undefined &&
                  webSocketNegotiationChatInfo.conflictCheckStatus
                    ? webSocketNegotiationChatInfo.conflictCheckStatus
                    : "",
                isTermsAcceptedByLP:
                  webSocketNegotiationChatInfo &&
                  webSocketNegotiationChatInfo.isTermsAcceptedByLP !==
                    undefined &&
                  webSocketNegotiationChatInfo.isTermsAcceptedByLP
                    ? webSocketNegotiationChatInfo.isTermsAcceptedByLP
                    : false,
                agreedBudget:
                  webSocketNegotiationChatInfo &&
                  webSocketNegotiationChatInfo.agreedBudget !== undefined &&
                  webSocketNegotiationChatInfo.agreedBudget !== null
                    ? webSocketNegotiationChatInfo.agreedBudget
                    : null,
                agreedBudgetCurrency:
                  webSocketNegotiationChatInfo &&
                  webSocketNegotiationChatInfo.agreedBudgetCurrency !==
                    undefined &&
                  webSocketNegotiationChatInfo.agreedBudgetCurrency !== null
                    ? webSocketNegotiationChatInfo.agreedBudgetCurrency
                    : null,
                agreedDueDate:
                  webSocketNegotiationChatInfo &&
                  webSocketNegotiationChatInfo.agreedDueDate !== undefined &&
                  webSocketNegotiationChatInfo.agreedDueDate !== null
                    ? webSocketNegotiationChatInfo.agreedDueDate
                    : null,
                lpUserId: notifiSenderId,
                lpUserName: lpUserName,
                organisationName: getOrgName(),
              },
            });
          }

          if (userNotificationType == "NEW_COMMENT") {
            commonnotitrack("NEW_COMMENT", {
              notiType: "NEW_COMMENT",
              taskId:
                webSocketTaskInfo &&
                webSocketTaskInfo.taskId !== undefined &&
                webSocketTaskInfo.taskId
                  ? webSocketTaskInfo.taskId
                  : 0,
              notificationId: notificationId,
              notifiSenderId: notifiSenderId,
              commentResp: webSocketCommentInfo,
            });
          }
        }
      }
    }
  };

  async getTaskDetailData(param, otherParam) {
    let notiType =
      otherParam.notiType !== undefined && otherParam.notiType !== null
        ? otherParam.notiType
        : "";

    let notiObj =
      otherParam.notiObj !== undefined && otherParam.notiObj !== null
        ? otherParam.notiObj
        : null;
    //console.log("**** from getTaskDetailData notiObj=>>",notiObj, " for notiType=>>>",notiType);

    let webSocketNotificationInfo =
      notiObj !== null &&
      notiObj.webSocketNotificationInfo !== undefined &&
      notiObj.webSocketNotificationInfo !== null
        ? notiObj.webSocketNotificationInfo
        : null;

    let webSocketNegotiationChatInfo =
      notiObj !== null &&
      notiObj.webSocketNegotiationChatInfo !== undefined &&
      notiObj.webSocketNegotiationChatInfo !== null
        ? notiObj.webSocketNegotiationChatInfo
        : null;

    let notificationTriggeredBy =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationTriggeredBy !== undefined &&
      webSocketNotificationInfo.notificationTriggeredBy !== null
        ? webSocketNotificationInfo.notificationTriggeredBy
        : null;

    let lpUserId =
      notificationTriggeredBy !== null &&
      notificationTriggeredBy.id !== undefined &&
      notificationTriggeredBy.id !== null
        ? notificationTriggeredBy.id
        : 0;

    let firstName =
      notificationTriggeredBy !== null &&
      notificationTriggeredBy.firstName !== undefined &&
      notificationTriggeredBy.firstName !== null
        ? notificationTriggeredBy.firstName
        : "";

    let lastName =
      notificationTriggeredBy !== null &&
      notificationTriggeredBy.lastName !== undefined &&
      notificationTriggeredBy.lastName !== null
        ? notificationTriggeredBy.lastName
        : "";

    let lpUserName = firstName + " " + lastName;

    let setShare =
      this.context !== undefined && this.context.setShare !== undefined
        ? this.context.setShare
        : null;
    let sharedData =
      this.context !== undefined && this.context.share !== undefined
        ? this.context.share
        : null;

    let respData = null;
    let lservdrf = new TaskDetailsService();
    await lservdrf
      .getTaskDetail(param)
      .then((data) => {
        respData = data.data.entity;

        let assignedTo =
          respData.assignedTo !== undefined &&
          respData.assignedTo !== null &&
          respData.assignedTo.userId !== undefined &&
          respData.assignedTo.userId !== null
            ? respData.assignedTo
            : null;

        let isTaskCompleted =
          respData.isTaskCompleted !== undefined &&
          respData.isTaskCompleted !== null
            ? respData.isTaskCompleted
            : false;

        let taskId =
          respData.id !== undefined && respData.id !== null ? respData.id : 0;
        let taskName =
          respData.taskName !== undefined && respData.taskName !== null
            ? respData.taskName
            : "";

        if (
          sharedData !== undefined &&
          sharedData !== null &&
          setShare !== undefined &&
          setShare !== null
        ) {
          if (notiType == "TASK_TERMS") {
            sharedData["actionType"] = "SPLITSCREEN";
            sharedData["leftTabToActive"] = "PROFESSIONALSINTERESTED";
            sharedData["purpose"] = "VIEW_RESPONSE_LIST";
            sharedData["taskId"] = taskId;

            let otherData =
              sharedData.other !== undefined && sharedData.other !== null
                ? sharedData.other
                : null;
            let otherParam = {};
            if (otherData) {
              otherParam = { shareData: otherData };

              otherData["taskResp"] = respData;
              otherData["subTasksCount"] = respData.subTasks
                ? respData.subTasks.length
                : 0;
              sharedData.other = otherData;
              ////console.log("111 sharedData===>>",sharedData);
            } else {
              otherParam = { shareData: otherData };

              otherData = {
                taskResp: respData,
                subTasksCount: respData.subTasks ? respData.subTasks.length : 0,
              };
              sharedData.other = otherData;
              ////console.log("22222 sharedData===>>",sharedData);
            }

            this.props.history.push("/tasks");
            setTimeout(() => {
              setShare(sharedData);
            }, 2500);
          } else if (notiType == "TASK_REQUEST_ACCEPTED") {
            sharedData["actionType"] = "SPLITSCREEN";
            sharedData["leftTabToActive"] = "PROFESSIONALSINTERESTED";
            sharedData["purpose"] = "VIEW_RESPONSE_LIST";
            sharedData["taskId"] = taskId;

            let otherData =
              sharedData.other !== undefined && sharedData.other !== null
                ? sharedData.other
                : null;
            let otherParam = {};
            if (otherData) {
              otherParam = { shareData: otherData };

              otherData["taskResp"] = respData;
              otherData["subTasksCount"] = respData.subTasks
                ? respData.subTasks.length
                : 0;
              sharedData.other = otherData;
              ////console.log("111 sharedData===>>",sharedData);
            } else {
              otherParam = { shareData: otherData };

              otherData = {
                taskResp: respData,
                subTasksCount: respData.subTasks ? respData.subTasks.length : 0,
              };
              sharedData.other = otherData;
              ////console.log("22222 sharedData===>>",sharedData);
            }

            this.props.history.push("/tasks");
            setTimeout(() => {
              setShare(sharedData);
            }, 2500);
          } else if (notiType == "TASK_DECLINED") {
            sharedData["actionType"] = "SPLITSCREEN";
            sharedData["leftTabToActive"] = "PROFESSIONALSINTERESTED";
            sharedData["purpose"] = "VIEW_RESPONSE_LIST";
            sharedData["taskId"] = taskId;

            let otherData =
              sharedData.other !== undefined && sharedData.other !== null
                ? sharedData.other
                : null;
            let otherParam = {};
            if (otherData) {
              otherParam = { shareData: otherData };

              otherData["taskResp"] = respData;
              otherData["subTasksCount"] = respData.subTasks
                ? respData.subTasks.length
                : 0;
              sharedData.other = otherData;
              ////console.log("111 sharedData===>>",sharedData);
            } else {
              otherParam = { shareData: otherData };

              otherData = {
                taskResp: respData,
                subTasksCount: respData.subTasks ? respData.subTasks.length : 0,
              };
              sharedData.other = otherData;
              ////console.log("22222 sharedData===>>",sharedData);
            }

            this.props.history.push("/tasks");
            setTimeout(() => {
              setShare(sharedData);
            }, 2500);
          } else if (notiType == "TASK_ACCEPTED") {
            sharedData["actionType"] = "SPLITSCREEN";
            sharedData["leftTabToActive"] = "MONITORTASK";
            sharedData["purpose"] = "VIEW_RESPONSE_LIST";
            sharedData["taskId"] = taskId;

            let otherData =
              sharedData.other !== undefined && sharedData.other !== null
                ? sharedData.other
                : null;

            if (otherData) {
              otherData["taskResp"] = respData;
              otherData["subTasksCount"] = respData.subTasks
                ? respData.subTasks.length
                : 0;
              otherData["triggeredFrom"] = "PROFESSIONAL_INTERESTED_LIST";
              sharedData.other = otherData;
            } else {
              otherData = {
                taskResp: respData,
                subTasksCount: respData.subTasks ? respData.subTasks.length : 0,
                triggeredFrom: "PROFESSIONAL_INTERESTED_LIST",
              };
              sharedData.other = otherData;
            }

            this.props.history.push("/tasks");
            setTimeout(() => {
              setShare(sharedData);
            }, 2500);
          } else if (notiType == "NEGOTIATION") {
            if (assignedTo === null && isTaskCompleted !== true) {
              let isTermsAcceptedByLPData =
                webSocketNegotiationChatInfo &&
                webSocketNegotiationChatInfo.isTermsAcceptedByLP !== undefined
                  ? webSocketNegotiationChatInfo.isTermsAcceptedByLP
                  : false;

              let budgetNegotiationStatus =
                webSocketNegotiationChatInfo &&
                webSocketNegotiationChatInfo.budgetNegotiationStatus !==
                  undefined
                  ? webSocketNegotiationChatInfo.budgetNegotiationStatus
                  : "";

              let dueDateNegotiationStatus =
                webSocketNegotiationChatInfo &&
                webSocketNegotiationChatInfo.dueDateNegotiationStatus !==
                  undefined
                  ? webSocketNegotiationChatInfo.dueDateNegotiationStatus
                  : "";

              sharedData["actionType"] = "SPLITSCREEN";
              sharedData["leftTabToActive"] = "PROFESSIONALTASKNEGOTIATIONCHAT";
              sharedData["purpose"] = "VIEW_NEGOCHAT_LIST";
              sharedData["taskId"] = taskId;

              let otherData =
                sharedData.other !== undefined && sharedData.other !== null
                  ? sharedData.other
                  : null;

              if (otherData) {
                otherData["taskResp"] = respData;

                otherData["subTasksCount"] = respData.subTasks
                  ? respData.subTasks.length
                  : 0;
                otherData["negoChatBellNoti"] = {};
                otherData["negoChatBellNoti"]["taskOrSubTask"] = "STASK";
                otherData["negoChatBellNoti"]["taskIdOrSubTaskId"] = taskId;
                otherData["negoChatBellNoti"]["taskOrSubTaskName"] = taskName;
                otherData["negoChatBellNoti"]["lpUserInterestedData"] = {};
                otherData["negoChatBellNoti"]["lpUserId"] = lpUserId;
                otherData["negoChatBellNoti"]["lpUserName"] = lpUserName;
                otherData["negoChatBellNoti"]["ccUserId"] = getCcUserId();
                otherData["negoChatBellNoti"]["isTermsAcceptedByLP"] =
                  isTermsAcceptedByLPData;
                otherData["negoChatBellNoti"]["assignedTo"] = assignedTo;
                otherData["negoChatBellNoti"]["budgetNegotiationStatus"] =
                  budgetNegotiationStatus;
                otherData["negoChatBellNoti"]["dueDateNegotiationStatus"] =
                  dueDateNegotiationStatus;
                otherData["negoChatBellNoti"]["assignRel"] = {
                  taskOrSubTask: "STASK",
                  taskIdOrSubTaskId: taskId,
                  taskOrSubTaskName: taskName,
                  budgetStatus:
                    webSocketNegotiationChatInfo &&
                    webSocketNegotiationChatInfo.budgetNegotiationStatus !==
                      undefined &&
                    webSocketNegotiationChatInfo.budgetNegotiationStatus !==
                      null
                      ? webSocketNegotiationChatInfo.budgetNegotiationStatus
                      : null,
                  dueDateStatus:
                    webSocketNegotiationChatInfo &&
                    webSocketNegotiationChatInfo.dueDateNegotiationStatus !==
                      undefined &&
                    webSocketNegotiationChatInfo.dueDateNegotiationStatus !==
                      null
                      ? webSocketNegotiationChatInfo.dueDateNegotiationStatus
                      : null,
                  conflictCheckStatus:
                    webSocketNegotiationChatInfo &&
                    webSocketNegotiationChatInfo.conflictCheckStatus !==
                      undefined &&
                    webSocketNegotiationChatInfo.conflictCheckStatus
                      ? webSocketNegotiationChatInfo.conflictCheckStatus
                      : "",
                  isTermsAcceptedByLP:
                    webSocketNegotiationChatInfo &&
                    webSocketNegotiationChatInfo.isTermsAcceptedByLP !==
                      undefined &&
                    webSocketNegotiationChatInfo.isTermsAcceptedByLP
                      ? webSocketNegotiationChatInfo.isTermsAcceptedByLP
                      : false,
                  termsAcceptedOn:
                    webSocketNegotiationChatInfo &&
                    webSocketNegotiationChatInfo.termsAcceptedOn !==
                      undefined &&
                    webSocketNegotiationChatInfo.termsAcceptedOn
                      ? webSocketNegotiationChatInfo.termsAcceptedOn
                      : null,
                  agreedBudget:
                    webSocketNegotiationChatInfo &&
                    webSocketNegotiationChatInfo.agreedBudget !== undefined &&
                    webSocketNegotiationChatInfo.agreedBudget !== null
                      ? webSocketNegotiationChatInfo.agreedBudget
                      : null,
                  agreedBudgetCurrency:
                    webSocketNegotiationChatInfo &&
                    webSocketNegotiationChatInfo.agreedBudgetCurrency !==
                      undefined &&
                    webSocketNegotiationChatInfo.agreedBudgetCurrency !== null
                      ? webSocketNegotiationChatInfo.agreedBudgetCurrency
                      : null,
                  agreedDueDate:
                    webSocketNegotiationChatInfo &&
                    webSocketNegotiationChatInfo.agreedDueDate !== undefined &&
                    webSocketNegotiationChatInfo.agreedDueDate !== null
                      ? webSocketNegotiationChatInfo.agreedDueDate
                      : null,
                  lpUserId: lpUserId,
                  lpUserName: lpUserName,
                  organisationName: getOrgName(),
                };
              } else {
                otherData = {
                  taskResp: respData,
                  subTasksCount: respData.subTasks
                    ? respData.subTasks.length
                    : 0,
                  subTasksCount: respData.subTasks
                    ? respData.subTasks.length
                    : 0,
                  negoChatBellNoti: {
                    taskOrSubTask: "STASK",
                    taskIdOrSubTaskId: taskId,
                    taskOrSubTaskName: taskName,
                    taskOrSubTaskName: taskName,
                    lpUserId: lpUserId,
                    lpUserName: lpUserName,
                    ccUserId: getCcUserId(),
                    isTermsAcceptedByLP: isTermsAcceptedByLPData,
                    termsAcceptedOn:
                      webSocketNegotiationChatInfo &&
                      webSocketNegotiationChatInfo.termsAcceptedOn !==
                        undefined &&
                      webSocketNegotiationChatInfo.termsAcceptedOn
                        ? webSocketNegotiationChatInfo.termsAcceptedOn
                        : null,
                    assignedTo: assignedTo,
                    budgetNegotiationStatus: budgetNegotiationStatus,
                    dueDateNegotiationStatus: dueDateNegotiationStatus,
                    assignRel: {
                      taskOrSubTask: "STASK",
                      taskIdOrSubTaskId: taskId,
                      taskOrSubTaskName: taskName,
                      budgetStatus:
                        webSocketNegotiationChatInfo &&
                        webSocketNegotiationChatInfo.budgetNegotiationStatus !==
                          undefined &&
                        webSocketNegotiationChatInfo.budgetNegotiationStatus
                          ? webSocketNegotiationChatInfo.budgetNegotiationStatus
                          : null,
                      dueDateStatus:
                        webSocketNegotiationChatInfo &&
                        webSocketNegotiationChatInfo.dueDateNegotiationStatus !==
                          undefined &&
                        webSocketNegotiationChatInfo.dueDateNegotiationStatus
                          ? webSocketNegotiationChatInfo.dueDateNegotiationStatus
                          : null,
                      conflictCheckStatus:
                        webSocketNegotiationChatInfo &&
                        webSocketNegotiationChatInfo.conflictCheckStatus !==
                          undefined &&
                        webSocketNegotiationChatInfo.conflictCheckStatus
                          ? webSocketNegotiationChatInfo.conflictCheckStatus
                          : "",
                      isTermsAcceptedByLP:
                        webSocketNegotiationChatInfo &&
                        webSocketNegotiationChatInfo.isTermsAcceptedByLP !==
                          undefined &&
                        webSocketNegotiationChatInfo.isTermsAcceptedByLP
                          ? webSocketNegotiationChatInfo.isTermsAcceptedByLP
                          : false,
                      agreedBudget:
                        webSocketNegotiationChatInfo &&
                        webSocketNegotiationChatInfo.agreedBudget !==
                          undefined &&
                        webSocketNegotiationChatInfo.agreedBudget
                          ? webSocketNegotiationChatInfo.agreedBudget
                          : null,
                      agreedBudgetCurrency:
                        webSocketNegotiationChatInfo &&
                        webSocketNegotiationChatInfo.agreedBudgetCurrency !==
                          undefined &&
                        webSocketNegotiationChatInfo.agreedBudgetCurrency
                          ? webSocketNegotiationChatInfo.agreedBudgetCurrency
                          : null,
                      agreedDueDate:
                        webSocketNegotiationChatInfo &&
                        webSocketNegotiationChatInfo.agreedDueDate !==
                          undefined &&
                        webSocketNegotiationChatInfo.agreedDueDate
                          ? webSocketNegotiationChatInfo.agreedDueDate
                          : null,
                      lpUserId: lpUserId,
                      lpUserName: lpUserName,
                      organisationName: getOrgName(),
                    },
                  },
                };
              }

              sharedData["other"] = otherData;

              this.props.history.push("/tasks");
              setTimeout(() => {
                setShare(sharedData);
              }, 2500);
            }
          } else if (notiType == "NEW_COMMENT") {
            sharedData["actionType"] = "SPLITSCREEN";
            sharedData["leftTabToActive"] = "MONITORTASK";
            sharedData["purpose"] = "VIEW_RESPONSE_LIST";
            sharedData["taskId"] = taskId;

            let otherData =
              sharedData.other !== undefined && sharedData.other !== null
                ? sharedData.other
                : null;

            if (otherData) {
              otherData["taskResp"] = respData;
              otherData["subTasksCount"] = respData.subTasks
                ? respData.subTasks.length
                : 0;
              otherData["triggeredFrom"] = "PROFESSIONAL_INTERESTED_LIST";
              sharedData.other = otherData;
            } else {
              otherData = {
                taskResp: respData,
                subTasksCount: respData.subTasks ? respData.subTasks.length : 0,
                triggeredFrom: "PROFESSIONAL_INTERESTED_LIST",
              };
              sharedData.other = otherData;
            }

            this.props.history.push("/tasks");
            setTimeout(() => {
              setShare(sharedData);
            }, 2500);
          } else if (notiType == "TIMESHEET") {
            sharedData["actionType"] = "SPLITSCREEN";
            sharedData["leftTabToActive"] = "TIMELOGS";
            sharedData["purpose"] = "VIEW_TIMELOG_LIST";
            sharedData["taskId"] = taskId;

            let otherData =
              sharedData.other !== undefined && sharedData.other !== null
                ? sharedData.other
                : null;

            if (otherData) {
              otherData["taskResp"] = respData;
              otherData["subTasksCount"] = respData.subTasks
                ? respData.subTasks.length
                : 0;
              otherData["triggeredFrom"] = "BELL_NOTIFICATION_MENU";
              sharedData.other = otherData;
            } else {
              otherData = {
                taskResp: respData,
                subTasksCount: respData.subTasks ? respData.subTasks.length : 0,
                triggeredFrom: "BELL_NOTIFICATION_MENU",
              };
              sharedData.other = otherData;
            }

            this.props.history.push("/tasks");
            setTimeout(() => {
              setShare(sharedData);
            }, 2500);
          } else if (notiType == "INVOICE_DUE") {
            sharedData["actionType"] = "SPLITSCREEN";
            sharedData["leftTabToActive"] = "INVOICE_MASTER";
            sharedData["purpose"] = "DETERMINE_WHICH_VIEW";
            sharedData["taskId"] = taskId;

            let otherData =
              sharedData.other !== undefined && sharedData.other !== null
                ? sharedData.other
                : null;

            if (otherData) {
              otherData["taskResp"] = respData;
              otherData["subTasksCount"] = respData.subTasks
                ? respData.subTasks.length
                : 0;
              otherData["triggeredFrom"] = "BELL_NOTIFICATION_MENU";
              sharedData.other = otherData;
            } else {
              otherData = {
                taskResp: respData,
                subTasksCount: respData.subTasks ? respData.subTasks.length : 0,
                triggeredFrom: "BELL_NOTIFICATION_MENU",
              };
              sharedData.other = otherData;
            }

            //console.log("from invoice due logic bell ---- sharedData=>",sharedData);

            this.props.history.push("/tasks");
            setTimeout(() => {
              setShare(sharedData);
            }, 2500);
          } else if (notiType == "INVOICE_PAID") {
            sharedData["actionType"] = "SPLITSCREEN";
            sharedData["leftTabToActive"] = "INVOICE_MASTER";
            sharedData["purpose"] = "DETERMINE_WHICH_VIEW";
            sharedData["taskId"] = taskId;

            let otherData =
              sharedData.other !== undefined && sharedData.other !== null
                ? sharedData.other
                : null;

            if (otherData) {
              otherData["taskResp"] = respData;
              otherData["subTasksCount"] = respData.subTasks
                ? respData.subTasks.length
                : 0;
              otherData["triggeredFrom"] = "BELL_NOTIFICATION_MENU";
              sharedData.other = otherData;
            } else {
              otherData = {
                taskResp: respData,
                subTasksCount: respData.subTasks ? respData.subTasks.length : 0,
                triggeredFrom: "BELL_NOTIFICATION_MENU",
              };
              sharedData.other = otherData;
            }

            //console.log("from invoice due logic bell ---- sharedData=>",sharedData);

            this.props.history.push("/tasks");
            setTimeout(() => {
              setShare(sharedData);
            }, 2500);
          } else if (notiType == "TASK_EDIT_APPROVED") {
            sharedData["actionType"] = "SPLITSCREEN";
            sharedData["leftTabToActive"] = "TASKDETAILS";
            sharedData["purpose"] = "VIEW";
            sharedData["taskId"] = taskId;

            let otherData =
              sharedData.other !== undefined && sharedData.other !== null
                ? sharedData.other
                : null;

            if (otherData) {
              otherData["taskResp"] = respData;
              otherData["subTasksCount"] = respData.subTasks
                ? respData.subTasks.length
                : 0;
              otherData["triggeredFrom"] = "BELL_NOTIFICATION_MENU";
              sharedData.other = otherData;
            } else {
              otherData = {
                taskResp: respData,
                subTasksCount: respData.subTasks ? respData.subTasks.length : 0,
                triggeredFrom: "BELL_NOTIFICATION_MENU",
              };
              sharedData.other = otherData;
            }

            //console.log("from invoice due logic bell ---- sharedData=>",sharedData);

            this.props.history.push("/tasks");
            setTimeout(() => {
              setShare(sharedData);
            }, 2500);
          } else if (notiType == "TASK_DOCUMENT") {
            sharedData["actionType"] = "SPLITSCREEN";
            sharedData["leftTabToActive"] = "DOCUMENTS";
            sharedData["purpose"] = "VIEW_DOC_LIST";
            sharedData["taskId"] = taskId;

            let otherData =
              sharedData.other !== undefined && sharedData.other !== null
                ? sharedData.other
                : null;

            if (otherData) {
              otherData["taskResp"] = respData;
              otherData["subTasksCount"] = respData.subTasks
                ? respData.subTasks.length
                : 0;
              otherData["triggeredFrom"] = "BELL_NOTIFICATION_MENU";
              sharedData.other = otherData;
            } else {
              otherData = {
                taskResp: respData,
                subTasksCount: respData.subTasks ? respData.subTasks.length : 0,
                triggeredFrom: "BELL_NOTIFICATION_MENU",
              };
              sharedData.other = otherData;
            }

            //console.log("from invoice due logic bell ---- sharedData=>",sharedData);

            this.props.history.push("/tasks");
            setTimeout(() => {
              setShare(sharedData);
            }, 2500);
          }
        }
      })
      .catch((err) => {
        let errStatusCodeAr = [400, 500]; // allowed error status code
        if (err.response && err.response.data) {
          let erRespData = err.response.data;
          if (errStatusCodeAr.indexOf(erRespData.status) !== -1) {
            let errMsg = erRespData.message
              ? erRespData.message
              : "Some technical problem occurred.";
            console.log(" errMsg on task fetch info==>", errMsg);
            // close split screen
            //this.closeSplit();
          }
        }
      })
      .finally(() => {});

    return respData;
  }

  /**
   * TaskListOfSingleTask
   * Function name : loadLoader
   * Author        :
   * Created Date  : 27-3-2020
   * Purpose       : Load loader
   * Params        :
   **/

  loadLoader() {
    return (
      <CustLoader className={"smallLoaderGreen"} loaderText={"Loading.."} />
    );
  }

  /**
   * TaskListOfSingleTask
   * Function name : hideLoader
   * Author        :
   * Created Date  : 27-3-2020
   * Purpose       : Hide loader
   * Params        :
   **/

  hideLoader() {
    this.isComponentLoaded && this.setState({ loaderFlag: false });
  }

  /**
   * TaskListOfSingleTask
   * Function name : showLoader
   * Author        :
   * Created Date  : 27-3-2020
   * Purpose       : showLoader
   * Params        :
   **/

  showLoader() {
    this.isComponentLoaded && this.setState({ loaderFlag: true });
  }

  callServiceToFetchNotiList = (paramObj, trackcalledfrom) => {
    let paramObjData = {};
    paramObjData.pageNum = paramObj.pageNum;

    let pageNumArTrack = [...this.state.pageNumArTrack];

    // restrict repeated pagenum related api call
    if (pageNumArTrack.indexOf(paramObjData.pageNum) > -1) {
      //console.log("******Sorry cant hit api with page number => "+paramObjData.pageNum+" **********");
      return false;
    } else {
      pageNumArTrack.push(paramObjData.pageNum);
      this.isComponentLoaded &&
        this.setState({ pageNumArTrack: pageNumArTrack });
    }

    let stcservice = new NotificationListFetchService();
    stcservice
      .getNotificationList({ data: paramObjData })
      .then((data) => {
        if (data.status == 200) {
          let respData = {};

          if (data.data.entity !== undefined) {
            respData = data.data.entity;
            if (respData.notifications !== undefined) {
              if (respData.notifications.length > 0) {
                // set list data to state
                let prevRecords = [...this.state.listData];
                let currRecords = respData.notifications;
                let concatenateRecords = prevRecords.concat(currRecords);
                let totalUnreadNotificationCount =
                  respData.totalUnreadNotificationCount !== undefined &&
                  respData.totalUnreadNotificationCount !== null
                    ? respData.totalUnreadNotificationCount
                    : 0;
                this.isComponentLoaded &&
                  this.setState(
                    {
                      listData: concatenateRecords,
                      totalNumberOfPages: respData.totalNumberOfPages,
                      totalUnreadNotificationCount:
                        totalUnreadNotificationCount,
                    },
                    () => {
                      //console.log(" this.state =>>>",this.state);
                    }
                  );
              } else {
                if (this.state.listData.length == 0) {
                  this.isComponentLoaded &&
                    this.setState({
                      listData: [],
                      pageNum: 1,
                      totalNumberOfPages: 1,
                      loaderFlag: true,
                      pageNumArTrack: [],
                      totalUnreadNotificationCount: 0,
                    });
                }
              }
            } else {
            }
          }
        }
      })
      .catch((err) => {
        let errStatusCodeAr = [400, 500]; // allowed error status code
        if (err.response && err.response.data) {
          let erRespData = err.response.data;
          if (errStatusCodeAr.indexOf(erRespData.status) !== -1) {
            let errMsg = erRespData.message
              ? erRespData.message
              : "Some technical problem occurred.";
            console.log(errMsg);
          }
        }
      })
      .finally(() => {
        this.hideLoader();
        this.isComponentLoaded && this.setState({ bellButtonFlag: false });
      });
  };

  trackScroll = () => {
    let scobj = this.scrollerRef.current;
    let scrollTop = scobj.getValues().scrollTop;
    let scrollHeight = scobj.getScrollHeight();
    let clientHeight = scobj.getClientHeight();

    let prevPageNum = this.state.pageNum;
    let totalNumberOfPages = this.state.totalNumberOfPages;

    //console.log(" scrollHeight=>",scrollHeight," clientHeight==>",clientHeight, " scrollTop=>>",scrollTop);

    if (
      scrollHeight - clientHeight == scrollTop &&
      prevPageNum < totalNumberOfPages
    ) {
      this.showLoader();

      setTimeout(() => {
        // call list api
        let pageNumData = prevPageNum + 1;
        let paramObj = { pageNum: pageNumData };

        this.isComponentLoaded &&
          this.setState({ pageNum: pageNumData }, () => {
            this.callServiceToFetchNotiList(
              paramObj,
              "***called from scroll bar***"
            );
          });
      }, 900);
    }
  };

  /**
   * BellNotificationList
   * Function name : callLogOut
   * Author        :
   * Created Date  : 3-7-2020
   * Purpose       : simple log out functionality
   * Params        : paramObj
   **/

  toggleNotiList = (paramObj) => {
    let notiListOpenFlag = this.state.notiListOpenFlag ? false : true;
    //console.log(" prev=>notiListOpenFlag=>",this.state.notiListOpenFlag);
    this.isComponentLoaded &&
      this.setState({ notiListOpenFlag: notiListOpenFlag }, () => {
        //console.log(" now=>notiListOpenFlag=>",notiListOpenFlag);
        if (notiListOpenFlag) {
          this.menuDropDownDivRef.current.focus();
          this.reloadBellData();
          setTimeout(() => {
            //this.showLoader();
          }, 500);

          // setTimeout(()=>{
          //   this.isComponentLoaded && this.setState({"bellButtonFlag": false});
          // },500);
        }
      });
  };

  closeNotiListOnBlur = (paramObj) => {
    // its motive is to close the list when mouse is clicked outsidedropdown list
    let notiListOpenFlag = false;
    if (this.state.notiListOpenFlag !== false) {
      this.closeTheListDropDown({});
    }
  };

  closeTheListDropDown = (paramObj) => {
    this.isComponentLoaded &&
      this.setState({ notiListOpenFlag: false }, () => {
        if (
          paramObj.calledFrom !== undefined &&
          paramObj.calledFrom == "REAL_TIME_NOTI"
        ) {
          this.reloadBellData();
        }
      });
  };

  getListStructure = () => {
    let listDataAr = [];

    Object.keys(this.state.listData).map((e, i) => {
      let listObj = this.formatBasedOnNotificationType(this.state.listData[i]);
      listDataAr.push(listObj);
    });
    return <>{listDataAr}</>;
  };

  formatBasedOnNotificationType = (paramObj) => {
    let webSocketNotificationInfo =
      paramObj.webSocketNotificationInfo !== undefined &&
      paramObj.webSocketNotificationInfo !== null
        ? paramObj.webSocketNotificationInfo
        : null;

    let userNotificationType =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.userNotificationType !== undefined &&
      webSocketNotificationInfo.userNotificationType !== null
        ? webSocketNotificationInfo.userNotificationType
        : "";

    let listObj = null;
    let otherParam = { triggeredFor: "", notiType: "" };
    otherParam.triggeredFor = "FIRSTTIME";

    switch (userNotificationType) {
      case "INVOICE_DUE":
        otherParam.notiType = "INVOICE_DUE";
        listObj = this.formatInvoiceDueNoti(paramObj, otherParam);
        if (listObj) {
          //console.log(" INVOICE_DUE==>",listObj);
          return <>{listObj}</>;
        } else {
          return <></>;
        }
        break;
      case "TASK_TERMS":
        otherParam.notiType = "TASK_TERMS";
        listObj = this.formatTaskTermsNoti(paramObj, otherParam);
        if (listObj) {
          //console.log(" TASK_TERMS==>",listObj);
          return <>{listObj}</>;
        } else {
          return <></>;
        }
        break;
      case "NEGOTIATION":
        otherParam.notiType = "NEGOTIATION";
        listObj = this.formatNegotiationNoti(paramObj, otherParam);
        if (listObj) {
          //console.log(" NEGOTIATION==>",listObj);
          return <>{listObj}</>;
        } else {
          return <></>;
        }
        break;
      case "TASK_REQUEST_ACCEPTED":
        otherParam.notiType = "TASK_REQUEST_ACCEPTED";
        listObj = this.formatTaskReqAcceptedNoti(paramObj, otherParam);
        if (listObj) {
          //console.log(" TASK_REQUEST_ACCEPTED==>",listObj);
          return <>{listObj}</>;
        } else {
          return <></>;
        }
        break;

      case "TASK_ACCEPTED":
        otherParam.notiType = "TASK_ACCEPTED";
        listObj = this.formatTaskOfferAcceptedNoti(paramObj, otherParam);
        if (listObj) {
          //console.log(" TASK_REQUEST_ACCEPTED==>",listObj);
          return <>{listObj}</>;
        } else {
          return <></>;
        }
        break;

      case "TASK_DECLINED":
        otherParam.notiType = "TASK_DECLINED";
        listObj = this.formatTaskOfferDeclineNoti(paramObj, otherParam);
        if (listObj) {
          //console.log(" TASK_REQUEST_ACCEPTED==>",listObj);
          return <>{listObj}</>;
        } else {
          return <></>;
        }
        break;

      case "SUBSCRIPTION_SUCCESS":
        otherParam.notiType = "SUBSCRIPTION_SUCCESS";
        listObj = this.formatSubsriptionSuccessNoti(paramObj, otherParam);
        if (listObj) {
          //console.log(" SUBSCRIPTION_SUCCESS==>",listObj);
          return <>{listObj}</>;
        } else {
          return <></>;
        }
        break;
      case "SUBSCRIPTION_FAILURE":
        otherParam.notiType = "SUBSCRIPTION_FAILURE";
        listObj = this.formatSubsriptionFailureNoti(paramObj, otherParam);
        if (listObj) {
          //console.log(" SUBSCRIPTION_FAILURE==>",listObj);
          return <>{listObj}</>;
        } else {
          return <></>;
        }
        break;
      case "TIMESHEET":
        otherParam.notiType = "TIMESHEET";
        listObj = this.formatTimesheetNoti(paramObj, otherParam);
        if (listObj) {
          //console.log(" TIMESHEET==>",listObj);
          return <>{listObj}</>;
        } else {
          return <></>;
        }
        break;

      case "KYC":
        otherParam.notiType = "KYC";
        listObj = this.formatKycNoti(paramObj, otherParam);
        if (listObj) {
          //console.log(" KYC==>",listObj);
          return <>{listObj}</>;
        } else {
          return <></>;
        }
        break;

      case "PROFILE_UPDATE":
        otherParam.notiType = "PROFILE_UPDATE";
        listObj = this.formatProfileUpdateNoti(paramObj, otherParam);
        listObj = null;
        if (listObj) {
          //console.log(" PROFILE_UPDATE==>",listObj);
          return <>{listObj}</>;
        } else {
          return <></>;
        }
        break;

      case "NEW_COMMENT":
        otherParam.notiType = "NEW_COMMENT";
        listObj = this.formatNewCommentNoti(paramObj, otherParam);
        if (listObj) {
          //console.log(" NEW_COMMENT==>",listObj);
          return <>{listObj}</>;
        } else {
          return <></>;
        }
        break;

      case "TASK_DOCUMENT":
        otherParam.notiType = "TASK_DOCUMENT";
        listObj = this.formatTaskDocumentNoti(paramObj, otherParam);
        if (listObj) {
          //console.log(" TASK_DOCUMENT==>",listObj);
          return <>{listObj}</>;
        } else {
          return <></>;
        }
        break;

      case "VIRTUAL_ACCOUNT_LOAD":
        otherParam.notiType = "VIRTUAL_ACCOUNT_LOAD";
        listObj = this.formatVirtualAccLoadNoti(paramObj, otherParam);
        if (listObj) {
          //console.log(" TASK_DOCUMENT==>",listObj);
          return <>{listObj}</>;
        } else {
          return <></>;
        }
        break;

      case "SUBSCRIPTION_WAIVER":
        otherParam.notiType = "SUBSCRIPTION_WAIVER";
        listObj = this.formatSubscriptionWaiverNoti(paramObj, otherParam);
        if (listObj) {
          //console.log(" TASK_DOCUMENT==>",listObj);
          return <>{listObj}</>;
        } else {
          return <></>;
        }
        break;

      case "INVOICE_PAID":
        otherParam.notiType = "INVOICE_PAID";
        listObj = this.formatInvoicePaidNoti(paramObj, otherParam);
        if (listObj) {
          //console.log(" INVOICE_PAID==>",listObj);
          return <>{listObj}</>;
        } else {
          return <></>;
        }
        break;

      case "TASK_EDIT_APPROVED":
        otherParam.notiType = "TASK_EDIT_APPROVED";
        listObj = this.formatTaskEditApprovedNoti(paramObj, otherParam);
        if (listObj) {
          //console.log(" formatTaskEditApprovedNoti==>",listObj);
          return <>{listObj}</>;
        } else {
          return <></>;
        }
        break;

      default:
        return <></>;
    }
  };
  //1
  formatInvoiceDueNoti = (paramObj, otherParam) => {
    let webSocketNotificationInfo =
      paramObj !== null &&
      paramObj.webSocketNotificationInfo !== undefined &&
      paramObj.webSocketNotificationInfo !== null
        ? paramObj.webSocketNotificationInfo
        : null;

    let webSocketTaskInfo =
      paramObj !== null &&
      paramObj.webSocketTaskInfo !== undefined &&
      paramObj.webSocketTaskInfo !== null
        ? paramObj.webSocketTaskInfo
        : null;

    let websocketInvoiceInfo =
      paramObj !== null &&
      paramObj.websocketInvoiceInfo !== undefined &&
      paramObj.websocketInvoiceInfo !== null
        ? paramObj.websocketInvoiceInfo
        : null;

    let taskName =
      webSocketTaskInfo !== null &&
      webSocketTaskInfo.taskName !== undefined &&
      webSocketTaskInfo.taskName !== null
        ? webSocketTaskInfo.taskName
        : "";

    let notificationTime =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationTime !== undefined &&
      webSocketNotificationInfo.notificationTime !== null
        ? webSocketNotificationInfo.notificationTime
        : null;

    let notificationId =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationId !== undefined &&
      webSocketNotificationInfo.notificationId !== null
        ? webSocketNotificationInfo.notificationId
        : 0;

    let isRead =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.isRead !== undefined &&
      webSocketNotificationInfo.isRead !== null
        ? webSocketNotificationInfo.isRead
        : false;

    let invoiceDueDate =
      websocketInvoiceInfo !== null &&
      websocketInvoiceInfo.invoiceDueDate !== undefined &&
      websocketInvoiceInfo.invoiceDueDate !== null
        ? websocketInvoiceInfo.invoiceDueDate
        : null;

    let invoiceGenerator =
      websocketInvoiceInfo !== null &&
      websocketInvoiceInfo.invoiceGenerator !== undefined &&
      websocketInvoiceInfo.invoiceGenerator !== null
        ? websocketInvoiceInfo.invoiceGenerator
        : null;

    let firstName =
      invoiceGenerator.firstName !== undefined &&
      invoiceGenerator.firstName !== null
        ? invoiceGenerator.firstName
        : "";

    let lastName =
      invoiceGenerator.lastName !== undefined &&
      invoiceGenerator.lastName !== null
        ? invoiceGenerator.lastName
        : "";

    let fullName = firstName + " " + lastName;

    let msgClickParam = { notiType: otherParam.notiType, notiObj: paramObj };

    let listObj = (
      <li key={notificationId}>
        <div
          className={`notifi_li_box ${
            isRead == true ? "notifi_read" : "notifi_unread"
          }`}
        >
          <div className="notifi_close">
            <button
              onClick={(evn) => {
                evn.preventDefault();
                this.clickedToRemove(msgClickParam);
              }}
              className="btn_appearance_none notiDrop_footerBtn"
            >
              <img src={getBaseUrl() + "assets/images/balck_cross.png"} />
            </button>
          </div>
          <div className="notifi_cont_box">
            <div className="notifi_left_icon">
              <img src={getBaseUrl() + "assets/images/noti_info.png"} />
            </div>
            <div className="notifi_main_text">
              {/* <p className="noti_title_text">
              <a href="javascript:void(0);">
                <b>Icon for -- info.svg</b> -- noti_info.png
              </a>
            </p> */}
              <p className="noti_task_text">
                <a
                  href=""
                  onClick={(evn) => {
                    evn.preventDefault();
                    this.messageClicked(msgClickParam);
                  }}
                >
                  {fullName} has sent a new invoice payable by
                  {invoiceDueDate
                    ? CustDate.custFormatDate(new Date(invoiceDueDate), 6)
                    : "Due on receipt"}
                  for Task: "<b>{taskName}</b>"
                </a>
              </p>
            </div>
          </div>
          <div className="notifi_bottom_text">
            {/* 24 Sep 2020 9:45am IST */}
            {CustDate.custFormatDate(new Date(notificationTime), 6)}
          </div>
          <div className="clearboth" />
        </div>
      </li>
    );

    return <>{listObj}</>;
  };
  //2
  formatTaskTermsNoti = (paramObj, otherParam) => {
    let webSocketTaskInfo =
      paramObj !== null &&
      paramObj.webSocketTaskInfo !== undefined &&
      paramObj.webSocketTaskInfo !== null
        ? paramObj.webSocketTaskInfo
        : null;

    let webSocketNotificationInfo =
      paramObj !== null &&
      paramObj.webSocketNotificationInfo !== undefined &&
      paramObj.webSocketNotificationInfo !== null
        ? paramObj.webSocketNotificationInfo
        : null;

    let taskName =
      webSocketTaskInfo !== null &&
      webSocketTaskInfo.taskName !== undefined &&
      webSocketTaskInfo.taskName !== null
        ? webSocketTaskInfo.taskName
        : "";

    let notificationTime =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationTime !== undefined &&
      webSocketNotificationInfo.notificationTime !== null
        ? webSocketNotificationInfo.notificationTime
        : null;

    let notificationId =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationId !== undefined &&
      webSocketNotificationInfo.notificationId !== null
        ? webSocketNotificationInfo.notificationId
        : 0;

    let isRead =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.isRead !== undefined &&
      webSocketNotificationInfo.isRead !== null
        ? webSocketNotificationInfo.isRead
        : false;

    let notificationTriggeredBy =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationTriggeredBy !== undefined &&
      webSocketNotificationInfo.notificationTriggeredBy !== null
        ? webSocketNotificationInfo.notificationTriggeredBy
        : null;

    let firstName =
      notificationTriggeredBy !== null &&
      notificationTriggeredBy.firstName !== undefined &&
      notificationTriggeredBy.firstName !== null
        ? notificationTriggeredBy.firstName
        : "";

    let lastName =
      notificationTriggeredBy !== null &&
      notificationTriggeredBy.lastName !== undefined &&
      notificationTriggeredBy.lastName !== null
        ? notificationTriggeredBy.lastName
        : "";

    let fullName = firstName + " " + lastName;

    let msgClickParam = { notiType: otherParam.notiType, notiObj: paramObj };

    let listObj = (
      <li key={notificationId}>
        <div
          className={`notifi_li_box ${
            isRead == true ? "notifi_read" : "notifi_unread"
          }`}
        >
          <div className="notifi_close">
            <button
              onClick={(evn) => {
                evn.preventDefault();
                this.clickedToRemove(msgClickParam);
              }}
              className="btn_appearance_none notiDrop_footerBtn"
            >
              <img src={getBaseUrl() + "assets/images/balck_cross.png"} />
            </button>
          </div>
          <div className="notifi_cont_box">
            <div className="notifi_left_icon">
              <img src={getBaseUrl() + "assets/images/noti_info.png"} />
            </div>
            <div className="notifi_main_text">
              {/* <p className="noti_title_text">
              <a href="javascript:void(0);">
                <b>Icon for -- info.svg</b> -- noti_info.png
              </a>
            </p> */}
              <p className="noti_task_text">
                <a
                  href=""
                  onClick={(evn) => {
                    evn.preventDefault();
                    this.messageClicked(msgClickParam);
                  }}
                >
                  <b>{fullName}</b> has sent engagement letter and offered to
                  take Task: "<b>{taskName}</b>".
                </a>
              </p>
            </div>
          </div>
          <div className="notifi_bottom_text">
            {/* 24 Sep 2020 9:45am IST */}
            {CustDate.custFormatDate(new Date(notificationTime), 6)}
          </div>
          <div className="clearboth" />
        </div>
      </li>
    );

    return <>{listObj}</>;
  };
  //3
  formatNegotiationNoti = (paramObj, otherParam) => {
    let webSocketTaskInfo =
      paramObj !== null &&
      paramObj.webSocketTaskInfo !== undefined &&
      paramObj.webSocketTaskInfo !== null
        ? paramObj.webSocketTaskInfo
        : null;

    let webSocketNotificationInfo =
      paramObj !== null &&
      paramObj.webSocketNotificationInfo !== undefined &&
      paramObj.webSocketNotificationInfo !== null
        ? paramObj.webSocketNotificationInfo
        : null;

    let webSocketNegotiationChatInfo =
      paramObj !== null &&
      paramObj.webSocketNegotiationChatInfo !== undefined &&
      paramObj.webSocketNegotiationChatInfo !== null
        ? paramObj.webSocketNegotiationChatInfo
        : null;

    let taskName =
      webSocketTaskInfo !== null &&
      webSocketTaskInfo.taskName !== undefined &&
      webSocketTaskInfo.taskName !== null
        ? webSocketTaskInfo.taskName
        : "";

    let notificationTime =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationTime !== undefined &&
      webSocketNotificationInfo.notificationTime !== null
        ? webSocketNotificationInfo.notificationTime
        : null;

    let notificationId =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationId !== undefined &&
      webSocketNotificationInfo.notificationId !== null
        ? webSocketNotificationInfo.notificationId
        : 0;

    let isRead =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.isRead !== undefined &&
      webSocketNotificationInfo.isRead !== null
        ? webSocketNotificationInfo.isRead
        : false;

    let notificationTriggeredBy =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationTriggeredBy !== undefined &&
      webSocketNotificationInfo.notificationTriggeredBy !== null
        ? webSocketNotificationInfo.notificationTriggeredBy
        : null;

    let firstName =
      notificationTriggeredBy !== null &&
      notificationTriggeredBy.firstName !== undefined &&
      notificationTriggeredBy.firstName !== null
        ? notificationTriggeredBy.firstName
        : "";

    let lastName =
      notificationTriggeredBy !== null &&
      notificationTriggeredBy.lastName !== undefined &&
      notificationTriggeredBy.lastName !== null
        ? notificationTriggeredBy.lastName
        : "";

    let budgetNegotiationStatus =
      webSocketNegotiationChatInfo !== null &&
      webSocketNegotiationChatInfo.budgetNegotiationStatus !== undefined &&
      webSocketNegotiationChatInfo.budgetNegotiationStatus !== null
        ? webSocketNegotiationChatInfo.budgetNegotiationStatus
        : "";

    let dueDateNegotiationStatus =
      webSocketNegotiationChatInfo !== null &&
      webSocketNegotiationChatInfo.dueDateNegotiationStatus !== undefined &&
      webSocketNegotiationChatInfo.dueDateNegotiationStatus !== null
        ? webSocketNegotiationChatInfo.dueDateNegotiationStatus
        : "";

    let chat =
      webSocketNegotiationChatInfo !== null &&
      webSocketNegotiationChatInfo.chat !== undefined &&
      webSocketNegotiationChatInfo.chat !== null
        ? webSocketNegotiationChatInfo.chat
        : "";

    let dueDateNegoNoti =
      chat !== null && chat.dueDate !== undefined && chat.dueDate !== null
        ? chat.dueDate
        : null;

    let budgetNegoNoti =
      chat !== null && chat.budget !== undefined && chat.budget !== null
        ? chat.budget
        : null;

    let statusNegoNoti =
      chat !== null && chat.status !== undefined && chat.status !== null
        ? chat.status
        : null;

    let fullName = firstName + " " + lastName;

    let msgClickParam = { notiType: otherParam.notiType, notiObj: paramObj };

    let listObj = (
      <li key={notificationId}>
        <div
          className={`notifi_li_box ${
            isRead == true ? "notifi_read" : "notifi_unread"
          }`}
        >
          <div className="notifi_close">
            <button
              onClick={(evn) => {
                evn.preventDefault();
                this.clickedToRemove(msgClickParam);
              }}
              className="btn_appearance_none notiDrop_footerBtn"
            >
              <img src={getBaseUrl() + "assets/images/balck_cross.png"} />
            </button>
          </div>
          <div className="notifi_cont_box">
            <div className="notifi_left_icon">
              <img src={getBaseUrl() + "assets/images/noti_negotiation.png"} />
            </div>
            <div className="notifi_main_text">
              {budgetNegoNoti !== null &&
                dueDateNegoNoti === null &&
                statusNegoNoti == "AGREE" && (
                  <p className="noti_task_text">
                    <a
                      href=""
                      onClick={(evn) => {
                        evn.preventDefault();
                        this.messageClicked(msgClickParam);
                      }}
                    >
                      <b>{fullName}</b> has agreed to Budget for Task: "
                      <b>{taskName}</b>".
                    </a>
                  </p>
                )}

              {budgetNegoNoti === null &&
                dueDateNegoNoti !== null &&
                statusNegoNoti == "AGREE" && (
                  <p className="noti_task_text">
                    <a
                      href=""
                      onClick={(evn) => {
                        evn.preventDefault();
                        this.messageClicked(msgClickParam);
                      }}
                    >
                      <b>{fullName}</b> has agreed to Due Date for Task: "
                      <b>{taskName}</b>".
                    </a>
                  </p>
                )}

              {budgetNegoNoti !== null &&
                dueDateNegoNoti === null &&
                statusNegoNoti == "NEGOTIATE" && (
                  <p className="noti_task_text">
                    <a
                      href=""
                      onClick={(evn) => {
                        evn.preventDefault();
                        this.messageClicked(msgClickParam);
                      }}
                    >
                      <b>{fullName}</b> has sent a new negotiation for Budget
                      for Task: "<b>{taskName}</b>".
                    </a>
                  </p>
                )}

              {budgetNegoNoti === null &&
                dueDateNegoNoti !== null &&
                statusNegoNoti == "NEGOTIATE" && (
                  <p className="noti_task_text">
                    <a
                      href=""
                      onClick={(evn) => {
                        evn.preventDefault();
                        this.messageClicked(msgClickParam);
                      }}
                    >
                      <b>{fullName}</b> has sent a new negotiation for Due Date
                      for Task: "<b>{taskName}</b>".
                    </a>
                  </p>
                )}
            </div>
          </div>
          <div className="notifi_bottom_text">
            {/* 24 Sep 2020 9:45am IST */}
            {CustDate.custFormatDate(new Date(notificationTime), 6)}
          </div>
          <div className="clearboth" />
        </div>
      </li>
    );

    return <>{listObj}</>;
  };
  //4
  formatTaskReqAcceptedNoti = (paramObj, otherParam) => {
    let webSocketTaskInfo =
      paramObj !== null &&
      paramObj.webSocketTaskInfo !== undefined &&
      paramObj.webSocketTaskInfo !== null
        ? paramObj.webSocketTaskInfo
        : null;

    let webSocketNotificationInfo =
      paramObj !== null &&
      paramObj.webSocketNotificationInfo !== undefined &&
      paramObj.webSocketNotificationInfo !== null
        ? paramObj.webSocketNotificationInfo
        : null;

    let taskName =
      webSocketTaskInfo !== null &&
      webSocketTaskInfo.taskName !== undefined &&
      webSocketTaskInfo.taskName !== null
        ? webSocketTaskInfo.taskName
        : "";

    let notificationTime =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationTime !== undefined &&
      webSocketNotificationInfo.notificationTime !== null
        ? webSocketNotificationInfo.notificationTime
        : null;

    let notificationId =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationId !== undefined &&
      webSocketNotificationInfo.notificationId !== null
        ? webSocketNotificationInfo.notificationId
        : 0;

    let isRead =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.isRead !== undefined &&
      webSocketNotificationInfo.isRead !== null
        ? webSocketNotificationInfo.isRead
        : false;

    let notificationTriggeredBy =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationTriggeredBy !== undefined &&
      webSocketNotificationInfo.notificationTriggeredBy !== null
        ? webSocketNotificationInfo.notificationTriggeredBy
        : null;

    let firstName =
      notificationTriggeredBy !== null &&
      notificationTriggeredBy.firstName !== undefined &&
      notificationTriggeredBy.firstName !== null
        ? notificationTriggeredBy.firstName
        : "";

    let lastName =
      notificationTriggeredBy !== null &&
      notificationTriggeredBy.lastName !== undefined &&
      notificationTriggeredBy.lastName !== null
        ? notificationTriggeredBy.lastName
        : "";

    let fullName = firstName + " " + lastName;

    let msgClickParam = { notiType: otherParam.notiType, notiObj: paramObj };

    let listObj = (
      <li key={notificationId}>
        <div
          className={`notifi_li_box ${
            isRead == true ? "notifi_read" : "notifi_unread"
          }`}
        >
          <div className="notifi_close">
            <button
              onClick={(evn) => {
                evn.preventDefault();
                this.clickedToRemove(msgClickParam);
              }}
              className="btn_appearance_none notiDrop_footerBtn"
            >
              <img src={getBaseUrl() + "assets/images/balck_cross.png"} />
            </button>
          </div>
          <div className="notifi_cont_box">
            <div className="notifi_left_icon">
              <img src={getBaseUrl() + "assets/images/noti_info.png"} />
            </div>
            <div className="notifi_main_text">
              <p className="noti_task_text">
                <a
                  href=""
                  onClick={(evn) => {
                    evn.preventDefault();
                    this.messageClicked(msgClickParam);
                  }}
                >
                  You have a new Interest from <b>{fullName}</b> for Task: "
                  <b>{taskName}</b>".
                </a>
              </p>
            </div>
          </div>
          <div className="notifi_bottom_text">
            {/* 24 Sep 2020 9:45am IST */}
            {CustDate.custFormatDate(new Date(notificationTime), 6)}
          </div>
          <div className="clearboth" />
        </div>
      </li>
    );

    return <>{listObj}</>;
  };
  //5
  formatSubsriptionSuccessNoti = (paramObj, otherParam) => {
    let webSocketSubscriptionInfo =
      paramObj !== null &&
      paramObj.webSocketSubscriptionInfo !== undefined &&
      paramObj.webSocketSubscriptionInfo !== null
        ? paramObj.webSocketSubscriptionInfo
        : null;

    let webSocketNotificationInfo =
      paramObj !== null &&
      paramObj.webSocketNotificationInfo !== undefined &&
      paramObj.webSocketNotificationInfo !== null
        ? paramObj.webSocketNotificationInfo
        : null;

    let subscriptionAmount =
      webSocketSubscriptionInfo !== null &&
      webSocketSubscriptionInfo.subscriptionAmount !== undefined &&
      webSocketSubscriptionInfo.subscriptionAmount !== null
        ? webSocketSubscriptionInfo.subscriptionAmount
        : "";

    let notificationTime =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationTime !== undefined &&
      webSocketNotificationInfo.notificationTime !== null
        ? webSocketNotificationInfo.notificationTime
        : null;

    let notificationId =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationId !== undefined &&
      webSocketNotificationInfo.notificationId !== null
        ? webSocketNotificationInfo.notificationId
        : 0;

    let isRead =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.isRead !== undefined &&
      webSocketNotificationInfo.isRead !== null
        ? webSocketNotificationInfo.isRead
        : false;

    let msgClickParam = { notiType: otherParam.notiType, notiObj: paramObj };

    let listObj = (
      <li key={notificationId}>
        <div
          className={`notifi_li_box ${
            isRead == true ? "notifi_read" : "notifi_unread"
          }`}
        >
          <div className="notifi_close">
            <button
              onClick={(evn) => {
                evn.preventDefault();
                this.clickedToRemove(msgClickParam);
              }}
              className="btn_appearance_none notiDrop_footerBtn"
            >
              <img src={getBaseUrl() + "assets/images/balck_cross.png"} />
            </button>
          </div>
          <div className="notifi_cont_box">
            <div className="notifi_left_icon">
              <img src={getBaseUrl() + "assets/images/noti_info.png"} />
            </div>
            <div className="notifi_main_text">
              <p className="noti_task_text">
                <a
                  href=""
                  onClick={(evn) => {
                    evn.preventDefault();
                    this.messageClicked(msgClickParam);
                  }}
                >
                  Your payment of INR <b>{subscriptionAmount}</b> has been
                  received and your subscription is upgraded to Elite.
                </a>
              </p>
            </div>
          </div>
          <div className="notifi_bottom_text">
            {/* 24 Sep 2020 9:45am IST */}
            {CustDate.custFormatDate(new Date(notificationTime), 6)}
          </div>
          <div className="clearboth" />
        </div>
      </li>
    );

    return <>{listObj}</>;
  };
  //6
  formatSubsriptionFailureNoti = (paramObj, otherParam) => {
    let webSocketNotificationInfo =
      paramObj !== null &&
      paramObj.webSocketNotificationInfo !== undefined &&
      paramObj.webSocketNotificationInfo !== null
        ? paramObj.webSocketNotificationInfo
        : null;

    let notificationTime =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationTime !== undefined &&
      webSocketNotificationInfo.notificationTime !== null
        ? webSocketNotificationInfo.notificationTime
        : null;

    let notificationId =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationId !== undefined &&
      webSocketNotificationInfo.notificationId !== null
        ? webSocketNotificationInfo.notificationId
        : 0;

    let isRead =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.isRead !== undefined &&
      webSocketNotificationInfo.isRead !== null
        ? webSocketNotificationInfo.isRead
        : false;

    let msgClickParam = { notiType: otherParam.notiType, notiObj: paramObj };

    let listObj = (
      <li key={notificationId}>
        <div
          className={`notifi_li_box ${
            isRead == true ? "notifi_read" : "notifi_unread"
          }`}
        >
          <div className="notifi_close">
            <button
              onClick={(evn) => {
                evn.preventDefault();
                this.clickedToRemove(msgClickParam);
              }}
              className="btn_appearance_none notiDrop_footerBtn"
            >
              <img src={getBaseUrl() + "assets/images/balck_cross.png"} />
            </button>
          </div>
          <div className="notifi_cont_box">
            <div className="notifi_left_icon">
              <img src={getBaseUrl() + "assets/images/noti_info.png"} />
            </div>
            <div className="notifi_main_text">
              <p className="noti_task_text">
                <a
                  href=""
                  onClick={(evn) => {
                    evn.preventDefault();
                    this.messageClicked(msgClickParam);
                  }}
                >
                  Your payment for subscription has been failed.
                </a>
              </p>
            </div>
          </div>
          <div className="notifi_bottom_text">
            {/* 24 Sep 2020 9:45am IST */}
            {CustDate.custFormatDate(new Date(notificationTime), 6)}
          </div>
          <div className="clearboth" />
        </div>
      </li>
    );

    return <>{listObj}</>;
  };
  //7
  formatTimesheetNoti = (paramObj, otherParam) => {
    let webSocketNotificationInfo =
      paramObj !== null &&
      paramObj.webSocketNotificationInfo !== undefined &&
      paramObj.webSocketNotificationInfo !== null
        ? paramObj.webSocketNotificationInfo
        : null;

    let webSocketTaskInfo =
      paramObj !== null &&
      paramObj.webSocketTaskInfo !== undefined &&
      paramObj.webSocketTaskInfo !== null
        ? paramObj.webSocketTaskInfo
        : null;

    let taskName =
      webSocketTaskInfo !== null &&
      webSocketTaskInfo.taskName !== undefined &&
      webSocketTaskInfo.taskName !== null
        ? webSocketTaskInfo.taskName
        : "";

    let notificationTime =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationTime !== undefined &&
      webSocketNotificationInfo.notificationTime !== null
        ? webSocketNotificationInfo.notificationTime
        : null;

    let notificationId =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationId !== undefined &&
      webSocketNotificationInfo.notificationId !== null
        ? webSocketNotificationInfo.notificationId
        : 0;

    let isRead =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.isRead !== undefined &&
      webSocketNotificationInfo.isRead !== null
        ? webSocketNotificationInfo.isRead
        : false;

    let notificationTriggeredBy =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationTriggeredBy !== undefined &&
      webSocketNotificationInfo.notificationTriggeredBy !== null
        ? webSocketNotificationInfo.notificationTriggeredBy
        : null;

    let firstName =
      notificationTriggeredBy !== null &&
      notificationTriggeredBy.firstName !== undefined &&
      notificationTriggeredBy.firstName !== null
        ? notificationTriggeredBy.firstName
        : "";

    let lastName =
      notificationTriggeredBy !== null &&
      notificationTriggeredBy.lastName !== undefined &&
      notificationTriggeredBy.lastName !== null
        ? notificationTriggeredBy.lastName
        : "";

    let fullName = firstName + " " + lastName;

    let msgClickParam = { notiType: otherParam.notiType, notiObj: paramObj };

    let listObj = (
      <li key={notificationId}>
        <div
          className={`notifi_li_box ${
            isRead == true ? "notifi_read" : "notifi_unread"
          }`}
        >
          <div className="notifi_close">
            <button
              onClick={(evn) => {
                evn.preventDefault();
                this.clickedToRemove(msgClickParam);
              }}
              className="btn_appearance_none notiDrop_footerBtn"
            >
              <img src={getBaseUrl() + "assets/images/balck_cross.png"} />
            </button>
          </div>
          <div className="notifi_cont_box">
            <div className="notifi_left_icon">
              <img src={getBaseUrl() + "assets/images/noti_timesheet.png"} />
            </div>
            <div className="notifi_main_text">
              <p className="noti_task_text">
                <a
                  href=""
                  onClick={(evn) => {
                    evn.preventDefault();
                    this.messageClicked(msgClickParam);
                  }}
                >
                  <b>{fullName}</b> has updated Timesheet for Task:
                  <b>{taskName}</b>.
                </a>
              </p>
            </div>
          </div>
          <div className="notifi_bottom_text">
            {/* 24 Sep 2020 9:45am IST */}
            {CustDate.custFormatDate(new Date(notificationTime), 6)}
          </div>
          <div className="clearboth" />
        </div>
      </li>
    );

    return <>{listObj}</>;
  };
  //8
  formatKycNoti = (paramObj, otherParam) => {
    let webSocketNotificationInfo =
      paramObj !== null &&
      paramObj.webSocketNotificationInfo !== undefined &&
      paramObj.webSocketNotificationInfo !== null
        ? paramObj.webSocketNotificationInfo
        : null;

    let notificationTime =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationTime !== undefined &&
      webSocketNotificationInfo.notificationTime !== null
        ? webSocketNotificationInfo.notificationTime
        : null;

    let notificationId =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationId !== undefined &&
      webSocketNotificationInfo.notificationId !== null
        ? webSocketNotificationInfo.notificationId
        : 0;

    let isRead =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.isRead !== undefined &&
      webSocketNotificationInfo.isRead !== null
        ? webSocketNotificationInfo.isRead
        : false;

    let msgClickParam = { notiType: otherParam.notiType, notiObj: paramObj };

    let listObj = (
      <li key={notificationId}>
        <div
          className={`notifi_li_box ${
            isRead == true ? "notifi_read" : "notifi_unread"
          }`}
        >
          <div className="notifi_close">
            <button
              onClick={(evn) => {
                evn.preventDefault();
                this.clickedToRemove(msgClickParam);
              }}
              className="btn_appearance_none notiDrop_footerBtn"
            >
              <img src={getBaseUrl() + "assets/images/balck_cross.png"} />
            </button>
          </div>
          <div className="notifi_cont_box">
            <div className="notifi_left_icon">
              <img src={getBaseUrl() + "assets/images/noti_info.png"} />
            </div>
            <div className="notifi_main_text">
              <p className="noti_task_text">
                <a
                  href=""
                  onClick={(evn) => {
                    evn.preventDefault();
                    this.messageClicked(msgClickParam);
                  }}
                >
                  Your KYC has been successfully updated and approved by Admin.
                  You can now create tasks and discover professionals.
                </a>
              </p>
            </div>
          </div>
          <div className="notifi_bottom_text">
            {/* 24 Sep 2020 9:45am IST */}
            {CustDate.custFormatDate(new Date(notificationTime), 6)}
          </div>
          <div className="clearboth" />
        </div>
      </li>
    );

    return <>{listObj}</>;
  };
  //9
  formatProfileUpdateNoti = (paramObj, otherParam) => {
    let webSocketNotificationInfo =
      paramObj !== null &&
      paramObj.webSocketNotificationInfo !== undefined &&
      paramObj.webSocketNotificationInfo !== null
        ? paramObj.webSocketNotificationInfo
        : null;

    let notificationTime =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationTime !== undefined &&
      webSocketNotificationInfo.notificationTime !== null
        ? webSocketNotificationInfo.notificationTime
        : null;

    let notificationId =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationId !== undefined &&
      webSocketNotificationInfo.notificationId !== null
        ? webSocketNotificationInfo.notificationId
        : 0;

    let isRead =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.isRead !== undefined &&
      webSocketNotificationInfo.isRead !== null
        ? webSocketNotificationInfo.isRead
        : false;

    let msgClickParam = { notiType: otherParam.notiType, notiObj: paramObj };

    let listObj = (
      <li key={notificationId}>
        <div
          className={`notifi_li_box ${
            isRead == true ? "notifi_read" : "notifi_unread"
          }`}
        >
          <div className="notifi_close">
            <button
              onClick={(evn) => {
                evn.preventDefault();
                this.clickedToRemove(msgClickParam);
              }}
              className="btn_appearance_none notiDrop_footerBtn"
            >
              <img src={getBaseUrl() + "assets/images/balck_cross.png"} />
            </button>
          </div>
          <div className="notifi_cont_box">
            <div className="notifi_left_icon">
              <img src={getBaseUrl() + "assets/images/noti_info.png"} />
            </div>
            <div className="notifi_main_text">
              <p className="noti_task_text">
                <a
                  href=""
                  onClick={(evn) => {
                    evn.preventDefault();
                    this.messageClicked(msgClickParam);
                  }}
                >
                  Profile has been updated.
                </a>
              </p>
            </div>
          </div>
          <div className="notifi_bottom_text">
            {/* 24 Sep 2020 9:45am IST */}
            {CustDate.custFormatDate(new Date(notificationTime), 6)}
          </div>
          <div className="clearboth" />
        </div>
      </li>
    );

    return <>{listObj}</>;
  };

  //10
  formatNewCommentNoti = (paramObj, otherParam) => {
    let webSocketNotificationInfo =
      paramObj !== null &&
      paramObj.webSocketNotificationInfo !== undefined &&
      paramObj.webSocketNotificationInfo !== null
        ? paramObj.webSocketNotificationInfo
        : null;

    let webSocketCommentInfo =
      paramObj !== null &&
      paramObj.webSocketCommentInfo !== undefined &&
      paramObj.webSocketCommentInfo !== null
        ? paramObj.webSocketCommentInfo
        : null;

    let webSocketTaskInfo =
      paramObj !== null &&
      paramObj.webSocketTaskInfo !== undefined &&
      paramObj.webSocketTaskInfo !== null
        ? paramObj.webSocketTaskInfo
        : null;

    let notificationTime =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationTime !== undefined &&
      webSocketNotificationInfo.notificationTime !== null
        ? webSocketNotificationInfo.notificationTime
        : null;

    let notificationId =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationId !== undefined &&
      webSocketNotificationInfo.notificationId !== null
        ? webSocketNotificationInfo.notificationId
        : 0;

    let isRead =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.isRead !== undefined &&
      webSocketNotificationInfo.isRead !== null
        ? webSocketNotificationInfo.isRead
        : false;

    let taskName =
      webSocketTaskInfo !== null &&
      webSocketTaskInfo.taskName !== undefined &&
      webSocketTaskInfo.taskName !== null
        ? webSocketTaskInfo.taskName
        : "";

    let commentedBy =
      webSocketCommentInfo !== null &&
      webSocketCommentInfo.commentedBy !== undefined &&
      webSocketCommentInfo.commentedBy !== null
        ? webSocketCommentInfo.commentedBy
        : "";

    let comment =
      webSocketCommentInfo !== null &&
      webSocketCommentInfo.comment !== undefined &&
      webSocketCommentInfo.comment !== null
        ? webSocketCommentInfo.comment
        : "";

    // Newest1 - Start

    let videoLink =
      webSocketCommentInfo !== null &&
      webSocketCommentInfo.videoCallDetails !== undefined &&
      webSocketCommentInfo.videoCallDetails !== null &&
      webSocketCommentInfo.videoCallDetails.videoLink !== undefined &&
      webSocketCommentInfo.videoCallDetails.videoLink !== null
        ? webSocketCommentInfo.videoCallDetails.videoLink
        : null;

    let endingTerm = "Meeting Link.";

    if (videoLink) {
      if (comment.endsWith(endingTerm)) {
        comment = comment.replace(endingTerm, videoLink);
      } else {
        comment = (
          <React.Fragment>
            {comment}
            <br />
            {videoLink}
          </React.Fragment>
        );
      }
    }

    let newComment = (
      <React.Fragment>
        You have received a new message for Task:&nbsp;
        <b>"{taskName}"</b>
        &nbsp;by&nbsp; <b>{commentedBy}</b>:&nbsp;
        <b>{comment}</b>
      </React.Fragment>
    );

    let isVideoMeet = videoLink ? true : false;

    let msgClickParam = {
      notiType: otherParam.notiType,
      notiObj: paramObj,
      isVideoMeet: isVideoMeet,
    };

    // Newest1 - End

    let listObj = (
      <li key={notificationId}>
        <div
          className={`notifi_li_box ${
            isRead == true ? "notifi_read" : "notifi_unread"
          }`}
        >
          <div className="notifi_close">
            <button
              onClick={(evn) => {
                evn.preventDefault();
                this.clickedToRemove(msgClickParam);
              }}
              className="btn_appearance_none notiDrop_footerBtn"
            >
              <img src={getBaseUrl() + "assets/images/balck_cross.png"} />
            </button>
          </div>
          <div className="notifi_cont_box">
            <div className="notifi_left_icon">
              <img src={getBaseUrl() + "assets/images/noti_comment.png"} />
            </div>
            <div className="notifi_main_text">
              <p className="noti_task_text">
                {/* Newest1 - Start */}
                <a
                  href=""
                  onClick={(evn) => {
                    evn.preventDefault();
                    this.messageClicked(msgClickParam);
                  }}
                >
                  {newComment}
                </a>
                {/* Newest1 - End */}
              </p>
            </div>
          </div>
          <div className="notifi_bottom_text">
            {/* 24 Sep 2020 9:45am IST */}
            {CustDate.custFormatDate(new Date(notificationTime), 6)}
          </div>
          <div className="clearboth" />
        </div>
      </li>
    );

    return <>{listObj}</>;
  };

  //11
  formatTaskDocumentNoti = (paramObj, otherParam) => {
    let webSocketNotificationInfo =
      paramObj !== null &&
      paramObj.webSocketNotificationInfo !== undefined &&
      paramObj.webSocketNotificationInfo !== null
        ? paramObj.webSocketNotificationInfo
        : null;

    let webSocketTaskInfo =
      paramObj !== null &&
      paramObj.webSocketTaskInfo !== undefined &&
      paramObj.webSocketTaskInfo !== null
        ? paramObj.webSocketTaskInfo
        : null;

    let taskName =
      webSocketTaskInfo !== null &&
      webSocketTaskInfo.taskName !== undefined &&
      webSocketTaskInfo.taskName !== null
        ? webSocketTaskInfo.taskName
        : "";

    let notificationTime =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationTime !== undefined &&
      webSocketNotificationInfo.notificationTime !== null
        ? webSocketNotificationInfo.notificationTime
        : null;

    let notificationId =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationId !== undefined &&
      webSocketNotificationInfo.notificationId !== null
        ? webSocketNotificationInfo.notificationId
        : 0;

    let isRead =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.isRead !== undefined &&
      webSocketNotificationInfo.isRead !== null
        ? webSocketNotificationInfo.isRead
        : false;

    let notificationTriggeredBy =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationTriggeredBy !== undefined &&
      webSocketNotificationInfo.notificationTriggeredBy !== null
        ? webSocketNotificationInfo.notificationTriggeredBy
        : null;

    let firstName =
      notificationTriggeredBy !== null &&
      notificationTriggeredBy.firstName !== undefined &&
      notificationTriggeredBy.firstName !== null
        ? notificationTriggeredBy.firstName
        : "";

    let lastName =
      notificationTriggeredBy !== null &&
      notificationTriggeredBy.lastName !== undefined &&
      notificationTriggeredBy.lastName !== null
        ? notificationTriggeredBy.lastName
        : "";

    let fullName = firstName + " " + lastName;

    let msgClickParam = { notiType: otherParam.notiType, notiObj: paramObj };

    let listObj = (
      <li key={notificationId}>
        <div
          className={`notifi_li_box ${
            isRead == true ? "notifi_read" : "notifi_unread"
          }`}
        >
          <div className="notifi_close">
            <button
              onClick={(evn) => {
                evn.preventDefault();
                this.clickedToRemove(msgClickParam);
              }}
              className="btn_appearance_none notiDrop_footerBtn"
            >
              <img src={getBaseUrl() + "assets/images/balck_cross.png"} />
            </button>
          </div>
          <div className="notifi_cont_box">
            <div className="notifi_left_icon">
              <img src={getBaseUrl() + "assets/images/noti_info.png"} />
            </div>
            <div className="notifi_main_text">
              <p className="noti_task_text">
                <a
                  href=""
                  onClick={(evn) => {
                    evn.preventDefault();
                    this.messageClicked(msgClickParam);
                  }}
                >
                  <b>{fullName}</b> has sent a new document for Task:
                  <b>{taskName}</b>.
                </a>
              </p>
            </div>
          </div>
          <div className="notifi_bottom_text">
            {/* 24 Sep 2020 9:45am IST */}
            {CustDate.custFormatDate(new Date(notificationTime), 6)}
          </div>
          <div className="clearboth" />
        </div>
      </li>
    );

    return <>{listObj}</>;
  };
  //12
  formatVirtualAccLoadNoti = (paramObj, otherParam) => {
    let webSocketNotificationInfo =
      paramObj !== null &&
      paramObj.webSocketNotificationInfo !== undefined &&
      paramObj.webSocketNotificationInfo !== null
        ? paramObj.webSocketNotificationInfo
        : null;

    let webSocketVirtualAccountActionInfo =
      paramObj !== null &&
      paramObj.webSocketVirtualAccountActionInfo !== undefined &&
      paramObj.webSocketVirtualAccountActionInfo !== null
        ? paramObj.webSocketVirtualAccountActionInfo
        : null;

    let transactionType =
      webSocketVirtualAccountActionInfo !== null &&
      webSocketVirtualAccountActionInfo.transactionType !== undefined &&
      webSocketVirtualAccountActionInfo.transactionType !== null
        ? webSocketVirtualAccountActionInfo.transactionType
        : null;

    let amount =
      webSocketVirtualAccountActionInfo !== null &&
      webSocketVirtualAccountActionInfo.amount !== undefined &&
      webSocketVirtualAccountActionInfo.amount !== null
        ? webSocketVirtualAccountActionInfo.amount
        : null;

    let amountCurrency =
      webSocketVirtualAccountActionInfo !== null &&
      webSocketVirtualAccountActionInfo.amountCurrency !== undefined &&
      webSocketVirtualAccountActionInfo.amountCurrency !== null
        ? webSocketVirtualAccountActionInfo.amountCurrency
        : null;

    let notificationId =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationId !== undefined &&
      webSocketNotificationInfo.notificationId !== null
        ? webSocketNotificationInfo.notificationId
        : 0;

    let notificationTime =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationTime !== undefined &&
      webSocketNotificationInfo.notificationTime !== null
        ? webSocketNotificationInfo.notificationTime
        : null;

    let isRead =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.isRead !== undefined &&
      webSocketNotificationInfo.isRead !== null
        ? webSocketNotificationInfo.isRead
        : false;

    let msgClickParam = { notiType: otherParam.notiType, notiObj: paramObj };

    let listObj = (
      <li key={notificationId}>
        <div
          className={`notifi_li_box ${
            isRead == true ? "notifi_read" : "notifi_unread"
          }`}
        >
          <div className="notifi_close">
            <button
              onClick={(evn) => {
                evn.preventDefault();
                this.clickedToRemove(msgClickParam);
              }}
              className="btn_appearance_none notiDrop_footerBtn"
            >
              <img src={getBaseUrl() + "assets/images/balck_cross.png"} />
            </button>
          </div>
          <div className="notifi_cont_box">
            <div className="notifi_left_icon">
              <img src={getBaseUrl() + "assets/images/noti_wallet.png"} />
            </div>
            <div className="notifi_main_text">
              <p className="noti_task_text">
                <a
                  href=""
                  onClick={(evn) => {
                    evn.preventDefault();
                    this.messageClicked(msgClickParam);
                  }}
                >
                  {transactionType === "CREDIT" && amount && amountCurrency && (
                    <>
                      Your Virtual Bank Account has been credited with
                      <b>
                        {amount.toString() + " " + amountCurrency.toString()}
                      </b>
                      .
                    </>
                  )}
                  {transactionType === "DEBIT" && amount && amountCurrency && (
                    <>
                      Your Virtual Bank Account has been debited with
                      <b>
                        {amount.toString() + " " + amountCurrency.toString()}
                      </b>
                      .
                    </>
                  )}
                </a>
              </p>
            </div>
          </div>
          <div className="notifi_bottom_text">
            {/* 24 Sep 2020 9:45am IST */}
            {CustDate.custFormatDate(new Date(notificationTime), 6)}
          </div>
          <div className="clearboth" />
        </div>
      </li>
    );

    return <>{listObj}</>;
  };
  //13
  formatSubscriptionWaiverNoti = (paramObj, otherParam) => {
    let webSocketNotificationInfo =
      paramObj !== null &&
      paramObj.webSocketNotificationInfo !== undefined &&
      paramObj.webSocketNotificationInfo !== null
        ? paramObj.webSocketNotificationInfo
        : null;

    let notificationId =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationId !== undefined &&
      webSocketNotificationInfo.notificationId !== null
        ? webSocketNotificationInfo.notificationId
        : 0;

    let notificationTime =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationTime !== undefined &&
      webSocketNotificationInfo.notificationTime !== null
        ? webSocketNotificationInfo.notificationTime
        : null;

    let isRead =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.isRead !== undefined &&
      webSocketNotificationInfo.isRead !== null
        ? webSocketNotificationInfo.isRead
        : false;

    let msgClickParam = { notiType: otherParam.notiType, notiObj: paramObj };

    let listObj = (
      <li key={notificationId}>
        <div
          className={`notifi_li_box ${
            isRead == true ? "notifi_read" : "notifi_unread"
          }`}
        >
          <div className="notifi_close">
            <button
              onClick={(evn) => {
                evn.preventDefault();
                this.clickedToRemove(msgClickParam);
              }}
              className="btn_appearance_none notiDrop_footerBtn"
            >
              <img src={getBaseUrl() + "assets/images/balck_cross.png"} />
            </button>
          </div>
          <div className="notifi_cont_box">
            <div className="notifi_left_icon">
              <img src={getBaseUrl() + "assets/images/noti_info.png"} />
            </div>
            <div className="notifi_main_text">
              <p className="noti_task_text">
                <a
                  href=""
                  onClick={(evn) => {
                    evn.preventDefault();
                    this.messageClicked(msgClickParam);
                  }}
                >
                  Congratulations! You have received a subscription waiver and
                  your subscription is upgraded to Elite.
                </a>
              </p>
            </div>
          </div>
          <div className="notifi_bottom_text">
            {/* 24 Sep 2020 9:45am IST */}
            {CustDate.custFormatDate(new Date(notificationTime), 6)}
          </div>
          <div className="clearboth" />
        </div>
      </li>
    );

    return <>{listObj}</>;
  };
  //14
  formatInvoicePaidNoti = (paramObj, otherParam) => {
    let webSocketNotificationInfo =
      paramObj !== null &&
      paramObj.webSocketNotificationInfo !== undefined &&
      paramObj.webSocketNotificationInfo !== null
        ? paramObj.webSocketNotificationInfo
        : null;

    let notificationTime =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationTime !== undefined &&
      webSocketNotificationInfo.notificationTime !== null
        ? webSocketNotificationInfo.notificationTime
        : null;

    let notificationId =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationId !== undefined &&
      webSocketNotificationInfo.notificationId !== null
        ? webSocketNotificationInfo.notificationId
        : 0;

    let isRead =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.isRead !== undefined &&
      webSocketNotificationInfo.isRead !== null
        ? webSocketNotificationInfo.isRead
        : false;

    let webSocketTaskInfo =
      paramObj !== null &&
      paramObj.webSocketTaskInfo !== undefined &&
      paramObj.webSocketTaskInfo !== null
        ? paramObj.webSocketTaskInfo
        : null;

    let taskName =
      webSocketTaskInfo !== null &&
      webSocketTaskInfo.taskName !== undefined &&
      webSocketTaskInfo.taskName !== null
        ? webSocketTaskInfo.taskName
        : "";

    let websocketInvoiceInfo =
      paramObj !== null &&
      paramObj.websocketInvoiceInfo !== undefined &&
      paramObj.websocketInvoiceInfo !== null
        ? paramObj.websocketInvoiceInfo
        : null;

    let invoiceNumber =
      websocketInvoiceInfo !== null &&
      websocketInvoiceInfo.invoiceNumber !== undefined &&
      websocketInvoiceInfo.invoiceNumber !== null
        ? websocketInvoiceInfo.invoiceNumber
        : "";

    let currency =
      websocketInvoiceInfo !== null &&
      websocketInvoiceInfo.currency !== undefined &&
      websocketInvoiceInfo.currency !== null
        ? websocketInvoiceInfo.currency
        : "";

    let amount =
      websocketInvoiceInfo !== null &&
      websocketInvoiceInfo.amount !== undefined &&
      websocketInvoiceInfo.amount !== null
        ? websocketInvoiceInfo.amount
        : "";

    let invoiceId =
      websocketInvoiceInfo !== null &&
      websocketInvoiceInfo.invoiceId !== undefined &&
      websocketInvoiceInfo.invoiceId !== null
        ? websocketInvoiceInfo.invoiceId
        : 0;

    let invoiceGenerator =
      websocketInvoiceInfo !== null &&
      websocketInvoiceInfo.invoiceGenerator !== undefined &&
      websocketInvoiceInfo.invoiceGenerator !== null
        ? websocketInvoiceInfo.invoiceGenerator
        : null;

    let firstName =
      invoiceGenerator.firstName !== undefined &&
      invoiceGenerator.firstName !== null
        ? invoiceGenerator.firstName
        : "";

    let lastName =
      invoiceGenerator.lastName !== undefined &&
      invoiceGenerator.lastName !== null
        ? invoiceGenerator.lastName
        : "";

    let fullName = firstName + " " + lastName;

    let msgClickParam = { notiType: otherParam.notiType, notiObj: paramObj };

    let listObj = (
      <li key={notificationId}>
        <div
          className={`notifi_li_box ${
            isRead == true ? "notifi_read" : "notifi_unread"
          }`}
        >
          <div className="notifi_close">
            <button
              onClick={(evn) => {
                evn.preventDefault();
                this.clickedToRemove(msgClickParam);
              }}
              className="btn_appearance_none notiDrop_footerBtn"
            >
              <img src={getBaseUrl() + "assets/images/balck_cross.png"} />
            </button>
          </div>
          <div className="notifi_cont_box">
            <div className="notifi_left_icon">
              <img src={getBaseUrl() + "assets/images/noti_info.png"} />
            </div>
            <div className="notifi_main_text">
              <p className="noti_task_text">
                <a
                  href=""
                  onClick={(evn) => {
                    evn.preventDefault();
                    this.messageClicked(msgClickParam);
                  }}
                >
                  Invoice No. <b>{invoiceNumber}</b> by <b>{fullName}</b> for
                  <b>
                    {currency} {amount}
                  </b>
                  has been successfully cleared for Task: <b>"{taskName}"</b>
                </a>
              </p>
            </div>
          </div>
          <div className="notifi_bottom_text">
            {/* 24 Sep 2020 9:45am IST */}
            {CustDate.custFormatDate(new Date(notificationTime), 6)}
          </div>
          <div className="clearboth" />
        </div>
      </li>
    );

    return <>{listObj}</>;
  };
  //15
  formatTaskEditApprovedNoti = (paramObj, otherParam) => {
    let webSocketNotificationInfo =
      paramObj !== null &&
      paramObj.webSocketNotificationInfo !== undefined &&
      paramObj.webSocketNotificationInfo !== null
        ? paramObj.webSocketNotificationInfo
        : null;

    let notificationTime =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationTime !== undefined &&
      webSocketNotificationInfo.notificationTime !== null
        ? webSocketNotificationInfo.notificationTime
        : null;

    let notificationId =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationId !== undefined &&
      webSocketNotificationInfo.notificationId !== null
        ? webSocketNotificationInfo.notificationId
        : 0;

    let isRead =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.isRead !== undefined &&
      webSocketNotificationInfo.isRead !== null
        ? webSocketNotificationInfo.isRead
        : false;

    let webSocketTaskInfo =
      paramObj !== null &&
      paramObj.webSocketTaskInfo !== undefined &&
      paramObj.webSocketTaskInfo !== null
        ? paramObj.webSocketTaskInfo
        : null;

    let taskName =
      webSocketTaskInfo !== null &&
      webSocketTaskInfo.taskName !== undefined &&
      webSocketTaskInfo.taskName !== null
        ? webSocketTaskInfo.taskName
        : "";

    let notificationTriggeredBy =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationTriggeredBy !== undefined &&
      webSocketNotificationInfo.notificationTriggeredBy !== null
        ? webSocketNotificationInfo.notificationTriggeredBy
        : null;

    let firstName =
      notificationTriggeredBy !== null &&
      notificationTriggeredBy.firstName !== undefined &&
      notificationTriggeredBy.firstName !== null
        ? notificationTriggeredBy.firstName
        : "";

    let lastName =
      notificationTriggeredBy !== null &&
      notificationTriggeredBy.lastName !== undefined &&
      notificationTriggeredBy.lastName !== null
        ? notificationTriggeredBy.lastName
        : "";

    let fullName = firstName + " " + lastName;

    let msgClickParam = { notiType: otherParam.notiType, notiObj: paramObj };

    let listObj = (
      <li key={notificationId}>
        <div
          className={`notifi_li_box ${
            isRead == true ? "notifi_read" : "notifi_unread"
          }`}
        >
          <div className="notifi_close">
            <button
              onClick={(evn) => {
                evn.preventDefault();
                this.clickedToRemove(msgClickParam);
              }}
              className="btn_appearance_none notiDrop_footerBtn"
            >
              <img src={getBaseUrl() + "assets/images/balck_cross.png"} />
            </button>
          </div>
          <div className="notifi_cont_box">
            <div className="notifi_left_icon">
              <img src={getBaseUrl() + "assets/images/noti_task.png"} />
            </div>
            <div className="notifi_main_text">
              <p className="noti_task_text">
                <a
                  href=""
                  onClick={(evn) => {
                    evn.preventDefault();
                    this.messageClicked(msgClickParam);
                  }}
                >
                  <b>{fullName}</b> has accepted the task edit by you for Task:
                  <b>"{taskName}"</b>.
                </a>
              </p>
            </div>
          </div>
          <div className="notifi_bottom_text">
            {/* 24 Sep 2020 9:45am IST */}
            {CustDate.custFormatDate(new Date(notificationTime), 6)}
          </div>
          <div className="clearboth" />
        </div>
      </li>
    );

    return <>{listObj}</>;
  };
  // 16
  formatTaskOfferAcceptedNoti = (paramObj, otherParam) => {
    let webSocketTaskInfo =
      paramObj !== null &&
      paramObj.webSocketTaskInfo !== undefined &&
      paramObj.webSocketTaskInfo !== null
        ? paramObj.webSocketTaskInfo
        : null;

    let webSocketNotificationInfo =
      paramObj !== null &&
      paramObj.webSocketNotificationInfo !== undefined &&
      paramObj.webSocketNotificationInfo !== null
        ? paramObj.webSocketNotificationInfo
        : null;

    let taskName =
      webSocketTaskInfo !== null &&
      webSocketTaskInfo.taskName !== undefined &&
      webSocketTaskInfo.taskName !== null
        ? webSocketTaskInfo.taskName
        : "";

    let notificationTime =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationTime !== undefined &&
      webSocketNotificationInfo.notificationTime !== null
        ? webSocketNotificationInfo.notificationTime
        : null;

    let notificationId =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationId !== undefined &&
      webSocketNotificationInfo.notificationId !== null
        ? webSocketNotificationInfo.notificationId
        : 0;

    let isRead =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.isRead !== undefined &&
      webSocketNotificationInfo.isRead !== null
        ? webSocketNotificationInfo.isRead
        : false;

    let notificationTriggeredBy =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationTriggeredBy !== undefined &&
      webSocketNotificationInfo.notificationTriggeredBy !== null
        ? webSocketNotificationInfo.notificationTriggeredBy
        : null;

    let firstName =
      notificationTriggeredBy !== null &&
      notificationTriggeredBy.firstName !== undefined &&
      notificationTriggeredBy.firstName !== null
        ? notificationTriggeredBy.firstName
        : "";

    let lastName =
      notificationTriggeredBy !== null &&
      notificationTriggeredBy.lastName !== undefined &&
      notificationTriggeredBy.lastName !== null
        ? notificationTriggeredBy.lastName
        : "";

    let fullName = firstName + " " + lastName;

    let msgClickParam = { notiType: otherParam.notiType, notiObj: paramObj };

    let listObj = (
      <li key={notificationId}>
        <div
          className={`notifi_li_box ${
            isRead == true ? "notifi_read" : "notifi_unread"
          }`}
        >
          <div className="notifi_close">
            <button
              onClick={(evn) => {
                evn.preventDefault();
                this.clickedToRemove(msgClickParam);
              }}
              className="btn_appearance_none notiDrop_footerBtn"
            >
              <img src={getBaseUrl() + "assets/images/balck_cross.png"} />
            </button>
          </div>
          <div className="notifi_cont_box">
            <div className="notifi_left_icon">
              <img src={getBaseUrl() + "assets/images/noti_info.png"} />
            </div>
            <div className="notifi_main_text">
              <p className="noti_task_text">
                <a
                  href=""
                  onClick={(evn) => {
                    evn.preventDefault();
                    this.messageClicked(msgClickParam);
                  }}
                >
                  <b>{fullName}</b> has accepted the offer for Task: "
                  <b>{taskName}</b>".
                </a>
              </p>
            </div>
          </div>
          <div className="notifi_bottom_text">
            {/* 24 Sep 2020 9:45am IST */}
            {CustDate.custFormatDate(new Date(notificationTime), 6)}
          </div>
          <div className="clearboth" />
        </div>
      </li>
    );

    return <>{listObj}</>;
  };

  // 17
  formatTaskOfferDeclineNoti = (paramObj, otherParam) => {
    let webSocketTaskInfo =
      paramObj !== null &&
      paramObj.webSocketTaskInfo !== undefined &&
      paramObj.webSocketTaskInfo !== null
        ? paramObj.webSocketTaskInfo
        : null;

    let webSocketNotificationInfo =
      paramObj !== null &&
      paramObj.webSocketNotificationInfo !== undefined &&
      paramObj.webSocketNotificationInfo !== null
        ? paramObj.webSocketNotificationInfo
        : null;

    let taskName =
      webSocketTaskInfo !== null &&
      webSocketTaskInfo.taskName !== undefined &&
      webSocketTaskInfo.taskName !== null
        ? webSocketTaskInfo.taskName
        : "";

    let notificationTime =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationTime !== undefined &&
      webSocketNotificationInfo.notificationTime !== null
        ? webSocketNotificationInfo.notificationTime
        : null;

    let notificationId =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationId !== undefined &&
      webSocketNotificationInfo.notificationId !== null
        ? webSocketNotificationInfo.notificationId
        : 0;

    let isRead =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.isRead !== undefined &&
      webSocketNotificationInfo.isRead !== null
        ? webSocketNotificationInfo.isRead
        : false;

    let notificationTriggeredBy =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationTriggeredBy !== undefined &&
      webSocketNotificationInfo.notificationTriggeredBy !== null
        ? webSocketNotificationInfo.notificationTriggeredBy
        : null;

    let firstName =
      notificationTriggeredBy !== null &&
      notificationTriggeredBy.firstName !== undefined &&
      notificationTriggeredBy.firstName !== null
        ? notificationTriggeredBy.firstName
        : "";

    let lastName =
      notificationTriggeredBy !== null &&
      notificationTriggeredBy.lastName !== undefined &&
      notificationTriggeredBy.lastName !== null
        ? notificationTriggeredBy.lastName
        : "";

    let fullName = firstName + " " + lastName;

    let msgClickParam = { notiType: otherParam.notiType, notiObj: paramObj };

    let listObj = (
      <li key={notificationId}>
        <div
          className={`notifi_li_box ${
            isRead == true ? "notifi_read" : "notifi_unread"
          }`}
        >
          <div className="notifi_close">
            <button
              onClick={(evn) => {
                evn.preventDefault();
                this.clickedToRemove(msgClickParam);
              }}
              className="btn_appearance_none notiDrop_footerBtn"
            >
              <img src={getBaseUrl() + "assets/images/balck_cross.png"} />
            </button>
          </div>
          <div className="notifi_cont_box">
            <div className="notifi_left_icon">
              <img src={getBaseUrl() + "assets/images/noti_info.png"} />
            </div>
            <div className="notifi_main_text">
              <p className="noti_task_text">
                <a
                  href=""
                  onClick={(evn) => {
                    evn.preventDefault();
                    this.messageClicked(msgClickParam);
                  }}
                >
                  <b>{fullName}</b> has declined the offer for Task: "
                  <b>{taskName}</b>".
                </a>
              </p>
            </div>
          </div>
          <div className="notifi_bottom_text">
            {/* 24 Sep 2020 9:45am IST */}
            {CustDate.custFormatDate(new Date(notificationTime), 6)}
          </div>
          <div className="clearboth" />
        </div>
      </li>
    );

    return <>{listObj}</>;
  };

  // Newest1 - Start

  /**
   * BellNotificationList
   * Function name : openVidLink
   * Author        :
   * Created Date  : 18-6-2021
   * Purpose       : To open vidLink in a new tab
   * Params        : vidLink
   **/
  openVidLink = (vidLink) => {
    if (vidLink) {
      const newWindow = window.open(vidLink, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    } else {
      // console.log("vidLink is unavailable!");
    }
  };

  // Newest1 - End

  messageClicked = (paramObjData) => {
    // console.log("from messageClicked=>>", paramObjData);

    let paramObj =
      paramObjData.notiObj !== undefined && paramObjData.notiObj !== null
        ? paramObjData.notiObj
        : null;

    let notiType =
      paramObjData.notiType !== undefined && paramObjData.notiType !== null
        ? paramObjData.notiType
        : "";

    let webSocketNotificationInfo =
      paramObj !== null &&
      paramObj.webSocketNotificationInfo !== undefined &&
      paramObj.webSocketNotificationInfo !== null
        ? paramObj.webSocketNotificationInfo
        : null;

    let isRead =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.isRead !== undefined &&
      webSocketNotificationInfo.isRead !== null
        ? webSocketNotificationInfo.isRead
        : false;

    let notificationId =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationId !== undefined &&
      webSocketNotificationInfo.notificationId !== null
        ? webSocketNotificationInfo.notificationId
        : 0;

    if (isRead == false) {
      let idAr = [];
      idAr.push(notificationId);
      let paramForService = { paramToPost: { ids: idAr }, status: true };
      this.callServiceToMakeRead(paramForService, paramObjData);
    }

    // Newest1 - Start

    if (paramObjData.isVideoMeet)
      this.openVidLink(
        paramObj.webSocketCommentInfo.videoCallDetails.videoLink
      );

    // Newest1 - End

    setTimeout(() => {
      console.log(" call goToSection==>> with", paramObjData);
      this.goToSection(paramObjData);
    }, 1200);
  };

  callServiceToMakeRead = (paramForService, paramObjData) => {
    let stcservice = new NotiStatusUpdateService();
    stcservice
      .updateNotiStatus(paramForService)
      .then((data) => {
        if (data.status == 200) {
          let respData = {};
          if (data.data.entity !== undefined) {
            this.reloadBellData();
          }
        }
      })
      .catch((err) => {
        let errStatusCodeAr = [400, 500]; // allowed error status code
        if (err.response && err.response.data) {
          let erRespData = err.response.data;
          if (errStatusCodeAr.indexOf(erRespData.status) !== -1) {
            let errMsg = erRespData.message
              ? erRespData.message
              : "Some technical problem occurred.";
            console.log(errMsg);
          }
        }
      })
      .finally(() => {});
  };

  closeSplit = (objParam) => {
    //********* for context starts *****
    // let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;
    // if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){

    //     if(sharedData.actionType=="SPLITSCREEN" ){

    //       let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;

    //       if(setShare){
    //         let paramsCntxt = {
    //           "taskId":"",
    //           "actionType":"",
    //           "leftTabToActive":"",
    //           "purpose":"",
    //           "other":{}
    //         }
    //         setShare(paramsCntxt);
    //       }

    //     }
    // }
    //********* for context ends *****
    commonnotitrack("CLOSE_SPLITSCREEN", {
      notiType: "CLOSE_SPLITSCREEN",
      triggerCallback: false,
      trigerredFrom: "BELL_NOTIFICATION_MENU",
    });
  };

  goToSection = async (paramObjData) => {
    let notiObj =
      paramObjData.notiObj !== undefined && paramObjData.notiObj !== null
        ? paramObjData.notiObj
        : null;

    let notiType =
      paramObjData.notiType !== undefined && paramObjData.notiType !== null
        ? paramObjData.notiType
        : "";

    //let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;
    //let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;

    this.closeSplit({ trigerredFrom: "BELL_NOTIFICATION_MENU" });

    console.log(" from goToSection notiType==>", notiType, notiObj);

    let webSocketTaskInfo =
      notiObj !== null &&
      notiObj.webSocketTaskInfo !== undefined &&
      notiObj.webSocketTaskInfo !== null
        ? notiObj.webSocketTaskInfo
        : null;

    let taskId =
      webSocketTaskInfo !== null &&
      webSocketTaskInfo.taskId !== undefined &&
      webSocketTaskInfo.taskId !== null
        ? webSocketTaskInfo.taskId
        : 0;

    let paramObj1 = {};

    if (notiType) {
      switch (notiType) {
        case "INVOICE_DUE":
          paramObj1 = {
            data: {
              taskId: taskId,
            },
          };
          this.getTaskDetailData(paramObj1, {
            notiType: notiType,
            notiObj: notiObj,
          });
          break;
        case "TASK_TERMS":
          paramObj1 = {
            data: {
              taskId: taskId,
            },
          };
          this.getTaskDetailData(paramObj1, {
            notiType: notiType,
            notiObj: notiObj,
          });
          break;
        case "NEGOTIATION":
          paramObj1 = {
            data: {
              taskId: taskId,
            },
          };
          this.getTaskDetailData(paramObj1, {
            notiType: notiType,
            notiObj: notiObj,
          });
          break;
        case "TASK_REQUEST_ACCEPTED":
          paramObj1 = {
            data: {
              taskId: taskId,
            },
          };
          this.getTaskDetailData(paramObj1, {
            notiType: notiType,
            notiObj: notiObj,
          });
          break;
        case "TASK_ACCEPTED":
          paramObj1 = {
            data: {
              taskId: taskId,
            },
          };
          this.getTaskDetailData(paramObj1, {
            notiType: notiType,
            notiObj: notiObj,
          });
          break;
        case "TASK_DECLINED":
          paramObj1 = {
            data: {
              taskId: taskId,
            },
          };
          this.getTaskDetailData(paramObj1, {
            notiType: notiType,
            notiObj: notiObj,
          });
          break;

        case "SUBSCRIPTION_SUCCESS":
          this.props.history.push("/application-settings");
          break;
        case "SUBSCRIPTION_FAILURE":
          this.props.history.push("/application-settings");
          break;
        case "TIMESHEET":
          paramObj1 = {
            data: {
              taskId: taskId,
            },
          };
          this.getTaskDetailData(paramObj1, {
            notiType: notiType,
            notiObj: notiObj,
          });
          break;
        case "KYC":
          setTimeout(() => {
            this.props.history.push("/profile-settings");
          }, 900);
          break;
        case "PROFILE_UPDATE":
          setTimeout(() => {
            this.props.history.push("/profile-settings");
          }, 900);
          break;
        case "NEW_COMMENT":
          paramObj1 = {
            data: {
              taskId: taskId,
            },
          };
          this.getTaskDetailData(paramObj1, {
            notiType: notiType,
            notiObj: notiObj,
          });
          break;
        case "TASK_DOCUMENT":
          //
          paramObj1 = {
            data: {
              taskId: taskId,
            },
          };
          this.getTaskDetailData(paramObj1, {
            notiType: notiType,
            notiObj: notiObj,
          });
          break;
        case "VIRTUAL_ACCOUNT_LOAD":
          setTimeout(() => {
            this.props.history.push("/virtual-bank-account");
          }, 900);
          break;
        case "SUBSCRIPTION_WAIVER":
          setTimeout(() => {
            this.props.history.push("/application-settings");
          }, 900);
          break;
        case "INVOICE_PAID":
          //
          paramObj1 = {
            data: {
              taskId: taskId,
            },
          };
          this.getTaskDetailData(paramObj1, {
            notiType: notiType,
            notiObj: notiObj,
          });
          break;
        case "TASK_EDIT_APPROVED":
          //
          paramObj1 = {
            data: {
              taskId: taskId,
            },
          };
          this.getTaskDetailData(paramObj1, {
            notiType: notiType,
            notiObj: notiObj,
          });
          break;
        default:
          break;
      }
    }
  };

  callToMakeAllNotiRead = (paramObjData) => {
    let paramForService = { paramToPost: {}, status: true };
    this.callServiceToMakeAllNotiRead(paramForService);
  };

  callServiceToMakeAllNotiRead = (paramForService) => {
    let stcservice = new NotiAllStatusUpdateService();
    stcservice
      .updateNotiStatus(paramForService)
      .then((data) => {
        if (data.status == 200) {
          let respData = {};
          if (data.data.entity !== undefined) {
            this.reloadBellData();
          }
        }
      })
      .catch((err) => {
        let errStatusCodeAr = [400, 500]; // allowed error status code
        if (err.response && err.response.data) {
          let erRespData = err.response.data;
          if (errStatusCodeAr.indexOf(erRespData.status) !== -1) {
            let errMsg = erRespData.message
              ? erRespData.message
              : "Some technical problem occurred.";
            console.log(errMsg);
          }
        }
      })
      .finally(() => {
        this.isComponentLoaded &&
          this.setState({ markAllReadButtonFlag: false });
      });
  };

  clickedToRemove = (paramObjData) => {
    //console.log("from messageClicked=>>",paramObjData);

    let paramObj =
      paramObjData.notiObj !== undefined && paramObjData.notiObj !== null
        ? paramObjData.notiObj
        : null;

    let webSocketNotificationInfo =
      paramObj !== null &&
      paramObj.webSocketNotificationInfo !== undefined &&
      paramObj.webSocketNotificationInfo !== null
        ? paramObj.webSocketNotificationInfo
        : null;

    let notificationId =
      webSocketNotificationInfo !== null &&
      webSocketNotificationInfo.notificationId !== undefined &&
      webSocketNotificationInfo.notificationId !== null
        ? webSocketNotificationInfo.notificationId
        : 0;

    let idAr = [];
    idAr.push(notificationId);
    let paramForService = { paramToPost: { ids: idAr } };
    this.callServiceToRemove(paramForService, paramObjData);
  };

  callServiceToRemove = (paramForService, paramObjData) => {
    let stcservice = new NotiRemoveService();
    stcservice
      .deleteNotification(paramForService)
      .then((data) => {
        if (data.status == 200) {
          let respData = {};
          if (data.data.entity !== undefined) {
            this.reloadBellData();
          }
        }
      })
      .catch((err) => {
        let errStatusCodeAr = [400, 500]; // allowed error status code
        if (err.response && err.response.data) {
          let erRespData = err.response.data;
          if (errStatusCodeAr.indexOf(erRespData.status) !== -1) {
            let errMsg = erRespData.message
              ? erRespData.message
              : "Some technical problem occurred.";
            console.log(errMsg);
          }
        }
      })
      .finally(() => {});
  };

  callToRemoveAllNoti = (paramObjData) => {
    let paramForService = { paramToPost: {} };
    //console.log(" called callToRemoveAllNoti===")
    this.callServiceToRemoveAll(paramForService);
  };

  callServiceToRemoveAll = (paramForService) => {
    let stcservice = new NotiAllRemoveService();
    stcservice
      .deleteNotification(paramForService)
      .then((data) => {
        if (data.status == 200) {
          let respData = {};
          if (data.data.entity !== undefined) {
            this.reloadBellData();
          }
        }
      })
      .catch((err) => {
        let errStatusCodeAr = [400, 500]; // allowed error status code
        if (err.response && err.response.data) {
          let erRespData = err.response.data;
          if (errStatusCodeAr.indexOf(erRespData.status) !== -1) {
            let errMsg = erRespData.message
              ? erRespData.message
              : "Some technical problem occurred.";
            console.log(errMsg);
          }
        }
      })
      .finally(() => {
        this.isComponentLoaded &&
          this.setState({ removeAllNotiButtonFlag: false });
      });
  };

  // trackNotiForCloseSplit = (otherParam) =>{
  //   let notiType =  otherParam.notiType!==undefined? otherParam.notiType: "";
  //   let trigerredFrom =  otherParam.trigerredFrom!==undefined? otherParam.trigerredFrom: "";
  //   if(notiType=="CLOSE_SPLITSCREEN"){
  //       let closeSplitHeaderCallFrm = ["BELL_NOTIFICATION_MENU"];
  //       if(closeSplitHeaderCallFrm.indexOf(trigerredFrom)!=-1){
  //               console.log("trigerredFrom=>>",trigerredFrom);
  //       }

  //   }
  // }

  /**
   * BellNotificationList
   * Function name : render
   * Author        :
   * Created Date  :
   * Purpose       : lifecycle method
   * Params        :
   **/
  render() {
    return (
      <>
        {/* <NotiEmitTrack callbackfuncforcommontrackofnoti={this.trackNotiForCloseSplit} />  */}
        <div className="notification_sec_bg">
          <button
            disabled={this.state.bellButtonFlag}
            className="dropdown-toggle btn_appearance_none bell_drop_btn"
            type="button"
            //  data-toggle= "dropdown"

            onClick={(evn) => {
              evn.preventDefault();
              let curnotiListOpenFlag = this.state.notiListOpenFlag;
              if (curnotiListOpenFlag == false) {
                // before opening list change bellButtonFlag to true
                this.isComponentLoaded &&
                  this.setState({ bellButtonFlag: true });
              }
              this.toggleNotiList({});
            }}
          >
            <div className="notification_icon">
              <img src={getBaseUrl() + "assets/images/alarm.png"} />
              {this.state.totalUnreadNotificationCount !== null &&
                this.state.totalUnreadNotificationCount > 0 && (
                  <div className="left_mainHeader_notiTag">
                    {this.state.totalUnreadNotificationCount !== null
                      ? this.state.totalUnreadNotificationCount
                      : 0}
                  </div>
                )}
            </div>
          </button>
          <div
            ref={this.menuDropDownDivOrigRef}
            className={`dropdown-menu dropdown-menu-right notification_drop_menu ${
              this.state.notiListOpenFlag ? "show" : ""
            }`}
          >
            <input
              style={{ opacity: "0", position: "absolute" }}
              type="checkbox"
              key={"trackingchkbxfornotilist1"}
              ref={this.menuDropDownDivRef}
              onBlur={(evn) => {
                evn.preventDefault();
                setTimeout(() => {
                  console.log(" blur also called");
                  this.closeNotiListOnBlur({});
                }, 950);
              }}
            />
            <div className="notifi_drop_boxBg">
              <div className="notifi_drop_header">
                <div className="notiDrop_head_btn">
                  {this.state.listData.length > 0 &&
                    this.state.totalUnreadNotificationCount !== null &&
                    this.state.totalUnreadNotificationCount > 0 && (
                      <button
                        disabled={this.state.markAllReadButtonFlag}
                        onClick={(evn) => {
                          evn.preventDefault();
                          this.isComponentLoaded &&
                            this.setState(
                              { markAllReadButtonFlag: true },
                              () => {
                                this.callToMakeAllNotiRead({});
                              }
                            );
                        }}
                        className="btn_appearance_none greenText_btn notiDrop_headBtn"
                      >
                        Mark all as read
                      </button>
                    )}
                </div>
              </div>

              <div className="notifi_drop_body">
                <div className="notifi_drop_scroll_sec mCustomScrollbar height_400">
                  <Scrollbars
                    ref={this.scrollerRef}
                    style={{ height: 400 }}
                    autoHeightMin={400}
                    renderView={(props) => (
                      <div
                        {...props}
                        className="view bellnotilistscrollcustomclass"
                      />
                    )}
                    onScrollStop={this.trackScroll}
                  >
                    {/* === notifi drio scroll ===  */}
                    <ul>
                      {this.getListStructure()}

                      <div className=" text-center ">
                        {this.state.loaderFlag ? this.loadLoader() : null}
                      </div>
                    </ul>
                    {/* === notifi drio scroll ===  */}
                  </Scrollbars>

                  <div className="clearboth" />
                </div>
              </div>

              <div className="notifi_drop_footer">
                {this.state.listData.length > 0 && (
                  <button
                    onClick={(evn) => {
                      evn.preventDefault();
                      this.isComponentLoaded &&
                        this.setState({ removeAllNotiButtonFlag: true }, () => {
                          this.callToRemoveAllNoti({});
                        });
                    }}
                    className="btn_appearance_none notiDrop_footerBtn"
                  >
                    Clear all messages
                  </button>
                )}
              </div>

              <div className="clearboth" />
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(BellNotificationList);
