/**
 * ProfileKyc
 * Page/Class name   : ProfileKyc
 * Author            :
 * Created Date      : 7-5-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : render master component
**/
import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';

// scroll 
import { Scrollbars } from 'react-custom-scrollbars';
// Services
import KycInformationFetchService from '../../service/KycInformationFetchService';
import KycDetailSaveService from '../../service/KycDetailSaveService';
import KycDetailFinalSaveService from '../../service/KycDetailFinalSaveService';
import FetchStateService from '../../service/FetchStateService';

// for context
import CommonContext from '../../context/CommonContext';

//Services
//import TaskInvoiceHistoryService from '../../service/TaskInvoiceHistoryService';
//import  CustDate  from '../../../common_utilities/CustDate';
import { commonJsFuncModule as commonJsObj } from '../../../common_utilities/commonjsfunc';


// for realtime
import SockJS from 'sockjs-client';


const validateUrl = commonJsObj.validateUrl;
const validateEmail = commonJsObj.validateEmail;
const validateSpecialCharacterPresence = commonJsObj.validateSpecialCharacterPresence;
const validatePhoneNumberPattern = commonJsObj.validatePhoneNumberPattern;
const validateBlankSpace = commonJsObj.validateBlankSpace;
const setAccessObj = commonJsObj.setAccessObj;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getCcUserId = commonJsObj.getCcUserId;
const getRole = commonJsObj.getRole;

//for realtime websocket url
const sockUrl = "/ws?token="+getTokenFromLocStorage();
//for realtime subscription url to receive notification
const subscUrl1 = "/topic/notification."+getCcUserId();
//for realtime
var Stomp = require('stompjs');

const organisationTypeDropDown = [
  {"key":"","value":"Organisation Type"},
  {"key":"Sole Proprietorship","value":"Sole Proprietorship"},
  {"key":"Unregistered Partnership","value":"Unregistered Partnership"},
  {"key":"Registered Partnership","value":"Registered Partnership"},
  {"key":"Limited Liability Partnership (LLP)","value":"Limited Liability Partnership (LLP)"},
  {"key":"Private Company","value":"Private Company"},
  {"key":"Public Company","value":"Public Company"},
  {"key":"Trust","value":"Trust"},  
  {"key":"Society","value":"Society"},

];

// Individual Founders;
//  Sole Proprietorship; 
//  Unregistered Partnership; 
//  Registered Partnership; 
//  Limited Liability Partnership (LLP);
//   Private Company; 
//   Public Company; 
//   Trust; 
//   Society

class ProfileKyc extends Component {

    isComponentLoaded =  false;
    // for context
    static contextType = CommonContext;

    /**  
     * SplitScreenTaskDocuments
     * Function name : constructor
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : initializing children
     * Params        : props
    **/
    constructor(props) {
      super(props);
      this.children = props.children;
      this.state = {
            "loaderFlag": false,

            "billingAddress": "",
            "billingContactName": "",
            "billingEmailAddress": "",
            "designation": "",
            "firstName": "",
            "gstin": "",
            "lastName": "",
            "organisationAddress": "",
            "organisationBaseEmailAddress": "",
            "organisationIdentificationNumber": "",
            "organisationName": "",
            "organisationType": "",
            "organisationWebsite":"",
            "pan": "",
            "phoneNumber": "",

            "isOrganisationKycVerified":false,
            "isSubmittedForKYC": false,

            "stateOptions": [],
            "organisationRegisteredState":"",

            // error messages
            "billingAddressErMsg": "",
            "billingContactNameErMsg": "",
            "billingEmailAddressErMsg": "",
            "designationErMsg": "",
            "firstNameErMsg": "",
            "gstinErMsg": "",
            "lastNameErMsg": "",
            "organisationAddressErMsg": "",
            "organisationBaseEmailAddressErMsg": "",
            "organisationIdentificationNumberErMsg": "",
            "organisationNameErMsg": "",
            "organisationTypeErMsg": "",
            "organisationWebsiteErMsg":"",
            "panErMsg": "",
            "phoneNumberErMsg": "",
            "stateErMsg": "",


      };
      // for realtime
      this.clientsocket  = Stomp.over(new SockJS(sockUrl));
      
      
     
    }

    /**
     * SplitScreenTaskDocuments
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : lifecycle method
     * Params        :
    **/
    componentDidMount() {
      this.isComponentLoaded = true;

      
      this.getKycInfo();
      // for realtime
      const ref = this;       
      this.clientsocket.connect( {}, function (){
           //console.log("==Yes connected ==");
           
            // now subscribe to 
            this.subscribe(subscUrl1, (message) =>{
              // called when the client receives a STOMP message from the server
              ref.callbackSubscUrl1(message);
  
            });
 
         
         });
      
      
    }

     // for realtime
     callbackSubscUrl1 = (message) =>{
      // called when the client receives a STOMP message from the server
      if (message.body) {
        //console.log("=====got message with body from bell noti compo =>>>",message.body)

        let jsonWsResp = (message.body!==undefined && message.body!==null)?JSON.parse(message.body):null;
        let webSocketTaskInfo = null;
        let webSocketNotificationInfo = null;
        let webSocketCommentInfo = null;
        let notificationId = 0;
        let taskIdFromNoti = 0;
        //taskOwner
        let ccUserIdFromNoti = 0;

        if(jsonWsResp!==null){

           webSocketTaskInfo = (jsonWsResp.webSocketTaskInfo!==undefined && jsonWsResp.webSocketTaskInfo!==null)?jsonWsResp.webSocketTaskInfo:null;

           webSocketNotificationInfo = (jsonWsResp.webSocketNotificationInfo!==undefined && jsonWsResp.webSocketNotificationInfo!==null)?jsonWsResp.webSocketNotificationInfo:null;

           

           webSocketCommentInfo = (jsonWsResp.webSocketCommentInfo!==undefined && jsonWsResp.webSocketCommentInfo!==null)?jsonWsResp.webSocketCommentInfo:null;


           //console.log("==paresed==>> webSocketTaskInfo=>",webSocketTaskInfo," webSocketNotificationInfo==>>",webSocketNotificationInfo, " webSocketCommentInfo==>>",webSocketCommentInfo," typeof(webSocketNotificationInfo=>)",typeof(webSocketNotificationInfo));
           notificationId = webSocketNotificationInfo.notificationId!==undefined?webSocketNotificationInfo.notificationId:0;

          if(webSocketNotificationInfo && webSocketNotificationInfo.userNotificationType!==undefined && notificationId ){
            let userNotificationType = webSocketNotificationInfo!==null && webSocketNotificationInfo.userNotificationType!==undefined && webSocketNotificationInfo.userNotificationType!==null?webSocketNotificationInfo.userNotificationType:"";

            let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;

                //console.log(" found =====>>",userNotificationType," webSocketNotificationInfo==>",webSocketNotificationInfo);
                if(userNotificationType=="KYC"){
                  //setAccessObj({"key":"isKycVerified","keyValue":true});
                  console.log(" KYC received .....");
                  this.getKycInfo();


                }
              

            

          }


        }

      }
    }

   


    /**  
     * SplitScreenTaskDocuments
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {
      this.isComponentLoaded = false;

    }

    /**  
     * SplitScreenTaskDocuments
     * Function name : componentDidUpdate
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentDidUpdate(prevProps){

    }

    getStateOptionsData = (paramObj) =>{
      let luiserv = new FetchStateService();
      luiserv.getStateList({}).then(data =>{
          
      let stateInfoResp = data.data.entity;
      ////console.log(" loggedInUserInfoResp=>",loggedInUserInfoResp);
      this.setStateOfStateOptions(stateInfoResp);

        

          
      }).catch(err=>{
          console.log("Some server related tech problem occured",err);
      });
    }
    setStateOfStateOptions = (respData) =>{
        let stateDataObj = {
          "stateOptions": []
          };

        if(respData){
          stateDataObj.stateOptions  = respData!==undefined && respData!==null?respData:[];
          this.isComponentLoaded && this.setState(stateDataObj,()=>{
          });
  
        }
       
    }

    getKycInfo = () =>{
      let luiserv = new KycInformationFetchService();
      luiserv.getInfo({}).then(data =>{
          
      let loggedInUserInfoResp = data.data.entity;
      ////console.log(" loggedInUserInfoResp=>",loggedInUserInfoResp);
      this.setStateOfKyc(loggedInUserInfoResp);

        

          
      }).catch(err=>{
          console.log("Some server related tech problem occured",err);
      });
    }

    setStateOfKyc = (respData) =>{
      let stateDataObj = {                   
        
        "billingAddress": "",
        "billingContactName": "",
        "billingEmailAddress": "",
        "designation": "",
        "firstName": "",
        "gstin": "",
        "lastName": "",
        "organisationAddress": "",
        "organisationBaseEmailAddress": "",
        "organisationIdentificationNumber": "",
        "organisationName": "",
        "organisationType": "",
        "organisationWebsite":"",
        "pan": "",
        "phoneNumber": "",

        "stateOptions": [],
        "organisationRegisteredState":"",

        "isOrganisationKycVerified":false,
        "isSubmittedForKYC": false

        };

        

        if(respData){

          stateDataObj.billingAddress  = respData.billingAddress!==undefined && respData.billingAddress!==null?respData.billingAddress:"";

          stateDataObj.billingContactName  = respData.billingContactName!==undefined && respData.billingContactName!==null?respData.billingContactName:"";

          stateDataObj.billingEmailAddress  = respData.billingEmailAddress!==undefined && respData.billingEmailAddress!==null?respData.billingEmailAddress:"";

          stateDataObj.designation  = respData.designation!==undefined && respData.designation!==null?respData.designation:"";

          stateDataObj.firstName  = respData.firstName!==undefined && respData.firstName!==null?respData.firstName:"";

          stateDataObj.lastName  = respData.lastName!==undefined && respData.lastName!==null?respData.lastName:"";

          stateDataObj.gstin  = respData.gstin!==undefined && respData.gstin!==null?respData.gstin:"";

          stateDataObj.organisationAddress  = respData.organisationAddress!==undefined && respData.organisationAddress!==null?respData.organisationAddress:"";

          stateDataObj.organisationBaseEmailAddress  = respData.organisationBaseEmailAddress!==undefined && respData.organisationBaseEmailAddress!==null?respData.organisationBaseEmailAddress:"";

          stateDataObj.organisationIdentificationNumber  = respData.organisationIdentificationNumber!==undefined && respData.organisationIdentificationNumber!==null?respData.organisationIdentificationNumber:"";

          stateDataObj.organisationName  = respData.organisationName!==undefined && respData.organisationName!==null?respData.organisationName:"";

          stateDataObj.organisationType  = respData.organisationType!==undefined && respData.organisationType!==null?respData.organisationType:"";

          stateDataObj.organisationWebsite  = respData.organisationWebsite!==undefined && respData.organisationWebsite!==null?respData.organisationWebsite:"";

          stateDataObj.pan  = respData.pan!==undefined && respData.pan!==null?respData.pan:"";

          stateDataObj.phoneNumber  = respData.phoneNumber!==undefined && respData.phoneNumber!==null?respData.phoneNumber:"";

          stateDataObj.isOrganisationKycVerified  = respData.isOrganisationKycVerified!==undefined && respData.isOrganisationKycVerified!==null?respData.isOrganisationKycVerified:false;

          stateDataObj.isSubmittedForKYC  = respData.isSubmittedForKYC!==undefined && respData.isSubmittedForKYC!==null?respData.isSubmittedForKYC:false;

          stateDataObj.organisationRegisteredState  = respData.organisationRegisteredState!==undefined && respData.organisationRegisteredState!==null?respData.organisationRegisteredState.id:"";


          this.isComponentLoaded && this.setState(stateDataObj,()=>{
            setAccessObj({"key":"isKycVerified","keyValue":stateDataObj.isOrganisationKycVerified});
            this.getStateOptionsData({});
            //console.log(" this.state=>>",this.state);
          });
  
        }
       
    }

    handleChange = (e) => {
      this.isComponentLoaded &&  this.setState({[e.target.name]: e.target.value}); 
    }

    callSaveKycDetail = () => {        
      let creds = {                    
        "billingAddress": this.state.billingAddress,
        "billingContactName": this.state.billingContactName,
        "billingEmailAddress": this.state.billingEmailAddress,
        "designation": this.state.designation,
        "firstName": this.state.firstName,
        "gstin": this.state.gstin,
        "lastName": this.state.lastName,
        "organisationAddress": this.state.organisationAddress,
        "organisationBaseEmailAddress": this.state.organisationBaseEmailAddress,
        "organisationIdentificationNumber": this.state.organisationIdentificationNumber,
        "organisationName": this.state.organisationName,
        "organisationType": this.state.organisationType,
        "organisationWebsite":this.state.organisationWebsite,
        "pan": this.state.pan,
        "phoneNumber": this.state.phoneNumber,
        "organisationRegisteredState": this.state.organisationRegisteredState,
                };
    

      //console.log(" hit draft save api==>>",creds);
      //return false;
      

      let lserv = new KycDetailSaveService();
      lserv.saveDetail(creds).then(data =>{
          ////console.log("data=>",data,data.status);        
          if(data.status==200){              
              
            let paramSuc = {};
            paramSuc.notiMsg = "Updated successfully";
            paramSuc.successOrError = 1; // 0 or 1
            paramSuc.dismissDuration = 1500; // generally 1500
            paramSuc.showCloseButton = false;
            this.props.showsuccessorerrormessage(paramSuc);
      
                          
          }                   
      }).catch(err=>{
        let errStatusCodeAr = [400,500]; // allowed error status code
        if(err.response && err.response.data){
            let erRespData = err.response.data;
            if(errStatusCodeAr.indexOf(erRespData.status) !== -1){         
                let errMsg = erRespData.message?erRespData.message:"Some technical problem occurred from server. Please try again.";

                
                let paramEr = {};
                paramEr.notiMsg = errMsg;
                paramEr.successOrError = 0; // 0 or 1
                paramEr.dismissDuration = 1500; // generally 1500
                paramEr.showCloseButton = false;
                this.props.showsuccessorerrormessage(paramEr);

          }
        }        
  
      }).finally(()=>{
        setTimeout(()=>{
          this.isComponentLoaded && this.setState({loaderFlag:false},()=>{
            });
        },1500);
        
      });
  
    }

    callSaveFinalKycDetail = () => {        
      let creds = {                    
        "billingAddress": this.state.billingAddress,
        "billingContactName": this.state.billingContactName,
        "billingEmailAddress": this.state.billingEmailAddress,
        "designation": this.state.designation,
        "firstName": this.state.firstName,
        "gstin": this.state.gstin,
        "lastName": this.state.lastName,
        "organisationAddress": this.state.organisationAddress,
        "organisationBaseEmailAddress": this.state.organisationBaseEmailAddress,
        "organisationIdentificationNumber": this.state.organisationIdentificationNumber,
        "organisationName": this.state.organisationName,
        "organisationType": this.state.organisationType,
        "organisationWebsite":this.state.organisationWebsite,
        "pan": this.state.pan,
        "phoneNumber": this.state.phoneNumber,
        "organisationRegisteredState": this.state.organisationRegisteredState,
                };
    

      //console.log(" hit final save api==>>",creds);
      //return false;

      let lserv = new KycDetailFinalSaveService();
      lserv.saveDetail(creds).then(data =>{
          ////console.log("data=>",data,data.status);        
          if(data.status==200){        
            
              
            let paramSuc = {};
            paramSuc.notiMsg = "Updated successfully";
            paramSuc.successOrError = 1; // 0 or 1
            paramSuc.dismissDuration = 1500; // generally 1500
            paramSuc.showCloseButton = false;
            this.props.showsuccessorerrormessage(paramSuc);

            this.getKycInfo();
      
                          
          }                   
      }).catch(err=>{
        let errStatusCodeAr = [400,500]; // allowed error status code
        if(err.response && err.response.data){
            let erRespData = err.response.data;
            if(errStatusCodeAr.indexOf(erRespData.status) !== -1){         
                let errMsg = erRespData.message?erRespData.message:"Some technical problem occurred from server. Please try again.";

                
                let paramEr = {};
                paramEr.notiMsg = errMsg;
                paramEr.successOrError = 0; // 0 or 1
                paramEr.dismissDuration = 1500; // generally 1500
                paramEr.showCloseButton = false;
                this.props.showsuccessorerrormessage(paramEr);

          }
        }        
  
      }).finally(()=>{
        setTimeout(()=>{
          this.isComponentLoaded && this.setState({loaderFlag:false},()=>{
            });
        },1500);
        
      });
  
    }

   



    custValidate = ()=>{     
       

        let billingAddressErMsg = "";
        let billingContactNameErMsg = "";
        let billingEmailAddressErMsg=  "";
        let designationErMsg= "";
        let firstNameErMsg=  "";
        let gstinErMsg=  "";
        let lastNameErMsg=  "";
        let organisationAddressErMsg=  "";
        let organisationBaseEmailAddressErMsg=  "";
        let organisationIdentificationNumberErMsg=  "";
        let organisationNameErMsg=  "";
        let organisationTypeErMsg=  "";
        let organisationWebsiteErMsg= "";
        let panErMsg=  "";
        let phoneNumberErMsg=  "";
        let stateErMsg=  "";

        

        let totErrCountTrack = [];   

        if(!(this.state.firstName)||(this.state.firstName.length==0)||validateBlankSpace(this.state.firstName)==true){
          firstNameErMsg = "First name is required.";          
            totErrCountTrack.push(firstNameErMsg);   
          }

        if(!(this.state.lastName)||(this.state.lastName.length==0)||validateBlankSpace(this.state.lastName)==true){
          lastNameErMsg = "Last name is required.";          
            totErrCountTrack.push(lastNameErMsg);   
          }

        if(!(this.state.billingAddress)||(this.state.billingAddress.length==0)||validateBlankSpace(this.state.billingAddress)==true){
            billingAddressErMsg = "Billing address is required.";          
            totErrCountTrack.push(billingAddressErMsg);   
          }

        if(!(this.state.organisationType)||(this.state.organisationType.length==0)||validateBlankSpace(this.state.organisationType)==true){
          organisationTypeErMsg = "Organisation type is required";          
            totErrCountTrack.push(organisationTypeErMsg);   
          }

        if(!(this.state.designation)||(this.state.designation.length==0)||validateBlankSpace(this.state.designation)==true){
          designationErMsg = "Designation is required";          
            totErrCountTrack.push(designationErMsg);   
          }

        if(!(this.state.organisationRegisteredState)||(this.state.organisationRegisteredState.length==0)||validateBlankSpace(this.state.organisationRegisteredState)==true){
          stateErMsg = "State is required";          
            totErrCountTrack.push(stateErMsg);   
          }
        

        // if(!(this.state.organisationName)||(this.state.organisationName.length==0)||validateBlankSpace(this.state.organisationName)==true){
        //   organisationNameErMsg = "Organisation full name is required";          
        //     totErrCountTrack.push(organisationNameErMsg);   
        //   }
        
        if(!(this.state.organisationIdentificationNumber)||(this.state.organisationIdentificationNumber.length==0)||validateBlankSpace(this.state.organisationIdentificationNumber)==true){
          organisationIdentificationNumberErMsg = "Company identification no. / registration no. is required";          
            totErrCountTrack.push(organisationIdentificationNumberErMsg);   
          }

        if(!(this.state.organisationAddress)||(this.state.organisationAddress.length==0)||validateBlankSpace(this.state.organisationAddress)==true){
          organisationAddressErMsg = "Organisation address is required";          
            totErrCountTrack.push(organisationAddressErMsg);   
          }

        if(!(this.state.organisationWebsite)||(this.state.organisationWebsite.length==0)||validateBlankSpace(this.state.organisationWebsite)==true){
          organisationWebsiteErMsg = "Organisation website is required";          
            totErrCountTrack.push(organisationWebsiteErMsg);   
        }
        else {
         
          if(validateUrl(this.state.organisationWebsite)==false){
            organisationWebsiteErMsg = "Organisation website url is not proper ";          
            totErrCountTrack.push(organisationWebsiteErMsg); 
          }
        }

        // if(!(this.state.organisationBaseEmailAddress)||(this.state.organisationBaseEmailAddress.length==0)||validateBlankSpace(this.state.organisationBaseEmailAddress)==true){
        //   organisationBaseEmailAddressErMsg = "Organisation based email-id is required";          
        //     totErrCountTrack.push(organisationBaseEmailAddressErMsg);   
        //   }else{
            
        //     if(validateEmail(this.state.organisationBaseEmailAddress)==false){
        //       organisationBaseEmailAddressErMsg = "Organisation based email-id is not proper ";          
        //     totErrCountTrack.push(organisationBaseEmailAddressErMsg); 
        //   }
        //   }
        
        if(!(this.state.phoneNumber)||(this.state.phoneNumber.length==0)||validateBlankSpace(this.state.phoneNumber)==true){
          phoneNumberErMsg = "Phone number is required";          
            totErrCountTrack.push(phoneNumberErMsg);   
          }
          else if(validatePhoneNumberPattern(this.state.phoneNumber)==false){
              phoneNumberErMsg = "Phone number is in improper format";          
            totErrCountTrack.push(phoneNumberErMsg); 
          }

        if(!(this.state.billingContactName)||(this.state.billingContactName.length==0)||validateBlankSpace(this.state.billingContactName)==true){
          billingContactNameErMsg = "Billing contact name is required";          
            totErrCountTrack.push(billingContactNameErMsg);   
          }

        if(!(this.state.billingEmailAddress)||(this.state.billingEmailAddress.length==0)||validateBlankSpace(this.state.billingEmailAddress)==true){
          billingEmailAddressErMsg = "Billing contact organisation email-id is required";          
            totErrCountTrack.push(billingEmailAddressErMsg);   
          }else{
            
            if(validateEmail(this.state.billingEmailAddress)==false){
              billingEmailAddressErMsg = "Billing contact organisation email-id is not proper ";          
            totErrCountTrack.push(billingEmailAddressErMsg); 
          }
          }

        if(!(this.state.pan)||(this.state.pan.length==0)||validateBlankSpace(this.state.pan)==true){
          panErMsg = "PAN is required";          
            totErrCountTrack.push(panErMsg);   
          }else if(validateSpecialCharacterPresence(this.state.pan)==true){
            panErMsg = "Special character is not allowed ";          
            totErrCountTrack.push(panErMsg); 
          }
          else if((this.state.pan.length!=10)){
            panErMsg = "PAN should be of length 10 character ";          
            totErrCountTrack.push(panErMsg); 
          }

        if(!(this.state.gstin)||(this.state.gstin.length==0)||validateBlankSpace(this.state.gstin)==true){
          gstinErMsg = "GSTIN is required";          
            totErrCountTrack.push(gstinErMsg);   
          }else if(validateSpecialCharacterPresence(this.state.gstin)==true){
              gstinErMsg = "Special character is not allowed ";          
              totErrCountTrack.push(gstinErMsg); 
          }
          else if((this.state.gstin.length!=15)){
            gstinErMsg = "GSTIN should be of length 15 character ";          
            totErrCountTrack.push(gstinErMsg); 
          }
          else if(panErMsg==""){
            if((this.state.gstin).search(this.state.pan)==-1){
              gstinErMsg = "GSTIN should be of proper format "; 
              totErrCountTrack.push(gstinErMsg); 
            }
                     
            
          }
         

          let stateErData = {
            "billingAddressErMsg": billingAddressErMsg,
            "billingContactNameErMsg": billingContactNameErMsg,
            "billingEmailAddressErMsg": billingEmailAddressErMsg,
            "designationErMsg": designationErMsg,
            "firstNameErMsg": firstNameErMsg,
            "gstinErMsg": gstinErMsg,
            "lastNameErMsg": lastNameErMsg,
            "organisationAddressErMsg": organisationAddressErMsg,
            "organisationBaseEmailAddressErMsg": organisationBaseEmailAddressErMsg,
            "organisationIdentificationNumberErMsg": organisationIdentificationNumberErMsg,
            "organisationNameErMsg": organisationNameErMsg,
            "organisationTypeErMsg": organisationTypeErMsg,
            "organisationWebsiteErMsg": organisationWebsiteErMsg,
            "panErMsg": panErMsg,
            "phoneNumberErMsg": phoneNumberErMsg,
            "stateErMsg":stateErMsg

          }
        
          this.isComponentLoaded && this.setState(stateErData);
        

          if(totErrCountTrack.length>0){          
                return false;
          }
          return true;
    }

    chkValidationBeforeProceed = () =>{

        this.isComponentLoaded && this.setState({"loaderFlag":true});
        const isValid = this.custValidate();   
        if(isValid){
            this.callSaveFinalKycDetail();
        }else{

          let paramSuc = {};
          paramSuc.notiMsg = "Some fields contain improper data";
          paramSuc.successOrError = 0; // 0 or 1
          paramSuc.dismissDuration = 2000; // generally 1500
          paramSuc.showCloseButton = false;
          this.props.showsuccessorerrormessage(paramSuc);

          setTimeout(()=>{
            this.isComponentLoaded && this.setState({"loaderFlag":false},()=>{
              //console.log("inside chkValidationBeforeProceed this.state=>>", this.state);
            });
          },2500);
          
        } 

    }

    chkValidationBeforeDraftProceed = () =>{
      /*
        this.isComponentLoaded && this.setState({"loaderFlag":true});
        
        const isValid = this.custValidate();   
        
        if(isValid){
            this.callSaveKycDetail();
        }else{

          let paramSuc = {};
          paramSuc.notiMsg = "Some fields contain improper data";
          paramSuc.successOrError = 0; // 0 or 1
          paramSuc.dismissDuration = 2000; // generally 1500
          paramSuc.showCloseButton = false;
          this.props.showsuccessorerrormessage(paramSuc);

          setTimeout(()=>{
            this.isComponentLoaded && this.setState({"loaderFlag":false},()=>{
              //console.log("inside chkValidationBeforeDraftProceed this.state=>>", this.state);
            });
          },2500);
          
        } 
        */
       this.isComponentLoaded && this.setState({"loaderFlag":true},()=>{
        this.callSaveKycDetail();
      });

    }
  

    /**  
     * ProfileKyc
     * Function name : render
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() {  

      return (
        <>
          
          <div  className="dashboard_cont_area tasklist_cont_areabg setting_cont_areabg setting_main_body_section">
              {/* ===  DashBoard Main Body Start ===  */}
              <div className="cutom_container cutom_container_userSetting">
                <div className="create_task_area userSetting_area">
                  {/* ===  task Listing filter start === */}
                  {/* ===  task Listing filter end === */}
                  {/* ===========  Setting Page main body start =============   */}
                  {/* ===  task Listing start start === */}
                  <div className="task_area userSetting_task_area">
                    {/* === User Setting Section / Task Listing Section Start === */}
                    {/* ============== Start =================   */}
                    <div className="userSetting_section">
                      {/* -----  scroll adjust for settting screens start -----   */}
                      <div className="task_listing_scrollbg task_listing_scrollbg_proSetting">
                        {/* ==============   User Setting Section Start =================   */}
                        <div className="userSetting_area_bg">
                          {/* =====  User KYC page body start =====  */}
                          <div className="userSetting_area user_page_area">
                            <div className="userSetting_scroll">
                              {/* ====  User KYC start ===  */}
                              <div className="user_kyc_bg">
                                <div className="user_kyc_box">
                                 
                                  <div className="user_kyc_messpageBox_bg">
                                    {this.state.isSubmittedForKYC &&  this.state.isOrganisationKycVerified==false && (
                                   
                                    <div className="user_kyc_messpageBox">
                                      <p>
                                        <b>Verification Pending.</b> You will be able to
                                        create tasks once your details have been verified
                                        by the admin.Details entered in the KYC section is only visible to you.                                        
                                      </p>
                                    </div>
                                   )}
                                  
                                  </div>
                                  {/* === white box start ===  */}
                                  <div className="user_kyc_whiteBox">
                                    {/* ===  */}
                                    <div className="kyc_box_title">
                                      <div className="kyc_box_title_block">
                                        <h4>KYC details</h4>
                                        <div className="kyc_box_title_icon pointer_none">
                                          <span className="top_sug_left">
                                            <span
                                              className="input_tooltip btn_tooltip"
                                              aria-label="Demo ToolTip Text, Demo ToolTip Text, Demo ToolTip Text, Demo ToolTip Text"
                                              data-balloon-pos="down"
                                            >
                                              {/* <i
                                                className={`fa ${(( this.state.isSubmittedForKYC === false) || ( this.state.isSubmittedForKYC === true ||  this.state.isOrganisationKycVerified === true )  )?"fa-lock":"fa-lock"}`}
                                                aria-hidden="true"
                                              /> */}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    {/* ===  */}
                                    {/* ====  subscription scroll start ===  */}
                                    <div className="kyc_scroll_bg">
                                      <div className="react_use_set_scroll user_scroll mCustomScrollbar height_450">
                                        {/* ============== */}
                                        <Scrollbars 
                                      ref={this.scrollerRef}
                                      style={{ height: 450 }}
                                      autoHeightMin={450}
                                      renderView={props => <div {...props} className="view proflkycscrollcustomclass"/>}

                                     
                                          >
                                        <div className="s_midbox s_midbox_kyc">
                                          <div className="main_form_box">
                                            {/* ======= kyc form field start ======= */}
                                            <div className="form_block form_block_half">
                                              <div className="panel50 panel_half paddingright15 form__group_normar">
                                                <label
                                                  htmlFor="name"
                                                  className="form__label"
                                                >
                                                  <b>First name*</b>
                                                </label>
                                                <div className={`form__group field ${this.state.firstNameErMsg?"form_group_error":""}`}>
                                                  <input
                                                    className="form__field"
                                                    placeholder="Enter"
                                                    type="text"
                                                    name="firstName"
                                                    value={this.state.firstName}
                                                    onChange={this.handleChange}
                                                  />
                                                  <span className="error_span">
                                                   {this.state.firstNameErMsg}
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="panel50 panel_half paddingleft15 form__group_normar">
                                                <label
                                                  htmlFor="name"
                                                  className="form__label"
                                                >
                                                  <b>Last name*</b>
                                                </label>
                                                <div className={`form__group field ${this.state.lastNameErMsg?"form_group_error":""}`}>
                                                  <input
                                                    className="form__field"
                                                    placeholder="Enter"
                                                    type="text"
                                                    name="lastName"
                                                    value={this.state.lastName}
                                                    onChange={this.handleChange}
                                                  />
                                                  <span className="error_span">
                                                  {this.state.lastNameErMsg}
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="clearboth" />
                                            </div>
                                            <div className="form_block form_block_full">
                                              <div className="panel100 form__group_normar">
                                                <label
                                                  htmlFor="name"
                                                  className="form__label"
                                                >
                                                  <b>Designation*</b>
                                                </label>
                                                <div className={`form__group field ${this.state.designationErMsg?"form_group_error":""}`}>
                                                  <input
                                                    className="form__field"
                                                    placeholder="Enter"
                                                    type="text"
                                                    name="designation"
                                                    value={this.state.designation}
                                                    onChange={this.handleChange}
                                                  />
                                                  <span className="error_span">
                                                  {this.state.designationErMsg}
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="clearboth" />
                                            </div>
                                            <div className="form_block form_block_half">
                                              <div className="panel50 panel_half paddingright15 form__group_normar">
                                                <label
                                                  htmlFor="name"
                                                  className="form__label"
                                                >
                                                  <b>Organisation full name</b>
                                                </label>
                                                <div className={`form__group field ${this.state.organisationNameErMsg?"form_group_error":""}`}>
                                                  <div class="input_datashow">
                                                    <p>{this.state.organisationName}</p>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="panel50 panel_half paddingleft15 form__group_normar">
                                                <label
                                                  htmlFor="name"
                                                  className="form__label"
                                                >
                                                  <b>Type of organisation*</b>
                                                </label>
                                                <div className={`form__group field ${this.state.organisationTypeErMsg?"form_group_error":""}`}>
                                                  <select
                                                  onChange={ this.handleChange } name="organisationType"
                                                    className="selectpicker dropup form__field"
                                                    data-header="Organisation Size"
                                                    value={this.state.organisationType}
                                                  >
                                                      {Object.keys(organisationTypeDropDown).length > 0 &&
                                                        Object.keys(organisationTypeDropDown).map((e, i) => (
                                                            <option key={i} value={organisationTypeDropDown[e].key} > {organisationTypeDropDown[e].value}</option>
                                                        ))}
                                                  </select>
                                                  <span className="error_span">
                                                  {this.state.organisationTypeErMsg}
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="clearboth" />
                                            </div>
                                            <div className="form_block form_block_half">
                                              <div className="panel50 panel_half paddingright15 form__group_normar">
                                                <label
                                                  htmlFor="name"
                                                  className="form__label"
                                                >
                                                  <b>Country</b>
                                                </label>
                                                <div className={`form__group field `}>
                                                  
                                                  <select
                                                  name="organisationRegisteredState"
                                                    className="selectpicker dropup form__field"
                                                    data-header="Organisation Size"
                                                    
                                                  >
                                                      <option key={"stateoptnskey01"} value="" >India</option>
                                                      <option disabled={true} key={"stateoptnskey01"} value="" >Other</option>
                                                  </select>
                                                  <span className="error_span">
                                                  
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="panel50 panel_half paddingleft15 form__group_normar">
                                                <label
                                                  htmlFor="name"
                                                  className="form__label"
                                                >
                                                  <b>State*</b>
                                                </label>
                                                <div className={`form__group field ${this.state.stateErMsg?"form_group_error":""}`}>
                                                  <select
                                                  onChange={ this.handleChange } name="organisationRegisteredState"
                                                    className="selectpicker dropup form__field"
                                                    data-header="Organisation Size"
                                                    value={this.state.organisationRegisteredState}
                                                  >
                                                      <option key={"stateoptnskey01"} value="" >Select</option>
                                                      {Object.keys(this.state.stateOptions).length > 0 &&
                                                        Object.keys(this.state.stateOptions).map((e, i) => (
                                                            <option key={i} value={this.state.stateOptions[e].id} > {this.state.stateOptions[e].name}</option>
                                                        ))}
                                                  </select>
                                                  <span className="error_span">
                                                  {this.state.stateErMsg}
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="clearboth" />
                                            </div>
                                            
                                            <div className="form_block form_block_full">
                                              <div className="panel100 form__group_normar">
                                                <label
                                                  htmlFor="name"
                                                  className="form__label"
                                                >
                                                  <b>
                                                    Company Identification No. /
                                                    Registration No.*
                                                  </b>
                                                </label>
                                                <div className={`form__group field ${this.state.organisationIdentificationNumberErMsg?"form_group_error":""}`}>
                                                  <input
                                                    className="form__field"
                                                    placeholder="Enter"
                                                    type="text"
                                                    name="organisationIdentificationNumber"
                                                    value={this.state.organisationIdentificationNumber}
                                                    onChange={this.handleChange}
                                                  />
                                                  <span className="error_span">
                                                    {this.state.organisationIdentificationNumberErMsg}
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="clearboth" />
                                            </div>
                                            <div className="form_block form_block_full">
                                              <div className="panel100 form__group_normar">
                                                <label
                                                  htmlFor="name"
                                                  className="form__label"
                                                >
                                                  <b>Organisation Address*</b>
                                                </label>
                                                <div className={`form__group field ${this.state.organisationAddressErMsg?"form_group_error":""}`}>
                                                  <textarea
                                                    placeholder="Enter address"
                                                    name="organisationAddress"
                                                    value={this.state.organisationAddress}
                                                    onChange={this.handleChange}
                                                  />
                                                  <span className="error_span">
                                                  {this.state.organisationAddressErMsg}
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="clearboth" />
                                            </div>
                                            <div className="form_block form_block_half">
                                              <div className="panel50 panel_half paddingright15 form__group_normar">
                                                <label
                                                  htmlFor="name"
                                                  className="form__label"
                                                >
                                                  <b>Organisation website</b>
                                                </label>
                                                <div className={`form__group field ${this.state.organisationWebsiteErMsg?"form_group_error":""}`}>
                                                  <input
                                                    className="form__field"
                                                    placeholder="Enter"
                                                    type="text"
                                                    name="organisationWebsite"
                                                    value={this.state.organisationWebsite}
                                                    onChange={this.handleChange}
                                                  />
                                                  <span className="error_span">
                                                  {this.state.organisationWebsiteErMsg}
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="panel50 panel_half paddingleft15 form__group_normar">
                                                <label
                                                  htmlFor="name"
                                                  className="form__label"
                                                >
                                                  <b>Organisation based email ID</b>
                                                </label>
                                                <div className={`form__group field ${this.state.organisationBaseEmailAddressErMsg?"form_group_error":""}`}>
                                                  <div class="input_datashow">
                                                      <p>
                                                      {this.state.organisationBaseEmailAddress}
                                                      </p>
                                                    </div>     
                                                </div>
                                              </div>
                                              <div className="clearboth" />
                                            </div>
                                            <div className="form_block form_block_half">
                                              <div className="panel50 panel_half paddingright15 form__group_normar">
                                                <label
                                                  htmlFor="name"
                                                  className="form__label"
                                                >
                                                  <b>Phone number*</b>
                                                </label>
                                                <div className={`form__group field ${this.state.phoneNumberErMsg?"form_group_error":""}`}>
                                                  <input
                                                    className="form__field"
                                                    placeholder="Enter"
                                                    type="text"
                                                    name="phoneNumber"
                                                    value={this.state.phoneNumber}
                                                    onChange={this.handleChange}
                                                  />
                                                  <span className="error_span">
                                                  {this.state.phoneNumberErMsg}
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="panel50 panel_half paddingleft15 form__group_normar">
                                                <label
                                                  htmlFor="name"
                                                  className="form__label"
                                                >
                                                  <b>Billing contact name*</b>
                                                </label>
                                                <div className={`form__group field ${this.state.billingContactNameErMsg?"form_group_error":""}`}>
                                                  <input
                                                    className="form__field"
                                                    placeholder="Enter"
                                                    type="text"
                                                    name="billingContactName"
                                                    value={this.state.billingContactName}
                                                    onChange={this.handleChange}
                                                  />
                                                  <span className="error_span">
                                                  {this.state.billingContactNameErMsg}
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="clearboth" />
                                            </div>
                                            <div className="form_block form_block_full">
                                              <div className="panel100 form__group_normar">
                                                <label
                                                  htmlFor="name"
                                                  className="form__label"
                                                >
                                                  <b>
                                                    Billing contact organisation email ID*
                                                  </b>
                                                </label>
                                                <div className={`form__group field ${this.state.billingEmailAddressErMsg?"form_group_error":""}`}>
                                                  <input
                                                    className="form__field"
                                                    placeholder="Enter"
                                                    type="text"
                                                    name="billingEmailAddress"
                                                    value={this.state.billingEmailAddress}
                                                    onChange={this.handleChange}
                                                  />
                                                  <span className="error_span">
                                                  {this.state.billingEmailAddressErMsg}
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="clearboth" />
                                            </div>
                                            <div className="form_block form_block_full">
                                              <div className="panel100 form__group_normar">
                                                <label
                                                  htmlFor="name"
                                                  className="form__label"
                                                >
                                                  <b>Billing address*</b>
                                                </label>
                                                <div className={`form__group field ${this.state.billingAddressErMsg?"form_group_error":""}`}>
                                                  <textarea
                                                    placeholder="Enter address"
                                                    name="billingAddress"
                                                    value={this.state.billingAddress}
                                                    onChange={this.handleChange}
                                                  />
                                                  <span className="error_span">
                                                   {this.state.billingAddressErMsg}
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="clearboth" />
                                            </div>
                                            <div className="form_block form_block_half">
                                              <div className="panel50 panel_half paddingright15 form__group_normar">
                                                <label
                                                  htmlFor="name"
                                                  className="form__label"
                                                >
                                                  <b>PAN*</b>
                                                </label>
                                                <div className={`form__group field ${this.state.panErMsg?"form_group_error":""}`}>
                                                  <input
                                                    className="form__field"
                                                    placeholder="Enter"
                                                    type="text"
                                                    name="pan"
                                                    value={this.state.pan}
                                                    onChange={this.handleChange}
                                                  />
                                                  <span className="error_span">
                                                  {this.state.panErMsg}
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="panel50 panel_half paddingleft15 form__group_normar">
                                                <label
                                                  htmlFor="name"
                                                  className="form__label"
                                                >
                                                  <b>GSTIN*</b>
                                                </label>
                                                <div className={`form__group field ${this.state.gstinErMsg?"form_group_error":""}`}>
                                                  <input
                                                    className="form__field"
                                                    placeholder="Enter"
                                                    type="text"
                                                    name="gstin"
                                                    value={this.state.gstin}
                                                    onChange={this.handleChange}
                                                  />
                                                  <span className="error_span">
                                                  {this.state.gstinErMsg}
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="clearboth" />
                                            </div>
                                            {/* ======= kyc form field end ======= */}
                                          </div>
                                        </div>
                                        </Scrollbars>
                                        {/* ============== */}
                                      </div>
                                    </div>
                                    {/* ====  subscription scroll end ===  */}
                                  </div>
                                  {/* === white box end ===  */}
                                  {/* === white box start ===  */}
                                  {/* === white box end ===  */}
                                </div>
                              </div>
                              {/* ====  User KYC end ===  */}
                            </div>
                          </div>
                          {/* =====  User KYC page body end =====  */}
                          {/* =====  Virtual account page body start =====  */}
                          {/* =====  Virtual account page page body end =====  */}
                        </div>
                        {/* ==============   User Setting Section end =================   */}
                      </div>
                      {/* -----  scroll adjust for settting screens end -----   */}
                    </div>
                    {/* ============== end =================   */}
                    {/* === User Setting Section / Task Listing Section end === */}
                  </div>
                  {/* ===  task Listing start end === */}
                  {/* ===========  Setting Page main body end =============   */}
                </div>
              </div>
              {/* ===  DashBoard Main Body End ===  */}
          </div>
          {/* ===  DashBoard Main Body Footer Start ===  */}
          {/* User Setting footer strat */}
          {/* *****  Put as Dispaly: None ***** */}
          <div className="task_details_btn_area invoPay_footer kyc_footer">
            {/* {prev condition to show buttons=>> (( this.state.isSubmittedForKYC === false) || ( this.state.isSubmittedForKYC === true ||  this.state.isOrganisationKycVerified === true )  ) && (getRole()=="CC_ADMIN")} */}
          {((this.state.isSubmittedForKYC === false)||(this.state.isSubmittedForKYC === true && this.state.isOrganisationKycVerified === true)) && (getRole()=="CC_ADMIN") && (
            
            <div className="btn_fld">
                <div className="panel50">
                  <button disabled={this.state.loaderFlag}  onClick={(evn)=>{ evn.preventDefault();
                  this.chkValidationBeforeDraftProceed();
                    
                    }} className="btn_appearance_none green_btn">
                    <span>Save as draft</span>
                  </button>
                </div>
                <div className="panel50">
                  <div className="cancel_continue_btn">
                    <button
                    onClick={(evn)=>{ evn.preventDefault();
                      this.props.history.push("/dashboard");  
                  }}
                      type="button"
                      data-dismiss="modal"
                      className="btn_appearance_none modal_text_btn"
                    >
                      <span>Cancel</span>
                    </button>
                    <button disabled={this.state.loaderFlag} onClick={(evn)=>{ evn.preventDefault();
                    this.chkValidationBeforeProceed();
                    }} className="btn_appearance_none sub_grd_btn">
                      <span>Submit</span>
                    </button>
                  </div>
                </div>
                <div className="clearboth" />
            </div>

          )}
          </div>
          {/* *****  Put as Dispaly: None ***** */}

                  
        </>
      );
    }
}
export default withRouter(ProfileKyc);