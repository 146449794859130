/**
 * SplitScreenTaskInvoiceHistory
 * Page/Class name   : SplitScreenInvoiceView
 * Author            :
 * Created Date      : 7-5-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : render master component
 **/
import React, { Component } from "react";

import CustReactBootstrapModal from "./CustReactBootstrapModal";

import TaskInvoiceShare from "./TaskInvoiceShare";
import TaskStatusUpdateButton from "./TaskStatusUpdateButton";

// for context
import CommonContext from "../../context/CommonContext";
// scroll
// Newest1 - Start
import { Scrollbars } from "react-custom-scrollbars";
// Newest1 - End

//services
import TaskInvoiceHistoryService from "../../service/TaskInvoiceHistoryService";
import CustDate from "../../../common_utilities/CustDate";
import { commonJsFuncModule as commonJsObj } from "../../../common_utilities/commonjsfunc";

// For loading CustLoader
import CustLoader from "./CustLoader";

import SplitScreenLeftMenu from "../../share/SplitScreenLeftMenu";

// common noti track
import NotiEmitTrack from "./NotiEmitTrack";
import { commonnotitrack } from "./NotiEmitTrack";

const getDateObjFromMilliseconds = commonJsObj.getDateObjFromMilliseconds;
const getTimestampInMillisecondsFromDateObj =
  commonJsObj.getTimestampInMillisecondsFromDateObj;
const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;
const sortArrayOfJsonByKey = commonJsObj.sortArrayOfJsonByKey;
const getCcUserId = commonJsObj.getCcUserId;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getBaseUrl = commonJsObj.getBaseUrl;

class SplitScreenTaskInvoiceHistory extends Component {
  isComponentLoaded = false;
  // for context
  static contextType = CommonContext;

  /**
   * SplitScreenTaskDocuments
   * Function name : constructor
   * Author        :
   * Created Date  : 7-5-2020
   * Purpose       : initializing children
   * Params        : props
   **/
  constructor(props) {
    super(props);
    this.children = props.children;
    this.fileUpl = React.createRef();
    this.state = {
      lpUserId: 0,
      ccUserId: 0,
      taskOrSubTask: "",
      taskIdOrSubTaskId: 0,
      listData: [],
      pageNum: 1,
      totalNumberOfPages: 1,
      loaderFlag: true,
      pageNumArTrack: [],
      uploadingFlag: false,
      invoiceInfo: null,
      pageNumber: 1,
      numPages: null,
      pdfLoadErr: false,
      isTaskCompleted: false,
    };
    this.scrollerRef = React.createRef();
    this.modalfortaskdelRef = React.createRef();
  }

  /**
   * SplitScreenTaskDocuments
   * Function name : componentDidMount
   * Author        :
   * Created Date  : 7-5-2020
   * Purpose       : lifecycle method
   * Params        :
   **/
  componentDidMount() {
    this.isComponentLoaded = true;

    //
    let taskOrSubTask = "";
    let taskIdOrSubTaskId = 0;
    let lpUserId = 0;
    let ccUserId = 0;
    let triggeredFrom = "";
    let isTaskCompleted = false;

    //********* for context starts *****
    let sharedData =
      this.context !== undefined && this.context.share !== undefined
        ? this.context.share
        : null;
    if (
      sharedData !== undefined &&
      sharedData !== null &&
      sharedData.actionType !== undefined
    ) {
      if (sharedData.actionType == "SPLITSCREEN") {
        if (sharedData.other !== undefined && sharedData.other !== null) {
          triggeredFrom =
            sharedData.other.triggeredFrom !== undefined
              ? sharedData.other.triggeredFrom
              : "";
          //*** when user comes by clicking on monitor icon from split screen left menu starts
          if (
            triggeredFrom == "VIEW_INVOICE_SCREEN" ||
            triggeredFrom == "TASK_SPLIT_SCREEN_LEFT_MENU" ||
            triggeredFrom == "PAYMENT_PAGE" ||
            triggeredFrom == "BELL_NOTIFICATION_MENU"
          ) {
            console.log(" sharedData.other==>", sharedData.other);

            taskOrSubTask =
              sharedData.other.subTasksCount !== undefined &&
              sharedData.other.subTasksCount !== null &&
              sharedData.other.subTasksCount > 0
                ? "SWSTASK"
                : "STASK";

            if (taskOrSubTask == "STASK") {
              if (
                sharedData.other.taskResp !== undefined &&
                sharedData.other.taskResp !== null
              ) {
                let taskResp = sharedData.other.taskResp;

                taskIdOrSubTaskId =
                  taskResp.id !== undefined && taskResp.id !== null
                    ? taskResp.id
                    : 0;

                lpUserId =
                  taskResp.assignedTo !== undefined &&
                  taskResp.assignedTo !== null &&
                  taskResp.assignedTo.userId !== undefined &&
                  taskResp.assignedTo.userId !== null
                    ? taskResp.assignedTo.userId
                    : 0;

                isTaskCompleted =
                  taskResp.isTaskCompleted !== undefined &&
                  taskResp.isTaskCompleted !== null
                    ? taskResp.isTaskCompleted
                    : false;
              }
            } else if (taskOrSubTask == "SWSTASK") {
              // this scenario i.e task with sub task is not in sprint 5
            }

            ccUserId = getCcUserId();
          }
          //*** when user comes by clicking on monitor icon from split screen let menu ends
          //*** when user comes by clicking on monitor icon from professional interested list starts

          //*** when user comes by clicking on monitor icon from professional interested list ends
        }
      }

      //console.log(" Now taskOrSubTask=>",taskOrSubTask," taskIdOrSubTaskId=>",taskIdOrSubTaskId, " lpUserId=>",lpUserId," ccUserId==>",ccUserId," triggeredFrom=>",triggeredFrom, " agreedBudget==>",agreedBudget," agreedBudgetCurrency=>",agreedBudgetCurrency, " agreedDueDateMillis=>",agreedDueDateMillis);
    }

    // call list api
    let paramObj = {
      pageNum: 1,
      pageNumArTrack: [],
      listData: [],
      totalNumberOfPages: 1,
      taskIdOrSubTaskId: taskIdOrSubTaskId,
      taskOrSubTask: taskOrSubTask,
      lpUserId: lpUserId,
      ccUserId: ccUserId,
      isTaskCompleted: isTaskCompleted,
      invoiceInfo: null,
      pageNumber: 1,
      numPages: null,
      pdfLoadErr: false,
    };
    this.isComponentLoaded &&
      this.setState(paramObj, () => {
        this.callServiceToFetchTaskInvoiceHistory(
          paramObj,
          "INVOICEVIEWCOMPONENTLOADED"
        );
      });

    //
  }

  /**
   * SplitScreenTaskDocuments
   * Function name : componentWillUnmount
   * Author        :
   * Created Date  : 7-5-2020
   * Purpose       : lifecycle method
   * Params        :
   **/
  componentWillUnmount() {
    this.isComponentLoaded = false;
  }

  /**
   * SplitScreenTaskDocuments
   * Function name : componentDidUpdate
   * Author        :
   * Created Date  : 7-5-2020
   * Purpose       : lifecycle method
   * Params        :
   **/
  componentDidUpdate(prevProps) {}

  callBackOnTaskStatusUpdate = () => {
    // call list api
    let paramObj = {
      pageNum: 1,
      pageNumArTrack: [],
      listData: [],
      totalNumberOfPages: 1,
      taskIdOrSubTaskId: this.state.taskIdOrSubTaskId,
      taskOrSubTask: this.state.taskOrSubTask,
      lpUserId: this.state.lpUserId,
      ccUserId: this.state.ccUserId,
      isTaskCompleted: true,
      invoiceInfo: this.state.invoiceInfo,
      pageNumber: this.state.pageNumber,
      numPages: this.state.numPages,
      pdfLoadErr: this.state.pdfLoadErr,
    };
    this.isComponentLoaded &&
      this.setState(paramObj, () => {
        // update isTaskCompleted to true in context also
        // this code has been done as we are not getting key isTaskCompleted from the api response for task status update
        let setShare =
          this.context !== undefined && this.context.setShare !== undefined
            ? this.context.setShare
            : null;
        let sharedData =
          this.context !== undefined && this.context.share !== undefined
            ? this.context.share
            : null;
        if (
          sharedData !== undefined &&
          sharedData !== null &&
          setShare !== undefined &&
          setShare !== null
        ) {
          if (
            sharedData.other !== undefined &&
            sharedData.other !== null &&
            sharedData.other.taskResp !== undefined &&
            sharedData.other.taskResp !== null
          ) {
            let taskRespObjNew = sharedData.other.taskResp;
            taskRespObjNew.isTaskCompleted = true;

            sharedData.other.taskResp = taskRespObjNew;
            setShare(sharedData);
          }
        }

        this.callServiceToFetchTaskInvoiceHistory(
          paramObj,
          "INVOICEVIEWCOMPONENTLOADED"
        );
      });
  };

  /**
   * SplitScreenTaskDocuments
   * Function name : closeSplit
   * Author        :
   * Created Date  : 7-5-2020
   * Purpose       : closeSplit method to close the split screen
   * Params        :
   **/

  closeSplit = (objParam) => {
    //********* for context starts *****
    // let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;
    // if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){

    //     if(sharedData.actionType=="SPLITSCREEN" ){

    //       let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;

    //       if(setShare){
    //         let paramsCntxt = {
    //           "taskId":"",
    //           "actionType":"",
    //           "leftTabToActive":"",
    //           "purpose":"",
    //           "other":{}
    //         }
    //         setShare(paramsCntxt);
    //       }

    //     }
    // }
    //********* for context ends *****
    commonnotitrack("CLOSE_SPLITSCREEN", {
      notiType: "CLOSE_SPLITSCREEN",
      triggerCallback: false,
      trigerredFrom: "TASK_INVOICE_HISTORY_SCREEN",
    });
  };

  callServiceToFetchTaskInvoiceHistory = (paramObj, trackcalledfrom) => {
    let paramObjData = {};
    paramObjData.pageNum = paramObj.pageNum;
    paramObjData.taskOrSubTask = paramObj.taskOrSubTask;
    paramObjData.taskIdOrSubTaskId = paramObj.taskIdOrSubTaskId;
    paramObjData.lpUserId = paramObj.lpUserId;

    console.log(
      " trackcalledfrom=>",
      trackcalledfrom,
      " paramObjData=>",
      paramObjData
    );

    let stcservice = new TaskInvoiceHistoryService();
    stcservice
      .getTaskInvoiceHistoryList({ data: paramObjData })
      .then((data) => {
        if (data.status == 200) {
          let respData = {};
          if (data.data.entity !== undefined) {
            respData = data.data.entity;
            if (respData.invoices !== undefined && respData.invoices !== null) {
              if (respData.invoices.length > 0) {
                let invoiceInfo = respData.invoices[0];

                this.isComponentLoaded &&
                  this.setState(
                    {
                      listData: respData.invoices,
                      totalNumberOfPages: respData.totalNumberOfPages,
                      invoiceInfo: invoiceInfo,
                    },
                    () => {
                      console.log(" state invoiceInfo=>", this.state);
                    }
                  );
              }
            } else {
            }
          }
        }
      })
      .catch((err) => {
        let errStatusCodeAr = [400, 500]; // allowed error status code
        if (err.response && err.response.data) {
          let erRespData = err.response.data;
          if (errStatusCodeAr.indexOf(erRespData.status) !== -1) {
            let errMsg = erRespData.message
              ? erRespData.message
              : "Some technical problem occurred.";
            console.log(errMsg);
          }
        }
      })
      .finally(() => {
        this.hideLoader();
      });
  };

  /**
   * SplitScreenTaskDocuments
   * Function name : loadLoader
   * Author        :
   * Created Date  : 7-5-2020
   * Purpose       : load Loader
   * Params        :
   **/

  loadLoader() {
    return (
      <CustLoader className={"smallLoaderGreen"} loaderText={"Loading.."} />
    );
  }

  /**
   * SplitScreenTaskDocuments
   * Function name : hideLoader
   * Author        :
   * Created Date  : 7-5-2020
   * Purpose       : Hide loader
   * Params        :
   **/

  hideLoader() {
    this.isComponentLoaded && this.setState({ loaderFlag: false });
  }

  /**
   * SplitScreenTaskDocuments
   * Function name : showLoader
   * Author        :
   * Created Date  : 7-5-2020
   * Purpose       : showLoader
   * Params        :
   **/

  showLoader() {
    this.isComponentLoaded && this.setState({ loaderFlag: true });
  }

  loadfooterOfInvoiceView = () => {
    let isPaid = false;

    let invoiceInfo = this.state.invoiceInfo;
    if (
      invoiceInfo &&
      invoiceInfo.isPaid !== undefined &&
      invoiceInfo.isPaid !== null
    ) {
      isPaid = invoiceInfo.isPaid;
    }

    return (
      <>
        {/* === right slide section Footer  start ===  */}
        {/* Newest1 - Start */}
        {/* <div className="SlideS_bottombg flexbox-item Slide_chatBottomBg invoice_footer"> */}
        <div className="SlideS_bottombg flexbox-item Slide_chatBottomBg SlideS_bottombg_gray SlideS_bottombg_stickyFooterRightPart SlideS_bottombg_stickyFooterRightPart_invoice_footer">
          {/* Newest1 - End */}
          <div className="chat_bottom_left inv_bottom_left">
            {this.state.taskOrSubTask == "STASK" &&
              this.state.isTaskCompleted == false &&
              isPaid == true && (
                <TaskStatusUpdateButton
                  datas={{
                    taskOrSubTask: this.state.taskOrSubTask,
                    taskIdOrSubTaskId: this.state.taskIdOrSubTaskId,
                    lpUserId: this.state.lpUserId,
                    ccUserId: this.state.ccUserId,
                  }}
                  custclassname="btn_appearance_none sub_grd_btn"
                  buttontextname={"Close task"}
                  onsuccess={this.callBackOnTaskStatusUpdate}
                />
              )}
            {/* <button className="btn_appearance_none sub_grd_btn"><span>Close task</span></button> */}
          </div>
        </div>

        {/* === right slide section Footer  end ===  */}
      </>
    );
  };

  // view file modal
  onDocumentLoadSuccess = ({ numPages }) => {
    this.isComponentLoaded &&
      this.setState({ numPages }, () => {
        let otherParam = this.state.specificFileInfo;
        // setTimeout(()=>{
        //   this.getFileViewModalContent(otherParam);
        // },1500);
      });
  };

  // view file modal
  onDocumentLoadErr = (error) => {
    //console.log(" === error=>>",error);
    this.isComponentLoaded &&
      this.setState({ pdfLoadErr: true }, () => {
        let otherParam = this.state.specificFileInfo;
        // setTimeout(()=>{
        //   this.getFileViewModalContent(otherParam);
        //   },900);
      });
  };
  // view file modal
  triggerPrevNextPdfPage = (action, param) => {
    let pageNumber = this.state.pageNumber;
    let numPages = this.state.numPages;
    if (action == "PREV") {
      if (pageNumber > 1) {
        pageNumber = pageNumber - 1;
      }
    } else if (action == "NEXT") {
      if (numPages !== null && pageNumber != numPages) {
        pageNumber = pageNumber + 1;
      }
    }
    this.isComponentLoaded &&
      this.setState({ pageNumber: pageNumber }, () => {
        let otherParam = this.state.specificFileInfo;
        this.getFileViewModalContent(otherParam);
      });
  };

  loadTheContent = () => {
    const { pageNumber, numPages, pdfLoadErr } = this.state;

    let forSplitScrnCls2Inject = "";
    let datasPassForAssignObj = {};
    let showBackButton = false;
    //********* for context starts *****
    // context share
    let setShare =
      this.context !== undefined && this.context.setShare !== undefined
        ? this.context.setShare
        : null;
    let sharedData =
      this.context !== undefined && this.context.share !== undefined
        ? this.context.share
        : null;
    if (
      sharedData !== undefined &&
      sharedData !== null &&
      sharedData.actionType !== undefined
    ) {
      if (sharedData.actionType == "SPLITSCREEN") {
        forSplitScrnCls2Inject = "margin0";
      }
    }

    if (
      sharedData !== undefined &&
      sharedData !== null &&
      sharedData.other !== undefined
    ) {
      // invoiceViewedPaidStatus is injected into context from SplitscreenTaskInvoice page if the invoice voice is unpaid
      console.log("Checking sharedData=>>", sharedData);
      showBackButton =
        sharedData.other !== null &&
        sharedData.other.invoiceViewedPaidStatus !== undefined &&
        sharedData.other.invoiceViewedPaidStatus !== null &&
        sharedData.other.invoiceViewedPaidStatus == false
          ? true
          : false;
    }
    //********* for context ends *****

    let listObjAr = [];

    return (
      <>
        {/* Dashboard Slide Split Right start  */}
        <div
          className={`splitscreen_area
              ${forSplitScrnCls2Inject ? forSplitScrnCls2Inject : ""}`}
        >
          {/* Newest1 - Start */}
          {/* <div className="slideScreen_bg slideScreen_Chatbg"> */}
          <div className="slideScreen_bg slideScreen_Chatbg slideScreen_bg_heightAdjust">
            {/* Newest1 - End */}
            <SplitScreenLeftMenu />

            <div className="slideScreen_rightbg flexbox-parent">
              {/* === right slide section top start ===  */}
              <div className="SlideS_topbg flexbox-item slide_chat_topbg splitInvoiceTop">
                <div className="slide_top_titlebg">
                  <div className="slideChat_head">
                    <div className="slideChat_panel">
                      {showBackButton == true && (
                        <button
                          onClick={(evn) => {
                            evn.preventDefault();
                            if (setShare && sharedData) {
                              sharedData.leftTabToActive = "INVOICE";
                              sharedData.purpose = "VIEW_INVOICE";
                              sharedData.other.triggeredFrom =
                                "TASK_INVOICE_HISTORY";
                              setShare(sharedData);
                            }
                          }}
                          className="btn_appearance_none chatHeadBack"
                        >
                          <span>Back</span>
                        </button>
                      )}
                    </div>
                    <div className="slideChat_panel">
                      <h5>Invoice history</h5>
                    </div>

                    <div className="clearboth" />
                  </div>
                  <button
                    onClick={(evn) => {
                      evn.preventDefault();
                      this.closeSplit({
                        trigerredFrom: "TASK_INVOICE_HISTORY_SCREEN",
                      });
                    }}
                    className="btn_appearance_none slideTop_cross_btn cross"
                  >
                    <img src={getBaseUrl() + "assets/images/red_cross.png"} />
                  </button>
                </div>
              </div>
              {/* === right slide section top end ===  */}
              {/* === right slide section Middlie  start ===  */}
              <div className="SlideS_Midbg flexbox-item-grow SlideS_Midbg_chat SlideS_Midbg_invoice Pdf__invoice_list_bg">
                {/* Newest1 - Start */}
                {/* <div className="s_midbox_scroll_adjust  reactTestScroll scrollInvoiceBg"> */}
                <div
                  ref={this.scrollerRef}
                  className="s_midbox_scroll_adjust reactTestScroll scrollInvoiceBg mCustomAdjustmentForStickFooter rightMidPartHeightAdjustment rightMidPartHeightAdjustment_scrollInvoiceBg_pdfView"
                >
                  {/* {height:775 autoHeightMin: 775} */}
                  {/* <Scrollbars
                    ref={this.scrollerRef}
                    style={{ height: 400 }}
                    autoHeightMin={400}
                    renderView={(props) => (
                      <div
                        {...props}
                        className="view invoiceviewscreenscrollclass"
                      />
                    )}
                    onScrollStop={this.trackScroll}
                  > */}
                  {/* Newest1 - End */}
                  <div className="s_midbox_section s_midbox_section_inv">
                    {/* ====   sprint - 6 invoice main structure start ====  */}
                    {/* invoice import body start  */}
                    <div className="invoice_importbg">
                      {/* invoice top filter start */}
                      <div className="invoice_top_filter">
                        <div className="row">
                          <div className="col-md-6 col-sm-12 col-12">
                            {/* <div className="custom_select_bg">
                                      <select className="customSelect form__field">
                                        <option>Unpaid invoices</option>
                                        <option>Paid invoices</option>
                                        
                                      </select>
                                    </div> */}
                          </div>
                        </div>
                      </div>
                      {/* invoice top filter end */}
                      {/* invoice listing start */}
                      <div className="invo_listBox_bg">
                        <div className="invo_list_whiteBox">
                          {/* ====   invoice list Table structure start ====  */}
                          <div className="invo_list_table_bg">
                            <div className="table-responsive">
                              {/* ====   Table start  ====   */}
                              <table
                                id="example"
                                className="table table-bordered"
                                style={{ width: "100%" }}
                              >
                                <thead>
                                  <tr>
                                    <th>
                                      <label>Name</label>
                                    </th>
                                    <th>
                                      <label>Invoice no.</label>
                                    </th>
                                    <th>
                                      <label>Payment date</label>
                                    </th>
                                    <th>
                                      <label>Status</label>
                                    </th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.listData.length > 0 &&
                                    Object.keys(this.state.listData).map(
                                      (eob, i) => {
                                        let lpUserName = "";
                                        let lpFName = "";
                                        let lpLName = "";
                                        if (
                                          this.state.listData[i]
                                            .invoiceGenerator !== undefined
                                        ) {
                                          lpFName =
                                            this.state.listData[i]
                                              .invoiceGenerator.firstName !==
                                              undefined &&
                                            this.state.listData[i]
                                              .invoiceGenerator.firstName !==
                                              null
                                              ? this.state.listData[i]
                                                  .invoiceGenerator.firstName
                                              : "";

                                          lpLName =
                                            this.state.listData[i]
                                              .invoiceGenerator.lastName !==
                                              undefined &&
                                            this.state.listData[i]
                                              .invoiceGenerator.lastName !==
                                              null
                                              ? this.state.listData[i]
                                                  .invoiceGenerator.lastName
                                              : "";
                                        }

                                        lpUserName = lpFName + " " + lpLName;

                                        let paidAtOrDueDate = "";

                                        // earlier logic
                                        // if(this.state.listData[i].isPaid==false)
                                        // {
                                        //   paidAtOrDueDate  = this.state.listData[i].dueDate?CustDate.custFormatDate((new Date(this.state.listData[i].dueDate)),5):"---";
                                        // }else{
                                        //   paidAtOrDueDate  = this.state.listData[i].paidAt?CustDate.custFormatDate((new Date(this.state.listData[i].paidAt)),5):"---"
                                        // }

                                        if (
                                          this.state.listData[i].isPaid == false
                                        ) {
                                          paidAtOrDueDate = "Due on receipt";
                                        } else {
                                          paidAtOrDueDate = this.state.listData[
                                            i
                                          ].paidAt
                                            ? CustDate.custFormatDate(
                                                new Date(
                                                  this.state.listData[i].paidAt
                                                ),
                                                5
                                              )
                                            : "Due on receipt";
                                        }

                                        let invoiceInfo =
                                          this.state.listData[i];

                                        let downloadUrl = null;

                                        if (
                                          invoiceInfo &&
                                          invoiceInfo.downloadUrl !==
                                            undefined &&
                                          invoiceInfo.downloadUrl !== null
                                        ) {
                                          downloadUrl =
                                            invoiceInfo.downloadUrl +
                                            "&auth=" +
                                            getTokenFromLocStorage();
                                        }

                                        listObjAr.push(
                                          <tr key={i + 1}>
                                            <td>
                                              <p>{lpUserName}</p>
                                            </td>
                                            <td>
                                              <p>
                                                {this.state.listData[i]
                                                  .invoiceNumber !== undefined
                                                  ? this.state.listData[i]
                                                      .invoiceNumber
                                                  : ""}
                                              </p>
                                            </td>
                                            <td>
                                              <p>{paidAtOrDueDate}</p>
                                            </td>
                                            <td>
                                              <p>
                                                {this.state.listData[i]
                                                  .isPaid == false
                                                  ? "Due"
                                                  : "Paid"}
                                              </p>
                                            </td>
                                            <td>
                                              {/* <p className="invo_part_btn">
                                                 <button onClick={(evn)=>{
                                                    evn.preventDefault();
                                                    if(setShare && sharedData){
                                                      sharedData.leftTabToActive = "INVOICE";
                                                      sharedData.purpose = "VIEW_INVOICE";
                                                      sharedData.other.triggeredFrom = "TASK_INVOICE_HISTORY";
                                                      setShare(sharedData);
                                                      
                                                    }
                                                  }} className="btn_appearance_none greenText_btn">
                                                   <span>{this.state.listData[i].isPaid==true?"View PDF":"View"}</span>
                                                 </button>
                                                 {this.state.listData[i].isPaid==false && (
                                                    <TaskInvoiceShare custkeyid={i} custclassname="btn_appearance_none greenText_btn" datas={{"invoiceInfo":invoiceInfo,"taskOrSubTask":this.state.taskOrSubTask,"taskIdOrSubTaskId":this.state.taskIdOrSubTaskId,"lpUserId": this.state.lpUserId,"ccUserId":this.state.ccUserId}} />
                                                 )}
                                                 
                                                 
                                               </p> */}

                                              <div className="moreAction_tableSec">
                                                <button
                                                  class="btn_appearance_none moreAction_dropBtn"
                                                  type="button"
                                                  data-toggle="dropdown"
                                                >
                                                  More actions
                                                </button>
                                                <div className="dropdown-menu dropdown_menu_tableTds">
                                                  <ul>
                                                    <li>
                                                      <a
                                                        href=""
                                                        onClick={(evn) => {
                                                          evn.preventDefault();
                                                          if (downloadUrl) {
                                                            let winDwnObj =
                                                              window.open(
                                                                downloadUrl,
                                                                "_blank"
                                                              );
                                                            winDwnObj.focus();
                                                            //window.location.href = downloadUrl;
                                                          }
                                                        }}
                                                        className="dropdown-item"
                                                      >
                                                        Download invoice
                                                      </a>
                                                    </li>
                                                    <li>
                                                      <TaskInvoiceShare
                                                        custkeyid={i}
                                                        custclassname="dropdown-item "
                                                        datas={{
                                                          invoiceInfo:
                                                            invoiceInfo,
                                                          taskOrSubTask:
                                                            this.state
                                                              .taskOrSubTask,
                                                          taskIdOrSubTaskId:
                                                            this.state
                                                              .taskIdOrSubTaskId,
                                                          lpUserId:
                                                            this.state.lpUserId,
                                                          ccUserId:
                                                            this.state.ccUserId,
                                                        }}
                                                        buttontextname={
                                                          "Share invoice"
                                                        }
                                                        dispelementype={
                                                          "anchor"
                                                        }
                                                      />
                                                      {/* <a href="#" className="dropdown-item">Share invoice</a> */}
                                                    </li>
                                                    {/* <li><a href="#" className="dropdown-item">Upload professional’s TDS certificate</a></li>
                                                                    <li><a href="#" className="dropdown-item">View professional’s TDS certificate</a></li> */}
                                                  </ul>
                                                </div>
                                                <div class="clearboth"></div>
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  {this.state.listData.length > 0 && (
                                    <>{listObjAr}</>
                                  )}
                                </tbody>
                                <tfoot>
                                  {this.state.loaderFlag == false &&
                                    this.state.listData.length == 0 && (
                                      <tr key={"001"}>
                                        <td colSpan={5}>
                                          {this.state.listData.length == 0 && (
                                            <div className="table_foot_nodata">
                                              <p> No Data Found </p>
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                    )}

                                  <tr key={"002"}>
                                    <td colSpan={5}>
                                      <div className="table_foot_loading">
                                        {this.state.loaderFlag
                                          ? this.loadLoader()
                                          : null}
                                      </div>
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                              {/* ====   Table end  ====   */}
                            </div>
                          </div>
                          {/* ====   invoice list Table structure end ====  */}
                        </div>
                      </div>
                      {/* invoice listing end */}
                    </div>
                    {/* invoice import body end  */}

                    {/* ====   sprint - 6 invoice main structure end ====  */}
                  </div>
                  {/* Newest1 - Start */}
                  {/* </Scrollbars> */}
                  {/* Newest1 - End */}
                </div>
              </div>
              {/* === right slide section Middlie  end ===  */}
              {this.loadfooterOfInvoiceView()}
            </div>
          </div>
        </div>

        {/* Dashboard Slide Split Right end  */}
      </>
    );
  };

  // trackNotiForCloseSplit = (otherParam) =>{

  //   let notiType =  otherParam.notiType!==undefined? otherParam.notiType: "";
  //   let trigerredFrom =  otherParam.trigerredFrom!==undefined? otherParam.trigerredFrom: "";
  //   if(notiType=="CLOSE_SPLITSCREEN"){
  //       let closeSplitHeaderCallFrm = ["TASK_INVOICE_HISTORY_SCREEN"];
  //       if(closeSplitHeaderCallFrm.indexOf(trigerredFrom)!=-1){
  //               console.log("*******trigerredFrom=>>",trigerredFrom);
  //       }

  //   }
  // }

  /**
   * SplitScreenSingleTaskDetails
   * Function name : render
   * Author        :
   * Created Date  : 7-5-2020
   * Purpose       : lifecycle method
   * Params        :
   **/
  render() {
    return (
      <React.Fragment>
        {/* <NotiEmitTrack callbackfuncforcommontrackofnoti={this.trackNotiForCloseSplit} /> */}
        {this.loadTheContent()}

        {/* Confirm delete modal start */}
        <CustReactBootstrapModal
          ref={this.modalfortaskdelRef}
          backdrop={false}
          backdropClassName="custom_modal_class"
          animation={false}
          show={this.state.showDeleteModal}
          onShow={() => {}}
          onHide={() => {}}
          modalcustdialog="custom_modal_lg"
          loaderbodytitle="Organisation"
        />
        {/* Confirm delete modal end */}
      </React.Fragment>
    );
  }
}
export default SplitScreenTaskInvoiceHistory;
