
import React, { Component, Suspense, lazy, Form, FormInput} from 'react';
import OrgUserAddSaveService from '../../service/OrgUserAddSaveService';
// scroll 
import { Scrollbars } from 'react-custom-scrollbars';

import {commonJsFuncModule as commonJsObj} from  '../../../common_utilities/commonjsfunc';
import {commonStaticOptionsJsFuncModule as commonStaticJsObj} from  '../../../common_utilities/commonstaticoptions';

import LoggedinUserInfoService from '../../service/LoggedinUserInfoService';
import {notify} from '../../messagebar/CustomMessageBar';
import CustomMessageBar from '../../messagebar/CustomMessageBar';
// import notify from '../../messagebar/CustomMessageBar';
const checkInvalidEmailIdDomain = commonJsObj.checkInvalidEmailIdDomain;
const getOrganisationMaxCapacity = commonJsObj.getOrganisationMaxCapacity;
const getOrganisationCurrentCapacity = commonJsObj.getOrganisationCurrentCapacity;

const getBaseUrl = commonJsObj.getBaseUrl;
const getAdminRoleStaticOtions = commonStaticJsObj.getAdminRoleStaticOtions;

// const isAdminData = [
//     {"isAdmin":{"key":"","value":"Role"}},
//     {"isAdmin":{"key": true,"value": "Admin"}},
//     {"isAdmin":{"key": false,"value": "Team Member"}},

    
//   ];
 const isAdminData = getAdminRoleStaticOtions();


  const defaultNewDataElement = {
        "emailId": "",
        "firstName": "",
        "isAdmin": "",
        "lastName": ""
  };

  const defaultElementErMsg = {
    "emailIdErMsg": "",
    "firstNameErMsg": "",
    "isAdminErMsg": "",
    "lastNameErMsg": ""
};

class OrgUserAddComp extends Component {
    isComponentLoaded = false;
    constructor(props) {
    super(props);
    this.state = { values: [], 
                    errMsgs: [], 
                    organisationMaxCapacity:0, 
                    organisationCurrentCapacity:0, 
                    localTrackUserAddCount:0,
                    loaderFlag: false,
                    addMoreFlag: true

                };
    
  }

  localTrackUserAddCount
  componentDidMount(){      

    this.isComponentLoaded = true;      
    this.isComponentLoaded && this.setState({"values":[defaultNewDataElement]}); 

    //  update organisationMaxCapacity & organisationCurrentCapacity starts
    let luiserv = new LoggedinUserInfoService();
    luiserv.getLoggedInUserInfo({}).then(data =>{
    //console.log("=getLoggedInUserInfo=>",data);
    let respData = data.data;
    if(respData.entity){
        this.isComponentLoaded && this.setState({"organisationMaxCapacity":getOrganisationMaxCapacity()});
        this.isComponentLoaded && this.setState({"organisationCurrentCapacity":getOrganisationCurrentCapacity()},()=>{
            let localTrackUserAddCount = this.state.organisationMaxCapacity - this.state.organisationCurrentCapacity;
            if(localTrackUserAddCount==0){
                this.setState({"addMoreFlag":false});
            }
            this.isComponentLoaded && this.setState({"localTrackUserAddCount":localTrackUserAddCount},()=>{
                    //console.log("localTrackUserAddCount===>>", this.state.localTrackUserAddCount);

                    // if(localTrackUserAddCount<=0){
                    //     this.isComponentLoaded && this.setState({"loaderFlag":true});
                    // }

                    if(this.checkIfAddMoreCanBeDone()){
                        this.setAddMoreFlagTrue();                       
                    }else{
                        this.setAddMoreFlagFalse();                        
                    }

                    
                });
        });                    
    }
    //  update organisationMaxCapacity & organisationCurrentCapacity ends

    // setTimeout(()=>{
    //     console.log("organisationMaxCapacity=>"+this.state.organisationMaxCapacity, 
    //     "organisationCurrentCapacity=>"+this.state.organisationCurrentCapacity, 
    //     "localTrackUserAddCount=>"+this.state.localTrackUserAddCount );
    // },2000);

    }).catch(err=>{
    console.log("some server related tech problem occured",err);
    });

    

    
  }

  componentWillUnmount() {  
    this.isComponentLoaded = false;
}

   /**
 * orgDetailDataObj
 * Function name : custValidate
 * Author        :
 * Created Date  : 19-2-2020
 * Purpose       : validate data before submit
 * Params        : 
**/
custValidate = ()=>{    
  
    let totErrCountTrack = [];  
    let regexBlankSpace = /^ *$/; 
    let allowedIsAdminVal = [true,false]; 

    if(this.state.values.length>0){

        let items = [...this.state.errMsgs];       

        for(let i=0;i<this.state.values.length;i++){

            let emailIdErMsg= "";
            let firstNameErMsg = "";
            let isAdminErMsg = "";
            let lastNameErMsg =  "";

            let erFlgEmlTrk = false; 
            let erFlgFnTrk = false; 
            let erFlgLnTrk = false; 
            let erFlgRlTrk = false;

            
            if(!(this.state.values[i].firstName)||(this.state.values[i].firstName.length==0)|| (regexBlankSpace.test(this.state.values[i].firstName)==true)){
                firstNameErMsg = "First name is required.";
                erFlgFnTrk = true;                 
            }else{
                firstNameErMsg = "";  
            }

            if(!(this.state.values[i].lastName)||(this.state.values[i].lastName.length==0)|| (regexBlankSpace.test(this.state.values[i].lastName)==true)){
                lastNameErMsg = "Last name is required.";
                erFlgLnTrk = true;                  
            }else{
                lastNameErMsg = "";  
            }

            if(!(this.state.values[i].emailId)||(this.state.values[i].emailId.length==0)){
                emailIdErMsg = "Email-ID is required.";
                erFlgEmlTrk = true;                 
            }else if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.values[i].emailId)==false)
            {                
                emailIdErMsg = "Provide a proper Email-ID.";
                erFlgEmlTrk = true; 
            }
            else{
                  // check this emailid domain are allowed or not
                  let splitEmailAr = (this.state.values[i].emailId).split("@");
                  let foundInvalidEmailDomain = false;         
        
                  if(splitEmailAr.length>1){
                    let emlDomain = splitEmailAr[1];
                    if(checkInvalidEmailIdDomain(emlDomain)==true){
                        foundInvalidEmailDomain = true;
                    }
                  }  
        
                  if(foundInvalidEmailDomain){
                    emailIdErMsg = "Personal email addresses are not allowed.";
                    erFlgEmlTrk = true; 
                  }
                  else{
                    emailIdErMsg = ""; 
                  }      
            }
                       
            if((allowedIsAdminVal.indexOf(this.state.values[i].isAdmin)==-1)){
                isAdminErMsg = "Role is required.";
                erFlgRlTrk = true;                  
            }else{
                
                isAdminErMsg = "";  
            }
           
                      
            let item = {...items[i]};  

            item.emailIdErMsg = emailIdErMsg;
            item.firstNameErMsg = firstNameErMsg;
            item.isAdminErMsg = isAdminErMsg;
            item.lastNameErMsg = lastNameErMsg;
        
            items[i] = item;
            //console.log(" Set this object==>",items);

            if(erFlgEmlTrk == true || erFlgFnTrk == true || erFlgLnTrk == true || erFlgRlTrk == true ){
                totErrCountTrack.push(items);
            }
    
            
        }

        this.isComponentLoaded && this.setState({ "errMsgs":items },()=>{             
            //console.log(" Now errMsgs is=>", this.state.errMsgs);
        });
    }
  
    if(totErrCountTrack.length>0){          
        return false;
    }
    return true;
  }

    // Dynamically added structure
    createUI(){
     return this.state.values.map((el, i) => 
     	    
          
        <div key={i} className="modal_add_userbg">
          
                    <div className="row">
                                    <div className="col-md-6 col-sm-6 col-xs-12">
                                        <div className="modal_input  form__group field">
                        					<input custpropforkey="firstName"  onChange={this.handleChange.bind(this, i)} type="input" defaultValue="" className="form__field" placeholder="First Name" name={`firstName${i}`}   />
                        					<label htmlFor={`firstName${i}`} className="form__label">First Name</label>
                                            <span className="error_span"> {this.state.errMsgs[i] && this.state.errMsgs[i].firstNameErMsg} </span>
                        				</div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-xs-12">
                                        <div  className="modal_input  form__group field">
                        					<input custpropforkey="lastName" onChange={this.handleChange.bind(this, i)} type="input" className="form__field" placeholder="Last Name" name={`lastName${i}`}  />
                        					<label htmlFor={`lastName${i}`} className="form__label">Last Name</label>
                                            <span className="error_span"> {this.state.errMsgs[i] && this.state.errMsgs[i].lastNameErMsg} </span>
                        				</div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-xs-12">
                                        <div className="modal_input  form__group field">
                        					<input onChange={this.handleChange.bind(this, i)} custpropforkey="emailId" type="input" className="form__field" placeholder="User email ID" name={`emailId${i}`}    />
                        					<label htmlFor={`emailId${i}`} className="form__label">User email ID</label>
                                            <span className="error_span"> {this.state.errMsgs[i] && this.state.errMsgs[i].emailIdErMsg} </span>
                        				</div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-xs-12">
                                        <div className="modal_input modal_select">
                                            <select onChange={this.handleChange.bind(this, i)} custpropforkey="isAdmin" name={`isAdmin${i}`}  className="selectpicker dropup disabled" data-header="Organisation Size">
                                                {Object.keys(isAdminData).length > 0 &&
                                                Object.keys(isAdminData).map((e, i) => (
                                                <option key={i} value={isAdminData[e].isAdmin.key} > {isAdminData[e].isAdmin.value}</option>
                                                ))
                                                }
                                            </select>
                                            <span className="error_span"> {this.state.errMsgs[i] && this.state.errMsgs[i].isAdminErMsg} </span>
                                        </div>
                                    </div>
                                    {(i!=0) && 
                                    <div className="row_add_remove">
                                    <div className="mar_left-5">
                                        <div className="modal_input col_add_remove">
                                        <button onClick={this.removeClick.bind(this, i)}  type="button" className="mod_add_rem_btn mod_rem_user"><i className="fa fa-minus-circle" aria-hidden="true"></i> Remove</button>
                                        </div>
                                    </div>
                                    </div>
                                        
                                    }

                    </div>
        </div>           
     	          
     )
  }

  handleChange( i, event) {
    let custpropforkeyData = event.target.attributes.getNamedItem('custpropforkey').value;
    // console.log("Now==>",i," ==event.target name",event.target.name, "forKey==>", custpropforkeyData);
      
        // 1. Make a shallow copy of the items
        let items = [...this.state.values];
        // 2. Make a shallow copy of the item you want to mutate
        let item = {...items[i]};
        //console.log("==Hello prev data==>",item);
        // 3. Replace the property you're intested in
        
        if(custpropforkeyData=="firstName"){
            item.firstName = event.target.value;
        }

        if(custpropforkeyData=="lastName"){
            item.lastName = event.target.value;
        }

        if(custpropforkeyData=="emailId"){
            item.emailId = event.target.value;
        }

        if(custpropforkeyData=="isAdmin"){
            item.isAdmin = event.target.value;
            // change             
            if(item.isAdmin=="true"){
                item.isAdmin = true;
            }else if(item.isAdmin=="false"){
                item.isAdmin = false;
            }
            //console.log("value set=>",event.target.value);
        }

        // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
        items[i] = item;
            
        this.isComponentLoaded && this.setState({ "values":items },()=>{
            //console.log(" Now state is=>", this.state.values);
        });
  }
  
   async addClick(){
    //console.log("values==>", this.state.values.length, " organisationMaxCapacity=>>",this.state.organisationMaxCapacity, " localTrackUserAddCount=>",this.state.localTrackUserAddCount);

    if(this.checkIfAddMoreCanBeDone()){
        this.setAddMoreFlagTrue();   

        this.isComponentLoaded &&  this.setState(prevState => ({ values: [...prevState.values, defaultNewDataElement]}),()=>{

            if(this.checkIfAddMoreCanBeDone()==false){
                this.setAddMoreFlagFalse(); 
            }
        });
       
    }else{
        this.setAddMoreFlagFalse();        
    }
    
    

  }

  checkIfAddMoreCanBeDone(){   
    let chkFlag = true; 
    if(this.state.values.length >=  this.state.localTrackUserAddCount){
        
        chkFlag =  false;
        return chkFlag;

    }
    if(this.state.localTrackUserAddCount<=0){       
        chkFlag = false;
        return chkFlag;      
    }
    return chkFlag; 
  }
  setAddMoreFlagTrue(){
    this.setState({"addMoreFlag":true},()=>{
    }); 
  }

  setAddMoreFlagFalse(){
    this.setState({"addMoreFlag":false},()=>{
    }); 
   }

 


  
  removeClick(i){
     let valuesData = [...this.state.values];
     let errMsgsData = [...this.state.errMsgs];
     valuesData.splice(i,1);
     errMsgsData.splice(i,1);
     //this.isComponentLoaded && this.setState({ values });
     this.isComponentLoaded && this.setState({ "values":valuesData,"errMsgs":errMsgsData },()=>{
        //console.log(" Now state is=>", this.state.values);

        if(this.checkIfAddMoreCanBeDone()){
            this.setAddMoreFlagTrue();
            setTimeout(()=>{
                this.setAddMoreFlagTrue();
            },300);
        }else{
            this.setAddMoreFlagFalse();
            setTimeout(()=>{
                this.setAddMoreFlagFalse();
            },300);
            
        }




    });

    

  }

  

  callSendInvite = (e)=>{
      e.preventDefault();
      this.isComponentLoaded && this.setState({loaderFlag:true});       
      const isValid = this.custValidate();  
    //   setTimeout(()=>{
    //     console.log("state=>", this.state);
    //   }, 2500);      
      
      if(isValid){         
          //console.log("data to be posted==>",this.state.values);
          const paramDataObj =  this.state.values;
            let lserv = new OrgUserAddSaveService();
            lserv.saveOrgUser(paramDataObj).then(data =>{        
                if(data.status==200){                   
                        
                        let notiMsg = "Success";
                        notify(notiMsg,true,{
                        "successMsg":1,
                        "customWrapClass":"customerrlass",
                        "showCloseButton": false,                     
                        // "callbackClose": ()=>{
                        //     this.props.calladdorgusermodalclose();   
                        // },

                        "dismiss": {
                        "duration": 1500,
                        }

                        });

                        setTimeout(()=>{
                            this.props.calladdorgusermodalclose(); 
                        },1900);
       
                                                 
                }                   
            }).catch(err=>{
                let errStatusCodeAr = [400,500]; // allowed error status code
                if(err.response && err.response.data){
                    let erRespData = err.response.data;
                    if(errStatusCodeAr.indexOf(erRespData.status) !== -1){    

                        let errMsg = erRespData.message?erRespData.message: "Some technical problem occurred from server. Please try again.";
                        //console.log(errMsg);
                        let notiMsg = errMsg;
                        notify(notiMsg,true,{
                        "successMsg":0,
                        "customWrapClass":"customerrlass",
                        "showCloseButton": false, 

                        "dismiss": {
                        "duration": 1500,
                        }

                        });    
                        
                        

                        
                    }
                }        

            }).finally(()=>{
                setTimeout(()=>{
                    this.isComponentLoaded && this.setState({loaderFlag:false},()=>{
                    });
                },1500);
                
            });
        
      }else{
        this.isComponentLoaded && this.setState({loaderFlag:false}); 
      } 
  
    }

  render() {
    return (
      	<>  
            <div className="modal-header">                
                <h4 className="modal-title modal_title_tool">Create new users
                    <div className="input_tooltip_bg">
                        <span className="input_tooltip" aria-label="Create new users" data-balloon-pos="down">
                        <i className="fa fa-question-circle"></i>
                        </span>
                    </div>
                    
                    
                </h4>
                <button type="button" onClick={()=>{this.props.calladdorgusermodalclose();}} className="close modal_close" data-dismiss="modal">&times;</button>
            </div>
            <CustomMessageBar />
            <div className="modal-body modal-body-scrollbar custom_scrollbar_edit ">
            
                <Scrollbars 
                style={{ height: 350 }}        
            
                autoHeightMin={350}
            
                
            
                >
                    <div className="react_scrollbar_box">
                        <div className="modal_top_details">                    
                            <p className="modal_p">You are an admin for this account. You can add other team members to this account & assign roles. </p>      
                        </div> 
                        
                        {/* dynamic structure to inject starts*/}
                        {this.createUI()} 
                        {/* dynamic structure to inject ends */}
                        {/* add more link structure starts */}            
                        <div className="row_add_remove">                            
                                    <div className="modal_input col_add_remove">
                                       {this.state.addMoreFlag && (<button onClick={this.addClick.bind(this)}  type="button" className="mod_add_rem_btn mod_add_user"><i className="fa fa-plus-circle" aria-hidden="true"></i> Add another user</button>)}

                                    </div>                                
                        </div>
                        {/* add more link structure ends */} 

                    </div>
                </Scrollbars>
        
            </div>
            <div className="modal-footer">
                <button disabled={this.state.loaderFlag}   type="button" className="btn btn-primary modal_btn_save" onClick={ this.callSendInvite } data-dismiss="modal"><span>Send Invite</span></button>
            
            </div>             
        </>
      
    );
  }
}
export default OrgUserAddComp;