/**
 * ProfileSettings
 * Page/Class name   : ProfileSettings
 * Author            :
 * Created Date      : 7-5-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : render master component
**/
import React, { Component } from 'react';



// for context
import CommonContext from '../../context/CommonContext';

//Services
import LoggedinUserInfoService from '../../service/LoggedinUserInfoService';

//import  CustDate  from '../../../common_utilities/CustDate';
import { commonJsFuncModule as commonJsObj } from '../../../common_utilities/commonjsfunc';
import {notify} from '../../messagebar/CustomMessageBar';
import CustomMessageBar from '../../messagebar/CustomMessageBar';
import ProfileUser from './ProfileUser';
import ProfileKyc from './ProfileKyc';

const getDateObjFromMilliseconds = commonJsObj.getDateObjFromMilliseconds;
const getTimestampInMillisecondsFromDateObj = commonJsObj.getTimestampInMillisecondsFromDateObj;
const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;
const sortArrayOfJsonByKey = commonJsObj.sortArrayOfJsonByKey;
const getCcUserId = commonJsObj.getCcUserId;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;




class ProfileSettings extends Component {

    isComponentLoaded =  false;
    // for context
    static contextType = CommonContext;

    /**  
     * SplitScreenTaskDocuments
     * Function name : constructor
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : initializing children
     * Params        : props
    **/
    constructor(props) {
      super(props);
      this.children = props.children;
      this.state = {
       
        tabOption: "" // PROFILE_KYC or PROFILE_USER


      };
      this.refDivTop = React.createRef();
     
    }

    /**
     * SplitScreenTaskDocuments
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : lifecycle method
     * Params        :
    **/
    componentDidMount() {
      this.isComponentLoaded = true;
      
      this.checkBeforeSwitchOnFirstimeLoad();
    }

   


    /**  
     * SplitScreenTaskDocuments
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {
      this.isComponentLoaded = false;

    }

    /**  
     * SplitScreenTaskDocuments
     * Function name : componentDidUpdate
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentDidUpdate(prevProps){

    }

    showSuccessOrErrorMessage = (param) =>{
      window.scrollTo(0, this.refDivTop.current.offsetTop);
      
      let notiMsg = param.notiMsg;
      let successOrError = param.successOrError; // 0 or 1
      let dismissDuration = param.dismissDuration; // generally 1500
      let showCloseButton = param.showCloseButton; // generally false

      notify(notiMsg,true,{
      "successMsg":successOrError,
      "customWrapClass":"customsuccclass",
      "showCloseButton": showCloseButton,                     
      "dismiss": {
          "duration": dismissDuration,
      }

    });

    }

    getUserInfo = () =>{
      let luiserv = new LoggedinUserInfoService();
      luiserv.getLoggedInUserInfo({}).then(data =>{
          
      let respUser = data.data.entity;
      if(respUser){
        let isKycVerified = respUser.isKycVerified!==undefined&&respUser.isKycVerified!==null?respUser.isKycVerified:false;
        if(isKycVerified){
          this.switchToTab("PROFILE_USER");
        }else{
          this.switchToTab("PROFILE_KYC");
        }

      }

          
      }).catch(err=>{
          console.log("Some server related tech problem occured",err);
      });
    }

    checkBeforeSwitchOnFirstimeLoad = () => {      
      this.getUserInfo();
      setTimeout(()=>{
        // some exception occured so need to show some page after 7 seconds
        if(this.state.tabOption==""){
          this.switchToTab("PROFILE_USER");
        }
      },7000);
    }

    loadTabContent = () => {
      if(this.state.tabOption=="PROFILE_USER"){
          return (<ProfileUser showsuccessorerrormessage={this.showSuccessOrErrorMessage} />);
      }else if(this.state.tabOption=="PROFILE_KYC"){
        return (<ProfileKyc showsuccessorerrormessage={this.showSuccessOrErrorMessage} />);
      }
    }

    switchToTab =  (tabName) => {
        this.isComponentLoaded && this.setState({"tabOption":tabName});
    }

  

    /**  
     * ProfileSettings
     * Function name : render
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() {  

      return (
        <>
        <div ref={this.refDivTop} className="kyc_section_part_01">
            <CustomMessageBar />
            <div className="dashboard_cont_area tasklist_cont_areabg setting_cont_areabg setting_top_tab_section">
              {/* ===  DashBoard Main Body Start ===  */}
              <div className="cutom_container cutom_container_userSetting">
              
                <div className="create_task_area userSetting_area">
                  {/* ===  task Listing filter start === */}
                  <div className="task_area_filterbg task_area_filterbg_lp">
                    <div className="filter_area ">
                      {/* ===  task top filter start ===  */}
                      <div className="top_filter_left top_filter_left_lp">
                        <div className="top_filter_backbtn_bg top_filter_backbtn_bg_lp">
                          {/* == profile setting ul start ==   */}
                          <div className="profile_setting_ulbg pr_set_ulbg">
                            
                            <ul>
                                <li   className={` ${this.state.tabOption=="PROFILE_KYC"?"active":""}`}>
                                  <button className="btn_appearance_none task_ass_tabBtn" onClick={(evn)=>{ 
                                    evn.preventDefault();
                                    this.switchToTab("PROFILE_KYC");
                                  
                                  }} >
                                    <span className="task_ass_span">KYC Details</span>
                                  </button>
                                </li>
                                <li className={` ${this.state.tabOption=="PROFILE_USER"?"active":""}`}>
                                  <button className="btn_appearance_none task_ass_tabBtn" onClick={(evn)=>{ 
                                    evn.preventDefault();
                                    this.switchToTab("PROFILE_USER"); 
                                  }} >
                                    <span className="task_ass_span">Profile</span>
                                  </button>
                                </li>
                              </ul>
                          </div>
                          {/* == profile setting ul end ==   */}
                          <div className="clearboth" />
                        </div>
                      </div>
                      {/* ===  task top filter end === */}
                      <div className="clearboth" />
                    </div>
                  </div>
                  {/* ===  task Listing filter end === */}
                  {/* ===========  Setting Page main body start =============   */}
                  {/* ===  task Listing start start === */}
                  {/* ===  task Listing start end === */}
                  {/* ===========  Setting Page main body end =============   */}
                </div>
              </div>
              {/* ===  DashBoard Main Body End ===  */}
            </div>
        </div>
        <div className={"kyc_section_part_02"} >
          {/* ===========  Setting Page main body start =============   */}
          {this.loadTabContent()}
          {/* ===========  Setting Page main body end =============   */}
        </div>
        </>
      );
    }
}
export default ProfileSettings;