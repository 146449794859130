/**
 * AssignButton
 * Page/Class name   : AssignButton
 * Author            :
 * Created Date      : 24-4-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : rendering loader structure
**/
import React, { Component, Suspense, lazy} from 'react';
import '../../../custom_resources/css/custom.css';

// Modal
import CustReactBootstrapModal from './CustReactBootstrapModal';
import CustomMessageBar from '../../messagebar/CustomMessageBar';
// Services
import AssignTaskOrSubTaskService from '../../service/AssignTaskOrSubTaskService';
import TaskDetailsService from '../../service/TaskDetailsService';
// for context
import CommonContext from '../../context/CommonContext';

import {notifytasklistontaskstatuschange} from './TaskListOfSingleTask';
import {notifymultitasklistontaskstatuschange} from './TaskListOfMultiTask';

import  CustDate  from '../../../common_utilities/CustDate';

import { commonJsFuncModule as commonJsObj } from '../../../common_utilities/commonjsfunc';

const getBaseUrl = commonJsObj.getBaseUrl;
const getBaseUrlOfLp = commonJsObj.getBaseUrlOfLp;
const getSiteName = commonJsObj.getSiteName;


class AssignButton extends Component {
    /**
     * AssignButton
     * Function name : constructor
     * Author        :
     * Created Date  : 
     * Purpose       : initializing state
     * Params        : props
    **/

   isComponentLoaded = false;

   // for context
   static contextType = CommonContext; 

    constructor(props) {
        super(props);
        this.state = {

            showConflictInfoModal: false,
            showEngageAgreeModal: false,
            agreeFlag: false,
            assignRel: null

            
        }    

        this.modalforconflmessgRef =  React.createRef();
        this.modalforengagreeRef = React.createRef();
    }

    /**
     * AssignButton
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentDidMount() {
        this.isComponentLoaded = true;

        
        
    }

    /**
     * AssignButton
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {  
        this.isComponentLoaded = false;
    }

    async getTaskDetailData(param){   

        let respData = null;
        let lservdrf = new TaskDetailsService();
        await lservdrf.getTaskDetail(param).then(data =>{ 
    
          respData  = data.data.entity; 
    
            
    
        }).catch((err)=>{                       
            
          let errStatusCodeAr = [400,500]; // allowed error status code
          if(err.response && err.response.data){
              let erRespData = err.response.data;
              if(errStatusCodeAr.indexOf(erRespData.status) !== -1){         
                  let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                  console.log(" errMsg on task fetch info==>",errMsg);
                  // close split screen
                  //this.closeSplit();
                                   
          }
          } 
  
  
        }).finally(()=>{
           
        });
        
        return respData;
      }

    sendAssignment = async (paramToPost,otherData) =>{  
       
        

        let stcservice = new AssignTaskOrSubTaskService();
        stcservice.makeAssignmentOfTaskOrSubTask(paramToPost).then(data =>{
              if(data.status==200){
                  let respData ={};
                  if(data.data.entity!==undefined){
                   
                    this.gotoMonitorScreen({});
                    
                    
              }
          }
          }).catch((err)=>{
                  
                  let errStatusCodeAr = [400,500]; // allowed error status code
                  if(err.response && err.response.data){
                      let erRespData = err.response.data;
                      if(errStatusCodeAr.indexOf(erRespData.status) !== -1){         
                          let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                          console.log(errMsg);
                                           
                  }
                  } 

                setTimeout(()=>{
                    this.getEngageAgreeModalContent(otherData);
                },1800);
                
          }).finally(()=>{  
            this.isComponentLoaded && this.setState({"agreeFlag":false},()=>{
              
            });
                       
              
          });
        
      }

      gotoMonitorScreen = async (otherParam) => {

        let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;

        let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;

        if(sharedData && setShare){        
            let paramObj1 = {
                    "data":{
                    "taskId":sharedData.taskId
                    }
             };         
            // set latest task details resp data to other.taskResp
            sharedData.other.taskResp = await this.getTaskDetailData(paramObj1);
        }

        this.engageAgreeModalClose();
        notifytasklistontaskstatuschange("ASSIGNED",{
        });
        notifymultitasklistontaskstatuschange("ASSIGNED",{
        });

        
        setTimeout(()=>{

        //** go to monitor screen starts **
        // context share

        if(sharedData && setShare){
            sharedData.leftTabToActive = "MONITORTASK";
                sharedData.purpose = "VIEW_RESPONSE_LIST";
                sharedData.other.triggeredFrom = "PROFESSIONAL_INTERESTED_LIST";

                setShare(sharedData);  
        }
        //** go to monitor screen ends **

        },1200);

      }

    openPopupBasedOnConflictStatus = (dataObj)=> {

        //conflictCheckStatus
        //console.log(" *******dataObj==>",dataObj);
        let conflictCheckStatusFlag = false;
        if(this.state.assignRel && this.state.assignRel.conflictCheckStatus=="IN_PROGRESS"){
            // open popup to inform  conflic status is in progress
            this.conflictStatusInfoModalOpen({});

        }else{
            // open popup of agreement
            this.engageAgreeModalOpen({});
            

        }

    }


    /*** Conflict status Modal  starts  */
    /**
     * TaskListOfSingleTask
     * Function name : TaskListOfSingleTask
     * Author        :
     * Created Date  : 29-3-2020
     * Purpose       : To open delete modal
     * Params        : 
    **/  
   conflictStatusInfoModalOpen = (otherParam)=>{    
    this.isComponentLoaded &&  this.setState({showConflictInfoModal:true},()=>{
      this.modalforconflmessgRef.current.showModal();
      this.getConflictStatusInfoModalContent(otherParam);
    });    
      
  }

  /**
     * TaskAddSingleTaskStep2
     * Function name : organisationDetailModalClose
     * Author        :
     * Created Date  : 11-2-2020
     * Purpose       : To close organisationDetailModal
     * Params        : 
    **/
   conflictStatusInfoModalClose = ()=>{    
    this.isComponentLoaded && this.setState({showConflictInfoModal:false},()=>{
      this.modalforconflmessgRef.current.hideModal();
    });
    }

    /**
   * TaskAddSingleTaskStep2
   * Function name : getOrganisationDetailModalOpen
   * Author        :
   * Created Date  : 11-2-2020
   * Purpose       : Get modal view with data
   * Params        : 
  **/
    getConflictStatusInfoModalContent(otherParam) {   
       
        //console.log(" NOWW otherParam==>",otherParam);
        //let taskName = otherParam.taskName!==undefined?otherParam.taskName:"";
        let taskName = this.state.assignRel.taskOrSubTaskName!==undefined?this.state.assignRel.taskOrSubTaskName:"";
        let lawyerName = this.state.assignRel.lpUserName!==undefined?this.state.assignRel.lpUserName:"";
        /*
        let lawyerName = "";
        
        if(otherParam.lpUserInterestedData!==undefined && otherParam.lpUserInterestedData.userDetails!==undefined){
            let taskRespObj = otherParam.lpUserInterestedData;
            let lawyerFname = (taskRespObj.userDetails.firstName!==undefined && taskRespObj.userDetails.firstName!==null)?taskRespObj.userDetails.firstName:"";

            let lawyerLname = (taskRespObj.userDetails.lastName!==undefined &&  taskRespObj.userDetails.lastName!==null)?taskRespObj.userDetails.lastName:"";
    
             lawyerName = lawyerFname+" "+lawyerLname;
        }
        */


        


        let viewData = <div className="dash_modal">
        
                <>
            <div className="modal-header">
                <h4 className="modal-title modal_title_tool"> Offer Task
                {/*<div className="input_tooltip_bg">
                        <span className="input_tooltip" aria-label="Organisation's Details" data-balloon-pos="down">
                        <i className="fa fa-question-circle"></i>
                        </span>
                        </div>*/}
                </h4>
                <button type="button" onClick={
                    ()=>{
                    this.conflictStatusInfoModalClose();

                }} className="close modal_close" data-dismiss="modal">&times;</button>
            </div>
            <CustomMessageBar />            
            <div className="modal-body padding_top">
            
            <div class="popUP_text_p">
                    {/* <p>
            You have started the conflict check progress with lawshram for task &nbsp; <b>{taskName}</b> &nbsp;&nbsp; for&nbsp;&nbsp; <b> {lawyerName} </b>             
                    </p> */}

                    <p>
                    You have started conflict check, for which status is awaited. Do you still want to assign task?
                    </p>


              </div>
            </div>
            <div className="modal-footer">

                <button   type="button" className="btn_appearance_none modal_text_btn " onClick={(e)=>{ e.preventDefault();
                    this.conflictStatusInfoModalClose();
                        

                } } data-dismiss="modal"><span>Cancel</span></button>
               
                <button  type="button" className="btn btn-primary modal_btn_save" onClick={(e)=>{ e.preventDefault();
                    this.conflictStatusInfoModalClose();
                    setTimeout(()=>{
                        this.engageAgreeModalOpen(otherParam);
                    },1000);
                    

                } } data-dismiss="modal"><span>Confirm</span></button>
                
            </div>
          
          
          </>
        
                        
        </div>;
    
        this.modalforconflmessgRef.current.updateStateContent(viewData);
        this.modalforconflmessgRef.current.showContent();
        return viewData;
    }    

    /*** Conflict status Modal ends  */

    /*** Engagement Modal  starts  */
    /**
     * AssignButton
     * Function name : engageAgreeModalOpen
     * Author        :
     * Created Date  : 29-3-2020
     * Purpose       : To open delete modal
     * Params        : 
    **/  
   engageAgreeModalOpen = (otherParam)=>{    
    this.isComponentLoaded &&  this.setState({showEngageAgreeModal:true},()=>{
      this.modalforengagreeRef.current.showModal();
      this.getEngageAgreeModalContent(otherParam);
    });    
      
  }

  /**
     * AssignButton
     * Function name : engageAgreeModalClose
     * Author        :
     * Created Date  : 11-2-2020
     * Purpose       : To close organisationDetailModal
     * Params        : 
    **/
   engageAgreeModalClose = ()=>{    
    this.isComponentLoaded && this.setState({showEngageAgreeModal:false},()=>{
      this.modalforengagreeRef.current.hideModal();
    });
    }

     /**
   * AssignButton
   * Function name : getEngageAgreeModalContent
   * Author        :
   * Created Date  : 11-2-2020
   * Purpose       : Get modal view with data
   * Params        : 
  **/
    getEngageAgreeModalContent(otherParam) { 
        let taskResp = null;
        
        let ownerName = "";
        let organisationName = (this.state.assignRel.organisationName!==undefined && this.state.assignRel.organisationName!==null)?this.state.assignRel.organisationName: "";
        let lpUserName = (this.state.assignRel.lpUserName!==undefined && this.state.assignRel.lpUserName!==null)?this.state.assignRel.lpUserName: "";
        let taskName = this.state.assignRel.taskOrSubTaskName!==undefined?this.state.assignRel.taskOrSubTaskName: "";
        let taskOrSubTask = this.state.assignRel.taskOrSubTask!==undefined?this.state.assignRel.taskOrSubTask: "";
        //let lpUserInterestedData = otherParam.lpUserInterestedData!==undefined?otherParam.lpUserInterestedData: null;

        let agreedBudget = (this.state.assignRel.agreedBudget!==undefined && this.state.assignRel.agreedBudget!==null)?this.state.assignRel.agreedBudget: "";
        let agreedBudgetCurrency =  (this.state.assignRel.agreedBudgetCurrency!==undefined && this.state.assignRel.agreedBudgetCurrency!==null)?this.state.assignRel.agreedBudgetCurrency: "";
        let agreedDueDate = (this.state.assignRel.agreedDueDate!==undefined && this.state.assignRel.agreedDueDate!==null)?CustDate.custFormatDate((new Date(this.state.assignRel.agreedDueDate)),3):"No Due Date";
        let termsAcceptedOn = (this.state.assignRel.termsAcceptedOn!==undefined && this.state.assignRel.termsAcceptedOn!==null)?CustDate.custFormatDate((new Date(this.state.assignRel.termsAcceptedOn)),3):"";


        
        /*
        let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;
        if(sharedData!==null){
            taskResp = sharedData.other!==undefined && sharedData.other!==null && sharedData.other.taskResp!==undefined?  sharedData.other.taskResp:null;

            //console.log(" taskResp=>",taskResp," otherParam=>>",otherParam);
            let owner = taskResp.owner!==undefined && taskResp.owner!==null? taskResp.owner : null;

            let ownFnm = owner.firstName!==undefined && owner.lastName!==null? owner.firstName : "";
            let ownLnm = owner.lastName!==undefined && owner.lastName!==null? owner.lastName : "";

            ownerName = ownFnm+" "+ ownLnm;

            organisationName = taskResp.organisationName!==undefined && taskResp.organisationName!==null? taskResp.organisationName : "";

            taskName = taskResp.taskName!==undefined && taskResp.taskName!==null? taskResp.taskName : "";

            let negotiationStatus = lpUserInterestedData.negotiationStatus!==undefined && lpUserInterestedData.negotiationStatus!==null?lpUserInterestedData.negotiationStatus:null; 

            agreedBudget = negotiationStatus.agreedBudget!==undefined&& negotiationStatus.agreedBudget!==null?negotiationStatus.agreedBudget:"";  

            agreedBudgetCurrency = negotiationStatus.agreedBudgetCurrency!==undefined&& negotiationStatus.agreedBudgetCurrency!==null?negotiationStatus.agreedBudgetCurrency:"";  

            agreedDueDate = negotiationStatus.agreedDueDate!==undefined&& negotiationStatus.agreedDueDate!==null?CustDate.custFormatDate((new Date(negotiationStatus.agreedDueDate)),3):"No Due Date";

            let termsAcceptanceStatus = negotiationStatus.termsAcceptanceStatus!==undefined && negotiationStatus.termsAcceptanceStatus!==null?negotiationStatus.termsAcceptanceStatus:null;

            termsAcceptedOn = termsAcceptanceStatus.termsAcceptedOn!==undefined && termsAcceptanceStatus.termsAcceptedOn!==null?CustDate.custFormatDate((new Date(termsAcceptanceStatus.termsAcceptedOn)),3):"";

        }
        */
        

        let viewData = <div className="dash_modal">
        
                <>
            <div className="modal-header">
                <h4 className="modal-title modal_title_tool"> Engagement Letter
                {/*<div className="input_tooltip_bg">
                        <span className="input_tooltip" aria-label="Organisation's Details" data-balloon-pos="down">
                        <i className="fa fa-question-circle"></i>
                        </span>
                        </div>*/}
                </h4>
                <button type="button" onClick={
                    ()=>{
                    this.engageAgreeModalClose();

                }} className="btn_appearance_none slideTop_cross_btn cross" data-dismiss="modal">
                <img src={getBaseUrl()+"assets/images/red_cross.png"} />
            </button>
            </div>
            <CustomMessageBar />            
            <div className="modal-body padding_top">
            
            <div className="engagementPop_p">
             
                <div className="engaLtr_title">
            A PROFESSIONAL ENGAGEMENT POWERED BY {(getSiteName().toUpperCase())}
                </div>
                <div className="engaLtr_contBlock">
                    <div className="engaLtr_half">
                    Organisation: <b>{organisationName}</b>
                    </div>
                    <div className="engaLtr_half">
                    {" "}
                    Professional: <b>{lpUserName}</b>
                    </div>
                </div>
                <div className="engaLtr_contBlock">
                    <div className="engaLtr_full">
                    <p>
                        Congratulations on agreeing to complete the task <b>"{taskName}"</b>
                    </p>
                    </div>
                    <div className="engaLtr_full">
                    <p>
                        The Professional shall complete the Task using the {getSiteName()} Platform by{" "}
                        <b>{agreedDueDate}.</b>
                    </p>
                    </div>
                    <div className="engaLtr_full">
                    <p>
                        The Professional shall generate an invoice using the {getSiteName()} Platform.
                    </p>
                    </div>
                    <div className="engaLtr_full">
                    <p>
                        The Organisation shall pay the invoice amount <b>{agreedBudgetCurrency} {agreedBudget}.</b>
                    </p>
                    </div>
                    <div className="engaLtr_full">
                    <p>
                        The Professional hereby grants a non-exclusive, perpetual, world-wide
                        license to the Organisation to use the intellectual property created in
                        the deliverable of the Task.
                    </p>
                    <p>
                        The Professional shall be liable under applicable laws for the
                        deliverable under the Task or the part thereof including but not limited
                        to professional liability.
                    </p>
                    <p>
                        The Professional and the Organisation hereby agree that, {getSiteName()} will
                        only facilitate the workflow between the Organisation and the
                        Professional and shall not be responsible for accuracy and quality of
                        the Professional’s services rendered herein or for the payment
                        obligations of the Organisation.
                    </p>
                    </div>
                    <div className="engaLtr_full">
                    <p>
                        The terms and conditions of use for the <a href={getBaseUrlOfLp()+"terms_privacy/terms_professional.pdf"} target="_blank">Professional</a> and
                        the <a href={getBaseUrl()+"terms_privacy/terms_organisation.pdf"} target="_blank">Organisation</a> are hereby incorporated by reference
                        (the <b>“Terms and Conditions”</b>). Words and expressions used, but not
                        defined in this engagement letter shall have the meanings as assigned to
                        them in the Terms and Conditions.
                    </p>
                    </div>
                    <div className="engaLtr_full">
                    <p>
                        Any dispute arising out of this engagement letter shall be submitted to
                        an online dispute resolution mechanism provided by {getSiteName()}.
                    </p>
                    <p>
                        This engagement letter shall be construed and interpreted in accordance
                        with the laws of Republic of India.
                    </p>
                    </div>
                    <div className="engaLtr_tableLayout">
                    <div className="engaLtr_tableLayout_half engaLtr_tableLayout_left">
                        <div className="engaLtr_tableLayout_block engaLtr_tableLayout_block_sign">
                        Signed by the Organisation
                        </div>
                        <div className="engaLtr_tableLayout_block engaLtr_tableLayout_block_date">
                        Date - {CustDate.custFormatDate((new Date()),3)}
                        </div>
                    </div>
                    <div className="engaLtr_tableLayout_half engaLtr_tableLayout_right">
                        <div className="engaLtr_tableLayout_block engaLtr_tableLayout_block_sign">
                        Signed by the Professional
                        </div>
                        <div className="engaLtr_tableLayout_block engaLtr_tableLayout_block_date">
                        Date - {termsAcceptedOn}
                        </div>
                    </div>
                    <div className="clearboth" />
                    </div>
                </div>
                <div className="clearboth" />


            </div>
            </div>
            <div className="modal-footer">

            
            
                <button disabled={this.state.agreeFlag}  type="button" className="btn btn-primary modal_btn_save" onClick={(e)=>{ e.preventDefault();
                    this.makeAssignMent(otherParam);
                                
                    

                } } data-dismiss="modal"><span>I Agree</span></button>
                
            </div>
        
        
        </>
        
                        
        </div>;

        this.modalforengagreeRef.current.updateStateContent(viewData);
        this.modalforengagreeRef.current.showContent();
        return viewData;
    }    

    /*** Engagement status Modal ends  */


    makeAssignMent = (otherParam) => {

        console.log("from makeAssignMentmakeAssignMent===>",this.state.assignRel);
        let taskId = 0;
        let subTaskId = 0;
        let assigneeId = 0;

        let assignPostParam= {};

        if(this.state.assignRel.lpUserId!==undefined ){
            assigneeId =  this.state.assignRel.lpUserId;
        }

        if(this.state.assignRel.taskOrSubTask!==undefined && this.state.assignRel.taskIdOrSubTaskId!==undefined){
            if(this.state.assignRel.taskOrSubTask=="STASK"){
                taskId = this.state.assignRel.taskIdOrSubTaskId;

                assignPostParam["taskId"] = taskId;
                assignPostParam["assigneeId"] = assigneeId;
            }
            else if(this.state.assignRel.taskOrSubTask=="SWSTASK"){
                subTaskId = this.state.assignRel.taskIdOrSubTaskId;

                assignPostParam["subTaskId"] = subTaskId;
                assignPostParam["assigneeId"] = assigneeId;
            }
        }

        // hit assign api
        //console.log(" assignPostParam=>",assignPostParam);   
        
        this.isComponentLoaded && this.setState({"agreeFlag":true},()=>{
            this.getEngageAgreeModalContent(otherParam);

            setTimeout(()=>{
                this.sendAssignment({"data":assignPostParam},otherParam);
            },1200);
        });
        
        
        
    }
   

    /**
     * AssignButton
     * Function name : render
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() {    
       
       return (
             <>                
                <button onClick={(evn)=>{
                        evn.preventDefault();
                        this.isComponentLoaded && this.setState({"assignRel":this.props.datas},()=>{
                            this.openPopupBasedOnConflictStatus();
                        });
                        
                        

                      }} className={`${this.props.custclassname?this.props.custclassname:""}`}>
                      <span>Assign</span>
                </button>
               

                {/* ConflictInfo Modal start */}
                <CustReactBootstrapModal ref={this.modalforconflmessgRef}  backdrop={false} backdropClassName="custom_modal_class "   animation={false} show={this.state.showConflictInfoModal} onShow={()=>{}} onHide={()=>{}} modalcustdialog="custom_modal_lg conflictstatusinfomsgpopupcls"  loaderbodytitle="Organisation"  />
                {/* ConflictInfo Modal end */}

                {/* Engagement Agreement Modal start */}
                <CustReactBootstrapModal ref={this.modalforengagreeRef}  backdrop={false} backdropClassName="custom_modal_class "   animation={false} show={this.state.showEngageAgreeModal} onShow={()=>{}} onHide={()=>{}} modalcustdialog="custom_modal_lg engageagreepopupcls engagementLetter_modal_bg"  loaderbodytitle="Organisation"  />
                {/* Engagement Agreement Modal end */}



             </>
            
        );
    }

  }
  export default AssignButton;