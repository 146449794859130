/**  
 * MarkInvoicePaidService
 * Page/Class name   : MarkInvoicePaidService
 * Author            :
 * Created Date      : 16-62020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, getTweetRecords
 * Purpose           : api service page
**/
import {Component } from 'react';
// import {getEndPoint,getTokenFromLocStorage, callApi} from  '../../common_utilities/commonjsfunc';
// newly added axios
//import axios from 'axios';
import {axiosCustomObj} from '../customisedthirdparty/AxiosCustom';

import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
const callApi = commonJsObj.callApi;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;
const getMaxPageSize = commonJsObj.getMaxPageSize;


class MarkInvoicePaidService extends Component {

    
    /**  
     * MarkInvoicePaidService
     * Function name : constructor
     * Author        :
     * Created Date  : 16-6-2020
     * Purpose       : initializing state
     * Params        : 
    **/
    constructor() {
      super();
      this.state = {
          
      }

      //this.axiosObj = axios;
      this.axiosObj = null;
      
      this.apiEndPoint = commonJsObj.getEndPoint()+'rest/tasks/invoice/pay';
    }

    /**  
     * MarkInvoicePaidService
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 16-6-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentDidMount() {
    }

    /**  
     * MarkInvoicePaidService
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 16-6-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentWillUnmount() {  
    }



    /**  
     * MarkInvoicePaidService
     * Function name : getLoginResp
     * Author        :
     * Created Date  : 16-6-2020
     * Purpose       : Login check to provide access token 
     * Params        : paramObj
    **/
    async makeInvoicePaid(paramObj){
      this.axiosObj = axiosCustomObj({});
      let accToken = getTokenFromLocStorage();   
      let offsetOfLocalDateTime = getOffsetOfLocalDateTime(new Date());

      try{
            let urldata =this.apiEndPoint; 

            // const defaultPageSize = getMaxPageSize();
            // let pageNum = paramObj.pageNum?paramObj.pageNum:1;
            // let pageSize = paramObj.pageSize?paramObj.pageSize:defaultPageSize;

            let offsetHr = offsetOfLocalDateTime.hr;
            let offsetMin = offsetOfLocalDateTime.min;
           
            let data  = paramObj.data;
            let lpUserId = data.lpUserId?data.lpUserId:0;
            

            let taskIdOrSubTaskId = data.taskIdOrSubTaskId?data.taskIdOrSubTaskId:0;
            let taskOrSubTask = data.taskOrSubTask?data.taskOrSubTask:"";
            let invoiceId =data.invoiceId?data.invoiceId:"";

            

            if(taskOrSubTask=="STASK"){
              urldata = urldata+"/"+invoiceId+"/task/"+taskIdOrSubTaskId+"/?offset_hour="+offsetHr+"&offset_minute="+offsetMin;
            }
            else if(taskOrSubTask=="SWSTASK"){
              urldata = urldata+"/"+invoiceId+"/sub_task/"+taskIdOrSubTaskId+"/?offset_hour="+offsetHr+"&offset_minute="+offsetMin;
            }
            

            //console.log("task single list api hitted with =>>",urldata);


            let headerOptn = {  
              "Content-Type": "application/json",
              "Authorization": accToken
            };          

            let respObj = this.axiosObj.put(urldata,{},{
            headers: headerOptn
            });

            return respObj;
    }
    catch(err){
        console.log("Some error occured===>",err); // TypeError: failed to fetch
    }
      

    }

    
}
export default MarkInvoicePaidService;