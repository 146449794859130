/**
 * NotiEmitTrack
 * Page/Class name   : NotiEmitTrack
 * Author            :
 * Created Date      : 25-6-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : rendering loader structure
**/
import React, { Component, Suspense, lazy} from 'react';
import '../../../custom_resources/css/custom.css';

import ee from 'event-emitter';

// for context
import CommonContext from '../../context/CommonContext';


import { commonJsFuncModule as commonJsObj } from '../../../common_utilities/commonjsfunc';

const validateBlankSpace = commonJsObj.validateBlankSpace;
const getBaseUrl = commonJsObj.getBaseUrl;


const emitter = new ee();

export const commonnotitrack = (optionName,otherParam) => {
    emitter.emit('commonnotitrack', optionName, otherParam);
}



class NotiEmitTrack extends Component {
    /**
     * NotiEmitTrack
     * Function name : constructor
     * Author        :
     * Created Date  : 
     * Purpose       : initializing state
     * Params        : props
    **/

   isComponentLoaded = false;

   // for context
   static contextType = CommonContext; 

    constructor(props) {
        super(props);
        this.state = {};

         // for event emitter 
         emitter.on('commonnotitrack',(optionName,otherParam)=>{        
            
            switch(optionName){
                case "TASK_TERMS":
                    if(this.props.callbackfuncforcommontrackofnoti!==undefined){                  
                    this.props.callbackfuncforcommontrackofnoti(otherParam);
                    }                  
                    break;
                case "TASK_ACCEPTED":
                    if(this.props.callbackfuncforcommontrackofnoti!==undefined){                  
                    this.props.callbackfuncforcommontrackofnoti(otherParam);
                    }                  
                    break;
                case "TASK_DECLINED":
                    if(this.props.callbackfuncforcommontrackofnoti!==undefined){                  
                    this.props.callbackfuncforcommontrackofnoti(otherParam);
                    }                  
                    break;
                case "NEGOTIATION":    
                    if(this.props.callbackfuncforcommontrackofnoti!==undefined){              
                    this.props.callbackfuncforcommontrackofnoti(otherParam);
                    }                  
                    break;
                case "NEW_COMMENT": 
                    if(this.props.callbackfuncforcommontrackofnoti!==undefined){                 
                    this.props.callbackfuncforcommontrackofnoti(otherParam);
                    }                  
                    break;
                case "KYC":
                    if(this.props.callbackfuncforcommontrackofnoti!==undefined){                  
                        this.props.callbackfuncforcommontrackofnoti(otherParam);
                        }                  
                    break;
                case "SUBSCRIPTION_SUCCESS":
                    if(this.props.callbackfuncforcommontrackofnoti!==undefined){                  
                        this.props.callbackfuncforcommontrackofnoti(otherParam);
                        }                  
                    break;
                case "SUBSCRIPTION_FAILURE":
                    if(this.props.callbackfuncforcommontrackofnoti!==undefined){                  
                        this.props.callbackfuncforcommontrackofnoti(otherParam);
                        }                  
                    break;
                    
                case "CLOSE_SPLITSCREEN":   
                        let notiType =  otherParam.notiType!==undefined? otherParam.notiType: "";
                        let triggerCallback =  otherParam.triggerCallback!==undefined? otherParam.triggerCallback: false; 
                        let trigerredFrom =  otherParam.trigerredFrom!==undefined? otherParam.trigerredFrom: ""; 
                        if(triggerCallback){
                            if(this.props.callbackfuncforcommontrackofnoti!==undefined){
                            this.props.callbackfuncforcommontrackofnoti(otherParam); 
                            }
                        }else{
                            //********* for context starts *****
                            let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;  
                            
                            let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;

                            if(sharedData!==undefined && sharedData!==null){         

                                if(sharedData.actionType!== undefined && sharedData.actionType=="SPLITSCREEN" ){

                                    let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;

                                    if(setShare){ 
                                    let paramsCntxt = sharedData;
                                    paramsCntxt["taskId"] = "";
                                    paramsCntxt["actionType"] = "";
                                    paramsCntxt["leftTabToActive"] = "";
                                    paramsCntxt["purpose"] = "";
                                    paramsCntxt["other"] = {};
                                    setShare(paramsCntxt);                              
                                    }

                                    
                                }
                            }
                            //********* for context ends *****    
                        }       
                        break;
                case "SCROLL_TO_MAIN_HEADER":                  
                        if(this.props.callbackfuncforcommontrackofnoti!==undefined){
                            this.props.callbackfuncforcommontrackofnoti(otherParam);
                        }                    
                        break;
            
            }          

        });

       
    }

    /**
     * NotiEmitTrack
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentDidMount() {
        this.isComponentLoaded = true;

        
        
    }

    /**
     * NotiEmitTrack
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {  
        this.isComponentLoaded = false;
    }

   

    /**
     * NotiEmitTrack
     * Function name : render
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() {    
       
       return (
             <>                
              
             </>
            
        );
    }

  }
  export default NotiEmitTrack;