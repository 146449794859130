/**  
 * SplitScreenTimeLogs
 * Page/Class name   : SplitScreenTimeLogs
 * Author            :
 * Created Date      : 7-5-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : render master component
**/
import React, { Component } from 'react';

import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";
// for context
import CommonContext from '../../context/CommonContext';
// scroll 
// import { Scrollbars } from 'react-custom-scrollbars';
import  CustDate  from '../../../common_utilities/CustDate';
// For loading CustLoader
import CustLoader from './CustLoader';
import { commonJsFuncModule as commonJsObj } from '../../../common_utilities/commonjsfunc';

import {notify} from '../../messagebar/CustomMessageBar';
import CustomMessageBar from '../../messagebar/CustomMessageBar';

//services
import TaskDetailsService from '../../service/TaskDetailsService';
import TaskTimeLogListService from '../../service/TaskTimeLogListService';
import NotifyToUpdateTimeLogService from '../../service/NotifyToUpdateTimeLogService';

import SplitScreenLeftMenu from "../../share/SplitScreenLeftMenu";

// common noti track
import NotiEmitTrack from "./NotiEmitTrack";
import {commonnotitrack} from "./NotiEmitTrack";


// new scroll
import InfiniteScroll from 'react-infinite-scroll-component';

const getDateObjFromMilliseconds = commonJsObj.getDateObjFromMilliseconds;
const getTimestampInMillisecondsFromDateObj = commonJsObj.getTimestampInMillisecondsFromDateObj;
// const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;
// const sortArrayOfJsonByKey = commonJsObj.sortArrayOfJsonByKey;
const getBaseUrl = commonJsObj.getBaseUrl;
const getCcUserId = commonJsObj.getCcUserId;
const convertMinutueToHour = commonJsObj.convertMinutueToHour;
const getStringInitials = commonJsObj.getStringInitials;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getDateFormatForCal = commonJsObj.getDateFormatForCal;


const ExampleCustomInput = ({ value, onClick, keydata, placeholdertxt }) => (    
  <input
  type="text"
  className="form__field customdatefld"
  placeholder={placeholdertxt}
  defaultValue={value}
  onClick={onClick}
  key={keydata}
  />
);



class SplitScreenTimeLogs extends Component {

    isComponentLoaded =  false;
    // for context
    static contextType = CommonContext; 

    /**  
     * SplitScreenTimeLogs
     * Function name : constructor
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : initializing children
     * Params        : props
    **/
    constructor(props) {
      super(props);
      this.children = props.children;

      this.state = {
        "listData": [],
        "pageNum": 1,
        "totalNumberOfPages": 1,
        "loaderFlag": true,
        "pageNumArTrack": [],
        "lpUserId": 0,
        "ccUserId": 0,
        "taskOrSubTask": '',
        "taskIdOrSubTaskId": 0,
        "totalMinutesLogged": 0,
        "timeLogCreatorDetails":null,
        "lpUserName": "",
        "sortDir":"",
        "sortOn":"",
        "dateSortDirClass":"",
        "timeSortDirClass":"",
        "dateFrom": null,
        "dateTo":null,
        "dateFilterFlag": false,
        "dateFromErrMsg":"",
        "dateToErrMsg":"",
        "requestTimeLogUpdFlag": false,
        "isTaskCompleted": false


        

      };
      this.scrollerRef = React.createRef();
    }

    /**  
     * SplitScreenTimeLogs
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentDidMount() {
      this.isComponentLoaded = true;

      let taskOrSubTask = "";
      let taskIdOrSubTaskId = 0;
      let lpUserId = 0;
      let ccUserId = 0;
      let triggeredFrom = '';  
      let isTaskCompleted = false;
     
      //********* for context starts *****
      let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;      
      if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){  

        if(sharedData.actionType=="SPLITSCREEN" ){
          if(sharedData.other!==undefined && sharedData.other!==null){            
                     
            triggeredFrom  = sharedData.other.triggeredFrom!==undefined?sharedData.other.triggeredFrom:"";
            //*** when user comes by clicking on monitor icon from split screen left menu starts
            if(triggeredFrom=="TASK_SPLIT_SCREEN_LEFT_MENU"||triggeredFrom=="BELL_NOTIFICATION_MENU"){

                //console.log(" sharedData.other==>",sharedData.other);
            
                taskOrSubTask = (sharedData.other.subTasksCount!==undefined && sharedData.other.subTasksCount!==null && sharedData.other.subTasksCount>0)?"SWSTASK":"STASK";  

                if(taskOrSubTask=="STASK"){
                  

                  if(sharedData.other.taskResp!==undefined && sharedData.other.taskResp!==null){

                    let taskResp = sharedData.other.taskResp;

                    taskIdOrSubTaskId = (taskResp.id!==undefined && taskResp.id!==null)?taskResp.id:0;

                   
                    lpUserId = ((taskResp.assignedTo!==undefined && taskResp.assignedTo!==null)&&(taskResp.assignedTo.userId!==undefined &&taskResp.assignedTo.userId!==null)) ? taskResp.assignedTo.userId:0;

                    isTaskCompleted = (taskResp.isTaskCompleted!==undefined && taskResp.isTaskCompleted!==null)?taskResp.isTaskCompleted:false;

                  }

                }
                else if(taskOrSubTask=="SWSTASK"){
                  // this scenario i.e task with sub task is not in sprint 5

                } 
                
                ccUserId = getCcUserId();
            } 
            //*** when user comes by clicking on monitor icon from split screen let menu ends
            //*** when user comes by clicking on monitor icon from professional interested list starts

            //*** when user comes by clicking on monitor icon from professional interested list ends
            
          }

          
        }

        //console.log(" Now taskOrSubTask=>",taskOrSubTask," taskIdOrSubTaskId=>",taskIdOrSubTaskId, " lpUserId=>",lpUserId," ccUserId==>",ccUserId," triggeredFrom=>",triggeredFrom);

      }


      // set list data to blank state
      this.showLoader();
      setTimeout(()=>{
      
     
        // call list api         
        let paramObj = {"pageNum":1,"pageNumArTrack": [], "listData": [],"totalNumberOfPages":1,"taskIdOrSubTaskId":taskIdOrSubTaskId,"taskOrSubTask":taskOrSubTask,"lpUserId":lpUserId,"ccUserId":ccUserId,"isTaskCompleted":isTaskCompleted};
        this.isComponentLoaded && this.setState(paramObj,()=>{

          this.callServiceToFetchTaskTimeLogList(paramObj,"TASKTIMELOGLISTFROMCOMPONENTLOADED");

        

         

        });

        
        
         },1005);
    
    }

    /**  
     * SplitScreenTimeLogs
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {
      this.isComponentLoaded = false;
      
    }

    /**  
     * SplitScreenTimeLogs
     * Function name : componentDidUpdate
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentDidUpdate(prevProps){

    }

    /**  
     * SplitScreenTimeLogs
     * Function name : closeSplit
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : closeSplit method to close the split screen
     * Params        : 
    **/

    closeSplit = (objParam)=>{
          //********* for context starts *****
          // let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;      
          // if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){         

          //     if(sharedData.actionType=="SPLITSCREEN" ){

          //       let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;

          //       if(setShare){                              
          //         let paramsCntxt = {
          //           "taskId":"",
          //           "actionType":"",
          //           "leftTabToActive":"",
          //           "purpose":"",
          //           "other":{}
          //         } 
          //         setShare(paramsCntxt);                              
          //       }

                
          //     }
          // }
          //********* for context ends *****

          commonnotitrack("CLOSE_SPLITSCREEN",{
            "notiType": "CLOSE_SPLITSCREEN",
            "triggerCallback":false,
            "trigerredFrom":"TASK_TIMELOG_SCREEN"
           });

    }  

    loadLawyerInfoPopup =  (i,otherParam)=>{

      //console.log(this.state.listData[i].userDetails);
      let practiceAreas = [];
      let locations = [];
      let lawyerName = "";
      let lawyerFname = "";
      let lawyerLname = "";
      let bioData = "";
      let expData = "";
      let ratingData = "";

      let profilePictureURL = null;
      //console.log(" timeLogCreatorDetails===>>",this.state.timeLogCreatorDetails);
      
      let userInfoObj  = this.state.timeLogCreatorDetails!==undefined?this.state.timeLogCreatorDetails:null ;
      if(userInfoObj){

        lawyerFname = (userInfoObj.firstName!==undefined && userInfoObj.firstName!==null)?userInfoObj.firstName:"";

        lawyerLname = (userInfoObj.lastName!==undefined && userInfoObj.lastName!==null)?userInfoObj.lastName:"";

        lawyerName = lawyerFname+" "+lawyerLname;

        practiceAreas = userInfoObj.practiceAreas!==undefined?userInfoObj.practiceAreas:[];

        locations = userInfoObj.locations!==undefined?userInfoObj.locations:[];

        profilePictureURL = userInfoObj.profilePictureURL!==undefined?userInfoObj.profilePictureURL:null;

        bioData = (userInfoObj.bio!==undefined && userInfoObj.bio!==null?(userInfoObj.bio):"");

        expData = (userInfoObj.workExperience!==undefined && userInfoObj.workExperience!==null?(userInfoObj.workExperience+" years"):"");

        ratingData = (userInfoObj.rating!==undefined && userInfoObj.rating!==null?(userInfoObj.rating):"");


      }
      

     

      return (
        <div
            className="dropdown-menu user_popDetails_block"
            aria-labelledby="dropdownMenuButton"
          >
        <div className="user_popBox">
        <div className="u_popBox_sec user_popBox_top">
          <div className="user_popBox_topLeft">
            <h4>
            {lawyerName}
            </h4>
            <div className="userRating_block">
              <div className="ass_userRating">
                <i
                  className="fa fa-star"
                  aria-hidden="true"
                />{" "}
                {ratingData}
              </div>
              <div className="ass_userExp">
                Experience - {expData}
              </div>
            </div>
          </div>
          <div className="user_popBox_topRight">
            {profilePictureURL!==null && (
              <img src={profilePictureURL} />
            )}
            {profilePictureURL===null && ( 
              <div className="user_name_text">
                  <span>{getStringInitials(lawyerName)}</span>
              </div>
            )}
           
          </div>
        </div>
        <div className="u_popBox_sec user_popBox_location">
          <h5>Locations</h5>
          <ul>
              {locations!==null && Object.keys(locations).map((incl,edt)=><li key={userInfoObj.userId+"0"+incl}>{
                  locations[incl]}</li>)
              }
            
            
          </ul>
        </div>
        <div className="u_popBox_sec user_popBox_pracArea">
          <h5>Practice areas</h5>
          <ul>
              {practiceAreas!==null && Object.keys(practiceAreas).map((prci,e)=><li key={userInfoObj.userId+"0"+prci}>{
                  practiceAreas[prci]}</li>)
              }
            
            
          </ul>
        </div>
        <div className="u_popBox_sec user_popBox_about">
          <h5>About</h5>
          <p>
          {bioData}
          </p>
        </div>
      </div>
    
        </div>
      );
    }
    
    // trackScroll = ()=>{

    //       let scobj = this.scrollerRef.current;
    //       let scrollTop = scobj.getValues().scrollTop;
    //       let scrollHeight = scobj.getScrollHeight();
    //       let clientHeight = scobj.getClientHeight();
      
    //       let prevPageNum = this.state.pageNum;
    //       let totalNumberOfPages = this.state.totalNumberOfPages;
          
    //       //console.log(" scrollHeight=>",scrollHeight," clientHeight==>",clientHeight, " scrollTop=>>",scrollTop);
          
    //       if((scrollHeight-clientHeight)==scrollTop && prevPageNum < totalNumberOfPages){
            
    //         this.showLoader();

    //         setTimeout(()=>{
    //           // call list api                         
    //           let pageNumData = prevPageNum + 1;
    //           let paramObj = {"pageNum":pageNumData,"taskIdOrSubTaskId":this.state.taskIdOrSubTaskId,"taskOrSubTask":this.state.taskOrSubTask,"lpUserId":this.state.lpUserId,"ccUserId":this.state.ccUserId};

    //           this.isComponentLoaded && this.setState(paramObj,()=>{
    //             this.callServiceToFetchTaskTimeLogList(paramObj,"TASKTIMELOGLISTFROMSCROLLER");
    //           });
              
    //           },900);
          

    //       }

    // }

    fetchMoreData = ()=>{

      let prevPageNum = this.state.pageNum;
      let totalNumberOfPages = this.state.totalNumberOfPages;

      // if( prevPageNum < totalNumberOfPages){
            
            this.showLoader();

            setTimeout(()=>{
              // call list api                         
              let pageNumData = prevPageNum + 1;
              let paramObj = {"pageNum":pageNumData,"taskIdOrSubTaskId":this.state.taskIdOrSubTaskId,"taskOrSubTask":this.state.taskOrSubTask,"lpUserId":this.state.lpUserId,"ccUserId":this.state.ccUserId};

              this.isComponentLoaded && this.setState(paramObj,()=>{
                this.callServiceToFetchTaskTimeLogList(paramObj,"TASKTIMELOGLISTFROMSCROLLER");
              });
              
              },900);
          

          // }

      

    }

    callServiceToFetchTaskTimeLogList = (paramObj,trackcalledfrom)=>{
      
      //console.log(" trackcalledfrom=>",trackcalledfrom," paramObj=>",paramObj);
      let paramObjData = {};
      paramObjData.pageNum = paramObj.pageNum;
      paramObjData.taskOrSubTask = paramObj.taskOrSubTask;
      paramObjData.taskIdOrSubTaskId = paramObj.taskIdOrSubTaskId;
      paramObjData.lpUserId = paramObj.lpUserId;
      paramObjData.sortOn = paramObj.sortOn;
      paramObjData.sortDir = paramObj.sortDir;
      paramObjData.dateFrom = paramObj.dateFrom;
      paramObjData.dateTo = paramObj.dateTo;
    
      let pageNumArTrack = [...this.state.pageNumArTrack];

      // restrict repeated pagenum related api call
      if(pageNumArTrack.indexOf(paramObjData.pageNum)>-1){
        //console.log("******Sorry cant hit api with page number => "+paramObjData.pageNum+" **********");
          return false;
      }else{
        pageNumArTrack.push(paramObjData.pageNum);
        this.isComponentLoaded && this.setState({"pageNumArTrack":pageNumArTrack});
      }

      
      let stcservice = new TaskTimeLogListService();
      stcservice.getTimeLogList({"data":paramObjData}).then(data =>{
              
                if(data.status==200){
                  let respData ={};
                  let timeLogCreatorDetails = null;
                  let totalMinutesLogged = 0;

                  if(data.data.entity!==undefined){
                    
                     respData  = data.data.entity;

                     timeLogCreatorDetails = (respData.timeLogCreatorDetails!==undefined && respData.timeLogCreatorDetails!==null)?respData.timeLogCreatorDetails: null;

                     totalMinutesLogged = (respData.totalMinutesLogged!==undefined && respData.totalMinutesLogged!==null)?respData.totalMinutesLogged: null;

                     if(respData.timeReportsForUser!==undefined){
                          if(respData.timeReportsForUser.length > 0){                                         
  
                             // set list data to state
                             let prevRecords = [...this.state.listData];
                             let currRecords = respData.timeReportsForUser;
                             let concatenateRecords = prevRecords.concat(currRecords);

                          

                             this.isComponentLoaded && this.setState({"listData":concatenateRecords,"totalNumberOfPages":respData.totalNumberOfPages,
                            "timeLogCreatorDetails":timeLogCreatorDetails,
                            "totalMinutesLogged":totalMinutesLogged,
                          },()=>{ 
  
                             });
                          }else{                            
  
                              if(this.state.listData.length==0){
                                this.isComponentLoaded && this.setState({"listData":[],"totalNumberOfPages":respData.totalNumberOfPages,"pageNum":1,"timeLogCreatorDetails":timeLogCreatorDetails,
                                "totalMinutesLogged":totalMinutesLogged,});
                              }
                          }
  
  
                          }else{                          
                        }
              }
          }
        }).catch((err)=>{
                
                let errStatusCodeAr = [400,500]; // allowed error status code
                if(err.response && err.response.data){
                    let erRespData = err.response.data;
                    if(errStatusCodeAr.indexOf(erRespData.status) !== -1){         
                        let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                        console.log(errMsg);
                                         
                }
                } 
        }).finally(()=>{
            this.hideLoader();
            this.isComponentLoaded && this.setState({"dateFilterFlag":false});           
            
        });
    }

    

     /**  
     * SplitScreenTimeLogs
     * Function name : loadLoader
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : load Loader 
     * Params        : 
    **/
    
    loadLoader() {
      return (
          <CustLoader className={'smallLoaderGreen'} loaderText={'Loading..'} />
      );
    }

    /**
     * SplitScreenTimeLogs
     * Function name : hideLoader
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : Hide loader
     * Params        : 
     **/

    hideLoader() {
      this.isComponentLoaded && this.setState({ loaderFlag: false });
    }

    /**
     * SplitScreenTimeLogs
     * Function name : showLoader
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : showLoader
     * Params        : 
     **/

    showLoader() {
      this.isComponentLoaded && this.setState({ loaderFlag: true });
    }

    setCalDate=(dt,dateFor)=>{            

      dt = getTimestampInMillisecondsFromDateObj(dt);
      if(dateFor=="dateFrom"){
        this.isComponentLoaded && this.setState({"dateFrom":dt},()=>{
          //console.log(" after dateFrom set this.state=>",this.state);
        });

      }
      else if(dateFor=="dateTo"){
        this.isComponentLoaded && this.setState({"dateTo":dt},()=>{
          //console.log(" after dateTo set this.state=>",this.state);
        });
      }

    }
    
    customDate = (defaultDate,dtKey,placeholdertxt,datefor)=>{        
           
      return (
        <DatePicker
          selected={defaultDate}
          onChange={date => {this.setCalDate(date,datefor)}}
          calendarClassName="customtaskaddclassstep1calcls"         
          customInput={<ExampleCustomInput keydata={dtKey} placeholdertxt={placeholdertxt}   />}
          dateFormat={getDateFormatForCal({"countryCode":"IN"})}
        />
      );
    }

    triggerDateFiltration = () =>{
      let dateFrom = this.state.dateFrom;
      let dateTo = this.state.dateTo;
      let dateFromErrMsg = "";
      let dateToErrMsg = "";

      let dateFiltErAr = [];

      if(dateFrom!==null && dateTo!==null ){
        if(dateFrom>dateTo){
          dateFromErrMsg = "Date for from should be older date";
          dateFiltErAr.push(dateFromErrMsg);
        }

      }else if(dateFrom===null && dateTo!==null){
        dateFromErrMsg = "Date for from is required";
        dateFiltErAr.push(dateFromErrMsg);
      }
      else if(dateFrom!==null && dateTo===null){
        dateToErrMsg = "Date for to is required";
        dateFiltErAr.push(dateToErrMsg);

      }
      else if(dateFrom===null && dateTo===null){
        dateFromErrMsg = "Date for from is required";
        dateToErrMsg = "Date for to is required";
        dateFiltErAr.push(dateFromErrMsg);
        dateFiltErAr.push(dateToErrMsg);

      }

      this.isComponentLoaded && this.setState({"dateFilterFlag":(dateFiltErAr.length==0?true:false),"dateFromErrMsg":dateFromErrMsg,"dateToErrMsg":dateToErrMsg});
      //console.log(" dateFiltErAr==>",dateFiltErAr," dateFrom=>",dateFrom," dateTo=>",dateTo);
      if(dateFiltErAr.length==0){
          setTimeout(()=>{
          
                    // fetch list with date filtration
                    let paramObj = {"sortOn":"","sortDir":"","dateSortDirClass":"","timeSortDirClass":"","dateFrom":dateFrom,"dateTo":dateTo,"pageNum":1,"listData": [],"pageNumArTrack":[],"taskIdOrSubTaskId":this.state.taskIdOrSubTaskId,"taskOrSubTask":this.state.taskOrSubTask,"lpUserId":this.state.lpUserId,"ccUserId":this.state.ccUserId};

                    let scobj = this.scrollerRef.current;           
                    //scobj.scrollToTop();

                    window.scrollTo(0,  this.scrollerRef.current.offsetTop);

                    this.isComponentLoaded && this.setState(paramObj,()=>{
                            
                            // call list api           
                            this.showLoader();
                            setTimeout(()=>{
                              this.callServiceToFetchTaskTimeLogList(paramObj,"TASKTIMELOGLISTFROMDATEFILTER");
                          },500);

              });
          
          },1000);
      }

    }

    triggerDateSort = (other) =>{
      
      let sortDir = this.state.sortDir;
      let sortOn = other.sortOn;
      let dateSortDirClass = "";
      let timeSortDirClass = "";
      if((this.state.sortOn!=sortOn)){
        sortDir = "DESC";
        if(sortOn=="DATE"){
          dateSortDirClass = " th_des ";
          timeSortDirClass = "";
        }else if(sortOn=="LOG_HOUR"){
          dateSortDirClass = "";
          timeSortDirClass = " th_des ";
        }

      }else{
        if(sortDir==""||sortDir=="ASC"){
        
          sortDir = "DESC";
          if(sortOn=="DATE"){
            dateSortDirClass = " th_des ";
            timeSortDirClass = "";
          }else if(sortOn=="LOG_HOUR"){
            dateSortDirClass = "";
            timeSortDirClass = " th_des ";
          }
        }else{
          sortDir = "ASC";
          if(sortOn=="DATE"){
            dateSortDirClass = " th_ase ";
            timeSortDirClass = "";
          }else if(sortOn=="LOG_HOUR"){
            dateSortDirClass = "";
            timeSortDirClass = " th_ase ";
          }
        }
      }
     
      let paramObj = {"sortOn":sortOn,"sortDir":sortDir,"dateSortDirClass":dateSortDirClass,"timeSortDirClass":timeSortDirClass,"dateFrom":this.state.dateFrom,"dateTo":this.state.dateTo,"pageNum":1,"listData": [],"pageNumArTrack":[],"taskIdOrSubTaskId":this.state.taskIdOrSubTaskId,"taskOrSubTask":this.state.taskOrSubTask,"lpUserId":this.state.lpUserId,"ccUserId":this.state.ccUserId};

      let scobj = this.scrollerRef.current;           
      //scobj.scrollToTop
      window.scrollTo(0,  this.scrollerRef.current.offsetTop);

      this.isComponentLoaded && this.setState(paramObj,()=>{
             
              // call list api           
              this.showLoader();
              setTimeout(()=>{
                this.callServiceToFetchTaskTimeLogList(paramObj,"TASKTIMELOGLISTFROMSORT");
            },500);

      });
      
    }

    triggerNotifyToUpdateLog = (paramObj)=>{

      let paramObjData = {};
      
      paramObjData.taskOrSubTask = this.state.taskOrSubTask;
      paramObjData.taskIdOrSubTaskId = this.state.taskIdOrSubTaskId;
      paramObjData.lpUserId = this.state.lpUserId;
      paramObjData.data = {};
      

      let stcservice = new NotifyToUpdateTimeLogService();
      stcservice.sendNotificationToUpdateTimeLog(paramObjData).then(data =>{
              
                if(data.status==200){
                  let notiMsg = "Request sent successfully";
                  notify(notiMsg,true,{
                  "successMsg":1,
                  "customWrapClass":"customerrmidlass",
                  "showCloseButton": false, 

                  "dismiss": {
                  "duration": 1010,
                  }

                  });
                
                }
        }).catch((err)=>{
                
                let errStatusCodeAr = [400,500]; // allowed error status code
                if(err.response && err.response.data){
                    let erRespData = err.response.data;
                    if(errStatusCodeAr.indexOf(erRespData.status) !== -1){         
                        let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred";
                        console.log(errMsg);

                        notify(errMsg,true,{
                        "successMsg":0,
                        "customWrapClass":"customerrmidlass",
                        "showCloseButton": false, 

                        "dismiss": {
                        "duration": 1010,
                        }

                        });
                                         
                }
                } 
        }).finally(()=>{
            
            this.isComponentLoaded && this.setState({"requestTimeLogUpdFlag":false});           
            
        });

    }
       
    /**  
     * SplitScreenTimeLogs
     * Function name : loadfooterOfDocument
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : load footer Of timelog page
     * Params        : 
    **/

    

    loadfooterOfDocument = () => {

      let downLoadUrl = commonJsObj.getEndPoint()+"rest/time/report/pdf/";
      let offsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime(new Date());
      let offsetHr = offsetOfLocalDateTime.hr;
      let offsetMin = offsetOfLocalDateTime.min;
      let accToken = getTokenFromLocStorage();

      if(this.state.taskOrSubTask=="STASK"){
          // for task without subtask
          downLoadUrl = downLoadUrl+"task/"+this.state.taskIdOrSubTaskId+"?offset_hour="+offsetHr+"&offset_minute="+offsetMin+"&auth="+accToken;

          if(this.state.sortOn!="" && this.state.sortDir!=""){
            downLoadUrl = downLoadUrl+"&sort_on="+this.state.sortOn+"&sort_dir="+this.state.sortDir;
          }

          if(this.state.dateFrom!==null && this.state.dateTo!==null){
            downLoadUrl = downLoadUrl+"&from="+this.state.dateFrom+"&to="+this.state.dateTo;
          }

      }else if(this.state.taskOrSubTask=="SWSTASK"){
          // for task with subtask
      }
      

      return (
        <>
        {/* === right slide section Footer  start ===  */}
        <div className="SlideS_bottombg flexbox-item Slide_chatBottomBg SlideS_bottombg_gray invoiceList_bottomBg SlideS_bottombg_stickyFooterRightPart SlideS_bottombg_stickyFooterRightPart_timeSheet">
          <div className="chat_bottom_left">
            <a href={downLoadUrl} download={true} className="btn_appearance_none timesheet_downloadBtn">
              <span>Download as PDF</span>
            </a>
          </div>
          <div className="s_footer_btnbg">
            <div className="timeSt_Footer_right">
                    <p className="timeSt_Footer_totlaTime">
                        <b>Total hours </b> <span>{convertMinutueToHour(this.state.totalMinutesLogged)!==null?convertMinutueToHour(this.state.totalMinutesLogged).hoursDisplayText2:""}</span>
                    </p>

              
            </div>
          </div>
        </div>
        {/* === right slide section Footer  end ===  */}

        </>
      );
      
    }
    loadTimeLogContent = () => {
     
      let dateFrom = this.state.dateFrom;
      let defaultDateFrom = dateFrom!==null?getDateObjFromMilliseconds(dateFrom):null;

      let dateTo = this.state.dateTo;
      let defaultDateTo = dateTo!==null?getDateObjFromMilliseconds(dateTo):null;

      let lawyerName = '';
      let lawyerFname = '';
      let lawyerLname = '';
      let profilePictureURL = "";

      if(this.state.timeLogCreatorDetails!==undefined && this.state.timeLogCreatorDetails!==null ){
        lawyerFname = (this.state.timeLogCreatorDetails.firstName!==undefined && this.state.timeLogCreatorDetails.firstName!==null)?this.state.timeLogCreatorDetails.firstName:"";

        lawyerLname = (this.state.timeLogCreatorDetails.lastName!==undefined && this.state.timeLogCreatorDetails.lastName!==null)?this.state.timeLogCreatorDetails.lastName:"";

        lawyerName = lawyerFname+" "+lawyerLname;

        profilePictureURL = (this.state.timeLogCreatorDetails.profilePictureURL!==undefined && this.state.timeLogCreatorDetails.profilePictureURL!==null)?this.state.timeLogCreatorDetails.profilePictureURL:null;

        
      }
        //console.log(" ****** this.state==>",this.state);
        return (
          <>
          {/* === right slide section Middlie  start ===  */}
          <div className="SlideS_Midbg flexbox-item-grow SlideS_Midbg_chat SlideS_Midbg_timesheet SlideS_Midbg_heightAdjustment SlideS_Midbg_heightAdjustment_timeSheet_cc">
            <div ref={this.scrollerRef} id="scrollableDivTimelg"  style={{
                                     
                                     flexDirection: 'column',
                                   }} className="s_midbox_scroll_adjust reactTestScroll mCustomAdjustmentForStickFooter rightMidPartHeightAdjustment rightMidPartHeightAdjustment_timeSheet_cc">
            {/* <Scrollbars 
                                ref={this.scrollerRef}
                                style={{ height: 900 }}                  
                                autoHeightMin={900}
                                renderView={props => <div {...props} className="view timeloglistscrollcustomclass"/>}

                                
              > */}
              <div className="s_midbox_section s_midbox_section_chat">
                 <CustomMessageBar />
                {/* ====   sprint - 5 chat structure start ====  */}
                {/* Document list start  */}
                <div className="dataTable_bg">
                  <div className="timesheet_filterBg">
                    <div className="timesheet_filterBg_left">
                      <form>
                        <div className="timesheet_filter_box">
                          <div className="timest_date_box">
                            <div className="timest_inputGrp timest_date_grp">
                              <div className={`form__group field ${this.state.dateFromErrMsg?"form_group_error":""}`}>
                                <div
                                  className="input-group date"
                                  id="sandbox-container"
                                >
                                  {/* <input
                                    type="text"
                                    className="form__field customdatefld"
                                    placeholder="From"
                                  /> */}
                                  {this.customDate(defaultDateFrom,'dtKey1','From',"dateFrom")}
                                </div>
                                <span className="error_span">
                                  {this.state.dateFromErrMsg}
                                </span>
                              </div>
                              <div className="clearboth" />
                            </div>
                            <div className="timest_inputGrp timest_date_grp">
                              <div className={`form__group field ${this.state.dateToErrMsg?"form_group_error":""}`}>
                                <div
                                  className="input-group date"
                                  id="sandbox-container"
                                >
                                  {/* <input
                                    type="text"
                                    className="form__field customdatefld"
                                    placeholder="To"
                                  /> */}
                                  {this.customDate(defaultDateTo,'dtKey2','To',"dateTo")}
                                </div>
                                <span className="error_span">
                                  {this.state.dateToErrMsg}
                                </span>
                              </div>
                              <div className="clearboth" />
                            </div>
                          </div>
                          <div className="timest_btn_box">
                            <div className="timest_filterBtn">
                              <button onClick={(evn)=>{
                                evn.preventDefault();
                                this.triggerDateFiltration();
                              }} disabled={this.state.dateFilterFlag} className="btn_appearance_none sub_grd_btn chatHeadAssign">
                                <span>Filter</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  {/* == white table timeSheet box bg start ==  */}
                  
                  {/* ==== timesheet ====  */}
                  <div className="timesheet_block">
                      
                    <div className="timest_top">
                       <div className="timest_left">
                       </div>
                      <div className="timest_right">
                        {this.state.isTaskCompleted==false && (
                               <button disabled={this.state.requestTimeLogUpdFlag} onClick={(evn)=>{ 
                                evn.preventDefault();
                                this.isComponentLoaded && this.setState({"requestTimeLogUpdFlag":true},()=>{
                                  setTimeout(()=>{
                                    this.triggerNotifyToUpdateLog({});
                                  },2000);
                                  
                                });  
                                
                              }} className="btn_appearance_none timest_rightBtn">
                                {`${this.state.requestTimeLogUpdFlag==true?"Sending request..":"Request to update timesheet"}`}
                              </button>
                            
                            
                        )}
                       
                      </div>
                      <div className="clearboth" />
                    </div>
                    <div className="table_boxBg">
                      <div className="reactTable_scroll mCustomAdjustmentForStickFooter_timeSheetTable timeSheetTableScrollAdjust timeSheetTable_cc"  >
                      {/* <Scrollbars 
                                ref={this.scrollerRef}
                                style={{ height: 450 }}                  
                                autoHeightMin={450}
                                renderView={props => <div {...props} className="view timeloglistscrollcustom2class"/>}

                                onScrollStop={this.trackScroll}
                                
                        > */}
                        <InfiniteScroll
                                dataLength={this.state.listData.length} //This is important field to render the next data
                                next={this.fetchMoreData}
                                hasMore={true}
                                 
                                // loader={<h4>Loading...</h4>}
                                scrollableTarget="scrollableDivTimelg"
                                inverse={false}
                                
                                >
                        <div className="table-responsive">
                          {/* ====   Table start  ====   */}
                          <table
                            id="example"
                            className="table table-bordered"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr>
                                <th>
                                  <div className="tabeTh_data">Date</div>
                                  <div className="th_btn">
                                    <button onClick={(evn)=>{
                                      evn.preventDefault();
                                      this.triggerDateSort({"sortOn":"DATE"});
                                    }} className={`btn_appearance_none th_normal ${this.state.dateSortDirClass}`}>
                                      <span className="uparrow">
                                        <i
                                          className="fa fa-long-arrow-up"
                                          aria-hidden="true"
                                        />
                                      </span>
                                      <span className="downarrow">
                                        <i
                                          className="fa fa-long-arrow-down"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    </button>
                                  </div>
                                </th>
                                <th>
                                  <div className="tabeTh_data">Logged</div>
                                  <div className="th_btn">
                                    <button onClick={(evn)=>{
                                      evn.preventDefault();
                                      this.triggerDateSort({"sortOn":"LOG_HOUR"});
                                    }} className={`btn_appearance_none th_normal ${this.state.timeSortDirClass}`}>
                                      <span className="uparrow">
                                        <i
                                          className="fa fa-long-arrow-up"
                                          aria-hidden="true"
                                        />
                                      </span>
                                      <span className="downarrow">
                                        <i
                                          className="fa fa-long-arrow-down"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    </button>
                                  </div>
                                </th>
                                <th>Description</th>
                              </tr>
                            </thead>
                             <tbody>
                              {/* <tr>
                                <td>16 Sep 2019</td>
                                <td>1hr 0min</td>
                                <td>
                                  <p>
                                    Started working on the file &amp; filled the report.
                                  </p>
                                </td>
                              </tr>
                                */}
                                {this.state.loaderFlag==false && this.state.listData.length==0 && (
                                  <tr key={"kndf1"}>
                                    <td colSpan="3">
                                        <div className=" text-center">
                                            No records found
                                        </div>
                                    </td>
                                  </tr>
                                )}
                               {this.state.listData.length>0 && Object.keys(this.state.listData).map((e,i)=>(
                                  
                                  <tr key={i}>
                                    <td>{this.state.listData[e].loggedAtInMillis ?CustDate.custFormatDate((new Date(this.state.listData[e].loggedAtInMillis)),3): ""}</td>
                                    <td>{convertMinutueToHour(this.state.listData[i].loggedMinutes)!==null?convertMinutueToHour(this.state.listData[i].loggedMinutes).hoursDisplayText1:""}
                                    </td>
                                    <td>
                                      <p>
                                        {this.state.listData[i].logDescription?this.state.listData[i].logDescription:""}
                                      </p>
                                    </td>
                                  </tr>
                                  ))}
                               
                              
                             </tbody>
                            <tfoot>
                              <tr>
                                <td colSpan="3">
                                {this.state.loaderFlag ? this.loadLoader() : null}
                                </td>
                              </tr>
                            </tfoot>
                            
                          </table>
                          {/* ====   Table end  ====   */}
                        </div>
                      
                        </InfiniteScroll>
                      {/* </Scrollbars> */}
                      
                     
                      </div>
                    </div>
                  </div>
                  {/* ==== timesheet ====  */}
                  {/* ==== timesheet ====  */}
                  {/* ==== timesheet ====  */}
                  {/* ==== timesheet ====  */}
                  {/* ==== timesheet ====  */}
                  {/* == white table timeSheet box bg end ==  */}
                </div>
                {/* Document list end  */}
                {/* ====   sprint - 5 chat structure end ====  */}
                
              </div>
            {/* </Scrollbars> */}
            </div>
          </div>
          {/* === right slide section Middlie  end ===  */}
          </>     
        );
      }
    
   
  /**  
     * SplitScreenTimeLogs
     * Function name : loadTheContent
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : load content of the timelog page
     * Params        : 
    **/

    loadTheContent = ()=>{

      let forSplitScrnCls2Inject = "";
      let datasPassForAssignObj = {};
      //********* for context starts *****
      let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;      
      if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){         

          if(sharedData.actionType=="SPLITSCREEN" ){            
            forSplitScrnCls2Inject = "margin0"
          }

          

         
      }
      //********* for context ends *****
      
      let lawyerName = '';
      let lawyerFname = '';
      let lawyerLname = '';
      let profilePictureURL = "";

      if(this.state.timeLogCreatorDetails!==undefined && this.state.timeLogCreatorDetails!==null ){
        lawyerFname = (this.state.timeLogCreatorDetails.firstName!==undefined && this.state.timeLogCreatorDetails.firstName!==null)?this.state.timeLogCreatorDetails.firstName:"";

        lawyerLname = (this.state.timeLogCreatorDetails.lastName!==undefined && this.state.timeLogCreatorDetails.lastName!==null)?this.state.timeLogCreatorDetails.lastName:"";

        lawyerName = lawyerFname+" "+lawyerLname;

        profilePictureURL = (this.state.timeLogCreatorDetails.profilePictureURL!==undefined && this.state.timeLogCreatorDetails.profilePictureURL!==null)?this.state.timeLogCreatorDetails.profilePictureURL:null;

        
      }
      

      return (
        <>
        { /* Dashboard Slide Split Right start  */   }
        <div className={`splitscreen_area 
              ${forSplitScrnCls2Inject?forSplitScrnCls2Inject:""}`
                }>
              <div className="slideScreen_bg slideScreen_Chatbg slideScreen_bg_heightAdjust">
                  <SplitScreenLeftMenu />
                  <div className="slideScreen_rightbg flexbox-parent">
                  {/* === right slide section top start ===  */}
                  <div className="SlideS_topbg flexbox-item">
                        <div className="slide_top_titlebg">
                          {/* <h4>View Timesheet</h4> */}
                          <div className="titleText_withImageDropdown">
                          <div className="titleText_withImgDropBlock titleText_withImgDropBlock_staticText">
                            <span className="titleText_withImageDropdown_textSpan">
                            View Activity Log

                              </span>
                          </div>
                          <div className="titleText_withImgDropBlock titleText_withImgDropBlock_imgName">                       

                            <>
                            {profilePictureURL!==null && (
                              <img className="titleText_withImage" src={profilePictureURL} />
                                )}
                                {profilePictureURL===null && ( 
                              <div className="user_name_text">
                                  <span>{getStringInitials(lawyerName)}</span>
                              </div>
                            )}

                            <button className="btn_appearance_none titleText_withImageDropdown_btn " type="button" data-toggle="dropdown" aria-expanded="false">
                              {lawyerName}
                            </button>
                            {this.loadLawyerInfoPopup(1,{})}

                             {/* --- optional dropdown for title image text start ---     */}
                        {/* 
                                          <div class="dropdown-menu user_popDetails_block">
                                              <div class="user_popBox">
                                                  <div class="u_popBox_sec user_popBox_top">
                                                      <div class="user_popBox_topLeft">
                                                          <h4>Condition -1  Sourav Roy</h4>
                                                          <div class="userRating_block">
                                                              <div class="ass_userRating">
                                                                  <i class="fa fa-star" aria-hidden="true"></i>  4.5 
                                                              </div>
                                                              <div class="ass_userExp">
                                                                  Experience - 5 years
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div class="user_popBox_topRight">
                                                          <div class="user_name_text"><span>AM</span></div>
                                                      </div>
                                                  </div>
                                                  <div class="u_popBox_sec user_popBox_location">
                                                      <h5>Locations</h5>
                                                      <ul>
                                                          <li>Lorem ipsum dolor sit</li>
                                                          <li>Loremipsumdolorsit</li>
                                                          <li>Loremipsum dolorsit</li>
                                                          <li>Loremipsum dolorsit</li>
                                                      </ul>
                                                  </div>
                                                  <div class="u_popBox_sec user_popBox_pracArea">
                                                      <h5>Practice areas</h5>
                                                      <ul>
                                                          <li>Lorem ipsum dolor sit</li>
                                                          <li>Loremipsumdolorsit</li>
                                                          <li>Loremipsum dolorsit</li>
                                                          <li>Loremipsum dolorsit</li>
                                                      </ul>
                                                  </div>
                                                  <div class="u_popBox_sec user_popBox_about">
                                                      <h5>About</h5>
                                                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                      </p>
                                                  </div>
                                              </div>
                                          </div>
                                            */}
                        {/* --- optional dropdown for title image text end ---     */}
                            
                            
                            
                            </>

                       
                          </div>
                      
                      
                      </div>

                          <button onClick={(evn)=>{
                            evn.preventDefault();
                            //
                            this.closeSplit({"trigerredFrom":"TASK_TIMELOG_SCREEN"});
                            
          

                          }} className="btn_appearance_none slideTop_cross_btn cross">
                            <img src={getBaseUrl()+"assets/images/red_cross.png"} alt />
                          </button>
                          <div className="clearboth" />
                        </div>
                    </div>
                  {/* === right slide section top end ===  */}
                  {/* === right slide section Middlie  start ===  */}
                  
                    
                   {this.loadTimeLogContent()} 
                          

                 
                  {/* === right slide section Middlie  end ===  */}
                  {/* === right slide section Footer  start ===  */}
                  {this.loadfooterOfDocument()}
                 
                  
                  {/* === right slide section Footer  end ===  */}
                </div>
              
              
              </div>

        </div>


        { /* Dashboard Slide Split Right end  */ }
        </>
      );
    }

    // trackNotiForCloseSplit = (otherParam) =>{
     
    //   let notiType =  otherParam.notiType!==undefined? otherParam.notiType: "";
    //   let trigerredFrom =  otherParam.trigerredFrom!==undefined? otherParam.trigerredFrom: ""; 
    //   if(notiType=="CLOSE_SPLITSCREEN"){
    //       let closeSplitHeaderCallFrm = ["TASK_TIMELOG_SCREEN"];
    //       if(closeSplitHeaderCallFrm.indexOf(trigerredFrom)!=-1){
    //               console.log("*******trigerredFrom=>>",trigerredFrom);
    //       }
  
  
    //   }
    // }
   
    /**  
     * SplitScreenTimeLogs
     * Function name : render
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() {    
      
      return (
          <React.Fragment>
            
            {/* <NotiEmitTrack callbackfuncforcommontrackofnoti={this.trackNotiForCloseSplit} /> */}
            {this.loadTheContent()}
          
          </React.Fragment>
      );
    }
}
export default SplitScreenTimeLogs;

