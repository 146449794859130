/**
 * OfferTaskButton
 * Page/Class name   : OfferTaskButton
 * Author            :
 * Created Date      : 24-3-2021
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : rendering loader structure
**/
import React, { Component, Suspense, lazy} from 'react';
import '../../../custom_resources/css/custom.css';

// Modal
import CustReactBootstrapModal from './CustReactBootstrapModal';
import CustomMessageBar from '../../messagebar/CustomMessageBar';
// Services
import OfferTaskService from '../../service/OfferTaskService';
import TaskDetailsService from '../../service/TaskDetailsService';
// for context
import CommonContext from '../../context/CommonContext';

import {notifytasklistontaskstatuschange} from './TaskListOfSingleTask';
import {notifymultitasklistontaskstatuschange} from './TaskListOfMultiTask';

import  CustDate  from '../../../common_utilities/CustDate';



import { commonJsFuncModule as commonJsObj } from '../../../common_utilities/commonjsfunc';

const getBaseUrl = commonJsObj.getBaseUrl;
const getBaseUrlOfLp = commonJsObj.getBaseUrlOfLp;
const getSiteName = commonJsObj.getSiteName;
const findElement = commonJsObj.findElement;


class OfferTaskButton extends Component {
    /**
     * OfferTaskButton
     * Function name : constructor
     * Author        :
     * Created Date  : 
     * Purpose       : initializing state
     * Params        : props
    **/

   isComponentLoaded = false;

   // for context
   static contextType = CommonContext; 

    constructor(props) {
        super(props);
        this.state = {

            showConflictInfoModal: false,
            showEngageAgreeModal: false,
            aggreeAndSendOfferBtnClkFlag: false,
            assignRel: null,
            tabContentNumber:0,//0=> nothing, 1=>Confidentiality Check, 2=>Conflict Check,3=> Engagement Letter
            tabsToShow:[
              {"tabNumber":1,"tabShowStatus":false,"keyName":"CONFIDENTIALITY_CHECK"},
              {"tabNumber":2,"tabShowStatus":false,"keyName":"CONFLICT_CHECK"},
              {"tabNumber":3,"tabShowStatus":true,"keyName":"ENGAGEMENT_LETTER"},
          
          ]

            
        }    

        this.modalforconflmessgRef =  React.createRef();
        this.modalforengagreeRef = React.createRef();
    }

    /**
     * OfferTaskButton
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentDidMount() {
        this.isComponentLoaded = true;

        
        
    }

    /**
     * OfferTaskButton
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {  
        this.isComponentLoaded = false;
    }

   

    callSendOfferTask = async (paramToPost,otherData) =>{        
        

        let stcservice = new OfferTaskService();
        stcservice.sendOfferTask(paramToPost).then(data =>{
              if(data.status==200){
                  let respData ={};
                  if(data.data.entity!==undefined){                   
                        setTimeout(()=>{
                        // call api to send offer
                        this.engagementConfiModalClose();
                        let anyDataToPass =  {"activity":"OFFER_SENT_SUCCESS"};
                        this.props.callbackfunc(anyDataToPass);

                        },1500);
                    
                    
              }
          }
          }).catch((err)=>{
                  
                  let errStatusCodeAr = [400,500]; // allowed error status code
                  if(err.response && err.response.data){
                      let erRespData = err.response.data;
                      if(errStatusCodeAr.indexOf(erRespData.status) !== -1){         
                          let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                          console.log(errMsg);
                                           
                  }
                  } 

                setTimeout(()=>{
                    this.getEngagementConfiModalContent();
                },1800);
                
          }).finally(()=>{  
            this.isComponentLoaded && this.setState({"aggreeAndSendOfferBtnClkFlag":false},()=>{
              
            });
                       
              
          });
        
      }

     

    openPopupBasedOnCondition = (dataObj)=> {

      let tabsToShow = [...this.state.tabsToShow];
     
      console.log("--- from openPopupBasedOnCondition this.state.assignRel==>>>>",this.state.assignRel);

      let isOrganisationDetailsMasked = (this.state.assignRel.isOrganisationDetailsMasked!==undefined && this.state.assignRel.isOrganisationDetailsMasked!==null)?this.state.assignRel.isOrganisationDetailsMasked:false;

      let conflictCheckStatus = (this.state.assignRel.conflictCheckStatus!==undefined && this.state.assignRel.conflictCheckStatus!==null)?this.state.assignRel.conflictCheckStatus:""; //PASS, FAIL, IN_PROGRESS, NOT_STARTED.

      let allowedStatusForConflTab = ["PASS", "FAIL", "IN_PROGRESS"];

      let engagementLetterShowFlag = true;
      let tabArVal = [];
      
      Object.keys(tabsToShow).map((e,i)=> { 
            if(tabsToShow[i].keyName=="CONFIDENTIALITY_CHECK" && isOrganisationDetailsMasked===true) {         
              tabsToShow[i].tabShowStatus =  true;
              tabArVal.push(tabsToShow[i].tabNumber);
            }
            else if(tabsToShow[i].keyName=="CONFLICT_CHECK" && allowedStatusForConflTab.indexOf(conflictCheckStatus)!=-1) {             
              tabsToShow[i].tabShowStatus =  true;
              tabArVal.push(tabsToShow[i].tabNumber);
            }
            else if(tabsToShow[i].keyName=="ENGAGEMENT_LETTER" && engagementLetterShowFlag===true) {             
              tabsToShow[i].tabShowStatus =  true;
              tabArVal.push(tabsToShow[i].tabNumber);
            }


      });

      // get the tab number to display 1st
      let tabContentNumber = tabArVal[0]; // tab content to display and make the tab active
      this.isComponentLoaded && this.setState({"tabsToShow":tabsToShow},()=>{
        this.switchTab({"tabContentNumber":tabContentNumber,"reloadTheContent":false});
        setTimeout(()=>{
          this.confidentialityEngagementModalOpen({});
        },1500);

      });

      
     

    }


    /*** Conflict status Modal  starts  */
    /**
     * TaskListOfSingleTask
     * Function name : TaskListOfSingleTask
     * Author        :
     * Created Date  : 29-3-2020
     * Purpose       : To open delete modal
     * Params        : 
    **/  
   conflictStatusInfoModalOpen = (otherParam)=>{    
    this.isComponentLoaded &&  this.setState({showConflictInfoModal:true},()=>{
      this.modalforconflmessgRef.current.showModal();
      this.getConflictStatusInfoModalContent(otherParam);
    });    
      
  }

  /**
     * TaskAddSingleTaskStep2
     * Function name : organisationDetailModalClose
     * Author        :
     * Created Date  : 24-3-2021
     * Purpose       : To close organisationDetailModal
     * Params        : 
    **/
   conflictStatusInfoModalClose = ()=>{    
    this.isComponentLoaded && this.setState({showConflictInfoModal:false},()=>{
      this.modalforconflmessgRef.current.hideModal();
    });
    }

    /**
   * TaskAddSingleTaskStep2
   * Function name : getOrganisationDetailModalOpen
   * Author        :
   * Created Date  : 24-3-2021
   * Purpose       : Get modal view with data
   * Params        : 
  **/
    getConflictStatusInfoModalContent(otherParam) {   
       
        //console.log(" NOWW otherParam==>",otherParam);
        //let taskName = otherParam.taskName!==undefined?otherParam.taskName:"";
        let taskName = this.state.assignRel.taskOrSubTaskName!==undefined?this.state.assignRel.taskOrSubTaskName:"";
        let lawyerName = this.state.assignRel.lpUserName!==undefined?this.state.assignRel.lpUserName:"";
        /*
        let lawyerName = "";
        
        if(otherParam.lpUserInterestedData!==undefined && otherParam.lpUserInterestedData.userDetails!==undefined){
            let taskRespObj = otherParam.lpUserInterestedData;
            let lawyerFname = (taskRespObj.userDetails.firstName!==undefined && taskRespObj.userDetails.firstName!==null)?taskRespObj.userDetails.firstName:"";

            let lawyerLname = (taskRespObj.userDetails.lastName!==undefined &&  taskRespObj.userDetails.lastName!==null)?taskRespObj.userDetails.lastName:"";
    
             lawyerName = lawyerFname+" "+lawyerLname;
        }
        */


        


        let viewData = <div className="dash_modal">
        
                <>
            <div className="modal-header">
                <h4 className="modal-title modal_title_tool"> Offer Task
                {/*<div className="input_tooltip_bg">
                        <span className="input_tooltip" aria-label="Organisation's Details" data-balloon-pos="down">
                        <i className="fa fa-question-circle"></i>
                        </span>
                        </div>*/}
                </h4>
                <button type="button" onClick={
                    ()=>{
                    this.conflictStatusInfoModalClose();

                }} className="close modal_close" data-dismiss="modal">&times;</button>
            </div>
            <CustomMessageBar />            
            <div className="modal-body padding_top">
            
            <div class="popUP_text_p">
                    {/* <p>
            You have started the conflict check progress with lawshram for task &nbsp; <b>{taskName}</b> &nbsp;&nbsp; for&nbsp;&nbsp; <b> {lawyerName} </b>             
                    </p> */}

                    <p>
                    You have started conflict check, for which status is awaited. Do you still want to assign task?
                    </p>


              </div>
            </div>
            <div className="modal-footer">

                <button   type="button" className="btn_appearance_none modal_text_btn " onClick={(e)=>{ e.preventDefault();
                    this.conflictStatusInfoModalClose();
                        

                } } data-dismiss="modal"><span>Cancel</span></button>
               
                <button  type="button" className="btn btn-primary modal_btn_save" onClick={(e)=>{ e.preventDefault();
                    this.conflictStatusInfoModalClose();
                    setTimeout(()=>{
                        this.confidentialityEngagementModalOpen(otherParam);
                    },1000);
                    

                } } data-dismiss="modal"><span>Confirm</span></button>
                
            </div>
          
          
          </>
        
                        
        </div>;
    
        this.modalforconflmessgRef.current.updateStateContent(viewData);
        this.modalforconflmessgRef.current.showContent();
        return viewData;
    }    

    /*** Conflict status Modal ends  */

    /*** Engagement Modal  starts  */
    /**
     * OfferTaskButton
     * Function name : confidentialityEngagementModalOpen
     * Author        :
     * Created Date  : 29-3-2020
     * Purpose       : To open delete modal
     * Params        : 
    **/  
   confidentialityEngagementModalOpen = (otherParam)=>{    
    this.isComponentLoaded &&  this.setState({showEngageAgreeModal:true},()=>{
      this.modalforengagreeRef.current.showModal();
      this.getEngagementConfiModalContent();
    });    
      
  }

  /**
     * OfferTaskButton
     * Function name : engagementConfiModalClose
     * Author        :
     * Created Date  : 24-3-2021
     * Purpose       : To close organisationDetailModal
     * Params        : 
    **/
   engagementConfiModalClose = ()=>{    
    this.isComponentLoaded && this.setState({showEngageAgreeModal:false},()=>{
      this.modalforengagreeRef.current.hideModal();
    });
    }
    

     /**
   * OfferTaskButton
   * Function name : getEngagementConfiModalContent
   * Author        :
   * Created Date  : 24-3-2021
   * Purpose       : Get modal view with data
   * Params        : 
  **/
    getEngagementConfiModalContent() { 
     
        let viewData = <div className="dash_modal">
        {/* ===   new engagementLetter_modal modal section start ===  */}
        <div className="modal-header">
          <div className="engmLetter_topBlueSec">
            <div className="engmLetter_topBlueSec_tabBlock">
              <ul>
                {this.loadTabs()}
              </ul>
            </div>
          </div>
        </div>
        <div className="modal-body engmLetter_modalBody">
          {this.getTabContent()}
        </div>
        {/* ===   new engagementLetter_modal modal section end ===  */}
      </div>
      ;

        this.modalforengagreeRef.current.updateStateContent(viewData);
        this.modalforengagreeRef.current.showContent();
        return viewData;
    }    

    /*** Engagement status Modal ends  */


    loadTabs = () =>{


      let tabsToShow = [...this.state.tabsToShow];
      let structureToDisplay = [];
      

      Object.keys(tabsToShow).map((e,i)=> { 
            if(tabsToShow[i].keyName=="CONFIDENTIALITY_CHECK" && tabsToShow[i].tabShowStatus ===  true) {         
              structureToDisplay.push(
                    <li className={`${this.state.tabContentNumber==1?"active":""}`}>
                        <a href="" onClick={(evn)=>{
                          evn.preventDefault();
                        }}>Identity Masking</a>
                    </li>
                );
            }
            else if(tabsToShow[i].keyName=="CONFLICT_CHECK" && tabsToShow[i].tabShowStatus ===  true) {             
              structureToDisplay.push(
                <li className={`${this.state.tabContentNumber==2?"active":""}`}>
                  <a href="" onClick={(evn)=>{
                    evn.preventDefault();
                  }} >Conflict Check</a>
                </li>
            );
            }
            else if(tabsToShow[i].keyName=="ENGAGEMENT_LETTER" && tabsToShow[i].tabShowStatus ===  true) {             
              structureToDisplay.push(
                <li className={`${this.state.tabContentNumber==3?"active":""}`}>
                <a href="" onClick={(evn)=>{
                  evn.preventDefault();
                }} >Engagement Letter</a>
              </li>
            );
            }


      });

    return (<>{structureToDisplay}</>);

    }

    loadTabContent0 = () =>{
      return (<>
        <div></div>
      </>);
    }

    loadTabContent1 = () => {
      return (       
       
        <div className="engmLetter_modalBody_heightAdjust">
        {/* ===  optional alert message start ===  */}
        <div style={{display: 'none'}} className="alert_secction  ">
          <div className="alert alert-dismissible alert_custom alert-success">
            <p>  Alert Meassage</p>
          </div>
        </div>
        {/* ===  optional alert message end ===  */}
        {/* engmLetter_contBody_bg body start  */}
        <div className="engmLetter_contBody_bg">
          <div className="engmLetter_contBody_scrollAdjust_bg">
            {/* main Confidentiality Check TAB content body start */}
            <div className="popup_tabBlockBg_common popup_confidentialityCheck_tabBlockBg">
              <div className="confidentialityCheck_tabContent">
                <p className="popUp_yellow_text">
                  Your organisation details was kept confidential at the time of task creation. If you proceed to offer the task then professional will be able to see the organisation details.
                </p>
              </div>
            </div>
            {/* main Confidentiality Check TAB content body end */}
            {/* main Engagement Letter TAB content body start */}
            {/* main Engagement Letter TAB content body end */}
          </div>    
        </div>
        {/* engmLetter_contBody_bg body end  */}
        {/* engmLetter_contBody_bg footer start  */}
        <div className="engmLetter_modalFooter">
          <div className="engmLetter_modalFooter_sec">
            <button onClick={(evn)=>{ evn.preventDefault(); this.engagementConfiModalClose(); }} className="btn_appearance_none blank_btn"><span>Cancel</span></button>
            <button onClick={(evn)=>{
              evn.preventDefault();
              this.continueClicked({"clickedFrom":"CONFIDENTIALITY_CHECK"});
              

            }} className="btn_appearance_none sub_grd_btn"><span>Continue</span></button>
          </div>
        </div>
        {/* engmLetter_contBody_bg footer end  */}
      </div>
      );
    }

    loadTabContent2 = () => {

        let conflictStatusTxt = "";  //PASS, FAIL, IN_PROGRESS, NOT_STARTED
        let conflictStatusInfoMsg = "";

        if(this.state.assignRel.conflictCheckStatus!==undefined && this.state.assignRel.conflictCheckStatus!==null){
          if(this.state.assignRel.conflictCheckStatus=="IN_PROGRESS"){
            conflictStatusTxt = "Ongoing";
            conflictStatusInfoMsg = "Conflict check has been initiated by you. Are you sure you want to continue.";
          }
          else if(this.state.assignRel.conflictCheckStatus=="PASS"){
            conflictStatusTxt = "Pass";
            conflictStatusInfoMsg = "Conflict check was passed. You can click on continue.";

          }
          else if(this.state.assignRel.conflictCheckStatus=="FAIL"){
            conflictStatusTxt = "Fail";
            conflictStatusInfoMsg = "Conflict check was failed. Are you sure you want to continue.";

          }


        }
        return (
        <div className="engmLetter_modalBody_heightAdjust">
              {/* ===  optional alert message start ===  */}
              <div style={{display: 'none'}} className="alert_secction  ">
                <div className="alert alert-dismissible alert_custom alert-success">
                  <p>  Alert Meassage</p>
                </div>
              </div>
              {/* ===  optional alert message end ===  */}
              {/* engmLetter_contBody_bg body start  */}
              <div className="engmLetter_contBody_bg">
                <div className="engmLetter_contBody_scrollAdjust_bg">
                  {/* main Confidentiality Check TAB content body start */}
                  {/* main Confidentiality Check TAB content body end */}
                  {/* main Conflict Check TAB content body start */}
                  <div className="popup_tabBlockBg_common popup_conflictcheck_tabBlockBg">
                    <div className="conflictcheck_tabContent">
                      <h3><span className="conflictcheck_titleSpan">Conflict check status : </span>                     
                      {conflictStatusTxt} 
                     
                      </h3>
                    </div>
                    <div className="conflictcheck_tabContent">
                      <p className="popUp_yellow_text">
                      {conflictStatusInfoMsg} 
                      </p>
                    </div>
                  </div>
                  {/* main Conflict Check TAB content body end */}
                  {/* main Engagement Letter TAB content body start */}
                  {/* main Engagement Letter TAB content body end */}
                </div>                            
              </div>
              {/* engmLetter_contBody_bg body end  */}
              {/* engmLetter_contBody_bg footer start  */}
              <div className="engmLetter_modalFooter">
                <div className="engmLetter_modalFooter_sec">
                  <button onClick={(evn)=>{ evn.preventDefault(); this.engagementConfiModalClose(); }}  className="btn_appearance_none blank_btn"><span>Cancel</span></button>
                  <button onClick={(evn)=>{
              evn.preventDefault();
              this.continueClicked({"clickedFrom":"CONFLICT_CHECK"});
              

            }} className="btn_appearance_none sub_grd_btn"><span>Continue</span></button>
                </div>
              </div>
              {/* engmLetter_contBody_bg footer start  */}
        </div>
      );
    }

    loadTabContent3 = () => {

      let taskResp = null;
        
      let ownerName = "";
      let organisationName = (this.state.assignRel.organisationName!==undefined && this.state.assignRel.organisationName!==null)?this.state.assignRel.organisationName: "";
      let lpUserName = (this.state.assignRel.lpUserName!==undefined && this.state.assignRel.lpUserName!==null)?this.state.assignRel.lpUserName: "";
      let taskName = this.state.assignRel.taskOrSubTaskName!==undefined?this.state.assignRel.taskOrSubTaskName: "";
      let taskOrSubTask = this.state.assignRel.taskOrSubTask!==undefined?this.state.assignRel.taskOrSubTask: "";
      let taskIdOrSubTaskId = this.state.assignRel.taskIdOrSubTaskId!==undefined?this.state.assignRel.taskIdOrSubTaskId: "";
      let taskDesc = this.state.assignRel.taskDesc!==undefined?this.state.assignRel.taskDesc: "";
      

      let agreedBudget = (this.state.assignRel.agreedBudget!==undefined && this.state.assignRel.agreedBudget!==null)?this.state.assignRel.agreedBudget: "";
      let agreedBudgetCurrency =  (this.state.assignRel.agreedBudgetCurrency!==undefined && this.state.assignRel.agreedBudgetCurrency!==null)?this.state.assignRel.agreedBudgetCurrency: "";
      let agreedDueDate = (this.state.assignRel.agreedDueDate!==undefined && this.state.assignRel.agreedDueDate!==null)?CustDate.custFormatDate((new Date(this.state.assignRel.agreedDueDate)),3):"No Due Date";
      let isOrganisationDetailsMasked = (this.state.assignRel.isOrganisationDetailsMasked!==undefined && this.state.assignRel.isOrganisationDetailsMasked!==null)?this.state.assignRel.isOrganisationDetailsMasked:false;


      let ccSignatureDate = CustDate.custFormatDate((new Date()),8);

      return (
        <div className="engmLetter_modalBody_heightAdjust">
        {/* ===  optional alert message start ===  */}
        {/* <div style={{display: 'none'}} className="alert_secction  ">
          <div className="alert alert-dismissible alert_custom alert-success">
            <p>  Alert Meassage</p>
          </div>
        </div> */}
        {/* ===  optional alert message end ===  */}
        {/* engmLetter_contBody_bg body start  */}
        <div className="engmLetter_contBody_bg">
          <div className="engmLetter_contBody_scrollAdjust_bg">
            {/* main Engagement Letter TAB content body start */}
            <div className="popup_tabBlockBg_common popup_engagement_tabblockBg">
              <div className="popup_engagement_topTile_block">
              <h3>A Professional Engagement Powered By {getSiteName()}</h3>
              </div>
              <div className="popup_engagement_midContBlock_bg">
                

                <div className="engagementPop_p">
                  <div className="engaLtr_contBlock">
                    <div className="engaLtr_full">
                      <p>This Engagement Letter finalizes the terms and conditions entered 
                        into on <i>[Date of signing of agreement by professional]</i> (the “Effective Date”), by and between 
                      </p>
                    </div>
                    <div className="engaLtr_full">
                      <p>Organisation: <i>{organisationName}</i>; and  
                      </p>
                    </div>
                    <div className="engaLtr_full">
                      <p>Professional: <i>{lpUserName}</i>
                      </p>
                    </div>
                  </div>
                  <div className="engaLtr_contBlock">
                    <div className="engaLtr_half">
                      <p> Task Name: <i>{taskName}</i></p>
                    </div>
                    <div className="engaLtr_half">
                      <p>Task No. <i>{taskIdOrSubTaskId}</i> </p>
                    </div>
                  </div>
                  <div className="engaLtr_contBlock">
                    <div className="engaLtr_full">
                      <p>The Scope of Task shall be: <i>{taskDesc}</i>
                      </p>
                    </div>
                    <div className="engaLtr_full">
                      <p>The Professional shall complete the Task by <i>{agreedDueDate}</i> (the “ Due Date”).
                      </p>
                    </div>
                    <div className="engaLtr_full">
                      <p>The Organisation shall pay the invoice amount <i>{agreedBudgetCurrency}&nbsp;{agreedBudget}</i> (the “Invoice Amount”).
                      </p>
                    </div>
                  </div>
                  <div className="engaLtr_full">
                    <p>
                      The Organisation acknowledges and agrees that subject to the scope and complexity of the Task the Professional 
                      may renegotiate the Due Date, the Scope and the Invoice Amount.Such renegotiated terms after being agreed to by 
                      both the parties shall be incorporated herein by reference.
                    </p>
                    <p>
                      The Professional hereby grants a non-exclusive, perpetual, world-wide license to the Organisation to use the intellectual property 
                      created in the deliverable of the Task.The Professional shall be liable under applicable laws for the deliverable under the Task or 
                      the part thereof including but not limited to professional liability.
                      The Professional shall ensure and maintain the confidentiality of all and any information, written or oral, 
                      including but not limited to documents, images, representations or Intellectual Property among others, that the Professional may receive, 
                      through the Platform or otherwise, from the Organisation for the purpose of Task.The Professional shall 
                      communicate the progress of the Task on the Platform to the Organisation, as and when required.
                    </p>
                    <p>
                      The Organisation shall share all such data or extend any such assistance as required by the Professional for timely completion of the Task.
                      The Organisation shall pay the Invoice Amount and applicable taxes on completion of the Task and raising of invoice by the Professional on the Platform.
                    </p>
                    <p>
                      This Engagement Letter shall constitute all the terms and conditions agreed between the Parties and sets forth the entire understanding and agreement of the 
                      Parties as to the subject matter of this Agreement and supersedes all prior discussions and understandings in respect to the subject of this 
                      Agreement, whether written or oral.
                    </p>
                    <p>
                      Any dispute arising out of this Engagement Letter shall be referred to the independent grievance redressal body 
                      of Flywork Innovations Private Limited. If the dispute fails to be resolved within 30 (thirty) days, then the Organisation and the Professional 
                      may resolve it in accordance with the laws of Republic of India.
                    </p>
                  </div>
                  <div className="clearboth" />
                </div>

              
              </div>
              {/* ===  Popup Engagement Letter Addclose Section start ===  */}
              {/* ===  Popup Engagement Letter Addclose Section end ===  */}
              {/* ===  Popup Engagement Letter Bottom Signature Section start ===  */}
              <div className="popup_engagement_bottomContBlock_signature_sec">
                <div className="pop_engm_bottomSignature_left">
                  <div className="pop_engm_bottomSignature_1">
                    <h5>
                      {/* Signed by */}
                      Signed on behalf of the organization
                      </h5>
                  </div>
                  <div className="pop_engm_bottomSignature_2 pop_engm_bottomSignature_Font">
                    <h5>{"Your digitally generated signature will appear"}</h5>
                  </div>
                  <div className="pop_engm_bottomSignature_3  pop_engm_bottomSignature_dateTime">
                    <span>{ccSignatureDate}</span>
                   
                  </div>
                </div>
                {/*                                         
                                        <div class="pop_engm_bottomSignature_right">
                                            <div class="pop_engm_bottomSignature_1">
                                                <h5>Signed by</h5>
                                            </div>
                                            <div class="pop_engm_bottomSignature_2 pop_engm_bottomSignature_Font">
                                                <h4>Abhisek Manna</h4>
                                            </div>
                                            <div class="pop_engm_bottomSignature_3  pop_engm_bottomSignature_dateTime">
                                                <span>02 Feb 2021,</span>
                                                <span>3:30pm IST</span>
                                            </div>
                                        </div> 
                                        */}
                <div className="clearboth" />
              </div>
              {/* ===  Popup Engagement Letter Bottom Signature Section start ===  */}
            </div>
            {/* main Engagement Letter TAB content body end */}
          </div>                            
        </div>
        {/* engmLetter_contBody_bg body end  */}
        {/* engmLetter_contBody_bg footer start  */}
        <div className="engmLetter_modalFooter">
          <div className="engmLetter_modalFooter_sec">
            <button  onClick={(evn)=>{ evn.preventDefault(); this.engagementConfiModalClose(); }}  className="btn_appearance_none blank_btn"><span>Cancel</span></button>
            <button disabled={this.state.aggreeAndSendOfferBtnClkFlag} onClick={(evn)=>{
              evn.preventDefault();
              this.aggreeAndSendOffer();

            }} className="btn_appearance_none sub_grd_btn"><span>Agree &amp; Send Offer</span></button>
          </div>
        </div>
        {/* engmLetter_contBody_bg footer end  */}
      </div>
      

      );
    }


    continueClicked = (paramObj) =>{
        let clickedFrom = paramObj.clickedFrom!==undefined?paramObj.clickedFrom:"";
        let tabContentNumber = 0; 
        let tabShowStatus = false;      

        let tabsToShow = [...this.state.tabsToShow];
        let filtObj = null;
        if(clickedFrom=="CONFIDENTIALITY_CHECK"){
            // search for CONFLICT_CHECK to get its tabContentNumber
            filtObj = findElement(tabsToShow,"keyName","CONFLICT_CHECK");             
            filtObj = filtObj?filtObj.objData:null;
            if(filtObj){
              tabContentNumber = filtObj.tabNumber;
              tabShowStatus = filtObj.tabShowStatus;
              if(tabShowStatus===false){
                // search for ENGAGEMENT_LETTER to get its tabContentNumber
                filtObj = findElement(tabsToShow,"keyName","ENGAGEMENT_LETTER");
                filtObj = filtObj?filtObj.objData:null;
                  if(filtObj){
                    tabContentNumber = filtObj.tabNumber;                    
                    this.switchTab({"tabContentNumber":tabContentNumber,"reloadTheContent":true});
                  }
              }
              else{
                this.switchTab({"tabContentNumber":tabContentNumber,"reloadTheContent":true});
              }           
              
            }
        }
        else if(clickedFrom=="CONFLICT_CHECK"){
          // search for ENGAGEMENT_LETTER to get its tabContentNumber
          filtObj = findElement(tabsToShow,"keyName","ENGAGEMENT_LETTER");
          filtObj = filtObj?filtObj.objData:null;
            if(filtObj){
              tabContentNumber = filtObj.tabNumber;
              console.log(" filtObj===>>>",filtObj," clickedFrom=>>>",clickedFrom, "switch to tabContentNumber=>>>",tabContentNumber);              
              this.switchTab({"tabContentNumber":tabContentNumber,"reloadTheContent":true});
            }
        }    
        


    }

    switchToConfidentialityTab = () =>{
      this.isComponentLoaded && this.setState({"tabContentNumber":1});

    }

    switchTab = (paramObj) =>{

      let tabContentNumber= paramObj.tabContentNumber?paramObj.tabContentNumber:0; 
      let reloadTheContent= paramObj.reloadTheContent?paramObj.reloadTheContent:false;      

      
      switch(tabContentNumber){
        case 1:{
            this.isComponentLoaded && this.setState({"tabContentNumber":1},()=>{
              if(reloadTheContent){
                this.getEngagementConfiModalContent();
              }
              
            });
            break;
          }
        case 2:{
          this.isComponentLoaded && this.setState({"tabContentNumber":2},()=>{
            if(reloadTheContent){
              this.getEngagementConfiModalContent();
            }
          });
          break;
        }
        case 3:{
          this.isComponentLoaded && this.setState({"tabContentNumber":3},()=>{
            if(reloadTheContent){
              this.getEngagementConfiModalContent();
            }
          });
          break;
        }
        default:{
            this.isComponentLoaded && this.setState({"tabContentNumber":0},()=>{
              if(reloadTheContent){
                this.getEngagementConfiModalContent();
              }
            });
            break;
          } 


      }

    }

    getTabContent = () =>{

      let tabContentNumber= this.state.tabContentNumber;      
      switch(tabContentNumber){
        case 1:{          
          return this.loadTabContent1();
          // setTimeout(()=>{
          //   this.getEngagementConfiModalContent();
          // },2000);
            
          break;
          }
        case 2:{
          return this.loadTabContent2();
          // setTimeout(()=>{
          //   this.getEngagementConfiModalContent();
          // },1500);
          break;
        }
        case 3:{
          return this.loadTabContent3();
          // setTimeout(()=>{
          //   this.getEngagementConfiModalContent();
          // },1500);
          break;
        }
        default:{
          return this.loadTabContent0();
          // setTimeout(()=>{
          //   this.getEngagementConfiModalContent();
          // },1500);
            break;
          } 


      }

    }



    aggreeAndSendOffer = () => {   

      this.isComponentLoaded && this.setState({"aggreeAndSendOfferBtnClkFlag":true},()=>{
        this.getEngagementConfiModalContent();

        let taskOrSubTask = this.state.assignRel.taskOrSubTask!==undefined?this.state.assignRel.taskOrSubTask: "";
        let taskIdOrSubTaskId = this.state.assignRel.taskIdOrSubTaskId!==undefined?this.state.assignRel.taskIdOrSubTaskId: 0;
        let lpUserId = this.state.assignRel.lpUserId!==undefined?this.state.assignRel.lpUserId: 0;



        let paramToPass = {"data":{
          "taskId":taskIdOrSubTaskId,
          "lpUserId":lpUserId
        }};
        this.callSendOfferTask(paramToPass);

        
         
      });
     
        
    }
   

    /**
     * OfferTaskButton
     * Function name : render
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() {    
       
       return (
             <> 
             {this.props.controltype=="BUTTON" && (
                  <button onClick={(evn)=>{
                    evn.preventDefault();
                    this.isComponentLoaded && this.setState({"assignRel":this.props.datas, },()=>{
                        this.openPopupBasedOnCondition();
                    });
                    
                    

                  }} className={`${this.props.custclassname?this.props.custclassname:""}`}>
                  <span>{this.props.controlnametext}</span>
            </button>

             )}               
                
               

                {/* ConflictInfo Modal start */}
                <CustReactBootstrapModal ref={this.modalforconflmessgRef}  backdrop={false} backdropClassName="custom_modal_class "   animation={false} show={this.state.showConflictInfoModal} onShow={()=>{}} onHide={()=>{}} modalcustdialog="custom_modal_lg conflictstatusinfomsgpopupcls"  loaderbodytitle="Organisation"  />
                {/* ConflictInfo Modal end */}

                {/* Engagement Agreement Modal start */}
                <CustReactBootstrapModal ref={this.modalforengagreeRef}  backdrop={false} backdropClassName="custom_modal_class "   animation={false} show={this.state.showEngageAgreeModal} onShow={()=>{}} onHide={()=>{}} modalcustdialog="custom_modal_lg modal-lg modal-dialog-centered newTab_engagementLetter_modalBg newTab_engagementLetter_modalBg_cc"  loaderbodytitle="Organisation"  />
                {/* Engagement Agreement Modal end */}



             </>
            
        );
    }

  }
  export default OfferTaskButton;