/**
 * TaskStatusUpdateButton
 * Page/Class name   : TaskCloseButton
 * Author            :
 * Created Date      : 17-6-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : rendering loader structure
**/
import React, { Component, Suspense, lazy} from 'react';
import '../../../custom_resources/css/custom.css';

// Modal
import CustReactBootstrapModal from './CustReactBootstrapModal';
import {notify} from '../../messagebar/CustomMessageBar';
import CustomMessageBar from '../../messagebar/CustomMessageBar';
// Services
import TaskStatusUpdateService from '../../service/TaskStatusUpdateService';
import TaskDetailsService from '../../service/TaskDetailsService';
// for context
import CommonContext from '../../context/CommonContext';
//Rating
import ReactStars from "react-rating-stars-component";

import {notifytasklistontaskstatuschange} from './TaskListOfSingleTask';
import {notifymultitasklistontaskstatuschange} from './TaskListOfMultiTask';

import { commonJsFuncModule as commonJsObj } from '../../../common_utilities/commonjsfunc';

const getBaseUrl = commonJsObj.getBaseUrl;

const taskStatusKeyVal = [
  {  "key":"COMPLETED","value":"Completed"}
];

const stateVarData = {
  "showTaskStatusUpdateShareModal": false,
"lpUserId": 0,
"ccUserId": 0,
"taskOrSubTask": '',
"taskIdOrSubTaskId": 0,
"rating":0,
"review":"",
"status":"COMPLETED",
"sendButtonFlag":false,
// for error messages
"statusErrMsg": "",
"reviewErrMsg": "",
"ratingErrMsg": ""};

class TaskStatusUpdateButton extends Component {
    /**
     * TaskStatusUpdateButton
     * Function name : constructor
     * Author        :
     * Created Date  : 
     * Purpose       : initializing state
     * Params        : props
    **/

   isComponentLoaded = false;

   // for context
   static contextType = CommonContext; 

    constructor(props) {
        super(props);
        this.state = stateVarData;   

        this.modalfortaskstatusupdateRef = React.createRef();
    }

    /**
     * TaskStatusUpdateButton
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentDidMount() {
        this.isComponentLoaded = true;

        
        
    }

    /**
     * TaskStatusUpdateButton
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {  
        this.isComponentLoaded = false;
    }

   

    ratingChanged = (newRating) => {
      //console.log("newRating==>>",newRating);
      this.isComponentLoaded && this.setState({"rating":newRating});
      setTimeout(()=>{
        let paramObj = {};
        this.getTaskStatusUpdateModalContent(paramObj);
      },1500);
      
    }

    async getTaskDetailData(param){   

      let respData = null;
      let lservdrf = new TaskDetailsService();
      await lservdrf.getTaskDetail(param).then(data =>{ 
  
        respData  = data.data.entity; 
  
          
  
      }).catch((err)=>{                       
          
        let errStatusCodeAr = [400,500]; // allowed error status code
        if(err.response && err.response.data){
            let erRespData = err.response.data;
            if(errStatusCodeAr.indexOf(erRespData.status) !== -1){         
                let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                console.log(" errMsg on task fetch info==>",errMsg);
                // close split screen
                //this.closeSplit();
                                 
        }
        } 


      }).finally(()=>{
         
      });
      
      return respData;
    }

    setTaskResp = async (otherParam) => {

      let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;

      let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;

      if(sharedData && setShare){        
          let paramObj1 = {
                  "data":{
                  "taskId":sharedData.taskId
                  }
           };         
          // set latest task details resp data to other.taskResp
          sharedData.other.taskResp = await this.getTaskDetailData(paramObj1);
          setShare(sharedData);
      }
    }
    

     /**
         * TaskStatusUpdateButton
         * Function name : custValidate
         * Author        :
         * Created Date  : 18-2-2020
         * Purpose       : validate data before submit
         * Params        : 
    **/
    custValidate = ()=>{

        let totErrCountTrack = [];
        let statusErrMsg = "";  
        let reviewErrMsg = ""; 
        let ratingErrMsg = "";  
      

        if(!(this.state.status)||(this.state.status.length==0)){
          statusErrMsg = "Status not provided.";
          totErrCountTrack.push(statusErrMsg);
        } 
        
        if(!(this.state.review)||(this.state.review.length==0)){
          reviewErrMsg = "Review not provided.";
          totErrCountTrack.push(reviewErrMsg);
        }else{
          if(this.state.review.length<50){
            reviewErrMsg = "Minimum 50 characters are needed.";
            totErrCountTrack.push(reviewErrMsg);
          }

        } 

        if(!(this.state.rating)){
          ratingErrMsg = "Rating not provided";
          totErrCountTrack.push(ratingErrMsg);
        } 

        this.isComponentLoaded && this.setState(
          {
            "statusErrMsg":statusErrMsg,
            "reviewErrMsg":reviewErrMsg,
            "ratingErrMsg":ratingErrMsg,

          }
        );
      
      
        if(totErrCountTrack.length>0){                    
                return false;
        }

        return true;
    }

    resetModalState = (paramObj)=>{
        this.isComponentLoaded && this.setState(stateVarData,()=>{
          this.getTaskStatusUpdateModalContent(paramObj);
        });
    }

    callTaskStatusUpdateService = (paramObj) =>{

      const {taskOrSubTask,taskIdOrSubTaskId,lpUserId,ccUserId} = this.props.datas;
        let postData = {
          "remarks":"",
          "rating":this.state.rating,
          "review":this.state.review
        }

        let passParam = {
                        "postData":postData,
                        "taskOrSubTask":taskOrSubTask,
                        "taskIdOrSubTaskId":taskIdOrSubTaskId,
                        "lpUserId":lpUserId,
                        "ccUserId":ccUserId,
                        "status":this.state.status
                        };
        //console.log(" passParam=>",passParam);                
        let stcservice = new TaskStatusUpdateService();
        stcservice.updateTaskStatus({"data":passParam}).then(data =>{
              if(data.status==200){
                  let respData ={};
                  if(data.data.entity!==undefined){
                    notifytasklistontaskstatuschange("CLOSED",{
                    });
                    notifymultitasklistontaskstatuschange("CLOSED",{
                    });
                    let msgNoti = data.data.entity?data.data.entity:"Successfully updated"; 
                   
  
                    notify(msgNoti,true,{
                        "successMsg":1,
                        "customWrapClass":"customerrmidlass",
                        "showCloseButton": false, 
            
                        "dismiss": {
                        "duration": 1700,
                        }
            
                        });       
                    setTimeout(()=>{
                              this.taskStatusUpdateModalClose();
                              this.setTaskResp({});   
                          
                    },1900);

                    setTimeout(()=>{
                      this.props.onsuccess();
                    },2000);
                    
                    
                    
              }
          }
          }).catch((err)=>{
                  
                  let errStatusCodeAr = [400,500]; // allowed error status code
                  if(err.response && err.response.data){
                      let erRespData = err.response.data;
                      if(errStatusCodeAr.indexOf(erRespData.status) !== -1){         
                          let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                          console.log(errMsg);

                          notify(errMsg,true,{
                            "successMsg":0,
                            "customWrapClass":"customerrmidlass",
                            "showCloseButton": false, 
                
                            "dismiss": {
                            "duration": 1700,
                            }
                
                            });   
                                
                                           
                  }
                  } 
          }).finally(()=>{

            setTimeout(()=>{
                this.isComponentLoaded && this.setState({"sendButtonFlag":false},()=>{
                    this.getTaskStatusUpdateModalContent(paramObj);
                });            
                          
            },1500);
  
            
                       
              
          });
    }

    preCallTaskStatusUpdateService = (paramObj) =>{
        this.getTaskStatusUpdateModalContent(paramObj);
       
        // make any validation
        let chk = this.custValidate(paramObj);
        if(chk){
            this.callTaskStatusUpdateService({});
        }else{
            this.isComponentLoaded && this.setState({"sendButtonFlag":false},()=>{
                this.getTaskStatusUpdateModalContent(paramObj);
            });
        }
        
        
    }

    triggerTaskStatusUpdateModalOpen = (otherParam) =>{

        let invoiceInfo = this.props.invoiceInfo?this.props.invoiceInfo:null;

        this.isComponentLoaded && this.setState({"showTaskStatusUpdateShareModal": false,
        "tags": [],
        "invoiceInfo": invoiceInfo,"sendButtonFlag":false},()=>{

                this.taskStatusUpdateModalOpen(otherParam);
        });


    }

    trackControlDataChange = (param)=>{
          let controlName = param.controlName;
          let controlValue = param.value;
         this.isComponentLoaded && this.setState({[controlName]:controlValue});
    }


    /*** TaskStatusUpdateButton  share modal starts  */
    /**
     * TaskStatusUpdateButton
     * Function name : conflictModalOpen
     * Author        :
     * Created Date  : 29-3-2020
     * Purpose       : To open conflict modal
     * Params        : 
    **/  
   taskStatusUpdateModalOpen = (otherParam)=>{    
    this.isComponentLoaded &&  this.setState({showTaskStatusUpdateShareModal:true},()=>{
      this.modalfortaskstatusupdateRef.current.showModal();
      this.getTaskStatusUpdateModalContent(otherParam);
    });    
      
  }

  /**
     * TaskStatusUpdateButton
     * Function name : conflictModalClose
     * Author        :
     * Created Date  : 17-6-2020
     * Purpose       : To close conflict modal
     * Params        : 
    **/
   taskStatusUpdateModalClose = ()=>{    
    this.isComponentLoaded && this.setState({showTaskStatusUpdateShareModal:false},()=>{
      this.modalfortaskstatusupdateRef.current.hideModal();
    });
    }

    /**
   * TaskStatusUpdateButton
   * Function name : getConflictModalContent
   * Author        :
   * Created Date  : 17-6-2020
   * Purpose       : Get modal view with data
   * Params        : 
  **/
    getTaskStatusUpdateModalContent(otherParam) {  
      
      console.log(" from getTaskStatusUpdateModalContent otherParam==>",otherParam);
        
        let lawyerConflictCheckStatus = otherParam.lawyerConflictCheckStatus!==undefined?otherParam.lawyerConflictCheckStatus: "";

        let viewData = <div className="dash_modal conflictpopbdyoutercls">
        
                <>
            <div className="modal-header">
                <h4 className="modal-title modal_title_tool"> Task Status 
                {/*<div className="input_tooltip_bg">
                        <span className="input_tooltip" aria-label="Organisation's Details" data-balloon-pos="down">
                        <i className="fa fa-question-circle"></i>
                        </span>
                        </div>*/}
                </h4>
                <button type="button" onClick={
                    (evn)=>{
                        evn.preventDefault();
                    this.taskStatusUpdateModalClose();

                }} className="close modal_close" data-dismiss="modal">&times;</button>
            </div>
            <CustomMessageBar />
            <div className="modal-body padding_top">
                <div className="modal_body_row_bg invoPay_modal_body">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <h4>Create a notary document</h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-7 col-sm-12 col-xs-12">
                    <div className={`modal_input modal_select ${this.state.statusErrMsg?" form_group_error":""}`}>
                      <select name="organisationSize" className="selectpicker dropup">
                        {Object.keys(taskStatusKeyVal).map((e,i)=>(
                          <option value={taskStatusKeyVal[e].key}>{taskStatusKeyVal[e].value}</option>
                        ))}
                        
                        
                      </select>
                        <span className="error_span">{this.state.statusErrMsg}</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className={`modal_input form__group field ${this.state.reviewErrMsg?" form_group_error":""}`}>
                      <textarea
                        placeholder="Please add a review (Min 50 characters)"
                        onChange={(evn)=>{
                            evn.preventDefault();
                            let param = {"controlName":"review","value":evn.target.value}
                            this.trackControlDataChange(param);
                        }}
                        >
                          {this.state.review}
                        </textarea>
                        
                      
                      <span className="error_span">{this.state.reviewErrMsg}</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className={`modal_input form__group field ${this.state.reviewErrMsg?" form_group_error":""}`}>
                      <div className="modal_rating_sec">
                        <label>Rate</label>
                        <div className="modal_rating">              
                          <ReactStars
                          count={5}
                          value={this.state.rating}
                          onChange={this.ratingChanged}
                          size={24}
                          color2={"#ffd700"}
                          />
                        </div>
                      </div>
                      <span className="error_span">{this.state.ratingErrMsg}</span>
                    </div>
                  </div>
                </div>
            </div>
            
            </div>

            <div className="modal-footer">
                {/* <button onClick={
                    (evn)=>{
                        evn.preventDefault();
                    this.taskStatusUpdateModalClose();

                }}  type="button"  className="btn_appearance_none modal_text_btn"><span>Cancel</span></button> */}
                       
                <button disabled={this.state.sendButtonFlag}  type="button" className="btn btn-primary modal_btn_save" onClick={(e)=>{ e.preventDefault();  
                this.isComponentLoaded && this.setState({"sendButtonFlag":true},()=>{
                    this.preCallTaskStatusUpdateService(otherParam);
                });
                

                } } ><span>Save</span></button>
            </div>
          
          
          </>
        
                        
        </div>;
    
        this.modalfortaskstatusupdateRef.current.updateStateContent(viewData);
        this.modalfortaskstatusupdateRef.current.showContent();
        return viewData;
    }    

    /*** TaskStatusUpdateButton modal related ends  */


   

    /**
     * TaskStatusUpdateButton
     * Function name : render
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() {    
       
       return (
             <>                
                <button key={this.props.custkeyid?this.props.custkeyid:"k001"} onClick={(evn)=>{
                        evn.preventDefault();
                        //console.log(" from listing page==>"," datas=>>",this.props.datas);
                        this.resetModalState({});
                        setTimeout(()=>{
                          this.triggerTaskStatusUpdateModalOpen({});
                        },1500);
                        

                      }} className={`${this.props.custclassname?this.props.custclassname:""}`}>
                      <span>{this.props.buttontextname?this.props.buttontextname:"Close task"}</span>
                </button>
               

                {/* ConflictInfo Modal start */}
                <CustReactBootstrapModal ref={this.modalfortaskstatusupdateRef}  backdrop={false} backdropClassName="custom_modal_class "   animation={false} show={this.state.showTaskStatusUpdateShareModal} onShow={()=>{}} onHide={()=>{}} modalcustdialog="custom_modal_lg taskinvsharepopupcls"  loaderbodytitle="Organisation"  />
                {/* ConflictInfo Modal end */}

               


             </>
            
        );
    }

  }
  export default TaskStatusUpdateButton;