/**
 * VbaCommonPart
 * Page/Class name   : VbaCommonPart
 * Author            :
 * Created Date      : 9-6-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : rendering loader structure
**/
import React, { Component, Suspense, lazy} from 'react';
import '../../../custom_resources/css/custom.css';

// Modal
import CustReactBootstrapModal from './CustReactBootstrapModal';
import {notify} from '../../messagebar/CustomMessageBar';
import CustomMessageBar from '../../messagebar/CustomMessageBar';
// Services
import MarkInvoicePaidService from '../../service/MarkInvoicePaidService';
// for context
import CommonContext from '../../context/CommonContext';
import TaskInvoiceShare from './TaskInvoiceShare';
import VbaShare from './VbaShare';
import { commonJsFuncModule as commonJsObj } from '../../../common_utilities/commonjsfunc';

const getCcUserId = commonJsObj.getCcUserId;





class VbaCommonPart extends Component {
    /**
     * VbaCommonPart
     * Function name : constructor
     * Author        :
     * Created Date  : 
     * Purpose       : initializing state
     * Params        : props
    **/

   isComponentLoaded = false;

   // for context
   static contextType = CommonContext; 

    constructor(props) {
        super(props);
        this.state = {
        //vba
        "vbaInfo":null,
        "accountBalance":0,
        "currency":"",
        "lastFetchedFromTheBankAt":null,
        "virtualAccountNumber":"",
        "ifsc":"",
        "benificieryName":"",
        "qrCodeImageURL":null,
        "qrCodeImagePath":null,
        "qrCodeUPIAppOpener":null,

        // //invoice
        // "invoiceInfo":null,
        // "invoiceId":0,
        // "invoiceNumber":"",
        // "invoiceAmount":0,
        // "totalInvoiceAmountToBePaid":0,
        // "gstAdded":0,
        // "isReverseGstApplicable":false,
        // "tdsAmountDeducted":0,

        //  // other important information
        //  "taskOrSubTask":"",
        //  "taskIdOrSubTaskId":0,
        //  "ccUserId":0,
        //  "lpUserId":0,
            
        }    

        this.modalfortaskinvshareRef = React.createRef();
    }

    /**
     * TaskInvoiceShare
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentDidMount() {
    this.isComponentLoaded = true;

    setTimeout(()=>{
    const {vbaInfo,injectedIn} = this.props.datas;    

        //console.log(" this.props==>",this.props," sharedData=>",sharedData," invoiceInfo==>",invoiceInfo, " vbaInfo=>",vbaInfo);
        
       
       // this.setUserVirtualBankBalanceStateData(vbaInfo,{"injectedIn":injectedIn});
    },1500);

    

        
        
    }

    /**
     * TaskInvoiceShare
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {  
        this.isComponentLoaded = false;
    }

     /**
     * TaskInvoiceShare
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/

    static getDerivedStateFromProps(props, state) {
      // console.log(" 11called =>getDerivedStateFromProps =>> props=>",props," state=>>",state);
      const vbaInfo = props.datas.vbaInfo!==undefined?props.datas.vbaInfo:null;
      if (vbaInfo !==null && vbaInfo !== state.vbaInfo) {
        //console.log(" == 22 called getDerivedStateFromProps===== ");
        return {
          "vbaInfo": vbaInfo,
          "accountBalance":(vbaInfo.accountBalance!==undefined && vbaInfo.accountBalance!==null)?vbaInfo.accountBalance:0,
          "currency":(vbaInfo.currency!==undefined && vbaInfo.currency!==null)?vbaInfo.currency:"",
          "lastFetchedFromTheBankAt":(vbaInfo.lastFetchedFromTheBankAt!==undefined && vbaInfo.lastFetchedFromTheBankAt!==null)?vbaInfo.lastFetchedFromTheBankAt:null,
          "virtualAccountNumber":(vbaInfo.virtualAccountNumber!==undefined && vbaInfo.virtualAccountNumber!==null)?vbaInfo.virtualAccountNumber:0,
          "ifsc": (vbaInfo.ifsc!==undefined && vbaInfo.ifsc!==null)?vbaInfo.ifsc:"",
          "benificieryName":(vbaInfo.benificieryName!==undefined && vbaInfo.benificieryName!==null)?vbaInfo.benificieryName:"",
          "qrCodeImageURL":(vbaInfo.qrCodeImageURL!==undefined && vbaInfo.qrCodeImageURL!==null)?vbaInfo.qrCodeImageURL:"",
          "qrCodeImagePath": (vbaInfo.qrCodeImagePath!==undefined && vbaInfo.qrCodeImagePath!==null)?vbaInfo.qrCodeImagePath:null,
          "qrCodeUPIAppOpener":(vbaInfo.qrCodeUPIAppOpener!==undefined && vbaInfo.qrCodeUPIAppOpener!==null)?vbaInfo.qrCodeUPIAppOpener:null,
        };
      }
  
      // Return null to indicate no change to state.
      return null;
    }

  

    setUserVirtualBankBalanceStateData = (vbBalInfo,otherParam) => {

        if(vbBalInfo!==undefined && vbBalInfo!==null){

            let accountBalance = (vbBalInfo.accountBalance!==undefined && vbBalInfo.accountBalance!==null)?vbBalInfo.accountBalance:0;
    
            let currency = (vbBalInfo.currency!==undefined && vbBalInfo.currency!==null)?vbBalInfo.currency:"";
    
            let lastFetchedFromTheBankAt = (vbBalInfo.lastFetchedFromTheBankAt!==undefined && vbBalInfo.lastFetchedFromTheBankAt!==null)?vbBalInfo.lastFetchedFromTheBankAt:null;
    
            let virtualAccountNumber = (vbBalInfo.virtualAccountNumber!==undefined && vbBalInfo.virtualAccountNumber!==null)?vbBalInfo.virtualAccountNumber:0;
    
            let ifsc = (vbBalInfo.ifsc!==undefined && vbBalInfo.ifsc!==null)?vbBalInfo.ifsc:"";
    
            let benificieryName = (vbBalInfo.benificieryName!==undefined && vbBalInfo.benificieryName!==null)?vbBalInfo.benificieryName:"";
    
            let qrCodeImageURL = (vbBalInfo.qrCodeImageURL!==undefined && vbBalInfo.qrCodeImageURL!==null)?vbBalInfo.qrCodeImageURL:"";
    
            let qrCodeImagePath = (vbBalInfo.qrCodeImagePath!==undefined && vbBalInfo.qrCodeImagePath!==null)?vbBalInfo.qrCodeImagePath:null;
    
            let qrCodeUPIAppOpener = (vbBalInfo.qrCodeUPIAppOpener!==undefined && vbBalInfo.qrCodeUPIAppOpener!==null)?vbBalInfo.qrCodeUPIAppOpener:null;
    
            let vbaBalStateData = { 
            "vbaInfo":vbBalInfo,
            "accountBalance":accountBalance,
            "currency":currency,
            "lastFetchedFromTheBankAt":lastFetchedFromTheBankAt,
            "virtualAccountNumber":virtualAccountNumber,
            "ifsc":ifsc,
            "benificieryName":benificieryName,
            "qrCodeImageURL":qrCodeImageURL,
            "qrCodeImagePath":qrCodeImagePath,
            "qrCodeUPIAppOpener":qrCodeUPIAppOpener,
            };
            this.isComponentLoaded && this.setState(vbaBalStateData);
        }
        
    }

    

   

    /**
     * TaskInvoiceShare
     * Function name : render
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() {    
       
       return (
             <>                
                <div className="pay_det_sec pay_det_color">
              <div className="pay_det_sec_p">
                <p>
                  {/* You can add money to your virtual bank account using UPI,
                  NEFT, RTGS and IMPS or use share details below to ask
                  accounts team to add money to virtual bank account */}
                  You can add the money by scanning the QR code on Google Pay or PhonePe. You can
                  share the account details below to ask accounts team to add money to virtual bank account
                </p>
              </div>
              <div className="qr_block_bg">
                <div className="qr_block_left">
                  <div className="qr_block_row">
                    <div className="qr_l">Account Number</div>
                    <div className="qr_r">{this.state.virtualAccountNumber}</div>
                    <div className="clearboth" />
                  </div>
                  <div className="qr_block_row">
                    <div className="qr_l">IFSC</div>
                    <div className="qr_r">{this.state.ifsc}</div>
                    <div className="clearboth" />
                  </div>
                  <div className="qr_block_row">
                    <div className="qr_l">Benificiary Name</div>
                    <div className="qr_r">
                      {this.state.benificieryName}
                    </div>
                    <div className="clearboth" />
                  </div>
                </div>
                <div className="qr_block_right">
                  <a href="">
                    <img
                      className="qr_img"
                      src={this.state.qrCodeImagePath}
                    />
                  </a>
                </div>
                <div className="clearboth" />
              </div>
              <div className="qr_block_btn">
              <VbaShare custkeyid={"1"} custclassname="sub_grd_btn" buttontextname={"Share Details"} datas={{}} />
                {/* <button className="sub_grd_btn">
                  <span>Share Details</span>
                </button> */}
              </div>
              <div className="pay_det_sec_p">
                <p>
                  Some of the payment modes may take a while to be processed
                  and hence there could be a delay to reflect the amount in
                  your virtual bank account. You will receive a notification
                  once the virtual bank account balance is updated
                </p>
              </div>
            </div>
          

               


             </>
            
        );
    }

  }
  export default VbaCommonPart;