/**
 * TaskTabPage
 * Page/Class name   : TaskTabPage
 * Author            :
 * Created Date      : 20-03-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : rendering dashboard
**/
import React, { Component, Suspense, lazy} from 'react';
import {withRouter} from 'react-router-dom';
import '../../../custom_resources/css/custom.css';

// for context
import CommonContext from '../../context/CommonContext';

// Modal
import CustReactBootstrapModal from './CustReactBootstrapModal';

// scroll 
import { Scrollbars } from 'react-custom-scrollbars';

import {commonJsFuncModule as commonJsObj} from  '../../../common_utilities/commonjsfunc';

import TaskListOfSingleTask from './TaskListOfSingleTask.js';
import TaskListOfMultiBundleTask from './TaskListOfMultiBundleTask.js';
import TaskListOfMultiTask from './TaskListOfMultiTask.js';

// common noti track
//import NotiEmitTrack from "./NotiEmitTrack";
import {commonnotitrack} from "./NotiEmitTrack";

const getAccessObj = commonJsObj.getAccessObj;


const defaultFilterOptnStatic = {"key":"ALL_SINGLE_TASKS","value":"All (Default)"};
// multitask list filter options
const filterOptionsMt = [ 
    {"key":"ALL_SINGLE_TASKS","value":"All (Default)"},    
    {"key":"TO_ASSIGN","value":"To be Assigned"},
    {"key":"ASSIGNED","value":"Assigned (Ongoing)"},
];
// general list filter options
const filterOptions = [ 
    {"key":"ALL_SINGLE_TASKS","value":"All (Default)"},    
    {"key":"TO_ASSIGN","value":"To be Assigned"},
    {"key":"ASSIGNED","value":"Assigned (Ongoing)"},
    {"key":"DRAFTS","value":"Saved Drafts"},
   
    // {"key":"MULTI_TASKS","value":"MULTI_TASKS"},
];

// default sort option
const defaultSortOptnStatic = {"key":"CREATED_DATE","value":"Create Date - Newest First","key2":"DESC"};

// For filter MULTI_TASKS sort options
const sortOptionsMt = [ 
    {"key":"CREATED_DATE","value":"Create Date - Newest First","key2":"DESC"},
    {"key":"CREATED_DATE","value":"Create Date - Oldest First","key2":"ASC"},
    {"key":"ASSIGNED_DATE","value":"Assigned Date - Newest First","key2":"DESC"},
    {"key":"ASSIGNED_DATE","value":"Assigned Date - Oldest First","key2":"ASC"},
    {"key":"DUE_DATE","value":"Due Date - Newest First","key2":"DESC"},
    {"key":"DUE_DATE","value":"Due Date - Oldest First","key2":"ASC"},    
    {"key":"BUDGET","value":"Budget - Highest to Lowest","key2":"DESC"},
    {"key":"BUDGET","value":"Budget - Lowest to Highest","key2":"ASC"},
];

// For filter ALL_SINGLE_TASKS sort options
const sortOptionsSt = sortOptionsMt;

// For filter ASSIGNED sort options
const sortOptionsAs = sortOptionsMt;

// For filter DRAFTS sort options
const sortOptionsDr = [ 
    {"key":"CREATED_DATE","value":"Create Date - Newest First","key2":"DESC"},
    {"key":"CREATED_DATE","value":"Create Date - Oldest First","key2":"ASC"},    
];

// For filter TO_ASSIGN sort options
const sortOptionsToAs = [ 
    {"key":"CREATED_DATE","value":"Create Date - Newest First","key2":"DESC"},
    {"key":"CREATED_DATE","value":"Create Date - Oldest First","key2":"ASC"},
    {"key":"DUE_DATE","value":"Due Date - Newest First","key2":"DESC"},
    {"key":"DUE_DATE","value":"Due Date - Oldest First","key2":"ASC"},          
    {"key":"BUDGET","value":"Budget - Highest to Lowest","key2":"DESC"},
    {"key":"BUDGET","value":"Budget - Lowest to Highest","key2":"ASC"},
];



class TaskTabPage extends Component {
    isComponentLoaded = false;

    // for context
    static contextType = CommonContext;    

    /**
     * TaskTabPage
     * Function name : constructor
     * Author        :
     * Created Date  : 26-3-2020
     * Purpose       : initializing state
     * Params        : props
    **/
    constructor(props) {
        super(props);
        
        this.state = {
            
            step : 1,
            defaultFilterOptn: defaultFilterOptnStatic,
            defaultSortOptn: defaultSortOptnStatic,
            defFilterOptions: filterOptions,
            defMtFilterOptions: filterOptionsMt,
            defSortOptions: [],

            multiTaskId: 0, // for multi task id from multitask bundle


            
            

        };
    }

   



    /**
     * TaskTabPage
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 20-3-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentDidMount() {
        this.isComponentLoaded = true;

        //*** check allowed start ****
        let accessObj = getAccessObj(); 
        let isKycVerified = accessObj.isKycVerified!==undefined && accessObj.isKycVerified!==null?accessObj.isKycVerified: false;
        let isSubscriptionActive = accessObj.isSubscriptionActive!==undefined && accessObj.isSubscriptionActive!==null?accessObj.isSubscriptionActive: false;  

        if(isKycVerified===false||isSubscriptionActive===false){
            this.props.history.push("/dashboard");
        }
        //**** check allowed end ****  



        //********* for context starts *****
        // updating data in context 
        // let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;  
        // example
        //setShare({name:"data"});          
        let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;
        
        if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){  

            //console.log(" sharedData===>>",sharedData);


            if(sharedData.actionType=="SINGLETASK" ){
                this.gotoToStep({step:1});
                this.unsetContextData({});
            }
            else if(sharedData.actionType=="MULTITASK"){

                // forcefully making multi task bundle list appear
                setTimeout(()=>{
                    let filtObj = {"key":"MULTI_TASKS","value":""}; //default will get set then
                    this.setTaskListRelDataOfSingleTask("setChangedFilterOption",filtObj,{});                   
                    setTimeout(()=>{
                        this.gotoToStep({step:2});
                        setTimeout(()=>{this.unsetContextData({});},450);
                        
                    },500);
                    
                },900);
                
            }
            else if(sharedData.actionType=="DRAFT"){               
                
                this.gotoToStep({step:1});
                // note it is a special case so unset of context for single task list  has been done from TaskListOfSingleTask componentDidMount func 
                //this.unsetContextData({});         
            }
        }
        //********* for context ends *****      
        
       
               
        
         
    }

    unsetContextData = (param) =>{
        
        //********* for context starts *****
        // updating data in context 
        let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;  
        // example
        if(setShare){
            if(param.key!==undefined && param.value !==undefined ){
                let paramKey = param.key;
                let paramValue = param.value;
                setShare({paramKey:paramValue}); 

            }else{
                setShare({}); 
            }                                
        }                      
                          
        //********* for context ends ***** 
    }

    /**
     * TaskTabPage
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 20-3-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {  
        this.isComponentLoaded = false;
    }

   

    closeSplit = ()=>{
        //********* for context starts *****
        let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;      
        if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){         
  
            if(sharedData.actionType=="SPLITSCREEN" ){
  
              let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;
  
              if(setShare){                              
                let paramsCntxt = {
                  "taskId":"",
                  "actionType":"",
                  "leftTabToActive":"",
                  "purpose":"",
                  "other":{}
                } 
                setShare(paramsCntxt);                              
              }
  
              
            }
        }
        //********* for context ends *****
  
      }
   
   goToCreateTaskPage = () =>{
        //this.closeSplit();
        commonnotitrack("CLOSE_SPLITSCREEN",{
            "notiType": "CLOSE_SPLITSCREEN",
            "triggerCallback":false,
            "closeSplitCalledFrom":"TASK_TAB_CREATE_TASK_BUTTON"
           });
        this.props.history.push("/task-add");
   }

   setTaskListRelDataOfSingleTask =  (key, value, otherParam) =>{

        //this.closeSplit();
        switch(key){
                case "setChangedFilterOption":
                    let obj = value;
                    if(obj===null){
                        // set the default filter
                        obj = defaultFilterOptnStatic;
                    }
                
                    this.isComponentLoaded && this.setState({"defaultFilterOptn":obj});
                    
                    switch(obj.key){
                        case "ASSIGNED":
                            this.isComponentLoaded && this.setState({"defSortOptions":sortOptionsAs,"defaultSortOptn":defaultSortOptnStatic});            
                            break;
                        case "TO_ASSIGN":
                            this.isComponentLoaded && this.setState({"defSortOptions":sortOptionsToAs,"defaultSortOptn":defaultSortOptnStatic});
                            
                            break;
                        case "DRAFTS":
                            this.isComponentLoaded && this.setState({"defSortOptions":sortOptionsDr,"defaultSortOptn":defaultSortOptnStatic},()=>{
                               
                            });
                            
                            break;
                        case "ALL_SINGLE_TASKS":
                            this.isComponentLoaded && this.setState({"defSortOptions":sortOptionsSt,"defaultSortOptn":defaultSortOptnStatic});
                            
                            break;
                        case "MULTI_TASKS":
                            this.isComponentLoaded && this.setState({"defSortOptions":sortOptionsMt,"defaultSortOptn":defaultSortOptnStatic},()=>{
                                console.log(" **** setChangedFilterOption MULTI_TASKS=>",this.state," sortOptionsMt==>",sortOptionsMt, " defaultSortOptnStatic=>",defaultSortOptnStatic);
                            });
                            
                            break;
                
                    }
                    break;
                case "setChangedSortOption":
                    let obj2 = value;
                    this.isComponentLoaded && this.setState({"defaultSortOptn":obj2});
                    break;
                case "setMultiTaskId":
                    let multiTaskId = value;
                   
                    this.isComponentLoaded && this.setState({"multiTaskId":multiTaskId},()=>{
                        
                    });
                    break;
        }
   }


    gotoToStep = (otherParam)=>{
       
        this.isComponentLoaded &&  this.setState({
            step: otherParam.step
        },()=>{
           
        });
    
    }
    
    render() {
        
        const {step} =  this.state;        
        const {
            
            //single task list
            defaultFilterOptn,defaultSortOptn,defFilterOptions,defSortOptions,
            //multi task list
            defMtFilterOptions,multiTaskId
            } =  this.state;
            

        //single task list
        const singleTaskListRel = {step,defaultFilterOptn,defaultSortOptn,defFilterOptions,defSortOptions};
        //multi task list
        const multiTaskListRel = {step,defaultFilterOptn,defaultSortOptn,defMtFilterOptions,defSortOptions,multiTaskId};

       

        switch(step){
                case 1:                    
                   
                    // single task list flow
                    return (
                       
                        <TaskListOfSingleTask 
                        values={singleTaskListRel} 
                        
                        gotoToStep={this.gotoToStep}
                        
                        setTaskListRelDataOfSingleTask={this.setTaskListRelDataOfSingleTask}
                        goToCreateTaskPage={this.goToCreateTaskPage}
                        unsetContextData={this.unsetContextData}                     
                        
                        
                        />
                       
                         );
                    
                    
                    break;
                case 2:
                    console.log(" *** case 2 rendering TaskListOfMultiBundleTask ***"," singleTaskListRel =>>",singleTaskListRel);
                    // multi task bundle list flow                    
                    return (
                        <TaskListOfMultiBundleTask 
                        values={singleTaskListRel} 
                        
                        gotoToStep={this.gotoToStep}
                        setTaskListRelDataOfSingleTask={this.setTaskListRelDataOfSingleTask}
                        goToCreateTaskPage={this.goToCreateTaskPage}
                        unsetContextData={this.unsetContextData} 

                        
                        
                        
                        />
                         );
                    break;
                case 3:
                    console.log(" *** case 3 rendering TaskListOfMultiTask ***"," multiTaskListRel =>>",multiTaskListRel);
                    // multi task bundle list flow                    
                    return (
                        <TaskListOfMultiTask 
                        values={multiTaskListRel} 
                        
                        gotoToStep={this.gotoToStep}
                        setTaskListRelDataOfSingleTask={this.setTaskListRelDataOfSingleTask}
                        goToCreateTaskPage={this.goToCreateTaskPage}
                        unsetContextData={this.unsetContextData} 
                        
                        
                        
                        />
                         );
                    break;                                        
        }
       
       
    }

   

  }
  export default withRouter(TaskTabPage);