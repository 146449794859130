/**  
 * NotifyToUpdateTimeLogService
 * Page/Class name   : CreateTaskService
 * Author            :
 * Created Date      : 22-5-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, getTweetRecords
 * Purpose           : api service page
**/
import {Component } from 'react';
// import {getEndPoint,getTokenFromLocStorage, callApi} from  '../../common_utilities/commonjsfunc';
// newly added axios
//import axios from 'axios';
import {axiosCustomObj} from '../customisedthirdparty/AxiosCustom';

import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
const callApi = commonJsObj.callApi;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;



class NotifyToUpdateTimeLogService extends Component {

    
    /**  
     * NotifyToUpdateTimeLogService
     * Function name : constructor
     * Author        :
     * Created Date  : 22-5-2020
     * Purpose       : initializing state
     * Params        : 
    **/
    constructor() {
      super();
      this.state = {
          
      }

      //this.axiosObj = axios;
      this.axiosObj = null;
      
      this.apiEndPoint = commonJsObj.getEndPoint()+'rest/time/update';
    }

    /**  
     * NotifyToUpdateTimeLogService
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 22-5-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentDidMount() {
    }

    /**  
     * NotifyToUpdateTimeLogService
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 6-3-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentWillUnmount() {  
    }



    /**  
     * NotifyToUpdateTimeLogService
     * Function name : sendNotificationToUpdateTimeLog
     * Author        :
     * Created Date  : 22-5-2020
     * Purpose       : send Notification ToUpdate Time Log 
     * Params        : paramObj
    **/
    async sendNotificationToUpdateTimeLog(paramObj){
      this.axiosObj = axiosCustomObj({});
      let accToken = getTokenFromLocStorage();     
      try{
            let urldata =this.apiEndPoint;            
            
            let dataObj = paramObj.data;
            //let lpUserId = paramObj.lpUserId?paramObj.lpUserId:0;

            let taskIdOrSubTaskId = paramObj.taskIdOrSubTaskId?paramObj.taskIdOrSubTaskId:0;
            let taskOrSubTask = paramObj.taskOrSubTask?paramObj.taskOrSubTask:"";

            if(taskOrSubTask=="STASK"){
              urldata = urldata+"/task/"+taskIdOrSubTaskId;
            }
            else if(taskOrSubTask=="SWSTASK"){
              urldata = urldata+"/sub_task/"+taskIdOrSubTaskId;
            }
            
            //console.log(" ==dataObj==>",dataObj);


            let headerOptn = {  
              "Content-Type": "application/json",
              "Authorization": accToken
            };          

            let respObj = this.axiosObj.post(urldata,dataObj, {
            headers: headerOptn
            });

            return respObj;
    }
    catch(err){
        console.log("Some error occured===>",err); // TypeError: failed to fetch
    }
      

    }

    
}
export default NotifyToUpdateTimeLogService;