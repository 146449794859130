/**  
 * SplitScreenSingleTaskWithSubTaskDetails
 * Page/Class name   : MasterLayout
 * Author            :
 * Created Date      : 7-2-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : render master component
**/
import React, { Component } from 'react';


// import CustomMessageBar from '../messagebar/CustomMessageBar';

import SplitScreenLeftMenu from "../../share/SplitScreenLeftMenu";

// for context
import CommonContext from '../../context/CommonContext';
import  CustDate  from '../../../common_utilities/CustDate';

import TaskDetailsService from '../../service/TaskDetailsService';

// common noti track
import NotiEmitTrack from "./NotiEmitTrack";
import {commonnotitrack} from "./NotiEmitTrack";

// scroll 
import { Scrollbars } from 'react-custom-scrollbars';
import { commonJsFuncModule as commonJsObj } from '../../../common_utilities/commonjsfunc';

const getBaseUrl = commonJsObj.getBaseUrl;

const stateStaticData = {
  taskId: "",
  taskName: "",
  description: "",
  practiceAreas: [],
  locations: [],
  taskCategory: null,
  categoryType: null,
  budgetCurrency: "",
  budget: "",
  assignedTo: "",
  dueDateMillis: "",
  taskFiles: [],
  subTasks: []

 
};

class SplitScreenSingleTaskWithSubTaskDetails extends Component {

    isComponentLoaded =  false;
    // for context
    static contextType = CommonContext; 

    /**  
     * SplitScreenSingleTaskWithSubTaskDetails
     * Function name : constructor
     * Author        :
     * Created Date  : 7-2-2020
     * Purpose       : initializing children
     * Params        : props
    **/
   constructor(props) {
    super(props);
    this.children = props.children;

    this.state = stateStaticData;
  }

    /**  
     * SplitScreenSingleTaskWithSubTaskDetails
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 7-2-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
   componentDidMount() {
    this.isComponentLoaded = true;
    //console.log(" split screen single task loaded......");

    //********* for context starts *****
    let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;      
    if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){         

        if(sharedData.actionType=="SPLITSCREEN" ){
          let respData = sharedData.other.taskResp;
          
          this.setStateData(respData);
        }
    }
    //********* for context ends *****
    
  }

    /**  
     * SplitScreenSingleTaskWithSubTaskDetails
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 7-2-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {
      this.isComponentLoaded = false;
    }

    closeSplit = ()=>{
      //********* for context starts *****
      let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;      
      if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){         

          if(sharedData.actionType=="SPLITSCREEN" ){

            let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;

            if(setShare){                              
              let paramsCntxt = {
                "taskId":"",
                "actionType":"",
                "leftTabToActive":"",
                "purpose":"",
                "other":{}
              } 
              setShare(paramsCntxt);                              
            }

            
          }
      }
      //********* for context ends *****

    }

    getTaskDetailData = (param)=>{

      let paramObjData = param.paramObjData;
      let paramsCntxt = param.paramsCntxt;

      let lservdrf = new TaskDetailsService();
      lservdrf.getTaskDetail(paramObjData).then(data =>{ 

          let entityObj  = data.data.entity;

          // context share
          let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;
          if(setShare){ 
                  paramsCntxt["other"]["taskResp"]= entityObj;
                  //console.log(" paramsCntxt===>>",paramsCntxt);
                  setShare(param.paramsCntxt); 
                  
          }
                                     
                               
      

      }).catch((e)=>{                       
          console.log("server error=>",e);
      }).finally(()=>{
         
      });
    }


    loadTheContent = ()=>{
        let forSplitScrnCls2Inject = "";
        //********* for context starts *****
        let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;      
        if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){         

            if(sharedData.actionType=="SPLITSCREEN" ){            
              forSplitScrnCls2Inject = "margin0"
            }
        }
        //********* for context ends *****

        //console.log(" this.state===>",this.state);
        let practiceAreas = this.state.practiceAreas;
        let practiceAreasMoreTxt = ""; let practiceAreaName = "";
        
        if(practiceAreas && (practiceAreas.length ==1)){
          practiceAreaName = practiceAreas[0]?practiceAreas[0].practiceArea:"";
          

        }
        else if(practiceAreas && practiceAreas.length>1){
          let practiceAreasCnt = practiceAreas.length;
          practiceAreasCnt =  practiceAreasCnt-1;
          practiceAreasMoreTxt = "+"+practiceAreasCnt+" more";

          practiceAreaName = practiceAreas[0]?practiceAreas[0].practiceArea:"";
        }

        let locations = this.state.locations;
        let locationsMoreTxt = ""; let locationsName = "";
        
        if(locations && (locations.length ==1)){
          locationsName = locations[0]?locations[0].city:"";
          

        }
        else if(locations && locations.length>1){
          let locCnt = locations.length;
          locCnt =  locCnt-1;
          locationsMoreTxt = locCnt+" more";

          locationsName = locations[0]?locations[0].city:"";
        }

        //let assignedTo = this.state.assignedTo;
        //let assignedToName = assignedTo?"none":"none";

        let budgetData = "";
        let budget = this.state.budget?this.state.budget: "";
        let budgetCurrency = this.state.budgetCurrency?this.state.budgetCurrency: "";

        if(budget && budgetCurrency){
          budgetData = budgetCurrency+" "+budget;
        }

        let taskFiles = this.state.taskFiles;
        let taskFilesCnt = 0;

        if(taskFiles){
          taskFilesCnt = taskFiles?taskFiles.length:0;
        }
        

        return (
          <>          
          { /* Dashboard Slide Split Right start  */   }
          <div className={`splitscreen_area 
                ${forSplitScrnCls2Inject?forSplitScrnCls2Inject:""}`
                  }>
                  <div className="slideScreen_bg">
                    <SplitScreenLeftMenu />
                    <div className="slideScreen_rightbg flexbox-parent">
                      {/* === right slide section top start ===  */}
                      <div className="SlideS_topbg flexbox-item">
                        <div className="slide_top_titlebg">
                          <h4>Task Details</h4>
                          <button onClick={(evn)=>{
                          
                          evn.preventDefault();
                          //this.closeSplit();
                          commonnotitrack("CLOSE_SPLITSCREEN",{
                            "notiType": "CLOSE_SPLITSCREEN",
                            "triggerCallback":false,
                            "closeSplitCalledFrom":"SINGLE_TASK_WITHSUBTASK_DETAILS"
                           });
                    
                      }} className="btn_appearance_none slideTop_cross_btn cross">
                            <img src={getBaseUrl()+"assets/images/red_cross.png"}  />
                          </button>
                        </div>
                      </div>
                      {/* === right slide section top end ===  */}
                      {/* === right slide section Middlie  start ===  */}
                      <div className="SlideS_Midbg flexbox-item-grow">
                        
                        <div className="s_midbox_scroll_adjust mCustomScrollbar reactTestScroll reactTestScroll_multi  height_800">
                          <Scrollbars 
                                ref={this.scrollerRef}
                                style={{ height: 775 }}                  
                                autoHeightMin={775}
                                renderView={props => <div {...props} className="view taskdetailsbtskscrollcustomclass"/>}

                                onScrollStop={this.trackScroll}
                            >
                            <div className="s_midbox_section">
                            <div className="s_midbox_title">
                              {/* <h5>Task Number - CAT0005</h5> */}
                            </div>
                            <div className="s_midbox">
                              {/* ====  slidebox start ====  */}
                              <div className="scroll_maintask_bg">
                                <div className="enter_task_box sub_task_addbg">
                                  {/* === enter task box start ===  */}
                                  <div className="main_form_box">
                                    <div className="form_block form_block_half">
                                      <div className="panel50 panel_half paddingright15 form__group_normar">
                                        <label htmlFor="name" className="form__label">
                                          <b>Task Name</b>
                                        </label>
                                        <div className="form__group field">
                                          <div className="input_datashow">
                                            <p>{this.state.taskName}</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="clearboth" />
                                    </div>
                                    <div className="form_block form_block_half">
                                      <div className="panel50 panel_half paddingright15 form__group_normar">
                                        <label htmlFor="name" className="form__label">
                                          <b>Practice area</b>
                                        </label>
                                        <div className="form__group field">
                                          <div className="form__group field">
                                            <div className="input_datashow">
                                              { practiceAreaName && (
                                              <span className="dataShow_green">
                                                    {practiceAreaName}
                                              </span>
                                              )}
                                              <span className="dataShow_more">{practiceAreasMoreTxt}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="clearboth" />
                                    </div>
                                    
                                    {/* <div className="form_block form_block_half">
                                      <div className="panel50 panel_half paddingright15 form__group_normar">
                                        <label htmlFor="name" className="form__label">
                                          <b>Category of task</b>
                                        </label>
                                        <div className="form__group field">
                                          <div className="input_datashow">
                                            <p>{this.state.taskCategory && this.state.taskCategory.category?this.state.taskCategory.category: "" }
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="panel50 panel_half paddingleft15 form__group_normar">
                                        <label htmlFor="name" className="form__label">
                                          <b>Type of task</b>
                                        </label>
                                        <div className="form__group field">
                                          <div className="form__group field">
                                            <div className="input_datashow">
                                              <p>{this.state.categoryType && this.state.categoryType.categoryType?this.state.categoryType.categoryType: "" }
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="clearboth" />
                                    </div>
                                    */}
                                    <div className="form_block form_block_half">
                                      <div className=" panel_half form__group_normar">
                                        <label htmlFor="name" className="form__label">
                                          <b>Task description</b>
                                        </label>
                                        <div className="form__group field">
                                          <div className="input_datashow">
                                            <p>{this.state.description}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="clearboth" />
                                    </div>
                                    
                                    <div className="form_block form_block_half">
                                      <div className="panel50 panel_half paddingright15 form__group_normar">
                                        <label htmlFor="name" className="form__label">
                                          <b>Due date</b>
                                        </label>
                                        <div className="form__group field">
                                          <div className="input_datashow">
                                            <p>{this.state.dueDateMillis ?CustDate.custFormatDate((new Date(this.state.dueDateMillis)),3): "None"}</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="clearboth" />
                                    </div>
                                  </div>
                                  {/* === enter task box end ===  */}
                                  {/* === enter sub task box start ===  */}
                                  <div className="scroll_subtask_bg splite_scroll_subtask_bg">

                                    {this.loadSubTasks()}
                                    
                                    {/* <div className="taskadd_btnbg">
                                      <button className="btn_appearance_none add_subtask_btn  margin_bottom10">
                                        <span>Add another Sub-task</span>
                                      </button>
                                    </div>
                                  
                                   */}
                                  
                                  </div>
                                  {/* === enter sub task box end ===  */}
                                </div>
                              </div>
                              {/* ====  slidebox end ====  */}
                            </div>
                          </div>
                          
                          </Scrollbars>
                        </div>
                        
                      </div>
                      {/* === right slide section Middlie  end ===  */}
                      {/* === right slide section Footer  start ===  */}
                      <div className="SlideS_bottombg flexbox-item ">
                        <div className="s_footer_btnbg">
                          <button onClick={(ev)=>{ev.preventDefault();

                            let subTasksCount = this.state.subTasks? this.state.subTasks.length:0;
                             //
                           let paramsCntxt = {};
                           paramsCntxt = {
                             "taskId":this.state.taskId,
                             "actionType":"SPLITSCREEN",
                             "leftTabToActive":"TASKDETAILS",
                             "purpose":"EDIT",
                             "other":{
                               "subTasksCount":subTasksCount}
                   
                           } 
 
                           let paramObjData = {"data":{"taskId":this.state.taskId}};      
                           let param = {"paramObjData":paramObjData,"paramsCntxt":paramsCntxt}
                             
                           this.getTaskDetailData(param);

                           //

                            }} className="btn_appearance_none green_btn">
                            <span>Edit</span>
                          </button>
                        </div>
                      </div>
                      {/* === right slide section Footer  end ===  */}
                    </div>
                  </div>
                </div>

          { /* Dashboard Slide Split Right end  */ }
          
          </>
        );
    }

    setStateData = (entityObj) =>{

      
      let paramData = stateStaticData;

      if(entityObj!==undefined && entityObj!==null){
        paramData.taskId = entityObj.id;
        paramData.taskName = entityObj.taskName;
        paramData.description = entityObj.description;
        paramData.practiceAreas = entityObj.practiceAreas;
        paramData.locations = entityObj.locations;
        paramData.taskCategory = entityObj.taskCategory;
        paramData.categoryType = entityObj.categoryType;
        paramData.budgetCurrency = entityObj.budgetCurrency;
        paramData.budget = entityObj.budget;
        paramData.assignedTo = entityObj.assignedTo;
        paramData.dueDateMillis = entityObj.dueDateMillis;
        paramData.taskFiles = entityObj.taskFiles;
        paramData.subTasks = entityObj.subTasks;

        
        this.isComponentLoaded && this.setState(paramData,()=>{     
        });
          
    }else{
      //this.closeSplit();
      commonnotitrack("CLOSE_SPLITSCREEN",{
        "notiType": "CLOSE_SPLITSCREEN",
        "triggerCallback":false,
        "closeSplitCalledFrom":"SINGLE_TASK_WITHSUBTASK_DETAILS"
       });
    }        

    } 

    loadSubTasks =  ()=>{
      
        let subTasksData = this.state.subTasks;
        
        let subTasksDataAr = [];
         Object.keys(subTasksData).map((e,i)=>{

          let assignedTo = subTasksData[e].assignedTo;
          let assignedToName = "None";
          if(assignedTo!==undefined && assignedTo!==null){
            let fNm = assignedTo.firstName!==undefined && assignedTo.firstName!==null?assignedTo.firstName:"";
            let lNm = assignedTo.lastName!==undefined && assignedTo.lastName!==null?assignedTo.lastName:"";
            assignedToName = fNm+" "+lNm;

          }
          subTasksDataAr.push(
          <div key={i} className="enter_task_box slidetask_subbox_bg">
          <div className="task_title_head text-right">
        <h5>Sub-Task No. {subTasksData[e].id}</h5>
            {/* <button className="btn_appearance_none subtask_cross_btn">
              <img src="assets/images/delete.png" />
            </button> */}
          </div>
          <div className="main_form_box">
            <div className="form_block">
              <div className="form__group_normar field form_group_error">
                <label htmlFor="name" className="form__label">
                  <b>Sub-task Name</b>
                </label>
                <div className="form__group field">
                  <div className="input_datashow">
                    <p>{subTasksData[e].subTaskName}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="form_block form_block_half">
              <div className="panel50 panel_half paddingright15 form__group_normar">
                <label htmlFor="name" className="form__label">
                  <b>Category of Task</b> &nbsp;
                </label>
                <div className="form__group field">
                  <div className="input_datashow">
                    <p>{subTasksData[e].category?subTasksData[e].category.category:""}</p>
                  </div>
                </div>
              </div>
              <div className="panel50 panel_half paddingleft15 form__group_normar">
                <label htmlFor="name" className="form__label">
                  <b>Type of Task</b>
                  &nbsp;
                </label>
                <div className="form__group field">
                  <div className="input_datashow">
                  <p>{subTasksData[e].categoryType?subTasksData[e].categoryType.categoryType:""}</p>
                  </div>
                </div>
              </div>
              <div className="clearboth" />
            </div>
            <div className="form_block form_block_half">
              <div className="panel50 panel_half paddingright15 form__group_normar">
                <label htmlFor="name" className="form__label">
                  <b>Budget</b>
                </label>
                <div className="form__group field">
                  <div className="input_datashow">
                  <p>{subTasksData[e].budget && subTasksData[e].budgetCurrency?(subTasksData[e].budgetCurrency+" "+subTasksData[e].budget):""}</p>
                  </div>
                </div>
              </div>
              <div className="panel50 panel_half paddingleft15 form__group_normar">
                <label htmlFor="name" className="form__label">
                  <b>Assign to</b>
                </label>
                <div className="form__group field">
                  <div className="form__group field">
                    <div className="input_datashow">
                        <p className={`${assignedToName=="None"?"":"assTo_green"}`}>{assignedToName}</p>
                    </div>
                  </div>
                </div>

                
              </div>
              <div className="clearboth" />
            </div>
            <div className="form_block form_block_half">
              <div className="panel50 panel_half paddingright15 form__group_normar">
                <label htmlFor="name" className="form__label">
                  <b>Due Date</b>
                </label>
                <div className="form__group field">
                  <div className="input_datashow">
                  <p>None</p>
                  </div>
                </div>
              </div>
             <div className="clearboth" />
            </div>
            
          
          </div>
          {/* === enter task box end ===  */}
        </div>
        
  
         )
          });

        return <>{subTasksDataAr}</>;
      
    }

    componentDidUpdate(prevProps){

        if(this.props.values.taskId!==prevProps.values.taskId){
          
              //********* for context starts *****
              let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;      
              if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){         
        
                  if(sharedData.actionType=="SPLITSCREEN" ){
                    let respData = sharedData.other.taskResp;
                    //console.log(" from render=>",respData)
                    this.setStateData(respData);
                  }
              }
              //********* for context ends *****


        }

    }

    trackNotiForCloseSplit = (otherParam) =>{
     
      let notiType =  otherParam.notiType!==undefined? otherParam.notiType: "";
      let closeSplitCalledFrom =  otherParam.closeSplitCalledFrom!==undefined? otherParam.closeSplitCalledFrom: ""; 
      if(notiType=="CLOSE_SPLITSCREEN"){
          let closeSplitHeaderCallFrm = ["SINGLE_TASK_WITHSUBTASK_DETAILS"];
          if(closeSplitHeaderCallFrm.indexOf(closeSplitCalledFrom)!=-1){
                  console.log("*******closeSplitCalledFrom=>>",closeSplitCalledFrom);
          }
  
  
      }
    }

    /**  
     * SplitScreenSingleTaskWithSubTaskDetails
     * Function name : render
     * Author        :
     * Created Date  : 7-2-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() {    

     
      return (
          <React.Fragment>
             <NotiEmitTrack callbackfuncforcommontrackofnoti={this.trackNotiForCloseSplit} />
            {this.loadTheContent()}
          
          
          </React.Fragment>
      );
    }
}
export default SplitScreenSingleTaskWithSubTaskDetails;

