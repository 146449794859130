/**
 * ApplicationSettings
 * Page/Class name   : ApplicationSettings
 * Author            :
 * Created Date      : 30-6-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : render master component
**/
import React, { Component } from 'react';



// for context
import CommonContext from '../../context/CommonContext';



//import  CustDate  from '../../../common_utilities/CustDate';
import { commonJsFuncModule as commonJsObj } from '../../../common_utilities/commonjsfunc';
import {notify} from '../../messagebar/CustomMessageBar';
import CustomMessageBar from '../../messagebar/CustomMessageBar';

import SubscriptionPackage from './SubscriptionPackage';


// const getDateObjFromMilliseconds = commonJsObj.getDateObjFromMilliseconds;
// const getTimestampInMillisecondsFromDateObj = commonJsObj.getTimestampInMillisecondsFromDateObj;
// const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;
// const sortArrayOfJsonByKey = commonJsObj.sortArrayOfJsonByKey;
// const getCcUserId = commonJsObj.getCcUserId;
// const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;




class ApplicationSettings extends Component {

    isComponentLoaded =  false;
    // for context
    static contextType = CommonContext;

    /**  
     * SplitScreenTaskDocuments
     * Function name : constructor
     * Author        :
     * Created Date  : 30-6-2020
     * Purpose       : initializing children
     * Params        : props
    **/
    constructor(props) {
      super(props);
      this.children = props.children;
      this.state = {
       
        tabOption: "" // SUBSCRIPTION_PACKAGE or APP_PREFERENCES or USER_ROLES


      };
      
     
    }

    /**
     * SplitScreenTaskDocuments
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 30-6-2020
     * Purpose       : lifecycle method
     * Params        :
    **/
    componentDidMount() {
      this.isComponentLoaded = true;
      this.checkBeforeSwitchOnFirstimeLoad();
      
    }

   


    /**  
     * SplitScreenTaskDocuments
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 30-6-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {
      this.isComponentLoaded = false;

    }

    /**  
     * SplitScreenTaskDocuments
     * Function name : componentDidUpdate
     * Author        :
     * Created Date  : 30-6-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentDidUpdate(prevProps){

    }

    checkBeforeSwitchOnFirstimeLoad = () => {
      let subHeaderTabToActive = "";
      let triggeredFrom = "";

      let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;

      if(sharedData!==undefined && sharedData!==null ){ 
        
        subHeaderTabToActive = sharedData.subHeaderTabToActive!==undefined?sharedData.subHeaderTabToActive:"";

        if(sharedData.other!==undefined && sharedData.other!==null){
          triggeredFrom = sharedData.other.triggeredFrom = "SUBSCRIPTION_PAYMENT";
        }
      }

      if(triggeredFrom=="SUBSCRIPTION_PAYMENT" && subHeaderTabToActive=="SUBSCRIPTION_PACKAGE"){       
        this.switchToTab("SUBSCRIPTION_PACKAGE");
      }     
      else if(subHeaderTabToActive=="APP_PREFERENCES"){
        this.switchToTab("APP_PREFERENCES");
      }
      else if(subHeaderTabToActive=="USER_ROLES"){
        this.switchToTab("USER_ROLES");
      }
      else{
        this.switchToTab("SUBSCRIPTION_PACKAGE");
      }
      
    }
  

    showSuccessOrErrorMessage = (param) =>{
      let notiMsg = param.notiMsg;
      let successOrError = param.successOrError; // 0 or 1
      let dismissDuration = param.dismissDuration; // generally 1500
      let showCloseButton = param.showCloseButton; // generally false

      notify(notiMsg,true,{
      "successMsg":successOrError,
      "customWrapClass":"customsuccclass",
      "showCloseButton": showCloseButton,                     
      "dismiss": {
          "duration": dismissDuration,
      }

    });

    }

    loadTabContent = () => {
      if(this.state.tabOption=="SUBSCRIPTION_PACKAGE"){
        return (<SubscriptionPackage showsuccessorerrormessage={this.showSuccessOrErrorMessage} />);
      }
    }

    switchToTab =  (tabName) => {
        this.isComponentLoaded && this.setState({"tabOption":tabName});
    }

  

    /**  
     * ApplicationSettings
     * Function name : render
     * Author        :
     * Created Date  : 30-6-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() {  

      return (
        <>
        <div className="kyc_section_part_01">
            <CustomMessageBar />
            <div className="dashboard_cont_area tasklist_cont_areabg setting_cont_areabg setting_top_tab_section">
              {/* ===  DashBoard Main Body Start ===  */}
              <div className="cutom_container cutom_container_userSetting">
              
                <div className="create_task_area userSetting_area">
                  {/* ===  task Listing filter start === */}
                  <div className="task_area_filterbg task_area_filterbg_lp">
                    <div className="filter_area ">
                      {/* ===  task top filter start ===  */}
                      <div className="top_filter_left top_filter_left_lp">
                        <div className="top_filter_backbtn_bg top_filter_backbtn_bg_lp">
                          {/* == profile setting ul start ==   */}
                          <div className="profile_setting_ulbg app_set_ulbg">
                          
                            <ul>
                                <li   className={` ${this.state.tabOption=="SUBSCRIPTION_PACKAGE"?"active":""}`}>
                                  <button className="btn_appearance_none task_ass_tabBtn" onClick={(evn)=>{ 
                                    evn.preventDefault();
                                    this.switchToTab("SUBSCRIPTION_PACKAGE");
                                  
                                  }} >
                                    <span className="task_ass_span">Subscription</span>
                                  </button>
                                </li>
                                <li className={` ${this.state.tabOption=="USER_ROLES"?"active":""}`}>
                                      <button onClick={(evn)=>{ 
                                        evn.preventDefault();
                                   
                                  
                                        }} className="btn_appearance_none task_ass_tabBtn">
                                            <span className="task_ass_span">User Roles</span>
                                            <span className="comming_soon">Coming soon</span>
                                        </button>
                                </li>
                                <li className={` ${this.state.tabOption=="APP_PREFERENCES"?"active":""}`}>
                                      <button onClick={(evn)=>{ 
                                        evn.preventDefault();
                                   
                                  
                                        }} className="btn_appearance_none task_ass_tabBtn">
                                            <span className="task_ass_span">Application Preferences</span>
                                            <span className="comming_soon">Coming soon</span>
                                        </button>
                                </li>
                              </ul>
                          </div>
                          {/* == profile setting ul end ==   */}
                          <div className="clearboth" />
                        </div>
                      </div>
                      {/* ===  task top filter end === */}
                      <div className="clearboth" />
                    </div>
                  </div>
                  {/* ===  task Listing filter end === */}
                  {/* ===========  Setting Page main body start =============   */}
                  {/* ===  task Listing start start === */}
                  {/* ===  task Listing start end === */}
                  {/* ===========  Setting Page main body end =============   */}
                </div>
              </div>
              {/* ===  DashBoard Main Body End ===  */}
            </div>
        </div>
        <div className={"kyc_section_part_02"} >
          {/* ===========  Setting Page main body start =============   */}
          {this.loadTabContent()}
          {/* ===========  Setting Page main body end =============   */}
        </div>
        </>
      );
    }
}
export default ApplicationSettings;