/**
 * LogoutCommon
 * Page/Class name   : LogoutCommon
 * Author            :
 * Created Date      : 26-6-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : rendering loader structure
**/
import React, { Component, Suspense, lazy} from 'react';
import { withRouter} from 'react-router-dom';
import '../../../custom_resources/css/custom.css';



// Services
import LogoutService from '../../service/LogoutService';
// for context
import CommonContext from '../../context/CommonContext';

import { commonJsFuncModule as commonJsObj } from '../../../common_utilities/commonjsfunc';

const removeTokenFromLocStorage = commonJsObj.removeTokenFromLocStorage;

class LogoutCommon extends Component {
    /**
     * LogoutCommon
     * Function name : constructor
     * Author        :
     * Created Date  : 
     * Purpose       : initializing state
     * Params        : props
    **/

   isComponentLoaded = false;

   // for context
   static contextType = CommonContext; 

    constructor(props) {
        super(props);
        this.state = {}
    }

    /**
     * LogoutCommon
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentDidMount() {
        this.isComponentLoaded = true;

        
        
    }

    /**
     * LogoutCommon
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {  
        this.isComponentLoaded = false;
    }

    callLogoutService = (paramObj)=>{

        let lservdrf = new LogoutService();
        let paramObjData = {};
        lservdrf.doLogout(paramObjData).then(data =>{ 
          // remove token from browser
          //removeTokenFromLocStorage();  
          // got login page
          //this.props.history.push("/signin");
          
        
  
        }).catch((e)=>{                       
            console.log("server error=>",e);
        }).finally(()=>{
          removeTokenFromLocStorage();  
          this.props.history.push("/signin");
        });
      }
  
    /**
           * LogoutCommon
           * Function name : callLogOut
           * Author        :
           * Created Date  : 26-6-2020
           * Purpose       : simple log out functionality
           * Params        : paramObj
    **/
  
    callLogOut = (paramObj) => {
                
          console.log(" Hey call logout...");
          this.callLogoutService({});
      }

    
   

    /**
     * LogoutCommon
     * Function name : render
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() {    
       
       return (
             <>                
                <a href="" key={this.props.custkeyid?this.props.custkeyid:"menulogoutlink001"}  onClick={(evn)=>{ 
                                  evn.preventDefault();
                                  this.callLogOut({});
                                  }}
                                   className={`${this.props.custclassname?this.props.custclassname:""}`}>
                      {this.props.textname?this.props.textname:"Logout"}
                </a>
     
             </>
            
        );
    }

  }
  export default withRouter(LogoutCommon);