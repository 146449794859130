/**  
 * ConflictCheckService
 * Page/Class name   : OrgDetailSaveService
 * Author            :
 * Created Date      : 20-4-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, getTweetRecords
 * Purpose           : api service page
**/
import {Component } from 'react';
// import {getEndPoint,getTokenFromLocStorage, callApi} from  '../../common_utilities/commonjsfunc';
// newly added axios
//import axios from 'axios';
import {axiosCustomObj} from '../customisedthirdparty/AxiosCustom';


import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
const callApi = commonJsObj.callApi;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;



class ConflictCheckService extends Component {

    
    /**  
     * ConflictCheckService
     * Function name : constructor
     * Author        :
     * Created Date  : 17-2-2020
     * Purpose       : initializing state
     * Params        : 
    **/
    constructor() {
      super();
      this.state = {
          
      }

      //this.axiosObj = axios;
      this.axiosObj = null; 
      
      
      this.apiEndPoint = commonJsObj.getEndPoint()+'rest/tasks/conflict_check';
    }

    /**  
     * ConflictCheckService
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 17-2-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentDidMount() {
    }

    /**  
     * ConflictCheckService
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 17-2-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentWillUnmount() {  
    }



    /**  
     * ConflictCheckService
     * Function name : initiateConflictCheck
     * Author        :
     * Created Date  : 26-2-2020
     * Purpose       : Login check to provide access token 
     * Params        : paramObj
    **/
    async initiateConflictCheck(paramObj){
      this.axiosObj = axiosCustomObj({});
      let accToken = getTokenFromLocStorage();     
      try{
            let urldata =this.apiEndPoint; 
            
            let taskId =  paramObj.data.taskId;
            let userId =  paramObj.data.userId;

            let organisationNames = paramObj.data.organisationNames;
            let paramToPost = {
              "organisationNames" : organisationNames
            };

            urldata = urldata+"/task/"+taskId+"/against/"+userId;

            let headerOptn = {  
              "Content-Type": "application/json",
              "Authorization": accToken
            };          

            let respObj = this.axiosObj.post(urldata,paramToPost, {
            headers: headerOptn
            });

            return respObj;
    }
    catch(err){
        console.log("Some error occured===>",err); // TypeError: failed to fetch
    }
      

    }

    
}
export default ConflictCheckService;