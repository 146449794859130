/**  
 * VirtualAccountBalanceService
 * Page/Class name   : VirtualAccountBalanceService
 * Author            :
 * Created Date      : 8-6-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, getTweetRecords
 * Purpose           : api service page
**/
import {Component } from 'react';
// import {getEndPoint,getTokenFromLocStorage, callApi} from  '../../common_utilities/commonjsfunc';
// newly added axios
//import axios from 'axios';
import {axiosCustomObj} from '../customisedthirdparty/AxiosCustom';

import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
const callApi = commonJsObj.callApi;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;
const getMaxPageSize = commonJsObj.getMaxPageSize;


class VirtualAccountBalanceService extends Component {

    
    /**  
     * VirtualAccountBalanceService
     * Function name : constructor
     * Author        :
     * Created Date  : 8-6-2020
     * Purpose       : initializing state
     * Params        : 
    **/
    constructor() {
      super();
      this.state = {
          
      }

      //this.axiosObj = axios;
      this.axiosObj = null;
      
      this.apiEndPoint = commonJsObj.getEndPoint()+'rest/organisations/virtual-account/details';
    }

    /**  
     * VirtualAccountBalanceService
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 24-3-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentDidMount() {
    }

    /**  
     * VirtualAccountBalanceService
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 4-3-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentWillUnmount() {  
    }



    /**  
     * VirtualAccountBalanceService
     * Function name : getLoginResp
     * Author        :
     * Created Date  : 24-3-2020
     * Purpose       : Login check to provide access token 
     * Params        : paramObj
    **/
    async getVirtualBankBalanceInfo(paramObj){
      this.axiosObj = axiosCustomObj({});   
      let accToken = getTokenFromLocStorage();   
      let offsetOfLocalDateTime = getOffsetOfLocalDateTime(new Date());

      try{
            let urldata =this.apiEndPoint; 

            let data =  paramObj.data;
            // const defaultPageSize = getMaxPageSize();
            // let pageNum = data.pageNum?data.pageNum:1;
            // let pageSize = defaultPageSize;

             let offsetHr = offsetOfLocalDateTime.hr;
             let offsetMin = offsetOfLocalDateTime.min;   
                   
             urldata = urldata+"/?offset_hour="+offsetHr+"&offset_minute="+offsetMin;
            //console.log("task single list api hitted with =>>",urldata);


            let headerOptn = {  
              "Content-Type": "application/json",
              "Authorization": accToken
            };          

            let respObj = this.axiosObj.get(urldata,{
            headers: headerOptn
            });

            return respObj;
    }
    catch(err){
        console.log("Some error occured===>",err); // TypeError: failed to fetch
    }
      

    }

    
}
export default VirtualAccountBalanceService;