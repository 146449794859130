/**
 * SplitScreenLeftMenu
 * Page/Class name   : SplitScreenLeftMenu
 * Author            :
 * Created Date      : 10-2-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : rendering dashboard
 **/
import React, { Component, Suspense, lazy } from "react";
import customimages from "../../custom_resources/images";
import "../../custom_resources/css/custom.css";

// for context
import CommonContext from "../context/CommonContext";

//Services
import TaskDetailsService from "../../components/service/TaskDetailsService";

import { commonJsFuncModule as commonJsObj } from "../../common_utilities/commonjsfunc";
const getBaseUrl = commonJsObj.getBaseUrl;

class SplitScreenLeftMenu extends Component {
  isComponentLoaded = false;

  // for context
  static contextType = CommonContext;

  /**
   * Dashboard
   * Function name : constructor
   * Author        :
   * Created Date  : 10-2-2020
   * Purpose       : initializing state
   * Params        : props
   **/
  constructor(props) {
    super(props);
    this.state = {};

    // For Organisation detail modal
    //this.orgdetmodref = React.createRef();
    // For User add to Organisation modal
    //this.usraddorgmodref = React.createRef();
    //this.orgcitygaref = React.createRef();
  }

  /**
   * Dashboard
   * Function name : componentDidMount
   * Author        :
   * Created Date  : 10-2-2020
   * Purpose       : lifecycle method
   * Params        :
   **/

  componentDidMount() {
    this.isComponentLoaded = true;
  }

  /**
   * Dashboard
   * Function name : componentWillUnmount
   * Author        :
   * Created Date  : 10-2-2020
   * Purpose       : lifecycle method
   * Params        :
   **/
  componentWillUnmount() {
    this.isComponentLoaded = false;
  }

  closeSplit = () => {
    //********* for context starts *****
    let sharedData =
      this.context !== undefined && this.context.share !== undefined
        ? this.context.share
        : null;
    if (
      sharedData !== undefined &&
      sharedData !== null &&
      sharedData.actionType !== undefined
    ) {
      if (sharedData.actionType == "SPLITSCREEN") {
        let setShare =
          this.context !== undefined && this.context.setShare !== undefined
            ? this.context.setShare
            : null;

        if (setShare) {
          let paramsCntxt = {
            taskId: sharedData.taskId,
            actionType: "",
            leftTabToActive: "",
            purpose: "",
            other: {},
          };
          setShare(paramsCntxt);
        }
      }
    }
    //********* for context ends *****
  };

  async getTaskDetailData(param) {
    let respData = null;
    let lservdrf = new TaskDetailsService();
    await lservdrf
      .getTaskDetail(param)
      .then((data) => {
        respData = data.data.entity;
      })
      .catch((err) => {
        let errStatusCodeAr = [400, 500]; // allowed error status code
        if (err.response && err.response.data) {
          let erRespData = err.response.data;
          if (errStatusCodeAr.indexOf(erRespData.status) !== -1) {
            let errMsg = erRespData.message
              ? erRespData.message
              : "Some technical problem occurred.";
            console.log(" errMsg on task fetch info==>", errMsg);
            // close split screen
            //this.closeSplit();
          }
        }
      })
      .finally(() => {});

    return respData;
  }

  goToSplitScreenTab = async (keyname, otherParam) => {
    // context share
    let setShare =
      this.context !== undefined && this.context.setShare !== undefined
        ? this.context.setShare
        : null;

    let sharedData =
      this.context !== undefined && this.context.share !== undefined
        ? this.context.share
        : null;

    switch (keyname) {
      case "TASKDETAILS":
        if (setShare && sharedData) {
          //setShare(sharedData);

          sharedData.leftTabToActive = "TASKDETAILS";
          sharedData.purpose = "VIEW";

          let paramObj1 = {
            data: {
              taskId: sharedData.taskId,
            },
          };

          sharedData.other.taskResp = await this.getTaskDetailData(paramObj1);

          setShare(sharedData);
        }

        break;
      case "PROFESSIONALSINTERESTED":
        if (setShare && sharedData) {
          sharedData.leftTabToActive = "PROFESSIONALSINTERESTED";
          sharedData.purpose = "VIEW_RESPONSE_LIST";
          let paramObj1 = {
            data: {
              taskId: sharedData.taskId,
            },
          };
          sharedData.other.taskResp = await this.getTaskDetailData(paramObj1);
          setShare(sharedData);

          /*
                let paramsCntxt = {
                  "taskId": "",
                  "actionType":"SPLITSCREEN",
                  "leftTabToActive":"",
                  "purpose":"VIEW_RESPONSE_LIST",
                  "other":{}
        
                };
                */
        }
        break;

      case "MONITORTASK":
        console.log("Split MONITORTASK sharedData====>>", sharedData);
        if (setShare && sharedData) {
          let taskOrSubTask =
            sharedData.other !== undefined &&
            sharedData.other &&
            sharedData.other.subTasksCount !== undefined &&
            sharedData.other.subTasksCount &&
            sharedData.other.subTasksCount > 0
              ? "SWSTASK"
              : "STASK";

          let taskResp = null;
          // if it is single task and assigned data is present then ok
          if (
            taskOrSubTask == "STASK" &&
            sharedData.other !== undefined &&
            sharedData.other.taskResp !== undefined &&
            sharedData.other.taskResp !== null
          ) {
            taskResp = sharedData.other.taskResp;
            if (
              taskResp.assignedTo !== undefined &&
              taskResp.assignedTo !== null &&
              taskResp.assignedTo.userId !== undefined &&
              taskResp.assignedTo.userId !== null
            ) {
              sharedData.leftTabToActive = "MONITORTASK";
              sharedData.purpose = "VIEW_RESPONSE_LIST";
              sharedData.other.triggeredFrom = "TASK_SPLIT_SCREEN_LEFT_MENU";
              //setShare(sharedData);

              let paramObj1 = {
                data: {
                  taskId: sharedData.taskId,
                },
              };
              sharedData.other.taskResp = await this.getTaskDetailData(
                paramObj1
              );
              setShare(sharedData);
            }
          }
        }
        break;

      case "DOCUMENTS":
        if (setShare && sharedData) {
          sharedData.leftTabToActive = "DOCUMENTS";
          sharedData.purpose = "VIEW_DOC_LIST";

          let paramObj1 = {
            data: {
              taskId: sharedData.taskId,
            },
          };
          sharedData.other.taskResp = await this.getTaskDetailData(paramObj1);
          setShare(sharedData);
        }
        break;
      case "TIMELOGS":
        if (setShare && sharedData) {
          let taskOrSubTask =
            sharedData.other.subTasksCount !== undefined &&
            sharedData.other.subTasksCount !== null &&
            sharedData.other.subTasksCount > 0
              ? "SWSTASK"
              : "STASK";

          let taskResp = null;
          // if it is single task and assigned data is present then ok
          if (
            taskOrSubTask == "STASK" &&
            sharedData.other.taskResp !== undefined &&
            sharedData.other.taskResp !== null
          ) {
            taskResp = sharedData.other.taskResp;
            if (
              taskResp.assignedTo !== undefined &&
              taskResp.assignedTo !== null &&
              taskResp.assignedTo.userId !== undefined &&
              taskResp.assignedTo.userId !== null
            ) {
              sharedData.leftTabToActive = "TIMELOGS";
              sharedData.purpose = "VIEW_TIMELOG_LIST";
              sharedData.other.triggeredFrom = "TASK_SPLIT_SCREEN_LEFT_MENU";
              //setShare(sharedData);

              let paramObj1 = {
                data: {
                  taskId: sharedData.taskId,
                },
              };
              sharedData.other.taskResp = await this.getTaskDetailData(
                paramObj1
              );
              setShare(sharedData);
            }
          }
        }
        break;
      case "INVOICE_MASTER":
        if (setShare && sharedData) {
          let taskOrSubTask =
            sharedData.other.subTasksCount !== undefined &&
            sharedData.other.subTasksCount !== null &&
            sharedData.other.subTasksCount > 0
              ? "SWSTASK"
              : "STASK";

          let taskResp = null;
          // if it is single task and assigned data is present then ok
          if (
            taskOrSubTask == "STASK" &&
            sharedData.other.taskResp !== undefined &&
            sharedData.other.taskResp !== null
          ) {
            taskResp = sharedData.other.taskResp;
            if (
              taskResp.assignedTo !== undefined &&
              taskResp.assignedTo !== null &&
              taskResp.assignedTo.userId !== undefined &&
              taskResp.assignedTo.userId !== null
            ) {
              sharedData.leftTabToActive = "INVOICE_MASTER";
              sharedData.purpose = "DETERMINE_WHICH_VIEW";
              sharedData.other.triggeredFrom = "TASK_SPLIT_SCREEN_LEFT_MENU";
              //setShare(sharedData);

              let paramObj1 = {
                data: {
                  taskId: sharedData.taskId,
                },
              };
              sharedData.other.taskResp = await this.getTaskDetailData(
                paramObj1
              );
              setShare(sharedData);
            }
          }
        }
        break;
    }
  };

  /**
   * Dashboard
   * Function name : render
   * Author        :
   * Created Date  : 11-2-2020
   * Purpose       : lifecycle method
   * Params        :
   **/
  render() {
    let sharedData =
      this.context !== undefined && this.context.share !== undefined
        ? this.context.share
        : null;

    let leftTabToActive =
      sharedData && sharedData.leftTabToActive
        ? sharedData.leftTabToActive
        : "";

    let taskResp =
      sharedData &&
      sharedData.other !== undefined &&
      sharedData.other !== null &&
      sharedData.other.taskResp !== undefined
        ? sharedData.other.taskResp
        : null;
    let assignedTo =
      taskResp && taskResp.assignedTo !== undefined
        ? taskResp.assignedTo
        : null;

    return (
      <React.Fragment>
        <div className="slideScreen_leftbg">
          <div className="SlideS_leftul">
            <ul>
              <li>
                <a
                  href=""
                  onClick={(ev) => {
                    ev.preventDefault();
                    this.goToSplitScreenTab("TASKDETAILS", {});
                  }}
                  className={`${
                    leftTabToActive == "TASKDETAILS" ? "active" : ""
                  }`}
                >
                  <div className="sidebar_img">
                    <img
                      src={getBaseUrl() + "assets/images/task_details.png"}
                    />
                  </div>
                  <div className="hover_tooltip">
                    <h4>Task Details</h4>
                    <p>Task Details</p>
                  </div>
                </a>
              </li>
              <li>
                <a
                  href=""
                  onClick={(ev) => {
                    ev.preventDefault();
                    this.goToSplitScreenTab("PROFESSIONALSINTERESTED", {});
                  }}
                  className={`${
                    leftTabToActive == "PROFESSIONALSINTERESTED" ||
                    leftTabToActive == "PROFESSIONALTASKNEGOTIATIONCHAT"
                      ? "active"
                      : ""
                  }`}
                >
                  <div className="sidebar_img">
                    <img
                      src={getBaseUrl() + "assets/images/negotiations.png"}
                    />
                  </div>
                  <div className="hover_tooltip">
                    <h4>Professionals Interested</h4>
                    <p>
                      View all the responses / negotiations for a task
                      pre-assignment in here
                    </p>
                  </div>
                </a>
              </li>
              <li>
                <a
                  href=""
                  onClick={(ev) => {
                    ev.preventDefault();
                    this.goToSplitScreenTab("MONITORTASK", {});
                  }}
                  className={`${
                    leftTabToActive == "MONITORTASK"
                      ? "top_sug_left active"
                      : "top_sug_left"
                  } ${assignedTo === null ? "pointer_none" : ""}`}
                >
                  <div className="sidebar_img">
                    <img src={getBaseUrl() + "assets/images/chat.png"} />
                  </div>
                  <div className="hover_tooltip">
                    <h4>Task Mailbox</h4>
                    <p>
                      You can chat here with the lawyer to get a quick update on
                      the task
                    </p>
                  </div>
                </a>
              </li>

              <li>
                <a
                  href=""
                  onClick={(ev) => {
                    ev.preventDefault();
                    this.goToSplitScreenTab("DOCUMENTS", {});
                  }}
                  className={`${
                    leftTabToActive == "DOCUMENTS"
                      ? "top_sug_left active"
                      : "top_sug_left"
                  }`}
                >
                  <div className="sidebar_img">
                    <img src={getBaseUrl() + "assets/images/documents.png"} />
                  </div>
                  <div className="hover_tooltip">
                    {/* <h4>Document Manager</h4> */}
                    <h4>Task Documents</h4>
                    <p>
                      Where task-related documents can be viewed, uploaded and
                      downloaded.
                    </p>
                  </div>
                </a>
              </li>

              <li>
                <a
                  href=""
                  onClick={(ev) => {
                    ev.preventDefault();
                    this.goToSplitScreenTab("TIMELOGS", {});
                  }}
                  className={`${
                    leftTabToActive == "TIMELOGS"
                      ? "top_sug_left active"
                      : "top_sug_left"
                  } ${assignedTo === null ? "pointer_none" : ""}`}
                >
                  <div className="sidebar_img">
                    <img src={getBaseUrl() + "assets/images/timelog.png"} />
                  </div>
                  <div className="hover_tooltip">
                    <h4>Activity Log</h4>
                    <p>Activity Log</p>
                  </div>
                </a>
              </li>
              {/* <li>
                      <a href="" onClick={(ev)=>{ev.preventDefault();}}>
                        <div className="sidebar_img">
                          <img src={getBaseUrl()+"assets/images/assigned_history.png"}  />
                        </div>
                        <div className="hover_tooltip">
                          <h4>simply dummy text</h4>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry. Lorem Ipsum has been the industry's standard dummy text
                            ever since the 1500s,
                          </p>
                        </div>
                      </a>
                    </li>
                     */}
              <li>
                <a
                  href=""
                  onClick={(ev) => {
                    ev.preventDefault();
                    this.goToSplitScreenTab("INVOICE_MASTER", {});
                  }}
                  className={`${
                    leftTabToActive == "INVOICE"
                      ? "top_sug_left active"
                      : "top_sug_left"
                  } ${assignedTo === null ? "pointer_none" : ""}`}
                >
                  <div className="sidebar_img">
                    <img src={getBaseUrl() + "assets/images/invoice.png"} />
                  </div>
                  <div className="hover_tooltip">
                    <h4>Invoice</h4>
                    <p>Invoice</p>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default SplitScreenLeftMenu;
