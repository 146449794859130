/**
 * TaskListOfMultiBundleTask
 * Page/Class name   : TaskListOfMultiBundleTask
 * Author            :
 * Created Date      : 1-11-2019
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : rendering loader structure
**/
import React, { Component, Suspense, lazy} from 'react';
import {Link, NavLink} from 'react-router-dom';
import '../../../custom_resources/css/custom.css';

import TaskStatusCodeComponent from './TaskStatusCodeComponent';

// scroll 
// import { Scrollbars } from 'react-custom-scrollbars';
import TaskListForSingleTaskService from '../../service/TaskListForSingleTaskService';


import { commonJsFuncModule as commonJsObj } from '../../../common_utilities/commonjsfunc';

import  CustDate  from '../../../common_utilities/CustDate';

// For loading CustLoader
import CustLoader from './CustLoader';

import FeedbackAssistant from './FeedbackAssistant';


// new scroll
import InfiniteScroll from 'react-infinite-scroll-component';


// for context
import CommonContext from '../../context/CommonContext';

const getBaseUrl = commonJsObj.getBaseUrl;

class TaskListOfMultiBundleTask extends Component {

    isComponentLoaded = false;

    // for context
    static contextType = CommonContext;

    /**
     * TaskListOfSingleTask
     * Function name : constructor
     * Author        :
     * Created Date  : 24-03-2020
     * Purpose       : initializing state
     * Params        : props
    **/
    constructor(props) {
        super(props);

        
        this.state = { 
          "listData": [],
          "pageNum": 1,
          "totalNumberOfPages": 1,
          "loaderFlag": true,
          "pageNumArTrack": [],
        };

        this.scrollerRef = React.createRef();
           
    }   

 

    /**
     * TaskListOfSingleTask
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 24-03-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentDidMount() {
        this.isComponentLoaded =  true;        
        // const {values} = this.props;        
        // const defaultFilterOptn =  values.defaultFilterOptn;
        // const defaultSortOptn =  values.defaultSortOptn;
                  //
          // let filtObj = null; //default will get set then
          // setTimeout(()=>{          
            
  
          //   //--- special case for draft starts-----
          //     let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;
              
          //     if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){         
    
          //       if(sharedData.actionType=="MULTITASK" ){

          //         console.log("=== 2 Yes now set  multitask  filtration page====");
          //          //default will get set then
          //          filtObj = {"key":"MULTI_TASKS","value":""}; 
          //          this.props.unsetContextData({});
          //       }
          //     }
          //   //--- special case for draft ends-----
            
          //   this.props.setTaskListRelDataOfSingleTask("setChangedFilterOption",filtObj,{});
           
          // },500);

          //

         

           // set list data to blank state
        this.showLoader();
        setTimeout(()=>{
          const {values}= this.props;   
          console.log(" *********** didmount of bundle task values===>",values);
          // call list api         
          let paramObj = {"pageNum":1,"pageNumArTrack": [], "totalNumberOfPages":1};
          this.isComponentLoaded && this.setState(paramObj,()=>{
            this.callServiceToFetchList(paramObj,"*****called from componentDidMount**** ");

          });
          
          },2100);
    }

    

    /**
     * TaskListOfSingleTask
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 24-03-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {  
        this.isComponentLoaded =  false;
    }    

    /**
     * TaskListOfSingleTask
     * Function name : callServiceToFetchList
     * Author        :
     * Created Date  : 27-03-2020
     * Purpose       : callServiceToFetchList
     * Params        : 
    **/

   callServiceToFetchList = (paramObj,trackcalledfrom)=>{
      
    //console.log(trackcalledfrom);

    const {values}= this.props;     
    const defaultFilterOptn =  values.defaultFilterOptn;
    const defaultSortOptn =  values.defaultSortOptn; 
    
    console.log("SERVICE called *****trackcalledfrom====>",trackcalledfrom," defaultFilterOptn===>",defaultFilterOptn," defaultSortOptn===>",defaultSortOptn," values=>",values);

    let paramObjData = {};
    paramObjData.pageNum = paramObj.pageNum;
    paramObjData.filterOption=defaultFilterOptn;
    paramObjData.sortOption=defaultSortOptn;

    let pageNumArTrack = [...this.state.pageNumArTrack];

    // restrict repeated pagenum related api call
    if(pageNumArTrack.indexOf(paramObjData.pageNum)>-1){
      //console.log("******Sorry cant hit api with page number => "+paramObjData.pageNum+" **********");
        return false;
    }else{
      pageNumArTrack.push(paramObjData.pageNum);
      this.isComponentLoaded && this.setState({"pageNumArTrack":pageNumArTrack});
    }
    
    
    let stcservice = new TaskListForSingleTaskService();
    stcservice.getSingleTaskList(paramObjData).then(data =>{
          if(data.status==200){
              let respData ={};
              if(data.data.entity!==undefined){
                
                 respData  = data.data.entity;
                 console.log("from response=>>>",respData);

                 if(respData.multipleTasksCreatedByFile!==undefined){
                      if(respData.multipleTasksCreatedByFile.length > 0){
                           
                         // set list data to state
                         let prevRecords = [...this.state.listData];
                         let currRecords = respData.multipleTasksCreatedByFile;
                         let concatenateRecords = prevRecords.concat(currRecords);
                         this.isComponentLoaded && this.setState({"listData":concatenateRecords,"totalNumberOfPages":respData.totalNumberOfPages},()=>{ 

                         });
                      }else{
                          console.log("========== empty data=========");

                          if(this.state.listData.length==0){
                            this.isComponentLoaded && this.setState({"listData":[],"totalNumberOfPages":respData.totalNumberOfPages,"pageNum":1});
                          }
                      }


                      }else{                          
                    }
          }
      }
      }).catch((err)=>{
              
              let errStatusCodeAr = [400,500]; // allowed error status code
              if(err.response && err.response.data){
                  let erRespData = err.response.data;
                  if(errStatusCodeAr.indexOf(erRespData.status) !== -1){         
                      let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                      console.log(errMsg);
                                       
              }
              } 
      }).finally(()=>{
          this.hideLoader();           
          
      });
  }

 

  /**
   * TaskListOfSingleTask
   * Function name : loadLoader
   * Author        :
   * Created Date  : 27-3-2020
   * Purpose       : Load loader
   * Params        : 
   **/
  
  loadLoader() {
    return (
        <CustLoader className={'smallLoaderGreen'} loaderText={'Loading..'} />
    );
  }

  /**
   * TaskListOfSingleTask
   * Function name : hideLoader
   * Author        :
   * Created Date  : 27-3-2020
   * Purpose       : Hide loader
   * Params        : 
   **/

  hideLoader() {
    this.isComponentLoaded && this.setState({ loaderFlag: false });
  }

  /**
   * TaskListOfSingleTask
   * Function name : showLoader
   * Author        :
   * Created Date  : 27-3-2020
   * Purpose       : showLoader
   * Params        : 
   **/

  showLoader() {
    this.isComponentLoaded && this.setState({ loaderFlag: true });
  }

  // trackScroll = ()=>{

  //       let scobj = this.scrollerRef.current;
  //       let scrollTop = scobj.getValues().scrollTop;
  //       let scrollHeight = scobj.getScrollHeight();
  //       let clientHeight = scobj.getClientHeight();
    
  //       let prevPageNum = this.state.pageNum;
  //       let totalNumberOfPages = this.state.totalNumberOfPages;
        
        
  //       if((scrollHeight-clientHeight)==scrollTop && prevPageNum < totalNumberOfPages){
          
  //         this.showLoader();

  //         setTimeout(()=>{
  //           // call list api                         
  //           let pageNumData = prevPageNum + 1;
  //           let paramObj = {"pageNum":pageNumData};

  //           this.isComponentLoaded && this.setState({ pageNum: pageNumData },()=>{
  //             this.callServiceToFetchList(paramObj,"***called from scroll bar***");
  //           });
            
  //           },900);
        
  
  //       }

  // }

  fetchMoreData = () => {


    let prevPageNum = this.state.pageNum;
    let totalNumberOfPages = this.state.totalNumberOfPages;

    if(prevPageNum < totalNumberOfPages){
          
      this.showLoader();

      setTimeout(()=>{
        // call list api                         
        let pageNumData = prevPageNum + 1;
        let paramObj = {"pageNum":pageNumData};

        this.isComponentLoaded && this.setState({ pageNum: pageNumData },()=>{
          this.callServiceToFetchList(paramObj,"***called from scroll bar***");
        });
        
        },900);
    

    }

  }

  getListStructure(){

    const {values}= this.props;       
    const defaultFilterOptn =  values.defaultFilterOptn;
    return (
      
        this.state.listData.length>0 && (   Object.keys(this.state.listData).map((e, i) => 
        <li key={i} className="tasklist_li multitask_list_li">
        <div className="task_listing_block task_listing_block_multi">
          <div className="task_popup_bg">
            <div className="task_box">
              {/* === tasktop right ===  */}
              <div className="assign_img task_status_top">
                <div className="dropdown tasktop_statusbtn">
                  <button
                    className="btn_appearance_none taskStatus_btn"
                    type="button"
                    data-toggle="dropdown"
                  >
                    <i className="fa fa-ellipsis-h" aria-hidden="true" />
                  </button>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <div className="taskstat_dropmenu">
                      <ul>
                        {/* <li>
                          <a href="#">Edit</a>
                        </li> */}
                        <li>
                          <a href="" onClick={(evnt)=>{evnt.preventDefault();}}>Delete</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="taskstatus_color">
                  {/* <span className="taskstat_clr_span taskstat_clr_gray" /> */}
                </div>
                <div className="taskstatus_msg">
                  {/* <p>Meassages (1000)</p> */}
                </div>
                <div className="taskstatus_color taskstatus_col_new">
                  {/* <span className="taskstat_clr_span_new">New</span> */}
                </div>
                <div className="taskstatus_flag">
                  {/* <a href="    ">
                    <img src="assets/images/invoice_due_flag.png" alt />
                  </a> */}
                </div>
                <div className="clearboth" />
              </div>
              {/* === tasktop right ===  */}
              <div className="panel60 task_des">
                <div className="task_left_desc">
                  <h1>Multiple Tasks ({this.state.listData[e].totalTaskCount?((this.state.listData[e].totalTaskCount)<=1? this.state.listData[e].totalTaskCount+ " Task":this.state.listData[e].totalTaskCount+" Tasks"):"0 Task"})</h1>
                  <h2>Multiple Tasks {this.state.listData[e].fileDetails.id}</h2>
                </div>
                <div className="task_assigned">
                  <h3>Created Date - {this.state.listData[e].createdDateMillis ?CustDate.custFormatDate((new Date(this.state.listData[e].createdDateMillis)),3): "None"}</h3>
                  
                 
                </div>
              </div>
              <div className="panel40 task_date">
                <div className="taskfun_btnbg">
                  <button onClick={
                    (ev)=>{
                      ev.preventDefault();
                      
                      this.props.setTaskListRelDataOfSingleTask("setMultiTaskId",(this.state.listData[e].fileDetails.id),{});

                      setTimeout(()=>{
                        this.props.gotoToStep({step:3});
                      },1000);
                      
                    }
                  }  className="btn_appearance_none task_func_btn task_popup">
                    Open
                  </button>
                </div>
              </div>
              <div className="clearboth" />
            </div>
          </div>
        </div>
      </li>

       
          )
        )
      
    );
  }

    

    /**
     * TaskListOfSingleTask
     * Function name : render
     * Author        :
     * Created Date  : 24-03-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() {  

        const {values}= this.props;
        //console.log(" values==>",values," defSortOptions==>>",values.defSortOptions);
        const defaultFilterOptn =  values.defaultFilterOptn;
        const defaultSortOptn =  values.defaultSortOptn;
        const defFilterOptions =  values.defFilterOptions;
        const defSortOptions =  values.defSortOptions;
        const taskSingleOrMultiFlag = values.step==1?false:true;
        //console.log("  taskSingleOrMultiFlag==>",taskSingleOrMultiFlag);
      
      return (
        <React.Fragment>  
             <div>
                {/* Dashboard Main Screen content area start */}
                <div className="dashboard_cont_area tasklist_cont_areabg">
                  {/* ===  DashBoard Main Body Start ===  */}
                  <div className="cutom_container">
                    <div className="tab-content">
                      <div  className="tab-pane fade in active">
                        <div className="taskbtn">
                          <button onClick={()=>{                                             
                                        this.props.goToCreateTaskPage();
                                    
                                    }}  className="sub_grd_btn create_tsk_btn">
                            <span>
                              <img src={getBaseUrl()+"assets/images/add_icon.png"} alt />
                            </span>{" "}
                            <span>Create a new task</span>
                          </button>
                        </div>
                        <div className="create_task_area create_task_area_heightAdjustment">
                          {/* ===  task Listing filter start === */}
                          <div className="task_area_filterbg">
                            <div className="filter_area">
                              {/* ===  task top filter start ===  */}
                              <div className="top_filter_left">
                                <div className="top_filter_backbtn_bg">
                                 
                                  <div className="multitask_switch">
                                    <div className="multitask_switch_text">
                                      Show Multiple Task
                                    </div>
                                    <div className="switch_btn">
                                      <label className="switch">
                                        <input type="checkbox" onChange={
                                          (ev)=>{
                                             
                                            this.props.gotoToStep({step:1});
                                          }
                                        } checked={taskSingleOrMultiFlag} />
                                        <span className="slider round" />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="top_filter_right">
                                
                              </div>
                              {/* ===  task top filter end === */}
                              <div className="clearboth" />
                            </div>
                          </div>
                          {/* ===  task Listing filter end === */}
                          {/* ===  task Listing start start === */}
                          <div className="task_area">
                            {/* ===  Task Listing Section Start === */}
                            <div className="task_listing_scrollbg">
                              {/* <Scrollbars 
                                  ref={this.scrollerRef}
                                  style={{ height: 550 }}                  
                                  autoHeightMin={550} 
                                  renderView={props => <div {...props} className="view tasklistscrollcustomclass"/>}

                                  onScrollStop={this.trackScroll}
                              >                               */}
                              <div className="view tasklistscrollcustomclas">
                                <div ref={this.scrollerRef}  id="scrollableDivMulTskBunchLst"
                                    style={{
                                     
                                      flexDirection: 'column',
                                    }} className="task_listing_scroll_react mScroll_auto new_autoHeightAdjust new_autoHeightAdjust_leftTaskDominous">
                                <InfiniteScroll
                                dataLength={this.state.listData.length} //This is important field to render the next data
                                next={this.fetchMoreData}
                                hasMore={true}
                                 
                                // loader={<h4>Loading...</h4>}
                                scrollableTarget="scrollableDivMulTskBunchLst"
                                inverse={false}
                                
                                >
                                    <div className="task_listing_area">
                                {
                                      this.state.loaderFlag==false && this.state.listData.length==0 && (
                                    <div className=" text-center no records found">
                                        No records found
                                    </div>
                                      )
                                    }
                                    <ul>
                                    {this.getListStructure()}
                                    </ul>
                                   <div className=" text-center ">
                                        {this.state.loaderFlag ? this.loadLoader() : null}
                                    </div>
                                </div>
                                </InfiniteScroll>
                                </div>
                              </div>
                              {/* </Scrollbars>       */}

                            </div>
                            {/* ===  Task Listing Section end === */}
                          </div>
                          {/* ===  task Listing start end === */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ===  DashBoard Main Body End ===  */}
                </div>
                {/* Dashboard Main Screen content area end */}
                {/* ===  DashBoard Main Body Footer Start ===  */}
                <div className="dash_main_footerbg bottomStickFooter bottomStickFooter_leftPart">
                  <div className="dash_main_footer">
                    <div className="dash_foot_leftbg">
                      <div className="taskstatus_bg">
                        {/* =====  new Task Status =====  */}
                        <TaskStatusCodeComponent />           
                        {/* =====  new Task Status =====  */}
                      </div>
                    </div>
                    <div className="dash_foot_rightbg">
                      <FeedbackAssistant />
                    </div>
                    <div className="clearboth" />
                  </div>
                </div>
                {/* ===  DashBoard Main Body Footer Start ===  */}
          </div>
        </React.Fragment>
        
    );
    }

    }
  export default TaskListOfMultiBundleTask;