/**
 * SplitScreenSingleTaskDetails
 * Page/Class name   : MasterLayout
 * Author            :
 * Created Date      : 7-2-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : render master component
 **/
import React, { Component } from "react";

// import CustomMessageBar from '../messagebar/CustomMessageBar';

// for context
import CommonContext from "../../context/CommonContext";
// scroll
import { Scrollbars } from "react-custom-scrollbars";

import TaskDetailsService from "../../service/TaskDetailsService";
import CustDate from "../../../common_utilities/CustDate";
import { commonJsFuncModule as commonJsObj } from "../../../common_utilities/commonjsfunc";

import SplitScreenLeftMenu from "../../share/SplitScreenLeftMenu";
import TaskStatusUpdateButton from "./TaskStatusUpdateButton";
import CalculateInvoice from "./CalculateInvoice";

// Newest1 - Start
import CustReactBootstrapModal from "./CustReactBootstrapModal";
import ProfileNewMod from "./ProfileNewMod";
// Newest1 - End

// common noti track
//import NotiEmitTrack from "./NotiEmitTrack";
import { commonnotitrack } from "./NotiEmitTrack";

const getBaseUrl = commonJsObj.getBaseUrl;
const getCcUserId = commonJsObj.getCcUserId;
const getStringInitials = commonJsObj.getStringInitials;

const stateStaticData = {
  taskId: "",
  taskName: "",
  description: "",
  practiceAreas: [],
  locations: [],
  taskCategory: null,
  categoryType: null,
  budgetCurrency: "",
  budget: "",
  assignedTo: null,
  dueDateMillis: "",
  taskFiles: [],
  subTasks: [],
  isTaskCompleted: false,
  //to show editted tag & msg
  isUnApprovedEditPresent: false,
  taskEditStatusDisplayMessage: "",
  editedDescription: "",
  editedBudgetCurrency: "",
  editedBudget: "",
  editedDueDateMillis: "",
  latestActiveUnapprovedEditDate: "",

  lpUserId: 0,
  ccUserId: 0,
  taskOrSubTask: "",
  taskIdOrSubTaskId: 0,
  isTaskCompleted: false,
  isPaid: false,

  // Newest1 - Start
  showPVDisclaimerModal: false,
  showPVDisplayModal: false,
  // Newest1 - End
};

class SplitScreenSingleTaskDetails extends Component {
  isComponentLoaded = false;
  // for context
  static contextType = CommonContext;

  /**
   * SplitScreenSingleTaskDetails
   * Function name : constructor
   * Author        :
   * Created Date  : 7-2-2020
   * Purpose       : initializing children
   * Params        : props
   **/
  constructor(props) {
    super(props);
    this.children = props.children;

    this.state = stateStaticData;

    // Newest1 - Start
    this.modalforpvdisclaimerRef = React.createRef();
    this.modalforpvdisplayRef = React.createRef();
    // Newest1 - End
  }

  /**
   * SplitScreenSingleTaskDetails
   * Function name : componentDidMount
   * Author        :
   * Created Date  : 7-2-2020
   * Purpose       : lifecycle method
   * Params        :
   **/
  componentDidMount() {
    this.isComponentLoaded = true;

    //********* for context starts *****
    let setShare =
      this.context !== undefined && this.context.setShare !== undefined
        ? this.context.setShare
        : null;

    let sharedData =
      this.context !== undefined && this.context.share !== undefined
        ? this.context.share
        : null;
    if (
      sharedData !== undefined &&
      sharedData !== null &&
      sharedData.actionType !== undefined
    ) {
      if (sharedData.actionType == "SPLITSCREEN") {
        let respData = sharedData.other.taskResp;

        this.setStateData(respData);
      }
    }
    //********* for context ends *****
  }

  /**
   * SplitScreenSingleTaskDetails
   * Function name : componentWillUnmount
   * Author        :
   * Created Date  : 7-2-2020
   * Purpose       : lifecycle method
   * Params        :
   **/
  componentWillUnmount() {
    this.isComponentLoaded = false;
  }

  closeSplit = (objParam) => {
    //********* for context starts *****
    // let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;
    // if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){

    //     if(sharedData.actionType=="SPLITSCREEN" ){

    //       let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;

    //       if(setShare){
    //         let paramsCntxt = {
    //           "taskId":"",
    //           "actionType":"",
    //           "leftTabToActive":"",
    //           "purpose":"",
    //           "other":{}
    //         }
    //         setShare(paramsCntxt);
    //       }

    //     }
    // }
    //********* for context ends *****

    commonnotitrack("CLOSE_SPLITSCREEN", {
      notiType: "CLOSE_SPLITSCREEN",
      triggerCallback: false,
      trigerredFrom: "SINGLE_TASK_DETAILS",
    });
  };

  // Newest1 - Start

  // loadLawyerInfoPopup = (i, otherParam) => {
  //   //console.log(this.state.listData[i].userDetails);
  //   let practiceAreas = [];
  //   let locations = [];
  //   let lawyerName = "";
  //   let lawyerFname = "";
  //   let lawyerLname = "";
  //   let bioData = "";
  //   let expData = "";
  //   let ratingData = "";

  //   let profilePictureURL = null;

  //   let userInfoObj =
  //     this.state.assignedTo !== undefined ? this.state.assignedTo : null;
  //   if (userInfoObj) {
  //     lawyerFname =
  //       userInfoObj.firstName !== undefined && userInfoObj.firstName !== null
  //         ? userInfoObj.firstName
  //         : "";

  //     lawyerLname =
  //       userInfoObj.lastName !== undefined && userInfoObj.lastName !== null
  //         ? userInfoObj.lastName
  //         : "";

  //     lawyerName = lawyerFname + " " + lawyerLname;

  //     practiceAreas =
  //       userInfoObj.practiceAreas !== undefined
  //         ? userInfoObj.practiceAreas
  //         : [];

  //     locations =
  //       userInfoObj.locations !== undefined ? userInfoObj.locations : [];

  //     profilePictureURL =
  //       userInfoObj.profilePictureURL !== undefined
  //         ? userInfoObj.profilePictureURL
  //         : null;

  //     bioData =
  //       userInfoObj.bio !== undefined && userInfoObj.bio !== null
  //         ? userInfoObj.bio
  //         : "";

  //     expData =
  //       userInfoObj.workExperience !== undefined &&
  //       userInfoObj.workExperience !== null
  //         ? userInfoObj.workExperience + " years"
  //         : "";

  //     ratingData =
  //       userInfoObj.rating !== undefined && userInfoObj.rating !== null
  //         ? userInfoObj.rating
  //         : "";
  //   }

  //   return (
  //     <div
  //       className="dropdown-menu user_popDetails_block"
  //       aria-labelledby="dropdownMenuButton"
  //     >
  //       <div className="user_popBox">
  //         <div className="u_popBox_sec user_popBox_top">
  //           <div className="user_popBox_topLeft">
  //             <h4>{lawyerName}</h4>
  //             <div className="userRating_block">
  //               <div className="ass_userRating">
  //                 <i className="fa fa-star" aria-hidden="true" />
  //                 {ratingData}
  //               </div>
  //               <div className="ass_userExp">Experience - {expData}</div>
  //             </div>
  //           </div>
  //           <div className="user_popBox_topRight">
  //             {profilePictureURL !== null && <img src={profilePictureURL} />}
  //             {profilePictureURL === null && (
  //               <div className="user_name_text">
  //                 <span>{getStringInitials(lawyerName)}</span>
  //               </div>
  //             )}
  //           </div>
  //         </div>
  //         <div className="u_popBox_sec user_popBox_location">
  //           <h5>Locations</h5>
  //           <ul>
  //             {locations !== null &&
  //               Object.keys(locations).map((incl, edt) => (
  //                 <li key={userInfoObj.userId + "0" + incl}>
  //                   {locations[incl]}
  //                 </li>
  //               ))}
  //           </ul>
  //         </div>
  //         <div className="u_popBox_sec user_popBox_pracArea">
  //           <h5>Practice areas</h5>
  //           <ul>
  //             {practiceAreas !== null &&
  //               Object.keys(practiceAreas).map((prci, e) => (
  //                 <li key={userInfoObj.userId + "0" + prci}>
  //                   {practiceAreas[prci]}
  //                 </li>
  //               ))}
  //           </ul>
  //         </div>
  //         <div className="u_popBox_sec user_popBox_about">
  //           <h5>About</h5>
  //           <p>{bioData}</p>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  /*** Professional View Display Modal starts  */

  /**
   * SplitScreenSingleTaskDetails
   * Function name : pVDisplayModalClose
   * Author        :
   * Created Date  : 9-7-2021
   * Purpose       : To close pVDisplay modal
   * Params        :
   **/
  pVDisplayModalClose = () => {
    this.isComponentLoaded &&
      this.setState({ showPVDisplayModal: false }, () => {
        this.modalforpvdisplayRef.current.hideModal();
      });
  };

  /**
   * SplitScreenSingleTaskDetails
   * Function name : getPVDisplayModalContent
   * Author        :
   * Created Date  : 9-7-2021
   * Purpose       : Get pVDisplay modal view with data
   * Params        :
   **/
  getPVDisplayModalContent = () => {
    let userId = null;

    if (this.state.assignedTo)
      userId = this.state.assignedTo.userId
        ? this.state.assignedTo.userId
        : null;

    let viewData = (
      <ProfileNewMod
        closingFunction={this.pVDisplayModalClose}
        lprofileId={userId}
      />
    );

    this.modalforpvdisplayRef.current.updateStateContent(viewData);
    this.modalforpvdisplayRef.current.showContent();
    return viewData;
  };

  /**
   * SplitScreenSingleTaskDetails
   * Function name : pVDisplayModalOpen
   * Author        :
   * Created Date  : 9-7-2021
   * Purpose       : To open pVDisplay modal
   * Params        :
   **/
  pVDisplayModalOpen = () => {
    this.isComponentLoaded &&
      this.setState({ showPVDisplayModal: true }, () => {
        this.modalforpvdisplayRef.current.showModal();
        this.getPVDisplayModalContent();
      });
  };

  /*** Professional View Display Modal ends  */

  /*** Professional View Disclaimer Modal starts  */

  /**
   * SplitScreenSingleTaskDetails
   * Function name : pVDiscModCloseDispModOpen
   * Author        :
   * Created Date  : 9-7-2021
   * Purpose       : To close pVDisclaimer modal and conditionally open pVDisplay modal
   * Params        : shouldDisplay
   **/
  pVDiscModCloseDispModOpen = (shouldDisplay) => {
    this.isComponentLoaded &&
      this.setState({ showPVDisclaimerModal: false }, () => {
        this.modalforpvdisclaimerRef.current.hideModal();
        if (shouldDisplay) {
          setTimeout(() => {
            this.pVDisplayModalOpen();
          }, 300);
        }
      });
  };

  /**
   * SplitScreenSingleTaskDetails
   * Function name : getPVDisclaimerModalContent
   * Author        :
   * Created Date  : 9-7-2021
   * Purpose       : Get pVDisclaimer modal view with data
   * Params        :
   **/
  getPVDisclaimerModalContent = () => {
    let viewData = (
      <div className="dash_modal">
        <div className="modal-header">
          <h4 className="modal-title modal_title_tool">
            View Professional’s Profile
            {/*<div className="input_tooltip_bg">
                      <span className="input_tooltip" aria-label="Organisation's Details" data-balloon-pos="down">
                      <i className="fa fa-question-circle"></i>
                      </span>
                      </div>*/}
          </h4>
          <button
            type="button"
            onClick={() => {
              this.pVDiscModCloseDispModOpen(false);
            }}
            className="close modal_close"
            data-dismiss="modal"
          >
            &times;
          </button>
        </div>
        {/* <CustomMessageBar /> */}
        <div className="modal-body padding_top">
          <div className="fileUp_modalBox">
            <p>
              Disclaimer: By clicking on "View" you agree that you have sought
              this information of your own accord. You further agree that
              neither the Professional nor Flywork has solicited and/or
              advertised to induce you for work.
            </p>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              this.pVDiscModCloseDispModOpen(false);
            }}
            data-dismiss="modal"
            class="btn_appearance_none modal_text_btn"
          >
            <span>Cancel</span>
          </button>
          <button
            type="button"
            className="btn_appearance_none green_btn"
            onClick={(e) => {
              e.preventDefault();
              this.pVDiscModCloseDispModOpen(true);
            }}
            data-dismiss="modal"
          >
            <span>View</span>
          </button>
        </div>
      </div>
    );

    this.modalforpvdisclaimerRef.current.updateStateContent(viewData);
    this.modalforpvdisclaimerRef.current.showContent();
    return viewData;
  };

  /**
   * SplitScreenSingleTaskDetails
   * Function name : pVDisclaimerModalOpen
   * Author        :
   * Created Date  : 9-7-2021
   * Purpose       : To open pVDisclaimer modal
   * Params        :
   **/
  pVDisclaimerModalOpen = () => {
    this.isComponentLoaded &&
      this.setState({ showPVDisclaimerModal: true }, () => {
        this.modalforpvdisclaimerRef.current.showModal();
        this.getPVDisclaimerModalContent();
      });
  };

  /*** Professional View Disclaimer Modal ends  */

  getTaskDetailData = (param) => {
    let paramObjData = param.paramObjData;
    let paramsCntxt = param.paramsCntxt;

    let lservdrf = new TaskDetailsService();
    lservdrf
      .getTaskDetail(paramObjData)
      .then((data) => {
        let entityObj = data.data.entity;

        // context share
        let setShare =
          this.context !== undefined && this.context.setShare !== undefined
            ? this.context.setShare
            : null;
        if (setShare) {
          paramsCntxt["other"]["taskResp"] = entityObj;
          //console.log(" paramsCntxt===>>",paramsCntxt);
          setShare(param.paramsCntxt);
        }
      })
      .catch((e) => {
        console.log("server error=>", e);
      })
      .finally(() => {});
  };

  loadTheContent = () => {
    let forSplitScrnCls2Inject = "";
    //********* for context starts *****
    let sharedData =
      this.context !== undefined && this.context.share !== undefined
        ? this.context.share
        : null;
    if (
      sharedData !== undefined &&
      sharedData !== null &&
      sharedData.actionType !== undefined
    ) {
      if (sharedData.actionType == "SPLITSCREEN") {
        forSplitScrnCls2Inject = "margin0";
      }
    }
    //********* for context ends *****

    let practiceAreas = this.state.practiceAreas;
    let practiceAreasMoreTxt = "";
    let practiceAreaName = "";
    let pracNameToDispForMore = [];

    if (practiceAreas && practiceAreas.length == 1) {
      practiceAreaName = practiceAreas[0] ? practiceAreas[0].practiceArea : "";
    } else if (practiceAreas && practiceAreas.length > 1) {
      let practiceAreasCnt = practiceAreas.length;
      practiceAreasCnt = practiceAreasCnt - 1;
      practiceAreasMoreTxt = "+" + practiceAreasCnt + " more";

      practiceAreaName = practiceAreas[0] ? practiceAreas[0].practiceArea : "";
      for (let ind = 1; ind < practiceAreas.length; ind++) {
        pracNameToDispForMore.push(practiceAreas[ind].practiceArea);
      }
    }

    let locations = this.state.locations;
    let locationsMoreTxt = "";
    let locationsName = "";
    let locNameToDispForMore = [];

    if (locations && locations.length == 1) {
      locationsName = locations[0] ? locations[0].cityName : "";
    } else if (locations && locations.length > 1) {
      let locCnt = locations.length;
      locCnt = locCnt - 1;
      locationsMoreTxt = "+" + locCnt + " more";

      locationsName = locations[0] ? locations[0].cityName : "";

      for (let ind = 1; ind < locations.length; ind++) {
        locNameToDispForMore.push(locations[ind].cityName);
      }
    }

    let assignedTo = this.state.assignedTo;
    let assignedToName = "None";
    if (assignedTo !== undefined && assignedTo !== null) {
      let fNm =
        assignedTo.firstName !== undefined && assignedTo.firstName !== null
          ? assignedTo.firstName
          : "";
      let lNm =
        assignedTo.lastName !== undefined && assignedTo.lastName !== null
          ? assignedTo.lastName
          : "";
      assignedToName = fNm + " " + lNm;
    }
    //let assignedToName = assignedTo?assignedTo.firstName:"none";

    let budgetData = "";
    let budget = this.state.budget ? this.state.budget : "";
    let budgetCurrency = this.state.budgetCurrency
      ? this.state.budgetCurrency
      : "";

    if (budget && budgetCurrency) {
      budgetData = budgetCurrency + " " + budget;
    }

    let taskFiles = this.state.taskFiles;
    let taskFilesCnt = 0;

    if (taskFiles) {
      taskFilesCnt = taskFiles ? taskFiles.length : 0;
    }

    let budgetEditFlag = false; // for both budget & currency
    let budgetOnlyEditFlag = false; // for  budget only
    let budgetCurrOnlyEditFlag = false; // for currency only
    let budgetDataEditted = "";
    let budgetDataOnlyEdit = "";
    let budgetCurrDataOnlyEdit = "";
    if (
      this.state.isUnApprovedEditPresent == true &&
      (this.state.editedBudget || this.state.editedBudgetCurrency)
    ) {
      budgetEditFlag = true;

      if (this.state.editedBudget && this.state.editedBudgetCurrency) {
        budgetDataEditted =
          this.state.editedBudgetCurrency + " " + this.state.editedBudget;

        budgetOnlyEditFlag = true;
        budgetCurrOnlyEditFlag = true;

        budgetDataOnlyEdit = this.state.editedBudget;
        budgetCurrDataOnlyEdit = this.state.editedBudgetCurrency;
      } else if (this.state.editedBudget && !this.state.editedBudgetCurrency) {
        budgetDataEditted = budgetCurrency + " " + this.state.editedBudget;

        budgetOnlyEditFlag = true;
        budgetCurrOnlyEditFlag = false;

        budgetDataOnlyEdit = this.state.editedBudget;
        budgetCurrDataOnlyEdit = "";
      } else if (!this.state.editedBudget && this.state.editedBudgetCurrency) {
        budgetDataEditted = this.state.editedBudgetCurrency + " " + budget;

        budgetOnlyEditFlag = false;
        budgetCurrOnlyEditFlag = true;

        budgetDataOnlyEdit = "";
        budgetCurrDataOnlyEdit = this.state.editedBudgetCurrency;
      }
    }

    let dueDateData = this.state.dueDateMillis
      ? CustDate.custFormatDate(new Date(this.state.dueDateMillis), 3)
      : "None";

    let editedDueDateFlag = false;
    let editedDueDate = "None";
    let editedDueDateMillis = null;
    //editedDueDateMillis
    if (
      this.state.isUnApprovedEditPresent == true &&
      this.state.editedDueDateMillis
    ) {
      editedDueDateFlag = true;
      editedDueDate = this.state.editedDueDateMillis
        ? CustDate.custFormatDate(new Date(this.state.editedDueDateMillis), 3)
        : "None";
      editedDueDateMillis = this.state.editedDueDateMillis
        ? this.state.editedDueDateMillis
        : null;
    }

    return (
      <>
        {/* Dashboard Slide Split Right start  */}
        <div
          className={`splitscreen_area 
              ${forSplitScrnCls2Inject ? forSplitScrnCls2Inject : ""}`}
        >
          <div className="slideScreen_bg slideScreen_bg slideScreen_Chatbg slideScreen_bg_heightAdjust">
            <SplitScreenLeftMenu />
            <div className="slideScreen_rightbg flexbox-parent">
              {/* === right slide section top start ===  */}
              <div className="SlideS_topbg flexbox-item">
                <div className="slide_top_titlebg">
                  <h4>Task Details</h4>
                  <button
                    onClick={(evn) => {
                      evn.preventDefault();
                      this.closeSplit({ trigerredFrom: "SINGLE_TASK_DETAILS" });
                    }}
                    className="btn_appearance_none slideTop_cross_btn cross"
                  >
                    <img src={getBaseUrl() + "assets/images/red_cross.png"} />
                  </button>
                </div>
              </div>
              {/* === right slide section top end ===  */}
              {/* === right slide section Middlie  start ===  */}
              <div className="SlideS_Midbg flexbox-item-grow SlideS_Midbg_heightAdjustment SlideS_Midbg_heightAdjustment_taskDetails">
                <div className="s_midbox_scroll_adjust reactTestScroll mCustomAdjustmentForStickFooter rightMidPartHeightAdjustment rightMidPartHeightAdjustment_taskDetails">
                  {/* <Scrollbars 
                              ref={this.scrollerRef}
                              style={{ height: 730 }}
                              autoHeightMin={730}
                              renderView={props => <div {...props} className="view taskdetailnosbtskscrollcustomclass"/>}

                              onScrollStop={this.trackScroll}
                      > */}
                  <div className="view taskdetailnosbtskscrollcustomclass">
                    <div className="s_midbox_section s_midbox_section_taskEdit_new">
                      {this.state.taskEditStatusDisplayMessage && (
                        <div className="top_msgBg">
                          <div className="allert_messg">
                            {this.state.taskEditStatusDisplayMessage}
                          </div>
                        </div>
                      )}

                      <div className="s_midbox">
                        {/* ====  slidebox start ====  */}
                        <div className="scroll_maintask_bg">
                          <div className="enter_task_box sub_task_addbg right_TaskBox_new">
                            {/* == task title new design start ==   */}
                            <div className="task_title_head text-right">
                              <h5>Task Number - {this.state.taskId}</h5>
                            </div>
                            {/* == task title new design end ==   */}
                            {/* === enter task box start ===  */}
                            <div className="main_form_box">
                              <div className="form_block form_block_half">
                                <div className="panel50 panel_half paddingright15 form__group_normar">
                                  <label htmlFor="name" className="form__label">
                                    <b>Task Name</b>
                                  </label>
                                  <div className="form__group field">
                                    <div className="input_datashow">
                                      <p>{this.state.taskName}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="panel50 panel_half paddingleft15 form__group_normar">
                                  <label htmlFor="name" className="form__label">
                                    <b>Assigned to</b>
                                  </label>
                                  <div className="form__group field">
                                    <div className="form__group field">
                                      <div className="input_datashow">
                                        {assignedToName == "None" && (
                                          //  <p className={`${assignedToName=="None"?"":"assTo_green"}`}>{assignedToName}</p>
                                          <p className="">{assignedToName}</p>
                                        )}

                                        {assignedToName != "None" && (
                                          <div className="onClick_userDetails onClick_userName">
                                            <div className="ass_user_name">
                                              <button
                                                className="btn_appearance_none assignUser_nameBtn"
                                                onClick={(evn) => {
                                                  evn.preventDefault();
                                                  this.pVDisclaimerModalOpen();
                                                }}
                                                // type="button"
                                                // data-toggle="dropdown"
                                                // aria-expanded="false"
                                              >
                                                {assignedToName}
                                              </button>
                                              {/* {this.loadLawyerInfoPopup(1, {})} */}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="clearboth" />
                              </div>
                              <div className="form_block form_block_half">
                                <div className="panel50 panel_half paddingright15 form__group_normar">
                                  <label htmlFor="name" className="form__label">
                                    <b>Practice area</b>
                                  </label>
                                  <div className="form__group field">
                                    <div className="form__group field">
                                      <div className="input_datashow">
                                        {practiceAreaName && (
                                          <span className="dataShow_green">
                                            {practiceAreaName}
                                          </span>
                                        )}
                                        {/* <span className="dataShow_more">{practiceAreasMoreTxt}</span> */}

                                        {practiceAreasMoreTxt && (
                                          <div className="dataShow_more_newTooltip">
                                            <a
                                              href={(evn) => {
                                                evn.preventDefault();
                                              }}
                                            >
                                              <span className="dataShow_more">
                                                {practiceAreasMoreTxt}
                                              </span>
                                            </a>
                                            <div className="moreTooltip_show">
                                              <div className="moreTooltip_box">
                                                <ul>
                                                  {pracNameToDispForMore.length >
                                                    0 &&
                                                    pracNameToDispForMore.map(
                                                      (e, i) => (
                                                        <li>
                                                          <div className="moretooltip_greenTag">
                                                            {
                                                              pracNameToDispForMore[
                                                                i
                                                              ]
                                                            }
                                                          </div>
                                                        </li>
                                                      )
                                                    )}
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="panel50 panel_half paddingleft15 form__group_normar ">
                                  <label htmlFor="name" className="form__label">
                                    <b>Location</b>
                                    &nbsp;
                                  </label>
                                  <div className="form__group field">
                                    <div className="input_datashow">
                                      {locationsName && (
                                        <span className="dataShow_green">
                                          {locationsName}
                                        </span>
                                      )}

                                      {locationsMoreTxt && (
                                        <div className="dataShow_more_newTooltip">
                                          <a
                                            href={(evn) => {
                                              evn.preventDefault();
                                            }}
                                          >
                                            <span className="dataShow_more">
                                              {locationsMoreTxt}
                                            </span>
                                          </a>
                                          <div className="moreTooltip_show">
                                            <div className="moreTooltip_box">
                                              <ul>
                                                {locNameToDispForMore.length >
                                                  0 &&
                                                  locNameToDispForMore.map(
                                                    (e, i) => (
                                                      <li>
                                                        <div className="moretooltip_greenTag">
                                                          {
                                                            locNameToDispForMore[
                                                              i
                                                            ]
                                                          }
                                                        </div>
                                                      </li>
                                                    )
                                                  )}
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="clearboth" />
                              </div>
                              <div className="form_block form_block_half">
                                <div className="panel50 panel_half paddingright15 form__group_normar">
                                  <label htmlFor="name" className="form__label">
                                    <b>Category of task</b>
                                  </label>
                                  <div className="form__group field">
                                    <div className="input_datashow">
                                      <p>
                                        {this.state.taskCategory &&
                                        this.state.taskCategory.category
                                          ? this.state.taskCategory.category
                                          : ""}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="panel50 panel_half paddingleft15 form__group_normar">
                                  <label htmlFor="name" className="form__label">
                                    <b>Type of task</b>
                                  </label>
                                  <div className="form__group field">
                                    <div className="form__group field">
                                      <div className="input_datashow">
                                        <p>
                                          {this.state.categoryType &&
                                          this.state.categoryType.categoryType
                                            ? this.state.categoryType
                                                .categoryType
                                            : ""}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="clearboth" />
                              </div>
                              <div className="form_block form_block_half">
                                <div className=" panel_half form__group_normar">
                                  <label htmlFor="name" className="form__label">
                                    <b>Task description</b>
                                    {this.state.isUnApprovedEditPresent ==
                                      true &&
                                      this.state.editedDescription && (
                                        <div className="lable_popDetails_bg">
                                          <button className="btn_appearance_none lable_popBtn">
                                            Edited
                                          </button>
                                          <div className="lable_pop_details">
                                            <div className="lable_pop_detailsTop">
                                              {/* <button class="btn_appearance_none veh_btn">View edited history</button> */}
                                              <h5>Awaiting Confirmation</h5>
                                              <div className="clearboth" />
                                            </div>
                                            <p>{this.state.description}</p>
                                            <p className="dtp">
                                              <span>
                                                {this.state
                                                  .latestActiveUnapprovedEditDate
                                                  ? CustDate.custFormatDate(
                                                      new Date(
                                                        this.state.latestActiveUnapprovedEditDate
                                                      ),
                                                      4
                                                    )
                                                  : "None"}
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      )}
                                  </label>
                                  <div className="form__group field">
                                    <div className="input_datashow">
                                      <p>
                                        {this.state.isUnApprovedEditPresent ==
                                          true && this.state.editedDescription
                                          ? this.state.editedDescription
                                          : this.state.description}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="clearboth" />
                              </div>
                              <div className="form_block form_block_half">
                                <div className="panel50 panel_half paddingright15 form__group_normar">
                                  <label htmlFor="name" className="form__label">
                                    <b>Budget</b>
                                    {budgetEditFlag && (
                                      <div className="lable_popDetails_bg">
                                        <button className="btn_appearance_none lable_popBtn">
                                          Edited
                                        </button>
                                        <div className="lable_pop_details">
                                          <div className="lable_pop_detailsTop">
                                            {/* <button class="btn_appearance_none veh_btn">View edited history</button> */}
                                            <h5>Awaiting Confirmation</h5>
                                            <div className="clearboth" />
                                          </div>
                                          <p>{budgetData}</p>
                                          <p className="dtp">
                                            <span>
                                              {this.state
                                                .latestActiveUnapprovedEditDate
                                                ? CustDate.custFormatDate(
                                                    new Date(
                                                      this.state.latestActiveUnapprovedEditDate
                                                    ),
                                                    4
                                                  )
                                                : "None"}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                  </label>
                                  <div className="form__group field">
                                    <div className="input_datashow">
                                      <p>
                                        {budgetEditFlag
                                          ? budgetDataEditted
                                          : budgetData}
                                      </p>
                                    </div>
                                    {this.state.isTaskCompleted == false && (
                                      <div className="cal_invoice_btnSec">
                                        <CalculateInvoice
                                          custclassnamebtn={
                                            "btn_appearance_none calInvBtn"
                                          }
                                          custclassnamebtnspan={
                                            "calInvBtn_text"
                                          }
                                          textname={
                                            "Calculate estimated invoice"
                                          }
                                          budget={budget}
                                          budgetcurrency={budgetCurrency}
                                        />
                                        {/* <button className="btn_appearance_none calInvBtn"><span className="calInvBtn_text">Calculate Invoice</span></button> */}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="panel50 panel_half paddingleft15 form__group_normar">
                                  <label htmlFor="name" className="form__label">
                                    <b>Due date</b>
                                    {this.state.isUnApprovedEditPresent ==
                                      true &&
                                      this.state.editedDueDateMillis && (
                                        <div className="lable_popDetails_bg">
                                          <button className="btn_appearance_none lable_popBtn">
                                            Edited
                                          </button>
                                          <div className="lable_pop_details">
                                            <div className="lable_pop_detailsTop">
                                              {/* <button class="btn_appearance_none veh_btn">View edited history</button> */}
                                              <h5>Awaiting Confirmation</h5>
                                              <div className="clearboth" />
                                            </div>
                                            <p>{dueDateData}</p>
                                            <p className="dtp">
                                              <span>
                                                {this.state
                                                  .latestActiveUnapprovedEditDate
                                                  ? CustDate.custFormatDate(
                                                      new Date(
                                                        this.state.latestActiveUnapprovedEditDate
                                                      ),
                                                      4
                                                    )
                                                  : "None"}
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      )}
                                  </label>
                                  <div className="form__group field form_group_error">
                                    <div className="input_datashow">
                                      <p>
                                        {editedDueDateFlag
                                          ? editedDueDate
                                          : dueDateData}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="clearboth" />
                              </div>
                              <div className="form_block form_block_half">
                                <div className=" panel_half form__group_normar">
                                  <div className="s_form_fileup_bg">
                                    <div className="sform_attachment_sec">
                                      <div className="s_attachment">
                                        Task documents ({taskFilesCnt})
                                      </div>
                                      <div className="s_attachment_btnbg">
                                        <div className="s_att_btn s_att_btn_view">
                                          <button
                                            onClick={(evn) => {
                                              evn.preventDefault();
                                              let setShare =
                                                this.context !== undefined &&
                                                this.context.setShare !==
                                                  undefined
                                                  ? this.context.setShare
                                                  : null;

                                              let sharedData =
                                                this.context !== undefined &&
                                                this.context.share !== undefined
                                                  ? this.context.share
                                                  : null;

                                              if (setShare && sharedData) {
                                                sharedData.leftTabToActive =
                                                  "DOCUMENTS";
                                                sharedData.purpose =
                                                  "VIEW_DOC_LIST";
                                                setShare(sharedData);
                                              }
                                            }}
                                            className="btn_appearance_none s_view_btn"
                                          >
                                            View
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="clearboth" />
                              </div>
                            </div>
                            {/* === enter task box end ===  */}
                            {/* == main from box button section start ==  */}
                            <div className="mainFormBox_btnSec">
                              <div className="s_footer_btnbg">
                                {this.state.isTaskCompleted == false && (
                                  <button
                                    onClick={(ev) => {
                                      ev.preventDefault();

                                      let subTasksCount = this.state.subTasks
                                        ? this.state.subTasks.length
                                        : 0;
                                      //
                                      let paramsCntxt = {};
                                      paramsCntxt = {
                                        taskId: this.state.taskId,
                                        actionType: "SPLITSCREEN",
                                        leftTabToActive: "TASKDETAILS",
                                        purpose: "EDIT",
                                        other: {
                                          subTasksCount: subTasksCount,
                                        },
                                      };

                                      let paramObjData = {
                                        data: { taskId: this.state.taskId },
                                      };
                                      let param = {
                                        paramObjData: paramObjData,
                                        paramsCntxt: paramsCntxt,
                                      };

                                      this.getTaskDetailData(param);

                                      //
                                    }}
                                    className="btn_appearance_none green_btn"
                                  >
                                    <span>Edit</span>
                                  </button>
                                )}
                              </div>
                            </div>
                            {/* == main from box button section end ==  */}
                            {/* === enter sub task box start ===  */}
                            {/* === enter sub task box end ===  */}
                          </div>
                        </div>
                        {/* ====  slidebox end ====  */}
                      </div>
                    </div>
                  </div>
                  {/* </Scrollbars> */}
                </div>
              </div>
              {/* === right slide section Middlie  end ===  */}
              {/* === right slide section Footer  start ===  */}
              <div className="SlideS_bottombg flexbox-item SlideS_bottombg_stickyFooterRightPart SlideS_bottombg_stickyFooterRightPart_taskDetails ">
                <div className="s_footer_btnbg invoTask_footer_btnbg">
                  {this.state.taskOrSubTask == "STASK" &&
                    this.state.isTaskCompleted == false &&
                    this.state.isPaid == true && (
                      <TaskStatusUpdateButton
                        datas={{
                          taskOrSubTask: this.state.taskOrSubTask,
                          taskIdOrSubTaskId: this.state.taskIdOrSubTaskId,
                          lpUserId: this.state.lpUserId,
                          ccUserId: this.state.ccUserId,
                        }}
                        custclassname="btn_appearance_none sub_grd_btn"
                        buttontextname={"Close task"}
                        onsuccess={this.callBackOnTaskStatusUpdate}
                      />
                    )}
                </div>
              </div>
              {/* === right slide section Footer  end ===  */}
            </div>
          </div>
        </div>

        {/* Dashboard Slide Split Right end  */}
      </>
    );
  };

  // Newest1 - End

  setStateData = (entityObj) => {
    let paramData = stateStaticData;

    if (entityObj !== undefined && entityObj !== null) {
      paramData.taskId = entityObj.id !== undefined ? entityObj.id : "";
      paramData.taskName =
        entityObj.taskName !== undefined ? entityObj.taskName : "";
      paramData.description =
        entityObj.description !== undefined ? entityObj.description : "";
      paramData.practiceAreas =
        entityObj.practiceAreas !== undefined ? entityObj.practiceAreas : "";
      paramData.locations =
        entityObj.locations !== undefined ? entityObj.locations : "";
      paramData.taskCategory =
        entityObj.taskCategory !== undefined ? entityObj.taskCategory : "";
      paramData.categoryType =
        entityObj.categoryType !== undefined ? entityObj.categoryType : "";

      paramData.assignedTo =
        entityObj.assignedTo !== undefined ? entityObj.assignedTo : null;
      if (paramData.assignedTo) {
        paramData.budgetCurrency =
          entityObj.agreedBudgetCurrency !== undefined
            ? entityObj.agreedBudgetCurrency
            : "";
        paramData.budget =
          entityObj.agreedBudget !== undefined ? entityObj.agreedBudget : "";
        paramData.dueDateMillis =
          entityObj.agreedDueDateMillis !== undefined
            ? entityObj.agreedDueDateMillis
            : "";
      } else {
        paramData.budgetCurrency =
          entityObj.budgetCurrency !== undefined
            ? entityObj.budgetCurrency
            : "";
        paramData.budget =
          entityObj.budget !== undefined ? entityObj.budget : "";
        paramData.dueDateMillis =
          entityObj.dueDateMillis !== undefined ? entityObj.dueDateMillis : "";
      }

      paramData.taskFiles =
        entityObj.taskFiles !== undefined ? entityObj.taskFiles : [];
      paramData.subTasks =
        entityObj.subTasks !== undefined ? entityObj.subTasks : [];
      paramData.isTaskCompleted =
        entityObj.isTaskCompleted !== undefined
          ? entityObj.isTaskCompleted
          : false;

      // for editted tag related
      paramData.isUnApprovedEditPresent =
        entityObj.isUnApprovedEditPresent !== undefined
          ? entityObj.isUnApprovedEditPresent
          : "";
      paramData.taskEditStatusDisplayMessage =
        entityObj.taskEditStatusDisplayMessage !== undefined
          ? entityObj.taskEditStatusDisplayMessage
          : "";
      paramData.editedDescription =
        entityObj.editedDescription !== undefined
          ? entityObj.editedDescription
          : "";
      paramData.editedBudgetCurrency =
        entityObj.editedBudgetCurrency !== undefined
          ? entityObj.editedBudgetCurrency
          : "";
      paramData.editedBudget =
        entityObj.editedBudget !== undefined ? entityObj.editedBudget : "";
      paramData.editedDueDateMillis =
        entityObj.editedDueDateMillis !== undefined
          ? entityObj.editedDueDateMillis
          : "";
      paramData.latestActiveUnapprovedEditDate =
        entityObj.latestActiveUnapprovedEditDate !== undefined
          ? entityObj.latestActiveUnapprovedEditDate
          : "";
      //////

      let taskOrSubTask = "";
      let taskIdOrSubTaskId = 0;
      let lpUserId = 0;
      let ccUserId = 0;
      let isTaskCompleted = false;
      //let taskKind = "";
      let taskType = "";

      taskOrSubTask =
        entityObj.subTasksCount !== undefined &&
        entityObj.subTasksCount !== null &&
        entityObj.subTasksCount > 0
          ? "SWSTASK"
          : "STASK";

      if (taskOrSubTask == "STASK") {
        taskIdOrSubTaskId =
          entityObj.id !== undefined && entityObj.id !== null
            ? entityObj.id
            : 0;

        lpUserId =
          entityObj.assignedTo !== undefined &&
          entityObj.assignedTo !== null &&
          entityObj.assignedTo.userId !== undefined &&
          entityObj.assignedTo.userId !== null
            ? entityObj.assignedTo.userId
            : 0;

        isTaskCompleted =
          entityObj.isTaskCompleted !== undefined &&
          entityObj.isTaskCompleted !== null
            ? entityObj.isTaskCompleted
            : false;

        //INVOICE_PAID
        //taskKind = (entityObj.taskKind!==undefined && entityObj.taskKind!==null) ? entityObj.taskKind:"";
        taskType =
          entityObj.taskType !== undefined && entityObj.taskType !== null
            ? entityObj.taskType
            : "";

        ccUserId = getCcUserId();

        paramData.taskOrSubTask = taskOrSubTask;
        paramData.taskIdOrSubTaskId = taskIdOrSubTaskId;
        paramData.lpUserId = lpUserId;
        paramData.ccUserId = ccUserId;
        paramData.isTaskCompleted = isTaskCompleted;
        paramData.isPaid = taskType == "INVOICE_PAID" ? true : false;
      }

      this.isComponentLoaded && this.setState(paramData, () => {});
    } else {
      this.closeSplit({ trigerredFrom: "SINGLE_TASK_DETAILS" });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.values.taskId !== prevProps.values.taskId) {
      //********* for context starts *****
      let sharedData =
        this.context !== undefined && this.context.share !== undefined
          ? this.context.share
          : null;
      if (
        sharedData !== undefined &&
        sharedData !== null &&
        sharedData.actionType !== undefined
      ) {
        if (sharedData.actionType == "SPLITSCREEN") {
          let respData = sharedData.other.taskResp;

          this.setStateData(respData);
        }
      }
      //********* for context ends *****
    }
  }

  callBackOnTaskStatusUpdate = () => {
    let sharedData =
      this.context !== undefined && this.context.share !== undefined
        ? this.context.share
        : null;
    if (
      sharedData !== undefined &&
      sharedData !== null &&
      sharedData.actionType !== undefined
    ) {
      if (sharedData.actionType == "SPLITSCREEN") {
        let respData = sharedData.other.taskResp;

        this.setStateData(respData);
      }
    }
  };

  // trackNotiForCloseSplit = (otherParam) =>{

  //   let notiType =  otherParam.notiType!==undefined? otherParam.notiType: "";
  //   let trigerredFrom =  otherParam.trigerredFrom!==undefined? otherParam.trigerredFrom: "";
  //   if(notiType=="CLOSE_SPLITSCREEN"){
  //       let closeSplitHeaderCallFrm = ["SINGLE_TASK_DETAILS"];
  //       if(closeSplitHeaderCallFrm.indexOf(trigerredFrom)!=-1){
  //               console.log("*******trigerredFrom=>>",trigerredFrom);
  //       }

  //   }
  // }

  /**
   * SplitScreenSingleTaskDetails
   * Function name : render
   * Author        :
   * Created Date  : 7-2-2020
   * Purpose       : lifecycle method
   * Params        :
   **/
  render() {
    return (
      <React.Fragment>
        {/* <NotiEmitTrack callbackfuncforcommontrackofnoti={this.trackNotiForCloseSplit} /> */}
        {this.loadTheContent()}

        {/* Newest1 - Start */}

        {/* Professional View Disclaimer Modal start */}
        <CustReactBootstrapModal
          ref={this.modalforpvdisclaimerRef}
          backdrop={false}
          backdropClassName="custom_modal_class"
          animation={false}
          show={this.state.showPVDisclaimerModal}
          onShow={() => {}}
          onHide={() => {}}
          modalcustdialog="custom_modal_lg modal-dialog-centered modal_newProfileViewConfirmation"
          loaderbodytitle="Organisation"
        />
        {/* Professional View Disclaimer Modal end */}

        {/* Professional View Display Modal start */}
        <CustReactBootstrapModal
          ref={this.modalforpvdisplayRef}
          backdrop={false}
          backdropClassName="custom_modal_class"
          animation={false}
          show={this.state.showPVDisplayModal}
          onShow={() => {}}
          onHide={() => {}}
          modalcustdialog="custom_modal_lg modal-dialog-centered modal_newProfileViewFullDetails"
          loaderbodytitle="Organisation"
        />
        {/* Professional View Display Modal end */}

        {/* Newest1 - End */}
      </React.Fragment>
    );
  }
}
export default SplitScreenSingleTaskDetails;
