/**
 * MasterLayout
 * Page/Class name   : MasterLayout
 * Author            :
 * Created Date      : 7-2-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : render master component
**/
import React, { Component } from 'react';
import Header from '../share/Header';
import SideBar from '../share/SideBar';

import SplitScreenPart from '../share/SplitScreenPart';
import ApiResponseStatusTrackCustom from '../error/ApiResponseStatusTrackCustom';


// import CustomMessageBar from '../messagebar/CustomMessageBar';
import { commonJsFuncModule as commonJsObj } from '../../common_utilities/commonjsfunc';

// for context
import CommonContext from '../context/CommonContext';
// hot jar
import { hotjar } from 'react-hotjar';

import ClearCache from "./../../ClearCache";


// google ga
//import ReactGA from 'react-ga';
import {customizedRGA} from "../customisedthirdparty/CustomizedReactGa";
import { createBrowserHistory } from "history";

// load google map library
import {LoadGoogleMap} from '../customisedthirdparty/LoadGoogleMap';


const hjLoadFlag = commonJsObj.hjLoadFlag;
const getHjIdSv = commonJsObj.getHjIdSv;
const getUserEmail = commonJsObj.getUserEmail;

 

class MasterLayout extends Component {

    isComponentLoaded =  false;

    // for context
    static contextType = CommonContext;  

    /**  
     * MasterLayout
     * Function name : constructor
     * Author        :
     * Created Date  : 7-2-2020
     * Purpose       : initializing children
     * Params        : props
    **/
    constructor(props) {
      super(props);
      this.children = props.children;
    }

    /**  
     * MasterLayout
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 7-2-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentDidMount() {
      this.isComponentLoaded = true;   

      
      // ga rnd
      let loggedInUserEmail = getUserEmail();
      customizedRGA.setUserId( {
        userId: loggedInUserEmail
      });
      let histObj =  createBrowserHistory();      
      customizedRGA.sendPageViewData({"path":histObj.location.pathname});
      
      // google map api load
      LoadGoogleMap.loadGoogleMapLibrary(() => {
           }); 

      // load hotjar
      if(hjLoadFlag()=="YES"){
        let hjObj= getHjIdSv();
        let hjid =  hjObj.hjid;
        let hjsv =  hjObj.hjsv;
        hotjar.initialize(hjid, hjsv);

      }
      
    }

    

    /**  
     * MasterLayout
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 7-2-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {
      this.isComponentLoaded = false;
    }

    /**  
     * MasterLayout
     * Function name : render
     * Author        :
     * Created Date  : 7-2-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() {    
      const share = {};

      let forSplitScrnCls1Inject = "";
      

      //********* for context starts *****
      let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;      
      if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){         

          if(sharedData.actionType=="SPLITSCREEN" ){
            forSplitScrnCls1Inject = "width50";
            
          }
      }
      //********* for context ends *****

      return (
          <React.Fragment>
                <ApiResponseStatusTrackCustom />
                <div className={`wrapper_bg`}>
                    
                    {  /*  Dashboard Left Section start */  }
                    <div className={`wrapper ${forSplitScrnCls1Inject?forSplitScrnCls1Inject:""}`}>
                      <ClearCache duration={30000}>
                          {({ loading, isLatestVersion, refreshCacheAndReload, returnCurrentBuildVersionText }) => {
                            if (loading) return null;
                            if (!loading && !isLatestVersion) {
                              // You can decide how and when you want to force reload
                              // setTimeout(()=>{
                              //   refreshCacheAndReload();
                              // },120000);                              
                            }
                            return (
                              <div className="newbuildTrackRelMsgOuterDiv">
                              
                                  { (!loading && !isLatestVersion) && (
                                    <div class="leftTopHead_updateNotiBar">
                                        <p className="newbuildTrackRelMsg">New updates available. Please click on the update button to update your application.</p>
                                        <button className="btn_appearance_none newbuildTrackRelCacheClearButton" onClick={(evn)=>{ 
                                          evn.preventDefault();
                                          refreshCacheAndReload();
                                        
                                        }} >
                                          <span>Update</span>
                                        </button>
                                     </div>
                                  
                                  )}
                                
                              </div>
                            );
                          }}
                      </ClearCache>
                      <Header />
                      {this.children}   
                    </div>
                    {/* Dashboard Left Section end  */    }
                    { /* Dashboard Slide Split Right start  */ }
                    <SplitScreenPart  />
                    { /* Dashboard Slide Split Right end  */ }
                </div>
                                         
          </React.Fragment>
      );
    }
}
export default MasterLayout;

