/**  
 * SplitScreenSingleTaskWithSubTaskDetailsEdit
 * Page/Class name   : MasterLayout
 * Author            :
 * Created Date      : 7-2-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : render master component
**/
import React, { Component } from 'react';

import { commonJsFuncModule as commonJsObj } from '../../../common_utilities/commonjsfunc';
// Modal
import CustReactBootstrapModal from './CustReactBootstrapModal';

import SplitScreenLeftMenu from "../../share/SplitScreenLeftMenu";

// services
import FileUploadService from '../../service/FileUploadService';
import FloorPriceBasedOnCategAndTypeService from '../../service/FloorPriceBasedOnCategAndTypeService';
import TaskUpdateService from '../../service/TaskUpdateService';
import TaskDetailsService from '../../service/TaskDetailsService';
import TypeIdBasedOnCategoryService from '../../service/TypeIdBasedOnCategoryService';
import CategoriesBasedOnPracticeAreaService from '../../service/CategoriesBasedOnPracticeAreaService';

import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";

// for context
import CommonContext from '../../context/CommonContext';

import {notify} from '../../messagebar/CustomMessageBar';
import CustomMessageBar from '../../messagebar/CustomMessageBar';

import  CustDate  from '../../../common_utilities/CustDate';

// common noti track
import NotiEmitTrack from "./NotiEmitTrack";
import {commonnotitrack} from "./NotiEmitTrack";

// scroll 
import { Scrollbars } from 'react-custom-scrollbars';

const getDateObjFromMilliseconds = commonJsObj.getDateObjFromMilliseconds;
const getTimestampInMillisecondsFromDateObj = commonJsObj.getTimestampInMillisecondsFromDateObj;
const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;
const getBaseUrl = commonJsObj.getBaseUrl;
const currencyStaticData = [
  {"key":"INR","value":"INR"},
  {"key":"EUR","value":"Pound"},
  {"key":"USD","value":"Dollar"}
];

const defaultSubTaskKeys ={
  "name": "",
  "categoryId": "",
  "typeId": "",
  "budgetCurrency": "INR",
  "budget": "",
  "typeIdOptions": []
};

const ExampleCustomInput = ({ value, onClick }) => (    
    <input
    type="text"
    className="form__field customdatefld"
    placeholder="Task Due Date (Optional)"
    defaultValue={value}
    onClick={onClick}
    />
  );


const stateStaticData = {
  taskId: "",
  taskName: "",
  description: "",
  practiceAreas: [],
  locations: [],
  taskCategory: null,
  categoryType: null,
  budgetCurrency: "",
  budget: "",
  assignedTo: "",
  dueDateMillis: null,
  taskFiles: [],
  offsetHour: null,
  offsetMinute: null,
  // sub
  subTasks: [],
  categoryOptions: [],
  errMsgsSubT: [],

  uploadingFlag: false,
  // for continue button 
  continueDisabled: false,

  //For error messages   
  budgetCurrencyErrMsg :'',
  budgetErrMsg :'',
  descriptionErMsg: '',
  dueDateErMsg: '',
  fileUplErMsg: '',
  
  // for update confirm modal
  showUpdateConfirmModal: false

  
 
};
class SplitScreenSingleTaskWithSubTaskDetailsEdit extends Component {

    isComponentLoaded =  false;
    // for context
    static contextType = CommonContext; 

    /**  
     * SplitScreenSingleTaskWithSubTaskDetailsEdit
     * Function name : constructor
     * Author        :
     * Created Date  : 7-2-2020
     * Purpose       : initializing children
     * Params        : props
    **/
    constructor(props) {
      super(props);
      this.children = props.children;

      this.state = stateStaticData;
      this.budgetInputRef = React.createRef();
      this.fileUpl = React.createRef();
      this.modalforcmfrmupdtRef = React.createRef();
      this.modalforsubtskremvRef = React.createRef();
    }

    /**  
     * SplitScreenSingleTaskWithSubTaskDetailsEdit
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 7-2-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentDidMount() {
      this.isComponentLoaded = true;
      //console.log(" split screen single task loaded......");

     

      //********* for context starts *****
      let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;      
      if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){         

          if(sharedData.actionType=="SPLITSCREEN" ){

            let paramObj1 = {
              "data":{
                "taskId":this.props.values.taskId
              }
            }
            this.getTaskDetailData(paramObj1);

          }
      }
      //********* for context ends *****
      
    }

    /**  
     * SplitScreenSingleTaskWithSubTaskDetailsEdit
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 7-2-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {
      this.isComponentLoaded = false;
    }

    componentDidUpdate(prevProps){

      if(this.props.values.taskId!==prevProps.values.taskId){
            let paramObj1 = {
              "data":{
                "taskId":this.props.values.taskId
              }
            }
            this.getTaskDetailData(paramObj1);
      }

    }

    customDate = (defaultDate)=>{        
           
      return (
        <DatePicker
          selected={defaultDate}
          onChange={date => {this.setCalDate(date)}}
          calendarClassName="customtaskaddclassstep1calcls"         
          customInput={<ExampleCustomInput   />}
        />
      );
    }

    // set data from response received from context
    setStateData = (entityObj) =>{

      
      let paramData = stateStaticData;

      if(entityObj!==undefined){
        paramData.taskId = entityObj.id;
        paramData.taskName = entityObj.taskName;
        paramData.description = entityObj.description;
        paramData.practiceAreas = entityObj.practiceAreas;
        paramData.locations = entityObj.locations;
        paramData.taskCategory = entityObj.taskCategory;
        paramData.categoryType = entityObj.categoryType;
        paramData.budgetCurrency = entityObj.budgetCurrency;
        paramData.budget = entityObj.budget;
        paramData.assignedTo = entityObj.assignedTo;
        paramData.dueDateMillis = entityObj.dueDateMillis;
        paramData.taskFiles = entityObj.taskFiles;
        paramData.subTasks = [];
        
        this.setCategFromPracticeArea(paramData.practiceAreas);

        this.isComponentLoaded && this.setState(paramData,()=>{     
        });
        
        //*** for substasks special tricky and complex code starts
             
        let subTasks  = entityObj.subTasks;
        let subTasksData = [];       
        Object.keys(subTasks).map((e,i)=>{
           
             if(subTasks[i]){
                 let subT = {
                    "id": "",
                    "name": "",
                    "categoryId": "",
                    "typeId": "",
                    "budgetCurrency": "INR",
                    "budget": "",
                    "typeIdOptions": []
                };
                subT.id = subTasks[e].id?subTasks[e].id:"";
                subT.name = subTasks[e].subTaskName?subTasks[e].subTaskName:"";
                subT.categoryId = subTasks[e].category?subTasks[e].category.id:0;
                subT.typeId = subTasks[e].categoryType?subTasks[e].categoryType.id:0;
                subT.budgetCurrency = subTasks[e].budgetCurrency?subTasks[e].budgetCurrency:"";
                subT.budget = subTasks[e].budget?subTasks[e].budget:"";

                subTasksData.push(subT);
             }
            

        });

        let requests = Object.keys(subTasksData).map((e,i)=>{
            return this.getTypesIdsFromCategoryService(subTasksData[e].categoryId,null,null); 
        });

        Promise.all(requests)
            .then(body => { 
            body.forEach((res,i) => {
            if (res)                               
                subTasksData[i].typeIdOptions = res.resp;
            });

                    
            let otherParam9 = {"action":"push","bulk":[]};   
            otherParam9.bulk = subTasksData;                  
            this.setStatesForSubTask("setSubTaskKeyData","",otherParam9);
            

        });

       
        
        
        //*** for substasks special tricky and complex code ends

        //
        
              
      }     

    }

    async getTypesIdsFromCategoryService(categId,prevsel,i){
        
      let respData = {"resp":[]};
      let tpserv = new TypeIdBasedOnCategoryService();        
      let resp =  await tpserv.getTypeIdsBasedOnCategory(categId).then(data =>{
      if(data.status==200){

          if(data.data.entity!==undefined){                 
              respData.resp = data.data.entity; 

              if(i!==null){

                let otherParam0 = {};
                let setSubTaskKeyData0 = {"keyIndex":i,"keyName":"typeId","keyValue":""};   
                let caseKeyword0 = "setSubTaskKeyData";  
                this.setSubTaskKeyData(caseKeyword0,setSubTaskKeyData0, otherParam0);

                let otherParam = {};
                let setSubTaskKeyData = {"keyIndex":i,"keyName":"typeIdOptions","keyValue":respData.resp};   
                let caseKeyword = "setSubTaskKeyData";  
                this.setSubTaskKeyData(caseKeyword,setSubTaskKeyData, otherParam); 
              }

                            

          }           
      }
      }).catch((e)=>{
          ////console.log.log(" Something went wrong with api server=>",e);
      });

      return respData;
 
  
    }

    setCategFromPracticeArea(selectedList,otherParam){
      

            // get categories based on practice area selection            
    let selectedPrcAreaData = ""; let  selectedPrcAreaAr = [];
    if(selectedList.length>0){
        Object.keys(selectedList).map((e,i)=>{
            selectedPrcAreaAr.push(selectedList[e].practiceId);
        });
        if(selectedPrcAreaAr.length>0){
            selectedPrcAreaData = selectedPrcAreaAr.join(",");
        }
        // set categories based on practice area selection
        let setPrevSel= false;
        this.getCategFromPracticeAreaService(selectedPrcAreaData,setPrevSel);
    }else{
          
    }

    }

    getCategFromPracticeAreaService(practicAreas,setPrevSel){
       
      if(practicAreas!=''){
          let tpserv = new CategoriesBasedOnPracticeAreaService();        
          tpserv.getCategBasedOnPracticeArea(practicAreas).then(data =>{
          if(data.status==200){
              if(data.data.entity!==undefined){                
                this.isComponentLoaded && this.setState({"categoryOptions":data.data.entity},()=>{  
                                
                });



              }           
          }
          }).catch((e)=>{
              ////console.log.log(" Something went wrong with api server=>",e);
          });
      }else{
                      
      }
      
    
    }


    closeSplit = ()=>{
          //********* for context starts *****
          let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;      
          if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){         

              if(sharedData.actionType=="SPLITSCREEN" ){

                let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;

                if(setShare){                              
                  let paramsCntxt = {
                    "taskId":sharedData.taskId,
                    "actionType":"",
                    "leftTabToActive":"",
                    "purpose":"",
                    "other":{}
                  } 
                  setShare(paramsCntxt);                              
                }

                
              }
          }
          //********* for context ends *****

    }

    custValidate =()=>{
    
      const {subTasks,errMsgsSubT} = this.state;
      let subtaskKeys = subTasks;

      let totErrCountTrack = [];   
      let totSubErrCountTrack = [];

      
      
      let regexBlankSpace = /^ *$/;   
      

     
    
      if(subtaskKeys.length>0){

          let items = [...this.state.errMsgsSubT];       

          for(let i=0;i<subtaskKeys.length;i++){

              let subTaskNameErrMsg= "";
              let categoryIdErrMsg = "";
              let typeIdErrMsg = "";
              let budgetErrMsg =  "";

              let erFlgStNmTrk = false; 
              let erFlgCatTrk = false; 
              let erFlgTpIdTrk = false; 
              let erFlgBudgTrk = false;

              
              if(!(subtaskKeys[i].name)||(subtaskKeys[i].name.length==0)|| (regexBlankSpace.test(subtaskKeys[i].name)==true)){
                  subTaskNameErrMsg = "Sub task name is required.";
                  erFlgStNmTrk = true;                 
              }

              if(!(subtaskKeys[i].categoryId)||(subtaskKeys[i].categoryId.length==0)|| (regexBlankSpace.test(subtaskKeys[i].categoryId)==true)){
                  categoryIdErrMsg = "Category required.";
                  erFlgCatTrk = true;                  
              }

              if(!(subtaskKeys[i].typeId)||(subtaskKeys[i].typeId.length==0)|| (regexBlankSpace.test(subtaskKeys[i].typeId)==true)){
                  typeIdErrMsg = "Type is required.";
                  erFlgTpIdTrk = true;                 
              }

              if(errMsgsSubT[i] && regexBlankSpace.test(errMsgsSubT[i].budgetErrMsg)!=true){
                  budgetErrMsg = this.state.errMsgsSubT[i].budgetErrMsg;
                  erFlgBudgTrk = true;  
              }else{
                if(!(subtaskKeys[i].budget)||(subtaskKeys[i].budget.length==0)|| (regexBlankSpace.test(subtaskKeys[i].budget)==true)){
                  budgetErrMsg = "Budget is required.";
                  erFlgBudgTrk = true;                 
                }else if(erFlgCatTrk || erFlgTpIdTrk){
                  budgetErrMsg = "Input for either Category of Task or Type of Task or Budget or Budget Currency is missing.";
                    erFlgBudgTrk = true;
                }
              }             
              
            
                        
              let item = {...items[i]};  

              item.subTaskNameErrMsg = subTaskNameErrMsg;
              item.categoryIdErrMsg = categoryIdErrMsg;
              item.typeIdErrMsg = typeIdErrMsg;
              item.budgetErrMsg = budgetErrMsg;
          
              items[i] = item;
              //////console.log.log(" Set this object==>",items);

              if(erFlgStNmTrk == true || erFlgCatTrk == true || erFlgTpIdTrk == true || erFlgBudgTrk == true ){
                totSubErrCountTrack.push(items);
              }
      
              
          }

          if(totSubErrCountTrack.length>0){
            this.isComponentLoaded && this.setState({ "errMsgsSubT":items },()=>{             
             
            });
          }else{
            this.isComponentLoaded && this.setState({ "errMsgsSubT":[] },()=>{             
             
            });
          }
          


      }

 
        
        if(totSubErrCountTrack.length>0||totErrCountTrack.length>0){
            return false;
        }
    
        return true;
  }

    hitUpdateApi = ()=>{
        let chk = this.custValidate();        
        if(chk){          
          //call modal this.updateTask();
          this.updateConfirmModalOpen();

        }
      }

    setCalDate=(dt)=>{
      ////console.log.log("selected date=>>",dt);
      let dtTs = getTimestampInMillisecondsFromDateObj(dt);
      let dttOfset = getOffsetOfLocalDateTime(dt);       

      let otherParam1 = {};
      this.setStatesForSTEdit("dueDate",dtTs,otherParam1);
      let otherParam2 = {};
      this.setStatesForSTEdit("timeOffset",dttOfset,otherParam2);
      }

    getTaskDetailData = (param)=>{   

          let lservdrf = new TaskDetailsService();
          lservdrf.getTaskDetail(param).then(data =>{              

              this.setState(stateStaticData,()=>{
                setTimeout(()=>{   
                  let entityObj  = data.data.entity;         
                  this.setStateData(entityObj);
                },1500);
                
              });

          }).catch((e)=>{                       
              console.log("server error=>",e);
          }).finally(()=>{
            
          });
      }

    

    budgetOnBlurTrack = async (e,i) =>{
      let budgetInputValue = e.target.value;
      let otherParam = {
          categoryId: 0,
          typeId: 0,
          budgetCurrency: ''
      }; 
          
      
      const subtaskKeys = this.state.subTasks;
      const categoryId = subtaskKeys[i].categoryId;
      const typeId = subtaskKeys[i].typeId;
      const budgetCurrency =  subtaskKeys[i].budgetCurrency;
      const budget =  subtaskKeys[i].budget;
      //////console.log.log(" categoryId==>"+categoryId," typeId=>"+typeId, " budgetCurrency=>"+budgetCurrency, " values =>", values );
      let errMsg = "";
      if(categoryId!='' && typeId!='' && budget!='' && budgetCurrency!=''){
          otherParam.categoryId = categoryId;
          otherParam.typeId = typeId;
          otherParam.budgetCurrency = budgetCurrency;

          let chkResp = await this.budgetFloorValueCheck(budgetInputValue, otherParam);
          if(chkResp){
              if(chkResp.checkStatus==false){
                  // error
                  errMsg = chkResp.errMsg?chkResp.errMsg:"Something went wrong, please try again.";                    
              }

          }
          
      }else{            
          //error
          errMsg = "Input for either Category of Task or Type of Task or Budget or Budget Currency is missing.";             
      }

      let preErr = [...this.state.errMsgsSubT];
      let currRowErr = {...preErr[i]};
      currRowErr.budgetErrMsg = errMsg;                    

      preErr[i] = currRowErr
      this.isComponentLoaded && this.setState({"errMsgsSubT":preErr});

             
  }

    async budgetFloorValueCheck(budgetInputValue,otherParam){
      // From here
      let qryStrng = otherParam.categoryId+"/"+otherParam.typeId+"?currency="+otherParam.budgetCurrency;
      const respObj = {
          "floorPrice":0,
          "checkStatus": false,
          "errMsg": ""
      }

      let fpservice = new FloorPriceBasedOnCategAndTypeService();        
      await fpservice.getFloorPrice(qryStrng).then(data =>{
          if(data.status==200){
              if(data.data.entity!==undefined){
                  let floorPrice = data.data.entity;
                  if(budgetInputValue>=floorPrice){
                          //ok
                          respObj.floorPrice = floorPrice;
                          respObj.checkStatus = true;
                  }else{
                      // not ok
                      respObj.floorPrice = floorPrice;
                      respObj.checkStatus = false;
                      respObj.errMsg = "Budget cannot be less than floor price "+otherParam.budgetCurrency+" "+floorPrice;
                  }
              }
          }
      }).catch((err)=>{
              let errStatusCodeAr = [400,500]; // allowed error status code
              if(err.response && err.response.data){
                  let erRespData = err.response.data;
                  if(errStatusCodeAr.indexOf(erRespData.status) !== -1){         
                      let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                      // not ok
                      respObj.floorPrice = 0;
                      respObj.checkStatus = false;
                      respObj.errMsg = errMsg;                       
              }
              } 
      });

      return respObj;

      
    }

    // For screen ST screeen 1
    setStatesForSTEdit = (stateKeyName,stateKeyValue, otherParam) =>{
            
        switch(stateKeyName){
            case "uplDocuments":
                if(otherParam.action=="push"){

                    if(otherParam.bulk!==undefined && otherParam.bulk.length>0){
                        let prevDoc = [];
                        let docs = otherParam.bulk;
                        Object.keys(docs).map(function(key, index) { 
                        // convert server response of file size from mb to bytes 
                        let fileSize = (docs[key].fileSize)?(docs[key].fileSize)*1024*1024:0;            
                        prevDoc.push({"id":docs[key].id,"name":docs[key].name,"size":fileSize})
                        });
                        this.isComponentLoaded &&  this.setState({"taskFiles":prevDoc});


                    }else{
                        let prevDoc = [...this.state.taskFiles];
                        let docs = stateKeyValue;
                        Object.keys(docs).map(function(key, index) { 
                        // convert server response of file size from mb to bytes 
                        let fileSize = (docs[key].fileSize)?(docs[key].fileSize)*1024*1024:0;            
                        prevDoc.push({"id":docs[key].id,"name":docs[key].name,"size":fileSize})
                        });
                        this.isComponentLoaded &&  this.setState({"taskFiles":prevDoc});

                    }
                    

              
                }else  if(otherParam.action=="remove"){
                    
                    let valuesData = [...this.state.taskFiles]; 
                    let indx = stateKeyValue;       
                    valuesData.splice(indx,1);        
                    this.isComponentLoaded &&  this.setState({"taskFiles":valuesData});
                }                
                break;   
            
            case "handleChange":
                let inputName = otherParam.inputFieldName;
                let valueData = otherParam.value;

                this.isComponentLoaded &&  this.setState({[inputName]:valueData});
                break;

            case "dueDate":
                let dateData = stateKeyValue;
                this.isComponentLoaded &&  this.setState({"dueDateMillis":dateData});
                break;
            case "timeOffset":
                let offsetDataObj = stateKeyValue;
                this.isComponentLoaded &&  this.setState({"offsetHour": offsetDataObj.hr,
                "offsetMinute": offsetDataObj.min});
                break;

            
            case "budgetCurrency":
                  this.isComponentLoaded && this.setState({"budgetCurrency":stateKeyValue,"budget":""});
                  break;
            case "budget":
                  this.isComponentLoaded && this.setState({"budget":stateKeyValue});
                  break;
            
        }
    }

    updateTask = ()=>{
          let chkVal = this.custValidate();
        
          if(chkVal){

          let offsetOfLocalDateTime = getOffsetOfLocalDateTime(new Date());
          let offsetHr = offsetOfLocalDateTime.hr;
          let offsetMin = offsetOfLocalDateTime.min;

              // hit task update api
          this.isComponentLoaded && this.setState({"continueDisabled":true},()=>{
            this.getUpdateConfirmModalContent();
          });
          let paramObj= {
          "taskName":"",
          "taskDescription":"",
          "dueDate": null,
          "categoryId":"",
          "typeId":"",
          "budget":"",
          "budgetCurrency":"",
          "taskDocIds":[],       
          "offsetHour":null,
          "offsetMinute":null
          };

        
          const {taskId,taskName,description,dueDateMillis,taskCategory,categoryType,budget,budgetCurrency,taskFiles,offsetHour,offsetMinute,subTasks} = this.state;
          
          let taskDocIdsAr = [];
          if(taskFiles.length>0){
              
              Object.keys(taskFiles).map((e,i)=>{
                  taskDocIdsAr.push(taskFiles[e].id);
              });           

          }


          paramObj.taskName = taskName;
          paramObj.taskDescription = description;
          paramObj.dueDate = dueDateMillis;
          paramObj.offsetHour = offsetHour?offsetHour:offsetHr;
          paramObj.offsetMinute = offsetMinute?offsetMinute:offsetMin;
          paramObj.taskDocIds = taskDocIdsAr;

          //paramObj.categoryId = taskCategory.id;
          //paramObj.typeId = categoryType.id;
          //paramObj.budget = budget;
          //paramObj.budgetCurrency = budgetCurrency;

          paramObj.subTasks  = subTasks;
        

        let paramObjData = {
              "taskId" : taskId,
              "data": paramObj
        };

          let stcservice = new TaskUpdateService();
          stcservice.updateTask(paramObjData).then(data =>{
              if(data.status==200){

                  

                  let respData ={};
                  if(data.data.entity!==undefined){
                    

                    let notiMsg = "Updated successfully";
                    notify(notiMsg,true,{
                    "successMsg":1,
                    "customWrapClass":"customerrmidlass",
                    "showCloseButton": false, 

                    "dismiss": {
                    "duration": 1000,
                    }

                    });

                    setTimeout(()=>{
                      this.updateConfirmModalClose();
                      let paramObj1 = {
                        "data":{
                          "taskId":this.props.values.taskId
                        }
                      };
                      this.getTaskDetailData(paramObj1);
                      
                    
                    }, 1250);  

                                 

                                        
                    
              }
          }
          }).catch((err)=>{
                  let errStatusCodeAr = [400,500]; // allowed error status code
                  if(err.response && err.response.data){
                      let erRespData = err.response.data;
                      if(errStatusCodeAr.indexOf(erRespData.status) !== -1){         
                          let errMsg = (erRespData.message)?erRespData.message:"Some technical problem occurred.";
                          //console.log.log(" errMsg on task creation==>",errMsg);

                          let notiMsg = errMsg;
                          notify(notiMsg,true,{
                          "successMsg":0,
                          "customWrapClass":"customerrmidlass",
                          "showCloseButton": false, 

                          "dismiss": {
                          "duration": 2500,
                          }

                          }); 
                                          
                  }
                  } 
          }).finally(()=>{
              this.isComponentLoaded &&  this.setState({"continueDisabled":false},()=>{
                this.getUpdateConfirmModalContent();
              });
          });

          }else{
            this.isComponentLoaded && this.setState({"continueDisabled":false});
          }

    }

    handleChange( i, event) {
      let custpropforkeyData = event.target.attributes.getNamedItem('custpropforkey').value;

      let otherParam = {};
      let setSubTaskKeyData = {"keyIndex":i,"keyName":custpropforkeyData,"keyValue":event.target.value};   
      let caseKeyword = "setSubTaskKeyData";  
      this.setSubTaskKeyData(caseKeyword,setSubTaskKeyData, otherParam);
      if(custpropforkeyData=="budgetCurrency"){
            // this.budgetInputRef.current.focus();   
            // set blank for budget
            setTimeout(()=>{
              let otherParam2 = {};
              let setSubTaskKeyData = {"keyIndex":i,"keyName":"budget","keyValue":""};   
              let caseKeyword = "setSubTaskKeyData";  
              this.setSubTaskKeyData(caseKeyword,setSubTaskKeyData, otherParam2);   
            },1200);
                   
            // now set focus to budget input
            this[`budgetSubTRef${i}`].focus();           
      }
      
    }

  

    loadSubTasks =  ()=>{
        
      let subTasksData = this.state.subTasks;
      let subTasksDataAr = [];
       Object.keys(subTasksData).map((e,i)=>{

        let assignedTo = subTasksData[e].assignedTo;
          let assignedToName = "None";
          if(assignedTo!==undefined && assignedTo!==null){
            let fNm = assignedTo.firstName!==undefined && assignedTo.firstName!==null?assignedTo.firstName:"";
            let lNm = assignedTo.lastName!==undefined && assignedTo.lastName!==null?assignedTo.lastName:"";
            assignedToName = fNm+" "+lNm;

          }

        subTasksDataAr.push(
        <div key={i} className="enter_task_box slidetask_subbox_bg">
        <div className="task_title_head text-right">
        {subTasksData[e].id  && ( <h5>Sub-Task No. {subTasksData[e].id}</h5> )}
        {subTasksData[e].id===undefined  && ( <h5>&nbsp;</h5> )}
          <button onClick={(e)=>{
            e.preventDefault();
            let objParam={"id":i};
            this.subTaskRemovalConfirmModalOpen(objParam);
            //this.removeClick(i);
            }} className="btn_appearance_none subtask_cross_btn">

            <img src={getBaseUrl()+"assets/images/delete.png"} />
          </button>
        </div>
        <div className="main_form_box">
          <div className="form_block">
            <div className={`form__group field ${ this.state.errMsgsSubT[i] && this.state.errMsgsSubT[i].subTaskNameErrMsg?"form_group_error": ""}` }>
              <label htmlFor="name" className="form__label">
                <b>Sub-task Name</b>
              </label>
              <div className="form__group field">
                <input type="input"
             className="form__field"
             placeholder="Enter sub-task Name"
             name="name"
             onChange={this.handleChange.bind(this, i)}
             custpropforkey="name"
             value={subTasksData[i].name} />
             <span className="error_span">
           {this.state.errMsgsSubT[i] && this.state.errMsgsSubT[i].subTaskNameErrMsg}
           </span>
                {/* <div className="input_datashow">
                  <p>{subTasksData[e].name}</p>
                </div> */}
              </div>
            </div>
          </div>
          <div className="form_block form_block_half">
            <div className="panel50 panel_half paddingright15 form__group_normar">
              <label htmlFor="name" className="form__label">
                <b>Category of Task</b> &nbsp;
              </label>
              <div className={`form__group field ${ this.state.errMsgsSubT[i] && this.state.errMsgsSubT[i].categoryIdErrMsg?"form_group_error": ""}` }>
              <select
                  className="selectpicker dropup form__field"
                  data-header="Organisation Size"
                  disabled={this.state.categoryOptions.length>0?false:true}
                  value={subTasksData[e].categoryId?subTasksData[e].categoryId:""} 
                  onChange={(e)=>{this.categoryOptionChangeTrack(i,(e.target.value))}}
                  custpropforkey="categoryId"
                  >
                  <option  value="">Any</option>
                  {Object.keys(this.state.categoryOptions).map((e,i)=> <option key={i}  value={this.state.categoryOptions[e].id}>{this.state.categoryOptions[e].category}</option>) }


                  </select>
                  <span className="error_span">
                  
                  {this.state.errMsgsSubT[i] && this.state.errMsgsSubT[i].categoryIdErrMsg}
                  </span>
                {/* <div className="input_datashow">
                  <p></p>
                </div> */}
              </div>
            </div>
            <div className="panel50 panel_half paddingleft15 form__group_normar">
              <label htmlFor="name" className="form__label">
                <b>Type of Task</b>
                &nbsp;
              </label>
              <div className={`form__group field ${ this.state.errMsgsSubT[i] && this.state.errMsgsSubT[i].typeIdErrMsg?"form_group_error": ""}` }>
                
                  <select
                          className="selectpicker dropup form__field"
                          data-header="Organisation Size"
                          disabled={subTasksData[i].typeIdOptions.length>0?false:true}
                          value={subTasksData[i].typeId} 
                          onChange={(e)=>{this.typeOfCategOptionChangeTrack(i,(e.target.value))}}

                          >
                          <option  value="">Any</option>                      
                          {subTasksData[i].typeIdOptions.map((e,i)=> <option key={i}  value={e.id}>{e.categoryType}</option>) }
                        </select>
                        <span className="error_span">{this.state.errMsgsSubT[i] && this.state.errMsgsSubT[i].typeIdErrMsg}</span>
                {/* <div className="input_datashow">
                <p></p>
                </div> */}
              </div>
            </div>
            <div className="clearboth" />
          </div>
          <div className="form_block form_block_half">
            <div className="panel50 panel_half paddingright15 form__group_normar">
              <label htmlFor="name" className="form__label">
                <b>Budget</b>
              </label>
              <div className="form__group field">
                {/* <div className="input_datashow">
                <p>{subTasksData[e].budget && subTasksData[e].budgetCurrency?(subTasksData[e].budgetCurrency+" "+subTasksData[e].budget):""}</p>
                </div> */}
                
                <div className={`price_input_grup_bg  ${ this.state.errMsgsSubT[i] && this.state.errMsgsSubT[i].budgetErrMsg?"form_group_error":""}`}>
                                            <div className="price_input_grup">
                                                <div className="price_input_grup_left">
                                                <input id={"budget-subt-"+i} custpropforkey="budget"
                                                ref={input => {
                                                  this[`budgetSubTRef${i}`] = input;
                                                }}
                                        
                                                onChange={this.handleChange.bind(this, i)} value={subTasksData[i].budget} onBlur={(event)=>{this.budgetOnBlurTrack(event,i)}} 
                                                type="text" placeholder="Budget" />
                                                </div>
                                                <div className="price_input_grup_right">
                                                <select 
                                                ref={input => {
                                                  this[`budgetCurrSubTRef${i}`] = input;
                                                }}

                                                custpropforkey="budgetCurrency"  value={subTasksData[i].budgetCurrency} 
                                            onChange={this.handleChange.bind(this, i)}  >
                                                {Object.keys(currencyStaticData).map((e,i)=> <option key={i}  value={currencyStaticData[e].key}>{currencyStaticData[e].value}</option>) }
                                                </select>
                                                </div>
                                            </div>
                                            <span className="error_span">
                                                {/* Please enter a minimum budget of INR 40000 for this Task. */}
                                                {this.state.errMsgsSubT[i] && this.state.errMsgsSubT[i].budgetErrMsg}
                                            </span>
                </div>
                
              </div>
            </div>
            <div className="panel50 panel_half paddingleft15 form__group_normar">
              <label htmlFor="name" className="form__label">
                <b>Assign to</b>
              </label>
              <div className="form__group field">
                <div className="form__group field">
                  <div className="input_datashow">
                       <p className={`${assignedToName=="None"?"":"assTo_green"}`}>{assignedToName}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="clearboth" />
          </div>
        </div>
        {/* === enter task box end ===  */}
      </div>
      
       )
                                              });
    
        return <>{subTasksDataAr}</>;
    
     }
  
    loadTheContent = ()=>{
      let forSplitScrnCls2Inject = "";
      //********* for context starts *****
      let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;      
      if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){         

          if(sharedData.actionType=="SPLITSCREEN" ){            
            forSplitScrnCls2Inject = "margin0"
          }
      }
      //********* for context ends *****

      //console.log(" this.state===>",this.state);
      let practiceAreas = this.state.practiceAreas;
      let practiceAreasMoreTxt = ""; let practiceAreaName = "";
      
      if(practiceAreas && (practiceAreas.length ==1)){
        practiceAreaName = practiceAreas[0]?practiceAreas[0].practiceArea:"";
        

      }
      else if(practiceAreas && practiceAreas.length>1){
        let practiceAreasCnt = practiceAreas.length;
        practiceAreasCnt =  practiceAreasCnt-1;
        practiceAreasMoreTxt = "+"+practiceAreasCnt+" more";

        practiceAreaName = practiceAreas[0]?practiceAreas[0].practiceArea:"";
      }

      let locations = this.state.locations;
      let locationsMoreTxt = ""; let locationsName = "";
      
      if(locations && (locations.length ==1)){
        locationsName = locations[0]?locations[0].city:"";
        

      }
      else if(locations && locations.length>1){
        let locCnt = locations.length;
        locCnt =  locCnt-1;
        locationsMoreTxt = locCnt+" more";

        locationsName = locations[0]?locations[0].city:"";
      }

      let assignedTo = this.state.assignedTo;
      let assignedToName = assignedTo?"none":"none";

      let budgetData = "";
      let budget = this.state.budget?this.state.budget: "";
      let budgetCurrency = this.state.budgetCurrency?this.state.budgetCurrency: "";

      if(budget && budgetCurrency){
        budgetData = budgetCurrency+" "+budget;
      }

      let taskFiles = this.state.taskFiles;
      let taskFilesCnt = 0;

      if(taskFiles){
        taskFilesCnt = taskFiles?taskFiles.length:0;
      }
      

      return (
        <>          
        { /* Dashboard Slide Split Right start  */   }
        <div className={`splitscreen_area 
              ${forSplitScrnCls2Inject?forSplitScrnCls2Inject:""}`
                }>
                <div className="slideScreen_bg">
                  <SplitScreenLeftMenu />
                  <div className="slideScreen_rightbg flexbox-parent">
                    {/* === right slide section top start ===  */}
                    <div className="SlideS_topbg flexbox-item">
                      <div className="slide_top_titlebg">
                        <h4>Task Details</h4>
                        <button onClick={(evn)=>{
                        
                        evn.preventDefault();
                        //this.closeSplit();
                        commonnotitrack("CLOSE_SPLITSCREEN",{
                          "notiType": "CLOSE_SPLITSCREEN",
                          "triggerCallback":false,
                          "closeSplitCalledFrom":"SINGLE_TASK_WITHSUBTASK_DETAILS_EDIT"
                         });
                  
                    }} className="btn_appearance_none slideTop_cross_btn cross">
                          <img src={getBaseUrl()+"assets/images/red_cross.png"}  />
                        </button>
                      </div>
                    </div>
                    {/* === right slide section top end ===  */}
                    {/* === right slide section Middlie  start ===  */}
                    <div className="SlideS_Midbg flexbox-item-grow">
                      
                      <div className="s_midbox_scroll_adjust mCustomScrollbar  reactTestScroll reactTestScroll_multi  height_800">
                        <Scrollbars 
                              ref={this.scrollerRef}
                              style={{ height: 775 }}                  
                              autoHeightMin={775}
                              renderView={props => <div {...props} className="view taskdetailsbtskscrollcustomclass"/>}

                              onScrollStop={this.trackScroll}
                          >
                          <div className="s_midbox_section">
                          <div className="s_midbox_title">
                            {/* <h5>Task Number - CAT0005</h5> */}
                          </div>
                          <div className="s_midbox">
                            {/* ====  slidebox start ====  */}
                            <div className="scroll_maintask_bg">
                              <div className="enter_task_box sub_task_addbg">
                                {/* === enter task box start ===  */}
                                <div className="main_form_box">
                                  <div className="form_block form_block_half">
                                    <div className="panel50 panel_half paddingright15 form__group_normar">
                                      <label htmlFor="name" className="form__label">
                                        <b>Task Name</b>
                                      </label>
                                      <div className="form__group field">
                                        <div className="input_datashow">
                                          <p>{this.state.taskName}</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="clearboth" />
                                  </div>
                                  <div className="form_block form_block_half">
                                    <div className="panel50 panel_half paddingright15 form__group_normar">
                                      <label htmlFor="name" className="form__label">
                                        <b>Practice area</b>
                                      </label>
                                      <div className="form__group field">
                                        <div className="form__group field">
                                          <div className="input_datashow">
                                            { practiceAreaName && (
                                            <span className="dataShow_green">
                                                  {practiceAreaName}
                                            </span>
                                            )}
                                            <span className="dataShow_more">{practiceAreasMoreTxt}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="clearboth" />
                                  </div>
                                  {/* <div className="form_block form_block_half">
                                    <div className="panel50 panel_half paddingright15 form__group_normar">
                                      <label htmlFor="name" className="form__label">
                                        <b>Category of task</b>
                                      </label>
                                      <div className="form__group field">
                                        <div className="input_datashow">
                                          <p>{this.state.taskCategory && this.state.taskCategory.category?this.state.taskCategory.category: "" }
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="panel50 panel_half paddingleft15 form__group_normar">
                                      <label htmlFor="name" className="form__label">
                                        <b>Type of task</b>
                                      </label>
                                      <div className="form__group field">
                                        <div className="form__group field">
                                          <div className="input_datashow">
                                            <p>{this.state.categoryType && this.state.categoryType.categoryType?this.state.categoryType.categoryType: "" }
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="clearboth" />
                                  </div>
                                   */}
                                  <div className="form_block form_block_half">
                                    <div className=" panel_half form__group_normar">
                                      <label htmlFor="name" className="form__label">
                                        <b>Task description</b>
                                      </label>
                                      <div className="form__group field">
                                        <div className="input_datashow">
                                          <p>{this.state.description}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="clearboth" />
                                  </div>
                                  <div className="form_block form_block_half">
                                    <div className="panel50 panel_half paddingright15 form__group_normar">
                                      <label htmlFor="name" className="form__label">
                                        <b>Due date</b>
                                      </label>
                                      <div className="form__group field">
                                        <div className="input_datashow">
                                          <p>{this.state.dueDateMillis ?CustDate.custFormatDate((new Date(this.state.dueDateMillis)),3): "None"}</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="clearboth" />
                                  </div>
                                </div>
                                {/* === enter task box end ===  */}
                                {/* === enter sub task box start ===  */}
                                <div className="scroll_subtask_bg splite_scroll_subtask_bg">
                                 
                                {/*previous db records of sub task */}
                                  {this.loadSubTasks()}
                                {/* just created sub task in ui */}
                                 
                                  
                                  <div className="taskadd_btnbg">
                                    <button onClick={(e)=>{
                                      e.preventDefault();
                                      this.addClick();
                                    }} className="btn_appearance_none add_subtask_btn  margin_bottom10">
                                      <span>Add another Sub-task</span>
                                    </button>
                                  </div>
                                
                               
                                
                                </div>
                                {/* === enter sub task box end ===  */}
                              </div>
                            </div>
                            {/* ====  slidebox end ====  */}
                          </div>
                        </div>
                        
                        </Scrollbars>
                      </div>
                      
                    </div>
                    {/* === right slide section Middlie  end ===  */}
                    {/* === right slide section Footer  start ===  */}
                    <div className="SlideS_bottombg flexbox-item ">
                      <div className="s_footer_btnbg">
                        <button onClick={(ev)=>{ev.preventDefault();
                        setTimeout(()=>{
                          this.hitUpdateApi();
                        },100);
                          
                        }} className="btn_appearance_none sub_grd_btn">
                          <span>Update</span>
                        </button>
                      </div>
                    </div>
                    {/* === right slide section Footer  end ===  */}
                  </div>
                </div>
              </div>

        { /* Dashboard Slide Split Right end  */ }
        
        </>
      );
    }


    trackFileSelection = e =>{
      e.preventDefault();
      let fileObj = e.target.files;    
      
      let chkFileValidation =  this.validateFileSelected(fileObj);
      
      //this.calUploadFileService(fileObj); 
      if(chkFileValidation){
          this.calUploadFileService(fileObj);
      }else{
        this.fileUpl.current.value = "";
      }    
    }

    validateFileSelected(fileObj){
      const maxFileUploadAllowed = 5;
      const totAllowedMaxFileUploadSize = 500 * 1024 * 1024; // 500 MB
      const allowedFileExt = ['jpg','jpeg','png','doc','docx','txt','pdf'];
      
      const taskFiles = this.state.taskFiles;        
      const docsData = taskFiles;
      let respdataFlag =  true;
      let stepOneStFileUplErMsg = "";
      if(docsData!==undefined){
          if(docsData.length==maxFileUploadAllowed){
              //error
              respdataFlag = false;
              stepOneStFileUplErMsg = "Sorry you cannot upload more than "+maxFileUploadAllowed+"  files in whole.";
              
          }else{
              if(docsData.length < maxFileUploadAllowed){
                  let nowtotAllowedCntOfFileUpl = maxFileUploadAllowed - docsData.length;
                  if(fileObj.length <= nowtotAllowedCntOfFileUpl){
                      // check file validations like size and extension
                      let totPrevSize  = 0; 
                      Object.keys(docsData).map((k,i)=>{
                          totPrevSize = totPrevSize + docsData[k].size;                 
                      });
                      ////console.log.log("Total attempted size=>",totSize);

                      let totCurSize  = 0; let totalInvalidExtAr = [];
                      Object.keys(fileObj).map((k,i)=>{
                          totCurSize = totCurSize + fileObj[k].size; 
                          let nameFl = fileObj[k].name;
                          let extnReceived = nameFl.split('.').pop();
                          if(allowedFileExt.indexOf(extnReceived)==-1){
                              totalInvalidExtAr.push(extnReceived);
                          }  
                      });

                      ////console.log.log("chk size=>> totPrevSize=>"+totPrevSize+" totCurSize=>"+totCurSize, "Totl size now=>>"+(totCurSize + totPrevSize), " totAllowedMaxFileUploadSize=>>>"+totAllowedMaxFileUploadSize );
                      if( (totCurSize + totPrevSize) > totAllowedMaxFileUploadSize){
                          //error
                          respdataFlag = false;
                          stepOneStFileUplErMsg = "You have crossed total maximum upload file size.";                            
                      }

                      /*
                      if(totalInvalidExtAr.length>0){
                          //console.log.log("totalInvalidExtAr=>",totalInvalidExtAr);
                          //error
                          respdataFlag = false;
                      }
                      */

                  }else{
                      //error
                      respdataFlag = false;
                      stepOneStFileUplErMsg = "You cannot upload more than "+maxFileUploadAllowed+" files in total (It includes previously uploaded files if any).";
                      
                  }
  
              }
          }
      }else{
          respdataFlag = false;
          stepOneStFileUplErMsg = "Something went wrong. Please try again.";
      }   

      this.isComponentLoaded && this.setState({ "fileUplErMsg": stepOneStFileUplErMsg});
      return respdataFlag;
    }

    async calUploadFileService(fileObj){
        ////console.log.log("chk file fileObj=>>>",fileObj);
        let lserv = new FileUploadService();
        this.isComponentLoaded && this.setState({"uploadingFlag":true});

        
        let taskTypeFlag = 0;

        let paramObjData = {
            "taskTypeFlag" : taskTypeFlag,
            "data": fileObj
      };

        lserv.uploadFiletoServer(paramObjData).then(data =>{
            //this.setStepOneStDocuments();
            //console.log.log("success resp=>",data);
            let entityObj  = data.data.entity;       

            let otherParam = {"action":"push"};
            this.setStatesForSTEdit("uplDocuments",entityObj,otherParam);


        }).catch((e)=>{
                //console.log.log("server error=>",e);
        }).finally(()=>{
            this.fileUpl.current.value = "";  
            this.isComponentLoaded && this.setState({"uploadingFlag":false});
        });
    }

    removeDocOnClick(i){               
      let otherParam = {"action":"remove"};
      this.setStatesForSTEdit("uplDocuments",i,otherParam);
    }

    //**** Sub task related tricky work  dependant methods starts */
    async addClick(){
      

      let otherParam = {};
      this.setStatesForSubTask("addSubTaskEntry",defaultSubTaskKeys,otherParam);      

    }

    removeClick(i){ 
    
    let errMsgsData = [...this.state.errMsgsSubT];      
    errMsgsData.splice(i,1);
    
    this.isComponentLoaded && this.setState({ "errMsgsSubT":errMsgsData },()=>{
      let otherParam = {};
      this.setStatesForSubTask("removeSubTaskEntry",i,otherParam);
    });     

    }

    categoryOptionChangeTrack = (i,optnVal) =>{
      let categoryIdValue = optnVal;//e.target.value;
      // this.props.setStatesForStep2ofST("categoryId",categoryIdValue);
      // ////console.log.log("categoryIdValue=>",categoryIdValue);

      let otherParam = {};
      let setSubTaskKeyData = {"keyIndex":i,"keyName":"categoryId","keyValue":categoryIdValue};   
      let caseKeyword = "setSubTaskKeyData";  this.setSubTaskKeyData(caseKeyword,setSubTaskKeyData, otherParam);


      // call service api to set type ids based on category 
      let setPrevSel=false;
      this.getTypesIdsFromCategoryService(categoryIdValue, setPrevSel, i);
  }

  typeOfCategOptionChangeTrack = (i,optnVal) =>{
      // let typeIdValue = optnVal;
      // this.props.setStatesForStep2ofST("typeId",typeIdValue); 
      
      let otherParam = {};
      let setSubTaskKeyData = {"keyIndex":i,"keyName":"typeId","keyValue":optnVal};   
      let caseKeyword = "setSubTaskKeyData";  this.setSubTaskKeyData(caseKeyword,setSubTaskKeyData, otherParam);


  }

     // Dynamically added structure

    setSubTaskKeyData(caseKeyword,setSubTaskKeyData, otherParam){     
      //console.log(" pass to set setSubTaskKeyData==>",setSubTaskKeyData); 
      this.setStatesForSubTask(caseKeyword,setSubTaskKeyData,otherParam);
    }

    setStatesForSubTask = (stateKeyName,stateKeyValue, otherParam) =>{      
              
            switch(stateKeyName){
               
              case "addSubTaskEntry":
                this.isComponentLoaded &&  this.setState(prevState => ({ subTasks: [...prevState.subTasks, stateKeyValue]}),()=>{
                          
                });
                break;
            case "removeSubTaskEntry":
                
                let valuesData = [...this.state.subTasks];
                valuesData.splice(stateKeyValue,1);
                this.isComponentLoaded && this.setState({"subTasks": valuesData },()=>{
                });
                break;
            case "setSubTaskKeyData": 
                if(otherParam.bulk!==undefined && otherParam.bulk.length>0){  
                
                    this.isComponentLoaded && this.setState({ "subTasks":otherParam.bulk },()=>{
                        
                    });

                }
                else{
                    // 1. Make a shallow copy of the items
                    let items = [...this.state.subTasks];
                    // 2. Make a shallow copy of the item you want to mutate
                    let item = {...items[stateKeyValue.keyIndex]};                
                    // 3. Replace the property you're intested in                
                    if(stateKeyValue.keyName=="name"){
                        item.name = stateKeyValue.keyValue;
                    }

                    if(stateKeyValue.keyName=="categoryId"){
                        item.categoryId = stateKeyValue.keyValue;
                    }

                    if(stateKeyValue.keyName=="typeIdOptions"){                       
                        item.typeIdOptions = stateKeyValue.keyValue;
                    }

                    if(stateKeyValue.keyName=="typeId"){
                        item.typeId = stateKeyValue.keyValue;
                    }

                    if(stateKeyValue.keyName=="budget"){
                        item.budget = stateKeyValue.keyValue;
                    }

                    if(stateKeyValue.keyName=="budgetCurrency"){
                        item.budgetCurrency = stateKeyValue.keyValue;
                        //console.log(" item.budgetCurrency==>",item.budgetCurrency,stateKeyValue.keyValue)
                    }

                    //console.log(" items==>>", items," stateKeyValue.keyIndex=>",stateKeyValue.keyIndex," item=>",item);

                    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
                    items[stateKeyValue.keyIndex] = item;
                        
                    this.isComponentLoaded && this.setState({ "subTasks":items },()=>{
                        //console.log(" Now state is=>", this.state.subtaskKeys);
                    });
                }
                break;
            case "categoryOptionsSubTStatic":
                this.isComponentLoaded && this.setState({ "categoryOptionsSubTStatic":stateKeyValue },()=>{
                });
                break;
            case "resetSubTaskEntry":                
                this.isComponentLoaded &&  this.setState({ subTasks: [otherParam.freshEntry]},()=>{       
                });
                break;


            }
    }

    //**** Sub task related tricky work  dependant methods ends */


    /*** update Confirm Modal  starts  */
    /**
     * SplitScreenSingleTaskWithSubTaskDetailsEdit
     * Function name : updateConfirmModalOpen
     * Author        :
     * Created Date  : 29-3-2020
     * Purpose       : To open  modal
     * Params        : 
    **/  
    updateConfirmModalOpen = (otherParam)=>{    
      this.isComponentLoaded &&  this.setState({showUpdateConfirmModal:true},()=>{
        this.modalforcmfrmupdtRef.current.showModal();
        this.getUpdateConfirmModalContent();
      });    
        
    }

    /**
     * SplitScreenSingleTaskWithSubTaskDetailsEdit
     * Function name : updateConfirmModalClose
     * Author        :
     * Created Date  : 11-2-2020
     * Purpose       : To close Modal
     * Params        : 
    **/
    updateConfirmModalClose = ()=>{    
      this.isComponentLoaded && this.setState({showUpdateConfirmModal:false},()=>{
        this.modalforcmfrmupdtRef.current.hideModal();
      });
      }

    /**
     * SplitScreenSingleTaskWithSubTaskDetailsEdit
     * Function name : getUpdateConfirmModalContent
     * Author        :
     * Created Date  : 11-2-2020
     * Purpose       : Get modal view with data
     * Params        : 
    **/
    getUpdateConfirmModalContent() {   
        const {cancelButtonAction} = this.props;

        let viewData = <div className="dash_modal">
        
                <>
            <div className="modal-header">
                <h4 className="modal-title modal_title_tool"> Edit Task
                {/*<div className="input_tooltip_bg">
                        <span className="input_tooltip" aria-label="Organisation's Details" data-balloon-pos="down">
                        <i className="fa fa-question-circle"></i>
                        </span>
                        </div>*/}
                </h4>
                <button type="button" onClick={
                    ()=>{
                    this.updateConfirmModalClose();

                }} className="btn_appearance_none slideTop_cross_btn cross" data-dismiss="modal">
                <img src={getBaseUrl()+"assets/images/red_cross.png"} />
            </button>
            </div>
            <CustomMessageBar />            
            <div className="modal-body padding_top">
            
              <div className="row">
                  Notify all professionals about change in task details?                 

              </div>
            </div>
            <div className="modal-footer">
               
                <button disabled={this.state.continueDisabled}  type="button" className="btn btn-primary modal_btn_save" onClick={(e)=>{ e.preventDefault();
                  
              this.isComponentLoaded && this.setState({"continueDisabled":true},()=>{
                    
                this.updateTask();
                    

                   });
                   
                    

                } } data-dismiss="modal"><span>Confirm</span></button>
                
            </div>
          
          
          </>
        
                        
        </div>;
    
        this.modalforcmfrmupdtRef.current.updateStateContent(viewData);
        this.modalforcmfrmupdtRef.current.showContent();
        return viewData;
    }    

    /*** update Confirm Modal ends  */


    /*** Subtask removal Confirm  Modal  starts  */
    /**
     * SplitScreenSingleTaskWithSubTaskDetailsEdit
     * Function name : subTaskRemovalConfirmModalOpen
     * Author        :
     * Created Date  : 29-3-2020
     * Purpose       : To open  modal
     * Params        : 
    **/  
   subTaskRemovalConfirmModalOpen = (otherParam)=>{    
    this.isComponentLoaded &&  this.setState({showSTRConfirmModal:true},()=>{
      this.modalforsubtskremvRef.current.showModal();
      this.getSubTaskRemovalConfirmModalContent(otherParam);
    });    
      
  }

  /**
   * SplitScreenSingleTaskWithSubTaskDetailsEdit
   * Function name : subTaskRemovalModalClose
   * Author        :
   * Created Date  : 11-2-2020
   * Purpose       : To close modal
   * Params        : 
  **/
  subTaskRemovalConfirmModalClose = ()=>{    
    this.isComponentLoaded && this.setState({showSTRConfirmModal:false},()=>{
      this.modalforsubtskremvRef.current.hideModal();
    });
    }

  /**
   * SplitScreenSingleTaskWithSubTaskDetailsEdit
   * Function name : getOrganisationDetailModalOpen
   * Author        :
   * Created Date  : 11-2-2020
   * Purpose       : Get modal view with data
   * Params        : 
  **/
  getSubTaskRemovalConfirmModalContent(otherParam) {   
      let indxData = otherParam.id;
      
      let viewData = <div className="dash_modal">
      
              <>
          <div className="modal-header">
              <h4 className="modal-title modal_title_tool"> Sub Task Delete
              
              </h4>
              <button type="button" onClick={
                  ()=>{
                  this.subTaskRemovalConfirmModalClose();

              }} className="btn_appearance_none slideTop_cross_btn cross" data-dismiss="modal">
              <img src={getBaseUrl()+"assets/images/red_cross.png"} />
          </button>
          </div>
          {/* <CustomMessageBar />             */}
          <div className="modal-body padding_top">
          
            <div className="row">
                Confirm delete?                 

            </div>
          </div>
          <div className="modal-footer">
             
              <button disabled={this.state.continueDisabled}  type="button" className="btn btn-primary modal_btn_save" onClick={(e)=>{ e.preventDefault();
              
              this.removeClick(indxData);
              this.subTaskRemovalConfirmModalClose();     
                  

              } } data-dismiss="modal"><span>Confirm</span></button>
              
          </div>
        
        
        </>
      
                      
      </div>;
  
      this.modalforsubtskremvRef.current.updateStateContent(viewData);
      this.modalforsubtskremvRef.current.showContent();
      return viewData;
  }    

  /***  Subtask removal Confirm Modal ends  */

  trackNotiForCloseSplit = (otherParam) =>{
     
    let notiType =  otherParam.notiType!==undefined? otherParam.notiType: "";
    let closeSplitCalledFrom =  otherParam.closeSplitCalledFrom!==undefined? otherParam.closeSplitCalledFrom: ""; 
    if(notiType=="CLOSE_SPLITSCREEN"){
        let closeSplitHeaderCallFrm = ["SINGLE_TASK_WITHSUBTASK_DETAILS_EDIT"];
        if(closeSplitHeaderCallFrm.indexOf(closeSplitCalledFrom)!=-1){
                console.log("*******closeSplitCalledFrom=>>",closeSplitCalledFrom);
        }


    }
  }
    
    /**  
     * SplitScreenSingleTaskDetails
     * Function name : render
     * Author        :
     * Created Date  : 7-2-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() { 

      return (
          <React.Fragment>
            <NotiEmitTrack callbackfuncforcommontrackofnoti={this.trackNotiForCloseSplit} />
            
          {this.loadTheContent()}
          
          {/* Confirm delete modal start */}
          <CustReactBootstrapModal ref={this.modalforcmfrmupdtRef}  backdrop={false} backdropClassName="custom_modal_class"   animation={false} show={this.state.showUpdateConfirmModal} onShow={()=>{}} onHide={()=>{}} modalcustdialog="custom_modal_lg"  loaderbodytitle="Organisation"  />
          {/* Confirm delete modal end */}

          {/* Confirm delete modal for subtask  start */}
          <CustReactBootstrapModal ref={this.modalforsubtskremvRef}  backdrop={false} backdropClassName="custom_modal_class"   animation={false} show={this.state.showSTRConfirmModal} onShow={()=>{}} onHide={()=>{}} modalcustdialog="custom_modal_lg"  loaderbodytitle="Organisation"  />
          {/* Confirm delete modal for subtask end */}


          
          </React.Fragment>
      );
    }
}
export default SplitScreenSingleTaskWithSubTaskDetailsEdit;

