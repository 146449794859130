/**
 * SplitScreenSingleTaskWithSubTaskDetailsEdit
 * Page/Class name   : MasterLayout
 * Author            :
 * Created Date      : 7-2-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : render master component
 **/
import React, { Component } from "react";

import { commonJsFuncModule as commonJsObj } from "../../../common_utilities/commonjsfunc";
import { commonStaticOptionsJsFuncModule as commonStaticJsObj } from "../../../common_utilities/commonstaticoptions";
// Modal
import CustReactBootstrapModal from "./CustReactBootstrapModal";

// services
import FileUploadService from "../../service/FileUploadService";
import FloorPriceBasedOnCategAndTypeService from "../../service/FloorPriceBasedOnCategAndTypeService";
import TaskUpdateService from "../../service/TaskUpdateService";
import TaskDetailsService from "../../service/TaskDetailsService";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// for context
import CommonContext from "../../context/CommonContext";

import { notify } from "../../messagebar/CustomMessageBar";
import CustomMessageBar from "../../messagebar/CustomMessageBar";

import CustDate from "../../../common_utilities/CustDate";

import SplitScreenLeftMenu from "../../share/SplitScreenLeftMenu";

// Newest1 - Start
import ProfileNewMod from "./ProfileNewMod";
// Newest1 - End

// common noti track
import NotiEmitTrack from "./NotiEmitTrack";
import { commonnotitrack } from "./NotiEmitTrack";

// scroll
import { Scrollbars } from "react-custom-scrollbars";

const getDateObjFromMilliseconds = commonJsObj.getDateObjFromMilliseconds;
const getTimestampInMillisecondsFromDateObj =
  commonJsObj.getTimestampInMillisecondsFromDateObj;
const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;
const getBaseUrl = commonJsObj.getBaseUrl;

const getStringInitials = commonJsObj.getStringInitials;
const getDateFormatForCal = commonJsObj.getDateFormatForCal;
const validatePositiveInputNumber = commonJsObj.validatePositiveInputNumber;

const getStaticCurrencies = commonStaticJsObj.getStaticCurrencies;
const getCommonAllowedFileExt = commonStaticJsObj.getCommonAllowedFileExt;

// const currencyStaticData = [
//     {"key":"INR","value":"INR"},
//     {"key":"EUR","value":"Pound"},
//     {"key":"USD","value":"Dollar"}
// ];
const currencyStaticData = getStaticCurrencies();

const ExampleCustomInput = ({ value, onClick }) => (
  <input
    type="text"
    className="form__field customdatefld"
    placeholder="Task Due Date (Optional)"
    value={value}
    onClick={onClick}
  />
);

const stateStaticData = {
  taskId: "",
  taskName: "",
  description: "",
  practiceAreas: [],
  locations: [],
  taskCategory: null,
  categoryType: null,
  budgetCurrency: "",
  budget: "",
  assignedTo: null,
  dueDateMillis: null,
  taskFiles: [],
  taskFilesOrig: [],
  offsetHour: null,
  offsetMinute: null,

  uploadingFlag: false,
  // for continue button
  continueDisabled: false,
  taskFilesCnt: 0,

  //For error messages
  budgetCurrencyErrMsg: "",
  budgetErrMsg: "",
  descriptionErMsg: "",
  dueDateErMsg: "",
  fileUplErMsg: "",

  // for update confirm modal
  showUpdateConfirmModal: false,

  //to show editted tag & msg
  isUnApprovedEditPresent: false,
  taskEditStatusDisplayMessage: "",
  editedDescription: "",
  editedBudgetCurrency: "",
  editedBudget: "",
  editedDueDateMillis: "",
  latestActiveUnapprovedEditDate: "",

  editedDescriptionInit: "",
  editedBudgetCurrencyInit: "",
  editedBudgetInit: "",
  editedDueDateMillisInit: "",

  descriptionInit: "",
  budgetCurrencyInit: "",
  budgetInit: "",
  dueDateMillisInit: null,
  updateSuccessRcvdFlag: false,

  // Newest1 - Start
  showPVDisclaimerModal: false,
  showPVDisplayModal: false,
  // Newest1 - End
};

class SplitScreenSingleTaskDetailsEdit extends Component {
  isComponentLoaded = false;
  // for context
  static contextType = CommonContext;

  /**
   * SplitScreenSingleTaskWithSubTaskDetailsEdit
   * Function name : constructor
   * Author        :
   * Created Date  : 7-2-2020
   * Purpose       : initializing children
   * Params        : props
   **/
  constructor(props) {
    super(props);
    this.children = props.children;

    this.state = stateStaticData;
    this.budgetInputRef = React.createRef();
    this.fileUpl = React.createRef();
    this.modalforcmfrmupdtRef = React.createRef();

    // Newest1 - Start
    this.modalforpvdisclaimerRef = React.createRef();
    this.modalforpvdisplayRef = React.createRef();
    // Newest1 - End
  }

  /**
   * SplitScreenSingleTaskWithSubTaskDetailsEdit
   * Function name : componentDidMount
   * Author        :
   * Created Date  : 7-2-2020
   * Purpose       : lifecycle method
   * Params        :
   **/
  componentDidMount() {
    this.isComponentLoaded = true;
    //console.log(" split screen single task loaded......");

    //********* for context starts *****
    let sharedData =
      this.context !== undefined && this.context.share !== undefined
        ? this.context.share
        : null;
    if (
      sharedData !== undefined &&
      sharedData !== null &&
      sharedData.actionType !== undefined
    ) {
      if (sharedData.actionType == "SPLITSCREEN") {
        let paramObj1 = {
          data: {
            taskId: this.props.values.taskId,
          },
        };

        this.getTaskDetailData(paramObj1);
      }
    }
    //********* for context ends *****
  }

  /**
   * SplitScreenSingleTaskWithSubTaskDetailsEdit
   * Function name : componentWillUnmount
   * Author        :
   * Created Date  : 7-2-2020
   * Purpose       : lifecycle method
   * Params        :
   **/
  componentWillUnmount() {
    this.isComponentLoaded = false;
  }

  componentDidUpdate(prevProps) {
    let sharedData =
      this.context !== undefined && this.context.share !== undefined
        ? this.context.share
        : null;
    if (
      sharedData !== undefined &&
      sharedData !== null &&
      sharedData.actionType !== undefined
    ) {
      if (sharedData.actionType == "SPLITSCREEN") {
        if (this.props.values.taskId !== prevProps.values.taskId) {
          // let paramObj1 = {
          //   "data":{
          //     "taskId":this.props.values.taskId
          //   }
          // }
          // this.getTaskDetailData(paramObj1);
        }
      }
    }
  }
  resetAllDataOfThisComponent = () => {
    this.isComponentLoaded && this.setState(stateStaticData);
  };

  customDate = (defaultDate, other) => {
    //console.log(" from customDate=== defaultDate==>",defaultDate," other==>",other);
    return (
      <DatePicker
        selected={defaultDate}
        onChange={(date) => {
          this.setCalDate(date, other);
        }}
        calendarClassName="customtaskaddclassstep1calcls"
        customInput={<ExampleCustomInput />}
        dateFormat={getDateFormatForCal({ countryCode: "IN" })}
      />
    );
  };

  // set data from response received from context
  setStateData = (entityObj, calledFrom) => {
    let paramData = stateStaticData;

    if (entityObj !== undefined && entityObj !== null) {
      paramData.taskId = entityObj.id !== undefined ? entityObj.id : "";
      paramData.taskName =
        entityObj.taskName !== undefined ? entityObj.taskName : "";
      paramData.description =
        entityObj.description !== undefined ? entityObj.description : "";
      paramData.practiceAreas =
        entityObj.practiceAreas !== undefined ? entityObj.practiceAreas : "";
      paramData.locations =
        entityObj.locations !== undefined ? entityObj.locations : "";
      paramData.taskCategory =
        entityObj.taskCategory !== undefined ? entityObj.taskCategory : "";
      paramData.categoryType =
        entityObj.categoryType !== undefined ? entityObj.categoryType : "";
      paramData.assignedTo =
        entityObj.assignedTo !== undefined ? entityObj.assignedTo : null;

      if (paramData.assignedTo) {
        paramData.budgetCurrency =
          entityObj.agreedBudgetCurrency !== undefined
            ? entityObj.agreedBudgetCurrency
            : "";
        paramData.budget =
          entityObj.agreedBudget !== undefined ? entityObj.agreedBudget : "";
        paramData.dueDateMillis =
          entityObj.agreedDueDateMillis !== undefined
            ? entityObj.agreedDueDateMillis
            : "";
      } else {
        paramData.budgetCurrency =
          entityObj.budgetCurrency !== undefined
            ? entityObj.budgetCurrency
            : "";
        paramData.budget =
          entityObj.budget !== undefined ? entityObj.budget : "";
        paramData.dueDateMillis =
          entityObj.dueDateMillis !== undefined ? entityObj.dueDateMillis : "";
      }
      paramData.taskFiles =
        entityObj.taskFiles !== undefined ? entityObj.taskFiles : [];
      paramData.taskFilesOrig =
        entityObj.taskFiles !== undefined ? entityObj.taskFiles : [];
      paramData.subTasks =
        entityObj.subTasks !== undefined ? entityObj.subTasks : [];

      paramData.taskFilesCnt =
        entityObj.taskFiles !== undefined ? entityObj.taskFiles.length : 0;

      // for editted tag related
      paramData.isUnApprovedEditPresent =
        entityObj.isUnApprovedEditPresent !== undefined
          ? entityObj.isUnApprovedEditPresent
          : "";
      paramData.taskEditStatusDisplayMessage =
        entityObj.taskEditStatusDisplayMessage !== undefined
          ? entityObj.taskEditStatusDisplayMessage
          : "";
      paramData.editedDescription =
        entityObj.editedDescription !== undefined
          ? entityObj.editedDescription
          : "";
      paramData.editedBudgetCurrency =
        entityObj.editedBudgetCurrency !== undefined
          ? entityObj.editedBudgetCurrency
          : "";
      paramData.editedBudget =
        entityObj.editedBudget !== undefined ? entityObj.editedBudget : "";
      paramData.editedDueDateMillis =
        entityObj.editedDueDateMillis !== undefined
          ? entityObj.editedDueDateMillis
          : "";
      paramData.latestActiveUnapprovedEditDate =
        entityObj.latestActiveUnapprovedEditDate !== undefined
          ? entityObj.latestActiveUnapprovedEditDate
          : "";

      paramData.editedDescriptionInit =
        entityObj.editedDescription !== undefined
          ? entityObj.editedDescription
          : "";
      paramData.editedBudgetCurrencyInit =
        entityObj.editedBudgetCurrency !== undefined
          ? entityObj.editedBudgetCurrency
          : "";
      paramData.editedBudgetInit =
        entityObj.editedBudget !== undefined ? entityObj.editedBudget : "";
      paramData.editedDueDateMillisInit =
        entityObj.editedDueDateMillis !== undefined
          ? entityObj.editedDueDateMillis
          : "";

      paramData.descriptionInit =
        entityObj.description !== undefined ? entityObj.description : "";
      paramData.budgetCurrencyInit =
        entityObj.budgetCurrency !== undefined ? entityObj.budgetCurrency : "";
      paramData.budgetInit =
        entityObj.budget !== undefined ? entityObj.budget : "";
      paramData.dueDateMillisInit =
        entityObj.dueDateMillis !== undefined ? entityObj.dueDateMillis : "";

      paramData.updateSuccessRcvdFlag =
        calledFrom == "CALLED_FROM_UPDATE_TASK_FUNC" ? true : false;

      this.isComponentLoaded && this.setState(paramData, () => {});
    } else {
      //this.closeSplit();
      commonnotitrack("CLOSE_SPLITSCREEN", {
        notiType: "CLOSE_SPLITSCREEN",
        triggerCallback: false,
        trigerredFrom: "SINGLE_TASK_DETAILS_EDIT",
      });
    }
  };

  // Newest1 - Start

  // loadLawyerInfoPopup = (i, otherParam) => {
  //   //console.log(this.state.listData[i].userDetails);
  //   let practiceAreas = [];
  //   let locations = [];
  //   let lawyerName = "";
  //   let lawyerFname = "";
  //   let lawyerLname = "";
  //   let bioData = "";
  //   let expData = "";
  //   let ratingData = "";

  //   let profilePictureURL = null;

  //   let userInfoObj =
  //     this.state.assignedTo !== undefined ? this.state.assignedTo : null;
  //   if (userInfoObj) {
  //     lawyerFname =
  //       userInfoObj.firstName !== undefined && userInfoObj.firstName !== null
  //         ? userInfoObj.firstName
  //         : "";

  //     lawyerLname =
  //       userInfoObj.lastName !== undefined && userInfoObj.lastName !== null
  //         ? userInfoObj.lastName
  //         : "";

  //     lawyerName = lawyerFname + " " + lawyerLname;

  //     practiceAreas =
  //       userInfoObj.practiceAreas !== undefined
  //         ? userInfoObj.practiceAreas
  //         : [];

  //     locations =
  //       userInfoObj.locations !== undefined ? userInfoObj.locations : [];

  //     profilePictureURL =
  //       userInfoObj.profilePictureURL !== undefined
  //         ? userInfoObj.profilePictureURL
  //         : null;

  //     bioData =
  //       userInfoObj.bio !== undefined && userInfoObj.bio !== null
  //         ? userInfoObj.bio
  //         : "";

  //     expData =
  //       userInfoObj.experience !== undefined && userInfoObj.experience !== null
  //         ? userInfoObj.experience + " years"
  //         : "";

  //     ratingData =
  //       userInfoObj.rating !== undefined && userInfoObj.rating !== null
  //         ? userInfoObj.rating
  //         : "";
  //   }

  //   return (
  //     <div
  //       className="dropdown-menu user_popDetails_block"
  //       aria-labelledby="dropdownMenuButton"
  //     >
  //       <div className="user_popBox">
  //         <div className="u_popBox_sec user_popBox_top">
  //           <div className="user_popBox_topLeft">
  //             <h4>{lawyerName}</h4>
  //             <div className="userRating_block">
  //               <div className="ass_userRating">
  //                 <i className="fa fa-star" aria-hidden="true" /> {ratingData}
  //               </div>
  //               <div className="ass_userExp">Experience - {expData}</div>
  //             </div>
  //           </div>
  //           <div className="user_popBox_topRight">
  //             {profilePictureURL !== null && <img src={profilePictureURL} />}
  //             {profilePictureURL === null && (
  //               <div className="user_name_text">
  //                 <span>{getStringInitials(lawyerName)}</span>
  //               </div>
  //             )}
  //           </div>
  //         </div>
  //         <div className="u_popBox_sec user_popBox_location">
  //           <h5>Locations</h5>
  //           <ul>
  //             {locations !== null &&
  //               Object.keys(locations).map((incl, edt) => (
  //                 <li key={userInfoObj.userId + "0" + incl}>
  //                   {locations[incl]}
  //                 </li>
  //               ))}
  //           </ul>
  //         </div>
  //         <div className="u_popBox_sec user_popBox_pracArea">
  //           <h5>Practice areas</h5>
  //           <ul>
  //             {practiceAreas !== null &&
  //               Object.keys(practiceAreas).map((prci, e) => (
  //                 <li key={userInfoObj.userId + "0" + prci}>
  //                   {practiceAreas[prci]}
  //                 </li>
  //               ))}
  //           </ul>
  //         </div>
  //         <div className="u_popBox_sec user_popBox_about">
  //           <h5>About</h5>
  //           <p>{bioData}</p>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  // Newest1 - End

  closeSplit = (objParam) => {
    //********* for context starts *****
    // let sharedData  = this.context!==undefined && this.context.share!==undefined?this.context.share:null;
    // if(sharedData!==undefined && sharedData!==null && sharedData.actionType!== undefined){

    //     if(sharedData.actionType=="SPLITSCREEN" ){

    //       let setShare  = this.context!==undefined && this.context.setShare!==undefined?this.context.setShare:null;

    //       if(setShare){
    //         let paramsCntxt = {
    //           "taskId":sharedData.taskId,
    //           "actionType":"",
    //           "leftTabToActive":"",
    //           "purpose":"",
    //           "other":{}
    //         }
    //         setShare(paramsCntxt);
    //       }

    //     }
    // }
    //********* for context ends *****

    commonnotitrack("CLOSE_SPLITSCREEN", {
      notiType: "CLOSE_SPLITSCREEN",
      triggerCallback: false,
      trigerredFrom: "SINGLE_TASK_DETAILS_EDIT",
    });
  };

  custValidate = () => {
    let {
      description,
      budgetCurrency,
      budget,
      editedDescription,
      editedBudgetCurrency,
      editedBudget,
      isUnApprovedEditPresent,
    } = this.state;

    //console.log("from custValidate this.state===>>",this.state);

    let descriptionErMsg = "";
    let budgetCurrencyErrMsg = "";
    let budgetErrMsg = "";

    description =
      description !== undefined && description !== null ? description : "";
    budgetCurrency =
      budgetCurrency !== undefined && budgetCurrency !== null
        ? budgetCurrency
        : "";
    budget = budget !== undefined && budget !== null ? budget : "";

    if (isUnApprovedEditPresent == true) {
      if (
        editedDescription !== undefined &&
        editedDescription !== null &&
        editedDescription
      ) {
        description = editedDescription;
      }

      if (
        editedBudgetCurrency !== undefined &&
        editedBudgetCurrency !== null &&
        editedBudgetCurrency
      ) {
        budgetCurrency = editedBudgetCurrency;
      }

      if (editedBudget !== undefined && editedBudget !== null && editedBudget) {
        budget = editedBudget;
      }
    }

    let totErrCountTrack = [];

    let regexBlankSpace = /^ *$/;

    if (!description || regexBlankSpace.test(description) == true) {
      descriptionErMsg = "Task Description is required.";
      totErrCountTrack.push(descriptionErMsg);
    } else if (description && description.length > 300) {
      descriptionErMsg = "Maximum 300 characters are allowed.";
      totErrCountTrack.push(descriptionErMsg);
    }

    if (
      budgetCurrency.length == 0 ||
      regexBlankSpace.test(budgetCurrency) == true
    ) {
      budgetCurrencyErrMsg = " Currency is required.";
      totErrCountTrack.push(budgetCurrencyErrMsg);
    }

    if (this.state.budgetErrMsg) {
      budgetErrMsg = this.state.budgetErrMsg;
      totErrCountTrack.push(budgetErrMsg);
    } else if (budget.length == 0 || regexBlankSpace.test(budget) == true) {
      budgetErrMsg = " Budget is required.";
      totErrCountTrack.push(budgetErrMsg);
    }

    this.isComponentLoaded &&
      this.setState(
        {
          descriptionErMsg: descriptionErMsg,
          budgetCurrencyErrMsg: budgetCurrencyErrMsg,
          budgetErrMsg: budgetErrMsg,
        },
        () => {
          //console.log(" this.state====>>>>", this.state);
        }
      );

    if (totErrCountTrack.length > 0) {
      return false;
    }

    return true;
  };

  hitUpdateApi = () => {
    let chk = this.custValidate();
    if (chk) {
      //call modal this.updateTask();
      this.updateConfirmModalOpen();
    }
  };

  setCalDate = (dt, other) => {
    let stateKeyName = "dueDate";
    let inputFieldName =
      other.editedDueDateFlag == true && this.state.editedDueDateMillis
        ? "editedDueDateMillis"
        : "dueDateMillis";

    let dtTs = getTimestampInMillisecondsFromDateObj(dt);
    let dttOfset = getOffsetOfLocalDateTime(dt);

    let otherParam1 = { inputFieldName: inputFieldName };
    this.setStatesForSTEdit(stateKeyName, dtTs, otherParam1);

    let otherParam2 = {};
    this.setStatesForSTEdit("timeOffset", dttOfset, otherParam2);
  };

  getTaskDetailData = (param) => {
    this.setState(stateStaticData, () => {
      setTimeout(() => {
        let lservdrf = new TaskDetailsService();
        lservdrf
          .getTaskDetail(param)
          .then((data) => {
            let entityObj = data.data.entity;
            this.setStateData(entityObj, "CALLED_FROM_GET_TASK_DETAIL_FUNC");
          })
          .catch((e) => {
            console.log("server error=>", e);
          })
          .finally(() => {});
      }, 3000);
    });
  };

  budgetChangeTrack = (e, other) => {
    let budgetValue = e.target.value;
    let inputFieldName =
      other.budgetOnlyEditFlag == true && this.state.editedBudget
        ? "editedBudget"
        : "budget";
    let { budget, editedBudget } = this.state;

    if (budgetValue) {
      if (validatePositiveInputNumber({ data: e.target.value })) {
        budgetValue = e.target.value;
      } else {
        budgetValue = inputFieldName == "editedBudget" ? editedBudget : budget;
      }
    }

    let otherPar = { inputFieldName: inputFieldName };
    this.setStatesForSTEdit("budget", budgetValue, otherPar);
  };

  currencyOptionChangeTrack = (e, other) => {
    let currencyValue = e.target.value;
    let inputFieldName =
      other.budgetCurrOnlyEditFlag == true && this.state.editedBudgetCurrency
        ? "editedBudgetCurrency"
        : "budgetCurrency";
    let otherPar = { inputFieldName: inputFieldName };
    this.setStatesForSTEdit("budgetCurrency", currencyValue, otherPar);
    // set focus to budget input
    this.budgetInputRef.current.focus();
  };

  budgetOnBlurTrack = async (e) => {
    let budgetInputValue = e.target.value;
    let otherParam = {
      categoryId: 0,
      typeId: 0,
      budgetCurrency: "",
    };
    let { taskCategory, categoryType, budgetCurrency, budget } = this.state;

    let categoryIdData = taskCategory !== undefined ? taskCategory.id : 0;
    let typeIdData = categoryType !== undefined ? categoryType.id : 0;
    let budgetCurrencyData = budgetCurrency !== undefined ? budgetCurrency : "";
    let budgetData = budget !== undefined ? budget : "";

    let errMsg = "";
    if (
      categoryIdData != "" &&
      typeIdData != "" &&
      budgetData != "" &&
      budgetCurrencyData != ""
    ) {
      otherParam.categoryId = categoryIdData;
      otherParam.typeId = typeIdData;
      otherParam.budgetCurrency = budgetCurrencyData;

      let chkResp = await this.budgetFloorValueCheck(
        budgetInputValue,
        otherParam
      );
      if (chkResp) {
        if (chkResp.checkStatus == false) {
          // error
          errMsg = chkResp.errMsg
            ? chkResp.errMsg
            : "Something went wrong, please try again.";
          this.isComponentLoaded && this.setState({ budgetErrMsg: errMsg });
        } else {
          // ok proceed
          this.isComponentLoaded && this.setState({ budgetErrMsg: errMsg });
        }
      }
    } else {
      //error
      errMsg = "Budget is missing.";
      this.isComponentLoaded && this.setState({ budgetErrMsg: errMsg });
    }
  };

  async budgetFloorValueCheck(budgetInputValue, otherParam) {
    // From here
    let qryStrng =
      otherParam.categoryId +
      "/" +
      otherParam.typeId +
      "?currency=" +
      otherParam.budgetCurrency;
    const respObj = {
      floorPrice: 0,
      checkStatus: false,
      errMsg: "",
    };

    let fpservice = new FloorPriceBasedOnCategAndTypeService();
    await fpservice
      .getFloorPrice(qryStrng)
      .then((data) => {
        if (data.status == 200) {
          if (data.data.entity !== undefined) {
            let floorPrice = data.data.entity;
            if (budgetInputValue >= floorPrice) {
              //ok
              respObj.floorPrice = floorPrice;
              respObj.checkStatus = true;
            } else {
              // not ok
              respObj.floorPrice = floorPrice;
              respObj.checkStatus = false;
              respObj.errMsg =
                "Budget cannot be less than floor price " +
                otherParam.budgetCurrency +
                " " +
                floorPrice;
            }
          }
        }
      })
      .catch((err) => {
        let errStatusCodeAr = [400, 500]; // allowed error status code
        if (err.response && err.response.data) {
          let erRespData = err.response.data;
          if (errStatusCodeAr.indexOf(erRespData.status) !== -1) {
            let errMsg = erRespData.message
              ? erRespData.message
              : "Some technical problem occurred.";
            // not ok
            respObj.floorPrice = 0;
            respObj.checkStatus = false;
            respObj.errMsg = errMsg;
          }
        }
      });

    return respObj;
  }

  // For screen ST screeen 1
  setStatesForSTEdit = (stateKeyName, stateKeyValue, otherParam) => {
    switch (stateKeyName) {
      case "uplDocuments":
        if (otherParam.action == "push") {
          if (otherParam.bulk !== undefined && otherParam.bulk.length > 0) {
            let prevDoc = [];
            let docs = otherParam.bulk;
            Object.keys(docs).map(function (key, index) {
              // convert server response of file size from mb to bytes
              let fileSize = docs[key].fileSize
                ? docs[key].fileSize * 1024 * 1024
                : 0;
              prevDoc.push({
                id: docs[key].id,
                name: docs[key].name,
                size: fileSize,
                notSavedYet: true,
              });
            });
            this.isComponentLoaded && this.setState({ taskFiles: prevDoc });
          } else {
            let prevDoc = [...this.state.taskFiles];
            let docs = stateKeyValue;
            Object.keys(docs).map(function (key, index) {
              // convert server response of file size from mb to bytes
              let fileSize = docs[key].fileSize
                ? docs[key].fileSize * 1024 * 1024
                : 0;
              prevDoc.push({
                id: docs[key].id,
                name: docs[key].name,
                size: fileSize,
              });
            });
            this.isComponentLoaded && this.setState({ taskFiles: prevDoc });
          }
        } else if (otherParam.action == "remove") {
          let valuesData = [...this.state.taskFiles];
          let indx = stateKeyValue;
          valuesData.splice(indx, 1);
          this.isComponentLoaded && this.setState({ taskFiles: valuesData });
        }
        break;

      case "handleChange":
        let inputName = otherParam.inputFieldName;
        let valueData = otherParam.value;
        let paramState = { [inputName]: valueData };

        if (inputName == "description" && valueData == "") {
          paramState["editedDescription"] = "";
        } else if (inputName == "editedDescription" && valueData == "") {
          paramState["description"] = "";
        }

        this.isComponentLoaded && this.setState(paramState);
        break;

      case "dueDate":
        let dateData = stateKeyValue;
        let inputNameDdt = otherParam.inputFieldName;
        this.isComponentLoaded && this.setState({ [inputNameDdt]: dateData });
        break;
      case "timeOffset":
        let offsetDataObj = stateKeyValue;
        this.isComponentLoaded &&
          this.setState({
            offsetHour: offsetDataObj.hr,
            offsetMinute: offsetDataObj.min,
          });
        break;

      case "budgetCurrency":
        let inputNameBudgCur = otherParam.inputFieldName;
        this.isComponentLoaded &&
          this.setState({
            [inputNameBudgCur]: stateKeyValue,
            budget: "",
            editedBudget: "",
          });
        break;
      case "budget":
        let inputNameBudg = otherParam.inputFieldName;

        let paramBudgState = { [inputNameBudg]: stateKeyValue };

        if (inputNameBudg == "budget" && stateKeyValue == "") {
          paramBudgState["editedBudget"] = "";
        } else if (inputNameBudg == "editedBudget" && stateKeyValue == "") {
          paramBudgState["budget"] = "";
        }

        this.isComponentLoaded && this.setState(paramBudgState);
        break;
    }
  };

  updateTask = () => {
    let chkVal = this.custValidate();

    if (chkVal) {
      let offsetOfLocalDateTime = getOffsetOfLocalDateTime(new Date());
      let offsetHr = offsetOfLocalDateTime.hr;
      let offsetMin = offsetOfLocalDateTime.min;

      // hit task update api
      this.isComponentLoaded &&
        this.setState({ continueDisabled: true }, () => {
          this.getUpdateConfirmModalContent();
        });
      let paramObj = {
        taskName: "",
        taskDescription: "",
        dueDate: null,
        categoryId: "",
        typeId: "",
        budget: "",
        budgetCurrency: "",
        taskDocIds: [],
        offsetHour: null,
        offsetMinute: null,
      };

      const {
        taskId,
        taskName,
        description,
        dueDateMillis,
        taskCategory,
        categoryType,
        budget,
        budgetCurrency,
        taskFiles,
        offsetHour,
        offsetMinute,
      } = this.state;

      let taskDocIdsAr = [];
      if (taskFiles.length > 0) {
        Object.keys(taskFiles).map((e, i) => {
          taskDocIdsAr.push(taskFiles[e].id);
        });
      }

      paramObj.taskName = taskName;
      paramObj.taskDescription =
        this.state.isUnApprovedEditPresent == true &&
        this.state.editedDescription
          ? this.state.editedDescription
          : description;
      paramObj.dueDate =
        this.state.isUnApprovedEditPresent == true &&
        this.state.editedDueDateMillis
          ? this.state.editedDueDateMillis
          : dueDateMillis;
      paramObj.offsetHour = offsetHour ? offsetHour : offsetHr;
      paramObj.offsetMinute = offsetMinute ? offsetMinute : offsetMin;
      paramObj.taskDocIds = taskDocIdsAr;

      paramObj.categoryId = taskCategory.id;
      paramObj.typeId = categoryType.id;
      paramObj.budget =
        this.state.isUnApprovedEditPresent == true && this.state.editedBudget
          ? this.state.editedBudget
          : budget;
      paramObj.budgetCurrency =
        this.state.isUnApprovedEditPresent == true &&
        this.state.editedBudgetCurrency
          ? this.state.editedBudgetCurrency
          : budgetCurrency;

      let paramObjData = {
        taskId: taskId,
        data: paramObj,
      };

      let stcservice = new TaskUpdateService();
      stcservice
        .updateTask(paramObjData)
        .then((data) => {
          if (data.status == 200) {
            let respData = {};
            if (data.data.entity !== undefined) {
              respData = data.data.entity;

              // context share
              let sharedData =
                this.context !== undefined && this.context.share !== undefined
                  ? this.context.share
                  : null;
              let setShare =
                this.context !== undefined &&
                this.context.setShare !== undefined
                  ? this.context.setShare
                  : null;
              if (setShare && sharedData) {
                sharedData.other.taskResp = respData;
                setShare(sharedData);
              }

              let notiMsg = "Updated successfully";
              notify(notiMsg, true, {
                successMsg: 1,
                customWrapClass: "customerrmidlass",
                showCloseButton: false,

                dismiss: {
                  duration: 1000,
                },
              });

              // this.isComponentLoaded && this.setState({"updateSuccessRcvdFlag":true},()=>{
              //   console.log("***** updateSuccessRcvdFlag=>>>>",this.state.updateSuccessRcvdFlag);
              setTimeout(() => {
                this.updateConfirmModalClose();

                //this.setState(respData);
                this.setStateData(respData, "CALLED_FROM_UPDATE_TASK_FUNC");
              }, 1250);

              // });
            }
          }
        })
        .catch((err) => {
          let errStatusCodeAr = [400, 500]; // allowed error status code
          if (err.response && err.response.data) {
            let erRespData = err.response.data;
            if (errStatusCodeAr.indexOf(erRespData.status) !== -1) {
              let errMsg = erRespData.message
                ? erRespData.message
                : "Some technical problem occurred.";
              //console.log.log(" errMsg on task creation==>",errMsg);

              let notiMsg = errMsg;
              notify(notiMsg, true, {
                successMsg: 0,
                customWrapClass: "customerrmidlass",
                showCloseButton: false,

                dismiss: {
                  duration: 2500,
                },
              });
            }
          }
        })
        .finally(() => {
          this.isComponentLoaded &&
            this.setState({ continueDisabled: false }, () => {
              this.getUpdateConfirmModalContent();
            });
        });
    } else {
      this.isComponentLoaded && this.setState({ continueDisabled: false });
    }
  };

  // Newest1 - Start

  /*** Professional View Display Modal starts  */

  /**
   * SplitScreenSingleTaskDetailsEdit
   * Function name : pVDisplayModalClose
   * Author        :
   * Created Date  : 9-7-2021
   * Purpose       : To close pVDisplay modal
   * Params        :
   **/
  pVDisplayModalClose = () => {
    this.isComponentLoaded &&
      this.setState({ showPVDisplayModal: false }, () => {
        this.modalforpvdisplayRef.current.hideModal();
      });
  };

  /**
   * SplitScreenSingleTaskDetailsEdit
   * Function name : getPVDisplayModalContent
   * Author        :
   * Created Date  : 9-7-2021
   * Purpose       : Get pVDisplay modal view with data
   * Params        :
   **/
  getPVDisplayModalContent = () => {
    let userId = null;

    if (this.state.assignedTo)
      userId = this.state.assignedTo.userId
        ? this.state.assignedTo.userId
        : null;

    let viewData = (
      <ProfileNewMod
        closingFunction={this.pVDisplayModalClose}
        lprofileId={userId}
      />
    );

    this.modalforpvdisplayRef.current.updateStateContent(viewData);
    this.modalforpvdisplayRef.current.showContent();
    return viewData;
  };

  /**
   * SplitScreenSingleTaskDetailsEdit
   * Function name : pVDisplayModalOpen
   * Author        :
   * Created Date  : 9-7-2021
   * Purpose       : To open pVDisplay modal
   * Params        :
   **/
  pVDisplayModalOpen = () => {
    this.isComponentLoaded &&
      this.setState({ showPVDisplayModal: true }, () => {
        this.modalforpvdisplayRef.current.showModal();
        this.getPVDisplayModalContent();
      });
  };

  /*** Professional View Display Modal ends  */

  /*** Professional View Disclaimer Modal starts  */

  /**
   * SplitScreenSingleTaskDetailsEdit
   * Function name : pVDiscModCloseDispModOpen
   * Author        :
   * Created Date  : 9-7-2021
   * Purpose       : To close pVDisclaimer modal and conditionally open pVDisplay modal
   * Params        : shouldDisplay
   **/
  pVDiscModCloseDispModOpen = (shouldDisplay) => {
    this.isComponentLoaded &&
      this.setState({ showPVDisclaimerModal: false }, () => {
        this.modalforpvdisclaimerRef.current.hideModal();
        if (shouldDisplay) {
          setTimeout(() => {
            this.pVDisplayModalOpen();
          }, 300);
        }
      });
  };

  /**
   * SplitScreenSingleTaskDetailsEdit
   * Function name : getPVDisclaimerModalContent
   * Author        :
   * Created Date  : 9-7-2021
   * Purpose       : Get pVDisclaimer modal view with data
   * Params        :
   **/
  getPVDisclaimerModalContent = () => {
    let viewData = (
      <div className="dash_modal">
        <div className="modal-header">
          <h4 className="modal-title modal_title_tool">
            View Professional’s Profile
            {/*<div className="input_tooltip_bg">
                      <span className="input_tooltip" aria-label="Organisation's Details" data-balloon-pos="down">
                      <i className="fa fa-question-circle"></i>
                      </span>
                      </div>*/}
          </h4>
          <button
            type="button"
            onClick={() => {
              this.pVDiscModCloseDispModOpen(false);
            }}
            className="close modal_close"
            data-dismiss="modal"
          >
            &times;
          </button>
        </div>
        {/* <CustomMessageBar /> */}
        <div className="modal-body padding_top">
          <div className="fileUp_modalBox">
            <p>
              Disclaimer: By clicking on "View" you agree that you have sought
              this information of your own accord. You further agree that
              neither the Professional nor Flywork has solicited and/or
              advertised to induce you for work.
            </p>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              this.pVDiscModCloseDispModOpen(false);
            }}
            data-dismiss="modal"
            class="btn_appearance_none modal_text_btn"
          >
            <span>Cancel</span>
          </button>
          <button
            type="button"
            className="btn_appearance_none green_btn"
            onClick={(e) => {
              e.preventDefault();
              this.pVDiscModCloseDispModOpen(true);
            }}
            data-dismiss="modal"
          >
            <span>View</span>
          </button>
        </div>
      </div>
    );

    this.modalforpvdisclaimerRef.current.updateStateContent(viewData);
    this.modalforpvdisclaimerRef.current.showContent();
    return viewData;
  };

  /**
   * SplitScreenSingleTaskDetailsEdit
   * Function name : pVDisclaimerModalOpen
   * Author        :
   * Created Date  : 9-7-2021
   * Purpose       : To open pVDisclaimer modal
   * Params        :
   **/
  pVDisclaimerModalOpen = () => {
    this.isComponentLoaded &&
      this.setState({ showPVDisclaimerModal: true }, () => {
        this.modalforpvdisclaimerRef.current.showModal();
        this.getPVDisclaimerModalContent();
      });
  };

  /*** Professional View Disclaimer Modal ends  */

  loadTheContent = () => {
    let forSplitScrnCls2Inject = "";
    //********* for context starts *****
    let sharedData =
      this.context !== undefined && this.context.share !== undefined
        ? this.context.share
        : null;
    if (
      sharedData !== undefined &&
      sharedData !== null &&
      sharedData.actionType !== undefined
    ) {
      if (sharedData.actionType == "SPLITSCREEN") {
        forSplitScrnCls2Inject = "margin0";
      }
    }
    //********* for context ends *****

    let practiceAreas = this.state.practiceAreas;
    let practiceAreasMoreTxt = "";
    let practiceAreaName = "";
    let pracNameToDispForMore = [];
    if (practiceAreas && practiceAreas.length == 1) {
      practiceAreaName = practiceAreas[0] ? practiceAreas[0].practiceArea : "";
    } else if (practiceAreas && practiceAreas.length > 1) {
      let practiceAreasCnt = practiceAreas.length;
      practiceAreasCnt = practiceAreasCnt - 1;
      practiceAreasMoreTxt = "+" + practiceAreasCnt + " more";

      practiceAreaName = practiceAreas[0] ? practiceAreas[0].practiceArea : "";
      for (let ind = 1; ind < practiceAreas.length; ind++) {
        pracNameToDispForMore.push(practiceAreas[ind].practiceArea);
      }
    }

    let locations = this.state.locations;
    let locationsMoreTxt = "";
    let locationsName = "";
    let locNameToDispForMore = [];

    if (locations && locations.length == 1) {
      locationsName = locations[0] ? locations[0].cityName : "";
    } else if (locations && locations.length > 1) {
      let locCnt = locations.length;
      locCnt = locCnt - 1;
      locationsMoreTxt = "+" + locCnt + " more";

      locationsName = locations[0] ? locations[0].cityName : "";

      for (let ind = 1; ind < locations.length; ind++) {
        locNameToDispForMore.push(locations[ind].cityName);
      }
    }

    let assignedTo = this.state.assignedTo;
    //let assignedToName = assignedTo?"none":"none";
    let assignedToName = "None";
    if (assignedTo !== undefined && assignedTo !== null) {
      let fNm =
        assignedTo.firstName !== undefined && assignedTo.firstName !== null
          ? assignedTo.firstName
          : "";
      let lNm =
        assignedTo.lastName !== undefined && assignedTo.lastName !== null
          ? assignedTo.lastName
          : "";
      assignedToName = fNm + " " + lNm;
    }

    let budgetData = "";
    let budget = this.state.budget ? this.state.budget : "";
    let budgetCurrency = this.state.budgetCurrency
      ? this.state.budgetCurrency
      : "";

    if (budget && budgetCurrency) {
      budgetData = budgetCurrency + " " + budget;
    }

    let budgetDataInit = "";
    let budgetInit = this.state.budgetInit ? this.state.budgetInit : "";
    let budgetCurrencyInit = this.state.budgetCurrencyInit
      ? this.state.budgetCurrencyInit
      : "";

    if (budgetInit && budgetCurrencyInit) {
      budgetDataInit = budgetCurrencyInit + " " + budgetInit;
    }

    let taskFiles = this.state.taskFiles;
    let taskFilesCnt = this.state.taskFilesCnt;

    //  if(taskFiles){
    //   taskFilesCnt = taskFiles?taskFiles.length:0;
    // }

    const defaultDateData = this.state.dueDateMillis; //new Date();
    const defaultDate =
      defaultDateData !== null
        ? getDateObjFromMilliseconds(defaultDateData)
        : null;

    let budgetEditFlagInit = false; // for both budget & currency first time
    let budgetEditFlag = false; // for both budget & currency
    let budgetOnlyEditFlag = false; // for  budget only
    let budgetCurrOnlyEditFlag = false; // for currency only
    let budgetDataEditted = "";
    let budgetDataOnlyEdit = "";
    let budgetCurrDataOnlyEdit = "";

    if (
      this.state.isUnApprovedEditPresent == true &&
      (this.state.editedBudgetInit || this.state.editedBudgetCurrencyInit)
    ) {
      budgetEditFlagInit = true;
    }

    if (
      this.state.isUnApprovedEditPresent == true &&
      (this.state.editedBudget || this.state.editedBudgetCurrency)
    ) {
      budgetEditFlag = true;

      if (this.state.editedBudget && this.state.editedBudgetCurrency) {
        budgetDataEditted =
          this.state.editedBudgetCurrency + " " + this.state.editedBudget;

        budgetOnlyEditFlag = true;
        budgetCurrOnlyEditFlag = true;

        budgetDataOnlyEdit = this.state.editedBudget;
        budgetCurrDataOnlyEdit = this.state.editedBudgetCurrency;
      } else if (this.state.editedBudget && !this.state.editedBudgetCurrency) {
        budgetDataEditted = budgetCurrency + " " + this.state.editedBudget;

        budgetOnlyEditFlag = true;
        budgetCurrOnlyEditFlag = false;

        budgetDataOnlyEdit = this.state.editedBudget;
        budgetCurrDataOnlyEdit = "";
      } else if (!this.state.editedBudget && this.state.editedBudgetCurrency) {
        budgetDataEditted = this.state.editedBudgetCurrency + " " + budget;

        budgetOnlyEditFlag = false;
        budgetCurrOnlyEditFlag = true;

        budgetDataOnlyEdit = "";
        budgetCurrDataOnlyEdit = this.state.editedBudgetCurrency;
      }
    }

    let dueDateData = this.state.dueDateMillis
      ? CustDate.custFormatDate(new Date(this.state.dueDateMillis), 3)
      : "None";

    let editedDueDateFlag = false;
    let editedDueDate = "None";
    let editedDueDateMillis = null;
    let editedDueDateMillisDtObj = null;
    //editedDueDateMillis
    if (
      this.state.isUnApprovedEditPresent == true &&
      this.state.editedDueDateMillis
    ) {
      editedDueDateFlag = true;
      editedDueDate = this.state.editedDueDateMillis
        ? CustDate.custFormatDate(new Date(this.state.editedDueDateMillis), 3)
        : "None";
      editedDueDateMillis = this.state.editedDueDateMillis
        ? this.state.editedDueDateMillis
        : null;
      editedDueDateMillisDtObj =
        editedDueDateMillis !== null
          ? getDateObjFromMilliseconds(editedDueDateMillis)
          : null;
    }

    let dueDateDataInit = this.state.dueDateMillisInit
      ? CustDate.custFormatDate(new Date(this.state.dueDateMillisInit), 3)
      : "None";

    let editedDueDateFlagInit = false;
    if (
      this.state.isUnApprovedEditPresent == true &&
      this.state.editedDueDateMillisInit
    ) {
      editedDueDateFlagInit = true;
    }

    //console.log(defaultDate,"defaultDate")
    return (
      <>
        {/* Dashboard Slide Split Right start  */}
        <div
          className={`splitscreen_area 
                ${forSplitScrnCls2Inject ? forSplitScrnCls2Inject : ""}`}
        >
          <div className="slideScreen_bg slideScreen_Chatbg slideScreen_bg_heightAdjust">
            <SplitScreenLeftMenu />
            <div className="slideScreen_rightbg flexbox-parent">
              {/* === right slide section top start ===  */}
              <div className="SlideS_topbg flexbox-item">
                <div className="slide_top_titlebg">
                  <h4>Task Details</h4>
                  <button
                    onClick={(evn) => {
                      evn.preventDefault();
                      this.closeSplit({
                        trigerredFrom: "SINGLE_TASK_DETAILS_EDIT",
                      });
                    }}
                    className="btn_appearance_none slideTop_cross_btn cross"
                  >
                    <img src={getBaseUrl() + "assets/images/red_cross.png"} />
                  </button>
                </div>
              </div>
              {/* === right slide section top end ===  */}
              {/* === right slide section Middlie  start ===  */}
              <div className="SlideS_Midbg flexbox-item-grow SlideS_Midbg_heightAdjustment SlideS_Midbg_heightAdjustment_taskDetails">
                <div className="s_midbox_scroll_adjust reactTestScroll mCustomAdjustmentForStickFooter rightMidPartHeightAdjustment rightMidPartHeightAdjustment_taskDetails">
                  {/* <Scrollbars 
                              ref={this.scrollerRef}
                              style={{ height: 750 }}                  
                              autoHeightMin={750}
                              renderView={props => <div {...props} className="view taskdetailnosbtskscrollcustomclass"/>}

                              onScrollStop={this.trackScroll}
                          > */}
                  <div className="view taskdetailnosbtskscrollcustomclass">
                    <div className="s_midbox_section s_midbox_section_taskEdit_new">
                      {this.state.taskEditStatusDisplayMessage && (
                        <div className="top_msgBg">
                          <div className="allert_messg">
                            {this.state.taskEditStatusDisplayMessage}
                          </div>
                        </div>
                      )}

                      {/* <div className="s_midbox_title">
                              <h5>Task Number - {this.state.taskId}</h5>
                            </div> */}

                      <div className="s_midbox">
                        {/* ====  slidebox start ====  */}
                        <div className="scroll_maintask_bg">
                          <div className="enter_task_box sub_task_addbg right_TaskBox_new">
                            {/* == task title new design start ==   */}
                            <div className="task_title_head text-right">
                              <h5>Task Number - {this.state.taskId}</h5>
                            </div>
                            {/* == task title new design end ==   */}
                            <div className="main_form_box">
                              <div className="form_block form_block_half">
                                <div className="panel50 panel_half paddingright15 form__group_normar">
                                  <label htmlFor="name" className="form__label">
                                    <b>Task Name</b>
                                  </label>
                                  <div className="form__group field">
                                    <div className="input_datashow">
                                      <p>{this.state.taskName}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="panel50 panel_half paddingleft15 form__group_normar">
                                  <label htmlFor="name" className="form__label">
                                    <b>Assigned to</b>
                                  </label>
                                  <div className="form__group field">
                                    <div className="form__group field">
                                      <div className="input_datashow">
                                        {assignedToName == "None" && (
                                          //  <p className={`${assignedToName=="None"?"":"assTo_green"}`}>{assignedToName}</p>
                                          <p className="">{assignedToName}</p>
                                        )}

                                        {assignedToName != "None" && (
                                          <div className="onClick_userDetails onClick_userName">
                                            <div className="ass_user_name">
                                              <button
                                                className="btn_appearance_none assignUser_nameBtn"
                                                onClick={(evn) => {
                                                  evn.preventDefault();
                                                  this.pVDisclaimerModalOpen();
                                                }}
                                                // type="button"
                                                // data-toggle="dropdown"
                                                // aria-expanded="false"
                                              >
                                                {assignedToName}
                                              </button>
                                              {/* {this.loadLawyerInfoPopup(1, {})} */}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="clearboth" />
                              </div>
                              <div className="form_block form_block_half">
                                <div className="panel50 panel_half paddingright15 form__group_normar">
                                  <label htmlFor="name" className="form__label">
                                    <b>Practice area</b>
                                  </label>
                                  <div className="form__group field">
                                    <div className="form__group field">
                                      <div className="input_datashow">
                                        {practiceAreaName && (
                                          <span className="dataShow_green">
                                            {practiceAreaName}
                                          </span>
                                        )}
                                        {/* <span className="dataShow_more">{practiceAreasMoreTxt}</span> */}

                                        {practiceAreasMoreTxt && (
                                          <div className="dataShow_more_newTooltip">
                                            <a
                                              href={(evn) => {
                                                evn.preventDefault();
                                              }}
                                            >
                                              <span className="dataShow_more">
                                                {practiceAreasMoreTxt}
                                              </span>
                                            </a>
                                            <div className="moreTooltip_show">
                                              <div className="moreTooltip_box">
                                                <ul>
                                                  {pracNameToDispForMore.length >
                                                    0 &&
                                                    pracNameToDispForMore.map(
                                                      (e, i) => (
                                                        <li>
                                                          <div className="moretooltip_greenTag">
                                                            {
                                                              pracNameToDispForMore[
                                                                i
                                                              ]
                                                            }
                                                          </div>
                                                        </li>
                                                      )
                                                    )}
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="panel50 panel_half paddingleft15 form__group_normar ">
                                  <label htmlFor="name" className="form__label">
                                    <b>Location</b>
                                    &nbsp;
                                  </label>
                                  <div className="form__group field">
                                    <div className="input_datashow">
                                      {locationsName && (
                                        <span className="dataShow_green">
                                          {locationsName}
                                        </span>
                                      )}

                                      {locationsMoreTxt && (
                                        <div className="dataShow_more_newTooltip">
                                          <a
                                            href={(evn) => {
                                              evn.preventDefault();
                                            }}
                                          >
                                            <span className="dataShow_more">
                                              {locationsMoreTxt}
                                            </span>
                                          </a>
                                          <div className="moreTooltip_show">
                                            <div className="moreTooltip_box">
                                              <ul>
                                                {locNameToDispForMore.length >
                                                  0 &&
                                                  locNameToDispForMore.map(
                                                    (e, i) => (
                                                      <li>
                                                        <div className="moretooltip_greenTag">
                                                          {
                                                            locNameToDispForMore[
                                                              i
                                                            ]
                                                          }
                                                        </div>
                                                      </li>
                                                    )
                                                  )}
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="clearboth" />
                              </div>
                              <div className="form_block form_block_half">
                                <div className="panel50 panel_half paddingright15 form__group_normar">
                                  <label htmlFor="name" className="form__label">
                                    <b>Category of task</b>
                                  </label>
                                  <div className="form__group field">
                                    <div className="input_datashow">
                                      <p>
                                        {this.state.taskCategory &&
                                        this.state.taskCategory.category
                                          ? this.state.taskCategory.category
                                          : ""}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="panel50 panel_half paddingleft15 form__group_normar">
                                  <label htmlFor="name" className="form__label">
                                    <b>Type of task</b>
                                  </label>
                                  <div className="form__group field">
                                    <div className="form__group field">
                                      <div className="input_datashow">
                                        <p>
                                          {this.state.categoryType &&
                                          this.state.categoryType.categoryType
                                            ? this.state.categoryType
                                                .categoryType
                                            : ""}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="clearboth" />
                              </div>
                              <div className="form_block form_block_half">
                                <div className=" panel_half form__group_normar">
                                  <label htmlFor="name" className="form__label">
                                    <b>Task description</b>
                                    {this.state.isUnApprovedEditPresent ==
                                      true &&
                                      this.state.editedDescriptionInit && (
                                        <div className="lable_popDetails_bg">
                                          <button className="btn_appearance_none lable_popBtn">
                                            Edited
                                          </button>
                                          <div className="lable_pop_details">
                                            <div className="lable_pop_detailsTop">
                                              {/* <button class="btn_appearance_none veh_btn">View edited history</button> */}
                                              <h5>Awaiting Confirmation</h5>
                                              <div className="clearboth" />
                                            </div>
                                            <p>{this.state.descriptionInit}</p>
                                            <p className="dtp">
                                              <span>
                                                {this.state
                                                  .latestActiveUnapprovedEditDate
                                                  ? CustDate.custFormatDate(
                                                      new Date(
                                                        this.state.latestActiveUnapprovedEditDate
                                                      ),
                                                      4
                                                    )
                                                  : "None"}
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      )}
                                  </label>
                                  <div
                                    className={`form__group field ${
                                      this.state.descriptionErMsg
                                        ? "form_group_error"
                                        : ""
                                    }`}
                                  >
                                    <textarea
                                      onChange={(e) => {
                                        let stateKeyName =
                                          this.state.isUnApprovedEditPresent ==
                                            true && this.state.editedDescription
                                            ? "editedDescription"
                                            : "description";

                                        let otherParam = {
                                          inputFieldName: stateKeyName,
                                          value: e.target.value,
                                        };
                                        this.setStatesForSTEdit(
                                          "handleChange",
                                          "",
                                          otherParam
                                        );
                                      }}
                                      value={
                                        this.state.isUnApprovedEditPresent ==
                                          true && this.state.editedDescription
                                          ? this.state.editedDescription
                                          : this.state.description
                                      }
                                    />
                                    <span className="error_span">
                                      {this.state.descriptionErMsg}
                                    </span>
                                  </div>
                                </div>
                                <div className="clearboth" />
                              </div>
                              <div className="form_block form_block_half">
                                <div className="panel50 panel_half paddingright15 form__group_normar">
                                  <label htmlFor="name" className="form__label">
                                    <b>Budget</b>
                                    {budgetEditFlagInit && (
                                      <div className="lable_popDetails_bg">
                                        <button className="btn_appearance_none lable_popBtn">
                                          Edited
                                        </button>
                                        <div className="lable_pop_details">
                                          <div className="lable_pop_detailsTop">
                                            {/* <button class="btn_appearance_none veh_btn">View edited history</button> */}
                                            <h5>Awaiting Confirmation</h5>
                                            <div className="clearboth" />
                                          </div>
                                          <p>{budgetDataInit}</p>
                                          <p className="dtp">
                                            <span>
                                              {this.state
                                                .latestActiveUnapprovedEditDate
                                                ? CustDate.custFormatDate(
                                                    new Date(
                                                      this.state.latestActiveUnapprovedEditDate
                                                    ),
                                                    4
                                                  )
                                                : "None"}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                  </label>
                                  <div className="form__group field">
                                    <div
                                      className={`price_input_grup_bg ${
                                        this.state.budgetErrMsg
                                          ? "form_group_error"
                                          : ""
                                      }`}
                                    >
                                      <div className="price_input_grup">
                                        <div className="price_input_grup_left">
                                          <input
                                            ref={this.budgetInputRef}
                                            onChange={(evn) => {
                                              this.budgetChangeTrack(evn, {
                                                budgetOnlyEditFlag:
                                                  budgetOnlyEditFlag,
                                              });
                                            }}
                                            value={
                                              budgetEditFlag &&
                                              budgetOnlyEditFlag
                                                ? budgetDataOnlyEdit
                                                : budget
                                            }
                                            onBlur={this.budgetOnBlurTrack}
                                            type="text"
                                            placeholder="Budget"
                                            maxLength="10"
                                          />
                                        </div>
                                        <div className="price_input_grup_right">
                                          <select
                                            value={
                                              budgetEditFlag &&
                                              budgetCurrOnlyEditFlag
                                                ? budgetCurrDataOnlyEdit
                                                : budgetCurrency
                                            }
                                            onChange={(evn) => {
                                              this.currencyOptionChangeTrack(
                                                evn,
                                                {
                                                  budgetCurrOnlyEditFlag:
                                                    budgetCurrOnlyEditFlag,
                                                }
                                              );
                                            }}
                                          >
                                            {Object.keys(
                                              currencyStaticData
                                            ).map((e, i) => (
                                              <option
                                                key={i}
                                                value={
                                                  currencyStaticData[e].key
                                                }
                                              >
                                                {currencyStaticData[e].value}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                      </div>
                                      <span className="error_span">
                                        {this.state.budgetErrMsg}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="panel50 panel_half paddingleft15 form__group_normar">
                                  <label htmlFor="name" className="form__label">
                                    <b>Due date</b>
                                    {this.state.isUnApprovedEditPresent ==
                                      true &&
                                      editedDueDateFlagInit == true && (
                                        <div className="lable_popDetails_bg">
                                          <button className="btn_appearance_none lable_popBtn">
                                            Edited
                                          </button>
                                          <div className="lable_pop_details">
                                            <div className="lable_pop_detailsTop">
                                              {/* <button class="btn_appearance_none veh_btn">View edited history</button> */}
                                              <h5>Awaiting Confirmation</h5>
                                              <div className="clearboth" />
                                            </div>
                                            <p>{dueDateDataInit}</p>
                                            <p className="dtp">
                                              <span>
                                                {this.state
                                                  .latestActiveUnapprovedEditDate
                                                  ? CustDate.custFormatDate(
                                                      new Date(
                                                        this.state.latestActiveUnapprovedEditDate
                                                      ),
                                                      4
                                                    )
                                                  : "None"}
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      )}
                                  </label>
                                  <div className="form__group field ">
                                    <div
                                      className="input-group date"
                                      id="sandbox-container"
                                    >
                                      {/* <input
                                              type="text"
                                              className="form__field customdatefld"
                                              placeholder="23 March 2020"
                                            /> */}
                                      {this.customDate(
                                        editedDueDateFlagInit
                                          ? editedDueDateMillisDtObj
                                          : defaultDate,
                                        {
                                          editedDueDateFlag:
                                            editedDueDateFlagInit,
                                          editedDueDateMillisDtObj:
                                            editedDueDateMillisDtObj,
                                          defaultDate: defaultDate,
                                        }
                                      )}
                                    </div>
                                    <span className="error_span"></span>
                                  </div>
                                </div>
                                <div className="clearboth" />
                              </div>
                              <div className="form_block form_block_half">
                                <div className=" panel_half form__group_normar">
                                  <div className="s_form_fileup_bg">
                                    <div className="sform_attachment_sec">
                                      <div className="s_attachment">
                                        Task documents ({taskFilesCnt})
                                      </div>
                                      <div className="s_attachment_btnbg">
                                        <div className="s_att_btn s_att_btn_view">
                                          <button
                                            onClick={(evn) => {
                                              evn.preventDefault();
                                              let setShare =
                                                this.context !== undefined &&
                                                this.context.setShare !==
                                                  undefined
                                                  ? this.context.setShare
                                                  : null;

                                              let sharedData =
                                                this.context !== undefined &&
                                                this.context.share !== undefined
                                                  ? this.context.share
                                                  : null;

                                              if (setShare && sharedData) {
                                                sharedData.leftTabToActive =
                                                  "DOCUMENTS";
                                                sharedData.purpose =
                                                  "VIEW_DOC_LIST";
                                                setShare(sharedData);
                                              }
                                            }}
                                            className="btn_appearance_none s_view_btn"
                                          >
                                            View
                                          </button>
                                        </div>
                                        <div className="s_att_btn s_att_btn_upload">
                                          <div className="upload-btn-wrapper">
                                            <label
                                              className="new_upbutton"
                                              htmlFor="upload"
                                            >
                                              <button className="uploadbtn">
                                                Upload
                                              </button>
                                            </label>
                                            <input
                                              ref={this.fileUpl}
                                              multiple
                                              onChange={this.trackFileSelection}
                                              type="file"
                                              name="files"
                                            />
                                            <div className="clearboth" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="file_loder_bg">
                                      <div className="show_file_loder_div" />
                                      <span className="error_span">
                                        {this.state.fileUplErMsg}
                                      </span>
                                    </div>
                                    <div className="custom_fileup_name">
                                      {this.state.uploadingFlag && (
                                        <div>Uploading...</div>
                                      )}

                                      {Object.keys(taskFiles).length > 0 &&
                                        Object.keys(taskFiles).map((k, i) => (
                                          <div
                                            key={i}
                                            className="upfile_name_box"
                                          >
                                            <span className="upfile_name">
                                              {taskFiles[k].name}
                                            </span>
                                            {(this.state.assignedTo == null ||
                                              (taskFiles[k].notSavedYet !==
                                                undefined &&
                                                taskFiles[k].notSavedYet ==
                                                  true) ||
                                              (this.state.assignedTo !== null &&
                                                taskFiles.length < 5)) && (
                                              <button
                                                onClick={(e) => {
                                                  e.preventDefault();

                                                  this.removeDocOnClick(i);
                                                }}
                                                className="upfile_name_cross btn_appearance_none"
                                              >
                                                <img
                                                  src={
                                                    getBaseUrl() +
                                                    "assets/images/error.png"
                                                  }
                                                />
                                              </button>
                                            )}
                                          </div>
                                        ))}
                                      {/* <div className="upfile_name_box">
                                              <span className="upfile_name">
                                                Sample-File-Name dfdfd
                                              </span>
                                              <button className="upfile_name_cross btn_appearance_none">
                                                <img src="assets/images/error.png" />
                                              </button>
                                            </div>
                                           */}
                                    </div>
                                  </div>
                                </div>
                                <div className="clearboth" />
                              </div>
                            </div>
                            {/* === enter task box end ===  */}
                            {/* == main from box button section start ==  */}
                            <div className="mainFormBox_btnSec">
                              {!this.state.updateSuccessRcvdFlag && (
                                <div className="s_footer_btnbg">
                                  <button
                                    onClick={(evn) => {
                                      let setShare =
                                        this.context !== undefined &&
                                        this.context.setShare !== undefined
                                          ? this.context.setShare
                                          : null;

                                      let sharedData =
                                        this.context !== undefined &&
                                        this.context.share !== undefined
                                          ? this.context.share
                                          : null;

                                      if (setShare && sharedData) {
                                        sharedData.leftTabToActive =
                                          "TASKDETAILS";
                                        sharedData.purpose = "VIEW";
                                        setShare(sharedData);
                                      }
                                    }}
                                    type="button"
                                    data-dismiss="modal"
                                    className="btn_appearance_none modal_text_btn"
                                  >
                                    <span>Cancel</span>
                                  </button>
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setTimeout(() => {
                                        this.hitUpdateApi();
                                      }, 1200);
                                    }}
                                    className="btn_appearance_none sub_grd_btn"
                                  >
                                    <span>update</span>
                                  </button>
                                </div>
                              )}
                            </div>
                            {/* == main from box button section end ==  */}
                          </div>
                        </div>
                        {/* ====  slidebox end ====  */}
                      </div>
                    </div>
                  </div>

                  {/* </Scrollbars> */}
                </div>
              </div>
              {/* === right slide section Middlie  end ===  */}
              {/* === right slide section Footer  start ===  */}
              <div className="SlideS_bottombg flexbox-item SlideS_bottombg_stickyFooterRightPart SlideS_bottombg_stickyFooterRightPart_taskDetails ">
                <div className="s_footer_btnbg"></div>
              </div>
              {/* === right slide section Footer  end ===  */}
            </div>
          </div>
        </div>

        {/* Dashboard Slide Split Right end  */}
      </>
    );
  };

  // Newest1 - End

  trackFileSelection = (e) => {
    e.preventDefault();
    let fileObj = e.target.files;

    let chkFileValidation = this.validateFileSelected(fileObj);

    //this.calUploadFileService(fileObj);
    if (chkFileValidation) {
      this.calUploadFileService(fileObj);
    } else {
      this.fileUpl.current.value = "";
    }
  };

  validateFileSelected(fileObj) {
    const maxFileUploadAllowed = 5;
    const totAllowedMaxFileUploadSize = 500 * 1024 * 1024; // 500 MB
    const allowedFileExt = getCommonAllowedFileExt();

    const taskFiles = this.state.taskFiles;
    const docsData = taskFiles;
    let respdataFlag = true;
    let stepOneStFileUplErMsg = "";
    if (docsData !== undefined) {
      //console.log(" docsData.length=>>>",docsData.length);
      if (docsData.length >= maxFileUploadAllowed) {
        //error
        respdataFlag = false;
        stepOneStFileUplErMsg =
          "Sorry you cannot upload more than " +
          maxFileUploadAllowed +
          "  files in whole.";
      } else {
        if (docsData.length < maxFileUploadAllowed) {
          let nowtotAllowedCntOfFileUpl =
            maxFileUploadAllowed - docsData.length;
          if (fileObj.length <= nowtotAllowedCntOfFileUpl) {
            // check file validations like size and extension
            let totPrevSize = 0;
            Object.keys(docsData).map((k, i) => {
              totPrevSize = totPrevSize + docsData[k].size;
            });
            ////console.log.log("Total attempted size=>",totSize);

            let totCurSize = 0;
            let totalInvalidExtAr = [];
            Object.keys(fileObj).map((k, i) => {
              totCurSize = totCurSize + fileObj[k].size;
              let nameFl = fileObj[k].name;
              let extnReceived = nameFl.split(".").pop();
              if (allowedFileExt.indexOf(extnReceived) == -1) {
                totalInvalidExtAr.push(extnReceived);
              }
            });

            ////console.log.log("chk size=>> totPrevSize=>"+totPrevSize+" totCurSize=>"+totCurSize, "Totl size now=>>"+(totCurSize + totPrevSize), " totAllowedMaxFileUploadSize=>>>"+totAllowedMaxFileUploadSize );
            if (totCurSize + totPrevSize > totAllowedMaxFileUploadSize) {
              //error
              respdataFlag = false;
              stepOneStFileUplErMsg =
                "You have crossed total maximum upload file size.";
            }

            if (totalInvalidExtAr.length > 0) {
              //console.log.log("totalInvalidExtAr=>",totalInvalidExtAr);
              //error
              respdataFlag = false;
              stepOneStFileUplErMsg =
                "These file type(s) " +
                totalInvalidExtAr.toString() +
                " are not allowed.";
            }
          } else {
            //error
            respdataFlag = false;
            stepOneStFileUplErMsg =
              "You cannot upload more than " +
              maxFileUploadAllowed +
              " files in total (It includes previously uploaded files if any).";
          }
        }
      }
    } else {
      respdataFlag = false;
      stepOneStFileUplErMsg = "Something went wrong. Please try again.";
    }

    this.isComponentLoaded &&
      this.setState({ fileUplErMsg: stepOneStFileUplErMsg });
    return respdataFlag;
  }

  async calUploadFileService(fileObj) {
    ////console.log.log("chk file fileObj=>>>",fileObj);
    let lserv = new FileUploadService();
    this.isComponentLoaded && this.setState({ uploadingFlag: true });

    let taskTypeFlag = 0;

    let paramObjData = {
      taskTypeFlag: taskTypeFlag,
      data: fileObj,
      fileUploadedFromPageSection: "SINGLETASKEDIT",
      fileUploadFor: "TASKDOCUMENT",
      taskAssigned: this.state.assignedTo ? this.state.assignedTo : null,
      taskId: this.state.taskId,
    };

    lserv
      .uploadFiletoServer(paramObjData)
      .then((data) => {
        //this.setStepOneStDocuments();
        //console.log.log("success resp=>",data);
        let entityObj = data.data.entity;

        let otherParam = { action: "push" };
        this.setStatesForSTEdit("uplDocuments", entityObj, otherParam);
      })
      .catch((e) => {
        //console.log.log("server error=>",e);
      })
      .finally(() => {
        this.fileUpl.current.value = "";
        this.isComponentLoaded && this.setState({ uploadingFlag: false });
      });
  }

  removeDocOnClick(i) {
    let otherParam = { action: "remove" };
    this.setStatesForSTEdit("uplDocuments", i, otherParam);
  }

  /*** update Confirm Modal  starts  */
  /**
   * SplitScreenSingleTaskDetailsEdit
   * Function name : SplitScreenSingleTaskDetailsEdit
   * Author        :
   * Created Date  : 29-3-2020
   * Purpose       : To open delete modal
   * Params        :
   **/
  updateConfirmModalOpen = (otherParam) => {
    this.isComponentLoaded &&
      this.setState({ showUpdateConfirmModal: true }, () => {
        this.modalforcmfrmupdtRef.current.showModal();
        this.getUpdateConfirmModalContent();
      });
  };

  /**
   * TaskAddSingleTaskStep2
   * Function name : organisationDetailModalClose
   * Author        :
   * Created Date  : 11-2-2020
   * Purpose       : To close organisationDetailModal
   * Params        :
   **/
  updateConfirmModalClose = () => {
    this.isComponentLoaded &&
      this.setState({ showUpdateConfirmModal: false }, () => {
        this.modalforcmfrmupdtRef.current.hideModal();
      });
  };

  /**
   * TaskAddSingleTaskStep2
   * Function name : getOrganisationDetailModalOpen
   * Author        :
   * Created Date  : 11-2-2020
   * Purpose       : Get modal view with data
   * Params        :
   **/
  getUpdateConfirmModalContent() {
    const { cancelButtonAction } = this.props;

    let viewData = (
      <div className="dash_modal">
        <>
          <div className="modal-header">
            <h4 className="modal-title modal_title_tool">
              Edit Task
              {/*<div className="input_tooltip_bg">
                        <span className="input_tooltip" aria-label="Organisation's Details" data-balloon-pos="down">
                        <i className="fa fa-question-circle"></i>
                        </span>
                        </div>*/}
            </h4>
            <button
              type="button"
              onClick={(evn) => {
                evn.preventDefault();
                this.updateConfirmModalClose();
              }}
              className="close modal_close"
              data-dismiss="modal"
            >
              &times;
            </button>
          </div>
          <CustomMessageBar />
          <div className="modal-body padding_top">
            {/* "Notify all professionals about change in task details?" */}
            {this.state.assignedTo === null ? (
              <div class="popUP_text_p">
                <p>
                  All the Qualified Professionals will be notified once you
                  update the task details. This will have to be re-negotiated
                  with Interested Professionals based on updated task details.
                </p>
              </div>
            ) : (
              <div class="popUP_text_p">
                <p>
                  You are trying to edit Task Details of an Ongoing Task. This
                  can only be done with the approval of the Task Assignee. We
                  will notify the Task Assignee who will have to approve this
                  edit request.
                </p>
              </div>
            )}
          </div>
          <div className="modal-footer">
            {/* <button onClick={(evn)=>{evn.preventDefault();
                 this.updateConfirmModalClose();
                }} type="button" data-dismiss="modal" className="btn_appearance_none modal_text_btn"><span>Cancel</span></button> */}
            <button
              disabled={this.state.continueDisabled}
              type="button"
              className="btn btn-primary modal_btn_save"
              onClick={(e) => {
                e.preventDefault();

                this.isComponentLoaded &&
                  this.setState({ continueDisabled: true }, () => {
                    this.updateTask();
                  });
              }}
              data-dismiss="modal"
            >
              <span>Continue</span>
            </button>
          </div>
        </>
      </div>
    );

    this.modalforcmfrmupdtRef.current.updateStateContent(viewData);
    this.modalforcmfrmupdtRef.current.showContent();
    return viewData;
  }

  /*** update Confirm Modal ends  */

  // trackNotiForCloseSplit = (otherParam) =>{

  //   let notiType =  otherParam.notiType!==undefined? otherParam.notiType: "";
  //   let trigerredFrom =  otherParam.trigerredFrom!==undefined? otherParam.trigerredFrom: "";
  //   if(notiType=="CLOSE_SPLITSCREEN"){
  //       let closeSplitHeaderCallFrm = ["SINGLE_TASK_DETAILS_EDIT"];
  //       if(closeSplitHeaderCallFrm.indexOf(trigerredFrom)!=-1){
  //               console.log("*******trigerredFrom=>>",trigerredFrom);
  //       }

  //   }
  // }

  /**
   * SplitScreenSingleTaskDetails
   * Function name : render
   * Author        :
   * Created Date  : 7-2-2020
   * Purpose       : lifecycle method
   * Params        :
   **/
  render() {
    return (
      <React.Fragment>
        {/* <NotiEmitTrack callbackfuncforcommontrackofnoti={this.trackNotiForCloseSplit} /> */}
        {this.loadTheContent()}

        {/* Confirm delete modal start */}
        <CustReactBootstrapModal
          ref={this.modalforcmfrmupdtRef}
          backdrop={false}
          backdropClassName="custom_modal_class"
          animation={false}
          show={this.state.showUpdateConfirmModal}
          onShow={() => {}}
          onHide={() => {}}
          modalcustdialog="custom_modal_lg"
          loaderbodytitle="Organisation"
        />
        {/* Confirm delete modal end */}

        {/* Newest1 - Start */}

        {/* Professional View Disclaimer Modal start */}
        <CustReactBootstrapModal
          ref={this.modalforpvdisclaimerRef}
          backdrop={false}
          backdropClassName="custom_modal_class"
          animation={false}
          show={this.state.showPVDisclaimerModal}
          onShow={() => {}}
          onHide={() => {}}
          modalcustdialog="custom_modal_lg modal-dialog-centered modal_newProfileViewConfirmation"
          loaderbodytitle="Organisation"
        />
        {/* Professional View Disclaimer Modal end */}

        {/* Professional View Display Modal start */}
        <CustReactBootstrapModal
          ref={this.modalforpvdisplayRef}
          backdrop={false}
          backdropClassName="custom_modal_class"
          animation={false}
          show={this.state.showPVDisplayModal}
          onShow={() => {}}
          onHide={() => {}}
          modalcustdialog="custom_modal_lg modal-dialog-centered modal_newProfileViewFullDetails"
          loaderbodytitle="Organisation"
        />
        {/* Professional View Display Modal end */}

        {/* Newest1 - End */}
      </React.Fragment>
    );
  }
}

export default SplitScreenSingleTaskDetailsEdit;
