/**  
 * TaskDetailsService
 * Page/Class name   : DraftDetailFetchService
 * Author            :
 * Created Date      : 6-3-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, getTweetRecords
 * Purpose           : api service page
**/
import {Component } from 'react';
// import {getEndPoint,getTokenFromLocStorage, callApi} from  '../../common_utilities/commonjsfunc';
// newly added axios
//import axios from 'axios';
// load AxiosCustom 
import {axiosCustomObj} from '../customisedthirdparty/AxiosCustom';

import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
const callApi = commonJsObj.callApi;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;

const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;



class TaskDetailsService extends Component {

    
    /**  
     * TaskDetailsService
     * Function name : constructor
     * Author        :
     * Created Date  : 30-3-2020
     * Purpose       : initializing state
     * Params        : 
    **/
    constructor() {
      super();
      this.state = {
          
      }

      //this.axiosObj = axios;
      //this.axiosObj = axiosCustomObj({});
      this.axiosObj = null; 
      
      this.apiEndPoint = commonJsObj.getEndPoint()+'rest/tasks/task_details';
    }

    /**  
     * FloorPriceBasedOnCategAndTypeService
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 6-3-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentDidMount() {
    }

    /**  
     * FloorPriceBasedOnCategAndTypeService
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 6-3-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentWillUnmount() {  
    }



    /**  
     * DraftDetailFetchService
     * Function name : getDraftDetail
     * Author        :
     * Created Date  : 30-3-2020
     * Purpose       : get Draft Detail 
     * Params        : paramObj
    **/
    async getTaskDetail(paramObj){
      this.axiosObj = axiosCustomObj({});
      let accToken = getTokenFromLocStorage();
      let offsetOfLocalDateTime = getOffsetOfLocalDateTime(new Date());

      try{
            let offsetHr = offsetOfLocalDateTime.hr;
            let offsetMin = offsetOfLocalDateTime.min;
            let taskId = paramObj.data.taskId;
            let urldata =this.apiEndPoint; 

            urldata = urldata+"/task_id/"+taskId+"?"+"offset_hour="+offsetHr+"&offset_minute="+offsetMin;


            let headerOptn = {  
              "Content-Type": "application/json",
              "Authorization": accToken
            };          

            let respObj = this.axiosObj.get(urldata,{
            headers: headerOptn
            });

            return respObj;
    }
    catch(err){
        console.log("Some error occured===>",err); // TypeError: failed to fetch
    }
      

    }

    
}
export default TaskDetailsService;