/**  
 * CustomMessageBar
 * Page/Class name   : CustomMessageBar
 * Author            :
 * Created Date      : 6-2-2020
 * Functionality     : Header
 * Purpose           : load messagebar component
**/
import React, { Component } from 'react';
import {Link, NavLink, withRouter} from 'react-router-dom';

// import { store } from 'react-notifications-component';
//loading some custom static images to be used
import customimages from '../../custom_resources/images';

import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
// const removeTokenFromLocStorage = commonJsObj.removeTokenFromLocStorage;
// const getUserEmail = commonJsObj.getUserEmail;

import ee from 'event-emitter';

const emitter = new ee();

export const notify = (msg,showFlag, otherParam) => {
    emitter.emit('customnotification', msg, showFlag, otherParam);
}

const initialStateData ={
    'msg' : '',
    'showFlag': false,
    'otherParam': {
        'successMsg':1,
        'customWrapClass':'',
        'msgKeyData':'',        
        'callbackClose':null,
        'callbackOpen': null,
        'dismiss':{}
    }
};

class CustomMessageBar extends Component {

    isComponentLoaded = false;

    /**
        * Header
        * Function name : constructor
        * Author        :
        * Created Date  : 23-10-2019
        * Purpose       : initializing state
        * Params        : props
       **/
    constructor(props) {
        super(props);
        this.state = initialStateData;
        

        emitter.on('customnotification',(msg,showFlag,otherParam)=>{            
            //console.log("000 ===customnotification==>>",msg,showFlag,otherParam);
             let showFlagData = false;
             if(showFlag===false|| showFlag===true){
                showFlagData = showFlag;
             }   

            let successMsg = 0;
            if(otherParam && otherParam.successMsg!==undefined){
                successMsg = otherParam.successMsg;
            }

            let customWrapClass = '';
            if(otherParam && otherParam.customWrapClass!==undefined){
                customWrapClass = otherParam.customWrapClass;
            }  
            
            let msgKeyData = 'genericK1';
            if(otherParam && otherParam.msgKeyData!==undefined){
                msgKeyData = otherParam.msgKeyData;
                //console.log("0 from emitter==>",otherParam.msgKeyData," msgKeyData==>",msgKeyData);
            }      

            let callbackClose = null;
            if(otherParam && otherParam.callbackClose!==undefined){
                callbackClose = otherParam.callbackClose;
            }

            let callbackOpen = null;
            if(otherParam && otherParam.callbackOpen!==undefined){
                callbackOpen = otherParam.callbackOpen;
            }

            let showCloseButton = true;
            if(otherParam && otherParam.showCloseButton!==undefined){
                showCloseButton = otherParam.showCloseButton;
            }
            

            let dismissAfterDuration = ''; let dismissData = {};
            if(otherParam && otherParam.dismiss!==undefined && otherParam.dismiss.duration!==undefined){
                dismissAfterDuration  = otherParam.dismiss.duration;
                dismissData = {
                    "duration": dismissAfterDuration
                };
                setTimeout(()=>{
                    this.closeNotificationMessage();
                }, dismissAfterDuration);
            }

            this.isComponentLoaded && this.setState({"msg":msg,"showFlag":showFlagData,"otherParam":{
                "successMsg":successMsg,
                "customWrapClass":customWrapClass,
                "msgKeyData":msgKeyData,
                "callbackClose": () => {                    
                    if(callbackClose==null){
                        return null;
                    }else{                        
                        return callbackClose();
                    }
                
                },
                "callbackOpen": () => {                    
                    if(callbackOpen==null){
                        return null;
                    }else{                        
                        return callbackOpen();
                    }
                
                },
                "showCloseButton":showCloseButton,
                "dismiss": dismissData
            }},()=>{
                //console.log("**** in message 1 this.state=>>",this.state.msgKeyData);
            });

            

              

        });
    }

    closeNotificationMessage(){       
            
            if(this.state!==undefined){
                if(this.state.otherParam!==undefined){
                    if(this.state.otherParam.callbackClose!==null){            
                        this.state.otherParam.callbackClose(); 
                     }
                    if(this.state.otherParam.callbackOpen!==null){            
                        this.state.otherParam.callbackOpen();
                    } 
                }
                
            }            
    
            this.isComponentLoaded && this.setState(initialStateData);       
        
    }

    /**
     * Header
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 22-11-2019
     * Purpose       : Life cycle hook method
     * Params        : 
     **/
    componentDidMount(){
        this.isComponentLoaded = true;
        // this.isComponentLoaded && this.setState({'u_email':getUserEmail()});
       
       
                   
    }

    /**
     * Header
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 22-11-2019
     * Purpose       : Life cycle hook method
     * Params        : 
     **/
    componentWillUnmount(){
        this.isComponentLoaded = false;
     }

    

    /**
     * Header
     * Function name : render
     * Author        :
     * Created Date  : 22-11-2019
     * Purpose       : Life cycle hook method
     * Params        : 
     **/
    render() {
        let msgKeyData = this.state.otherParam.msgKeyData;
        let propMsgKeyData = this.props.keydata!==undefined?this.props.keydata:"genericK1";

        //console.log("**** 2 in message 1 propMsgKeyData=>>",propMsgKeyData, " msgKeyData===>",msgKeyData);

        if(msgKeyData==propMsgKeyData){
            return (
                <>
                {/* -- Alerts section start --  */}
              
                     <div key={propMsgKeyData} style={{display:this.state.showFlag?this.state.showFlag:'none'}} className={`alert_secction ${this.state.otherParam.customWrapClass? this.state.otherParam.customWrapClass:''} `}>
                    <div className={`alert alert-dismissible alert_custom ${ this.state.otherParam.successMsg == 1? 'alert-success': 'alert-danger' }`}>
                    {this.state.otherParam.showCloseButton && 
                                        <button onClick={()=>{this.closeNotificationMessage()}}  type="button" className="close" >
                                        <span>×</span>
                                        </button>
                    }                    
                    <p>{this.state.msg}</p>
                        
                    </div>
                    
                    {/* <div className="alert alert-dismissible alert_custom alert-danger">
                        <button type="button" className="close" data-dismiss="alert">
                        <span>×</span>
                        </button>
                        <p>Oops!! An error has occured</p>
                    </div> */}
                    </div>
                    
                {/* -- Alerts section end --  */}
                </>
            );
        }else{
            return (<></>);
        }
       
    }

}

export default withRouter(CustomMessageBar);