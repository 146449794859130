/**
 * SplitScreenPart
 * Page/Class name   : MasterLayout
 * Author            :
 * Created Date      : 7-2-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : render master component
 **/
import React, { Component } from "react";

import SplitScreenSingleTaskDetails from "../content/after_login/SplitScreenSingleTaskDetails";
import SplitScreenSingleTaskWithSubTaskDetails from "../content/after_login/SplitScreenSingleTaskWithSubTaskDetails";
import SplitScreenSingleTaskDetailsEdit from "../content/after_login/SplitScreenSingleTaskDetailsEdit";
import SplitScreenSingleTaskWithSubTaskDetailsEdit from "../content/after_login/SplitScreenSingleTaskWithSubTaskDetailsEdit";
import SplitScreenProfessionalsInterested from "../content/after_login/SplitScreenProfessionalsInterested";
import SplitScreenProfessionalsNegotiationChat from "../content/after_login/SplitScreenProfessionalsNegotiationChat";
import SplitScreenMonitorTaskChat from "../content/after_login/SplitScreenMonitorTaskChat";
import SplitScreenTaskDocuments from "../content/after_login/SplitScreenTaskDocuments";
import SplitScreenTimeLogs from "../content/after_login/SplitScreenTimeLogs";
import SplitScreenPreAssingmentTaskDocuments from "../content/after_login/SplitScreenPreAssingmentTaskDocuments";
import SplitScreenTaskInvoiceView from "../content/after_login/SplitScreenTaskInvoiceView";
import SplitScreenTaskInvoiceHistory from "../content/after_login/SplitScreenTaskInvoiceHistory";
import SplitScreenTaskInvoice from "../content/after_login/SplitScreenTaskInvoice";

// import CustomMessageBar from '../messagebar/CustomMessageBar';

// for context
import CommonContext from "../context/CommonContext";

class SplitScreenPart extends Component {
  isComponentLoaded = false;
  // for context
  static contextType = CommonContext;

  /**
   * SplitScreenPart
   * Function name : constructor
   * Author        :
   * Created Date  : 7-2-2020
   * Purpose       : initializing children
   * Params        : props
   **/
  constructor(props) {
    super(props);
    this.children = props.children;

    this.state = {
      leftTabToActive: "",
      purpose: "",
      taskId: 0,
      // task details view related
      taskWithSubTaskFlag: 0, // 0=> not with sub task, 1=> yes with subtask
    };
  }

  /**
   * MasterLayout
   * Function name : componentDidMount
   * Author        :
   * Created Date  : 7-2-2020
   * Purpose       : lifecycle method
   * Params        :
   **/
  componentDidMount() {
    this.isComponentLoaded = true;
  }

  /**
   * MasterLayout
   * Function name : componentWillUnmount
   * Author        :
   * Created Date  : 7-2-2020
   * Purpose       : lifecycle method
   * Params        :
   **/
  componentWillUnmount() {
    this.isComponentLoaded = false;
  }

  /**
   * MasterLayout
   * Function name : render
   * Author        :
   * Created Date  : 7-2-2020
   * Purpose       : lifecycle method
   * Params        :
   **/
  render() {
    const share = {};

    let paramObjStatus = {
      leftTabToActive: "",
      purpose: "",
      taskId: 0,
      taskWithSubTaskFlag: 0, // 0=> not with sub task, 1=> yes with subtask
    };

    //********* for context starts *****
    let sharedData =
      this.context !== undefined && this.context.share !== undefined
        ? this.context.share
        : null;
    if (
      sharedData !== undefined &&
      sharedData !== null &&
      sharedData.actionType !== undefined
    ) {
      if (sharedData.actionType == "SPLITSCREEN") {
        console.log("****HELLO sharedData====>", sharedData);

        paramObjStatus.taskId = sharedData.taskId;
        paramObjStatus.leftTabToActive = sharedData.leftTabToActive;
        paramObjStatus.purpose = sharedData.purpose;
        paramObjStatus.taskWithSubTaskFlag =
          sharedData.other !== undefined &&
          sharedData.other &&
          sharedData.other.subTasksCount !== undefined &&
          sharedData.other.subTasksCount
            ? 1
            : 0;
      }
    }
    //********* for context ends *****

    let { leftTabToActive, purpose, taskId, taskWithSubTaskFlag } =
      paramObjStatus;
    const taskDetailTabRel = {
      leftTabToActive,
      purpose,
      taskId,
      taskWithSubTaskFlag,
    };
    //console.log(" leftTabToActive=>>",leftTabToActive," sharedData=>",sharedData);
    switch (leftTabToActive) {
      case "TASKDETAILS":
        if (purpose == "VIEW") {
          if (taskWithSubTaskFlag == 0) {
            //without sub task
            return <SplitScreenSingleTaskDetails values={taskDetailTabRel} />;
          } else if (taskWithSubTaskFlag == 1) {
            //with sub task
            return (
              <SplitScreenSingleTaskWithSubTaskDetails
                values={taskDetailTabRel}
              />
            );
          }
        } else if (purpose == "EDIT") {
          if (taskWithSubTaskFlag == 0) {
            //without sub task
            return (
              <SplitScreenSingleTaskDetailsEdit values={taskDetailTabRel} />
            );
          } else if (taskWithSubTaskFlag == 1) {
            //with sub task
            return (
              <SplitScreenSingleTaskWithSubTaskDetailsEdit
                values={taskDetailTabRel}
              />
            );
          }
        } else {
          return <></>;
        }

        break;
      case "PROFESSIONALSINTERESTED":
        return <SplitScreenProfessionalsInterested />;
        break;
      case "PROFESSIONALTASKNEGOTIATIONCHAT":
        return <SplitScreenProfessionalsNegotiationChat />;
        break;
      case "MONITORTASK":
        return <SplitScreenMonitorTaskChat />;
        break;
      case "DOCUMENTS":
        if (purpose == "SHOW_PRE_ASSINGMENT_DOC") {
          return <SplitScreenPreAssingmentTaskDocuments />;
        } else if (purpose == "VIEW_DOC_LIST") {
          return <SplitScreenTaskDocuments />;
        }
        break;
      case "TIMELOGS":
        return <SplitScreenTimeLogs />;
        break;
      case "INVOICE_MASTER":
        if (purpose == "DETERMINE_WHICH_VIEW") {
          return <SplitScreenTaskInvoice />;
        }
        break;
      case "INVOICE":
        if (purpose == "VIEW_INVOICE") {
          return <SplitScreenTaskInvoiceView />;
        } else if (purpose == "VIEW_INVOICE_LIST") {
          return <SplitScreenTaskInvoiceHistory />;
        }
        break;

      default:
        return <></>;
        break;
    }
  }
}

export default SplitScreenPart;
