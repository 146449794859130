/**  
 * ChatListOfFreeFlowMonitorService
 * Page/Class name   : TaskListForSingleTaskService
 * Author            :
 * Created Date      : 11-5-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, getTweetRecords
 * Purpose           : api service page
**/
import {Component } from 'react';
// import {getEndPoint,getTokenFromLocStorage, callApi} from  '../../common_utilities/commonjsfunc';
// newly added axios
//import axios from 'axios';
import {axiosCustomObj} from '../customisedthirdparty/AxiosCustom';


import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
const callApi = commonJsObj.callApi;
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getOffsetOfLocalDateTime = commonJsObj.getOffsetOfLocalDateTime;
const getMaxPageSize = commonJsObj.getMaxPageSize;


class ChatListOfFreeFlowMonitorService extends Component {

    
    /**  
     * ChatListOfFreeFlowMonitorService
     * Function name : constructor
     * Author        :
     * Created Date  : 11-5-2020
     * Purpose       : initializing state
     * Params        : 
    **/
    constructor() {
      super();
      this.state = {
          
      }

      //this.axiosObj = axios;
      this.axiosObj = null; 
      
      this.apiEndPoint = commonJsObj.getEndPoint()+'rest/tasks/comments';
    }

    /**  
     * ChatListOfFreeFlowMonitorService
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 11-5-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentDidMount() {
    }

    /**  
     * ChatListOfFreeFlowMonitorService
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 11-5-2020
     * Purpose       : life cycle method
     * Params        : 
    **/
    componentWillUnmount() {  
    }



    /**  
     * ChatListOfFreeFlowMonitorService
     * Function name : getLoginResp
     * Author        :
     * Created Date  : 11-5-2020
     * Purpose       : Login check to provide access token 
     * Params        : paramObj
    **/
    async getChatListOfFreeFlow(paramObj){
      this.axiosObj = axiosCustomObj({});
      let accToken = getTokenFromLocStorage();   
      let offsetOfLocalDateTime = getOffsetOfLocalDateTime(new Date());

      try{
            let urldata =this.apiEndPoint; 

            const defaultPageSize = getMaxPageSize();
            

            let offsetHr = offsetOfLocalDateTime.hr;
            let offsetMin = offsetOfLocalDateTime.min;
           
            let data  = paramObj.data;
            let lpUserId = data.lpUserId?data.lpUserId:0;

            let pageNum = data.pageNum?data.pageNum:1;
            let pageSize = data.pageSize?data.pageSize:defaultPageSize;
            let lastCommentId = data.lastCommentId?data.lastCommentId:0;

            let taskIdOrSubTaskId = data.taskIdOrSubTaskId?data.taskIdOrSubTaskId:0;
            let taskOrSubTask = data.taskOrSubTask?data.taskOrSubTask:"";
            


            if(taskOrSubTask=="STASK"){
              urldata = urldata+"/task/"+taskIdOrSubTaskId+"/?"+"offset_hour="+offsetHr+"&offset_minute="+offsetMin+"&page_number="+pageNum+"&page_size="+pageSize+"&last_comment_id="+lastCommentId;
            }
            else if(taskOrSubTask=="SWSTASK"){
              urldata = urldata+"/sub_task/"+taskIdOrSubTaskId+"/?"+"offset_hour="+offsetHr+"&offset_minute="+offsetMin+"&offset_minute="+offsetMin+"&page_number="+pageNum+"&page_size="+pageSize+"&last_comment_id="+lastCommentId;
            }            

            //console.log("free flow chat list api hitted with =>>",urldata);

            let headerOptn = {  
              "Content-Type": "application/json",
              "Authorization": accToken
            };          

            let respObj = this.axiosObj.get(urldata,{
            headers: headerOptn
            });

            return respObj;
    }
    catch(err){
        console.log("Some error occured===>",err); // TypeError: failed to fetch
    }
      

    }

    
}
export default ChatListOfFreeFlowMonitorService;