// Newest1 - Start

import React, { Component } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import CustLoader from "./CustLoader";

// Services
import AddProfInfoFetchKYCService from "../../service/AddProfInfoFetchKYCService";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class ProfileNewMod extends Component {
  /**
   * Profile
   * Function name : constructor
   * Author        :
   * Created Date  : 8-7-2021
   * Purpose       : initializing state
   * Params        :
   **/
  constructor(props) {
    super(props);
    this.state = {
      kYCObj: null,
      loaderFlag: true,
      kYCFetchError: "",
      base64PDF: null,
      pageNumber: 1,
      numPages: null,
      pDFLoadErr: false,
    };
    this.fileUpl = React.createRef();
  }

  /**
   * Profile
   * Function name : componentDidMount
   * Author        :
   * Created Date  : 8-7-2021
   * Purpose       : lifecycle method
   * Params        :
   **/
  componentDidMount() {
    this.isComponentLoaded = true;
    this.isComponentLoaded && this.callFetchKYCService();
  }

  /**
   * Profile
   * Function name : componentWillUnmount
   * Author        :
   * Created Date  : 8-7-2021
   * Purpose       : lifecycle method
   * Params        :
   **/
  componentWillUnmount() {
    this.isComponentLoaded = false;
  }

  /**
   * Profile
   * Function name : loadLoader
   * Author        :
   * Created Date  : 8-7-2021
   * Purpose       : load Loader
   * Params        :
   **/
  loadLoader() {
    return (
      <CustLoader className={"smallLoaderGreen"} loaderText={"Loading.."} />
    );
  }

  /**
   * Profile
   * Function name : hideLoader
   * Author        :
   * Created Date  : 8-7-2021
   * Purpose       : Hide loader
   * Params        :
   **/
  hideLoader() {
    this.isComponentLoaded && this.setState({ loaderFlag: false });
  }

  /**
   * Profile
   * Function name : showLoader
   * Author        :
   * Created Date  : 8-7-2021
   * Purpose       : showLoader
   * Params        :
   **/
  showLoader() {
    this.isComponentLoaded && this.setState({ loaderFlag: true });
  }

  async callFetchKYCService() {
    let stcservice = new AddProfInfoFetchKYCService();

    let lpUserId =
      this.props && this.props.lprofileId ? this.props.lprofileId : null;

    let kYCFetchError = "";

    if (lpUserId) {
      stcservice
        .fetchKYCDetails(lpUserId)
        .then((data) => {
          if (data && data.status === 200) {
            let kYCObj = data.data.entity;
            this.isComponentLoaded && this.setState({ kYCObj });
            this.isComponentLoaded &&
              kYCObj.additionalProfileInfoDoc &&
              kYCObj.additionalProfileInfoDoc.base64String &&
              this.setState({
                base64PDF: kYCObj.additionalProfileInfoDoc.base64String,
              });
          }
        })
        .catch((err) => {
          let errStatusCodeArr = [400, 500]; // allowed error status code
          if (err.response && err.response.data) {
            let errRespData = err.response.data;
            if (errStatusCodeArr.indexOf(errRespData.status) !== -1) {
              kYCFetchError = errRespData.message
                ? errRespData.message
                : "Some technical problem occurred while loading the KYC.";
            }
          }
        })
        .finally(() => {
          this.hideLoader();
        });
    } else {
      this.hideLoader();
      kYCFetchError = "Unable to retrieve LP user ID.";
    }

    this.isComponentLoaded && this.setState({ kYCFetchError });
  }

  onPDFLoadErr = (error) => {
    this.isComponentLoaded && this.setState({ pDFLoadErr: true });
  };

  onPDFLoadSuccess = ({ numPages }) => {
    this.isComponentLoaded && this.setState({ numPages });
  };

  triggerPrevNextPdfPage = (action) => {
    let pageNumber = this.state.pageNumber;
    let numPages = this.state.numPages;
    if (action == "PREV") {
      if (pageNumber > 1) {
        pageNumber = pageNumber - 1;
      }
    } else if (action == "NEXT") {
      if (numPages !== null && pageNumber != numPages) {
        pageNumber = pageNumber + 1;
      }
    }
    this.isComponentLoaded && this.setState({ pageNumber });
  };

  closePV = () => {
    this.props && this.props.closingFunction();
  };

  isAnyWordInTextLongerThan25 = (text) => {
    let isLong = false;
    let words = text.split(" ");
    words.forEach((word) => {
      if (word.length >= 25) isLong = true;
    });
    return isLong;
  };

  kYCDetailsSection = () => {
    const { loaderFlag, kYCObj, kYCFetchError } = this.state;

    const base64String =
      kYCObj && kYCObj.profilePicture && kYCObj.profilePicture.base64String;
    const namePrefix = kYCObj && kYCObj.namePrefix ? kYCObj.namePrefix : "";
    const practiceOwnerName =
      kYCObj && kYCObj.practiceOwnerName ? kYCObj.practiceOwnerName : "";
    const lawFirmName = kYCObj && kYCObj.lawFirmName ? kYCObj.lawFirmName : "";
    const isLawFirmNameSameAsPracticeOwnerName =
      kYCObj && kYCObj.isLawFirmNameSameAsPracticeOwnerName
        ? kYCObj.isLawFirmNameSameAsPracticeOwnerName
        : "";
    const aboutThePractice =
      kYCObj && kYCObj.aboutThePractice ? kYCObj.aboutThePractice : "";
    const website = kYCObj && kYCObj.website ? kYCObj.website : "";
    const linkedInUrl = kYCObj && kYCObj.linkedInUrl ? kYCObj.linkedInUrl : "";
    const officialEmailAddress =
      kYCObj && kYCObj.officialEmailAddress ? kYCObj.officialEmailAddress : "";
    const officialPhoneNumber =
      kYCObj && kYCObj.officialPhoneNumber ? kYCObj.officialPhoneNumber : "";
    const graduationUniversityName =
      kYCObj && kYCObj.graduationUniversity && kYCObj.graduationUniversity.name
        ? kYCObj.graduationUniversity.name
        : null;
    const workExperience =
      kYCObj && kYCObj.workExperience ? kYCObj.workExperience : null;
    const practiceAreas =
      kYCObj && kYCObj.practiceAreas ? kYCObj.practiceAreas : [];
    let practiceAreaArr = [];
    practiceAreas.forEach((practiceObj) => {
      practiceAreaArr.push(practiceObj.practiceArea);
    });
    let practiceAreasText = "";
    if (practiceAreaArr.length > 0) {
      practiceAreasText = practiceAreaArr.toString().replaceAll(",", ", ");
    }

    const locationsActive =
      kYCObj && kYCObj.locationsActive ? kYCObj.locationsActive : [];
    let locationsActiveArr = [];
    locationsActive.forEach((locationObj) => {
      locationsActiveArr.push(
        locationObj.cityName + " (" + locationObj.state + ")"
      );
    });
    let locationsActiveText = "";
    if (locationsActiveArr.length > 0) {
      locationsActiveText = locationsActiveArr.toString().replaceAll(",", ", ");
    }

    const isKYCVerified =
      kYCObj && kYCObj.isVerified ? kYCObj.isVerified : false;

    return (
      <React.Fragment>
        {loaderFlag ? (
          this.loadLoader()
        ) : kYCObj && kYCObj.isSubmittedForKYC ? (
          <div className="newUserSection_leftBg">
            <div className="newUserSidebarBg">
              <div className="newUserSidebar_topSection">
                <div className="newUserSidebar_topImgBlock">
                  {/* <img src="assets/images/user-avatar-2.jpg" alt="" /> */}
                  <img src={`data:image/jpg;base64,${base64String}`} alt="" />
                </div>
                <div className="newUserSidebar_topTextBlock">
                  <h3 className="newUserSidebar_topTextBlock_h3">
                    {namePrefix}&nbsp;{practiceOwnerName || "NA"}
                  </h3>
                  {!isLawFirmNameSameAsPracticeOwnerName && (
                    <h4 className="newUserSidebar_topTextBlock_h4">
                      {lawFirmName || "NA"}
                    </h4>
                  )}
                </div>
                {/* <div className="newUserSidebar_topButtonBlock">
                  <Link to="/kyc">
                    <button className="btn_appearance_none newUserSidebar_topButtonEdit">
                      Edit
                    </button>
                  </Link>
                </div> */}
              </div>
              <div className="newUserSidebar_detailsSection">
                <div className="newUserSidebar_detailsSecBlock">
                  <h4>
                    <b>KYC Details</b>
                  </h4>
                  <p>
                    {isKYCVerified === true ? (
                      <span style={{ color: "green" }}>Verified</span>
                    ) : (
                      <span style={{ color: "red" }}>Verification Pending</span>
                    )}
                  </p>
                </div>
                <div className="newUserSidebar_detailsSecBlock">
                  <h4>About Me</h4>
                  <p
                    style={{
                      wordBreak: this.isAnyWordInTextLongerThan25(
                        aboutThePractice
                      )
                        ? "break-all"
                        : "normal",
                    }}
                  >
                    {aboutThePractice || "NA"}
                  </p>
                </div>
                {/* <div className="newUserSidebar_detailsSecBlock">
                  <h4>Educational Qualifications</h4>
                  <p>{"Educational Qualifications" || "NA"}</p>
                </div>
                <div className="newUserSidebar_detailsSecBlock">
                  <h4>Website</h4>
                  <p>{website || "NA"}</p>
                </div>
                <div className="newUserSidebar_detailsSecBlock">
                  <h4>LinkedIn</h4>
                  <p>{linkedInUrl || "NA"}</p>
                </div>
                <div className="newUserSidebar_detailsSecBlock">
                  <h4>Official Email Address</h4>
                  <p>{officialEmailAddress || "NA"}</p>
                </div>
                <div className="newUserSidebar_detailsSecBlock">
                  <h4>Official Phone Number</h4>
                  <p>{officialPhoneNumber || "NA"}</p>
                </div> */}
                <div className="newUserSidebar_detailsSecBlock">
                  <h4>University/College</h4>
                  <p>{graduationUniversityName || "NA"}</p>
                </div>
                <div className="newUserSidebar_detailsSecBlock">
                  <h4>Work Experience (in Years)</h4>
                  <p>{workExperience || "NA"}</p>
                </div>
                <div className="newUserSidebar_detailsSecBlock">
                  <h4>Specialisations/Practice Areas</h4>
                  <p>{practiceAreasText || "NA"}</p>
                </div>
                <div className="newUserSidebar_detailsSecBlock">
                  <h4>Locations Active</h4>
                  <p>{locationsActiveText || "NA"}</p>
                </div>
                {/* <div className="newUserSidebar_detailsSecBlock">
                  <h4>Membership and Affiliations</h4>
                  <p>{"Membership and Affiliations" || "NA"}</p>
                </div> */}
              </div>
            </div>
          </div>
        ) : kYCFetchError ? (
          kYCFetchError
        ) : (
          "KYC not yet submitted by the Professional."
        )}
      </React.Fragment>
    );
  };

  addProfInfoSection = () => {
    const { pageNumber, numPages, pDFLoadErr } = this.state;

    return (
      <div className="newUserSection_rightBg">
        <div className="newUserSection_rightBlock">
          {/* -- New User Right part - top title start --  */}
          <div className="newUserSection_rightTopTitle">
            <div className="newUserSection_rightTopTitle_left">
              <h3>Additional Profile Information</h3>
            </div>
          </div>
          {/* -- New User Right part - top title end --  */}
          {/* -- New User Right part - With Blank Message start --  */}
          {!this.state.base64PDF && (
            <div className="newUserSection_blankMsg">
              <h3>
                Additional profile information has not yet been updated by the
                Professional.
              </h3>
            </div>
          )}
          {/* -- New User Right part - With Blank Message end --  */}
          {/* -- New User Right part - With API backend structurfe start --  */}
          {this.state.base64PDF && (
            <div className="newUserSection_pdfView_bg">
              {/* === Dynamic Invoice Import section (copy Canvus HTML code) -- 01 start ===    */}
              <div className="invoiceviewpage forpdfviewcustclass newUserSection_forpdfviewcustclass">
                {pDFLoadErr === false && (
                  <div className="okPdf">
                    <div className="pdfpagi">
                      <p>
                        Page {pageNumber} of {numPages}
                      </p>
                      <button
                        className="prevClass"
                        onClick={(evn) => {
                          evn.preventDefault();
                          this.triggerPrevNextPdfPage("PREV");
                        }}
                      >
                        Previous
                      </button>
                      <button
                        className="nextClass"
                        onClick={(evn) => {
                          evn.preventDefault();
                          this.triggerPrevNextPdfPage("NEXT");
                        }}
                      >
                        Next
                      </button>
                    </div>
                    <div className="react-pdf__Document">
                      <div
                        className="react-pdf__Page"
                        style={{ position: "relative" }}
                        data-page-number="1"
                      >
                        {/* <img
                      src="assets/images/canvas.png"
                      alt=""
                    /> */}
                        <Document
                          file={`data:application/pdf;base64,${
                            this.state.base64PDF ? this.state.base64PDF : ""
                          }`}
                          onLoadSuccess={this.onPDFLoadSuccess}
                          onLoadError={this.onPDFLoadErr}
                        >
                          <Page pageNumber={pageNumber} />
                        </Document>
                      </div>
                    </div>
                  </div>
                )}
                {pDFLoadErr === true && (
                  <div className="errPdf text-center">
                    Something went wrong while loading the PDF.
                  </div>
                )}
              </div>
              {/* === Dynamic Invoice Import section (copy Canvus HTML code) -- 01 end ===    */}
            </div>
          )}
          {/* -- New User Right part - With API backend structurfe end --  */}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="dash_modal">
        {/* --- Modal Header start ---  */}
        <div className="modal-header">
          <h4 className="modal-title modal_title_tool">
            View Professional’s Profile
          </h4>
          <button
            type="button"
            onClick={() => {
              this.closePV();
            }}
            className="close modal_close"
            data-dismiss="modal"
          >
            ×
          </button>
        </div>
        {/* --- Modal Header end ---  */}
        {/* --- Modal Aleart start ---  */}
        {/* <CustomMessageBar /> */}
        {/* --- Modal Aleart end ---  */}
        {/* --- Modal Body start ---  */}
        <div className="modal-body modal_body_newProfileViewFullDetails_bg">
          {/* =============  New User Details - With in Modal Body - Popup start ===== */}
          {/* ==-***-== New User Details section start ==-***-== */}
          <div className="newUserSection_areaBg newUserSection_areaBg_for_page">
            {/* -- new User Details Left section start --  */}
            {this.kYCDetailsSection()}
            {/* -- new User Details Left section end --  */}
            {/* -- new User Details Right section start --  */}
            {this.addProfInfoSection()}
            {/* -- new User Details Right section end --  */}
            <div className="clearboth"></div>
          </div>
          {/* ==-***-== New User Details section end ==-***-== */}
          {/* =============  New User Details - With in Modal Body - Popup end ===== */}
        </div>
        {/* --- Modal Body end ---  */}
        {/* --- Modal Footer start ---  */}
        <div
          className="modal-footer modal_footer_newProfileViewFullDetails"
          style={{ display: "none" }}
        >
          <button
            type="button"
            data-dismiss="modal"
            className="btn_appearance_none modal_text_btn"
          >
            <span>Cancel</span>
          </button>
          <button className="btn_appearance_none green_btn">
            <span>View</span>
          </button>
          <button
            type="button"
            className="btn btn-primary modal_btn_save"
            data-dismiss="modal"
          >
            <span>Confirm</span>
          </button>
        </div>
        {/* --- Modal Footer end ---  */}
      </div>
    );
  }
}

export default ProfileNewMod;

// Newest1 - End
