/**
 * ViewDocFileButton
 * Page/Class name   : AssignButton
 * Author            :
 * Created Date      : 24-4-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : rendering loader structure
**/
import React, { Component, Suspense, lazy} from 'react';
import '../../../custom_resources/css/custom.css';

// Modal
import CustReactBootstrapModal from './CustReactBootstrapModal';
import CustomMessageBar from '../../messagebar/CustomMessageBar';
// Services
import AssignTaskOrSubTaskService from '../../service/AssignTaskOrSubTaskService';
// for context
import CommonContext from '../../context/CommonContext';

import { commonJsFuncModule as commonJsObj } from '../../../common_utilities/commonjsfunc';

// scroll 
import { Scrollbars } from 'react-custom-scrollbars';
// view pdf related
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const allowedTypeForPopup = ["jpeg","jpg","png","pdf"];
const getTokenFromLocStorage = commonJsObj.getTokenFromLocStorage;
const getBaseUrl = commonJsObj.getBaseUrl;

class ViewDocFileButton extends Component {
    /**
     * AssignButton
     * Function name : constructor
     * Author        :
     * Created Date  : 
     * Purpose       : initializing state
     * Params        : props
    **/

   isComponentLoaded = false;

   // for context
   static contextType = CommonContext; 

    constructor(props) {
        super(props);
        this.state = {

           
           // view file modal
        "showFileDispModal":false,
        "pageNumber":1,
        "numPages":null,
        "specificFileInfo":null,
        "pdfLoadErr": false,
            
        }    

         // view file modal
      this.filedisplaymodalRef = React.createRef();
    }



    /**
     * AssignButton
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentDidMount() {
        this.isComponentLoaded = true;

        
        
    }

    /**
     * AssignButton
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {  
        this.isComponentLoaded = false;
    }

    triggerViewFunc = (paramObj)=>{
      
        if(allowedTypeForPopup.indexOf(paramObj.fileType)!=-1){
         this.isComponentLoaded && this.setState({"specificFileInfo":paramObj},()=>{
              // openpopup
              this.fileViewModalOpen(paramObj);
          });
          
  
        }else{
          // trigger download
          window.location.href = paramObj.downloadAddress+'&auth='+getTokenFromLocStorage();
        }
      }

     /*** File view  starts  */
    /**
     * SplitScreenTaskDocuments
     * Function name : fileViewModalOpen
     * Author        :
     * Created Date  : 29-3-2020
     * Purpose       : To open file View modal
     * Params        : 
    **/  
   fileViewModalOpen = (otherParam)=>{    
    this.isComponentLoaded &&  this.setState({showFileDispModal:true},()=>{
      this.filedisplaymodalRef.current.showModal();
      this.getFileViewModalContent(otherParam);
    });    
      
  }

  /**
     * SplitScreenTaskDocuments
     * Function name : fileViewModalClose
     * Author        :
     * Created Date  : 29-3-2020
     * Purpose       : To close file View modal
     * Params        : 
    **/
   fileViewModalClose = ()=>{    
    this.isComponentLoaded && this.setState({showFileDispModal:false},()=>{
      this.filedisplaymodalRef.current.hideModal();
    });
    }

    /**
     * SplitScreenTaskDocuments
     * Function name : getFileViewModalContent
     * Author        :
     * Created Date  : 29-3-2020
     * Purpose       : Get modal view with data
     * Params        : 
    **/
    getFileViewModalContent(otherParam) {      

        

        let viewData = <div className="dash_modal">
        
                <>
            <div className="modal-header">
                <h4 className="modal-title modal_title_tool"> 
                {/* title heading */}
                </h4>
                <button type="button" onClick={
                    ()=>{
                    this.fileViewModalClose();

                }} className="btn_appearance_none slideTop_cross_btn cross" data-dismiss="modal">
                <img src={getBaseUrl()+"assets/images/red_cross.png"} />
            </button>
            </div>
            <CustomMessageBar />            
            <div className="modal-body padding_top">
            {otherParam.fileType!="pdf" && (
                  <div className="engagementPop_p forimgviewcustclass">
                  {otherParam.fileType!="pdf" ? <img src={`${"data:image/"+otherParam.fileType};base64,${otherParam.base64String}`}/>: ''}    
                  </div>

            )}

            {otherParam.fileType=="pdf" &&  
            this.loadPdfContent(otherParam)} 
               

          
              
            </div>
            <div className="modal-footer">
              
                {/* <button className="btn btn-primary modal_btn_save" onClick={(e)=>{ e.preventDefault();

                } } data-dismiss="modal"><span>Download</span></button> */}
                
            </div>
          
          
          </>
        
                        
        </div>;

        this.filedisplaymodalRef.current.updateStateContent(viewData);
        this.filedisplaymodalRef.current.showContent();
        return viewData;
    }    

    /*** File view Modal ends  */
   
     // view file modal
     onDocumentLoadSuccess = ({ numPages }) => {
        this.isComponentLoaded && this.setState({ numPages },()=>{
          let otherParam = this.state.specificFileInfo;
          setTimeout(()=>{
            this.getFileViewModalContent(otherParam);
          },1500);
          
          
        });
      }
  
      // view file modal
      onDocumentLoadErr = ( error ) => {
       //console.log(" === error=>>",error);
       this.isComponentLoaded && this.setState({"pdfLoadErr":true},()=>{
        let otherParam = this.state.specificFileInfo;
        setTimeout(()=>{
          this.getFileViewModalContent(otherParam);
          },900);
       });
  
      }
      // view file modal
      triggerPrevNextPdfPage = (action,param) => {
  
        let pageNumber = this.state.pageNumber;
        let numPages = this.state.numPages;
        if(action=="PREV"){
  
          if(pageNumber>1){
            pageNumber = pageNumber -1;
  
          }
  
        }else if(action=="NEXT"){
          if(numPages!==null && pageNumber!=numPages){
            pageNumber = pageNumber +1;
  
          }
  
        }
        this.isComponentLoaded && this.setState({"pageNumber":pageNumber},()=>{
          let otherParam = this.state.specificFileInfo;
          this.getFileViewModalContent(otherParam);
          
          
          
        });
      }
      // view file modal
      loadPdfContent = (otherParam) =>{
  
        const { pageNumber, numPages,pdfLoadErr } = this.state;
  
        return(<>
                  <div className="engagementPop_p forpdfviewcustclass">
                     {pdfLoadErr==false && (
                        <div className="okPdf"> 
                        {numPages>1 && (
                        <div className="pdfpagi">
                            <p>Page {pageNumber} of {numPages}</p>
                            <button className="prevClass" onClick={(evn)=>{
                              evn.preventDefault();
                              this.triggerPrevNextPdfPage("PREV",{});
                            }}>Previous </button>
                            <button className="nextClass" onClick={(evn)=>{
                              evn.preventDefault();
                              this.triggerPrevNextPdfPage("NEXT",{});
                            }}>Next</button>
                        </div>

                        )}   
                        <Document
                            file={`data:application/pdf;base64,${otherParam.base64String}`}
                            onLoadSuccess={this.onDocumentLoadSuccess}
                            onLoadError={this.onDocumentLoadErr}
                            
                          >
                            <Page pageNumber={pageNumber} />
                        </Document>
                       
                       
                        </div>  
  
                     )}
                     {pdfLoadErr==true && ( 
                      <div className="errPdf text-center">
                        Something went wrong, please try later.
                      </div>
                     )}
                  </div>      
                  {/* <div className="engagementPop_p forpdfviewcustclass">
                      <embed src={`data:application/pdf;base64,${otherParam.base64String}`}  type="application/pdf" width="100%" height="400px"></embed>
                  </div>     */}
                </>
        )
      }
    /**
     * AssignButton
     * Function name : render
     * Author        :
     * Created Date  : 
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() {    
       
       return (
             <>                
                <button onClick={(evn)=>{
                                            evn.preventDefault();
                                            this.triggerViewFunc(this.props.data);

                                        }} className="btn_appearance_none docView_btn">View</button>
               
                {/* File display Modal start */}
                <CustReactBootstrapModal ref={this.filedisplaymodalRef}  backdrop={false} backdropClassName="custom_modal_class "   animation={false} show={this.state.showFileDispModal} onShow={()=>{}} onHide={()=>{}} modalcustdialog="custom_modal_lg engageagreepopupcls engagementLetter_modal_bg viewfilemodal_bg"  loaderbodytitle="Organisation"  />
                {/* File display Modal end */}

             </>
            
        );
    }

  }
  export default ViewDocFileButton;