/**  
 * Header
 * Page/Class name   : Header
 * Author            :
 * Created Date      : 6-2-2020
 * Functionality     : Header
 * Purpose           : load Header component
**/
import React, { Component } from 'react';
import {Link, NavLink, withRouter} from 'react-router-dom';

import customimages from '../../custom_resources/images';
import '../../custom_resources/css/custom.css';

// Modal
import CustReactBootstrapModal from '../../components/content/after_login/CustReactBootstrapModal';



import ee from 'event-emitter';

//Newly added
import OrgUserAddComp from '../../components/content/after_login/OrgUserAddComp.js';
import OrgDetailInfoComp from '../../components/content/after_login/OrgDetailInfoComp.js';

import FeedbackCommon from '../../components/content/after_login/FeedbackCommon.js';
import LogoutCommon from '../../components/content/after_login/LogoutCommon.js';
import RequestDemoCommon from '../../components/content/after_login/RequestDemoCommon.js';
import BellNotificationList from '../../components/content/after_login/BellNotificationList.js';

// common noti track
import NotiEmitTrack from "../../components/content/after_login/NotiEmitTrack";
import {commonnotitrack} from "../../components/content/after_login/NotiEmitTrack";

// for context
import CommonContext from '../context/CommonContext';

import {commonJsFuncModule as commonJsObj} from  '../../common_utilities/commonjsfunc';
//const removeTokenFromLocStorage = commonJsObj.removeTokenFromLocStorage;
const getUserEmail = commonJsObj.getUserEmail;
const getUserName = commonJsObj.getUserName;
const getOrgName = commonJsObj.getOrgName;


const getBaseUrl = commonJsObj.getBaseUrl;

const custEncode = commonJsObj.custEncode;
const getDataShouldShowAddUserPopUp = commonJsObj.getDataShouldShowAddUserPopUp;
const getDataShouldShowOrganisationPopUp = commonJsObj.getDataShouldShowOrganisationPopUp;
const updateShouldShowAddUserPopUp = commonJsObj.updateShouldShowAddUserPopUp;
const updateShouldShowOrganisationPopUp = commonJsObj.updateShouldShowOrganisationPopUp;
const getRole = commonJsObj.getRole;
const getUserImage = commonJsObj.getUserImage;


const emitter = new ee();

export const notifyheaderpopup = (optionName,otherParam) => {
    emitter.emit('customnotiforheaderpopup', optionName, otherParam);
}




class Header extends Component {

    isComponentLoaded = false;

    // for context
    static contextType = CommonContext;

    /**
        * Header
        * Function name : constructor
        * Author        :
        * Created Date  : 12-2-2020
        * Purpose       : initializing state
        * Params        : props
       **/
    constructor(props) {
        super(props);
        this.state = {
            u_email: '',
            u_name: '',
            org_name:'',
            userImage:null,


            // For Org detail modal   
            showOrgDetailModal: false,
            
            // For usr add to org modal 
            showUsrAddOrgModal: false,
            organisationMaxCapacity: 0,
            organisationCurrentCapacity: 0,
            shouldShowAddUserPopUp: false,            
          }        

        // For Organisation detail modal
        this.orgdetmodref = React.createRef();
        // For User add to Organisation modal
        this.usraddorgmodref = React.createRef();
         // For header outer div
        this.refparentheaderdiv = React.createRef();

        // for event emitter 
        emitter.on('customnotiforheaderpopup',(optionName,otherParam)=>{        
        
              switch(optionName){
                case "openOrgDetPopup":
                  console.log(" openOrgDetPopup");
                  if(getRole()=="CC_ADMIN" && getDataShouldShowOrganisationPopUp()== "true"){
                        // now update org popup show data
                        setTimeout(()=>{updateShouldShowOrganisationPopUp(false);},5000);
                        // show modal
                        this.organisationDetailModalOpen();
                  }  
                  break;
                case "updateProfImage":
                  this.setProfImage();
              }          

          });
         
    }

    /**
     * Header
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 12-2-2020
     * Purpose       : Life cycle hook method
     * Params        : 
     **/
    componentDidMount(){
        this.isComponentLoaded = true;
        this.isComponentLoaded && this.setState({'u_email':getUserEmail()});
        this.isComponentLoaded && this.setState({'u_name':getUserName()});
        this.isComponentLoaded && this.setState({'org_name':getOrgName()},()=>{
          
        });

        this.setProfImage();

        
    }

    /**
     * Header
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 12-2-2020
     * Purpose       : Life cycle hook method
     * Params        : 
     **/
    componentWillUnmount(){
        this.isComponentLoaded = false;
     }

     setProfImage = () =>{
      this.isComponentLoaded && this.setState({'userImage':getUserImage()});
     }

    

    /*** Organization detail modal related starts  */
    /**
     * Dashboard
     * Function name : organisationDetailModalOpen
     * Author        :
     * Created Date  : 11-2-2020
     * Purpose       : To open organisationDetailModal
     * Params        : 
    **/  
   organisationDetailModalOpen = ()=>{    
    this.isComponentLoaded &&  this.setState({showOrgDetailModal:true},()=>{
      this.orgdetmodref.current.showModal();
      this.getOrganisationDetailModalOpen();
    });    
      
  }

  /**
     * Dashboard
     * Function name : organisationDetailModalClose
     * Author        :
     * Created Date  : 11-2-2020
     * Purpose       : To close organisationDetailModal
     * Params        : 
    **/
   organisationDetailModalClose = ()=>{    
    this.isComponentLoaded && this.setState({showOrgDetailModal:false},()=>{
      this.orgdetmodref.current.hideModal();
    });
    }

    /**
   * Dashboard
   * Function name : getOrganisationDetailModalOpen
   * Author        :
   * Created Date  : 11-2-2020
   * Purpose       : Get modal view with data
   * Params        : 
  **/
    getOrganisationDetailModalOpen() {   
        let viewData = <div className="dash_modal">
        
        <OrgDetailInfoComp callorginfomodalclose={()=>{
                this.organisationDetailModalClose();
        }} orgnamedata={()=>{ return getOrgName();}} calladdorgusermodal={()=>{
            this.organisationUserAddModalOpen();
        }}  />
        
        </div>;
    
        this.orgdetmodref.current.updateStateContent(viewData);
        this.orgdetmodref.current.showContent();
        return viewData;
    }

    

    /*** Organization detail modal related ends  */

     /*** Add user to Organization modal related starts  */
    /**
     * Dashboard
     * Function name : organisationUserAddModalOpen
     * Author        :
     * Created Date  : 11-2-2020
     * Purpose       : To open organisationUserAddModal
     * Params        : 
    **/  
   organisationUserAddModalOpen = ()=>{    
    this.isComponentLoaded && this.setState({showUsrAddOrgModal:true},()=>{
      this.usraddorgmodref.current.showModal();
      this.getOrganisationUserAddModalOpenContent();
    });    
      
  }

  /**
     * Dashboard
     * Function name : organisationUserAddModalClose
     * Author        :
     * Created Date  : 11-2-2020
     * Purpose       : To close organisationUserAddModal
     * Params        : 
    **/
   organisationUserAddModalClose = ()=>{    
    this.isComponentLoaded && this.setState({showUsrAddOrgModal:false},()=>{
      this.usraddorgmodref.current.hideModal();
    });
    }

    /**
   * Dashboard
   * Function name : getOrganisationDetailModalOpen
   * Author        :
   * Created Date  : 11-2-2020
   * Purpose       : Get modal view with data
   * Params        : 
  **/
 getOrganisationUserAddModalOpenContent(data) {   
        let viewData = <div className="dash_modal">
        
        <OrgUserAddComp calladdorgusermodalclose={()=>{
            this.organisationUserAddModalClose();
        }} 
        
        />

        </div>;
    
        this.usraddorgmodref.current.updateStateContent(viewData);
        this.usraddorgmodref.current.showContent();
        return viewData;
        }
    /*** Add user to Organization modal related ends  */

    trackNotiForCloseSplit = (otherParam) =>{
      let notiType =  otherParam.notiType!==undefined? otherParam.notiType: "";
      let trigerredFrom =  otherParam.trigerredFrom!==undefined? otherParam.trigerredFrom: ""; 
      if(notiType=="CLOSE_SPLITSCREEN"){
          let closeSplitHeaderCallFrm = ["AFTER_LOGIN_HEADER_MENU","AFTER_LOGIN_HEADER_LOGO"];
          if(closeSplitHeaderCallFrm.indexOf(trigerredFrom)!=-1){
                  console.log("trigerredFrom=>>",trigerredFrom);
          }
      }
      
      if(notiType=="SCROLL_TO_MAIN_HEADER"){
        
        if(this.refparentheaderdiv!==null && this.refparentheaderdiv.current!==undefined && this.refparentheaderdiv.current!==null){
          window.scrollTo(0,  this.refparentheaderdiv.current.offsetTop);
        }
        
         
      }

    }

    /**
     * Header
     * Function name : render
     * Author        :
     * Created Date  : 12-2-2020
     * Purpose       : Life cycle hook method
     * Params        : 
     **/
    render() {

      let locPath  = this.props.location.pathname;
      let hideDefaultHeader  = false;
      let taskCreateUrlFlag = false;
      let invPaymentUrlFlag = false;
      let subsPaymentUrlFlag = false;
      
      if(locPath.indexOf("task-add")>-1){
        //hideDefaultHeader = true; 
        taskCreateUrlFlag = true;
      }
      else if(locPath.indexOf("payment")>-1){
        //hideDefaultHeader = true; 
        invPaymentUrlFlag = true;
      }
      else if(locPath.indexOf("subscription-payment")>-1){
        //hideDefaultHeader = true;
         subsPaymentUrlFlag = true;
      }

      let showDashTaskTabsFlag = false;
      if(/tasks/.test(locPath)){
        showDashTaskTabsFlag = true; 
      }
      else if(/dashboard/.test(locPath)){
        showDashTaskTabsFlag = true; 
      }

      let hideProfAppliVirLinks = ( taskCreateUrlFlag==false && invPaymentUrlFlag==false && subsPaymentUrlFlag==false)?false:true;


      if (hideDefaultHeader){       
        return (<></>)    
      }else{

        return (
          <>
          <NotiEmitTrack callbackfuncforcommontrackofnoti={this.trackNotiForCloseSplit} />       
          {
            /* header and nav area start */
          }
          <div ref={this.refparentheaderdiv} className={`header_nav_area ${(hideProfAppliVirLinks==true)?"header_nav_area_newadjust":""}`}>
            {/* logo and nav area start */}
            <div className="logo_nav_area">
              <div className="panel30 logo_panel">
                <div>
                  <a onClick={(evn)=>{ evn.preventDefault();
                      commonnotitrack("CLOSE_SPLITSCREEN",{
                        "notiType": "CLOSE_SPLITSCREEN",
                        "triggerCallback":false,
                        "trigerredFrom":"AFTER_LOGIN_HEADER_LOGO"
                       });
                      this.props.history.push("/tasks");
                  }} href="">
                    <img src={getBaseUrl()+"assets/images/logo.svg"}  />
                  </a>
                </div>
              </div>
              <div className="panel70 head_menu_panel">
                  <div className="nav_block">
                    <ul>
                      
                      
                      <li>
                        <a href=""  onClick={(evn)=>{ evn.preventDefault();
                                    this.props.history.push("/dashboard");
                        }}>
                          <span className="left_mainHeader_navImg">
                            <img src={getBaseUrl()+"assets/images/home.png"}   />
                          </span>{" "}
                          <div className="nav_txt">Home</div>
                        </a>
                      </li>
                      <li>
                        <a className="pointer_none" href=""  onClick={(evn)=>{ evn.preventDefault();}}>
                          <span className="left_mainHeader_navImg">
                            <img src={getBaseUrl()+"assets/images/knowledge-centre.png"}   />
                          </span>{" "}
                          <div className="nav_txt">Knowledge Centre</div>
                          <span className="comming_soon">Coming soon</span>
                        </a>
                      </li>
                      <li>
                        <a className="pointer_none" href=""  onClick={(evn)=>{ evn.preventDefault();}}>
                          <span className="left_mainHeader_navImg">
                            <img src={getBaseUrl()+"assets/images/getting-started.png"}   />
                          </span>{" "}
                          <div className="nav_txt">Getting Started</div>
                          <span className="comming_soon">Coming soon</span>
                        </a>
                      </li>
                      
                      <li className="bell_li">
                          <BellNotificationList />
                      </li>
                      <li className="user_Name_li">
                        <a href=""  onClick={(evn)=>{ evn.preventDefault();}}>
                          <div className="profile_name">
                            <div className="name">{this.state.u_name?this.state.u_name:''}</div>
                            <div className="com_name">{this.state.org_name?this.state.org_name:''}</div>
                          </div>
                        </a>
                      </li>
                      <li className="user_li">
                        <div className="profile_img">
                          {/* <div><img src="assets/images/pic.png" alt=""/> <i class="fa fa-caret-down" aria-hidden="true"></i></div> */}
                          <button
                            className="btn btn-secondary dropdown-toggle btn_appearance_none user_drop_btn"
                            type="button"
                            data-toggle="dropdown"
                          >
                            
                            { (this.state.userImage === null ||this.state.userImage == "null" || this.state.userImage==""|| !(this.state.userImage))  && (
                                          <>
                                          <img  src={getBaseUrl()+"assets/images/user-avatar-thumb.png"}  />
                                          </>

                            )}

                             { (this.state.userImage!=null && this.state.userImage != "null" && this.state.userImage)  && (
                                         <img src={this.state.userImage} />

                            )}
                          </button>
                          <div className="dropdown-menu dropdown-menu-right user_drop_menu">
                            <ul>
                              <li>
                                <a href=""  onClick={(evn)=>{ evn.preventDefault();
                                 commonnotitrack("CLOSE_SPLITSCREEN",{
                                  "notiType": "CLOSE_SPLITSCREEN",
                                  "triggerCallback":false,
                                  "trigerredFrom":"AFTER_LOGIN_HEADER_MENU"
                                 });
                                this.props.history.push("/profile-settings");
                                }} className="user_drop_a active">
                                  Profile settings
                                </a>
                              </li>
                              <li>
                                <a href=""  onClick={(evn)=>{ 
                                  evn.preventDefault();
                                  commonnotitrack("CLOSE_SPLITSCREEN",{
                                    "notiType": "CLOSE_SPLITSCREEN",
                                    "triggerCallback":false,
                                    "trigerredFrom":"AFTER_LOGIN_HEADER_MENU"
                                   });
                                  this.props.history.push("/application-settings");
                                }} className="user_drop_a active">
                                  Application settings
                                </a>
                              </li>
                              {/* CC-824 */}
                              {/* <li>
                                <a href=""   onClick={(evn)=>{ 
                                  evn.preventDefault();
                                  commonnotitrack("CLOSE_SPLITSCREEN",{
                                    "notiType": "CLOSE_SPLITSCREEN",
                                    "triggerCallback":false,
                                    "trigerredFrom":"AFTER_LOGIN_HEADER_MENU"
                                   });
                                  this.props.history.push("/virtual-bank-account");
                                  
                                  }} className="user_drop_a">
                                  Virtual bank account
                                </a>
                              </li> */}
                              <li>
                                <FeedbackCommon custclassname="user_drop_a" textname="Feedback" />
                                {/* <a href=""  onClick={(evn)=>{ evn.preventDefault();}} className="user_drop_a">
                                  Feedback
                                </a> */}
                              </li>
                              <li>
                                <RequestDemoCommon custclassname="user_drop_a" textname="Request a demo" />
                              </li>
                              <li>
                                <a href={getBaseUrl()+"terms_privacy/privacy.pdf"} target="_blank"  className="user_drop_a">
                                Privacy policy
                                </a>
                              </li>
                              <li>
                                <a href={getBaseUrl()+"terms_privacy/terms_organisation.pdf"} target="_blank"  className="user_drop_a">
                                Terms and condition
                                </a>
                              </li>
                              <li>
                                <a href=""  onClick={(evn)=>{ evn.preventDefault();}} className="user_drop_a pointer_none">
                                  Help & Support
                                  <span className="comming_soon">Coming soon</span>
                                </a>
                              </li>
                             
                              <li>
                                <LogoutCommon custclassname="user_drop_a" textname="Logout" />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
              </div>

              <div className="clearboth" />
            </div>
            {/* logo and nav area end */} 
            {/* tab nav area start */}
            <div className={`tabs_block `}>
              <ul className="nav">
                {showDashTaskTabsFlag && (
                    <>
                      <li>
                        <NavLink to="/dashboard"  activeClassName="activeNavLinkCust" >
                          Dashboard
                          
                      </NavLink>
                      </li>
                      <li>
                        <NavLink to="/tasks" activeClassName="activeNavLinkCust" >
                        Tasks
                        </NavLink>
                  </li>
                  </>
                )}
                {showDashTaskTabsFlag==false && hideProfAppliVirLinks==false && (
                    <>
                      <li>
                        <NavLink to="/profile-settings" activeClassName="activeNavLinkCust" >
                        Profile Settings
                        </NavLink>
                       
                      </li>
                      <li>
                        <NavLink to="/application-settings" activeClassName="activeNavLinkCust" >
                        Application Settings
                        </NavLink>
                       
                        
                      </li>
                      {/* CC-824 */}
                      {/* <li>
                        <NavLink to="/virtual-bank-account" activeClassName="activeNavLinkCust" >
                        Virtual Bank Account
                        </NavLink>
                      </li> */}
                </>
                )}
              </ul>
            </div>
            
            {/* tab nav area end */}
          </div>
          {
            /* header and nav area end */
          }
          {/* Organisation details modal start */}
          <CustReactBootstrapModal ref={this.orgdetmodref}  backdrop={false} backdropClassName="custom_modal_class"   animation={false} show={this.state.showOrgDetailModal} onShow={()=>{}} onHide={()=>{}} modalcustdialog="custom_modal_lg modal_organisation_lg"  loaderbodytitle="Organisation"  />
          {/* Organisation details modal end */}
           {/* User add to Organisation modal start */}
           <CustReactBootstrapModal ref={this.usraddorgmodref}  backdrop={false} backdropClassName="custom_modal_class"   animation={false} show={this.state.showUsrAddOrgModal} onShow={()=>{}} onHide={()=>{}} modalcustdialog="custom_modal_lg modal_organisation_lg"  loaderbodytitle="Organisation User Add"  />
          {/* User add to Organisation modal ends */}
          </>
      )

      }


        
    }

}

export default withRouter(Header);