/**
 * ProfileUser
 * Page/Class name   : ProfileUser
 * Author            :
 * Created Date      : 7-5-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : render master component
**/
import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
// scroll 
import { Scrollbars } from 'react-custom-scrollbars';

import NewCustomGoogleAutoComplete from '../../customisedthirdparty/NewCustomGoogleAutoComplete';
// Services
import OrganisationInformationFetchService from '../../service/OrganisationInformationFetchService';
import OrgDetailSaveService from '../../service/OrgDetailSaveService';
import UserLogoUploadService from '../../service/UserLogoUploadService';

// for context
import CommonContext from '../../context/CommonContext';

import {notifyheaderpopup} from '../../share/Header';

import { commonJsFuncModule as commonJsObj } from '../../../common_utilities/commonjsfunc';
import {commonStaticOptionsJsFuncModule as commonStaticJsObj} from  '../../../common_utilities/commonstaticoptions';

// google ga
//import ReactGA from 'react-ga';
import {customizedRGA} from "../../customisedthirdparty/CustomizedReactGa";


const getBaseUrl = commonJsObj.getBaseUrl;
const validateBlankSpace = commonJsObj.validateBlankSpace;
const updateUserImage = commonJsObj.updateUserImage;

const getStaticOrgSize = commonStaticJsObj.getStaticOrgSize;
const getStaticTeamSize = commonStaticJsObj.getStaticTeamSize;
const getUserEmail = commonJsObj.getUserEmail;

// // ga rnd 
// ReactGA.initialize('UA-190345080-1');



// const orgSizeData = [
//   {"size":{"key":"","value":"Organization Size"}},
//   {"size":{"key":"1-4 persons","value":"1-4 persons"}},
//   {"size":{"key":"5-19 persons","value":"5-19 persons"}},
//   {"size":{"key":"20-99 persons","value":"20-99 persons"}},
//   {"size":{"key":"100-999 persons","value":"100-999 persons"}},
//   {"size":{"key":"1000+ persons","value":"1000+ persons"}},
// ];
const orgSizeData = getStaticOrgSize();


// const teamSizeData = [
//   {"size":{"key":"","value":"Department Size"}},
//   {"size":{"key":"1-4 persons","value":"1-4 persons"}},
//   {"size":{"key":"5-19 persons","value":"5-19 persons"}},
//   {"size":{"key":"20-99 persons","value":"20-99 persons"}},
//   {"size":{"key":"100-999 persons","value":"100-999 persons"}},
//   {"size":{"key":"1000+ persons","value":"1000+ persons"}},
// ];
const teamSizeData = getStaticTeamSize();

const orgDetailDataObj = {
  
  loaderFlag: false,
  taskFiles: [],
  uploadingFlag: false,
  isOrganisationDetailsProvided: false,
  

  // for organisation detail modal
  //designation: '',
  organisationName: '',
  organisationSize: '',
  aboutTheOrganisation:'',
  //phoneNumber: '',
  teamSize: '',
  city: {
    "cityName": "",
    "latitude": "",
    "longitude": "",
    "placeId": "",
    "state":"",
    "country":""
  },
  textboxtext:"",
  filePath:null,
  logoId:"",

  locSelected: [],            
  locdisabledFlag: false,
  

  // for organisation err msg  
  aboutTheOrganisationErMsg: '',
  organisationSizeErMsg: '',  
  teamSizeErMsg: '',
  cityErMsg: '',
  fileUplErMsg: ''


}






class ProfileUser extends Component {

    isComponentLoaded =  false;
    // for context
    static contextType = CommonContext;

    /**  
     * SplitScreenTaskDocuments
     * Function name : constructor
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : initializing children
     * Params        : props
    **/
    constructor(props) {
      super(props);
      this.children = props.children;
      this.state = orgDetailDataObj;
      this.fileUpl = React.createRef();
      this.uploadedFileRef = React.createRef();
      
      //google ga
      this.rgooga = null;
      
     
    }

    /**
     * SplitScreenTaskDocuments
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : lifecycle method
     * Params        :
    **/
    componentDidMount() {
      this.isComponentLoaded = true;

      this.getOrgInfo();
    }

   


    /**  
     * SplitScreenTaskDocuments
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {
      this.isComponentLoaded = false;

    }

    /**  
     * SplitScreenTaskDocuments
     * Function name : componentDidUpdate
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentDidUpdate(prevProps){

    }

    getOrgInfo = () =>{
      let luiserv = new OrganisationInformationFetchService();
      luiserv.getInfo({}).then(data =>{
          
      let loggedInUserInfoResp = data.data.entity;
      ////console.log(" loggedInUserInfoResp=>",loggedInUserInfoResp);
      this.setStateOfOrgInfo(loggedInUserInfoResp);

        

          
      }).catch(err=>{
          console.log("Some server related tech problem occured",err);
      });
    }

    setStateOfOrgInfo = (orgInfo) =>{
      let stateDataObj = {                    
        "organisationName": "",
        "organisationSize": "",
        "teamSize": "",
        "aboutTheOrganisation": ""
        };

        let organisationDetails = orgInfo;
        let orgLogoObj= organisationDetails.organisationLogo!==undefined?organisationDetails.organisationLogo:null;

        if(organisationDetails){
          // get city location info
          let googleCityDetails = organisationDetails.googleCityDetails!==undefined?organisationDetails.googleCityDetails:null;

          stateDataObj.organisationName  = organisationDetails.organisationName!==undefined && organisationDetails.organisationName!==null?organisationDetails.organisationName:"";
          stateDataObj.organisationSize  = organisationDetails.organisationSize!==undefined && organisationDetails.organisationSize!==null?organisationDetails.organisationSize:"";
          stateDataObj.teamSize  = organisationDetails.teamSize!==undefined && organisationDetails.teamSize!==null?organisationDetails.teamSize:"";
          stateDataObj.aboutTheOrganisation  = organisationDetails.aboutTheOrganisation!==undefined && organisationDetails.aboutTheOrganisation!==null?organisationDetails.aboutTheOrganisation:"";

          this.isComponentLoaded && this.setState(stateDataObj,()=>{
            //console.log(" set this data=>>",googleCityDetails);
            this.setStateOfCityObj(googleCityDetails,{"calledFrom":"setStateOfOrgInfo"});

            this.setProfileImageState(orgLogoObj,{"calledFrom":"setStateOfOrgInfo"});
          });
  
        }
       
    }

    removeSelLocation(i,locSelected){
      let valuesData = [...this.state.locSelected];        
      valuesData.splice(i,1);
      this.isComponentLoaded && this.setState({ "locSelected":valuesData,"locdisabledFlag":false});  
      
  }

    setStateOfCityObj = (selectedinfo,otherParam) =>{
      let locObj  = {
        "cityName": "",
        "latitude": "",
        "longitude": "",
        "placeId": "",
        "state":"",
        "country":""
      };
      let textboxtext = "";
      let locSelectedAr = [];
      let locdisabledFlag = false;

      if(selectedinfo){
       
        
        if(otherParam.calledFrom=="setStateOfOrgInfo"){
          textboxtext  =  selectedinfo.cityName!==undefined?selectedinfo.cityName:""; // since textboxtext is not present
          locdisabledFlag = (selectedinfo.cityName!==undefined && selectedinfo.cityName)?true:false;
          if(locdisabledFlag){
            locObj.latitude = selectedinfo.latitude!==undefined?selectedinfo.latitude:"";
            locObj.longitude = selectedinfo.longitude!==undefined?selectedinfo.longitude:"";
            locObj.placeId = selectedinfo.placeId!==undefined?selectedinfo.placeId:"";
            locObj.cityName = selectedinfo.cityName!==undefined?selectedinfo.cityName:"";
            locObj.state = selectedinfo.state!==undefined?selectedinfo.state:"";
            locObj.country = selectedinfo.country!==undefined?selectedinfo.country:"";
            locSelectedAr.push(locObj);
          }
        }else if(otherParam.calledFrom=="selectedGoogleDataInfo"){
          //console.log(" selectedinfo==>>",selectedinfo);
          textboxtext  =  selectedinfo.textboxtext!==undefined?selectedinfo.textboxtext:"";
          locdisabledFlag = (selectedinfo.city!==undefined && selectedinfo.city)?true:false;
          if(locdisabledFlag){
            locObj.latitude = selectedinfo.lat!==undefined?selectedinfo.lat:"";
            locObj.longitude = selectedinfo.lng!==undefined?selectedinfo.lng:"";
            locObj.placeId = selectedinfo.placeId!==undefined?selectedinfo.placeId:"";
            locObj.cityName = selectedinfo.city!==undefined?selectedinfo.city:"";
            locObj.state = selectedinfo.state!==undefined?selectedinfo.state:"";
            locObj.country = selectedinfo.country!==undefined?selectedinfo.country:"";
            locSelectedAr.push(locObj);
          }
          
        }
        
        

      }
  
      this.isComponentLoaded && this.setState({"city":locObj,"textboxtext": textboxtext,"locSelected":locSelectedAr,"locdisabledFlag":locdisabledFlag},()=>{
        ////console.log(" set this data selectedinfo=>>",locObj," textboxtext==>",textboxtext);
        
        
      }); 

     
    }  
    
    setProfileImageState = (orgLogoObj,otherParam) =>{
        let filePath = null;

        if(otherParam.calledFrom=="setStateOfOrgInfo"){
          if(orgLogoObj!==null && orgLogoObj!==undefined){
            filePath  = orgLogoObj.filePath;
            this.isComponentLoaded && this.setState({"filePath":filePath},()=>{
              updateUserImage(filePath);
              setTimeout(()=>{
                notifyheaderpopup("updateProfImage",{
                });
              },900);
              

            });
          }
        }
        else if(otherParam.calledFrom=="calUploadFileService"){
          if(orgLogoObj!==null && orgLogoObj!==undefined){
            let file  = orgLogoObj.filePathObj;
           
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = function (e) {
              this.setState({
                filePath: [reader.result],
                logoId: orgLogoObj.logoId
              },()=>{
               
              })
            }.bind(this);
          }
        }
       

        

    }

    trackFileSelection = e =>{
      e.preventDefault();
      let fileObj = e.target.files;    
      
      let chkFileValidation =  this.validateFileSelected(fileObj);
      
      //this.calUploadFileService(fileObj); 
      if(chkFileValidation){
          this.calUploadFileService(fileObj);
      }else{
        this.fileUpl.current.value = "";
      }    
    }

    validateFileSelected(fileObj){
      const maxFileUploadAllowed = 1;
      const totAllowedMaxFileUploadSize = 500 * 1024 * 1024; // 500 MB
      const allowedFileExt = ['jpg','jpeg','png'];
      
      const taskFiles = this.state.taskFiles;        
      const docsData = taskFiles;
      let respdataFlag =  true;
      let stepOneStFileUplErMsg = "";
      if(docsData!==undefined){
          if(docsData.length==maxFileUploadAllowed){
              //error
              respdataFlag = false;
              stepOneStFileUplErMsg = "Sorry you cannot upload more than "+maxFileUploadAllowed+"  files in whole.";
              
          }else{
              if(docsData.length < maxFileUploadAllowed){
                  let nowtotAllowedCntOfFileUpl = maxFileUploadAllowed - docsData.length;
                  if(fileObj.length <= nowtotAllowedCntOfFileUpl){
                      // check file validations like size and extension
                      let totPrevSize  = 0; 
                      Object.keys(docsData).map((k,i)=>{
                          totPrevSize = totPrevSize + docsData[k].size;                 
                      });
                      //////console.log.log("Total attempted size=>",totSize);

                      let totCurSize  = 0; let totalInvalidExtAr = [];
                      Object.keys(fileObj).map((k,i)=>{
                          totCurSize = totCurSize + fileObj[k].size; 
                          let nameFl = fileObj[k].name;
                          let extnReceived = nameFl.split('.').pop();
                          if(allowedFileExt.indexOf(extnReceived.toLowerCase())==-1){
                              totalInvalidExtAr.push(extnReceived);
                          }  
                      });

                      //////console.log.log("chk size=>> totPrevSize=>"+totPrevSize+" totCurSize=>"+totCurSize, "Totl size now=>>"+(totCurSize + totPrevSize), " totAllowedMaxFileUploadSize=>>>"+totAllowedMaxFileUploadSize );
                      if( (totCurSize + totPrevSize) > totAllowedMaxFileUploadSize){
                          //error
                          respdataFlag = false;
                          stepOneStFileUplErMsg = "You have crossed total maximum upload file size.";                            
                      }

                      
                      if(totalInvalidExtAr.length>0){
                          ////console.log.log("totalInvalidExtAr=>",totalInvalidExtAr);
                          //error
                          stepOneStFileUplErMsg = "This file type is not allowed.";
                          respdataFlag = false;
                      }
                      

                  }else{
                      //error
                      respdataFlag = false;
                      stepOneStFileUplErMsg = "You cannot upload more than "+maxFileUploadAllowed+" files in total (It includes previously uploaded files if any).";
                      
                  }
  
              }
          }
      }else{
          respdataFlag = false;
          stepOneStFileUplErMsg = "Something went wrong. Please try again.";
      }   

      this.isComponentLoaded && this.setState({ "fileUplErMsg": stepOneStFileUplErMsg});
      if(respdataFlag==false){
        //console.log(" stepOneStFileUplErMsg=>>",stepOneStFileUplErMsg);
        let paramEr = {};
                    paramEr.notiMsg = stepOneStFileUplErMsg;
                    paramEr.successOrError = 0; // 0 or 1
                    paramEr.dismissDuration = 1500; // generally 1500
                    paramEr.showCloseButton = false;
                    this.props.showsuccessorerrormessage(paramEr);
      }
      return respdataFlag;
    }

    async calUploadFileService(fileObj){
      ////console.log.log("chk file fileObj=>>>",fileObj);
      let lserv = new UserLogoUploadService();
      this.isComponentLoaded && this.setState({"uploadingFlag":true});
      

      let paramObjData = {         
          "data": fileObj
      };

      lserv.uploadFiletoServer(paramObjData).then(data =>{
          //this.setStepOneStDocuments();
          ////console.log.log("success resp=>",data);
          let entityObj  = data.data.entity[0];

          let orgLogoObjData  = {"filePathObj":fileObj[0],"logoId":entityObj.id};
          let otherParamData = {"calledFrom":"calUploadFileService"};
          this.setProfileImageState(orgLogoObjData,otherParamData);
         


      }).catch((e)=>{
              ////console.log.log("server error=>",e);
      }).finally(()=>{
          this.fileUpl.current.value = "";  
          this.isComponentLoaded && this.setState({"uploadingFlag":false});
      });
    }


    callSaveOrgDetail = () => {        
          let creds = {                    
                    "organisationName": this.state.organisationName,
                    "organisationSize": this.state.organisationSize,
                    "teamSize": this.state.teamSize,
                    "aboutTheOrganisation": this.state.aboutTheOrganisation,
                    "city": this.state.city
                    };
          if(this.state.logoId){
            creds.logoId = this.state.logoId;
          }

          ////console.log(" hit save api==>>",creds);
          //return false;

          let lserv = new OrgDetailSaveService();
          lserv.saveOrgDetail(creds).then(data =>{
              ////console.log("data=>",data,data.status);        
              if(data.status==200){     
                let respdata = data.data.entity;
                this.setStateOfOrgInfo(respdata);                  
                let paramSuc = {};
                paramSuc.notiMsg = "Updated successfully";
                paramSuc.successOrError = 1; // 0 or 1
                paramSuc.dismissDuration = 1500; // generally 1500
                paramSuc.showCloseButton = false;
                this.props.showsuccessorerrormessage(paramSuc);

                // ga rnd start
                customizedRGA.sendEventData({
                  category: 'USER_PROFILE',
                  action: 'PROFILE_DATA_SUBMISSION_SUCCESS_RESPONSE',
                  label: 'User profile data submitted successfully',
                  nonInteraction: true,
                });
                // ga rnd ends
          
                              
              }                   
          }).catch(err=>{
            let errStatusCodeAr = [400,500]; // allowed error status code
            if(err.response && err.response.data){
                let erRespData = err.response.data;
                if(errStatusCodeAr.indexOf(erRespData.status) !== -1){         
                    let errMsg = erRespData.message?erRespData.message:"Some technical problem occurred from server. Please try again.";
  
                    
                    let paramEr = {};
                    paramEr.notiMsg = errMsg;
                    paramEr.successOrError = 0; // 0 or 1
                    paramEr.dismissDuration = 1500; // generally 1500
                    paramEr.showCloseButton = false;
                    this.props.showsuccessorerrormessage(paramEr);
  
              }
            }        
      
          }).finally(()=>{
            setTimeout(()=>{
              this.isComponentLoaded && this.setState({loaderFlag:false},()=>{
                });
            },1500);
            
          });
      
    }

    custValidate = ()=>{     
      let organisationSizeErMsg = '';
      let teamSizeErMsg = '';
      let aboutTheOrganisationErMsg = '';
      let cityErMsg = '';
    
      let totErrCountTrack = [];
        
      //let getOrgCityData = this.orgcitygaref.current.getSelectedPlaceFromGoogAuto();
      let textboxtext = this.state.textboxtext;
    
        if(!(this.state.aboutTheOrganisation)||(this.state.aboutTheOrganisation.length==0)|| (validateBlankSpace(this.state.aboutTheOrganisation)==true)){
          aboutTheOrganisationErMsg = "Description is required.";        
          
          totErrCountTrack.push(aboutTheOrganisationErMsg);
        }else{
            if(this.state.aboutTheOrganisation.length>280){
              aboutTheOrganisationErMsg = "Description cannot be more than 280 characters.";  
              totErrCountTrack.push(aboutTheOrganisationErMsg);   
            }
        } 
    
        if(!(this.state.teamSize)||(this.state.teamSize.length==0)){
          teamSizeErMsg = "Department Size is required.";         
          totErrCountTrack.push(teamSizeErMsg);          
            
        }
    
        if(!(this.state.organisationSize)||(this.state.organisationSize.length==0)){
          organisationSizeErMsg = "Organization Size is required.";          
          totErrCountTrack.push(organisationSizeErMsg);          
            
        }
    
        if((this.state.locSelected.length==0)){
          cityErMsg = "City is required.";          
          totErrCountTrack.push(cityErMsg);          
            
        }else{
          //console.log(" this.state.textboxtext==>",this.state.textboxtext,this.state.city);
        }

        let stateErData = {
          "aboutTheOrganisationErMsg": aboutTheOrganisationErMsg,
          "organisationSizeErMsg": organisationSizeErMsg,  
          "teamSizeErMsg": teamSizeErMsg,
          "cityErMsg": cityErMsg,
        }
       
        this.isComponentLoaded && this.setState(stateErData);
       
    
        if(totErrCountTrack.length>0){          
              return false;
        }
        return true;
    }

    chkValidationBeforeProceed = () =>{

      // ga rnd related starts
      // ReactGA.event({
      //   category: 'USER_PROFILE',
      //   action: 'SUBMIT_BUTTON_CLICK'
      // });

      customizedRGA.sendEventData({
        category: 'USER_PROFILE',
        action: 'SUBMIT_BUTTON_CLICK'
      });

      // ga rnd related ends

      this.isComponentLoaded && this.setState({"loaderFlag":true});
      const isValid = this.custValidate();   
      if(isValid){
          this.callSaveOrgDetail();
      }else{
        setTimeout(()=>{
          this.isComponentLoaded && this.setState({"loaderFlag":false});
        },2500);
        
      } 

    }
  
   
    selectedGoogleDataInfo = (selectedinfo)=>{  

          this.setStateOfCityObj(selectedinfo,{"calledFrom":"selectedGoogleDataInfo"});
  
    }
  
    handleChange = (e) => {
      this.isComponentLoaded &&  this.setState({[e.target.name]: e.target.value}); 
    }
    openFileUploadDialog = () =>{
      this.fileUpl.current.click();
    }
    /**  
     * ProfileUser
     * Function name : render
     * Author        :
     * Created Date  : 7-5-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() {  

      return (
        <>
             
              <div className="dashboard_cont_area tasklist_cont_areabg setting_cont_areabg setting_main_body_section">
                {/* ===  DashBoard Main Body Start ===  */}
                <div className="cutom_container cutom_container_userSetting">
                  <div className="create_task_area userSetting_area">
                    {/* ===  task Listing filter start === */}
                    {/* ===  task Listing filter end === */}
                    {/* ===========  Setting Page main body start =============   */}
                    {/* ===  task Listing start start === */}
                    <div className="task_area userSetting_task_area">
                      {/* === User Setting Section / Task Listing Section Start === */}
                      {/* ============== Start =================   */}
                      <div className="userSetting_section">
                        {/* -----  scroll adjust for settting screens start -----   */}
                        <div className="task_listing_scrollbg task_listing_scrollbg_proSetting">
                          {/* ==============   User Setting Section Start =================   */}
                          <div className="userSetting_area_bg">
                            {/* =====  User KYC page body start =====  */}
                            <div className="userSetting_area user_page_area">
                              <div className="userSetting_scroll">
                                {/* ====  User KYC start ===  */}
                                <div className="user_kyc_bg">
                                  <div className="user_kyc_box">
                                    <div className="user_kyc_img_boxBbg">
                                      <div className="user_kyc_img">
                                        {this.state.filePath && (
                                          <img ref={this.uploadedFileRef} src={this.state.filePath}  />

                                        )}
                                        { (this.state.filePath===null || this.state.filePath=="")  && (
                                          <img ref={this.uploadedFileRef} src={getBaseUrl()+"assets/images/user-avatar-thumb.png"}  />

                                        )}
                                        <button onClick={(evn)=>{
                                          evn.preventDefault();
                                          this.openFileUploadDialog();
                                        }} className="btn_appearance_none user_kyc_imgBtn">
                                          Edit
                                        </button>
                                        <input style={{"display":"none"}} ref={this.fileUpl} multiple onChange={this.trackFileSelection} type="file" name="files" />
                                      </div>
                                      
                                    </div>
                                   
                                    
                                    {/* === white box start ===  */}
                                    <div className="user_kyc_whiteBox">
                                      {/* ===  */}
                                      <div className="kyc_box_title">
                                        <div className="kyc_box_title_block">
                                          <h4>Organisation details</h4>
                                          <div className="kyc_box_title_icon pointer_none">
                                            <span className="top_sug_left">
                                              <span
                                                className="input_tooltip btn_tooltip"
                                                aria-label="Demo ToolTip Text, Demo ToolTip Text, Demo ToolTip Text, Demo ToolTip Text"
                                                data-balloon-pos="down"
                                              >
                                                <i
                                                  className="fa fa-globe"
                                                  aria-hidden="true"
                                                />
                                              </span>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      {/* ===  */}
                                      {/* ====  subscription scroll start ===  */}
                                      <div className="kyc_scroll_bg">
                                        <div className="react_use_set_scroll user_scroll mCustomScrollbar height_450">
                                          {/* ============== */}
                                          <Scrollbars 
                                            ref={this.scrollerRef}
                                            style={{ height: 450 }}
                                            autoHeightMin={450}
                                            renderView={props => <div {...props} className="view profluserscrollcustomclass"/>}
                                          >
                                          <div className="s_midbox s_midbox_kyc">
                                            <div className="main_form_box">
                                              {/* ======= kyc form field start ======= */}
                                              <div className="form_block form_block_full">
                                                <div className="panel100 form__group_normar">
                                                  <label
                                                    htmlFor="name"
                                                    className="form__label"
                                                  >
                                                    <b>Organisation name</b>
                                                  </label>
                                                  {/* <div className="form__group field form_group_error">
                                                    <input
                                                      className="form__field"
                                                      placeholder="Enter"
                                                      type="text"
                                                    />
                                                    <span className="error_span">
                                                      An error occured!
                                                    </span>
                                                  </div> */}
                                                  
                                                  <div className="form__group field">
                                                    <div className="input_datashow">
                                                <p>{this.state.organisationName}</p>
                                                    </div>
                                                  </div>
                                                  
                                                </div>
                                                <div className="clearboth" />
                                              </div>
                                              <div className="form_block form_block_full">
                                                <div className="panel100 form__group_normar">
                                                  <label
                                                    htmlFor="name"
                                                    className="form__label"
                                                  >
                                                    <b>About the organisation</b>
                                                  </label>
                                                  <div className={`form__group field ${ this.state.aboutTheOrganisationErMsg?'form_group_error':''}`}>
                                                    <textarea
                                                      placeholder="Enter description (Max 280 characters)"
                                                      name="aboutTheOrganisation"
                                                      value = {this.state.aboutTheOrganisation}
                                                      onChange={this.handleChange}
                                                      
                                                   />
                                                    <span className="error_span">
                                                      {this.state.aboutTheOrganisationErMsg}
                                                    </span>
                                                  </div>
                                                </div>
                                                <div className="clearboth" />
                                              </div>
                                              <div className="form_block form_block_half">
                                                <div className="panel50 panel_half paddingright15 form__group_normar">
                                                  <label
                                                    htmlFor="name"
                                                    className="form__label"
                                                  >
                                                    <b>Organisation size</b>
                                                  </label>
                                                  <div className={`form__group field ${ this.state.organisationSizeErMsg?'form_group_error':''}`}>
                                                  
                                                    {/* <input
                                                      className="form__field"
                                                      placeholder="Enter"
                                                      type="text"
                                                    />
                                                    <span className="error_span">
                                                      An error occured!
                                                    </span> */}
                                                     <select onChange={ this.handleChange } name="organisationSize" id="organisationSize" className="selectpicker dropup form__field" data-header="Organisation Size"
                                                     value={this.state.organisationSize}
                                                     >
                                                        {Object.keys(orgSizeData).length > 0 &&
                                                        Object.keys(orgSizeData).map((e, i) => (
                                                            <option key={i} value={orgSizeData[e].size.key} > {orgSizeData[e].size.value}</option>
                                                        ))
                                                         }                       
                                                        
                                                        </select>
                         
                                                  <span className="error_span"> {this.state.organisationSizeErMsg} </span>
                                                  </div>
                                                </div>
                                                <div className="panel50 panel_half paddingleft15 form__group_normar">
                                                  <label
                                                    htmlFor="name"
                                                    className="form__label"
                                                  >
                                                    <b>Department Size</b>
                                                  </label>
                                                  <div className={`form__group field ${ this.state.teamSizeErMsg?'form_group_error':''}`}>
                                                    {/* <select
                                                      className="selectpicker dropup form__field"
                                                      data-header="Organisation Size"
                                                    >
                                                      <option>Select</option>
                                                      <option>Select 01</option>
                                                      <option>Select 02</option>
                                                    </select>
                                                    <span className="error_span">
                                                      An error occured!
                                                    </span> */}
                                                    
                                                      <select onChange={ this.handleChange } name="teamSize" id="teamSize" className="selectpicker dropup form__field" data-header="Department Size"
                                                      value={this.state.teamSize}>
                                                     {Object.keys(teamSizeData).length > 0 &&
                                                      Object.keys(teamSizeData).map((e, i) => (
                                                          <option key={i} value={teamSizeData[e].size.key} > {teamSizeData[e].size.value}</option>
                                                      ))
                                                    }
                                                    </select>
                         
                                                    <span className="error_span"> {this.state.teamSizeErMsg} </span>
                                                                              
                                                  </div>
                                                </div>
                                                <div className="clearboth" />
                                              </div>
                                              <div className="form_block form_block_full">
                                                <div className="panel100 form__group_normar">
                                                  <label
                                                    htmlFor="name"
                                                    className="form__label"
                                                  >
                                                    <b>City</b>
                                                  </label>
                                                  <div className={`form__group field ${ this.state.cityErMsg?'form_group_error':''}`}>
                                                    {/* <input
                                                      className="form__field"
                                                      placeholder="Enter city"
                                                      type="text"
                                                    /> */}
                                                    
                                                     <NewCustomGoogleAutoComplete ref={this.orgcitygaref} placeholder="City" idname="orgcityid" textboxcustclass="form__field"                        
                                                      selectedGoogleDataInfo={this.selectedGoogleDataInfo}
                                                      showKey="CITY" textboxtext={""}
                                                      removeTextFlag="YES"
                                                      disabledFlag={this.state.locdisabledFlag}
                                                      />
                                                    <span className="error_span">
                                                    {this.state.cityErMsg}
                                                    </span>
                                                    <div className="locstselouterdiv">
                                                        <ul className="custclsforlocselectedforst">
                                                            {Object.keys(this.state.locSelected).map((e,i)=>(
                                                              <li key={i}>
                                                                  <span className="locselsttxtname">{this.state.locSelected[e].cityName}</span>
                                                                  <span className="locselstbtnname"><button onClick={this.removeSelLocation.bind(this,i,(this.state.locSelected[e]))}></button></span>
                                                              </li>))}
                                                        </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="clearboth" />
                                              </div>
                                              {/* ======= kyc form field end ======= */}
                                            </div>
                                          </div>
                                          
                                          </Scrollbars>
                                          {/* ============== */}
                                        </div>
                                      </div>
                                      {/* ====  subscription scroll end ===  */}
                                    </div>
                                    {/* === white box end ===  */}
                                  </div>
                                </div>
                                {/* ====  User KYC end ===  */}
                              </div>
                            </div>
                            {/* =====  User KYC page body end =====  */}
                            {/* =====  Virtual account page body start =====  */}
                            {/* =====  Virtual account page page body end =====  */}
                          </div>
                          {/* ==============   User Setting Section end =================   */}
                        </div>
                        {/* -----  scroll adjust for settting screens end -----   */}
                      </div>
                      {/* ============== end =================   */}
                      {/* === User Setting Section / Task Listing Section end === */}
                    </div>
                    {/* ===  task Listing start end === */}
                    {/* ===========  Setting Page main body end =============   */}
                  </div>
                </div>
                {/* ===  DashBoard Main Body End ===  */}
              </div>
              {/* ===  DashBoard Main Body Footer Start ===  */}
              {/* User Setting footer strat */}
              {/* *****  Put as Dispaly: None ***** */}
              <div className="task_details_btn_area invoPay_footer kyc_footer">
                <div className="btn_fld">
                  <div className="panel50">
                    {/* <button className="btn_appearance_none green_btn">
                      <span>Save as draft</span>
                    </button> */}
                  </div>
                  <div className="panel50">
                    <div className="cancel_continue_btn">
                      <button onClick={(evn)=>{ 
                        
                        evn.preventDefault();

                        // ga rnd related starts
                        // ReactGA.event({
                        //   category: 'USER_PROFILE',
                        //   action: 'CANCEL_BUTTON_CLICK'
                        // });

                        customizedRGA.sendEventData({
                          category: 'USER_PROFILE',
                          action: 'SUBMIT_BUTTON_CLICK'
                        });
                  

                        // ga rnd related ends
                        this.props.history.push("/dashboard");  
                    }}
                        type="button"
                        data-dismiss="modal"
                        className="btn_appearance_none modal_text_btn"
                      >
                        <span>Cancel</span>
                      </button>
                      <button disabled={this.state.loaderFlag} onClick={(evn)=>{
                        evn.preventDefault();
                        this.chkValidationBeforeProceed();
                      }} className="btn_appearance_none sub_grd_btn">
                        <span>Submit</span>
                      </button>
                    </div>
                  </div>
                  <div className="clearboth" />
                </div>
              </div>
              {/* *****  Put as Dispaly: None ***** */}
            
        </>
      );
    }
}
export default withRouter(ProfileUser);