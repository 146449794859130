/**
 * TaskStatusCodeComponent
 * Page/Class name   : CustLoader
 * Author            :
 * Created Date      : 17-2-2020
 * Functionality     : constructor, componentDidMount , componentWillUnmount, render
 * Purpose           : rendering loader structure
**/
import React, { Component, Suspense, lazy} from 'react';
import '../../../custom_resources/css/custom.css';

import { commonJsFuncModule as commonJsObj } from '../../../common_utilities/commonjsfunc';


const getBaseUrl = commonJsObj.getBaseUrl;



class TaskStatusCodeComponent extends Component {
    /**
     * TaskStatusCodeComponent
     * Function name : constructor
     * Author        :
     * Created Date  : 30-4-2020
     * Purpose       : initializing state
     * Params        : props
    **/
    constructor(props) {
        super(props);
        this.state = {
        }    
    }

    /**
     * TaskStatusCodeComponent
     * Function name : componentDidMount
     * Author        :
     * Created Date  : 30-4-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentDidMount() {
        
    }

    /**
     * TaskStatusCodeComponent
     * Function name : componentWillUnmount
     * Author        :
     * Created Date  : 30-4-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    componentWillUnmount() {  
    }

   
    /**
     * TaskStatusCodeComponent
     * Function name : render
     * Author        :
     * Created Date  : 30-4-2020
     * Purpose       : lifecycle method
     * Params        : 
    **/
    render() {    
       
       return (
             <>
                <div className="dropdown footer_taskdropdown">
                                    <button
                                    className="btn_appearance_none taskStatus_btn"
                                    type="button"
                                    data-toggle="dropdown"
                                    >
                                    Task Status
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <div className="foot_taskstatus_ulbg">
                                        <ul>
                                       
                                        <li>
                                            <a href="" onClick={(evn)=>{ evn.preventDefault();}} class="status_color">
                                            <span class="s_color s_gray_decline"></span>
                                            <span class="status_name">Offer Declined </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="" onClick={(evn)=>{ evn.preventDefault();}} className="status_color">
                                            <span className="s_color s_gray" />
                                            <span className="status_name">Saved Darft</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="" onClick={(evn)=>{ evn.preventDefault();}}  className="status_color">
                                            <span className="s_color s_sky" />
                                            <span className="status_name">Open Task</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="" onClick={(evn)=>{ evn.preventDefault();}}  className="status_color">
                                            <span className="s_color s_orange" />
                                            <span className="status_name">Task Offered</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="" onClick={(evn)=>{ evn.preventDefault();}}  className="status_color">
                                            <span className="s_color s_green" />
                                            <span className="status_name">Task Assigned</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="" onClick={(evn)=>{ evn.preventDefault();}}  className="status_color">
                                            <span className="s_color s_red" />
                                            <span className="status_name">Invoice Due</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="" onClick={(evn)=>{ evn.preventDefault();}}  className="status_color">
                                            <span className="s_color s_deepGreen" />
                                            <span className="status_name"> Invoice Paid</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="" onClick={(evn)=>{ evn.preventDefault();}}  className="status_color">
                                            <span className="s_color s_blue" />
                                            <span className="status_name">Closed Task</span>
                                            </a>
                                        </li>
                                      

                                        </ul>
                                    </div>
                                    </div>
                                </div>
                                
             </>
            
        );
    }

  }
  export default TaskStatusCodeComponent;